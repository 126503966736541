/**
 * @typedef {Object} Range
 * @property {number} start
 * @property {number} end
 */

/**
 * Returns all overlapping ranges from the given array.
 *
 * @param {T[]} ranges The values to check for conflicts
 * @return {Array<{start:number, end:number, ranges:T[]}>} All overlapping ranges
 * @template {Range} T
 */
export function computeConflicts(ranges) {
  // Given:
  // [
  //   {start: 0, end: 2}, // a
  //   {start: 1, end: 3}, // b
  //   {start: 5, end: 8}, // c
  //   {start: 6, end: 9}, // d
  //   {start: 7, end: 8}, // e
  // ]
  // Which looks like this on a timeline
  // 0123456789
  // aaa  cccc
  //  bbb  dddd
  //        ee
  // Returns these overlaps
  // [
  //  {start: 1, end: 2, ranges: [a, b]},
  //  {start: 6, end: 7, ranges: [c, d]},
  //  {start: 7, end: 8, ranges: [c, d, e]}
  // ]
  const res = [];
  /** @type {Object<number,number[]>} */
  const interestingTimes = {};
  ranges.forEach((r, i) => {
    (interestingTimes[r.start] || (interestingTimes[r.start] = [])).push(i);
    (interestingTimes[r.end] || (interestingTimes[r.end] = [])).push(-1 - i);
  });

  const activeRanges = {};
  const interestingKeys = Object.keys(interestingTimes).sort((a, b) => a - b);
  interestingKeys.forEach(t => {
    const indexesAtT = interestingTimes[t];
    const oldIndexes = Object.keys(activeRanges);
    const addedIndexes = [];
    const removedIndexes = [];
    indexesAtT.forEach(i => {
      if (i < 0) {
        delete activeRanges[-1 - i];
        removedIndexes.push(-1 - i);
      } else {
        activeRanges[i] = true;
        addedIndexes.push(i);
      }
    });

    if (oldIndexes.length > 1) {
      // there's an overlap
      const rs = oldIndexes.map(i => ranges[i]);
      const lastEnd = res.length > 0 ? res[res.length - 1].end : -Infinity;
      const start = Math.max(...rs.map(r => r.start), lastEnd);
      const end = Math.min(
          ...removedIndexes.map(i => ranges[i].end),
          ...addedIndexes.map(i => ranges[i].start)
      );
      res.push({start, end, ranges: rs.sort(rangeComparator)});
    }
  });

  return res;
}

/**
 * Sorts ranges ascending by start then end.
 *
 * @param {Range} a
 * @param {Range} b
 * @return {number}
 */
export function rangeComparator(a, b) {
  return a.start - b.start || a.end - b.end;
}

/**
 * This function takes a list of all current events, and filters them out to the ones belonging to a specific resource
 * This can then be used to check against the current event's range to see if there are any conflicting bookings at
 * that resource.
 *
 * @param {CalendarEvent[]} events
 * @param {Range} range
 * @param {firebase.firestore.DocumentReference} resourceRef
 * @return {boolean}
 */
export function eventsConflictWithRange(events, range, resourceRef) {
  const existingBookingsForResource = events
      .filter(e => e.resource && e.resource.ref.isEqual(resourceRef))
      .map(e => {
        return {start: e.bookingStart, end: e.bookingEnd};
      });
  existingBookingsForResource.push(range);

  return computeConflicts(existingBookingsForResource).length !== 0;
}
