import {differenceInDays} from 'date-fns';
import {cloneDate, startOfDayUTC, toISODate} from '@/util/dates';
import {countsInRange} from '@/views/dashboard/counts/counts-util';
import * as ss from 'simple-statistics';

/**
 * Creates an array with 'count' entries, and a value of defaultValue.
 *
 * @param {function(): T} defaultValue
 * @param {number} count
 * @return {T[]}
 * @template T
 */
export function repeat(defaultValue, count) {
  const arr = [];

  for (let i = 0; i < count; i++) {
    arr[i] = defaultValue();
  }

  return arr;
}

/**
 * Creates an array with an entry for each hour, and a value of defaultValue.
 *
 * @param {function(): T} defaultValue
 * @return {T[]}
 * @template T
 */
export function hoursArray(defaultValue) {
  return repeat(defaultValue, 24);
}
/**
 * Creates an array with an entry for each minute, and a value of defaultValue.
 *
 * @param {function(): T} defaultValue
 * @return {T[]}
 * @template T
 */
export function minutesArray(defaultValue) {
  return repeat(defaultValue, 60);
}
/**
 * Creates an object with a key for each hour, and a value of defaultValue.
 *
 * @param {function(): T} defaultValue
 * @return {Object<string, T>}
 * @template T
 */
export function hoursObject(defaultValue) {
  const hours = {};

  for (let i = 0; i < 24; i++) {
    const hour = hourKey(i);
    hours[hour] = defaultValue();
  }

  return hours;
}

/**
 * Creates a string in the format HH:mm for the given hour.
 *
 * @param {number} hour
 * @return {string}
 */
export function hourKey(hour) {
  return hour.toString().padStart(2, '0') + ':00';
}

/**
 * Create an object with the given keys, where is each value is the return value of defaultValue.
 *
 * @param {string[]} keys
 * @param {function(): T} [defaultValue]
 * @return {Object<string, T>}
 * @template T
 */
export function objectWithKeys(keys, defaultValue = () => ({})) {
  return keys.reduce((byId, id) => {
    byId[id] = defaultValue();
    return byId;
  }, {});
}

/**
 * Return an array of dates, one date for each date between start and end.
 * Each date will be at UTC midnight.
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @return {Date[]}
 */
export function datesInRange(startDate, endDate) {
  if (endDate < startDate) throw new Error(`end date should be after start date`);

  const date = startOfDayUTC(startDate);
  const difference = differenceInDays(endDate, date);

  const datesInRange = [];
  for (let i = 0; i <= difference; i++) {
    datesInRange.push(startOfDayUTC(date));
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return datesInRange;
}

/**
 * @param {Object<string, CountTotals>} bookingCountTotals
 * @param {Date} startOfDay
 * @return {CountTotals}
 */
export function countsForDay(bookingCountTotals, startOfDay) {
  const endOfDay = cloneDate(startOfDay);
  // todo: don't assume 24 hrs, use zonedTimeToUtc
  endOfDay.setUTCHours(startOfDay.getUTCHours() + 23, 59, 59, 999);
  const startOfDayYmd = toISODate(startOfDay);
  const endOfDayYmd = toISODate(endOfDay);

  const totalsWithFallback = (ymd) => bookingCountTotals[ymd] || [];
  if (startOfDayYmd === endOfDayYmd) {
    // all totals from one day
    return countsInRange(totalsWithFallback(startOfDayYmd), startOfDay, endOfDay);
  } else {
    // local day spans two UTC days
    return [
      ...countsInRange(totalsWithFallback(startOfDayYmd), startOfDay, endOfDay),
      ...countsInRange(totalsWithFallback(endOfDayYmd), startOfDay, endOfDay)
    ];
  }
}

/**
 * @param {number} numWeeks
 * @param {number[]} values
 * @param {number} [weekLength]
 * @return {number[]}
 */
export function weeklyPeaks(numWeeks, values, weekLength = 7) {
  const maxByWeek = repeat(() => 0, numWeeks);

  for (let week = 0; week < numWeeks; week++) {
    const from = week * weekLength;
    const to = from + weekLength; // exclusive
    const weekValues = values.slice(from, to);
    maxByWeek[week] = weekValues.length > 0 && ss.max(weekValues) || 0;
  }

  return maxByWeek;
}

/**
 * @param {number[]} entries
 * @param {number} fallback
 * @return {number}
 */
export function maxWithDefault(entries, fallback = 0) {
  return entries.length > 0 && ss.max(entries) || fallback;
}

/**
 * @param {number[]} entries
 * @param {number} [points]
 * @return {number[]}
 */
export function movingAverage(entries, points = 3) {
  const avgs = [];
  if (entries.length < points + 2) {
    return avgs; // only compute moving average if we have 3+ points to make
  }

  const count = entries.length - points + 1;

  for (let i = 0; i < count; i++) {
    avgs.push(ss.mean(entries.slice(i, i + points)));
  }

  return avgs;
}

/**
 * @param {number[]} entries
 * @param {number} [points]
 * @return {number[]}
 */
export function paddedMovingAverage(entries, points = 3) {
  const ma = movingAverage(entries, points);
  if (ma.length === 0) {
    return [];
  }
  const padding = Math.floor((points - 1) / 2);
  return [
    ...repeat(() => NaN, padding),
    ...ma,
    ...repeat(() => NaN, padding)
  ];
}

/**
 * @param {number} [dp]
 * @return {function(number|undefined): number|undefined}
 */
export function toFixed(dp = 2) {
  return value => isNaN(value) ? value : value.toFixed(dp);
}
