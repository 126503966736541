import {dbUtil, storage} from '@/firebase';
import {Logger} from '@vanti/vue-logger';
import DeferUtil from './defer-util';
import Vue from 'vue';
import {CascadingConfig} from '@/util/config';

const log = Logger.get('store/config');

export default {
  namespaced: true,
  state: {
    appSettingsRef: null,
    configCache: {},
    styleUrl: '',
    logoImg: ''
  },
  getters: {
    docRefsToWatch(state) {
      const refs = [];
      let head = state.appSettingsRef;
      while (head) {
        refs.push(head);
        const doc = state.configCache[head.path];
        if (doc) {
          head = doc.ref;
        } else {
          break;
        }
      }
      return refs;
    },
    mergedConfig(state) {
      const docs = [];
      let head = state.appSettingsRef;
      while (head) {
        const doc = state.configCache[head.path];
        if (doc) {
          docs.push({config: doc});
          head = doc.ref;
        } else {
          break;
        }
      }
      return new CascadingConfig(docs);
    },
    style(state) {
      return state.styleUrl;
    },
    logo(state) {
      return state.logoImg;
    },
    configLoaded(state, getters) {
      return state.appSettingsRef &&
          getters.docRefsToWatch.every(docRef => state.configCache.hasOwnProperty(docRef.path));
    },
    styleStoragePath(state, getters) {
      return getters.mergedConfig.get('cssFile', false);
    },
    logoStoragePath(state, getters) {
      return getters.mergedConfig.get('logoImg', false);
    },
    themeColors(state, getters) {
      return getters.mergedConfig.get('colors', {});
    },
    /** @type {kahu.firestore.ThemeColor|undefined} */
    primaryColor(state, getters) {
      return getters.themeColors.primary;
    },
    /** @type {kahu.firestore.ThemeColor|undefined} */
    secondaryColor(state, getters) {
      return getters.themeColors.secondary;
    },
    /** @type {kahu.firestore.ThemeColor|undefined} */
    accentColor(state, getters) {
      return getters.themeColors.accent;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    updateCache(state, {ref, doc}) {
      const data = doc;
      Vue.set(state.configCache, ref.path, data);
    },
    cacheClear(state, keys) {
      for (const key of keys) {
        Vue.delete(state.configCache, key);
      }
    },
    setAppSettingsRef(state, ref) {
      state.appSettingsRef = ref;
    },
    setCssFile(state, cssFile) {
      state.styleUrl = cssFile;
    },
    setLogoFile(state, logoFile) {
      state.logoImg = logoFile;
    }
  },
  actions: {
    init: {
      root: true,
      handler({commit, state, getters, dispatch, rootGetters}) {
        this.watch(
            () => rootGetters['ns'],
            async ns => {
              if (ns && ns !== '-') {
                commit('app/loading', `nsTheme`, {root: true});
                const ref = await dbUtil.doc('ns', ns, 'settings', rootGetters['versions/appName']);
                commit('setAppSettingsRef', ref);
                const defer = {};
                defer.nsSettings = ref.onSnapshot(
                    doc => {
                      if (doc.exists) {
                        const data = doc.data();
                        if (data && data.config && data.config.theme) {
                          commit('updateCache', {ref, doc: data.config.theme});
                          commit('app/loading', 'nsThemeStyle', {root: true});
                          commit('app/loading', 'nsThemeLogo', {root: true});// just in case
                          setTimeout(() => {
                            log.debug(`setting nsTheme loaded after timeout`);
                            commit('app/loaded', 'nsThemeStyle', {root: true});
                            commit('app/loaded', 'nsThemeLogo', {root: true});
                          }, 30 * 1000);
                        }
                      }
                      commit('app/loaded', `nsTheme`, {root: true});
                    },
                    err => {
                      log.error('ref.onSnapshot for ' + ref.path, err);
                      commit('app/loaded', `nsTheme`, {root: true});
                    }
                );
                commit('defer', defer);
              } else {
                commit('setAppSettingsRef', null);
                commit('reset', ['nsSettings']);
              }
            },
            {immediate: true}
        );

        this.watch(
            () => getters.styleStoragePath,
            async url => {
              if (url) {
                try {
                  const _storage = await storage;
                  const dlUrl = await _storage.ref().child(url).getDownloadURL();
                  commit('setCssFile', dlUrl);
                } catch (err) {
                  log.error('error getting css URL', err);
                }
              } else {
                commit('setCssFile', null);
              }
            }
        );

        this.watch(
            () => getters.logoStoragePath,
            async url => {
              if (url) {
                try {
                  const _storage = await storage;
                  const dlUrl = await _storage.ref().child(url).getDownloadURL();
                  commit('setLogoFile', dlUrl);
                } catch (err) {
                  log.error('error getting logo URL', err);
                }
              } else {
                commit('setLogoFile', null);
              }
              commit('app/loaded', 'nsThemeLogo', {root: true});
            }
        );

        // watch for changes to stylesheet and add to doc
        this.watch(
            () => getters.style,
            cssFile => {
              if (document && cssFile) {
                log.log('cssFile', cssFile);
                let link = document.getElementById('global-style');
                if (link) {
                  link.href = cssFile;
                } else {
                  link = document.createElement('link');
                  link.rel = 'stylesheet';
                  link.href = cssFile;
                  link.id = 'global-style';
                  document.head.appendChild(link);
                }
              } else {
                const link = document.getElementById('global-style');
                if (link) {
                  link.remove();
                }
              }
              commit('app/loaded', 'nsThemeStyle', {root: true});
            },
            {immediate: true}
        );
      }
    },
    unbind({commit}) {
      commit('reset');
    }
  }
};
