<template>
  <v-form
      @submit.prevent="submit"
      class="light-input pb-4"
      ref="form">
    <v-row dense class="px-2">
      <v-col cols="12" class="title font-weight-regular">
        <span v-if="newCard">New Card</span>
        <span v-else>Edit Details (for {{ card.id }})</span>
      </v-col>
    </v-row>
    <card-edit-about v-model="aboutInfo" :new-card="newCard" :v="$v.card" class="px-2 pb-4"/>
    <v-row dense class="mt-3 pl-4 pr-3">
      <v-col cols="12" lg="6" class="pr-lg-6">
        <v-row dense>
          <span v-if="newCard" class="body-2 grey--text">* required fields</span>
          <v-spacer/>
          <v-btn text @click="toView" class="mr-4">Cancel</v-btn>
          <v-btn color="accent" type="submit" :disabled="$v.$invalid || $v.$pending" :loading="$v.$pending">
            {{ newCard ? 'Create' : 'Save' }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {cloneDecoratedData} from '@/firebase';
import {emptyCard} from '@/templates';
import CardEditAbout from '@/views/people/card/components/CardEditAbout';
import debounce from 'debounce';
import firebase from 'firebase/app';
import {required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CardEdit',
  components: {CardEditAbout},
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      card: {},
      // a special field that is updated behind a debouncing function. We attach validation to this for unique ids
      // instead of the card.id field as vuelidate suggests any debouncing happens in out data methods not in the
      // validator
      idToCheck: null
    };
  },
  computed: {
    ...mapGetters('views/card', {
      originalCard: 'card'
    }),
    newCard() {
      return !this.id;
    },
    aboutInfo: {
      get() {
        return this.card;
      },
      set(v) {
        // use vue set to trigger reactivity
        Object.keys(v).forEach(k => {
          // don't set card.id for existing cards, this is not an enumerable property
          if (!this.newCard && k === 'id') {
            return;
          }
          this.$set(this.card, k, v[k]);
        });
      }
    }
  },
  validations: {
    // special validator that we use to async check for unique card IDs. The card.id validator
    // looks at the results of this
    idToCheck: {
      async cardUnique(val) {
        if (!val) {
          return true;
        }
        if (!this.newCard) {
          return true;
        }
        return !(await this.cardExists(val));
      }
    },
    card: {
      id: {
        required,
        cardUnique(val) {
          if (!val) {
            return true;
          }
          if (val !== this.idToCheck) {
            return true;
          }
          return this.$v.idToCheck.$pending || this.$v.idToCheck.cardUnique;
        }
      }
    }
  },
  watch: {
    ['card.id']: {
      handler: debounce(function(val) {
        // eslint-disable-next-line no-invalid-this
        this.idToCheck = val;
      }, 500)
    },
    originalCard: {
      // Don't need deep:true here as the backing model will replace the object on change anyway
      immediate: true,
      handler: 'handleCardUpdate'
    }
  },
  methods: {
    ...mapActions('views/card', [
      'cardExists',
      'updateCard'
    ]),
    submit() {
      // validate data
      if (this.$refs.form.validate()) {
        const editProps = new Set(['id', 'tags', 'title', 'nonTransferable']);
        Object.keys(this.card).forEach(k => {
          // remove properties we're not editing
          if (!editProps.has(k)) {
            delete this.card[k];
            return;
          }
          // remove blank entries
          const v = this.card[k];
          if (typeof v === 'string' && v === '' || v === null) {
            if (this.newCard) {
              delete this.card[k];
            } else {
              this.card[k] = firebase.firestore.FieldValue.delete();
            }
          }
          // replace bools with false if undefined
          if (k === 'nonTransferable') {
            this.card[k] = Boolean(v);
          }
        });

        if (this.newCard) {
          this.card.assignedAt = this.$store.getters['sites/activeSiteId'];
        }

        // add or update then redirect
        const existingId = this.card.id;
        const name = this.card.title || existingId;
        this.updateCard(this.card)
            .then(ref => {
              this.$notify.showSuccess(`${name} ${this.newCard ? 'added' : 'updated'} successfully`);
              const id = (ref && ref.id) || existingId;
              this.$router.push({name: 'card', params: {id: id}});
            })
            .catch((err) => {
              this.$logger.error(err);
              this.$notify.showError(`Error ${this.newCard ? 'adding' : 'updating'} ${name}: ${err}`);
            });
      }
    },
    handleCardUpdate(card) {
      // we could be better at this, we should really never allow changes. Except the first load is a change...!
      // For now, if the user has made any changes, don't update the model
      if (!this.$v.$anyDirty) {
        this.copyFields(card);
      } else {
        this.$logger.debug('Got change to card while we\'re editing', card);
      }
    },
    copyFields(card) {
      if (card) {
        if (card.exists) {
          this.card = cloneDecoratedData(card);
        }
      } else {
        this.card = JSON.parse(JSON.stringify(emptyCard));
      }
    },
    toView() {
      if (this.newCard) {
        this.$router.back();
      } else {
        this.$router.push({
          name: 'card',
          params: {
            id: this.card.id
          }
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
