import nested from 'nested-property';
import {createSnippet} from '@/firebase';

/**
 * Gets the value from a work order. Prefers edited values over non-edited.
 *
 * @param {Object} state
 * @param {kahu.firestore.WorkOrder & DecoratedData} workOrder
 * @param {string} property
 * @return {*}
 */
export function workOrderValue(state, workOrder, property) {
  if (state.edits[workOrder.id]) {
    if (state.edits[workOrder.id].hasOwnProperty(property)) {
      return state.edits[workOrder.id][property];
    }
  }
  return nested.get(workOrder, property);
}

/**
 * Creates a workOrderReservationDetail object.
 *
 * @param {kahu.firestore.ReservationRef} reservationRef
 * @param {kahu.firestore.Resource} resource
 * @return {kahu.firestore.WorkOrderReservationDetail}
 */
export function createWorkOrderDetail(reservationRef, resource) {
  const newWorkOrderDetail = {
    ...reservationRef,
    resource: createSnippet(resource)
  };
  return newWorkOrderDetail;
}


