<template>
  <v-tooltip bottom v-if="show">
    <template #activator="{on}">
      <v-menu :close-on-content-click="false" offset-y v-model="menu">
        <template #activator="{on: menuOn}">
          <v-btn icon v-on="{...on, ...menuOn}">
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
        </template>

        <v-card max-width="400">
          <v-card-title>Create an Account for {{ title }}</v-card-title>
          <v-card-text>
            A user account will be generated for {{ title }}, and they will be sent an email
            to set their password.
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="menu = false" outlined>Cancel</v-btn>
            <v-btn @click="create" color="primary" class="primary-text--text" :loading="loading">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>

    <span>Add as a guest user</span>
  </v-tooltip>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {emailParts} from '@/util/email';
import {functionUtil} from '@/firebase';

export default {
  name: 'PersonUserCheck',
  props: {
    person: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      menu: false,
      show: false
    };
  },
  computed: {
    ...mapGetters(['ns']),
    ...mapGetters('ns', ['domains']),
    ...mapGetters('auth', ['hasAccessTo']),
    email() {
      return this.person && this.person.email;
    },
    parts() {
      const email = this.person && this.person.email;
      return emailParts(email);
    },
    isNsDomain() {
      return this.domains.includes(this.parts.domain);
    },
    title() {
      return this.person && this.person.title;
    }
  },
  watch: {
    'person.email': {
      immediate: true,
      async handler() {
        this.show = this.email && !this.isNsDomain && !(await this.hasUserAccount());
      }
    }
  },
  methods: {
    ...mapActions('views/user', ['createUser']),
    async hasUserAccount() {
      if (!this.email) {
        return false;
      }
      if (!this.hasAccessTo.people.users) {
        return false;
      }
      const personRef = this.person && this.person.ref;
      if (!personRef) {
        return false;
      }
      this.$logger.debug('hasUserAccount', personRef.path);
      const userExistsForPersonApi = await functionUtil.userExistsForPersonApi();
      const res = await userExistsForPersonApi({personRefPath: personRef.path});
      return res.data;
    },
    async create() {
      this.loading = true;
      try {
        await this.createUser(this.email);
        this.show = false;
        this.$notify.showSuccess(`User created for ${this.title}`);
      } catch (e) {
        this.$logger.error(e);
        this.$notify.showError(`Error creating user for ${this.title}, ${e.message}`);
      } finally {
        this.loading = false;
        this.menu = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
