import {Logger} from '@vanti/vue-logger';
import {CardReaderClient} from '@vanti/card-reader-proto/src/gen/cards/cards_pb_service';

const log = Logger.get('api');

let grpcHost = process.env.VUE_APP_API_HOST;
if (!grpcHost) {
  grpcHost = 'http://localhost:' + 0xCAFD;
}

log.debug(`Using gRPC server at ${grpcHost}`);

export default {
  cards: new CardReaderClient(grpcHost)
};
