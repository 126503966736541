<template>
  <div class="user-table v-data-table theme--light">
    <table>
      <v-data-table-header :headers="headers" disable-sort/>
    </table>
    <!--
    Note: don't remove page-mode! While the visual functionality is the same, without it a dom node per row is created
    by the recycle-scroller, which quickly adds up.
    -->
    <recycle-scroller
        page-mode
        class="tbody"
        :items="items"
        :item-size="52">
      <template #default="{item}">
        <user-table-row
            :user="item"
            :headers="headers"
            :exclude-tags="excludeTags"
            :to="{name: 'user-view', params: {id: item.id}}"/>
      </template>
    </recycle-scroller>
    <resize-observer @notify="setWidth"/>
  </div>
</template>

<script>
import UserTableRow from '@/components/users/UserTableRow';
import {ResizeObserver} from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
import {RecycleScroller} from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
// force webpack to include data table styles we need, even though we aren't using it directly
import 'vuetify/src/components/VDataTable/VSimpleTable.sass';

const headers = [
  {text: 'Name', value: 'title', width: '40%'},
  {text: 'Email', value: 'email', width: '40%'},
  // {text: 'Last sign in', value: 'lastSignInTime', width: '20%'},
  {text: 'Linked person', value: 'peopleRef', width: '20%'}
];
const breakpoints = [
  {w: 0, cols: {title: '100%'}},
  {w: 350, cols: {title: '45%', email: '55%'}},
  {w: 600, cols: {title: '40%', email: '40%', peopleRef: '20%'}}
];

export default {
  name: 'UserTable',
  components: {UserTableRow, RecycleScroller, ResizeObserver},
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    excludeTags: {
      type: [Function, Array],
      default: undefined
    }
  },
  data() {
    return {
      width: null
    };
  },
  computed: {
    headers() {
      const width = this.width || 0;
      let breakpoint = null;
      for (let i = breakpoints.length - 1; i >= 0; i--) {
        if (breakpoints[i].w < width) {
          breakpoint = breakpoints[i];
          break;
        }
      }
      if (!breakpoint) {
        return headers;
      }

      return headers.filter(header => breakpoint.cols[header.value])
          .map(header => Object.assign({}, header, {width: breakpoint.cols[header.value]}));
    }
  },
  methods: {
    setWidth() {
      this.width = this.$el.clientWidth;
    }
  }
};
</script>

<style scoped>
  .tbody {
    width: 100%;
  }

  .user-table {
    /* needed by vue-resize */
    position: relative;
  }

  .user-table table {
    background-color: inherit;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    border-spacing: 0;
  }

  .user-table >>> th {
    height: 56px;
    font-size: 14px;
    font-weight: normal;
    padding: 0 16px;
    color: rgba(0,0,0,0.6);
  }

  .user-table >>> thead tr:last-child th {
    border-bottom: solid var(--v-grey-base) 1px;
  }

  .user-table >>> .hover {
    background: #eee;
  }
</style>
