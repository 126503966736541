import {editReservationValue, reservationValue} from './event';
import firebase from 'firebase/app';
import {get as _get} from 'lodash';

/**
 * Define on the given event properties related to approval.
 *
 * @param {import('vuex').ActionContext} context
 * @param {CalendarEvent} event
 */
export function defineApprovalProperties(context, event) {
  const booking = event.booking;
  const reservationKey = event.reservationKey;
  Object.defineProperty(event, 'showEvent', {
    enumerable: true,
    get() {
      const notRejected = !event.rejected;
      const accepted = event.accepted;
      const requiresApproval = event.needsApproval;
      return notRejected && (accepted || requiresApproval);
    }
  });
  Object.defineProperty(event, 'accepted', {
    enumerable: true,
    get() {
      if (!event.owner) {
        // only events with owners need to be accepted; events with no owner only exist in a room calendar
        return true;
      }
      const connection = event.connection;
      if (connection) return event.connection.event.accepted;
      // check if the resource has M365 sync
      const bookedResource = event.resource;
      const m365SyncEnabled = Boolean(bookedResource &&
          bookedResource.thirdParty &&
          bookedResource.thirdParty.graph &&
          bookedResource.thirdParty.graph.uid);
      if (!m365SyncEnabled) {
        // without M365 sync accepted === approved
        return event.approved;
      }
      // bookings in resource calendars are automatically approved (or rather don't require approval)
      // we only get here if the M365 sync has given the event an owner, which is sometimes the room name only
      if (!_get(booking, 'owner.ref') && _get(booking, 'thirdParty.graph.organizerEvent.resource.ref')) {
        return true;
      }
      // with M365 sync, accepted is only true if the reservation has been approved
      return event.approval && event.approval.status === 'APPROVED';
    }
  });
  Object.defineProperty(event, 'needsApproval', {
    enumerable: true,
    get() {
      // bookings without owners do not need approval
      if (!event.ownerPrivacy.exists) {
        return false;
      }
      // check if the space requires approval
      const bookedResource = event.resource;
      return bookedResource && bookedResource.approval && bookedResource.approval.required;
    }
  });

  Object.defineProperty(event, 'approval', {
    enumerable: true,
    get() {
      const connection = event.connection;
      if (connection) return event.connection.event.approval;
      let approval = reservationValue(context, booking, reservationKey, 'approval');
      if (approval) {
        return approval;
      }
      // handle the case where `approval.status` and `approval.*Time` have been edited
      const status = reservationValue(context, booking, reservationKey, 'approval.status');
      if (status) {
        approval = {status};
        const timeProps = ['submittedTime', 'feedbackTime', 'approvedTime', 'rejectedTime'];
        for (const timeProp of timeProps) {
          const value = reservationValue(context, booking, reservationKey, `approval.${timeProp}`);
          if (value) {
            approval[timeProp] = value;
          }
        }
        return approval;
      }
      return reservationValue(context, booking, reservationKey, 'approval');
    },
    set(v) {
      if (v) {
        editReservationValue(context, booking, reservationKey, 'approval.status', 'APPROVED');
        editReservationValue(context, booking, reservationKey,
            'approval.approvedTime', firebase.firestore.FieldValue.serverTimestamp());
      } else {
        editReservationValue(context, booking, reservationKey, 'approval.status', 'SUBMITTED');
        editReservationValue(context, booking, reservationKey,
            'approval.submittedTime', firebase.firestore.FieldValue.serverTimestamp());
      }
    }
  });

  Object.defineProperty(event, 'approved', {
    enumerable: true,
    get() {
      if (event.rejected) {
        return false;
      }
      return !event.needsApproval || (event.approval && event.approval.status === 'APPROVED');
    }
  });

  Object.defineProperty(event, 'rejected', {
    enumerable: true,
    get() {
      return event.approval && event.approval.status === 'REJECTED';
    }
  });
}
