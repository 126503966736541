<template>
  <v-menu :close-on-content-click="false" bottom offset-y v-model="menuOpen">
    <template #activator="{on}">
      <v-tooltip bottom :disabled="mobile || menuOpen" open-delay="500">
        <template #activator="{on: onTooltip}">
          <v-btn icon text v-on="{...onTooltip, ...on}">
            <v-icon color="grey lighten-2">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <span>My Account</span>
      </v-tooltip>
    </template>

    <v-card width="300">
      <v-card-title class="justify-center">
        <v-icon size="48">mdi-account-circle</v-icon>
      </v-card-title>
      <v-card-text>
        <v-list dense class="pb-0 text-center">
          <v-list-item>
            <v-list-item-title class="font-weight-bold">{{ displayName || '(no name set)' }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>{{ userEmail }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="justify-center">
            <preferences @opened="menuOpen = false"/>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="logout" depressed block>Logout</v-btn>
      </v-card-actions>
      <about-line class="px-2 pb-2"/>
    </v-card>
  </v-menu>
</template>

<script>
import breakpoints from '@/plugins/breakpoints';
import {mapActions, mapGetters} from 'vuex';
import AboutLine from './AboutLine';
import Preferences from '@/components/preferences/Preferences';

export default {
  name: 'AccountMenu',
  components: {Preferences, AboutLine},
  mixins: [breakpoints],
  data() {
    return {
      menuOpen: false,
      reloading: false
    };
  },
  computed: {
    ...mapGetters('auth', ['userEmail']),
    ...mapGetters('user', ['displayName'])
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    logout() {
      this.signOut()
          .then(() => this.$router.push({name: 'login'}))
          .catch(() => this.$logger.error('route push aborted'));
    }
  }
};
</script>

<style scoped>

</style>
