<template>
  <div v-if="show">
    <svg :viewBox="viewBox">
      <clipPath id="layoutClip">
        <path :d="clipPath"/>
      </clipPath>
      <g clip-path="url(#layoutClip)">
        <clickable-circle :circles="allCircles" :height="height" :width="width"/>
      </g>
    </svg>
  </div>
</template>

<script>
import ClickableCircle from '@/views/desk-booking/settings/map/ClickableCircle';
import {mapGetters} from 'vuex';

export default {
  name: 'CirclesEdit',
  components: {ClickableCircle},
  props: {
    floor: {
      type: Object,
      default: null
    },
    viewBox: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 1920
    },
    width: {
      type: Number,
      default: 1080
    }
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/circles', ['allCirclesByFloor']),
    allCircles() {
      return this.allCirclesByFloor(this.floor);
    },
    config() {
      if (!this.show) {
        return null;
      }
      return this.floor.neighbourhoodCircles;
    },
    clipPath() {
      if (!this.config) {
        return '';
      }
      return this.config.clipPath;
    },
    showGrid() {
      return true;
    },
    show() {
      return this.floor && this.floor.neighbourhoodCircles;
    }
  }
};
</script>

<style scoped>

</style>
