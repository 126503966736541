<template>
  <div class="selection-layer">
    <div :style="rootStyles" :class="{transition}">
      <v-scale-transition origin="bottom center" group>
        <div
            v-for="marker in markers"
            :key="marker.id"
            :style="marker.styles"
            class="selection-layer--pin">
          <v-icon
              :color="color"
              large
              class="selection-layer--icon">
            mdi-map-marker
          </v-icon>
          <slot name="popup" v-bind="marker" class="foo"/>
        </div>
      </v-scale-transition>
    </div>
  </div>
</template>

<script>
import {deskCenter} from '@/store/svg-util';
import {mapState} from 'vuex';

/**
 * @typedef {Object} Marker
 * @property {string} id The desk id
 * @property {Object} styles Styles to apply to the marker
 */

export default {
  name: 'SelectionLayer',
  props: {
    selectedArr: {
      type: Array,
      default() {
        return [];
      }
    },
    color: {
      type: String,
      default: 'primary'
    },
    /** @type {Object.<string, DeskParts>|null} */
    desks: {
      type: Object,
      default: null
    },
    /** @type {{x:number,y:number,scale:number}|null} */
    transform: {
      type: Object,
      default: null
    },
    transition: {
      type: Boolean
    },
    independent: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('selection', ['selected']),
    selectedDesks() {
      if (!this.selectedArr) return this.selected;
      return this.selectedArr.map(d => d.ref);
    },
    /**
     * @return {Marker[]}
     */
    markers() {
      const res = [];
      const scale = (this.transform && this.transform.scale) || 1;
      for (const selection of this.selectedDesks) {
        const id = selection.id;
        if (!id) continue;
        const desk = this.desks[id];
        if (!desk) continue;
        const center = deskCenter(desk);
        if (!center) continue;
        const {x, y} = center;
        res.push({
          id,
          selection,
          styles: {
            left: x + 'px',
            top: y + 'px',
            transform: `scale(${1 / scale})`
          }
        });
      }
      return res;
    },
    rootStyles() {
      const res = {};
      if (!this.independent) {
        return res;
      }
      if (this.transform) {
        res.transform = `scale(${this.transform.scale}) translate(${this.transform.x}px, ${this.transform.y}px)`;
      }
      if (this.transition) {
        res.transition = 'transform 200ms ease-in-out 0s';
      } else {
        res.transition = 'none';
      }
      return res;
    }
  }
};
</script>

<style scoped>
  .selection-layer {
    user-select: none;
    touch-action: none;
    pointer-events: none;
  }

  .selection-layer > * {
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
  }

  .selection-layer--pin {
    cursor: initial;
    position: absolute;
    width: 0;
    height: 0;
    overflow: visible;
  }

  .transition .selection-layer--pin {
    transition: transform 200ms ease-in-out 0s;
  }

  .selection-layer--icon {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
    position: absolute;
    left: -18px;
    bottom: -3px;
  }

  .selection-layer--icon:after {
    content: '';
    position: absolute;
    top: initial;
    left: initial;
    bottom: 0;
    height: 4px;
    width: 8px;
    background: radial-gradient(rgba(0, 0, 0, 0.2), transparent);
    z-index: -1;
    opacity: 1;
  }
</style>
