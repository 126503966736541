<template>
  <v-form
      @submit.prevent="submit"
      class="light-input pb-4"
      ref="form">
    <v-row dense class="px-2">
      <v-col cols="12" class="title font-weight-regular">
        Name & Details
      </v-col>
    </v-row>
    <person-edit-about v-model="aboutInfo" :v="$v.person" class="px-2 pb-4"/>
    <v-divider/>
    <v-row class="px-2">
      <v-col cols="12" lg="6" class="pr-lg-6">
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Contact Information
          </v-col>
        </v-row>
        <v-row dense>
          <person-edit-contact
              title="Contact Information"
              :person-with-email="personWithEmail"
              v-model="contactInfo"
              :v="$v.person"/>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Additional Information
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <form-row label="Cost Centre">
              <v-text-field
                  v-bind="formInputProps('constCentre', 'Cost centre')"
                  v-model.trim="person.costCentre"/>
            </form-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row dense class="mt-3 pl-4 pr-3">
      <span class="body-2 grey--text">* required fields</span>
      <v-spacer/>
      <v-btn text @click="toPersonView" class="mr-4">Cancel</v-btn>
      <v-btn color="accent" type="submit" :disabled="$v.$invalid || $v.$pending || !isDifferent" :loading="$v.$pending">
        {{ newPerson ? 'Create' : 'Save' }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import {formInputProps} from '@/components/form/form';
import FormRow from '@/components/form/FormRow';
import PersonEditAbout from '@/components/person/edit/PersonEditAbout';
import PersonEditContact from '@/components/person/edit/PersonEditContact';
import tagMixin from '@/components/tag-mixin';
import {cloneDecoratedData} from '@/firebase';
import {emptyPerson} from '@/templates';
import debounce from 'debounce';
import firebase from 'firebase/app';
import {email, maxLength, required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';
import {isEqualWith, omitBy} from 'lodash';

export default {
  name: 'PersonEdit',
  components: {PersonEditContact, PersonEditAbout, FormRow},
  mixins: [tagMixin, formInputProps],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      person: {},
      uneditedPerson: {},
      personExists: false,
      membershipType: 'permanent',
      // a special field that is updated behind a debouncing function. We attach validation to this for unique emails
      // instead of the person.email field as vuelidate suggests any debouncing happens in our data methods, not in the
      // validator
      emailToCheck: null,
      personWithEmail: null,
      isDifferent: false
    };
  },
  validations: {
    // special validator that we use to async check for unique emails. The person.email validator looks at the results
    // of this
    emailToCheck: {
      async unique(val) {
        if (!val) {
          return true;
        }

        try {
          const peopleWithEmail = await this.peopleWithEmail(val);
          if (peopleWithEmail.length === 0) {
            // nobody has the email
            return true;
          }
          if (peopleWithEmail.length > 1) {
            // too many people have the email
            return false;
          }

          const other = peopleWithEmail[0];
          if (this.id !== other.id) {
            this.personWithEmail = other;
          }

          // one person, could be us
          if (this.newPerson) {
            // can't be us if we're creating a new person
            return false;
          }

          return this.id === other.id;
        } catch (e) {
          this.$logger.warn('error checking email', e);
          return true;
        }
      }
    },
    person: {
      title: {
        required,
        maxLength: maxLength(100)
      },
      displayName: {
        required,
        maxLength: maxLength(25)
      },
      position: {
        maxLength: maxLength(100)
      },
      department: {
        maxLength: maxLength(100)
      },
      email: {
        email,
        unique() {
          return this.$v.emailToCheck.$pending || this.$v.emailToCheck.unique;
        }
      },
      employer: {
        title: {
          maxLength: maxLength(100)
        }
      }
    }
  },
  computed: {
    ...mapGetters('views/person', {
      originalPerson: 'person'
    }),
    ...mapGetters('sites', ['activeSiteDoc']),
    newPerson() {
      return !this.id;
    },
    aboutInfo: {
      get() {
        return this.person;
      },
      set(v) {
        // be careful as employer has refs
        const oldEmployer = this.person.employer;

        // use vue set to trigger reactivity
        Object.keys(v).forEach(k => {
          this.$set(this.person, k, v[k]);
        });
        if (oldEmployer && oldEmployer.title === v.employer.title) {
          // keep the full employer reference if the title hasn't changed
          this.person.employer = oldEmployer;
        }
      }
    },
    contactInfo: {
      get() {
        return this.person;
      },
      set(v) {
        Object.keys(v).forEach(k => {
          this.$set(this.person, k, v[k]);
        });
      }
    }
  },
  watch: {
    ['person.email']: {
      handler: debounce(function(val) {
        // eslint-disable-next-line no-invalid-this
        this.emailToCheck = val;
      }, 500)
    },
    originalPerson: {
      // Don't need deep:true here as the backing model will replace the object on change anyway
      immediate: true,
      handler: 'handlePersonUpdate'
    },
    person: {
      deep: true,
      handler() {
        this.compareChanges();
      }
    }
  },
  created() {
    this.setUneditedPerson();
  },
  methods: {
    ...mapActions('views/person', ['updatePerson', 'addSiteMembership']),
    ...mapActions('views/people', ['peopleWithEmail']),
    addPhoneNumber() {
      if (!this.person.hasOwnProperty('phones') || !Array.isArray(this.person.phones)) {
        this.$set(this.person, 'phones', []);
      }
      this.person.phones.push({type: '', number: ''});
    },
    submit() {
      // validate data
      if (this.$refs.form.validate()) {
        this.removeEmptyPhoneEntries();

        // remove blank entries
        Object.keys(this.person).forEach(k => {
          const v = this.person[k];
          if (typeof v === 'string' && v === '' || v === null) {
            if (this.newPerson) {
              delete this.person[k];
            } else {
              this.person[k] = firebase.firestore.FieldValue.delete();
            }
          }
        });

        // add or update then redirect
        const existingId = this.person.id;
        const name = this.person.displayName || this.person.title;
        this.updatePerson(this.person)
            .then(async ref => {
              if (this.newPerson && this.activeSiteDoc) {
                // add site membership
                const person = this.person;
                if (!person.id) {
                }
                await this.addSiteMembership({
                  person: {ref, id: ref.id, ...this.person},
                  site: {ref: this.activeSiteDoc.ref},
                  options: {
                    type: this.membershipType
                  }
                });
              }
              return ref;
            })
            .then(ref => {
              this.$notify.showSuccess(`${name} ${this.newPerson ? 'added' : 'updated'} successfully`);
              const id = (ref && ref.id) || existingId;
              this.$router.push({name: 'person', params: {id: id}});
            })
            .catch((err) => {
              this.$logger.error(err);
              this.$notify.showError(`Error ${this.newPerson ? 'adding' : 'updating'} ${name}: ${err}`);
            });
      }
    },
    handlePersonUpdate(person) {
      // we could be better at this, we should really never allow changes. Except the first load is a change...!
      // For now, if the user has made any changes, don't update the model
      if (!this.$v.$anyDirty) {
        this.copyFields(person);
      } else {
        this.$logger.debug('Got change to person while we\'re editing', person);
      }
    },
    copyFields(person) {
      if (person) {
        if (person.exists) {
          this.person = cloneDecoratedData(person, true); // ensure we have no circular references, for deep watch
          this.removeEmptyPhoneEntries();
          this.addPhoneNumber();
          this.personExists = true;
        } else {
          this.personExists = false;
        }
      } else {
        this.personExists = true;
        this.person = JSON.parse(JSON.stringify(emptyPerson));
        this.removeEmptyPhoneEntries();
        this.addPhoneNumber();
      }
    },
    removeEmptyPhoneEntries() {
      // remove empty phone entries
      if (this.person.phones) {
        this.person.phones.forEach((entry, index) => {
          if (entry.number === '') {
            this.person.phones.splice(index, 1);
          }
        });
      }
    },
    toPersonView() {
      if (this.newPerson) {
        this.$router.back();
      } else {
        this.$router.push({
          name: 'person',
          params: {
            id: this.person.id
          }
        });
      }
    },
    setUneditedPerson() {
      // note: person may contain DocumentReferences, which contain a circular reference to firestore.
      // we use cloneDecoratedData which handle this (unlike, for example, lodash.cloneDeep)
      this.uneditedPerson = this.person.exists ? cloneDecoratedData(this.person, true) : {};
      this.isDifferent = false;
    },
    compareChanges() {
      const personProperties = Object.keys(this.person);
      const uneditedPersonProperties = Object.keys(this.uneditedPerson);
      const differentProperties = personProperties.filter(x => !uneditedPersonProperties.includes(x));

      if (differentProperties) {
        differentProperties.forEach(prop => {
          this.$set(this.uneditedPerson, prop, null);
        });
      }

      // compare objects but ignore properties with
      // '' value or null values or object only contains falsy values (for person.tags property)
      this.isDifferent = !isEqualWith(
          omitBy(this.person, (value) => value === '' || value === null ||
              Object.values(value).every(v => !v)),
          omitBy(this.uneditedPerson, (value) => value === '' || value === null ||
              Object.values(value).every(v => !v)));
    }
  }
};
</script>

<style scoped>
</style>
