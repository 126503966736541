<template>
  <v-container class="stats py-0" fluid>
    <v-toolbar flat height="56" class="toolbar">
      <h2 class="headline">
        Utilisation {{ utilisation.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2}) }}
      </h2>
      <v-spacer/>
      <vc-date-picker
          v-model="dateRange"
          mode="range"
          :popover="{ placement: 'bottom-end', visibility: 'click' }"
          class="date-picker"/>
    </v-toolbar>
    <g-chart v-if="bookingsStats.length > 0" :data="gChartData" v-bind="gChartBind"/>
    <v-progress-circular v-else-if="bookingsStatsLoading" indeterminate size="48" color="accent"/>
  </v-container>
</template>

<script>
import {decorateSnapshot} from '@/firebase';
import {capitalCase} from 'capital-case';
import moment from 'moment';
import {GChart} from 'vue-google-charts';
import {mapGetters} from 'vuex';

const dateRangeDivider = '--';

export default {
  name: 'Stats',
  components: {GChart},
  props: {
    onDate: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    // How chart results should be grouped. Default '' means reserved totals. Possible values are:
    // types: shows types of reserved space (desks vs rooms, etc)
    // bySpace - e.g. byFloor: shows locations of reserved spaces (floor1 vs floor2, etc)
    group: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      siteCountLoading: false,
      siteCount: null,
      siteCountError: null,
      bookingsStatsLoading: false,
      bookingsStats: [],
      bookingsStatsError: null
    };
  },
  computed: {
    ...mapGetters('sites', ['activeSiteDoc']),
    /**
     * @type {{start:Date, end:Date}}
     */
    dateRange: {
      get() {
        if (!this.onDate) {
          return null;
        }
        const parts = this.onDate.split(dateRangeDivider);
        if (parts.length === 1) {
          return {
            start: new Date(parts[0]),
            end: new Date(parts[0])
          };
        } else {
          return {
            start: new Date(parts[0]),
            end: new Date(parts[1])
          };
        }
      },
      /**
       * @param {Object} date
       * @param {Date} date.start
       * @param {Date} date.end
       */
      set({start, end}) {
        const startStr = start.toISOString().substr(0, '2020-01-01'.length);
        const endStr = end.toISOString().substr(0, '2020-01-01'.length);
        if (startStr === endStr) {
          this.changeDate(startStr);
        } else {
          this.changeDate(startStr + dateRangeDivider + endStr);
        }
      }
    },
    /**
     * Computes each day between the start and end dates from dateRange inclusive.
     *
     * @return {Date[]}
     */
    datesInRange() {
      const isRange = this.onDate.indexOf(dateRangeDivider) >= 0;
      if (isRange) {
        const dates = [];
        const [startStr, endStr] = this.onDate.split(dateRangeDivider);
        const startDate = new Date(startStr + 'T00:00:00Z');
        const endDate = new Date(endStr + 'T00:00:00Z');
        for (let t = startDate.getTime(); t <= endDate.getTime(); t += 24 * 60 * 60 * 1000) {
          dates.push(new Date(t));
        }
        return dates;
      } else {
        return [new Date(this.onDate)];
      }
    },
    /**
     * Return all of the bookings count document references for all days covered by dateRange.
     *
     * @return {firebase.firestore.DocumentReference[]}
     */
    bookingsCountDocRefs() {
      const dateToRef = date => this.activeSiteDoc.ref.collection('counts')
          .doc('bookings-' + date.toISOString().substr(0, '2020-01-01'.length));
      return this.datesInRange.map(date => dateToRef(date));
    },
    /**
     * The title for the chart.
     *
     * @return {string}
     */
    title() {
      const title = 'Space reservations for ' + this.activeSiteDoc.title;
      if (this.onDate.indexOf(dateRangeDivider) >= 0) {
        const [startStr, endStr] = this.onDate.split(dateRangeDivider);
        return title + ' between ' + moment(startStr).format('DD/MM/YYYY') + ' and ' +
            moment(endStr).format('DD/MM/YYY');
      } else {
        return title + ' on ' + moment(this.onDate).format('DD/MM/YYYY');
      }
    },

    /**
     * Decorates the list of booking count documents with the date string they are counting.
     *
     * @return {{bookings: Object, on: string}[]}
     */
    bookingsByDate() {
      return this.bookingsStats
          .map(doc => ({on: doc.id.substr('bookings-'.length), bookings: doc}));
    },

    // convert the delta based booking counts into totals at each time
    bookingTotals() {
      const result = [];
      let runningTotal = {};
      for (const {on, bookings: bs} of this.bookingsByDate) {
        if (result.length === 0) {
          if (bs.hasOwnProperty('initial')) {
            runningTotal = bs.initial;
          }
          result.push({on: new Date(on + 'T00:00:00Z'), totals: runningTotal});
        }
        for (const key of Object.keys(bs).sort()) {
          if (!key.startsWith('at')) {
            continue;
          }
          const timeStr = key.substr(2);
          const timeMs = Date.parse(on + 'T' + timeStr + 'Z');
          const change = bs[key];

          runningTotal = this.mergeChanges(runningTotal, change);
          result.push({on: new Date(timeMs), totals: runningTotal});
        }
      }

      // duplicate the last item to make the chart fully encompass the dates
      if (result.length > 0) {
        const lastTime = new Date(this.bookingsByDate[this.bookingsByDate.length - 1].on + 'T23:59:59.999Z');
        result.push({on: lastTime, totals: runningTotal});
      }
      return result;
    },

    // convert the booking totals into percentage usage based on the current site counts.
    bookingPercentages() {
      const result = [];
      if (this.siteCount) {
        const capacityScale = 1 / this.siteCount.capacity;
        for (const {on, totals} of this.bookingTotals) {
          result.push({on, totals: this.multiply(totals, capacityScale)});
        }
      }
      return result;
    },
    // gather the different types of groupings of data that are available in the date range.
    groups() {
      // capture all booked types
      const types = new Set();
      // capture all booked locations
      /** @type {Object.<string, Set>} */
      const spaceTypes = {};

      for (const count of this.bookingsStats) {
        for (const change of Object.values(count)) {
          for (const deltaKey of Object.keys(change)) {
            // look for properties like isDesk and byFloor.floor1
            if (deltaKey.startsWith('is')) {
              // this is a type - convert isMyType -> myType
              const typeName = deltaKey[2].toLowerCase() + deltaKey.substr(3);
              types.add(typeName);
            } else if (deltaKey.startsWith('by')) {
              // a space based group
              const spaceIds = spaceTypes[deltaKey] || (spaceTypes[deltaKey] = new Set());
              const spaces = Object.keys(change[deltaKey]);
              spaces.forEach(space => spaceIds.add(space));
            }
          }
        }
      }

      const result = {
        types: Array.from(types).sort()
      };
      Object.keys(spaceTypes).forEach(
          spaceType => result[spaceType] = Array.from(spaceTypes[spaceType]).sort());
      return result;
    },
    // the name and type of each data series that will be shown. Based on the selected group type and available data.
    gChartDataColumns() {
      const group = this.group;
      const groups = this.groups;

      if (groups[group]) {
        return groups[group].map(type => {
          const label = capitalCase(type);
          return {type: 'number', label};
        });
      }

      // default to showing just the total
      return [{type: 'number', label: 'Reserved'}];
    },
    /**
     * Calculates the data that will be rendered by google charts. Each item in the array is a row, and each item in
     * those rows is a column. The first row describes the column format.
     *
     * @return {Array<Array>}
     */
    gChartData() {
      /**
       * @type {*[][]}
       */
      const data = [
        [
          {type: 'datetime', label: 'Time of day'},
          ...this.gChartDataColumns,
          {type: 'string', role: 'tooltip'}
        ]
      ];

      let valuesFn = row => [row.reserved || 0];

      // work out which data to show
      const group = this.group;
      if (group === 'types') {
        const allTypes = this.groups.types;
        valuesFn = row => {
          const vals = [];
          allTypes.forEach(type => {
            const rowChange = row[`is${type[0].toUpperCase()}${type.substr(1)}`];
            if (!rowChange) {
              vals.push(null);
            } else {
              vals.push(rowChange.reserved || null);
            }
          });
          return vals;
        };
      } else if (group.startsWith('by')) {
        const all = this.groups[group];
        valuesFn = row => {
          const vals = [];
          all.forEach(type => {
            const rowChange = row[group] && row[group][type];
            if (!rowChange) {
              vals.push(null);
            } else {
              vals.push(rowChange.reserved || null);
            }
          });
          return vals;
        };
      }

      for (const {on, totals} of this.bookingPercentages) {
        data.push([on, ...valuesFn(totals), '']);
      }

      // when using StackedAreaChart the bar heights are drawn based on the end of the segment instead of the beginning
      // so we adjust the data to match
      for (let i = data.length - 1; i > 1; i--) {
        data[i][1] = data[i - 1][1];
      }

      // add tooltips to the data entries
      for (let i = 2; i < data.length; i++) {
        const prevRow = data[i - 1];
        const row = data[i];
        const startTime = prevRow[0].toLocaleTimeString(undefined, {timeStyle: 'short'});
        const endTime = row[0].toLocaleTimeString(undefined, {timeStyle: 'short'});
        const rows = [];
        for (let j = 1; j < row.length - 1; j++) {
          const value = row[j];
          let valueStr = `${data[0][j].label}: `;
          if (value !== null && typeof value !== 'undefined') {
            valueStr += value.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 1});
          }
          rows.push(valueStr);
        }

        row[row.length - 1] = `Between ${startTime} and ${endTime}\n${rows.join('\n')}`;
      }

      return data;
    },
    // Configuration options for the chart. Unlikely to change dynamically but easier to read here.
    gChartBind() {
      return {
        settings: {
          packages: ['corechart']
        },
        options: {
          height: '400',
          title: this.title,
          animation: {
            duration: 200,
            easing: 'inAndOut'
          },
          hAxis: {},
          vAxis: {
            format: 'percent',
            maxValue: 1,
            minValue: 0
          },
          connectSteps: false,
          isStacked: true,
          legend: {
            position: 'bottom'
          }
        },
        // material version of the google line chart
        createChart: (el, google) => new google.visualization.SteppedAreaChart(el)
      };
    },

    // calculate the total utilisation value for the time period in question.
    utilisation() {
      if (!this.totalAvailableTimeMs) {
        return 0;
      }
      return this.totalReservedTimeMs / this.totalAvailableTimeMs;
    },

    // how much space+time in milliseconds has been reserved over the current date range.
    totalReservedTimeMs() {
      const bookingsByDate = this.bookingsByDate;
      if (bookingsByDate.length === 0) {
        return 0;
      }

      let total = 0;
      for (const {on: date, bookings: bs} of bookingsByDate) {
        // the total time reserved is equivalent to the area under the graph of utilisation per time
        let lastValue = 0;
        let lastTime = Date.parse(date + 'T00:00:00Z');
        const endOfDay = lastTime + 24 * 60 * 60 * 1000;
        if (bs.hasOwnProperty('initial')) {
          lastValue = bs.initial.reserved;
        }

        const timeKeys = Object.keys(bs).filter(key => key !== 'initial' && key.substr(0, 2) === 'at')
            .sort();
        for (const timeKey of timeKeys) {
          const countAtTime = bs[timeKey];
          const newTime = Date.parse(date + 'T' + timeKey.substr(2) + 'Z');
          const newValue = lastValue + countAtTime.reserved;
          const timeSinceLast = newTime - lastTime;

          total += lastValue * timeSinceLast;
          lastValue = newValue;
          lastTime = newTime;
        }

        // don't forget to account for the time after the last change
        if (lastValue > 0 && lastTime < endOfDay) {
          total += lastValue * (endOfDay - lastTime);
        }
      }

      return total;
    },

    // how much space+time is typically available during the days present in the current date range.
    totalAvailableTimeMs() {
      if (!this.siteCount) {
        return 0;
      }
      const msPerWorkWeek = 8 * 60 * 60 * 1000; // 8-hour day
      const availableCapacity = this.siteCount.capacity;
      const [start, end] = this.onDate.split(dateRangeDivider);
      if (end && start !== end) {
        return msPerWorkWeek * moment(end).diff(moment(start), 'days') * availableCapacity;
      }
      return msPerWorkWeek * availableCapacity;
    }
  },
  watch: {
    bookingsCountDocRefs: {
      immediate: true,
      handler: 'loadBookingsCounts'
    },
    activeSiteDoc: {
      immediate: true,
      handler: 'onActiveSiteChanged'
    }
  },
  methods: {
    changeDate(newDate) {
      if (newDate !== this.onDate) {
        this.$router.push({query: {...this.$route.query, onDate: newDate}});
      } else {
        this.loadBookingsCounts(this.bookingsCountDocRefs);
      }
    },
    /**
     * @param {firebase.firestore.DocumentSnapshot} siteDoc
     * @return {Promise<void>}
     */
    async onActiveSiteChanged(siteDoc) {
      if (!siteDoc) {
        this.siteCountError = null;
        this.siteCount = null;
      }

      this.siteCountLoading = true;
      try {
        const snap = await siteDoc.ref.collection('counts').doc('site').get();
        if (snap.exists) {
          this.siteCount = snap.data();
        } else {
          this.siteCount = {};
        }
        this.siteCountError = null;
      } catch (e) {
        this.siteCountError = e;
      } finally {
        this.siteCountLoading = false;
      }
    },
    /**
     * @param {firebase.firestore.DocumentReference[]} refs
     * @return {Promise<void>}
     */
    async loadBookingsCounts(refs) {
      if (refs.length === 0) {
        this.bookingsStatsError = null;
        this.bookingsStats = [];
        return;
      }

      this.bookingsStatsLoading = true;
      try {
        const newDocs = [];
        const allDocs = await Promise.all(refs.map(ref => ref.get()));
        for (const doc of allDocs) {
          if (doc.exists) {
            newDocs.push(decorateSnapshot(doc));
          }
        }
        this.bookingsStats = newDocs;
        this.bookingsStatsError = null;
      } catch (e) {
        this.bookingsStatsError = e;
      } finally {
        this.bookingsStatsLoading = false;
      }
    },

    /**
     * Merges two values with the following rules:
     * 1. If the values are both numbers, add them together
     * 2. If the values are both objects, union the object properties with any shared keys merging
     * 3. Else value the second value overwrites the first
     *
     * @param {*} c1
     * @param {*} c2
     * @return {*}
     */
    mergeChanges(c1, c2) {
      if (typeof c1 === 'number' && typeof c2 === 'number') {
        return c1 + c2;
      }
      if (c1 === null || c2 === null) {
        return c2;
      }
      if (typeof c1 === 'object' && typeof c2 === 'object') {
        const res = Object.assign({}, c1, c2);
        Object.keys(c1).forEach(key => {
          if (c2.hasOwnProperty(key)) {
            res[key] = this.mergeChanges(c1[key], c2[key]);
          }
        });
        return res;
      }
      return c2;
    },

    multiply(obj, val) {
      if (typeof obj === 'number') {
        return obj * val;
      }
      if (obj !== null && typeof obj === 'object') {
        const res = {};
        Object.keys(obj).forEach(key => {
          res[key] = this.multiply(obj[key], val);
        });
        return res;
      }
      return obj;
    }
  }
};
</script>

<style scoped>
  .toolbar {
    border-bottom: var(--v-grey-base) 1px solid;
    /* These position our popup above the svg element */
    position: relative;
    z-index: 1;
  }

  .date-picker {
    width: 300px;
  }
</style>
