<template>
  <div>
    <template v-if="created">
      <span>Card created</span>
      <template v-if="ownerChange">
        <span>, and assigned to </span>
        <person-link :person="owner"/>
      </template>
    </template>
    <template v-else-if="ownerChange">
      <template v-if="newOwner && previousOwner">
        <span>Transferred from </span>
        <person-link :person="previousOwner"/>
        <span> to </span>
        <person-link :person="owner"/>
      </template>
      <template v-else>
        <span>{{ ownerUnassigned ? 'Unassigned from ' : 'Assigned to ' }}</span>
        <person-link :person="owner"/>
      </template>
    </template>
    <template v-else-if="disabledChange">
      <span v-if="!before.disabled && after.disabled">Card disabled</span>
      <span v-if="before.disabled && !after.disabled">Card enabled</span>
    </template>
    <template v-else-if="roleChange">
      <span>Changed to a {{ after.role }} card</span>
    </template>
    <template v-else-if="validityChange">
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="on">
            <span v-if="paths.includes('enabledFrom') && paths.includes('enabledTo')">
              Validity range {{ after.enabledFrom | moment('L LT') }} - {{ after.enabledTo | moment('L LT') }}
            </span>
            <span v-else-if="paths.includes('enabledFrom')">
              Valid from {{ after.enabledFrom | moment('L LT') }}
            </span>
            <span v-else>
              Valid to {{ after.enabledTo | moment('L LT') }}
            </span>
          </span>
        </template>
        <span>Changed from </span>
        <span v-if="paths.includes('enabledFrom') && paths.includes('enabledTo')">
          {{ before.enabledFrom | moment('L LT') }} - {{ before.enabledTo | moment('L LT') }}
        </span>
        <span v-else-if="paths.includes('enabledFrom')">
          {{ before.enabledFrom | moment('L LT') }}
        </span>
        <span v-else>
          {{ before.enabledTo | moment('L LT') }}
        </span>
      </v-tooltip>
    </template>
    <template v-else-if="titleChange">
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="on">Card name changed to {{ after.title }}</span>
        </template>
        <span>Changed from {{ before.title }}</span>
      </v-tooltip>
    </template>
    <template v-else-if="reasonChange">
      <span>Reason updated to {{ after.updateReason }}</span>
    </template>
    <template v-else-if="tagsChange">
      <v-chip v-for="t in tagChanges" :key="t.tag" class="mr-1 text-capitalize" small>
        <v-icon left small>{{ t.added ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
        {{ t.tag }}
      </v-chip>
    </template>
    <template v-else-if="nonTransferableChange">
      <span>Card is now <em>{{ after.nonTransferable ? 'non-transferable' : 'transferable' }}</em></span>
    </template>
    <template v-else>
      <span>Unknown change</span>
    </template>
  </div>
</template>

<script>
import PersonLink from '@/components/person/PersonLink';
import {hasNewOwner, hasPreviousOwner} from '@/views/people/card/card-history';

export default {
  name: 'CardHistoryChange',
  components: {PersonLink},
  filters: {
    personsName(person) {
      if (person.displayName) {
        return person.displayName;
      }
      if (person.title) {
        return person.title;
      }
      return person.ref.id;
    }
  },
  props: {
    change: {
      type: Object,
      required: true
    }
  },
  computed: {
    after() {
      if (!this.change) {
        return {};
      }
      return this.change.change.after;
    },
    before() {
      if (!this.change) {
        return {};
      }
      return this.change.change.before;
    },
    changeDetail() {
      if (!this.change) {
        return {};
      }
      return this.change.change;
    },
    paths() {
      if (!this.change) {
        return {};
      }
      return this.change.paths;
    },

    ownerChange() {
      return this.paths.includes('owner.ref');
    },
    previousOwner() {
      if (hasPreviousOwner(this.changeDetail)) {
        return this.before.owner;
      }
      return null;
    },
    newOwner() {
      if (hasNewOwner(this.changeDetail)) {
        return this.after.owner;
      }
      return null;
    },
    ownerUnassigned() {
      return this.previousOwner && !this.newOwner;
    },
    owner() {
      return this.ownerUnassigned ? this.previousOwner : this.newOwner;
    },

    created() {
      return this.after && !this.before;
    },
    disabledChange() {
      return this.paths.includes('disabled');
    },
    roleChange() {
      return this.paths.includes('role');
    },
    tagsChange() {
      return this.paths.some(p => p.startsWith('tags'));
    },
    tagChanges() {
      const changes = [];
      if (!this.after.hasOwnProperty('tags')) {
        return tags;
      }
      const tags = this.after.tags;
      for (const tag of Object.keys(tags)) {
        changes.push({
          tag,
          added: tags[tag]
        });
      }
      return changes;
    },
    titleChange() {
      return this.paths.includes('title');
    },
    reasonChange() {
      return this.paths.includes('updateReason');
    },
    validityChange() {
      return this.paths.includes('enabledFrom') || this.paths.includes('enabledTo');
    },
    nonTransferableChange() {
      return this.paths.includes('nonTransferable');
    }
  }
};
</script>

<style scoped>
  .strikeout {
    text-decoration: line-through;
  }
</style>
