import {byId} from '@/store/firestore-util';
import {createSnippet, db} from '@/firebase';
import firebase from 'firebase/app';
import {editedValue, editValue, resetEditValue} from '@/util/editable-util';
import nested from 'nested-property';

/**
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData & kahu.firestore.Named} desk
 *
 * @return {EditableDesk}
 */
export function toEditableDesk(context, desk) {
  const editableDesk = /** @type {EditableDesk} */ {
    ref: desk.ref,
    id: byId(desk)
  };

  Object.defineProperty(editableDesk, 'title', {
    enumerable: true,
    get() {
      return desk.title;
    }
  });

  Object.defineProperty(editableDesk, 'neighbourhood', {
    enumerable: true,
    get() {
      return editedValue(context, desk, 'neighbourhood');
    },
    set(v) {
      const original = nested.get(desk, 'neighbourhood');
      const matchesOriginal = (!original && !v) || original && v && original.ref.isEqual(v.ref);
      if (matchesOriginal) {
        editableDesk.resetNeighbourhood();
      } else {
        if (original) {
          context.commit('recordRemoval', {neighbourhood: original, desk: editableDesk});
        }
        const n = v ? createSnippet(v, ['title']) : firebase.firestore.FieldValue.delete();
        if (v) {
          // only record an addition if we're not deleting the neighbourhood
          context.commit('recordAddition', {neighbourhood: n, desk: editableDesk});
        }
        editValue(context, desk, 'neighbourhood', n);
      }
    }
  });

  Object.defineProperty(editableDesk, 'hasNeighbourhood', {
    enumerable: true,
    get() {
      const n = editableDesk.neighbourhood;
      return Boolean(n && n.ref); // FieldValue.delete() won't have a ref
    }
  });

  editableDesk.isInNeighbourhood = neighbourhood => {
    const existing = editableDesk.neighbourhood;
    // existing won't have a ref if its value is FieldValue.delete()
    return existing && existing.ref && existing.ref.isEqual(neighbourhood && neighbourhood.ref);
  };

  editableDesk.resetNeighbourhood = () => {
    resetEditValue(context, desk, 'neighbourhood');
    context.commit('removeDeskFromArrays', {desk});
  };

  Object.defineProperty(editableDesk, 'disabled', {
    enumerable: true,
    get() {
      return editedValue(context, desk, 'disabled');
    },
    set(v) {
      const original = nested.get(desk, 'disabled');
      if (original === v || (original === undefined && !v)) {
        resetEditValue(context, desk, 'disabled');
      } else {
        editValue(context, desk, 'disabled', v);
      }
    }
  });

  // edit handling
  editableDesk.commit = batch => {
    let weMadeTheBatch = false;
    return /** @type {Promise<void>} */ Promise.resolve(batch).then(batch => {
      // reuse the passed in batch, or we don't need one
      if (batch) return batch;

      // need to make a new batch
      weMadeTheBatch = true;
      return db.then(_db => _db.batch());
    }).then(batch => {
      // interact with firestore to save the edits
      const tasks = [];
      tasks.push(context.dispatch('commitEdit', {desk, batch}));
      return Promise.all(tasks).then(() => batch);
    }).then(batch => {
      // if this was a batch that we made, then commit it
      if (weMadeTheBatch) return batch.commit();
      return null;
    });
  };
  editableDesk.reset = () => {
    context.commit('resetEdit', editableDesk);
  };
  Object.defineProperty(editableDesk, 'edited', {
    enumerable: true,
    get() {
      const edit = context.state.edits[byId(desk)] || {};
      return Object.keys(edit).length > 0;
    }
  });

  return editableDesk;
}
