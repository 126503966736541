import {cloneDecoratedData, decorateSnapshot} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import FirestoreUtil, {byRef} from '@/store/firestore-util';
import {Logger} from '@vanti/vue-logger';
import firebase from 'firebase/app';
import moment from 'moment';
import {scheduleTicks} from '@/util/schedule';
import {currentDate, minDate, toDate} from '@/util/dates';

const log = Logger.get('bookings');

export default {
  namespaced: true,
  state: {
    byRef: {},
    maxMeetingLengthMs: 24 * 60 * 60 * 1000, // 24hrs
    queryWindowMs: 12 * 60 * 60 * 1000, // 12hrs
    queryWindowOverlapMs: 15 * 60 * 1000, // 15min
    /**
     * The current time at a resolution that matters for calculating active bookings.
     *
     * @type {Date}
     */
    time: new Date()
  },
  getters: {
    knownBookings(state) {
      return Object.values(state.byRef);
    },
    activeBookings(state, getters) {
      const now = state.time;
      return getters.knownBookings.filter(b => {
        return b.fromTime.toDate() <= now && (!b.toTime || b.toTime.toDate() > now);
      });
    },
    /**
     * Returns a sorted (ascending) array of all the fromTime and toTimes for all known bookings. This is used to
     * calculate when we need to 'wake up' to change our state to correctly represent the active bookings.
     *
     * @param {Object} state
     * @return {Date[]}
     */
    changeSchedule(state) {
      // impl notes:
      // 1: Date doesn't play nicely with Set, it appears to be compared based on object identity so we use getTime
      // 2: Numbers aren't sorted correctly in arrays as they are 'converted to strings' before sorting
      const changes = new Set();
      Object.values(state.byRef).forEach(b => {
        if (b.fromTime) {
          changes.add(b.fromTime.toMillis());
        }
        if (b.toTime) {
          changes.add(b.toTime.toMillis());
        }
      });
      return Array.from(changes).sort((a, b) => a - b).map(t => new Date(t));
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    applyBookingSnapshotUpdate(state, querySnapshot) {
      FirestoreUtil.indexQuerySnapshotUpdates(state.byRef, querySnapshot, byRef);
    },
    clear(state) {
      state.byRef = {};
    },
    setTime(state, time) {
      state.time = time;
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (!authUser) {
        //   return dispatch('bind');
        // } else {
          return dispatch('unbind');
        }
      }
    },
    async bind({commit, state, dispatch, getters, rootGetters}) {
      if (DeferUtil.hasDefer(state)) {
        // already bound
        return;
      }

      const defer = {};
      defer.siteChanged = this.watch(() => rootGetters['sites/activeSiteDoc'], activeSite => {
        log.debug('handle site change');
        if (!activeSite) {
          return dispatch('unbindBookings');
        }

        const defer = {};
        dispatch('bindBookings', {activeSite, nowMs: Date.now()})
            .catch(err => log.error('During bindBookings refresh', err));

        const repeatQueryHandle = setInterval(() => {
          dispatch('bindBookings', {activeSite, nowMs: Date.now()})
              .catch(err => log.error('During bindBookings refresh', err));
        }, state.queryWindowMs);
        defer.repeatQueryHandle = () => clearTimeout(repeatQueryHandle);

        defer.changeSchedule = this.watch(
            () => getters.changeSchedule,
            () => dispatch('scheduleChanges', () => new Date()),
            {immediate: true});

        commit('defer', defer);
      });
      commit('defer', defer);
    },

    async unbind({commit}) {
      commit('reset');
      commit('clear');
      commit('reset', ['repeatQueryHandle', 'bookings']);
    },

    async bindBookings({state, commit, dispatch, rootGetters}, {activeSite, nowMs}) {
      if (!activeSite) {
        return;
      }

      const startAfter = firebase.firestore.Timestamp.fromMillis(nowMs - state.maxMeetingLengthMs);
      const startBefore = firebase.firestore.Timestamp.fromMillis(
          nowMs + state.queryWindowMs + state.queryWindowOverlapMs);

      const query = activeSite.ref.collection('bookings')
          .where('fromTime', '>=', startAfter)
          .where('fromTime', '<', startBefore)
          .orderBy('fromTime');

      const defer = {};
      defer.bookings = query.onSnapshot(
          snap => commit('applyBookingSnapshotUpdate', FirestoreUtil.prepareQuerySnapshot(snap)),
          err => log.error('bookings.onSnapshot', err)
      );
      commit('defer', defer);
    },

    unbindBookings({commit}) {
      commit('reset', ['repeatQueryHandle', 'bookings']);
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {Date} payload.start
     * @param {Date} payload.end
     * @param {number} payload.limit
     * @return {Promise<DecoratedData[]>}
     */
    async fetchBookingsFor({rootGetters}, {start, end, limit = 5000}) {
      const activeSite = rootGetters['sites/activeSiteDoc'];
      if (!activeSite) {
        throw new Error(`no active site selected`);
      }

      const startAfter = firebase.firestore.Timestamp.fromDate(start);
      const startBefore = firebase.firestore.Timestamp.fromDate(end);

      const query = activeSite.ref.collection('bookings')
          .where('fromTime', '>=', startAfter)
          .where('fromTime', '<', startBefore)
          .orderBy('fromTime')
          .limit(limit);

      const snap = await query.get();
      return snap.docs.map(d => decorateSnapshot(d));
    },

    /**
     * Release or cancel a booking.
     * A function is returned to undo the action
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {DecoratedData} payload.booking
     * @param {boolean} payload.release
     * @return {Promise<function()>}
     */
    async releaseOrCancel({dispatch}, {booking, release}) {
      if (release) {
        const update = {};
        const undo = {};
        // edit the end time
        update.toTime = firebase.firestore.Timestamp.now();
        undo.toTime = booking.toTime ? booking.toTime : firebase.firestore.FieldValue.delete();
        // check out if needed
        if (booking.checkIn && booking.checkIn.fromTime) {
          update['checkIn.toTime'] = update.toTime;
          undo['checkIn.toTime'] = firebase.firestore.FieldValue.delete();
        }
        await booking.ref.update(update);

        // return an undo action
        return () => booking.ref.update(undo);
      } else {
        const copy = cloneDecoratedData(booking);
        const collection = booking.ref.parent;
        const id = booking.ref.id;
        // remove booking
        await booking.ref.delete();

        // return an undo action
        return () => collection.doc(id).set(copy);
      }
    },
    /**
     * Sets up a chain of setTimeouts that update the state.time property based on the changeSchedule. This has the
     * effect of allowing getters and computed properties to rely on state.time to calculate active bookings without
     * needing to manage state separately.
     *
     * @param {import('vuex').ActionContext} context
     * @param {function():Date} now
     */
    scheduleChanges({getters, commit}, now) {
      commit('reset', ['schedule']);
      const changeSchedule = getters.changeSchedule;
      const n = changeSchedule.length;
      let i = 0;
      const scheduleNext = time => {
        commit('setTime', time);

        // find the first time that is after now AND after the last time we ran
        let nextTick = null;
        for (; i < n; i++) {
          if (changeSchedule[i] > time) {
            nextTick = changeSchedule[i];
            break;
          }
        }
        if (nextTick) {
          const delay = nextTick - time;
          const timeout = setTimeout(() => {
            scheduleNext(now());
          }, delay);
          commit('defer', {
            schedule() {
              clearTimeout(timeout);
            }
          });
        }
      };
      scheduleNext(now());
    }
  },

  modules: {
    forSelectedDesk: {
      namespaced: true,
      state: {
        loading: false,
        bookings: [],
        now: new Date()
      },
      getters: {
        loading(state) {
          return state.loading;
        },
        pendingBookings(state) {
          return state.bookings.filter(b => {
            const end = minDate(b.toTime, b.checkIn && b.checkIn.toTime);
            return !end || end > state.now;
          });
        },
        changeSchedule(state) {
          const res = new Set();
          const add = d => {
            d = toDate(d);
            if (d) res.add(d.getTime());
          };
          state.bookings.forEach(b => {
            add(b.fromTime);
            add(b.toTime);
            if (b.checkIn) {
              add(b.checkIn.fromTime);
              add(b.checkIn.toTime);
            }
          });
          return Array.from(res).sort().map(t => new Date(t));
        }
      },
      mutations: {
        ...DeferUtil.mutations(log),
        applyQuerySnapshot(state, snapshot) {
          FirestoreUtil.applyQuerySnapshotUpdates(state, 'bookings', snapshot);
        },
        clear(state) {
          state.bookings = [];
        },
        setTime(state, time) {
          state.now = time;
        },
        setLoading(state, loading) {
          state.loading = loading;
        }
      },
      actions: {
        onAuthStateChanged: {
          root: true,
          handler({dispatch}, authUser) {
            if (!authUser) {
              dispatch('unbind');
            }
          }
        },
        init: {
          root: true,
          handler({dispatch, rootGetters, getters}) {
            this.watch(
                () => rootGetters['selection/lastSelected'],
                selected => dispatch('bindSpace', selected),
                {immediate: true}
            );

            this.watch(
                () => getters.changeSchedule,
                () => dispatch('scheduleChanges', currentDate),
                {immediate: true}
            );
          }
        },
        bindSpace({state, commit}, space) {
          const newRef = byRef(space);
          commit('reset');
          commit('clear');
          if (!newRef) return; // nothing to bind to

          /** @type {firebase.firestore.DocumentReference} */
          const spaceRef = space.ref;
          if (!spaceRef) return;

          const now = moment();
          const from = moment.min(now.startOf('day'), now.subtract(6, 'hours'));
          const query = spaceRef.parent.parent.collection('bookings')
              .where('space.ref', '==', spaceRef)
              .where('fromTime', '>=', from.toDate())
              .orderBy('fromTime');

          commit('setLoading', true);
          const stop = query.onSnapshot(
              snap => {
                commit('applyQuerySnapshot', FirestoreUtil.prepareQuerySnapshot(snap));
                if (state.loading) commit('setLoading', false);
              },
              err => {
                log.error('during selected desk booking query', err);
                if (state.loading) commit('setLoading', false);
              }
          );

          commit('defer', {
            stop
          });
        },
        unbind({commit}) {
          commit('reset');
          commit('clear');
        },
        scheduleChanges({getters, commit}, now) {
          commit('defer', {
            scheduleChanges: scheduleTicks(getters.changeSchedule, d => commit('setTime', d))
          });
        }
      }
    }
  }
};
