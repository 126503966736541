<template>
  <div style="height: 100%;width: 100%">
    <v-progress-circular
        style="width: 100%!important;height: 100%!important;"
        :rotate="90"
        :width="2"
        :value="value"
        :color="color">
      <div class="text-center">
        <v-icon size="54" :color="color">{{ icon }}</v-icon>
        <h2 class="black--text">{{ value }}%</h2>
      </div>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'LiveStatusChart',
  props: {
    icon: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: '0.00'
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style scoped>
.v-progress-circular >>> svg {
  transform: rotate(90deg) scaleY(-1)!important;
}

.v-progress-circular >>> svg .v-progress-circular__underlay {
  stroke: var(--v-grey-lighten5);
}
</style>
