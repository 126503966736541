<template>
  <v-menu offset-y :close-on-content-click="false" v-model="open">
    <template #activator="{ on }">
      <v-tooltip bottom>
        <template #activator="{on: toolTipOn}">
          <v-btn icon v-on="{...on, ...toolTipOn}">
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        Select fields for export
      </v-tooltip>
    </template>

    <v-card width="350">
      <v-select
          v-model="selectedTz"
          :items="timezones"
          label="Export time zone"
          class="timezone-picker"/>
      <v-divider/>
      <v-card-text class="pa-0">
        <v-list subheader>
          <template v-for="(value, key, i) in headers">
            <v-divider :key="`divider-${i}`"/>
            <v-subheader :key="`subheader-${i}`">{{ key }}</v-subheader>

            <v-list-item-group
                v-model="model"
                :key="key"
                multiple>
              <template v-for="(item, index) in value">
                <v-list-item
                    :key="`item-${index}`"
                    :value="item"
                    active-class="primary--text text--accent-4"
                    class="pl-8">
                  <template #default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="primary accent-4"/>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="reset" outlined>Reset</v-btn>
        <v-btn @click="save" color="accent" depressed>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapMutations, mapState, mapGetters} from 'vuex';

export default {
  name: 'BookingsExportOptions',
  props: {
    headers: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      open: false,
      model: [],
      selectedTz: 'Europe/London'
    };
  },
  computed: {
    ...mapState('views/deskBooking/exportBookings', ['selectedHeaders', 'exportTz']),
    ...mapGetters('sites', ['activeSiteDoc']),
    timezones() {
      return ['Europe/London', 'UTC', this.siteTz, this.browserTz].sort();
    },
    siteTz() {
      return this.activeSiteDoc.config.timeZone || 'Europe/London';
    },
    browserTz() {
      // eslint-disable-next-line new-cap
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
  watch: {
    selectedHeaders: {
      handler: function(val) {
        this.model = val;
      },
      deep: true
    },
    siteTz: {
      handler: function(val) {
        this.updateExportTz(val);
      }
    },
    exportTz: {
      handler: function(val) {
        this.selectedTz = val;
      }
    }
  },
  mounted() {
    this.model = this.selectedHeaders;
    this.updateExportTz(this.siteTz);
  },
  methods: {
    ...mapMutations('views/deskBooking/exportBookings', [
      'updateSelectedHeaders',
      'selectAllHeaders',
      'updateExportTz'
    ]),

    save() {
      if (this.model === undefined || this.model.length == 0) {
        this.close();
        return;
      }
      this.updateExportTz(this.selectedTz);
      this.updateSelectedHeaders(this.model);
      this.close();
    },
    close() {
      this.open = !this.open;
    },
    reset() {
      this.model = this.selectedHeaders;
      this.selectAllHeaders();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  background: #fff;
}
.timezone-picker {
  margin-top: 0px;
  padding: 30px 15px 0 15px;
}
</style>
