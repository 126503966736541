import {repeat} from '@/views/dashboard/statistics/stats-util';

/**
 * @typedef {*} GraphOptions
 */


/**
 * @param {GraphOptions} options
 * @param {string} y
 * @param {string} [x]
 * @return {GraphOptions}
 */
export function withAxisTitles(options, y, x = 'Time Scale') {
  const style = {
    fontFamily: 'Open Sans", sans-serif',
    fontSize: '16px'
  };
  options.xaxis.title = {
    text: x,
    style
  };
  if (Array.isArray(options.yaxis)) {
    options.yaxis[0].title = {
      text: y,
      style
    };
  } else {
    options.yaxis.title = {
      text: y,
      style
    };
  }
  return options;
}

/**
 * @param {GraphOptions} options
 * @param {string} filename
 * @return {GraphOptions}
 */
export function withFileName(options, filename) {
  const exp = options.chart.toolbar.export;
  exp.csv.filename = exp.csv.filename.replace('graph_', filename);
  exp.png.filename = exp.csv.filename.replace('graph_', filename);
  exp.svg.filename = exp.csv.filename.replace('graph_', filename);
  return options;
}

/**
 * @param {GraphOptions} options
 * @param {number} bars
 * @param {number} lines
 * @return {GraphOptions}
 */
export function withTrendLines(options, bars = 2, lines = 2) {
  const colors = options.colors.slice(0, bars);
  options.colors = [
    ...colors,
    ...colors
  ];
  options.chart.type = 'line';
  options.markers = {
    size: [
      ...repeat(() => 0, 2),
      ...repeat(() => 5, lines)
    ]
  };
  options.stroke = {
    curve: 'smooth',
    width: [
      ...repeat(() => 0, 2),
      ...repeat(() => 3, lines)
    ]
  };
  return options;
}

/**
 * @param {GraphOptions} options
 * @return {GraphOptions}
 */
export function asCountGraph(options) {
  options.chart.type = 'bar';
  options.chart.stacked = true;
  options.markers = {size: 0};
  options.stroke = {show: false};
  options.xaxis.tickPlacement = 'on';
  options.yaxis.forceNiceScale = true;
  options.yaxis.labels = {
    formatter: value => value
  };
  options.tooltip.y = {
    formatter: value => value
  };
  return options;
}
