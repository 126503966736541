import {Logger} from '@vanti/vue-logger';
import {app} from '@/firebase';
import configDefaults from './app-config-defaults.json';
import siteConfigDefaults from './app-site-config-defaults.json';

const log = Logger.get('app-config');

/** @type {firebase.remoteConfig.RemoteConfig} */
let remoteConfig;

/**
 * See https://firebase.google.com/docs/remote-config
 * Firebase Remote Config is used to set feature flags in the app. A
 * single config value is used, stored as stringified JSON.
 *
 * `app-config-defaults.json` defines the default config, if none
 * can be fetched from the server.
 *
 * The config from the server is fetched on load, see
 * `plugins/fetch-app-config.js`
 */
export default {
  namespaced: true,
  state: {
    initialised: false,
    loaded: false, // fetched config from server
    features: configDefaults,
    defaultSiteConfig: siteConfigDefaults,
    permissions: [
      {
        v: 'deskBooking',
        t: 'Desk Booking',
        h: 'Allow ${name} to use the desk booking system',
        children: [
          {
            v: 'multiDeskBooking',
            t: 'Allow multiple desks',
            h: 'Allow ${name} to book multiple desks at the same time'
          },
          {
            v: 'advancedDeskBooking',
            t: 'In advance',
            h: 'Allow ${name} to book a desk in advance',
            children: [
              {
                v: 'advancedDeskBookingOtherNeighbourhoods',
                t: 'In other neighbourhoods',
                h: 'Allow ${name} to book a desk in advance in another neighbourhood'
              }
            ]
          }
        ]
      },
      {
        v: 'roomBooking',
        t: 'Room Booking',
        h: 'Allow ${name} to use the room booking system'
      }
    ],
    roles: [
      {
        title: 'General', roles: [
          {
            key: 'user',
            title: 'Kahu User',
            help: 'This user has access to the Kahu App and can view their own information & bookings.'
          },
          {
            key: 'ns.admin',
            title: 'Super Admin',
            help: 'This user has full access to everything.',
            inherit: ['user', 'desk-booking.admin', 'room-booking.admin', 'people.admin']
          }
        ]
      },
      {
        title: 'People', roles: [
          {
            key: 'people.admin',
            title: 'Admin',
            help: 'This user can add, edit, and delete people, assign cards, update visitor lists, and change ' +
                'user permissions.',
            inherit: ['user', 'people.reception']
          },
          {
            key: 'people.reception',
            title: 'Reception',
            help: 'This user can add & edit people, assign cards, and update visitor lists.',
            inherit: ['user']
          }
        ]
      },
      {
        title: 'Desk Booking', roles: [
          {
            key: 'desk-booking.admin',
            title: 'Admin',
            help: 'This user can edit settings related to the desk booking system.',
            inherit: ['user', 'desk-booking.data', 'desk-booking.neighbourhoods', 'desk-booking.settings']
          },
          {
            key: 'desk-booking.data',
            title: 'Data',
            help: 'This user can report on and export desk booking data.',
            inherit: ['user']
          },
          {
            key: 'desk-booking.neighbourhoods',
            title: 'Neighbourhoods',
            help: 'This user can edit neighbourhoods.',
            inherit: ['user']
          },
          {
            key: 'desk-booking.settings',
            title: 'Settings',
            help: 'This user can edit desk settings (e.g. bookable state).',
            inherit: ['user']
          }
        ]
      },
      {
        title: 'Room Booking', roles: [
          {
            key: 'room-booking.admin',
            title: 'Admin',
            help: 'This user can manage room bookings.',
            inherit: ['user']
          }
        ]
      }
    ]
  },
  getters: {
    cardHistoryEnabled(state) {
      return state.features.cardHistory;
    },
    sectionCardsEnabled(state) {
      if (!state.features.sections) {
        return false;
      }
      return state.features.sections.cards;
    },
    sectionDashboardStatisticsEnabled(state) {
      if (!state.features.sections) {
        return false;
      }
      return state.features.sections['dashboard-statistics'];
    },
    sectionUsersEnabled(state) {
      if (!state.features.sections) {
        return false;
      }
      return state.features.sections.users;
    },
    sectionVisitorsEnabled(state) {
      if (!state.features.sections) {
        return false;
      }
      return state.features.sections.visitors;
    },
    sectionRoomBookingReadOnly(state) {
      if (!state.features.roomBooking) {
        return false;
      }
      return state.features.roomBooking.readOnly;
    },
    defaultSiteConfig(state) {
      return state.defaultSiteConfig;
    }
  },
  mutations: {
    setInitialised(state) {
      state.initialised = true;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setFeatures(state, features) {
      state.features = features;
    },
    setConfigDefaults(state, defaults) {
      state.defaultSiteConfig = defaults;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setRoles(state, roles) {
      state.roles = roles;
    }
  },
  actions: {
    async initialise({commit, state}) {
      if (state.initialised) {
        return;
      }
      log.debug('initialise');
      commit('setInitialised');
      const fb = await app;
      remoteConfig = fb.remoteConfig();
      const settings = {
        fetchTimeoutMillis: 10 * 1000, // 10 seconds
        minimumFetchIntervalMillis: 12 * 60 * 60 * 1000 // 12 hours
      };
      if (process.env.NODE_ENV === 'development') {
        settings.minimumFetchIntervalMillis = 1000;
      }
      remoteConfig.settings = settings;
      // note that config variables can be string|number|boolean, not an object
      remoteConfig.defaultConfig = ({
        'app_features': JSON.stringify(state.features),
        'app_site_config_defaults': JSON.stringify(state.defaultSiteConfig),
        'app_permissions': JSON.stringify(state.permissions),
        'app_roles': JSON.stringify(state.roles)
      });
    },
    async fetch({commit, state, dispatch}) {
      if (!state.initialised) {
        await dispatch('initialise');
      }
      log.debug('fetch');
      try {
        await remoteConfig.fetchAndActivate();
        const jsonProps = {
          'app_features': 'setFeatures',
          'app_site_config_defaults': 'setConfigDefaults',
          'app_permissions': 'setPermissions',
          'app_roles': 'setRoles'
        };
        Object.entries(jsonProps).forEach(([prop, mutator]) => {
          const propVal = remoteConfig.getString(prop);
          try {
            const asObject = JSON.parse(propVal);
            commit(mutator, asObject);
          } catch (e) {
            log.error('Failed to parse ' + prop + ' json while applying remote config ' + propVal);
          }
        });
      } catch (e) {
        log.error('error fetching remote config', e);
      }
      // set loaded, and use defaults if there is an error
      commit('setLoaded', true);
    }
  }
};
