<template>
  <v-container fluid class="py-0">
    <v-toolbar flat height="56" class="toolbar">
      <v-spacer/>
      <vc-date-picker
          v-model="dateRange"
          mode="range"
          :input-props="{placeholder:'Select a date range'}"
          :popover="{ placement: 'bottom-end', visibility: 'click' }"
          class="date-picker mx-2"/>
      <v-tooltip bottom :disabled="!dateRangeTooltip">
        <template #activator="{ on, attrs }">
          <span
              v-on="on"
              v-bind="attrs">
            <v-btn
                outlined
                color="accent darken-1"
                @click="exportBookings"
                :disabled="selectedBookings.length === 0 || processingDownload">
              <v-icon left>mdi-download</v-icon>
              <span v-if="processingDownload">Processing Download</span>
              <span v-else>Download Data</span>
            </v-btn>
          </span>
        </template>
        <span>{{ dateRangeTooltip }}</span>
      </v-tooltip>
      <bookings-export-options :headers="headersBySection"/>
    </v-toolbar>
    <bookings-list-table
        :items="selectedBookings"
        :loading-info="loadingInfo"
        :no-data-text="noDataText"
        :tz="exportTz"/>
    <p class="mt-2 text-right" v-if="selectedBookings.length === maxBookingsToDisplay">
      Displaying first {{ maxBookingsToDisplay }} results. Export file will contain all results.
    </p>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import BookingsListTable from '@/views/desk-booking/export/BookingsListTable';
import {Booking} from '@/store/booking';
import {dbUtil, decorateSnapshot} from '@/firebase';
import {byRef} from '@/store/firestore-util';
import BookingsExportOptions from './BookingsExportOptions.vue';
import {functionUtil} from '@/firebase';
import {startOfDay, endOfDay, subDays, format, differenceInDays} from 'date-fns';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz/esm';

export default {
  name: 'BookingsExport',
  components: {BookingsListTable, BookingsExportOptions},
  data() {
    return {
      bookings: [],
      dateRange: {
        start: startOfDay(subDays(new Date(), 7)),
        end: startOfDay(new Date())
      },
      loadingInfo: {
        loading: false,
        text: 'Loading bookings...',
        type: ''
      },
      maxDays: 400, // limit the maximum date range length selectable
      maxBookingsToDisplay: 1000, // limit the number of bookings displayed in the table
      spaceKinds: {
        desk: undefined,
        room: undefined
      },
      selectedSpaceKind: 'desk',
      processingDownload: false
    };
  },
  computed: {
    ...mapGetters('sites', [
      'activeSiteDoc'
    ]),
    ...mapGetters('sites/aggregates', {
      siteAggregateByRef: 'byRef'
    }),
    ...mapState('views/deskBooking/exportBookings', ['headers', 'selectedHeaders']),
    ...mapGetters('views/deskBooking/exportBookings', ['headersBySection', 'exportTz']),

    hasDateRange() {
      return Boolean(this.dateRange);
    },
    dateRangeTooltip() {
      if (this.bookings.length > 0) return '';
      if (this.loadingInfo.loading) return 'Loading bookings, please wait';
      if (this.hasDateRange) return 'There are no bookings for this range';
      return 'Please select a date range';
    },
    noDataText() {
      if (!this.validDates) return 'Please select a valid date range';
      else if (this.overMaxDays) return `Please select ${this.maxDays} days (or fewer)`;
      else return 'No bookings';
    },
    downloadFileName() {
      const site = this.activeSiteDoc.title ? this.activeSiteDoc.title.replace(/\s/g, '_') : this.activeSiteDoc.id;
      if (!this.validDates) {
        return `bookings-${site}.csv`;
      }
      const start = format(this.dateRange.start, 'yyyy_MM_dd');
      const end = format(this.dateRange.end, 'yyyy_MM_dd');
      return `bookings-${site}-${start}-${end}.csv`;
    },
    dateRangeDays() {
      if (!this.validDates) {
        return 0;
      }
      return differenceInDays(this.dateRange.end, this.dateRange.start);
    },
    overMaxDays() {
      return this.dateRangeDays > this.maxDays;
    },
    validDates() {
      return this.dateRange && this.dateRange.start && this.dateRange.end;
    },
    selectedBookings() {
      return this.bookings.filter(b => b.isSpaceKind(this.spaceKinds[this.selectedSpaceKind]));
    },
    startDate() {
      const inputZoned = utcToZonedTime(this.dateRange.start, this.exportTz);
      const fnZoned = startOfDay(inputZoned);
      return new Date(zonedTimeToUtc(fnZoned, this.exportTz));
    },
    endDate() {
      const inputZoned = utcToZonedTime(this.dateRange.end, this.exportTz);
      const fnZoned = endOfDay(inputZoned);
      return new Date(zonedTimeToUtc(fnZoned, this.exportTz));
    }
  },
  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.validDates || this.overMaxDays) {
          this.bookings = [];
          return;
        }
        this.fetch().catch(err => this.$logger.error('error fetching bookings', err));
      }
    },
    exportTz: {
      handler() {
        if (!this.validDates || this.overMaxDays) {
          this.bookings = [];
          return;
        }
        this.fetch().catch(err => this.$logger.error('error fetching bookings', err));
      }
    }
  },
  methods: {
    ...mapActions('bookings', [
      'fetchBookingsFor'
    ]),
    async populateSpaceKinds() {
      if (!this.spaceKinds.desk) {
        this.spaceKinds.desk = await dbUtil.doc('space-kinds/desk');
        this.spaceKinds.room = await dbUtil.doc('space-kinds/room');
      }
    },
    async fetch() {
      if (!this.validDates) {
        return;
      }
      this.loadingInfo.loading = true;
      try {
        await this.populateSpaceKinds();
        /** @type {DecoratedData[]} */
        const bookings = await this.fetchBookingsFor({
          start: this.startDate,
          end: this.endDate,
          limit: this.maxBookingsToDisplay
        });
        const ownerDocsRequestsByRef = {};
        this.bookings = bookings.map(b => {
          const booking = new Booking(b, this.exportTz);
          // hydrate documents where we have them
          booking.hydrateSite(this.activeSiteDoc);
          if (booking.spaceRef) {
            booking.hydrateSpace(this.siteAggregateByRef(booking.spaceRef));
          }
          if (booking.floorRef) {
            booking.hydrateFloor(this.siteAggregateByRef(booking.floorRef));
          }
          if (booking.neighbourhoodRef) {
            booking.hydrateNeighbourhood(this.siteAggregateByRef(booking.neighbourhoodRef));
          }

          // owners are more complicated, we fetch them all asynchronously,
          // but cache the results so we aren't fetching people twice.
          if (booking.ownerRef) {
            const key = byRef(booking.owner);
            const request = ownerDocsRequestsByRef[key] ||
                (ownerDocsRequestsByRef[key] = booking.ownerRef.get().then(snap => decorateSnapshot(snap)));
            request.then(ownerDoc => booking.hydrateOwner(ownerDoc));
          }

          return booking;
        });
      } catch (e) {
        this.$logger.error('error fetching bookings', e);
      } finally {
        this.loadingInfo.loading = false;
      }
    },
    async exportBookings() {
      this.processingDownload = true;
      const exportBookings = await functionUtil.bookingExportApi();
      exportBookings({
        fromDate: this.startDate.toISOString(),
        toDate: this.endDate.toISOString(),
        exportTz: this.exportTz,
        siteId: this.activeSiteDoc.id,
        spaceKind: this.selectedSpaceKind,
        headers: this.selectedHeaders
      }).then(res => {
        this.generateDownload(res.data);
      }).catch(err => {
        this.processingDownload = false;
        this.$logger.error('error exporting bookings', err);
        this.$notify.showError('Error exporting bookings');
      });
    },
    generateDownload(csv) {
      const blob = new Blob([csv.blob], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.downloadFileName;
      a.click();
      window.URL.revokeObjectURL(url);
      this.$notify.showSuccess(
          `Downloading bookings (${csv.meta.filesize.formatted})`
      );
      this.processingDownload = false;
    },
    numberFormat(value) {
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    }
  }
};
</script>

<style scoped>
.toolbar {
  /* These position our popup above the svg element */
  position: relative;
  z-index: 2;
}

.date-picker {
  width: 300px;
}
</style>
