<template>
  <card-view temporary :card="tempCard" :disabled="hasExpired">
    <template #header>
      <v-overlay absolute opacity="0.7" v-if="hasExpired" class="text-center">
        <v-btn @click="$emit('edit')" color="primary" class="mx-3 primary-text--text">
          <v-icon left>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn @click="$emit('new')" color="primary" class="mx-3 primary-text--text">
          <v-icon left>mdi-plus-circle</v-icon>
          New
        </v-btn>
      </v-overlay>
    </template>
    <template #actions>
      <span class="ml-2 grey--text text--lighten-1">
        {{ expiryText }} {{ expiryDate | moment('L [at] HH:mm') }}
      </span>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn class="mx-1" icon v-on="on" @click="$emit('edit')">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Card</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn class="mx-1" icon v-on="on" @click="$emit('new')">
            <v-icon>mdi-credit-card-multiple</v-icon>
          </v-btn>
        </template>
        <span>Replace Card</span>
      </v-tooltip>
    </template>
  </card-view>
</template>

<script>
import CardView from '@/components/cards/CardView';
import moment from 'moment';
import {mapState} from 'vuex';

export default {
  name: 'TemporaryCardView',
  components: {CardView},
  computed: {
    ...mapState('views/person', ['tempCard']),
    notYetValid() {
      const now = moment();
      const enabledFrom = moment(this.tempCard.enabledFrom.toDate());
      return enabledFrom.isAfter(now);
    },
    hasExpired() {
      const now = moment();
      const enabledTo = moment(this.tempCard.enabledTo.toDate());
      return enabledTo.isBefore(now);
    },
    expiryDate() {
      if (this.notYetValid) {
        return this.tempCard.enabledFrom;
      }
      return this.tempCard.enabledTo;
    },
    expiryText() {
      if (this.notYetValid) {
        return 'Valid from';
      }
      if (this.hasExpired) {
        return 'Expired on';
      }
      return 'Expires on';
    }
  }
};
</script>

<style scoped>

</style>
