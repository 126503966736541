<template>
  <span class="v-input v-text-field datetime_field" :class="{'v-input--dense' : dense, 'no-time': !withTime}">
    <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        offset-y
        max-width="290px"
        min-width="290px"
        transition="scale-transition"
        v-model="datePicker">
      <template #activator="{ on }">
        <v-text-field
            :dense="dense"
            :hide-details="dense && !hasErrors"
            :label="label"
            :required="required"
            :error-messages="errors"
            class="date_field py-0"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-model="selectedDate"
            v-on="on"/>
      </template>
      <v-date-picker
          scrollable
          :first-day-of-week="localeFirstDayOfWeek"
          v-model="selectedDate"/>
    </v-menu>
    <v-menu
        v-if="withTime"
        :close-on-content-click="false"
        :nudge-right="40"
        offset-y
        max-width="290px"
        min-width="290px"
        ref="timeMenu"
        transition="scale-transition"
        v-model="timePicker">
      <template #activator="{ on }">
        <v-text-field
            :dense="dense"
            :hide-details="dense && !hasErrors"
            :required="required"
            class="time_field py-0"
            prepend-inner-icon="mdi-clock-outline"
            readonly
            v-model="selectedTime"
            v-on="on"/>
      </template>
      <v-time-picker
          @change="timePicker=false"
          format="24hr"
          scrollable
          v-if="timePicker"
          v-model="selectedTime"/>
    </v-menu>
  </span>
</template>

<script>

import moment from 'moment';
import {mapGetters} from 'vuex';

export default {
  name: 'VDatetimeField',
  props: {
    value: Date,
    dense: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    withTime: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dateTime: moment(this.value),
      datePicker: false,
      timePicker: false,
      dateString: moment(this.value).format('YYYY-MM-DD'),
      timeString: moment(this.value).format('HH:mm')
    };
  },
  computed: {
    ...mapGetters('sites/active', ['localeFirstDayOfWeek']),
    errors() {
      return this.rules.map(r => r(this.dateTime.toDate())).filter(r => r !== true);
    },
    hasErrors() {
      return this.errors.length > 0;
    },
    selectedDate: {
      get() {
        return this.dateString;
      },
      set(val) {
        this.datePicker = false;
        const date = moment(val, 'YYYY-MM-DD');
        this.dateTime = this.dateTime.year(date.year()).month(date.month()).date(date.date());
        this.dateString = this.dateTime.format('YYYY-MM-DD');
        this.notifyChange();
      }
    },
    selectedTime: {
      get() {
        return this.timeString;
      },
      set(val) {
        const time = moment(val, 'HH:mm');
        this.dateTime = this.dateTime.hour(time.hour()).minute(time.minute());
        this.timeString = this.dateTime.format('HH:mm');
        this.notifyChange();
      }
    }
  },
  methods: {
    notifyChange() {
      this.$emit('input', this.dateTime.toDate());
    }
  }
};
</script>

<style scoped>
  .datetime_field {
    display: flex;
    width: 100%;
    min-width: 230px;
  }
  .datetime_field.no-time {
    min-width: 130px;
  }

  .date_field {
    width: 66%;
    min-width: 130px;
    margin: auto;
  }

  .time_field {
    width: 33%;
    min-width: 100px;
    margin: auto;
  }
</style>
