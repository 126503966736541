<template>
  <div v-if="show">
    <svg :viewBox="viewBox">
      <clipPath id="layoutClip">
        <path :d="clipPath"/>
      </clipPath>
      <g clip-path="url(#layoutClip)">
        <template v-for="(c, i) in circles">
          <!--suppress RequiredAttributes -->
          <circle v-bind="c" :key="i"/>
        </template>
      </g>
      <rect v-if="showGrid" width="100%" height="100%" fill="url(#grid)"/>
      <defs>
        <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
          <path d="M 10 0 L 0 0 0 10" fill="none" stroke="gray" stroke-opacity="0.2" stroke-width="0.5"/>
        </pattern>
        <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
          <rect width="100" height="100" fill="url(#smallGrid)"/>
          <path d="M 100 0 L 0 0 0 100" fill="none" stroke="gray" stroke-opacity="0.2" stroke-width="1"/>
        </pattern>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'FloorPlanCircles',
  props: {
    floor: {
      type: Object,
      default: null
    },
    viewBox: {
      type: String,
      default: null
    }
  },
  computed: {
    config() {
      if (!this.show) {
        return null;
      }
      return this.floor.neighbourhoodCircles;
    },
    clipPath() {
      if (!this.config) {
        return '';
      }
      return this.config.clipPath;
    },
    circles() {
      if (!this.config) {
        return [];
      }
      return this.config.circles;
    },
    showGrid() {
      return this.config && this.config.showGrid;
    },
    show() {
      return this.floor && this.floor.neighbourhoodCircles;
    }
  }
};
</script>

<style scoped>

</style>
