import {Logger} from '@vanti/vue-logger';

const log = Logger.get('fetchAppConfig');

/**
 * Fetch remote config on load
 *
 * @param {Vuex.Store} store
 */
export default function(store) {
  log.debug('loading...');
  store.commit('app/loading', 'config');
  store.dispatch('appConfig/initialise')
      .then(() => store.dispatch('appConfig/fetch'))
      .catch(err => log.error('error loading remote config', err))
      .finally(() => store.commit('app/loaded', 'config'));
};
