<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Assigned Card</th>
        <th>Type</th>
        <th>Reason</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(change, index) in assignments">
        <tr :key="index">
          <td>{{ change.time | moment('L LT') }}</td>
          <td>
            <router-link :to="{name: 'card', params: {id: change.document.ref.id}}">
              {{ cardTitle(change) }}
            </router-link>
          </td>
          <td class="text-capitalize">{{ change.card | cardRole }}</td>
          <td>{{ change.card.updateReason }}</td>
        </tr>
      </template>
      <tr v-if="loading">
        <td colspan="4" class="text-center grey--text text--lighten-2 pt-0" style="height: unset;">
          <v-progress-linear indeterminate/>
          <span>loading</span>
        </td>
      </tr>
      <tr v-else-if="assignments.length === 0">
        <td colspan="4" class="text-center grey--text text--lighten-2">
          <span>No history available</span>
        </td>
      </tr>
      <tr v-else-if="expectsMore" @click="loadMore" style="cursor: pointer;">
        <td colspan="4" class="text-center">
          <v-btn text>Load More</v-btn>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {CardIdTypeRef} from '@/util/card-type';

export default {
  name: 'PersonAccessCardHistory',
  filters: {
    cardRole(card) {
      if (card.role) {
        return card.role;
      }
      if (card.hasOwnProperty('enabledFrom') || card.hasOwnProperty('enabledTo')) {
        return 'temporary';
      }
      return 'permanent';
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters('views/person/cardHistory', [
      'assignments',
      'latestRecord',
      'expectsMore'
    ]),
    ...mapState('views/person/cardHistory', [
      'latestChanges'
    ]),
    ...mapGetters('sites/active', ['configCardIdType'])
  },
  watch: {
    latestRecord() {
      if (!this.latestRecord) {
        return;
      }
      // when the query collection updates, bind any changes since the most recent document
      this.$store.dispatch('views/person/cardHistory/bindLatest')
          .catch(err => this.$logger.error(err));
    }
  },
  mounted() {
    this.$store.dispatch('views/person/cardHistory/bind')
        .then(() => this.$store.dispatch('views/person/cardHistory/loadNextPage'))
        .catch(err => this.$logger.error(err))
        .finally(() => this.loading = false);
  },
  methods: {
    cardTitle(change) {
      if (change.document.displayName) {
        return change.document.displayName;
      }
      if (change.document.title) {
        return change.document.title;
      }
      if (change.card.title) {
        return change.card.title;
      }
      if (this.configCardIdType !== CardIdTypeRef && change.card.uids && change.card.uids[this.configCardIdType]) {
        return change.card.uids[this.configCardIdType];
      }
      return change.document.ref.id;
    },
    loadMore() {
      this.$store.dispatch('views/person/cardHistory/loadNextPage')
          .catch(err => this.$logger.error(err));
    }
  }
};
</script>

<style scoped>

</style>
