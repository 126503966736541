import {DocumentCollection} from '@/store/collection/document-collection';

/**
 * A DocumentCollection that never has any records
 */
export class EmptyCollection extends DocumentCollection {
  /** @override */
  constructor() {
    super('', []);
    this.expectsMoreRecords = false;
  }

  /** @override */
  recordFetchAborted() {
    return false;
  }

  /** @override */
  load() {
  }

  /** @override */
  stop() {
  }

  /** @override */
  fetchRecords() {
  }

  /** @override */
  async loadNextPage() {
  }

  /** @override */
  sameAs({ns, queryClauses}) {
    return false;
  }
}
