import {ListComPortsRequest, SelectComPortRequest, State} from '@vanti/card-reader-proto/src/gen/cards/cards_pb';
import api from './api/api';
import {defaultCardStream} from './api/card-stream';
import {Logger} from '@vanti/vue-logger';

const log = Logger.get('cards');

export default {
  namespaced: true,
  state: {
    bound: false,
    /** @type {string|number} */
    state: '',
    ports: []
  },
  mutations: {
    setBound(state, b) {
      state.bound = b;
    },
    setState(state, s) {
      state.state = s;
    },
    setPorts(state, ports) {
      state.ports = ports;
    }
  },
  getters: {
    allPorts(state) {
      return state.ports;
    },
    stateLoading(state) {
      return state.state === '';
    },
    stateOk(state) {
      return state.state === State.OK;
    },
    stateError(state) {
      return state.state === State.ERROR;
    },
    stateNoPorts(state) {
      return state.state === State.NO_COM_PORTS;
    },
    stateMultiplePorts(state) {
      return state.state === State.MULTIPLE_COM_PORTS;
    },
    stateListComPortsError(state) {
      return state.state === State.LIST_COM_PORTS_ERROR;
    },
    noCardReaderSoftware(state, getters) {
      return getters.stateError;
    }
  },
  actions: {
    async listPorts({commit}) {
      /** @type {ListComPortsResponse} **/
      const res = await new Promise((res, rej) => api.cards.listComPorts(new ListComPortsRequest(), (err, ok) => {
        if (err) return rej(err);
        res(ok);
      }));

      log.debug('ports', res.getPortList());
      commit('setPorts', res.getPortList());
    },
    async selectPort(_, port) {
      const req = new SelectComPortRequest();
      req.setPort(port);
      /** @type {SelectComPortResponse} **/
      await new Promise((res, rej) => api.cards.selectComPort(req, (err, ok) => {
        if (err) return rej(err);
        res(ok);
      }));
    },
    bind({state, commit}) {
      if (state.bound) {
        return defaultCardStream.bind();
      }
      commit('setBound', true);
      defaultCardStream.bus.$on('state', s => {
        commit('setState', s);
      });
      defaultCardStream.bus.$on('disconnected', () => {
        commit('setState', State.ERROR);
      });
      return defaultCardStream.bind();
    },
    cancelPullCards() {
      return defaultCardStream.cancel();
    }
  }
};
