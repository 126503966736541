import {Logger} from '@vanti/vue-logger';
import DeferUtil from './defer-util';
import {byId} from './firestore-util';
import {db, decorateSnapshot} from '@/firebase';

const log = Logger.get('ns');

export default {
  namespaced: true,
  state: {
    doc: null
  },
  getters: {
    nsRef(state) {
      return state.doc && state.doc.ref;
    },
    domains(state) {
      return state.doc && state.doc.domains || [];
    },
    logoPath(state) {
      return state.doc && state.doc.logo;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setDoc(state, doc) {
      state.doc = doc;
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      async handler({state, commit, rootGetters}, authUser) {
        if (!authUser) {
          commit('reset');
          commit('setDoc', null);
          return;
        }

        const oldNs = byId(state.doc);
        const newNs = rootGetters.ns;
        if (oldNs !== newNs) {
          const defer = {};
          const _db = await db;
          defer.ns = _db.doc(`ns/${newNs}`).onSnapshot(
              snapshot => commit('setDoc', decorateSnapshot(snapshot)),
              err => log.error(`ns/${newNs}.onSnapshot`, err)
          );
          commit('defer', defer);
        }
      }
    }
  }
};
