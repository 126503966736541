import Loading from '@/views/Loading';

import BookingsExport from '@/views/desk-booking/export/BookingsExport';
import DeskSettings from '@/views/desk-booking/settings/DeskSettings';
import DeskSiteSettings from '@/views/desk-booking/SiteSettings';

import Card from '@/views/people/card/Card.vue';
import CardEdit from '@/views/people/card/CardEdit';
import CardView from '@/views/people/card/CardView';
import CardsList from '@/views/people/cards/CardsList';

import PeopleDirectory from '@/views/people/directory/PeopleDirectory';
import Person from '@/views/people/person/Person.vue';
import PersonEdit from '@/views/people/person/PersonEdit';
import PersonView from '@/views/people/person/PersonView';
import User from '@/views/people/users/User.vue';
import UsersList from '@/views/people/users/UsersList';
import UserView from '@/views/people/users/UserView';

import Stats from '@/views/status/stats/Stats';
import LiveStatus from '@/views/dashboard/live-status/LiveStatus';
import Statistics from '@/views/dashboard/statistics/Statistics.vue';

import PageNotFound from '@/components/PageNotFound';

export default [
  {
    path: '/',
    name: 'loading',
    component: Loading
  },
  {
    path: '/people',
    name: 'people',
    component: {
      name: 'People',
      template: '<router-view/>'
    },
    children: [
      {
        path: 'directory',
        name: 'directory',
        meta: {title: 'People Directory'},
        component: PeopleDirectory,
        props(route) {
          return {
            search: {
              ...route.query
            }
          };
        }
      },
      {
        path: 'visitors',
        name: 'visitors',
        component: PeopleDirectory,
        meta: {title: 'Visitors Directory'},
        props(route) {
          return {
            search: {
              'site.membership.type': 'temporary',
              ...route.query
            }
          };
        }
      },
      {
        path: 'card/:id',
        component: Card,
        props: true,
        children: [
          {
            path: '',
            name: 'card',
            component: CardView,
            meta: {title: 'Card'},
            props: true
          },
          {
            path: 'edit',
            name: 'card-edit',
            component: CardEdit,
            meta: {title: 'Edit Card'},
            props: true
          },
          {
            path: '/people/cards/new',
            name: 'new-card',
            meta: {title: 'New Card'},
            component: CardEdit
          }
        ]
      },
      {
        path: 'cards',
        name: 'cards',
        component: CardsList,
        meta: {title: 'Cards'},
        props(route) {
          return {
            search: {
              ...route.query
            }
          };
        }
      },
      {
        path: 'users',
        name: 'users',
        component: UsersList,
        meta: {title: 'Users'},
        props(route) {
          return {
            search: {
              ...route.query
            }
          };
        }
      },
      {
        path: 'user/:id',
        component: User,
        props: true,
        children: [
          {
            path: '',
            name: 'user-view',
            component: UserView,
            meta: {title: 'User'},
            props: true
          }
        ]
      },
      {
        path: 'new',
        component: Person,
        children: [
          {
            path: '',
            name: 'new-person',
            component: PersonEdit,
            meta: {title: 'New Person'},
            props: true
          }
        ]
      },
      {
        path: 'view/:id',
        component: Person,
        props: true,
        children: [
          {
            path: '',
            name: 'person',
            component: PersonView,
            meta: {title: 'View Person'},
            props: true
          },
          {
            path: 'edit',
            name: 'person-edit',
            component: PersonEdit,
            meta: {title: 'Edit Person'},
            props: true
          }
        ]
      }
    ]
  },
  {
    name: 'desk-booking',
    path: '/desk-booking',
    component: {
      name: 'Desk-Booking',
      template: '<router-view/>'
    },
    children: [
      {
        name: 'desk-site-settings',
        path: 'site',
        meta: {title: 'Desk Site Settings'},
        component: DeskSiteSettings
      },
      {
        name: 'desk-settings',
        path: 'desks',
        meta: {title: 'Desk Settings'},
        component: DeskSettings
      },
      {
        name: 'desk-bookings-export',
        path: 'export',
        meta: {title: 'Desk Bookings Export'},
        component: BookingsExport
      }
    ]
  },
  {
    name: 'room-booking',
    path: '/room-booking',
    component: {
      name: 'Room-Booking',
      template: '<router-view/>'
    },
    children: [
      {
        name: 'room-booking-calendar',
        path: 'calendar',
        meta: {title: 'Room Booking Calendar'},
        component: () => import(
            /* webpackChunkName: "room-booking-calendar" */
            '@/views/room-booking/calendar/BookingCalendar'
        ),
        children: [
          {
            name: 'room-booking-calendar-view',
            path: 'booking/:id',
            component: () => import(
                /* webpackChunkName: "room-booking-calendar-view" */
                '@/views/room-booking/calendar/booking-view/BookingSidebar'
            )
          }
        ]
      },
      {
        name: 'room-booking-export',
        path: 'export',
        meta: {title: 'Room Bookings Export'},
        component: () => import(
            /* webpackChunkName: "room-booking-export" */
            '@/views/room-booking/export/RoomBookingsExport'
        )
      }
    ]
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: {
      name: 'Dashboard',
      template: '<router-view/>'
    },
    children: [
      {
        name: 'dashboard-live-status',
        path: 'live-status',
        meta: {title: 'Live Status'},
        component: LiveStatus
      },
      {
        name: 'dashboard-statistics',
        path: 'statistics',
        meta: {title: 'Statistics'},
        component: Statistics
      }
    ]
  },
  {
    name: 'admin',
    path: '/admin',
    component: {
      name: 'Admin',
      template: '<router-view/>'
    },
    children: [
      {
        name: 'admin-audit-logs',
        path: 'audit-logs',
        meta: {title: 'Audit Logs'},
        component: () => import(/* webpackChunkName: "admin-audit-logs" */ '@/views/admin/audit/AuditLogs')
      },
      {
        name: 'admin-audit-sync-report',
        path: 'audit-sync-report',
        meta: {title: 'Audit Sync Report'},
        component: () => import(/* webpackChunkName: "admin-audit-sync-report" */
            '@/views/admin/audit-sync-report/AuditSyncReport')
      },
      {
        name: 'admin-integrations',
        path: 'integrations',
        meta: {title: 'Integrations'},
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "admin-integrations" */ '@/views/admin/integrations/Integrations.vue')
      }
      // {
      //   name: 'admin-site-config',
      //   path: 'site-config',
      //   meta: {title: 'Site Config'},
      //   component: () => import(/* webpackChunkName: "admin-site-config" */ '@/views/admin/site/SiteConfig')
      // }
    ]
  },
  {
    name: 'status', path: '/status',
    component: {
      name: 'Status',
      template: '<router-view/>'
    },
    children: [
      {
        name: 'stats', path: 'stats', component: Stats, props(route) {
          return route.query;
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component() {
      return import(/* webpackChunkName: "login" */ '@/views/Login.vue');
    }
  },
  {path: '*', component: PageNotFound}
];
