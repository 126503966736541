import {dbUtil} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import FirestoreUtil, {byRef} from '@/store/firestore-util';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';
import {Floor} from '@/store/floor';

const log = Logger.get('floors');

export default {
  namespaced: true,
  state: {
    byRef: {},
    /** @type {Object.<string,Floor>} */
    localStateByRef: {}
  },
  getters: {
    /**
     * @param {Object} state
     * @return {Floor[]}
     */
    floors(state) {
      return Object.values(state.localStateByRef).sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    },
    floorByRef(state) {
      return ref => state.byRef[ref];
    },
    localStateByRef(state) {
      return ref => state.localStateByRef[ref];
    },
    siteFloors(state, getters, rootState, rootGetters) {
      const activeSite = rootGetters['sites/activeSiteDoc'];
      if (!activeSite) return [];

      return getters.floors.filter(f => f.siteRef.isEqual(activeSite.ref));
    },
    siteFloorTitlesById(state, getters) {
      const byId = {};
      for (const siteFloor of getters.siteFloors) {
        byId[siteFloor.id] = siteFloor.title;
      }
      return byId;
    },
    siteFloorIds(state, getters) {
      return getters.siteFloors.map(f => f.id);
    },
    groupFloorIds(state, getters, rootState, rootGetters) {
      const siteId = rootGetters['sites/activeSiteId'];
      return getters.siteFloorIds.map(id => `${siteId}_${id}`);
    },
    groupFloorTitlesById(state, getters, rootState, rootGetters) {
      const siteId = rootGetters['sites/activeSiteId'];
      const byId = {};
      for (const siteFloor of getters.siteFloors) {
        byId[`${siteId}_${siteFloor.id}`] = siteFloor.title;
      }
      return byId;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setBindStarted(state, {floor, bindTask}) {
      floor.bindStarted = bindTask;
    },
    setSvgBounds(state, {floor, size}) {
      floor.svgBounds = size;
      floor.boundsLoaded = true;
    },
    planSvgContentUpdate(state, {floor, content}) {
      floor.planSvgContent = content;
      floor.planSvgContentLoaded = true;
    },
    bookablesSvgContentUpdate(state, {floor, content}) {
      floor.bookablesSvgContent = content;
      floor.bookablesSvgContentLoaded = true;
    },
    saveSvgDesks(state, {floor, svgDesks}) {
      floor.desksById = svgDesks;
      floor.desksLoaded = true;
    },
    setFocusOn(state, {floor, focusOn}) {
      floor.focusOn = focusOn;
    },
    setFocusOnSpaceAfterLoad(state, {floor, space}) {
      floor.focusOnSpaceAfterLoad = space;
    },
    applyQuerySnapshot(state, snapshot) {
      FirestoreUtil.indexQuerySnapshotUpdates(state.byRef, snapshot, byRef);
    },
    clear(state) {
      state.byRef = {};
      state.localStateByRef = {};
    },
    /**
     * @param {Object} state
     * @param {Array.<{type:string, key: string, value: Floor}>} changes
     */
    applyLocalState(state, changes) {
      for (const change of changes) {
        switch (change.type) {
          case 'added':
          case 'modified':
            Vue.set(state.localStateByRef, change.key, change.value);
            break;
          case 'removed':
            Vue.delete(state.localStateByRef, change.key);
            break;
        }
      }
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({commit, dispatch, rootGetters}, authUser) {
        if (authUser) {
          const defer = {};
          defer.activeSite = this.watch(
              () => rootGetters['sites/activeSiteDoc'],
              site => dispatch('loadActiveSite', site),
              {immediate: true});
          commit('defer', defer);
        } else {
          commit('reset');
          commit('clear');
        }
      }
    },
    async syncLocalState({commit, state}, snapshot) {
      // save our companion data for the floors we now know about
      const changes = [];
      snapshot.docChanges().forEach(change => {
        const ref = byRef(change.doc);
        switch (change.type) {
          case 'added':
            const floor = new Floor(this, ref);
            changes.push({
              type: change.type,
              key: ref,
              value: floor
            });
            break;
          case 'modified':
            // don't need to update the store
            state.localStateByRef[ref].bind();
            break;
          case 'removed':
            const s = state.localStateByRef[ref];
            if (s) {
              s.unbind();
              changes.push({
                type: change.type,
                key: ref
              });
            }
            break;
        }
      });

      if (changes.length > 0) {
        commit('applyLocalState', changes);
      }
    },
    async loadActiveSite({commit, dispatch}, site) {
      commit('reset', ['activeSiteFloors']);
      if (!site) {
        return;
      }

      // NOTE: we only watch the floors for the active site, the defer mechanism will cancel the queries for other
      // sites when we run again. We _do_ continue to cache the floor data for all see floors though.
      const defer = {};
      /** @type {firebase.firestore.DocumentReference} */
      const siteRef = site.ref;
      defer.activeSiteFloors = siteRef.collection('spaces')
          .where('kind.ref', '==', await dbUtil.doc('space-kinds/floor'))
          .onSnapshot(
              snap => {
                commit('applyQuerySnapshot', FirestoreUtil.prepareQuerySnapshot(snap));
                return dispatch('syncLocalState', snap);
              },
              err => log.error('floors.onSnapshot', err));
      commit('defer', defer);
    },

    locate({state, commit}, {space, floor}) {
      floor.locate(space);
    }
  }
};
