<template>
  <div>
    <div v-if="loading" class="mx-2">
      <span class="mr-2">Checking card</span>
      <v-progress-circular indeterminate size="16" width="2"/>
    </div>
    <v-alert
        v-if="!loading && hasOwner && samePerson"
        class="body-2"
        dense
        text
        tile
        type="info">
      This card already belongs to {{ person.title }} at another site. {{ siteName }} access will be added to this card.
    </v-alert>
    <v-alert
        v-if="!loading && hasOwner && !samePerson && isNonTransferable"
        class="body-2"
        dense
        text
        tile
        type="error">
      This card is non-transferable. If you continue, any markings or prints on the card may not match the new owner.
    </v-alert>
    <v-alert
        v-if="!loading && hasOwner && !samePerson"
        class="body-2"
        dense
        text
        tile
        type="warning">
      <router-link :to="ownerLink">{{ cardDoc.owner.title }}</router-link>
      <span> currently has this card assigned.</span>
      <span> It will be unassigned from them if you continue.</span>
    </v-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CardOwnershipCheck',
  props: {
    cardId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cardDoc: {},
      loading: false,
      message: ''
    };
  },
  computed: {
    ...mapGetters('sites', [
      'activeSiteDoc'
    ]),
    ...mapGetters('views/person', [
      'person'
    ]),
    siteName() {
      const site = this.activeSiteDoc;
      return (site && site.title) || '';
    },
    hasOwner() {
      return this.cardDoc.hasOwnProperty('owner');
    },
    ownerId() {
      return this.hasOwner ? this.cardDoc.owner.ref.id : null;
    },
    ownerLink() {
      return this.hasOwner ? {
        name: 'person',
        params: {
          id: this.ownerId
        }
      } : null;
    },
    samePerson() {
      return this.hasOwner && this.ownerId === this.person.id;
    },
    isNonTransferable() {
      return this.cardDoc.nonTransferable;
    }
  },
  watch: {
    cardId: {
      handler: 'getCard',
      immediate: true
    },
    cardDoc(doc) {
      this.$emit('card-doc', doc);
    }
  },
  methods: {
    ...mapActions('views/cards', ['getByUid', 'ownedCardByUid']),
    async getCard() {
      this.cardDoc = {};
      if (!this.cardId || this.cardId === '') {
        return;
      }
      this.loading = true;
      try {
        let card = await this.getByUid({uid: this.cardId});
        if (card) {
          this.cardDoc = card;
          return;
        }
        // if site.config.cardIdType !=== 'ref' then getByUid does a query including site
        // check if the owner has a matching card at another site
        card = await this.ownedCardByUid({uid: this.cardId, ownerRef: this.person.ref});
        this.cardDoc = card ? card : {};
      } catch (e) {
        this.$logger.error('Error getting card', e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
