import {BatchGroup} from '@/util/batch-group';
import {db} from '@/firebase';
import {byRef} from '@/store/firestore-util';
import {Logger} from '@vanti/vue-logger';
import {get as _get, isEqual} from 'lodash';

const log = Logger.get('desk-settings/changes');

/**
 * This module handles actions and state relating to Changes.vue.
 */
export default {
  namespaced: true,
  actions: {
    /**
     * Save all changes to neighbourhoods, desks, and floor circles.
     *
     * @param {import('vuex').ActionContext} [context]
     * @param {Object} options
     * @param {boolean} options.circlesChanged
     * @return {Promise<void>}
     */
    async saveAllChanges({dispatch, rootGetters}, {circlesChanged}) {
      const batchGroup = new BatchGroup(await db);
      await dispatch('views/deskBooking/settings/desks/commitAll', batchGroup, {root: true});
      if (circlesChanged) {
        await dispatch('views/deskBooking/settings/circles/saveCircles', batchGroup, {root: true});
      }
      // do neighbourhoods last so that desks get updated first and the neighbourhood-cleanup function
      // doesn't overwrite our changes
      // NOTE: not sure if this matters in a batch-save, but it might
      await dispatch('views/deskBooking/settings/neighbourhoods/commitAll', batchGroup, {root: true});
      await batchGroup.commit();
      try {
        await dispatch('_updatesHaveApplied', {siteRef: byRef(rootGetters['sites/activeSiteDoc'])});
      } catch (e) {
        log.warn(`error waiting for updates to apply`, e);
      }
      await dispatch('discardAllChanges');
    },
    /**
     * Clear all local state from neighbourhoods, desks, and circle stores.
     *
     * @param {import('vuex').ActionContext} [context]
     */
    discardAllChanges({commit}) {
      commit('views/deskBooking/settings/neighbourhoods/clearState', null, {root: true});
      commit('views/deskBooking/settings/desks/clearEdits', null, {root: true});
      commit('views/deskBooking/settings/circles/clearLocalState', null, {root: true});
      commit('selection/clearAll', null, {root: true});
    },
    /**
     * Wait for all updates to be applied to the site aggregate document.
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} options
     * @param {string} options.siteRef
     * @param {number} [options.timeoutMs]
     * @return {Promise<void>}
     * @private
     */
    _updatesHaveApplied({rootState, rootGetters}, {siteRef, timeoutMs = 10 * 1000}) {
      const neighbourhoodChanges = {
        added: rootState.views.deskBooking.settings.neighbourhoods.added.map(n => n.ref),
        edits: rootState.views.deskBooking.settings.neighbourhoods.edits,
        deleted: rootState.views.deskBooking.settings.neighbourhoods.deleted.map(n => n.ref)
      };
      const deskEdits = rootState.views.deskBooking.settings.desks.edits;
      const circleEdits = rootGetters['views/deskBooking/settings/circles/floorsWithCircleChanges']
          .reduce((byRef, floorRef) => {
            byRef[floorRef] = [
              // simple copy
              ...rootGetters['views/deskBooking/settings/circles/allCircleDocsByFloorRef'](floorRef)
            ];
            return byRef;
          }, {});
      return new Promise((resolve, reject) => {
        let timeoutId = null;
        let cancel = () => {};
        const cleanup = () => {
          timeoutId && clearTimeout(timeoutId);
          cancel();
        };
        timeoutId = setTimeout(() => {
          cleanup();
          reject(new Error(`timeout waiting for updates to apply`));
        }, timeoutMs);
        // watch the site aggregate document for this site, and each time we get a change see if the updates
        // have been applied that we are waiting for.
        cancel = this.watch(
            () => rootState.sites.aggregates.bySiteRef[siteRef],
            () => {
              const neighbourhoodUpdatesDone = neighbourhoodUpdatesApplied(
                  neighbourhoodChanges,
                  rootGetters['sites/aggregates/byRef'],
                  siteRef
              );
              if (!neighbourhoodUpdatesDone) {
                return;
              }
              const deskEditsDone = deskUpdatesApplied(
                  deskEdits,
                  rootGetters['sites/aggregates/byRef'],
                  siteRef
              );
              if (!deskEditsDone) {
                return;
              }
              const circleEditsDone = circleUpdatesApplied(
                  circleEdits,
                  rootGetters['sites/aggregates/byRef']
              );
              if (!circleEditsDone) {
                return;
              }
              log.debug('all applied!');
              cleanup();
              resolve();
            }
        );
      });
    }
  }
};

/**
 * @typedef {Object} NeighbourhoodUpdates
 * @property {firebase.firestore.DocumentReference[]} added
 * @property {firebase.firestore.DocumentReference[]} deleted
 * @property {Object<string, Object<string, *>>} edits
 */

/**
 * @param {NeighbourhoodUpdates} updates
 * @param {function(string):*} aggregateByRef
 * @param {string} siteRef
 * @return {boolean}
 */
function neighbourhoodUpdatesApplied(updates, aggregateByRef, siteRef) {
  const hasAdded = updates.added
      .every(ref => Boolean(aggregateByRef(ref && ref.path)));
  if (!hasAdded) {
    log.debug('not all neighbourhoods added yet');
    return false;
  }
  const haveDeleted = updates.deleted
      .every(ref => !Boolean(aggregateByRef(ref && ref.path)));
  if (!haveDeleted) {
    log.debug('not all neighbourhoods deleted yet');
    return false;
  }
  const hasEdits = Object.entries(updates.edits)
      .every(([id, edit]) => {
        const n = aggregateByRef(`${siteRef}/spaces/${id}`);
        if (!n) {
          return false;
        }
        return Object.entries(edit).every(([prop, value]) => simpleObjEqual(value, _get(n, prop)));
      });
  if (!hasEdits) {
    log.debug('not all neighbourhood edits applied yet');
    return false;
  }
  return true;
}

/**
 * @param {Object<string, Object<string, *>>} edits
 * @param {function(string):*} aggregateByRef
 * @param {string} siteRef
 * @return {boolean}
 */
function deskUpdatesApplied(edits, aggregateByRef, siteRef) {
  const hasEdits = Object.entries(edits)
      .every(([id, edit]) => {
        const d = aggregateByRef(`${siteRef}/bookables/${id}`);
        if (!d) {
          return false;
        }
        return Object.entries(edit).every(([prop, value]) => simpleObjEqual(value, _get(d, prop)));
      });
  if (!hasEdits) {
    log.debug('not all desk edits applied yet');
    return false;
  }
  return true;
}

/**
 * @param {Object<string, []>} circlesByFloorRef
 * @param {function(string):*} aggregateByRef
 * @return {boolean}
 */
function circleUpdatesApplied(circlesByFloorRef, aggregateByRef) {
  const hasEdits = Object.entries(circlesByFloorRef)
      .every(([floorRef, circles]) => {
        const f = aggregateByRef(floorRef);
        if (!f) {
          return false;
        }
        // isEqual is OK here since circles is a simple array of objects, no firestore references
        const eq = isEqual(circles, _get(f, 'neighbourhoodCircles.circles'));
        if (!eq) {
          log.debug(`circles don't match`, {
            circles,
            db: _get(f, 'neighbourhoodCircles.circles')
          });
        }
        return eq;
      });
  if (!hasEdits) {
    log.debug('not all circle edits applied yet');
    return false;
  }
  return true;
}

/**
 * Check if doc1 and doc2 are equal, checking only doc1s keys.
 *
 * Recursively checks any nested objects.
 *
 * @param {*} doc1
 * @param {*} doc2
 * @return {boolean} equal
 */
function simpleObjEqual(doc1, doc2) {
  for (const k in doc1) { // we only loop doc1's keys
    if (doc1.hasOwnProperty(k)) {
      const v1 = doc1[k];
      const v2 = doc2[k];

      // does the property exist in doc2
      if (doc1.hasOwnProperty(k) && !doc2.hasOwnProperty(k)) {
        return false;
      } else if (objectNotNull(v1) && typeof v1.isEqual === 'function') {
        // firebase values such as Timestamp have an isEqual function
        if (!v1.isEqual(v2)) {
          return false;
        }
      } else if (objectNotNull(v1) && v1 instanceof Date && objectNotNull(v2) && v2 instanceof Date) {
        if (v1.valueOf() !== v2.valueOf()) {
          return false;
        }
      } else if (Array.isArray(v1)) {
        if (!Array.isArray(v2) || v1.length !== v2.length) {
          return false;
        } else {
          // todo: this doesn't support array values which aren't objects
          for (let i = 0; i < v1.length; i++) {
            const nestedEqual = simpleObjEqual(v1[i], v2[i]);
            if (!nestedEqual) {
              return false;
            }
          }
        }
      } else if (objectNotNull(v1)) {
        const nestedEqual = simpleObjEqual(v1, v2);
        if (!nestedEqual) {
          return false;
        }
      } else if (v1 !== v2) {
        return false;
      }
    }
  }
  return true;
}

/**
 * Check if o is an object and not null
 *
 * Useful because `typeof null === 'object'`
 *
 * @param {*} o
 * @return {boolean}
 */
function objectNotNull(o) {
  return typeof o === 'object' && o !== null;
}
