import {PullCardsRequest, State} from '@vanti/card-reader-proto/src/gen/cards/cards_pb';
import api from './api';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';

const log = Logger.get('CardStream');

/**
 * Wrap the card stream logic. This is partly because when cancel is called, the stream doesn't
 * fire an 'end' event. Without care there could be a build up of promises which never complete.
 *
 * @class
 */
class CardStream {
  /**
   *
   */
  constructor() {
    this.stream = null;
    this.bus = new Vue();
  }

  /**
   * Connect to the stream of card data, and reconnect if there is an issue.
   * The reconnection will not be attempted if the stream was cancelled.
   *
   * @return {Promise}
   */
  bind() {
    /**
     * Recursive call to start a stream, with a timeout between connection attempts.
     *
     * @return {Promise}
     */
    const keepPullingCards = () => {
      return this.pullCards()
          .catch(err => {
            if (err instanceof StreamCancelled) {
              return Promise.reject(err);
            } else {
              this.bus.$emit('disconnected');
              log.error('Failed fetching cards', err);
            }
          })
          // wait 5s before trying again
          .then(() => new Promise(res => setTimeout(res, 5000)))
          .then(() => keepPullingCards());
    };

    return keepPullingCards();
  }

  /**
   * Cancel the stream, if one is active
   *
   * @return {Promise}
   */
  cancel() {
    if (!this.stream) {
      return Promise.resolve();
    }
    this.stream.cancel();
    this.bus.$emit('cancel');
    return Promise.resolve();
  }

  /**
   * Do an RPC call for a stream of card data. The promise is rejected if the stream ends, or if it's cancelled.
   *
   * @return {Promise}
   */
  pullCards() {
    if (this.stream) {
      // already listening
      return Promise.resolve();
    }
    const req = new PullCardsRequest();
    const stream = api.cards.pullCards(req);
    this.stream = stream;

    stream.on('data', res => {
      if (res.hasCard()) {
        this.bus.$emit('card', res.getCard().getId());
        log.debug('card', res.getCard().getId());
      } else {
        const state = getKeyByValue(State, res.getState());
        this.bus.$emit('state', res.getState());
        log.debug('state', state);
      }
    });

    // resolve this method on failure only
    return new Promise((_, rej) => {
      stream.on('end', status => {
        this.stream = null;
        rej(status);
      });
      this.bus.$once('cancel', () => {
        log.debug('stream cancelled');
        this.stream = null;
        rej(new StreamCancelled());
      });
    });
  }
}

/**
 * Error to identify that a stream has been cancelled
 */
class StreamCancelled extends Error {

}

/**
 * Get an object key by value.
 *
 * @param {*} object
 * @param {*} value
 * @return {*}
 */
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

const defaultCardStream = new CardStream();

export default CardStream;
export {CardStream, defaultCardStream, StreamCancelled};
