<template>
  <v-card>
    <v-card-title>
      Create new neighbourhood
    </v-card-title>
    <v-card-text>
      <v-row class="mx-0 align-center">
        <color-picker :color.sync="internalColor"/>
        <v-text-field class="pt-0 mt-0 ml-3" v-model="internalTitle" placeholder="Neighbourhood Name" hide-details/>
      </v-row>
      <v-row class="mx-0 align-center">
        <v-col class="mx-0 px-0 pb-0">
          <v-switch
              :value="internalExclusive"
              @change="onExclusiveChange"
              label="Is this neighbourhood exclusive?"/>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="internalExclusive" class="mx-0">
          <v-col cols="8" class="mx-0 px-0 py-0">
            <v-switch
                class="mt-0"
                v-model="internalExclusiveAdHoc"
                label="Ad-hoc exclusive"/>
          </v-col>
          <v-spacer/>
          <v-col cols="1" class="mx-0 px-0 py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-icon v-on="on" class="grey--text text--lighten-3 my-2">mdi-information-outline</v-icon>
              </template>
              Allow booking by anyone on the day
            </v-tooltip>
          </v-col>
        </v-row>
      </v-expand-transition>
      <div class="my-2" v-if="selected.length > 0">
        <v-subheader class="pl-0"><b>Desks to be added</b></v-subheader>
        <span>{{ selected.map(s => s.title).join(', ') }}</span>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" text @click="resetDialog">Cancel</v-btn>
      <v-btn color="success" :disabled="!internalTitle" :loading="loading" @click="newNeighbourhood">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ColorPicker from '@/views/desk-booking/settings/ColorPicker';
import {defaultColor, defaultExclusiveColor} from '@/views/desk-booking/settings/neighbourhoods/neighbourhoods';

export default {
  name: 'CreateNeighbourhoodDialog',
  components: {ColorPicker},
  data() {
    return {
      internalColor: '#ff0000',
      internalTitle: null,
      internalExclusive: false,
      internalExclusiveAdHoc: false,
      loading: false
    };
  },
  computed: {
    ...mapState('selection', ['selected']),
    ...mapState('views/deskBooking/settings/desks', ['desks']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['allNeighbourhoods'])
  },
  methods: {
    ...mapActions('views/deskBooking/settings/neighbourhoods', ['createNewNeighbourhood']),
    resetDialog() {
      this.internalColor = defaultColor;
      this.internalTitle = null;
      this.internalExclusive = false;
      this.internalExclusiveAdHoc = false;
      this.$emit('close-dialog');
    },
    /**
     * Handle colour changes for changing to an exclusive neighbourhood and back.
     * If the colour hasn't been changed by the user, switch it if we're changing between
     * an inclusive or exclusive neighbourhood.
     * If the user has selected a colour themselves, keep that.
     *
     * @param {boolean} newValue
     */
    onExclusiveChange(newValue) {
      this.internalExclusive = newValue;

      if (this.internalColor === defaultColor) {
        this.internalColor = defaultExclusiveColor;
      } else if (this.internalColor === defaultExclusiveColor) {
        this.internalColor = defaultColor;
      }
    },
    async newNeighbourhood() {
      if (this.allNeighbourhoods.map(n => n.title.toLowerCase()).includes(this.internalTitle.toLowerCase())) {
        this.$notify.showError(`Neighbourhood must have a unique name`);
      } else {
        try {
          this.loading = true;
          const newNeighbourhood = await this.createNewNeighbourhood({
            title: this.internalTitle,
            color: this.internalColor,
            exclusive: this.internalExclusive,
            exclusiveAdHoc: this.internalExclusiveAdHoc
          });

          if (newNeighbourhood) {
            for (const desk of this.selected) {
              const editableDesk = this.desks.find(d => d.id === desk.id);
              if (editableDesk) {
                editableDesk.neighbourhood = newNeighbourhood;
              }
            }
            this.internalTitle = null;
            this.internalExclusive = false;
            this.internalExclusiveAdHoc = false;
            this.internalColor = defaultColor;
            this.$emit('close-dialog');
          }
        } catch (e) {
          this.$notify.showError(`Error creating neighbourhood`);
          this.$logger.error(`error creating neighbourhood`, e);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
