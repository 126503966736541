<template>
  <vue-annotator
      @move-end="circleMoved"
      @resize-end="circleResized"
      @draw-end="newCircle"
      :width="width"
      :height="height"
      drawing>
    <template v-for="(c, i) in circles">
      <circle
          @click="bindCircle(c)"
          v-if="!isDeleted(c)"
          :id="c.id"
          :class="{ 'outline': circle && circle.id === c.id}"
          slot="annotation"
          :cx="c.cx"
          :cy="c.cy"
          :r="c.r"
          :fill="c.fill"
          :key="i"/>
    </template>

    <circle slot="drawing" cx="0" cy="0" r="0" fill="rgba(255, 0, 0, 0.5)"/>
  </vue-annotator>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'ClickableCircle',
  props: {
    circles: {
      type: Array,
      default: null
    },
    height: {
      type: Number,
      default: 1920
    },
    width: {
      type: Number,
      default: 1080
    }
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/circles', [
      'changesByCircleId',
      'circleById',
      'isCircleDeleted',
      'circle',
      'includeDeletedCircles'
    ]),
    ...mapGetters('views/deskBooking/settings/map', ['activeFloor'])
  },
  methods: {
    ...mapMutations('views/deskBooking/settings/circles', ['setCircle']),
    ...mapActions('views/deskBooking/settings/circles', ['createNewCircle']),
    isDeleted(circle) {
      return this.isCircleDeleted(circle.id);
    },
    bindCircle(circle) {
      this.setCircle(circle);
    },
    circleMoved(e) {
      if (!e && !e.id) return null;
      const circle = this.circleById(parseInt(e.id));
      if (!circle) return null;

      circle.cx = parseInt(e.attributes.cx.value);
      circle.cy = parseInt(e.attributes.cy.value);
    },
    circleResized(e) {
      if (!e && !e.id) return null;
      const circle = this.circleById(parseInt(e.id));
      if (!circle) return null;

      circle.r = parseInt(e.attributes.r.value);
    },
    async newCircle(e) {
      if (!e) {
        return null;
      }
      const circle = {
        id: this.includeDeletedCircles.length,
        floor: this.activeFloor.ref.path,
        cx: parseInt(e.attributes.cx.value),
        cy: parseInt(e.attributes.cy.value),
        r: parseInt(e.attributes.r.value),
        fill: e.attributes.fill.value
      };
      const editableCircle = await this.createNewCircle(circle);
      this.setCircle(editableCircle);
    }
  }
};
</script>

<style>
.svg_select_points {
  display: none !important;
}

.outline {
  stroke: var(--v-primary-base);
  stroke-width: 1;
}
</style>
