import {isEqual} from 'lodash';

/**
 * @param {kahu.firestore.User} user
 * @param {kahu.auth.Claims} claims
 * @return {boolean}
 */
export function claimsMatchUser(user, claims) {
  const userNs = user.ns;
  const claimsNs = claims.van && claims.van.ns;
  if (userNs !== claimsNs) {
    return false;
  }
  const userRoles = user.roles || [];
  const claimsRoles = claims.van && claims.van.r || [];
  userRoles.sort();
  claimsRoles.sort();
  if (!isEqual(userRoles, claimsRoles)) {
    return false;
  }
  const userSites = user.sites || [];
  const claimsSites = claims.van && claims.van.s || [];
  userSites.sort();
  claimsSites.sort();
  if (!isEqual(userSites, claimsSites)) {
    return false;
  }

  const userLpid = user.person && user.person.ref && user.person.ref.id;
  const claimsLpid = claims.van && claims.van.lpid;
  if (userLpid !== claimsLpid) {
    return false;
  }

  return true;
}
