import {Logger} from '@vanti/vue-logger';
import DeferUtil from '@/store/defer-util';
import {decorateSnapshot} from '@/firebase';
import {CascadingConfig} from '@/util/config';
import {awaitFirstSnapshot} from '@/util/snapshot-promise';
import {isEqual} from 'lodash';

const log = Logger.get('store/user/profile');

const appName = 'workplaceApp';

export default {
  namespaced: true,
  state: {
    /** @type {kahu.firestore.Person|Object} */
    person: {},
    /** @type {kahu.firestore.PersonPreferences|Object} */
    preferences: {}
  },
  getters: {
    /**
     * @param {*} state
     * @param {*} getters
     * @param {*} rootState
     * @return {null|firebase.firestore.DocumentReference}
     */
    profileRef(state, getters, rootState) {
      const user = rootState.user.user;
      if (user && user.profile && user.profile.ref) {
        return user.profile.ref;
      }
      return null;
    },
    /**
     * @param {*} state
     * @param {*} getters
     * @param {*} rootState
     * @return {null|firebase.firestore.DocumentReference}
     */
    personRef(state, getters, rootState) {
      const user = rootState.user.user;
      if (user && user.person && user.person.ref) {
        return user.person.ref;
      }
      return null;
    },
    personRefPath(state, getters) {
      return getters.personRef && getters.personRef.path;
    },
    /**
     * @param {*} state
     * @param {*} getters
     * @return {null|firebase.firestore.DocumentReference}
     */
    preferencesRef(state, getters) {
      const personRef = getters.personRef;
      if (!personRef) {
        return null;
      }
      return personRef.collection('prefs').doc('global');
    },
    preferencesRefPath(state, getters) {
      return getters.preferencesRef && getters.preferencesRef.path;
    },

    preferences(state) {
      const preferences = state.preferences;
      return new CascadingConfig([
        preferences[appName] || {},
        preferences.global || {}
      ], null);
    },

    preferredSiteRef(state, getters, rootState, rootGetters) {
      const userDefaultSiteRef = rootGetters['user/preferredSiteRef'];
      const personHomeSiteRef = getters.personHomeSiteRef;
      return getters.preferences.get('site.ref', personHomeSiteRef || userDefaultSiteRef);
    },
    preferredSiteId(state, getters) {
      return getters.preferredSiteRef && getters.preferredSiteRef.id;
    },

    personHomeSiteRef(state) {
      const person = state.person;
      return person && person.homeSite && person.homeSite.ref;
    },

    personGroupSitesByCountry(state, getters) {
      return getters.preferences.get('groupSitesByCountry', false);
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setPerson(state, person) {
      state.person = person;
    },
    setPreferences(state, prefs) {
      state.preferences = prefs;
    },
    clear(state) {
      state.person = {};
      state.preferences = {};
    }
  },
  actions: {
    init: {
      root: true,
      handler({commit, getters, rootGetters, dispatch}) {
        let oldValues = null;
        this.watch(
            // watch the path rather than ref, because the ref can fire multiple changes when there isn't one
            () => [
              getters.personRefPath,
              getters.preferencesRefPath,
              // watch userTokenNeedsRefresh - since our user doc will update (and corresponding refs) before
              // the auth user claims has updated, so we need to fetch only when the token doesn't need a refresh
              // (i.e. when auth claims match)
              rootGetters['auth/userTokenNeedsRefresh']
            ],
            (values) => {
              if (rootGetters['auth/userTokenNeedsRefresh']) return;
              if (isEqual(oldValues, values)) return;
              oldValues = values;

              const preferencesRef = getters.preferencesRef;
              const personRef = getters.personRef;
              if (!personRef && !preferencesRef) {
                commit('reset');
                commit('clear');
                commit('app/clear', 'user/person', {root: true});
                commit('app/clear', 'user/profile', {root: true});
                return;
              }
              dispatch('onPersonRefChange');
              dispatch('onPreferencesRefChange');
            }
        );
      }
    },
    async onPersonRefChange({commit, dispatch, getters}) {
      const personRef = getters.personRef;
      commit('app/loading', 'user/person', {root: true});
      commit('reset', 'preferences');
      try {
        await dispatch('bindPersonDoc', personRef);
      } catch (e) {
        log.error(`bindPersonDoc`, e);
      } finally {
        commit('app/loaded', 'user/person', {root: true});
      }
    },
    async onPreferencesRefChange({commit, dispatch, getters}) {
      const preferencesRef = getters.preferencesRef;
      commit('app/loading', 'user/profile', {root: true});
      commit('reset', 'preferences');
      try {
        await dispatch('bindPreferencesDoc', preferencesRef);
      } catch (e) {
        log.error(`bindPreferencesDoc`, e);
      } finally {
        commit('app/loaded', 'user/profile', {root: true});
      }
    },
    async bindPersonDoc({commit}, ref) {
      log.debug(`bindPersonDoc ${ref.path}`);
      const defer = {};
      defer.person = await awaitFirstSnapshot(ref,
          snap => {
            commit('setPerson', decorateSnapshot(snap));
          },
          err => {
            log.error(`bindPersonDoc.onSnapshot`, err);
          }
      );
      commit('defer', defer);
    },
    async bindPreferencesDoc({commit}, ref) {
      log.debug(`bindPreferencesDoc ${ref.path}`);
      const defer = {};
      defer.preferences = await awaitFirstSnapshot(ref,
          snap => {
            commit('setPreferences', decorateSnapshot(snap));
          },
          err => {
            log.error(`preferencesRef.onSnapshot`, err);
          }
      );
      commit('defer', defer);
    },

    async setPreferredSite({getters, dispatch}, site) {
      const preferencesRef = getters.preferencesRef;
      if (!preferencesRef) {
        log.debug(`no preferences doc to update, setting user defaultSite`);
        return dispatch('user/updateDefaultSite', site.ref && site.ref.id, {root: true});
      }
      await preferencesRef.set({
        [appName]: {
          site: {
            ref: site.ref,
            title: site.title
          }
        }
      }, {merge: true});
    },

    async setGroupSitesByCountry({getters, dispatch}, category) {
      const preferencesRef = getters.preferencesRef;
      await preferencesRef.set({
        [appName]: {
          groupSitesByCountry: category
        }
      }, {merge: true});
    }
  }
};
