<template>
  <div class="cards-list v-data-table theme--light">
    <table>
      <v-data-table-header :headers="headers" disable-sort/>
    </table>
    <!--
    Note: don't remove page-mode! While the visual functionality is the same, without it a dom node per row is created
    by the recycle-scroller, which quickly adds up.
    -->
    <recycle-scroller
        page-mode
        class="tbody"
        :items="items"
        :item-size="52">
      <template #default="{item}">
        <cards-list-row
            :card="item"
            :headers="headers"
            :to="{name: 'card', params: {id: item.id}}"
            :exclude-tags="excludeTags"/>
      </template>
    </recycle-scroller>
    <resize-observer @notify="setWidth"/>
  </div>
</template>

<script>
import CardsListRow from '@/views/people/cards/CardsListRow';
import {ResizeObserver} from 'vue-resize';
import {RecycleScroller} from 'vue-virtual-scroller';
// force webpack to include data table styles we need, even though we aren't using it directly
import 'vuetify/src/components/VDataTable/VSimpleTable.sass';

const headers = [
  {text: 'Name', value: 'title', width: 20},
  {text: 'Assigned To', value: 'ownerTitle', width: 30},
  {text: 'Expiry', value: 'enabledTo', width: 15},
  {text: 'Type', value: 'role', width: 10},
  {text: 'ID', value: 'id', width: 10},
  {text: 'Returned', value: 'returned', width: 8},
  {text: 'Edit', value: 'edit', width: 7}
];

const breakpoints = [
  {w: 0, cols: {title: 100}},
  {w: 350, cols: {title: 45, ownerTitle: 55}},
  {w: 600, cols: {title: 30, ownerTitle: 30, enabledTo: 20, role: 20}},
  {w: 900, cols: {title: 20, ownerTitle: 35, enabledTo: 20, role: 15, id: 10}},
  {w: 1200, cols: {title: 20, ownerTitle: 30, enabledTo: 15, role: 10, id: 10, returned: 8, edit: 7}}
];

export default {
  name: 'CardsListTable',
  components: {CardsListRow, RecycleScroller, ResizeObserver},
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    excludeTags: {
      type: [Function, Array],
      default: undefined
    }
  },
  data() {
    return {
      width: null
    };
  },
  computed: {
    headers() {
      const width = this.width || 0;
      let breakpoint = null;
      for (let i = breakpoints.length - 1; i >= 0; i--) {
        if (breakpoints[i].w < width) {
          breakpoint = breakpoints[i];
          break;
        }
      }
      if (!breakpoint) {
        return headers;
      }

      return headers.filter(header => breakpoint.cols[header.value])
          .map(header => Object.assign({}, header, {width: breakpoint.cols[header.value] + '%'}));
    }
  },
  methods: {
    setWidth() {
      this.width = this.$el.clientWidth;
    }
  }
};
</script>

<style scoped>
  .v-data-table .table-header >>> th {
    color: var(--text);
  }

  .tbody {
    width: 100%;
  }

  .cards-list {
    /* needed by vue-resize */
    position: relative;
  }

  .cards-list table {
    background-color: inherit;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    border-spacing: 0;
  }

  .cards-list >>> th {
    height: 56px;
    font-size: 14px;
    font-weight: normal;
    padding: 0 16px;
    color: rgba(0,0,0,0.6);
  }

  .cards-list >>> thead tr:last-child th {
    border-bottom: solid var(--v-grey-base) 1px;
  }

  .cards-list >>> .hover {
    background: #eee;
  }
</style>
