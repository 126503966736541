<template>
  <v-layout align-center>
    <v-row no-gutters class="justify-space-around">
      <v-progress-linear indeterminate v-if="!loaded" class="loading-bar"/>
      <v-col cols="12" class="mt-8 mb-10">
        <v-row no-gutters class="justify-space-around">
          <v-col cols="8" lg="4" md="6" sm="6">
            <h2>Spaces in use: {{ totalUtilisation }}%</h2>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-progress-linear
                    v-bind="attrs"
                    v-on="on"
                    :value="totalUtilisation"
                    height="32"
                    color="accent"
                    background-color="grey lighten-5"/>
              </template>
              {{ utilisationString }}
            </v-tooltip>
          </v-col>
          <v-col :class="{'mt-10': !desktop}" cols="12" lg="7" md="12" sm="12">
            <v-row class="justify-space-around">
              <v-col cols="3" v-if="showDesks" class="text-center">
                <totals color="primary" icon="mdi-desk" label="Total Desks" :count="deskCount"/>
              </v-col>
              <v-col cols="3" v-if="showRooms" class="text-center">
                <totals
                    color="accent"
                    icon="mdi-door-open"
                    label="Total Rooms (Capacity)"
                    :count="`${roomCount} (${roomCapacity})`"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="showDesks" class="px-4" cols="12" lg="6" md="12" sm="12">
        <chart-row
            label="desk"
            color="primary"
            :value="deskPercentage"
            icon="mdi-desk"
            :booked="reservedDesks"
            :checked-in="checkedInDesks"/>
      </v-col>
      <v-col v-if="showRooms" class="px-4" cols="12" lg="6" md="12" sm="12">
        <chart-row
            label="room"
            color="accent"
            :value="roomPercentage"
            icon="mdi-door-open"
            :booked="reservedRooms"
            :checked-in="checkedInRooms"
            :ad-hoc="adHocRooms"/>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Totals from '@/views/dashboard/live-status/Totals';
import ChartRow from '@/views/dashboard/live-status/ChartRow';
import breakpoints from '@/plugins/breakpoints';
import {
  checkedInResource,
  reservedAdHocResource,
  reservedCapacity,
  reservedResource
} from '@/views/dashboard/counts/booking-counts-util';

export default {
  name: 'LiveStatus',
  components: {Totals, ChartRow},
  mixins: [breakpoints],
  computed: {
    ...mapGetters('auth', ['hasAccessTo']),
    ...mapGetters('views/dashboard/liveStatus', ['loaded']),
    ...mapGetters('views/dashboard/counts/site', ['siteCount', 'deskCount', 'roomCount', 'roomCapacity']),
    ...mapGetters('views/dashboard/liveStatus/desks', ['deskTotals']),
    ...mapGetters('views/dashboard/liveStatus/rooms', ['roomTotals']),
    ...mapGetters('sites', ['activeSiteHasDeskBooking', 'activeSiteHasRoomBooking']),
    totalUtilisation() {
      return (((this.reservedDesks + this.reservedRooms) / this.siteCount) * 100).toFixed(2);
    },
    deskCapacity() {
      return this.deskTotals[reservedCapacity] || 0;
    },
    reservedDesks() {
      return this.deskTotals[reservedResource] || 0;
    },
    checkedInDesks() {
      return this.deskTotals[checkedInResource] || 0;
    },
    roomReservedCapacity() {
      return this.roomTotals[reservedCapacity] || 0;
    },
    reservedRooms() {
      return this.roomTotals[reservedResource] || 0;
    },
    checkedInRooms() {
      return this.roomTotals[checkedInResource] || 0;
    },
    adHocRooms() {
      return this.roomTotals[reservedAdHocResource] || 0;
    },
    roomPercentage() {
      return ((this.reservedRooms / this.roomCount) * 100).toFixed(2);
    },
    deskPercentage() {
      return ((this.reservedDesks / this.deskCount) * 100).toFixed(2);
    },
    showDesks() {
      return this.activeSiteHasDeskBooking && this.hasAccessTo.dashboard.liveStatus.desks;
    },
    showRooms() {
      return this.activeSiteHasRoomBooking && this.hasAccessTo.dashboard.liveStatus.rooms;
    },
    utilisationString() {
      return `${this.showDesks ? `Desks In-Use (${this.reservedDesks})` : ''}
      ${this.showDesks && this.showRooms ? '+' : ''}
      ${this.showRooms ? `Rooms In-Use (${this.reservedRooms})` : ''}
      / Total Capacity (${this.siteCount})`;
    }
  },
  mounted() {
    this.bind();
  },
  beforeDestroy() {
    this.unbind();
  },
  methods: {
    ...mapActions('views/dashboard/liveStatus', ['bind', 'unbind'])
  }
};
</script>

<style scoped>
.v-progress-circular >>> .v-progress-circular__info {
  display: inline-block!important;
}
.loading-bar {
  position: absolute;
}
</style>
