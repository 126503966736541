<template>
  <card-view :card="permCard">
    <template #header>
      <v-overlay absolute opacity="0.7" v-if="disabled" class="text-center">
        <v-btn @click="enableCard" color="primary" class="mx-3 primary-text--text">
          <v-icon left>mdi-check-circle</v-icon>
          Re-Enable
        </v-btn>
        <permanent-card-replace full-button/>
      </v-overlay>
    </template>
    <template #actions>
      <template v-if="validFromFuture">
        <span class="ml-2 grey--text text--lighten-1">
          Valid from {{ activeMembership.validFrom | moment('L [at] HH:mm') }}
        </span>
      </template>
      <template v-else-if="hasValidTo">
        <span class="ml-2 grey--text text--lighten-1">
          {{ expiryText }} {{ activeMembership.validTo | moment('L [at] HH:mm') }}
        </span>
      </template>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn class="mx-1" icon v-on="on" @click="$emit('edit')">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Card</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn class="mx-1" icon v-on="on" @click="disableCard">
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
        </template>
        <span>Disable Card</span>
      </v-tooltip>
      <permanent-card-replace/>
    </template>
  </card-view>
</template>

<script>
import CardView from '@/components/cards/CardView';
import PermanentCardReplace from '@/components/cards/PermanentCardReplace';
import moment from 'moment';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'PermanentCardView',
  components: {CardView, PermanentCardReplace},
  computed: {
    ...mapGetters('views/person', ['activeMembership']),
    ...mapState('views/person', ['permCard']),
    disabled() {
      return this.permCard.disabled;
    },
    validFromFuture() {
      if (!this.activeMembership) {
        return false;
      }
      if (!this.activeMembership.validFrom) {
        return false;
      }
      const validFrom = moment(this.activeMembership.validFrom.toDate());
      const now = moment(); // todo: this isn't reactive
      return validFrom.isAfter(now);
    },
    hasValidTo() {
      return this.activeMembership && this.activeMembership.hasOwnProperty('validTo');
    },
    hasExpired() {
      if (!this.hasValidTo) {
        return false;
      }
      const now = moment();
      const enabledTo = moment(this.activeMembership.validTo.toDate());
      return enabledTo.isBefore(now);
    },
    expiryText() {
      if (this.hasExpired) {
        return 'Expired on';
      }
      return 'Expires on';
    }
  },
  methods: {
    ...mapActions('views/person', [
      'enablePermCard',
      'disablePermCard'
    ]),
    enableCard() {
      this.enablePermCard()
          .then(() => this.$notify.showSuccess('Card enabled'))
          .catch(err => this.$logger.error('error enabling card', err));
    },
    disableCard() {
      this.disablePermCard()
          .then(() => this.$notify.showSuccess('Card disabled'))
          .catch(err => this.$logger.error('error disabling card', err));
    }
  }
};
</script>

<style scoped>

</style>
