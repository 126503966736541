import {isSameDocument} from '@/views/people/person/person-util';
import {auth, db, decorateSnapshot, functionUtil} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import firebase from 'firebase/app';

const log = Logger.get('views/user');

/**
 * Represents the active user, i.e. the user page.
 */
export default {
  namespaced: true,
  state: {
    loaded: false,
    user: null,
    userBindError: null
  },
  getters: {
    user(state) {
      return state.user;
    },
    userExists(state) {
      return state.user && state.user.exists;
    },
    loaded(state) {
      return state.loaded;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setUser(state, user) {
      state.user = user;
      state.loaded = true;
    },
    setUserBindError(state, err) {
      state.userBindError = err;
      state.loaded = true;
    },
    clear(state) {
      state.loaded = false;
      state.user = null;
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({commit, dispatch}, authUser) {
        if (!authUser) {
          dispatch('unbind');
        }
      }
    },
    /**
     * Load the user given by the user argument and watch for changes. The user should have an id or ref field,
     * for the bind to do anything.
     *
     * If you bind a user that matches (id or ref match) the currently bound user then nothing happens.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} user
     * @return {Promise<*>}
     */
    async bind({state, commit, dispatch}, user) {
      // don't rebind if setting the same user
      if (isSameDocument(state.user, user)) {
        return;
      }

      if (!user || (!user.ref && !user.id)) {
        // assume they want to clear the active user, in which case we're done
        // we setUser here so that the loading state is set
        commit('setUser', null);
        return;
      }

      await dispatch('unbind');

      const userRef = await dispatch('resolveRef', {doc: user, collection: 'users', withNs: false}, {root: true});
      log.debug('bind', userRef);

      const defer = {};
      defer.user = userRef.onSnapshot(
          userSnapshot => commit('setUser', decorateSnapshot(userSnapshot)),
          err => commit('setUserBindError', err)
      );
      commit('defer', defer);
    },
    unbind({commit}) {
      commit('reset');
      commit('clear');
    },
    /**
     * Update the user
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} user
     * @return {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*>}
     */
    async updateUser({state, rootGetters}, user) {
      // Note: because we are bound to the user (or should be), we don't need to re-fetch or event commit changes to
      // our local user as firestore will do this for us.

      if (user.ref && typeof user.ref.update === 'function') {
        const auditSnippet = rootGetters['user/auditSnippet'];
        return user.ref.update({
          ...user,
          ...auditSnippet
        });
      } else {
        throw new Error(`user doesn't have a ref to update`);
      }
    },
    async addRole({state, rootGetters}, role) {
      if (!state.user) {
        throw new Error('No user is active when adding role!');
      }

      const auditSnippet = rootGetters['user/auditSnippet'];
      return await state.user.ref.update({
        roles: firebase.firestore.FieldValue.arrayUnion(role),
        ...auditSnippet
      });
    },

    async removeRole({state, rootGetters}, role) {
      if (!state.user) {
        throw new Error('No user is active when removing role!');
      }

      const auditSnippet = rootGetters['user/auditSnippet'];
      return await state.user.ref.update({
        roles: firebase.firestore.FieldValue.arrayRemove(role),
        ...auditSnippet
      });
    },
    /**
     * Create a user -> person link
     *
     * @param {import('vuex').ActionContext} context
     * @param {DecoratedData} person
     * @return {Promise}
     */
    async linkToPerson({state, dispatch, rootGetters}, person) {
      log.debug('linkToPerson', person);
      if (!state.user) {
        throw new Error('No user is active for linking to a person.');
      }
      if (!person.ref) {
        throw new Error('No person ref for linking to a user.');
      }
      const alreadyLinked = await dispatch('personHasLink', person);
      if (alreadyLinked) {
        const title = person.displayName ? person.displayName : person.title;
        throw new Error(`${title} is already linked to a user.`);
      }
      const auditSnippet = rootGetters['user/auditSnippet'];
      return await state.user.ref.update({
        person: {
          ref: person.ref,
          title: person.title
        },
        ...auditSnippet
      });
    },
    /**
     * Remove the user -> person link
     *
     * @param {import('vuex').ActionContext} context
     * @return {Promise}
     */
    async unlinkPerson({state, rootGetters}) {
      log.debug('unlinkPerson');
      if (!state.user) {
        throw new Error('No user is active for unlinking.');
      }
      const alreadyUnlinked = !Boolean(state.user.person);
      if (alreadyUnlinked) {
        throw new Error(`User has no linked person to remove.`);
      }
      const auditSnippet = rootGetters['user/auditSnippet'];
      return await state.user.ref.update({
        person: firebase.firestore.FieldValue.delete(),
        ...auditSnippet
      });
    },
    /**
     * Check if a person has a user link
     *
     * @param {DecoratedData} person
     * @return {Promise<boolean>}
     */
    async personHasLink({rootGetters}, person) {
      const fb = await db;
      /** @type {firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>} */
      const snapshot = await fb.collection(`${rootGetters['nsRef']}/search/users/records`)
          .where('user.person.ref', '==', person.ref)
          .get();
      return snapshot.size !== 0;
    },

    async createUser({}, email) {
      const _auth = await auth;
      const createNewUser = await functionUtil.userCreateApi();
      await createNewUser({email});
      // note this can't be done easily by the function, but should live there
      await _auth.sendPasswordResetEmail(email);
    }
  }
};
