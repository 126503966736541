<template>
  <v-text-field
      :loading="loading"
      :label="textBoxLabel"
      @click:append="saveDisplayName"
      @keydown.enter="saveDisplayName"
      @change="saveDisplayName"
      :append-icon="changes ? 'mdi-check' : ''"
      v-model="name"/>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'EditDisplayName',
  data() {
    return {
      loading: false,
      name: null
    };
  },
  computed: {
    ...mapGetters('user', [
      'displayName',
      'profileRef',
      'hasProfile'
    ]),
    displayNameEditable() {
      return this.hasProfile;
    },
    hasDisplayName() {
      return !!this.displayName;
    },
    textBoxLabel() {
      return this.hasDisplayName ? 'Display Name' : 'Add a display name';
    },
    changes() {
      return this.name !== this.displayName;
    }
  },
  watch: {
    displayName: {
      handler(value) {
        this.name = value;
      },
      immediate: true
    }
  },
  methods: {
    async saveDisplayName() {
      if (!this.changes) {
        return;
      }
      if (!this.displayNameEditable) {
        this.$notify.showError(`Couldn't update your display name`);
        return;
      }
      try {
        this.loading = true;
        await this.profileRef.update({displayName: this.name});
        this.$notify.showSuccess('Your display name has been updated');
      } catch (e) {
        this.$logger.error('error updating displayName', e);
        this.$notify.showError(`Couldn't update your display name`);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
