import {Logger} from '@vanti/vue-logger';
import {db} from '@/firebase';
import FirestoreUtil from './firestore-util';
import siteAggregates from '@/store/site-aggregates';
import {CascadingConfig} from '@/util/config';
import {DefaultLocale, DefaultTimeZone} from '@/util/dates';
import DeferUtil from './defer-util';

const log = Logger.get('sites');

export default {
  namespaced: true,
  state: {
    sitesById: {},
    /** @type {string|null} */
    chosenSiteId: null
  },
  getters: {
    activeSiteDoc(state, getters) {
      const sites = getters.sitesById;

      // first check if a site has been explicitly chosen
      const chosen = state.chosenSiteId;
      if (chosen && sites.hasOwnProperty(chosen)) {
        return sites[chosen];
      }

      // else chose a site based on the first site id (sorted)
      const ids = Object.keys(sites).sort();
      if (ids.length > 0) {
        return sites[ids[0]];
      }

      // no sites to choose
      return null;
    },
    activeSiteId(state, getters) {
      const activeSite = getters.activeSiteDoc;
      return (activeSite && activeSite.id) || '';
    },
    sitesById(state, getters, rootState, rootGetters) {
      // ns admins can see all sites
      if (rootGetters['auth/isAdmin']) {
        return state.sitesById;
      }
      // other users are limited by the user.sites property
      const sites = {};
      const userSiteIds = rootGetters['user/userSiteIds'];
      for (const siteId of userSiteIds) {
        if (state.sitesById.hasOwnProperty(siteId)) {
          sites[siteId] = state.sitesById[siteId];
        }
      }
      return sites;
    },
    sitesByCountry(state, getters) {
      const byCountry = {Other: []};
      for (const site of Object.values(getters.sitesById)) {
        if (site && site.address && site.address.country) {
          if (!byCountry[site.address.country]) {
            byCountry[site.address.country] = [];
            byCountry[site.address.country].push(site);
          } else byCountry[site.address.country].push(site);
        } else {
          byCountry['Other'].push(site);
        }
      }
      return byCountry;
    },
    sitesConfigured(state, getters) {
      return Object.keys(getters.sitesById).length > 0;
    },
    forSpace(state, getters) {
      return space => getters.sitesById[space.ref.parent.parent.id];
    },
    activeSiteHasDeskBooking(state, getters) {
      if (getters.activeSiteDoc && !getters.activeSiteDoc.availablePermissions) {
        return true;
      } else if (getters.activeSiteDoc && getters.activeSiteDoc.availablePermissions.length > 0) {
        return getters.activeSiteDoc.availablePermissions.includes('deskBooking');
      }
      return false;
    },
    activeSiteHasRoomBooking(state, getters) {
      if (getters.activeSiteDoc && !getters.activeSiteDoc.availablePermissions) {
        return true;
      } else if (getters.activeSiteDoc && getters.activeSiteDoc.availablePermissions.length > 0) {
        return getters.activeSiteDoc.availablePermissions.includes('roomBooking');
      }
      return false;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),

    applySitesQuerySnapshot(state, snapshot) {
      FirestoreUtil.indexQuerySnapshotUpdates(state.sitesById, snapshot);
    },
    chooseActiveSite(state, chosenSiteId) {
      state.chosenSiteId = chosenSiteId;
    },
    clearSites(state) {
      state.sitesById = {};
    }
  },
  actions: {
    init: {
      root: true,
      handler({state, commit, rootGetters}) {
        // when the app is loaded, select the user's preferredSiteId if one is not chosen
        // we don't do this in a getter because we only want it to happen on load
        this.watch(
            () => rootGetters['app/loaded'],
            loaded => {
              if (!loaded || state.chosenSiteId) return;
              const preferredSiteId = rootGetters['user/profile/preferredSiteId'];
              if (preferredSiteId) {
                log.debug('setting active site=preferredSiteId', preferredSiteId);
                commit('chooseActiveSite', preferredSiteId);
              }
            }
        );
      }
    },
    onAuthStateChanged: {
      root: true,
      async handler({dispatch, commit, rootGetters}, authUser) {
        // no user, no need to load the sites
        if (!authUser) {
          log.debug('fetchSiteList: no user');
          commit('clearSites');
          return commit('app/clear', 'sites', {root: true});
        }
        // no access, no need to load the sites
        if (!rootGetters['auth/hasAppAccess']) {
          log.debug('fetchSiteList: no access');
          return commit('app/clear', 'sites', {root: true});
        }

        try {
          commit('app/loading', 'sites', {root: true});
          await dispatch('fetchSiteList');
        } catch (e) {
          log.error('fetchSiteList during auth change', e);
        } finally {
          commit('app/loaded', 'sites', {root: true});
        }
      }
    },
    async fetchSiteList({commit, rootGetters}) {
      const ns = rootGetters['auth/ns'];
      if (!ns) return;
      const defer = {};
      const _db = await db;
      defer.sites = _db.collection(`ns/${ns}/sites`).onSnapshot(
          snapshot => commit('applySitesQuerySnapshot', FirestoreUtil.prepareQuerySnapshot(snapshot)),
          err => log.error(`ns/${ns}/sites.onSnapshot`, err)
      );
      commit('defer', defer);
    }
  },
  modules: {
    aggregates: siteAggregates,
    active: {
      namespaced: true,
      getters: {
        doc(state, getters, rootState, rootGetters) {
          return rootGetters['sites/activeSiteDoc'];
        },
        /**
         * An array of available permissions for the active site
         *
         * @param {Object} state
         * @param {Object} getters
         * @param {Object} rootState
         * @return {string[]}
         */
        availablePermissions(state, getters, rootState) {
          const site = getters.doc;
          if (!site) {
            return [];
          }
          if (Array.isArray(site.availablePermissions)) {
            return site.availablePermissions;
          }
          const allPermissions = rootState.appConfig.permissions;
          const all = [];
          const add = perms => {
            for (const perm of perms) {
              all.push(perm.v);
              if (Array.isArray(perm.children)) {
                add(perm.children);
              }
            }
          };
          add(allPermissions);
          return all;
        },
        config(state, getters, rootState) {
          return new CascadingConfig([
            getters.doc || {},
            rootState.ns.doc || {}
          ]);
        },
        localeTag(state, getters) {
          return getters.config.get('locale', DefaultLocale);
        },
        locale(state, getters) {
          return getters.localeTag && new Intl.Locale(getters.localeTag);
        },
        localeWeekInfo(state, getters) {
          return getters.locale && getters.locale.weekInfo;
        },
        localeFirstDayOfWeek(state, getters) {
          // 1 Monday -> 7 Sunday
          // see https://tc39.es/proposal-intl-locale-info/#sec-week-info-of-locale
          let fdow = getters.localeWeekInfo && getters.localeWeekInfo.firstDay || 1; // default to Monday
          if (fdow === 7) {
            fdow = 0;
          }
          return fdow; // 0 Sunday - 6 Saturday
        },
        localeWeekend(state, getters) {
          // 1 Monday -> 7 Sunday
          // see https://tc39.es/proposal-intl-locale-info/#sec-week-info-of-locale
          // default to Saturday/Sunday
          const localeWeekend = getters.localeWeekInfo && getters.localeWeekInfo.weekend || [6, 7];
          return localeWeekend.map(dow => dow === 7 ? 0 : dow); // 0 Sunday - 6 Saturday
        },
        timeZone(state, getters) {
          return getters.config.get('timeZone', DefaultTimeZone);
        },
        workingHoursStartTime(state, getters) {
          return getters.config.get('workingHoursStartTime', 'startOfDay');
        },
        workingHoursEndTime(state, getters) {
          return getters.config.get('workingHoursEndTime', 'endOfDay');
        },
        statsWorkingDayStart(state, getters) {
          return getters.config.has('statsWorkingDayStart') ?
          getters.config.get('statsWorkingDayStart') : getters.workingHoursStartTime;
        },
        statsWorkingDayEnd(state, getters) {
          return getters.config.has('statsWorkingDayEnd') ?
          getters.config.get('statsWorkingDayEnd') : getters.workingHoursEndTime;
        },
        configCardIdType(state, getters) {
          return getters.config.get('cardIdType', 'ref');
        },
        hasDeskBooking(state, getters) {
          return getters.availablePermissions.includes('deskBooking');
        },
        hasRoomBooking(state, getters) {
          return getters.availablePermissions.includes('roomBooking');
        },
        deskBookingAdminAdvancedDays(state, getters) {
          return getters.config.get('deskBookingAdminAdvancedDays', 'unrestricted');
        },
        deskBookingAdminWeekends(state, getters) {
          return getters.config.get('deskBookingAdminWeekends', true);
        },
        deskBookingNsAdminAdvancedDays(state, getters) {
          return getters.config.get('deskBookingNsAdminAdvancedDays', getters.deskBookingAdminAdvancedDays);
        },
        deskBookingNsAdminWeekends(state, getters) {
          return getters.config.get('deskBookingNsAdminWeekends', getters.deskBookingAdminWeekends);
        },
        dashboardOccupancyRoomsEnabled(state, getters) {
          return getters.config.get('dashboardOccupancyRoomsEnabled', false);
        },
        dashboardOccupancyDesksEnabled(state, getters) {
          return getters.config.get('dashboardOccupancyDesksEnabled', false);
        },
        maskedFloorName(state, getters) {
          return getters.config.get('maskedFloorName', null);
        },
        departmentInputsEnabled(state, getters) {
          return getters.config.get('departmentInputsEnabled', false);
        }
      }
    }
  }
};
