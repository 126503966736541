import { render, staticRenderFns } from "./EditGroupSitesByCountry.vue?vue&type=template&id=7b0562d7&scoped=true&"
import script from "./EditGroupSitesByCountry.vue?vue&type=script&lang=js&"
export * from "./EditGroupSitesByCountry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b0562d7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
