<template>
  <v-row>
    <v-col cols="6">
      <v-card flat>
        <v-card-title>
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-icon v-on="on" class="mr-2">mdi-information</v-icon>
            </template>
            <template v-if="isHourly">
              Peak utilisation is calculated by taking the maximum utilisation across all days at each hour.
              <pre>peak per hour = max(all days at hour)</pre>
            </template>
            <template v-else>
              Peak utilisation is calculated by taking the maximum utilisation across all hours of each day.
              <pre>peak per day = max(all hours on day)</pre>
            </template>
          </v-tooltip>
          <h3>Peak Utilisation</h3>
          <chip-tooltip :color="colors[0]" child-class="ml-2">
            {{ peakReserved.toFixed(2) }}%
            <template #tooltip>Peak Reserved Utilisation</template>
          </chip-tooltip>
          <chip-tooltip :color="colors[1]" child-class="mx-2">
            {{ peakCheckedIn.toFixed(2) }}%
            <template #tooltip>Peak Checked-in Utilisation</template>
          </chip-tooltip>
          <chip-tooltip v-if="showOccupancyData" :color="colors[2]">
            {{ peakOccupied.toFixed(2) }}%
            <template #tooltip>Peak Occupied Utilisation</template>
          </chip-tooltip>
        </v-card-title>
        <apex-chart :options="peakGraphOptions" :series="peak"/>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card flat>
        <v-card-title>
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-icon v-on="on" class="mr-2">mdi-information</v-icon>
            </template>
            <template v-if="isHourly">
              Average utilisation is calculated by taking the mean across all days at each hour.
              <pre>avg per hour = sum(all days at hour) / (num. days)</pre>
            </template>
            <template v-else>
              Average utilisation is calculated by taking the mean across all hours of each day.
              <pre>avg per day = sum(all hours on day) / (num. hours)</pre>
            </template>
          </v-tooltip>
          <h3>Average Utilisation</h3>
          <chip-tooltip :color="colors[0]" child-class="mx-2">
            {{ meanReservedUtilisation.toFixed(2) }}%
            <template #tooltip>Average Reserved Utilisation</template>
          </chip-tooltip>
          <chip-tooltip :color="colors[1]" child-class="mr-2">
            {{ meanCheckedInUtilisation.toFixed(2) }}%
            <template #tooltip>Average Checked-in Utilisation</template>
          </chip-tooltip>
          <chip-tooltip v-if="showOccupancyData" :color="colors[2]">
            {{ meanOccupiedUtilisation.toFixed(2) }}%
            <template #tooltip>Average Occupied Utilisation</template>
          </chip-tooltip>
        </v-card-title>
        <apex-chart :options="meanGraphOptions" :series="mean"/>
      </v-card>
    </v-col>
    <template v-if="showCount">
      <v-col cols="6">
        <v-card flat>
          <v-card-title><h3>Peak Reservation Count</h3></v-card-title>
          <apex-chart :options="countGraphOptions" :series="peakCount"/>
        </v-card>
      </v-col>
      <v-col cols="6"/>
    </template>
    <template v-if="showByFloor">
      <v-col cols="12">
        <v-card flat>
          <v-card-title><h3>Peak Utilisation By Floor</h3></v-card-title>
        </v-card>
      </v-col>
      <template v-for="(name, id) in floorTitlesById">
        <v-col cols="6" :key="id">
          <h4 class="px-4">{{ name }} - {{ peakCheckedInByFloor[id].toFixed(2) }}%</h4>
          <apex-chart
              :options="peakGraphOptions"
              :series="[{name: 'Peak Utilisation', type: 'column', data: peakByFloor[id]}]"/>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex';
import {arrayRotate} from '@/util/array';
import {TimeScales} from '@/views/dashboard/statistics/statistics';
import ChipTooltip from '@/views/dashboard/statistics/ChipTooltip';
import {cloneDeep} from 'lodash';
import {paddedMovingAverage, toFixed} from '@/views/dashboard/statistics/stats-util';
import {asCountGraph, withAxisTitles, withFileName, withTrendLines} from '@/views/dashboard/statistics/graph-options';

const twoDp = toFixed(2);

export default {
  name: 'StatisticsTab',
  components: {ChipTooltip},
  props: {
    storeSuffix: {
      type: String,
      required: true
    },
    showByFloor: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: false
    },
    movingAverage: {
      type: Boolean,
      default: false
    },
    floorTitlesById: {
      type: Object,
      required: true
    },
    graphOptions: {
      type: Object,
      required: true
    },
    timeScale: {
      type: Number,
      required: true
    },
    showOccupancyData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('views/dashboard/statistics', [
      'midnightHour',
      'workingHoursStart',
      'workingHoursEnd',
      'statsWorkingDayStart',
      'statsWorkingDayEnd'
    ]),
    calculatedStartTime() {
      return this.statsWorkingDayStart !== null ? this.statsWorkingDayStart : this.workingHoursStartTime;
    },
    calculatedEndTime() {
      return this.statsWorkingDayEnd !== null ? this.statsWorkingDayEnd : this.workingHoursEndTime;
    },
    storePath() {
      return `views/dashboard/statistics/${this.storeSuffix}`;
    },
    isHourly() {
      return this.timeScale === TimeScales.Hourly;
    },
    colors() {
      return this.graphOptions.colors;
    },
    peakCheckedIn() {
      return this.$store.getters[`${this.storePath}/peakCheckedIn`];
    },
    peakCheckedInByFloor() {
      return this.$store.getters[`${this.storePath}/byFloor/peakCheckedIn`];
    },
    peakReserved() {
      return this.$store.getters[`${this.storePath}/peakReserved`];
    },
    peakOccupied() {
      return this.$store.getters[`${this.storePath}/peakOccupied`];
    },
    meanReservedUtilisation() {
      return this.$store.getters[`${this.storePath}/meanReservedUtilisation`];
    },
    meanCheckedInUtilisation() {
      return this.$store.getters[`${this.storePath}/meanCheckedInUtilisation`];
    },
    meanOccupiedUtilisation() {
      return this.$store.getters[`${this.storePath}/meanOccupiedUtilisation`];
    },

    countGraphOptions() {
      const opts = cloneDeep(this.graphOptions);
      asCountGraph(opts);
      withAxisTitles(opts, 'Peak Reservation Count');
      withFileName(opts, 'peak_reservation_count_');
      return opts;
    },
    meanGraphOptions() {
      const opts = cloneDeep(this.graphOptions);
      withAxisTitles(opts, 'Average Utilisation');
      withFileName(opts, 'average_utilisation_');

      const numberOfLines = this.showOccupancyData ? 3 : 2;
      withTrendLines(opts, numberOfLines, numberOfLines);
      return opts;
    },
    peakGraphOptions() {
      const opts = cloneDeep(this.graphOptions);
      withAxisTitles(opts, 'Peak Utilisation');
      withFileName(opts, 'peak_utilisation_');

      const numberOfLines = this.showOccupancyData ? 3 : 2;
      withTrendLines(opts, numberOfLines, numberOfLines);
      return opts;
    },
    hourlyPeakCount() {
      const peakAdHoc = this.$store.getters[`${this.storePath}/peakAdHocCountByHour`];
      const peakCheckedIn = this.$store.getters[`${this.storePath}/peakCheckedInCountByHour`];
      const peakReserved = this.$store.getters[`${this.storePath}/peakReservedCountByHour`];
      const peakOccupied = this.$store.getters[`${this.storePath}/peakOccupiedCountByHour`];
      const midnightHour = this.midnightHour;
      const adHoc = arrayRotate(peakAdHoc, midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);
      const checkedIn = arrayRotate(peakCheckedIn, midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime)
          .map((value, i) => value - adHoc[i]); // checked-in includes ad-hoc
      const reserved = arrayRotate(peakReserved, midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime)
          .map((value, i) => value - adHoc[i] - checkedIn[i]); // reserved includes checked-in & ad-hoc
      const occupied = arrayRotate(peakOccupied, midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);

      const series = [
        {
          name: 'Reserved',
          type: 'column',
          data: reserved.map(twoDp)
        },
        {
          name: 'Checked-in',
          type: 'column',
          data: checkedIn.map(twoDp)
        },
        {
          name: 'Ad-Hoc',
          type: 'column',
          data: adHoc.map(twoDp)
        }
      ];

      if (this.showOccupancyData) {
        series.push({
          name: 'Occupied',
          type: 'column',
          data: occupied.map(twoDp)
        });
      }
      return series;
    },
    dailyPeakCount() {
      const adHoc = this.$store.getters[`${this.storePath}/peakAdHocCountBySiteDay`];
      const checkedIn = this.$store.getters[`${this.storePath}/peakCheckedInCountBySiteDay`]
          .map((value, i) => value - adHoc[i]); // checked-in includes ad-hoc
      const reserved = this.$store.getters[`${this.storePath}/peakReservedCountBySiteDay`]
          .map((value, i) => value - adHoc[i] - checkedIn[i]); // reserved includes checked-in & ad-hoc
      const occupied = this.$store.getters[`${this.storePath}/peakOccupiedCountBySiteDay`];
      const series = [
        {
          name: 'Reserved',
          type: 'column',
          data: reserved.map(twoDp)
        },
        {
          name: 'Checked-in',
          type: 'column',
          data: checkedIn.map(twoDp)
        },
        {
          name: 'Ad-Hoc',
          type: 'column',
          data: adHoc.map(twoDp)
        }
      ];

      if (this.showOccupancyData) {
        series.push({
          name: 'Occupied',
          type: 'column',
          data: occupied.map(twoDp)
        });
      }

      return series;
    },

    hourlyMean() {
      const reserved = this.$store.getters[`${this.storePath}/meanReservedUtilisationByHour`];
      const checkedIn = this.$store.getters[`${this.storePath}/meanCheckedInUtilisationByHour`];
      const occupied = this.$store.getters[`${this.storePath}/meanOccupiedUtilisationByHour`];
      const reservedValues = arrayRotate(reserved, this.midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);
      const checkedInValues = arrayRotate(checkedIn, this.midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);
      const occupiedValues = arrayRotate(occupied, this.midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);

      const series = [
        {
          name: 'Avg. Reserved',
          type: 'column',
          data: reservedValues.map(twoDp)
        },
        {
          name: 'Avg. Checked-in',
          type: 'column',
          data: checkedInValues.map(twoDp)
        }
      ];
      if (this.showOccupancyData) {
        series.push(
            {
              name: 'Avg. Occupied',
              type: 'column',
              data: occupiedValues.map(twoDp)
            }
        );
      }
      if (this.movingAverage) {
        series.push(
            {
              name: 'Avg. Reserved Trend',
              type: 'line',
              data: paddedMovingAverage(reservedValues).map(twoDp)
            },
            {
              name: 'Avg. Checked-in Trend',
              type: 'line',
              data: paddedMovingAverage(checkedInValues).map(twoDp)
            }
        );
      }
      if (this.movingAverage && this.showOccupancyData) {
        series.push(
            {
              name: 'Avg. Occupied Trend',
              type: 'line',
              data: paddedMovingAverage(occupiedValues).map(twoDp)
            }
        );
      }

      return series;
    },
    hourlyPeak() {
      const reserved = this.$store.getters[`${this.storePath}/peakReservedByHour`];
      const checkedIn = this.$store.getters[`${this.storePath}/peakCheckedInByHour`];
      const occupied = this.$store.getters[`${this.storePath}/peakOccupiedByHour`];
      const reservedValues = arrayRotate(reserved, this.midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);
      const checkedInValues = arrayRotate(checkedIn, this.midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);
      const occupiedValues = arrayRotate(occupied, this.midnightHour)
          .slice(this.calculatedStartTime, this.calculatedEndTime);

      const series = [
        {
          name: 'Peak Reserved',
          type: 'column',
          data: reservedValues.map(twoDp)
        },
        {
          name: 'Peak Checked-in',
          type: 'column',
          data: checkedInValues.map(twoDp)
        }
      ];
      if (this.showOccupancyData) {
        series.push(
            {
              name: 'Peak Occupied',
              type: 'column',
              data: occupiedValues.map(twoDp)
            }
        );
      }
      if (this.movingAverage) {
        series.push(
            {
              name: 'Peak Reserved Trend',
              type: 'line',
              data: paddedMovingAverage(reservedValues).map(twoDp)
            },
            {
              name: 'Peak Checked-in Trend',
              type: 'line',
              data: paddedMovingAverage(checkedInValues).map(twoDp)
            }
        );
      }
      if (this.movingAverage && this.showOccupancyData) {
        series.push(
            {
              name: 'Peak Occupied Trend',
              type: 'line',
              data: paddedMovingAverage(occupiedValues).map(twoDp)
            }
        );
      }
      return series;
    },
    hourlyPeakByFloor() {
      const checkedInByHourByFloor = this.$store.getters[`${this.storePath}/byFloor/peakCheckedInByHour`];
      const byFloor = {};
      for (const [floor, byHour] of Object.entries(checkedInByHourByFloor)) {
        byFloor[floor] = arrayRotate(byHour, this.midnightHour)
            .slice(this.calculatedStartTime, this.calculatedEndTime)
            .map(twoDp);
      }
      return byFloor;
    },
    dailyMean() {
      const reserved = this.$store.getters[`${this.storePath}/meanReservedUtilisationBySiteDay`];
      const checkedIn = this.$store.getters[`${this.storePath}/meanCheckedInUtilisationBySiteDay`];
      const occupied = this.$store.getters[`${this.storePath}/meanOccupiedUtilisationBySiteDay`];
      const series = [
        {
          name: 'Avg. Reserved',
          type: 'column',
          data: reserved.map(twoDp)
        },
        {
          name: 'Avg. Checked-in',
          type: 'column',
          data: checkedIn.map(twoDp)
        }

      ];
      if (this.showOccupancyData) {
        series.push(
            {
              name: 'Avg. Occupied',
              type: 'column',
              data: occupied.map(twoDp)
            }
        );
      }
      if (this.movingAverage) {
        series.push(
            {
              name: 'Avg. Reserved Trend',
              type: 'line',
              data: paddedMovingAverage(reserved).map(twoDp)
            },
            {
              name: 'Avg. Checked-in Trend',
              type: 'line',
              data: paddedMovingAverage(checkedIn).map(twoDp)
            }
        );
      }
      if (this.movingAverage && this.showOccupancyData) {
        series.push(
            {
              name: 'Avg. Occupied Trend',
              type: 'line',
              data: paddedMovingAverage(occupied).map(twoDp)
            }
        );
      }
      return series;
    },
    dailyPeak() {
      const reserved = this.$store.getters[`${this.storePath}/peakReservedBySiteDay`];
      const checkedIn = this.$store.getters[`${this.storePath}/peakCheckedInBySiteDay`];
      const occupied = this.$store.getters[`${this.storePath}/peakOccupiedBySiteDay`];
      const series = [
        {
          name: 'Peak Reserved',
          type: 'column',
          data: reserved.map(twoDp)
        },
        {
          name: 'Peak Checked-in',
          type: 'column',
          data: checkedIn.map(twoDp)
        }
      ];
      if (this.showOccupancyData) {
        series.push(
            {
              name: 'Peak Occupied',
              type: 'column',
              data: occupied.map(twoDp)
            }
        );
      }
      if (this.movingAverage) {
        series.push(
            {
              name: 'Peak Reserved Trend',
              type: 'line',
              data: paddedMovingAverage(reserved).map(twoDp)
            },
            {
              name: 'Peak Checked-in Trend',
              type: 'line',
              data: paddedMovingAverage(checkedIn).map(twoDp)
            }
        );
      }
      if (this.movingAverage && this.showOccupancyData) {
        series.push(
            {
              name: 'Peak Occupied Trend',
              type: 'line',
              data: paddedMovingAverage(occupied).map(twoDp)
            }
        );
      }
      return series;
    },
    dailyPeakByFloor() {
      const checkedInByDayByFloor = this.$store.getters[`${this.storePath}/byFloor/peakCheckedInBySiteDay`];
      const byFloor = {};
      for (const [floor, byDay] of Object.entries(checkedInByDayByFloor)) {
        byFloor[floor] = byDay.map(twoDp);
      }
      return byFloor;
    },

    peak() {
      if (this.isHourly) {
        return this.hourlyPeak;
      }
      return this.dailyPeak;
    },
    peakByFloor() {
      if (this.isHourly) {
        return this.hourlyPeakByFloor;
      }
      return this.dailyPeakByFloor;
    },
    peakCount() {
      if (this.isHourly) {
        return this.hourlyPeakCount;
      }
      return this.dailyPeakCount;
    },
    mean() {
      if (this.isHourly) {
        return this.hourlyMean;
      }
      return this.dailyMean;
    }
  }
};
</script>

<style scoped>

</style>
