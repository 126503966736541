<template>
  <p>
    Site Settings
  </p>
</template>
<script>
export default {
  name: 'DeskSiteSettings'
};
</script>

<style scoped>

</style>
