import moment from 'moment';
import {endOfDay, fromYearMonthDay, startOfDay} from '@/util/dates';
import {add} from 'date-fns';
import {toDate} from './dates';
import {format, utcToZonedTime} from 'date-fns-tz';

/**
 * Convert a firebase Timestamp to a moment object
 *
 * @param {firebase.firestore.Timestamp|MomentInput} timestamp
 * @return {moment.Moment}
 */
export function toMoment(timestamp) {
  if (timestamp === null || timestamp === undefined) return timestamp;
  if (typeof timestamp.toDate === 'function') {
    return moment(timestamp.toDate());
  }
  return moment(timestamp);
}

/**
 * Returns a string with the format of 'HH:mm'
 *
 * @param {kahu.firestore.RelativeTime|string} time A time string hh:mm[:ss[.mmm]]
 * or one of the special values ['startOfDay', 'endOfDay']
 * @return {string}
 */
export function hoursMinutes(time) {
  if (time === 'endOfDay') return '23:59';
  if (time === 'startOfDay') return '00:00';
  return time;
}

/**
 * Returns a Date with the given time on the day specified by the first argument.
 *
 * @param {Date} day
 * @param {kahu.firestore.RelativeTime|string} time A time string hh:mm[:ss[.mmm]]
 * or one of the special values ['startOfDay', 'endOfDay']
 * @return {Date}
 */
export function timeOfDay(day, time) {
  if (time === 'endOfDay') return endOfDay(day);
  if (time === 'startOfDay') return startOfDay(day);

  const timeArr = time.split(':');
  const date = new Date(day.getTime());
  date.setHours(parseInt(timeArr[0]), parseInt(timeArr[1]));
  return date;
}

/**
 * @param {string} duration
 * @return {{time: number, unitTime: string}}
 */
export function parseDuration(duration) {
  const time = parseFloat(duration);
  const unit = duration.slice(time.toString().length);
  let unitTime = null;

  // date-fns add function uses text representations of units as variables
  // Get these from the possible duration values
  // See: https://date-fns.org/v2.23.0/docs/Duration
  switch (unit) {
    case 'd':
      unitTime = 'days';
      break;
    case 'h':
      unitTime = 'hours';
      break;
    case 'm':
      unitTime = 'minutes';
      break;
    case 's':
      unitTime = 'seconds';
      break;
    default:
      throw new Error('config value of catering edit time isn\'t a supported value');
  }

  return {time, unitTime};
}

/**
 * @param {string} duration
 * @param {...CanBeDate} start
 * @param {...CanBeDate} now
 * @return {boolean}
 */
export function compareEditDuration(duration, start, now) {
  const {time, unitTime} = parseDuration(duration);
  const startDate = toDate(start);
  let lastEditTime = add(startDate, {[unitTime]: time});
  if (unitTime === 'days') {
    lastEditTime = endOfDay(lastEditTime);
  }
  return toDate(now).getTime() < lastEditTime.getTime();
}

/**
 * Takes a date and a time string and returns a date with the set time in UTC time.
 * UTC time is required as all counts are stored in UTC
 *
 * @param {Date} date
 * @param {string} time
 * @return {Date}
 */
export function setHoursMinutesSeconds(date, time) {
  const [hours, minutes, seconds] = time.split(':');
  date.setUTCHours(parseInt(hours), parseInt(minutes), parseInt(seconds));
  return date;
}

/**
 * Takes a 'HH:mm' string, 'yyyy-MM-dd' date and the time zone for the site and returns a
 * formatted date and 'yyyy-MM-dd HH:mm' string in the correct time zone
 *
 * @param {string} time
 * @param {string} ymd
 * @param {string} timeZone
 * @return {[Date, string]}
 */
export function timeZoneFromCount(time, ymd, timeZone) {
  const timeString = time.replace('at', '');
  const hour = parseInt(timeString.split(':')[0]);
  const newDate = fromYearMonthDay(ymd);
  newDate.setHours(hour, 0, 0);

  const formattedDate = utcToZonedTime(newDate, timeZone);
  const formattedTime = format(formattedDate, 'yyyy-MM-dd HH:mm');
  return [formattedDate, formattedTime];
}

/**
 * Checks to see if a string is in the correct format for a time string
 * Valid: '1:30', '12:24', etc.
 * Invalid: '25:12', '01:67', etc.
 *
 * @param {string} timeString
 * @return {boolean}
 */
export function isAnInvalidTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  const hoursInvalid = parseInt(hours) >= 24 || parseInt(hours) < 0;
  const minutesInvalid = minutes.length !== 2 || parseInt(minutes) >= 60 || parseInt(minutes) < 0;
  return hoursInvalid || minutesInvalid;
}
