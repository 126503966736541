import DeferUtil from '@/store/defer-util';
import {LoadingUtil} from '@/util/loading';
import {Logger} from '@vanti/vue-logger';
import {decorateSnapshot} from '@/firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Vue from 'vue';
import {lastWeekDays, minDate, maxDate, toYearMonthDay, startOfDay, endOfDay, currentDate} from '@/util/dates';

const log = Logger.get('sync-report');

export default {
  namespaced: true,
  state: {
    day: currentDate(),
    reports: [],
    ...LoadingUtil.state()
  },
  getters: {
    ...LoadingUtil.getters(log),

    thirdPartyCollection(state, getters, rootState, rootGetters) {
      const ns = rootGetters['ns/nsRef'];
      if (!ns) {
        return;
      }
      return ns.collection('thirdparty');
    },
    week(state, getters, rootState, rootGetters) {
      return lastWeekDays(state.day, 8, true);
    },
    minDate(state, getters, rootState, rootGetters) {
      const min = minDate(...getters.week);
      return toYearMonthDay(min);
    },
    maxDate(state, getters, rootState, rootGetters) {
      const max = maxDate(...getters.week);
      return toYearMonthDay(max);
    },
    reportsByDate(state, getters) {
      const byDate = {};
      for (const report of state.reports) {
        byDate[toYearMonthDay(report.reportDate.toDate().toDateString())] = report;
      }
      return byDate;
    },
    currentReport(state, getters) {
      return getters.reportsByDate[toYearMonthDay(state.day)] || {};
    },
    currentReportDate(state, getters) {
      return getters.currentReport.reportDate || {};
    },
    currentReportPeriod(state, getters) {
      return getters.currentReport.reportPeriod || {};
    },
    currentReportData(state, getters) {
      if (!Object.keys(getters.currentReport).length) return [];
      const data = [];
      for (const [resource, info] of Object.entries(getters.currentReport.byResource)) {
        const {summary, title, bookings, site, countryCode} = info;
        const accuracy = (summary.accuracy * 100).toFixed(2) + '%';
        const missing = summary.m365EventsNotInKahu.toString();
        const accuracyKahu = (summary.accuracyKahu * 100).toFixed(2) + '%';
        const missingFromM365 = summary.kahuReservationsNotInM365.toString();
        data.push({
          title,
          resource,
          accuracy,
          missing,
          accuracyKahu,
          missingFromM365,
          bookings: bookings ? bookings : {},
          site,
          country: countryCode ? countryCode : 'Not specified'
        });
      }
      return data;
    },
    currentReportSummary(state, getters) {
      if (!Object.keys(getters.currentReport).length) return [];
      let summary = {};
      const resources = Object.entries(getters.currentReport.byResource);

      const hundredPerc = resources.filter(([k, v]) => v.summary.accuracy === 1);
      const greater95 = resources.filter(([k, v]) => v.summary.accuracy !== 1 && v.summary.accuracy >= 0.95);
      const lessThan95 = resources.filter(([k, v]) => v.summary.accuracy < 0.95);

      const hundredPercOutlook = resources.filter(([k, v]) => v.summary.accuracyKahu === 1);
      const greater95Outlook = resources.filter(([k, v]) =>
        v.summary.accuracyKahu !== 1 && v.summary.accuracyKahu >= 0.95
      );
      const lessThan95Outlook = resources.filter(([k, v]) => v.summary.accuracyKahu < 0.95);

      summary = {
        totalResources: {
          accuracy: resources?.length
        },
        hundredPerc: {
          accuracy: hundredPerc?.length,
          missing: (hundredPerc.length * 100 / resources.length).toFixed(2) + '%',
          accuracyOutlook: hundredPercOutlook?.length,
          missingFromOutlook: (hundredPercOutlook.length * 100 / resources.length).toFixed(2) + '%'
        },
        greater95: {
          accuracy: greater95?.length,
          missing: (greater95.length * 100 / resources.length).toFixed(2) + '%',
          accuracyOutlook: greater95Outlook?.length,
          missingFromOutlook: (greater95Outlook.length * 100 / resources.length).toFixed(2) + '%'
        },
        lessThan95: {
          accuracy: lessThan95?.length,
          missing: (lessThan95.length * 100 / resources.length).toFixed(2) + '%',
          accuracyOutlook: lessThan95Outlook?.length,
          missingFromOutlook: (lessThan95Outlook.length * 100 / resources.length).toFixed(2) + '%'
        }
      };

      return summary;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    ...LoadingUtil.mutations(),

    setReports: (state, payload) => {
      state.reports = payload;
    },
    setDay: (state, payload) => {
      Vue.set(state, 'day', payload);
    },
    resetDay: (state, payload) => {
      Vue.set(state, 'day', payload);
    }
  },
  actions: {
    init: {
      root: true,
      handler({commit, state, dispatch, rootGetters, getters}) {
        this.watch(
            () => state.day,
            () => dispatch('bind'),
            {immediate: true}
        );
      }
    },
    bind({commit, state, dispatch, getters, rootGetters}) {
      const thirdParty = getters['thirdPartyCollection'];
      if (!thirdParty) {
        return;
      }
      const ref = thirdParty.doc('m365').collection('syncReports2');
      const start = startOfDay(minDate(...getters.week));
      const end = endOfDay(maxDate(...getters.week));
      const fromDate = firebase.firestore.Timestamp.fromDate(start);
      const toDate = firebase.firestore.Timestamp.fromDate(end);

      const query = ref
          .where('reportDate', '>=', fromDate)
          .where('reportDate', '<', toDate);

      const defer = {};
      commit('loading', 'syncReports');
      defer.reports = query.onSnapshot(
          snapshot => {
            commit('setReports', snapshot.docs.map(doc => decorateSnapshot(doc)));
            commit('loaded', 'syncReports');
          },
          err => {
            log.error('syncReports.onSnapshot', err);
            commit('loaded', 'syncReports');
          }
      );
      commit('defer', defer);
    },
    unbind({commit}) {
      commit('reset');
      commit('resetDay', currentDate());
    }
  }
};
