<template>
  <v-row>
    <v-col cols="12">
      <permission-editor v-model="permissions" :available="availablePermissions" :person="person"/>
      <v-row justify="end">
        <v-col class="text-right">
          <v-btn color="accent" @click="updatePermissions" :disabled="noChanges" :loading="loading">
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PermissionEditor from '@/components/PermissionEditor';
import {cloneDeep} from 'lodash';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'PersonSitePermissions',
  components: {PermissionEditor},
  data() {
    return {
      loading: false,
      membershipType: 'permanent',
      permissions: {}
    };
  },
  computed: {
    ...mapGetters('views/person', ['activeMembership', 'person']),
    ...mapGetters('sites', ['activeSiteDoc']),
    ...mapState('appConfig', {allPermissions: 'permissions'}),
    availablePermissions() {
      // work with a copy
      const copy = cloneDeep(this.allPermissions);
      const site = this.activeSiteDoc;
      if (site && Array.isArray(site.availablePermissions)) {
        // filter based on the available permissions for the site
        const allowedPermissions = new Set(site.availablePermissions);
        const filter = items => {
          const filtered = items.filter(({v}) => allowedPermissions.has(v));
          filtered.forEach(item => {
            if (Array.isArray(item.children)) {
              item.children = filter(item.children);
            }
          });
          return filtered;
        };
        return filter(copy);
      }
      return copy;
    },
    visiblePermissions() {
      // make an object that only shows visible permissions that can be interacted with.
      const visiblePerms = {};
      const allPerms = new Set(Object.keys(this.permissions));

      const visitValues = (values) => {
        values.forEach(value => {
          if (allPerms.has(value.v)) {
            visiblePerms[value.v] = this.permissions[value.v];
          }
          if (value.children) {
            visitValues(value.children);
          }
        });
      };

      visitValues(this.availablePermissions);
      return visiblePerms;
    },
    noChanges() {
      const change = Object.keys(this.visiblePermissions)
          .filter(k => this.visiblePermissions[k] !== Boolean(this.personsPermissions[k]));
      return change.length === 0;
    },
    personsPermissions() {
      if (!this.activeMembership) {
        return {};
      }
      if (!this.activeMembership.permissions) {
        return {};
      }
      return this.activeMembership.permissions;
    }
  },
  watch: {
    personsPermissions: {
      handler(perms) {
        if (perms) {
          this.permissions = perms;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('views/person', ['createOrUpdateSiteMembership']),
    updatePermissions() {
      this.loading = true;
      const permissions = {
        options: {
          type: this.membershipType,
          permissions: this.permissions
        }
      };
      this.createOrUpdateSiteMembership(permissions)
          .then(() => this.$notify.showSuccess(`Permissions updated`))
          .catch(err => {
            const activeSiteId = (this.activeSiteDoc && this.activeSiteDoc.id) || '[no active site]';
            this.$logger.error('Error updating site permissions ' + activeSiteId, err);
            this.$notify.showError(`Error updating site permissions`);
          })
          .finally(() => this.loading = false);
    }
  }
};
</script>

<style scoped>

</style>
