import {Logger} from '@vanti/vue-logger';
import * as impl from '@/views/room-booking/calendar/v2/room-booking';
import {db} from '@/firebase';
import {chunkArray} from '@/util/chunks';
const log = Logger.get('booking-calendar');

export default {
  namespaced: true,
  actions: {
    /**
     * @param {*} [context]
     * @param {Object} options
     * @param {{sites: string[], resources: string[]}} options.selection
     * @param {Date} options.start
     * @param {Date} options.end
     * @return {Promise<firebase.firestore.QueryDocumentSnapshot<kahu.firestore.Booking>[]>}
     */
    async fetchBookings({rootState, rootGetters}, {selection, start, end}) {
      log.debug('fetchBookings', {selection, start, end});
      const _db = await db;
      const bookingsCol = _db.collection(rootGetters.nsRef + '/bookings');
      const resourcesCol = _db.collection(rootGetters.nsRef + '/resources');
      const resourcesBySiteId = rootState.resources.resourcesBySiteId;

      /**
       * @type {firebase.firestore.DocumentReference[]}
       */
      const resources = [];
      for (const siteId of selection.sites) {
        const r = resourcesBySiteId[siteId] || [];
        resources.push(...r.map(r => r.ref));
      }
      for (const resourceId of selection.resources) {
        resources.push(resourcesCol.doc(resourceId));
      }

      const resourceChunks = chunkArray(resources);
      const queries = resourceChunks.map(refs => {
        log.debug('fetchBookings', {refs});
        return impl.bookingQueryInCollection(bookingsCol, refs, start, end).get();
      });

      const res = await Promise.all(queries);
      const bookings = res.flatMap(snap => snap.docs);
      log.debug(`fetchBookings, got ${bookings.length} bookings`);
      return bookings;
    }
  }
};
