<template>
  <v-row class="pa-8 justify-space-around" style="height: 400px" no-gutters>
    <v-col cols="12" lg="4" md="3" sm="4" style="text-align: center">
      <live-status-chart :value="value" :icon="icon" :color="color"/>
    </v-col>
    <v-col align-self="center" justify="center" cols="12" lg="5" md="5" sm="6" class="pl-2">
      <b class="text-uppercase">Current {{ label }} Bookings</b>
      <v-row no-gutters>
        <v-col cols="8">Total Bookings:</v-col>
        <v-col cols="4" class="text-right">{{ booked }}</v-col>
        <v-col cols="8">Booked:</v-col>
        <v-col cols="4" class="text-right">{{ booked - checkedIn }}</v-col>
        <v-col cols="8">Checked In:</v-col>
        <v-col cols="4" class="text-right">{{ hasAdhoc ? checkedIn - adHoc : checkedIn }}</v-col>
        <v-col cols="8" v-if="hasAdhoc">Ad-Hoc:</v-col>
        <v-col cols="4" class="text-right" v-if="hasAdhoc">{{ adHoc }}</v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import LiveStatusChart from '@/views/dashboard/live-status/charts/LiveStatusChart';

export default {
  name: 'ChartRow',
  components: {LiveStatusChart},
  props: {
    value: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    booked: {
      type: Number,
      default: null
    },
    checkedIn: {
      type: Number,
      default: null
    },
    adHoc: {
      type: Number,
      default: null
    }
  },
  computed: {
    hasAdhoc() {
      return this.adHoc !== null;
    }
  }
};
</script>

<style scoped>

</style>
