import {cloneDate, dayOfWeek, fromYearMonthDayUTC, toISODate} from '@/util/dates';
import {chunkArray} from '@/util/chunks';
import FirestoreUtil, {byRef} from '@/store/firestore-util';
import {countsInRange, countsToTotals, countTotalAtTime} from '@/views/dashboard/counts/counts-util';
import DeferUtil from '@/store/defer-util';
import {LoadingUtil} from '@/util/loading';
import {
  countsForDay,
  hoursArray,
  maxWithDefault,
  minutesArray, objectWithKeys,
  weeklyPeaks
} from '@/views/dashboard/statistics/stats-util';
import * as ss from 'simple-statistics';
import {get as _get} from 'lodash';
import {
  checkedInCapacity,
  checkedInResource,
  PropNames,
  reservedAdHocResource,
  reservedCapacity,
  reservedResource,
  occupiedCapacity,
  occupiedResource
} from '@/views/dashboard/counts/booking-counts-util';

/**
 * @typedef {function(DecoratedData, Date[], string?): firebase.firestore.Query} GetCountsQuery
 */

export const countProps = Object.values(PropNames);

// /**
//  * @param {string} prop
//  * @return {boolean}
//  */
// const isCapacity = prop => prop.endsWith(CapacitySuffix);

/**
 * Vuex store which deals with statistics
 *
 * @param {Logger} log
 * @param {GetCountsQuery} getCountsQuery
 * @param {string} capacityGetter
 * @param {string} floorsGetter
 * @param {string} modulePath - slash-separate path to this module, used by sub-modules
 * @return {Object}
 */
export function createStatisticsStore(log, getCountsQuery, capacityGetter, floorsGetter, modulePath) {
  return {
    namespaced: true,
    state: {
      /**
       * Booking count documents by ref.
       *
       * @type {Object<string, kahu.firestore.stats.ByDayBookingCounts & DecoratedData>}
       */
      bookingCountsByRef: {},
      ...LoadingUtil.state()
    },
    getters: {
      ...LoadingUtil.getters(log),
      /**
       * Transform the booking counts documents into totals (rather than changes).
       *
       * @type {Object<string, CountTotals>}
       */
      bookingCountTotals(state) {
        if (!state.bookingCountsByRef || Object.keys(state.bookingCountsByRef).length === 0) return null;
        const counts = {};
        for (const count of Object.values(state.bookingCountsByRef)) {
          const ymd = toISODate(count.onDate);
          counts[ymd] = countsToTotals(count);
        }
        return counts;
      },
      /**
       * Take the booking count totals, and filter any outside of the selected dateRange.
       * When in a timezone that isn't UTC, count documents will be read from firestore that only some data is
       * needed from - this is because days in the site timezone may overlap two UTC days.
       *
       * @type {Object<string, CountTotals>}
       */
      bookingCountTotalsInRange(state, getters, rootState, rootGetters) {
        const countTotalsByDate = getters.bookingCountTotals;
        if (!countTotalsByDate) return null;
        const dateRange = rootGetters['views/dashboard/statistics/dateRange'];
        if (!dateRange) return countTotalsByDate;
        const counts = {};
        for (const [key, totals] of Object.entries(countTotalsByDate)) {
          // filter out any totals outside of the dateRange
          counts[key] = countsInRange(totals, dateRange.start, dateRange.end);
        }
        return counts;
      },
      /**
       * An object with an entry for each (UTC) day in the selected dateRange.
       *
       * @type {Object<string, CountTotals>}
       */
      countTotalsByDate(state, getters, rootState, rootGetters) {
        if (!getters.bookingCountTotalsInRange) return null;
        const dates = rootGetters['views/dashboard/statistics/ymdDates'];

        return dates.reduce((byDate, date) => {
          byDate[date] = getters.bookingCountTotalsInRange[date] || null;
          return byDate;
        }, {});
      },
      /** @type {CountValuesByHourByMinute} */
      countsByHourByMinute(state, getters, rootState, rootGetters) {
        const hours = hoursArray(() => minutesArray(() => []));
        if (!getters.countTotalsByDate) return hours;

        const excludeWeekends = _get(rootState, 'views.dashboard.statistics.options.excludeWeekends');
        const siteTimeZone = rootGetters && rootGetters['sites/active/timeZone'];
        const localeWeekend = rootGetters && rootGetters['sites/active/localeWeekend'];

        for (const ymd of Object.keys(getters.countTotalsByDate)) {
          /** @type {CountTotals} */
          const counts = getters.countTotalsByDate[ymd];
          if (!counts) continue;
          let time = fromYearMonthDayUTC(ymd);

          for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute++) {
              time = cloneDate(time);
              time.setUTCHours(hour, minute);

              if (excludeWeekends && localeWeekend.includes(dayOfWeek(time, siteTimeZone))) {
                continue;
              }

              const count = counts && countTotalAtTime(counts, time);
              const values = {};
              for (const prop of countProps) {
                values[prop] = count && count.totals[prop] || 0;
              }
              hours[hour][minute].push(values);
            }
          }
        }
        return hours;
      },
      /** @type {CountsByHour} */
      peakCountsByHour(state, getters) {
        const peaksByHour = hoursArray(() => ({}));
        for (let hour = 0; hour < 24; hour++) {
          const peaks = peaksByHour[hour];
          const byMinute = getters.countsByHourByMinute[hour];
          for (const prop of countProps) {
            peaks[prop] = maxWithDefault(byMinute.flatMap(entries => entries.map(entry => entry[prop] || 0)));
          }
        }
        return peaksByHour;
      },
      /** @type {CountsByHour} */
      meanCountsByHour(state, getters, rootState, rootGetters) {
        const dateCount = rootGetters['views/dashboard/statistics/numDaysInRange'];
        const meanByHour = hoursArray(() => ({}));
        for (let hour = 0; hour < 24; hour++) {
          const peaks = meanByHour[hour];
          const byMinute = getters.countsByHourByMinute[hour];
          for (const prop of countProps) {
            const values = ss.sum(byMinute.flatMap(entries => entries.map(entry => entry[prop] || 0)));
            peaks[prop] = values / (dateCount * 60);
          }
        }
        return meanByHour;
      },

      /**
       * Group countTotals by site timezone days (these can span multiple UTC days).
       * One array entry for each day in the selected dateRange.
       *
       * @type {CountTotals[]}
       */
      countTotalsBySiteDay(state, getters, rootState, rootGetters) {
        if (!getters.bookingCountTotalsInRange) return [];
        const siteDays = rootGetters['views/dashboard/statistics/siteDays'];
        return siteDays.map(day => countsForDay(getters.bookingCountTotalsInRange, day));
      },
      countsBySiteDayByHourByMinute(state, getters, rootState, rootGetters) {
        const excludeWeekends = _get(rootState, 'views.dashboard.statistics.options.excludeWeekends');
        const siteTimeZone = rootGetters && rootGetters['sites/active/timeZone'];
        const localeWeekend = rootGetters && rootGetters['sites/active/localeWeekend'];
        /** @type {Date[]} */
        const siteDays = rootGetters && rootGetters['views/dashboard/statistics/siteDays'];
        /** @type {CountTotals[]} */
        const countTotalsBySiteDay = getters.countTotalsBySiteDay;

        const bySiteDay = [];

        for (let i = 0; i < siteDays.length; i++) {
          const siteDay = siteDays[i];
          const countTotals = countTotalsBySiteDay[i];
          const hours = hoursArray(() => minutesArray(() => ({})));

          const time = cloneDate(siteDay);
          for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute++) {
              if (excludeWeekends && localeWeekend.includes(dayOfWeek(time, siteTimeZone))) {
                continue;
              }

              const count = countTotals && countTotalAtTime(countTotals, time);
              for (const prop of countProps) {
                hours[hour][minute][prop] = count && count.totals[prop] || 0;
              }
              time.setUTCMinutes(time.getUTCMinutes() + 1);
            }
          }

          bySiteDay.push(hours);
        }
        return bySiteDay;
      },
      /**
       * Peak (max) counts, with an entry per site timezone day, in the selected dateRange.
       *
       *  @type {CountsByDay}
       */
      peakCountsBySiteDay(state, getters) {
        return getters.countTotalsBySiteDay.map(counts => {
          const peaks = {};
          for (const prop of countProps) {
            const values = counts.map(count => count.totals[prop] || 0);
            peaks[prop] = values.length > 0 && ss.max(values) || 0;
          }
          return peaks;
        });
      },

      /**
       * @type {ValueByHourByMinute}
       */
      peakReservedByHour(state, getters, rootState, rootGetters) {
        if (!getters.countTotalsByDate) return [];
        const capacity = rootGetters[capacityGetter];

        const maxByHour = getters.peakCountsByHour.map(entry => entry[reservedCapacity]);

        return maxByHour.map(max => Math.min(max * 100 / capacity, 100));
      },
      peakReserved(state, getters) {
        return maxWithDefault(getters.peakReservedByHour);
      },
      /**
       * The peak reserved capacity (percentage), with an entry per site timezone day, in the selected dateRange.
       *
       * @type {number[]}
       */
      peakReservedBySiteDay(state, getters, rootState, rootGetters) {
        const capacity = rootGetters[capacityGetter];
        return getters.peakCountsBySiteDay.map(max => {
          return Math.min(max[reservedCapacity] * 100 / capacity, 100);
        });
      },
      /**
       * The peak reserved capacity (percentage), with an entry per 7-day period, in the selected dateRange.
       *
       * @type {number[]}
       */
      peakReservedByWeek(state, getters, rootState, rootGetters) {
        const numWeeks = rootGetters['views/dashboard/statistics/numWeeksInRange'];
        const weekLength = rootGetters['views/dashboard/statistics/weekLength'];
        return weeklyPeaks(numWeeks, getters.peakReservedBySiteDay, weekLength);
      },

      /**
       * The peak occupied capacity (percentage), with an entry per site timezone day, in the selected dateRange.
       *
       * @type {number[]}
       */
      peakOccupiedBySiteDay(state, getters, rootState, rootGetters) {
        const capacity = rootGetters[capacityGetter];
        return getters.peakCountsBySiteDay.map(max => {
          return Math.min(max[occupiedCapacity] * 100 / capacity, 100);
        });
      },
      /**
       * @type {ValueByHourByMinute}
       */
      peakOccupiedByHour(state, getters, rootState, rootGetters) {
        if (!getters.countTotalsByDate) return [];
        const capacity = rootGetters[capacityGetter];

        const maxByHour = getters.peakCountsByHour.map(entry => entry[occupiedCapacity]);

        return maxByHour.map(max => Math.min(max * 100 / capacity, 100));
      },
      peakOccupied(state, getters) {
        return maxWithDefault(getters.peakOccupiedByHour);
      },

      /** @type {ValueByHour} */
      peakCheckedInByHour(state, getters, rootState, rootGetters) {
        if (!getters.countTotalsByDate) return [];
        const capacity = rootGetters[capacityGetter];

        const maxByHour = getters.peakCountsByHour.map(entry => entry[checkedInCapacity]);

        return maxByHour.map(max => Math.min(max * 100 / capacity, 100));
      },
      peakCheckedIn(state, getters) {
        return maxWithDefault(getters.peakCheckedInByHour);
      },
      /**
       * The peak CheckedIn capacity (percentage), with an entry per site timezone day, in the selected dateRange.
       *
       * @type {number[]}
       */
      peakCheckedInBySiteDay(state, getters, rootState, rootGetters) {
        const capacity = rootGetters[capacityGetter];
        return getters.peakCountsBySiteDay.map(max => {
          return Math.min(max[checkedInCapacity] * 100 / capacity, 100);
        });
      },
      /**
       * The peak CheckedIn capacity (percentage), with an entry per 7-day period, in the selected dateRange.
       *
       * @type {number[]}
       */
      peakCheckedInByWeek(state, getters, rootState, rootGetters) {
        const numWeeks = rootGetters['views/dashboard/statistics/numWeeksInRange'];
        const weekLength = rootGetters['views/dashboard/statistics/weekLength'];
        return weeklyPeaks(numWeeks, getters.peakCheckedInBySiteDay, weekLength);
      },

      /** @type {ValueByHour} */
      peakAdHocCountByHour(state, getters) {
        if (!getters.countTotalsByDate) return [];
        return getters.peakCountsByHour.map(entry => entry[reservedAdHocResource]);
      },
      peakAdHocCountBySiteDay(state, getters) {
        return getters.peakCountsBySiteDay.map(entry => entry[reservedAdHocResource]);
      },
      peakOccupiedCountBySiteDay(state, getters) {
        return getters.peakCountsBySiteDay.map(entry => entry[occupiedResource]);
      },
      /** @type {ValueByHour} */
      peakCheckedInCountByHour(state, getters) {
        if (!getters.countTotalsByDate) return [];
        return getters.peakCountsByHour.map(entry => entry[checkedInResource]);
      },
      peakCheckedInCountBySiteDay(state, getters) {
        return getters.peakCountsBySiteDay.map(entry => entry[checkedInResource]);
      },
      /** @type {ValueByHour} */
      peakReservedCountByHour(state, getters) {
        if (!getters.countTotalsByDate) return [];
        return getters.peakCountsByHour.map(entry => entry[reservedResource]);
      },
      /** @type {ValueByHour} */
      peakOccupiedCountByHour(state, getters) {
        if (!getters.countTotalsByDate) return [];
        return getters.peakCountsByHour.map(entry => entry[occupiedResource]);
      },
      peakReservedCountBySiteDay(state, getters) {
        return getters.peakCountsBySiteDay.map(entry => entry[reservedResource]);
      },

      /** @type {ValueByHour} */
      meanReservedUtilisationByHour(state, getters, rootState, rootGetters) {
        if (!getters.countTotalsByDate) return [];
        const capacity = rootGetters[capacityGetter];
        return getters.meanCountsByHour.map(avg => Number(Math.min(avg[reservedCapacity] * 100 / capacity, 100)));
      },
      meanReservedUtilisation(state, getters, rootState, rootGetters) {
        const workingHoursStart = rootGetters['views/dashboard/statistics/workingHoursStart'];
        const workingHoursEnd = rootGetters['views/dashboard/statistics/workingHoursEnd'];
        const entries = getters.meanReservedUtilisationByHour
            .slice(workingHoursStart, workingHoursEnd);
        if (entries.length === 0) {
          return 0;
        }
        return ss.sumSimple(entries) / entries.length;
      },
      /** @type {number[]} */
      meanReservedUtilisationBySiteDay(state, getters, rootState, rootGetters) {
        const capacity = rootGetters[capacityGetter];
        return getters.meanCountsBySiteDay
            .map(means => Number(Math.min(means[reservedCapacity] * 100 / capacity, 100)));
      },

      /** @type {ValueByHour} */
      meanCheckedInUtilisationByHour(state, getters, rootState, rootGetters) {
        if (!getters.countTotalsByDate) return [];
        const capacity = rootGetters[capacityGetter];
        return getters.meanCountsByHour
            .map(avg => Number(Math.min(avg[checkedInCapacity] * 100 / capacity, 100)));
      },
      meanCheckedInUtilisation(state, getters, rootState, rootGetters) {
        const workingHoursStart = rootGetters['views/dashboard/statistics/workingHoursStart'];
        const workingHoursEnd = rootGetters['views/dashboard/statistics/workingHoursEnd'];
        const entries = getters.meanCheckedInUtilisationByHour
            .slice(workingHoursStart, workingHoursEnd);
        if (entries.length === 0) {
          return 0;
        }
        return ss.sumSimple(entries) / entries.length;
      },
      /** @type {number[]} */
      meanCheckedInUtilisationBySiteDay(state, getters, rootState, rootGetters) {
        const capacity = rootGetters[capacityGetter];
        return getters.meanCountsBySiteDay
            .map(means => Number(Math.min(means[checkedInCapacity] * 100 / capacity, 100)));
      },

      /** @type {ValueByHour} */
      meanOccupiedUtilisationByHour(state, getters, rootState, rootGetters) {
        if (!getters.countTotalsByDate) return [];
        const capacity = rootGetters[capacityGetter];
        return getters.meanCountsByHour
            .map(avg => Number(Math.min(avg[occupiedCapacity] * 100 / capacity, 100)));
      },
      meanOccupiedUtilisation(state, getters, rootState, rootGetters) {
        const workingHoursStart = rootGetters['views/dashboard/statistics/workingHoursStart'];
        const workingHoursEnd = rootGetters['views/dashboard/statistics/workingHoursEnd'];
        const entries = getters.meanOccupiedUtilisationByHour
            .slice(workingHoursStart, workingHoursEnd);
        if (entries.length === 0) {
          return 0;
        }
        return ss.sumSimple(entries) / entries.length;
      },
      /** @type {number[]} */
      meanOccupiedUtilisationBySiteDay(state, getters, rootState, rootGetters) {
        const capacity = rootGetters[capacityGetter];
        return getters.meanCountsBySiteDay
            .map(means => Number(Math.min(means[occupiedCapacity] * 100 / capacity, 100)));
      },

      /** @type {CountsByHour[]} */
      meanCountsBySiteDayByHour(state, getters) {
        return getters.countsBySiteDayByHourByMinute.map(byHourByMinute => {
          const meanByHour = hoursArray(() => ({}));
          for (let hour = 0; hour < 24; hour++) {
            const means = meanByHour[hour];
            const byMinute = byHourByMinute[hour];
            for (const prop of countProps) {
              const values = ss.sum(byMinute.map(entry => entry[prop] || 0));
              means[prop] = values / 60;
            }
          }
          return meanByHour;
        });
      },
      /** @type {CountValues} */
      meanCountsBySiteDay(state, getters, rootState, rootGetters) {
        const workingHoursStart = rootGetters['views/dashboard/statistics/workingHoursStart'];
        const workingHoursEnd = rootGetters['views/dashboard/statistics/workingHoursEnd'];
        return getters.meanCountsBySiteDayByHour
            .map(byHour => {
              const entries = byHour.slice(workingHoursStart, workingHoursEnd);
              const means = {};
              for (const prop of countProps) {
                const total = ss.sum(entries.map(entry => entry[prop] || 0));
                means[prop] = total / entries.length;
              }
              return means;
            });
      }
    },
    mutations: {
      ...LoadingUtil.mutations(),
      ...DeferUtil.mutations(log),
      setBookingCounts(state, bc) {
        state.bookingCountsByRef = bc;
      },
      /**
       * Update the cache of booking counts - only use this for onSnapshot updates since it will cause
       * getters to recompute which can be expensive with large data sets.
       *
       * @param {*} state
       * @param {firebase.firestore.DocumentChange[]} changes
       */
      updateBookingCounts(state, changes) {
        FirestoreUtil.indexQuerySnapshotUpdates(state.bookingCountsByRef, changes, byRef);
      },
      clear(state) {
        state.bookingCountsByRef = {};
      }
    },
    actions: {
      /**
       * @param {*} context
       * @param {Date[]} dates
       * @return {Promise<void>}
       */
      async watchCountsForDates({commit, rootGetters, dispatch}, dates) {
        const activeSite = rootGetters['sites/activeSiteDoc'];

        commit('loading', 'counts');
        commit('setBookingCounts', {});

        const chunks = chunkArray(dates);
        if (chunks.length === 0) {
          commit('loaded', 'counts');
          return;
        }

        const loaded = Array(chunks.length).fill(false);
        // local cache of data until all queries have loaded once
        // this is to stop getters needing to recompute each time a snapshot comes in
        const countsByRef = {};

        const setLoaded = (i) => {
          loaded[i] = true;
          log.debug('query loaded', i);
          if (loaded.every(l => l)) {
            log.debug('all loaded');
            commit('loaded', 'counts');
            commit('setBookingCounts', countsByRef);
          }
        };

        const defer = {};
        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i];
          if (chunk.length !== 0) {
            const key = `counts-${i}`;
            const dateQuery = getCountsQuery(activeSite, chunk);
            defer[key] = dateQuery.onSnapshot(
                snap => {
                  if (loaded[i]) {
                    commit('updateBookingCounts', FirestoreUtil.prepareQuerySnapshot(snap));
                  } else {
                    setLoaded(i);
                    const querySnapshot = FirestoreUtil.prepareQuerySnapshot(snap);
                    FirestoreUtil.indexQuerySnapshotUpdates(countsByRef, querySnapshot, byRef);
                  }
                },
                err => {
                  log.error(`watchCountsForDates.chunk-${i}`, err);
                  setLoaded(i);
                }
            );
          }
        }
        commit('defer', defer);
      },
      unbind({commit}) {
        commit('reset');
        commit('clear');
        commit('resetLoaded', {});
      }
    },
    modules: {
      byFloor: {
        namespaced: true,
        state: {},
        getters: {
          countTotalsByDate(state, getters, rootState, rootGetters) {
            return rootGetters[`${modulePath}/countTotalsByDate`];
          },
          countTotalsBySiteDay(state, getters, rootState, rootGetters) {
            return rootGetters[`${modulePath}/countTotalsBySiteDay`];
          },
          /**
           * @type {CountValuesByFloorByHourByMinute}
           */
          countsByHourByMinute(state, getters, rootState, rootGetters) {
            const floorIds = rootGetters[floorsGetter];
            const hoursByFloor = objectWithKeys(floorIds, () => hoursArray(() => minutesArray(() => [])));
            const byFloor = rootState.views.dashboard.statistics.options.byFloor;
            if (!getters.countTotalsByDate || !byFloor) return hoursByFloor;

            const excludeWeekends = rootState.views.dashboard.statistics.options.excludeWeekends;
            const siteTimeZone = rootGetters['sites/active/timeZone'];
            const localeWeekend = rootGetters['sites/active/localeWeekend'];

            for (const floorId of floorIds) {
              const hours = hoursByFloor[floorId];
              for (const ymd of Object.keys(getters.countTotalsByDate)) {
                /** @type {CountTotals} */
                const counts = getters.countTotalsByDate[ymd];
                if (!counts) continue;
                let time = fromYearMonthDayUTC(ymd);

                for (let hour = 0; hour < 24; hour++) {
                  for (let minute = 0; minute < 60; minute++) {
                    time = cloneDate(time);
                    time.setUTCHours(hour, minute);

                    if (excludeWeekends && localeWeekend.includes(dayOfWeek(time, siteTimeZone))) {
                      continue;
                    }

                    const count = counts && countTotalAtTime(counts, time);
                    const values = objectWithKeys(floorIds);
                    for (const prop of countProps) {
                      values[prop] = _get(count, `totals.byFloor.${floorId}.${prop}`) || 0;
                    }

                    hours[hour][minute].push(values);
                  }
                }
              }
            }
            return hoursByFloor;
          },
          /** @type {CountsByFloorByHour} */
          peakCountsByHour(state, getters, rootState, rootGetters) {
            const floorIds = rootGetters[floorsGetter];
            /** @type {CountsByFloorByHour} */
            const peaksByFloorByHour = objectWithKeys(floorIds, () => hoursArray(() => ({})));
            for (const floorId of floorIds) {
              const peaksByHour = peaksByFloorByHour[floorId];
              const countsByHourByMinute = getters.countsByHourByMinute[floorId];
              for (let hour = 0; hour < 24; hour++) {
                const peaks = peaksByHour[hour];
                const byMinute = countsByHourByMinute[hour];
                for (const prop of countProps) {
                  peaks[prop] = maxWithDefault(byMinute.flatMap(entries => entries.map(entry => entry[prop] || 0)));
                }
              }
            }
            return peaksByFloorByHour;
          },
          /** @type {ValueByFloorByHour} */
          peakCheckedInByHour(state, getters, rootState, rootGetters) {
            const capacityByFloor = rootGetters[capacityGetter + 'ByFloor'];

            const peakByFloor = {};
            for (const [floor, counts] of Object.entries(getters.peakCountsByHour)) {
              const peakCheckedIn = counts.map(entry => entry[checkedInCapacity]);
              peakByFloor[floor] = peakCheckedIn.map(peak => peak * 100 / capacityByFloor[floor]);
            }

            return peakByFloor;
          },
          /** @type {ValueByFloor} */
          peakCheckedIn(state, getters) {
            const peaksByFloor = {};
            for (const [floor, byHour] of Object.entries(getters.peakCheckedInByHour)) {
              peaksByFloor[floor]= maxWithDefault(byHour);
            }
            return peaksByFloor;
          },

          /**
           * Peak (max) counts, with an entry per site timezone day, in the selected dateRange.
           *
           *  @type {ByFloor<CountsByDay>}
           */
          peakCountsBySiteDay(state, getters, rootState, rootGetters) {
            const floorIds = rootGetters[floorsGetter];
            const byFloor = {};
            for (const floorId of floorIds) {
              byFloor[floorId] = getters.countTotalsBySiteDay.map(counts => {
                const peaks = {};
                for (const prop of countProps) {
                  const values = counts.map(count => _get(count, `totals.byFloor.${floorId}.${prop}`) || 0);
                  peaks[prop] = values.length > 0 && ss.max(values) || 0;
                }
                return peaks;
              });
            }

            return byFloor;
          },
          /**
           * The peak CheckedIn capacity (percentage), with an entry per site timezone day, in the selected dateRange.
           *
           * @type {ByFloor<number[]>}
           */
          peakCheckedInBySiteDay(state, getters, rootState, rootGetters) {
            const capacityByFloor = rootGetters[capacityGetter + 'ByFloor'];
            const byFloor = {};

            for (const [floor, peaks] of Object.entries(getters.peakCountsBySiteDay)) {
              byFloor[floor] = peaks.map(max => {
                return max[checkedInCapacity] * 100 / capacityByFloor[floor];
              });
            }
            return byFloor;
          }
        }
      },
      heatmap: {
        namespaced: true,
        state: {
          /**
           * Booking count documents by ref.
           *
           * @type {Object<string, kahu.firestore.stats.ByDayBookingResourceCounts & DecoratedData>}
           */
          bookingResourceCountsByRef: {},
          ...LoadingUtil.state()
        },
        getters: {
          ...LoadingUtil.getters(log),
          /**
           * An object where the keys are the resource-kind, e.g. `byDesk`, and the values are the list of
           * resource ids for that kind. Only includes resources mentioned in the loaded counts docs.
           *
           * e.g.
           * <pre>
           *  {
           *    byDesk: ['D1.001', 'D1.002']
           *  }
           * </pre>
           *
           * @type {Object<string, Set<string>>}
           */
          paths(state) {
            const paths = {};
            for (const count of Object.values(state.bookingResourceCountsByRef || {})) {
              for (const changes of Object.values(count.changes || {})) {
                for (const [byKindKey, entries] of Object.entries(changes || {})) {
                  if (!byKindKey.startsWith('by')) {
                    continue;
                  }
                  const ids = paths[byKindKey] || (paths[byKindKey] = new Set());
                  for (const id of Object.keys(entries || {})) {
                    ids.add(id);
                  }
                }
              }
            }
            return paths;
          },
          /**
           * Transform the booking counts documents into totals (rather than changes).
           *
           * @type {Object<string, ByResourceCountTotal>}
           */
          bookingCountTotals(state) {
            if (!state.bookingResourceCountsByRef || Object.keys(state.bookingResourceCountsByRef).length === 0) {
              return null;
            }
            const counts = {};
            for (const count of Object.values(state.bookingResourceCountsByRef)) {
              const ymd = toISODate(count.onDate);
              counts[ymd] = countsToTotals(count);
            }
            return counts;
          },
          /**
           * Take the booking count totals, and filter any outside of the selected dateRange.
           * When in a timezone that isn't UTC, count documents will be read from firestore that only some data is
           * needed from - this is because days in the site timezone may overlap two UTC days.
           *
           * @type {Object<string, ByResourceCountTotal>}
           */
          bookingCountTotalsInRange(state, getters, rootState, rootGetters) {
            const countTotalsByDate = getters.bookingCountTotals;
            if (!countTotalsByDate) return null;
            const dateRange = rootGetters['views/dashboard/statistics/dateRange'];
            if (!dateRange) return countTotalsByDate;
            const counts = {};
            for (const [key, totals] of Object.entries(countTotalsByDate)) {
              // filter out any totals outside of the dateRange
              counts[key] = countsInRange(totals, dateRange.start, dateRange.end);
            }
            return counts;
          },
          /**
           * An object with an entry for each (UTC) day in the selected dateRange.
           *
           * @type {Object<string, ByResourceCountTotal>}
           */
          countTotalsByDate(state, getters, rootState, rootGetters) {
            if (!getters.bookingCountTotalsInRange) return null;
            const dates = rootGetters['views/dashboard/statistics/ymdDates'];

            return dates.reduce((byDate, date) => {
              byDate[date] = getters.bookingCountTotalsInRange[date] || null;
              return byDate;
            }, {});
          },
          /** @type {ByHour<ByMinute<ByKind<ById<Counts>>[]>>} */
          countsByHourByMinute(state, getters, rootState, rootGetters) {
            const hours = hoursArray(() => minutesArray(() => []));
            if (!getters.countTotalsByDate) return hours;

            const excludeWeekends = _get(rootState, 'views.dashboard.statistics.options.excludeWeekends');
            const siteTimeZone = rootGetters && rootGetters['sites/active/timeZone'];
            const localeWeekend = rootGetters && rootGetters['sites/active/localeWeekend'];
            const paths = getters.paths;

            for (const ymd of Object.keys(getters.countTotalsByDate)) {
              /** @type {CountTotals} */
              const counts = getters.countTotalsByDate[ymd];
              if (!counts) continue;
              let time = fromYearMonthDayUTC(ymd);

              for (let hour = 0; hour < 24; hour++) {
                for (let minute = 0; minute < 60; minute++) {
                  time = cloneDate(time);
                  time.setUTCHours(hour, minute);

                  if (excludeWeekends && localeWeekend.includes(dayOfWeek(time, siteTimeZone))) {
                    continue;
                  }

                  const count = counts && countTotalAtTime(counts, time);
                  const values = {};
                  for (const [kind, ids] of Object.entries(paths)) {
                    values[kind] = {};
                    for (const id of ids) {
                      values[kind][id] = {};
                      for (const prop of countProps) {
                        values[kind][id][prop] = count &&
                            count.totals[kind] &&
                            count.totals[kind][id] &&
                            count.totals[kind][id][prop] || 0;
                      }
                    }
                  }
                  hours[hour][minute].push(values);
                }
              }
            }
            return hours;
          },
          /** @type {ByHour<ByKind<ById<Counts>>>} */
          meanCountsByHour(state, getters, rootState, rootGetters) {
            const dateCount = rootGetters['views/dashboard/statistics/numDaysInRange'];
            const meanByHour = hoursArray(() => ({}));
            const paths = getters.paths;
            for (let hour = 0; hour < 24; hour++) {
              const peaks = meanByHour[hour];
              const byMinute = getters.countsByHourByMinute[hour];
              for (const [kind, ids] of Object.entries(paths)) {
                peaks[kind] = {};
                for (const id of ids) {
                  peaks[kind][id] = {};
                  for (const prop of countProps) {
                    const values = ss.sum(byMinute.flatMap(entries => entries.map(entry => entry[kind] &&
                        entry[kind][id] &&
                        entry[kind][id][prop] || 0)));
                    peaks[kind][id][prop] = values / (dateCount * 60);
                  }
                }
              }
            }
            return meanByHour;
          },
          /** @type {ByHour<ByKind<ById<number>>>} */
          meanReservedUtilisationByHour(state, getters) {
            if (!getters.countTotalsByDate) return [];
            const paths = getters.paths;
            const byHour = {};
            for (const [kind, ids] of Object.entries(paths)) {
              byHour[kind] = {};
              for (const id of ids) {
                byHour[kind][id] = getters.meanCountsByHour
                    .map(avg => Number(Math.min(avg[kind][id][reservedResource] * 100, 100)));
              }
            }
            return byHour;
          },
          /** @type {ByKind<ById<number>>} */
          meanReservedUtilisation(state, getters, rootState, rootGetters) {
            const workingHoursStart = rootGetters['views/dashboard/statistics/workingHoursStart'];
            const workingHoursEnd = rootGetters['views/dashboard/statistics/workingHoursEnd'];
            const paths = getters.paths;
            const utilisation = {};
            for (const [kind, ids] of Object.entries(paths)) {
              utilisation[kind] = {};
              for (const id of ids) {
                const entries = getters.meanReservedUtilisationByHour[kind][id]
                    .slice(workingHoursStart, workingHoursEnd);
                if (entries.length === 0) {
                  utilisation[kind][id] = 0;
                }
                utilisation[kind][id] = ss.sumSimple(entries) / entries.length;
              }
            }
            return utilisation;
          }
        },
        mutations: {
          ...LoadingUtil.mutations(),
          ...DeferUtil.mutations(log),
          setBookingCounts(state, bc) {
            state.bookingResourceCountsByRef = bc;
          },
          /**
           * Update the cache of booking counts - only use this for onSnapshot updates since it will cause
           * getters to recompute which can be expensive with large data sets.
           *
           * @param {*} state
           * @param {firebase.firestore.DocumentChange[]} changes
           */
          updateBookingCounts(state, changes) {
            FirestoreUtil.indexQuerySnapshotUpdates(state.bookingResourceCountsByRef, changes, byRef);
          },
          clear(state) {
            state.bookingResourceCountsByRef = {};
          }
        },
        actions: {
          /**
           * @param {*} context
           * @param {Date[]} dates
           * @return {Promise<void>}
           */
          async watchCountsForDates({commit, rootGetters, dispatch}, dates) {
            const activeSite = rootGetters['sites/activeSiteDoc'];

            commit('loading', 'heatmap-counts');
            commit('setBookingCounts', {});

            const chunks = chunkArray(dates);
            if (chunks.length === 0) {
              commit('loaded', 'heatmap-counts');
              return;
            }

            const loaded = Array(chunks.length).fill(false);
            // local cache of data until all queries have loaded once
            // this is to stop getters needing to recompute each time a snapshot comes in
            const countsByRef = {};

            const setLoaded = (i) => {
              loaded[i] = true;
              log.debug('query loaded', i);
              if (loaded.every(l => l)) {
                log.debug('all loaded');
                commit('loaded', 'heatmap-counts');
                commit('setBookingCounts', countsByRef);
              }
            };

            const defer = {};
            for (let i = 0; i < chunks.length; i++) {
              const chunk = chunks[i];
              if (chunk.length !== 0) {
                const key = `heatmap-counts-${i}`;
                const dateQuery = getCountsQuery(activeSite, chunk, 'booking-resource');
                defer[key] = dateQuery.onSnapshot(
                    snap => {
                      if (loaded[i]) {
                        commit('updateBookingCounts', FirestoreUtil.prepareQuerySnapshot(snap));
                      } else {
                        setLoaded(i);
                        const querySnapshot = FirestoreUtil.prepareQuerySnapshot(snap);
                        FirestoreUtil.indexQuerySnapshotUpdates(countsByRef, querySnapshot, byRef);
                      }
                    },
                    err => {
                      log.error(`watchCountsForDates.chunk-${i}`, err);
                      setLoaded(i);
                    }
                );
              }
            }
            commit('defer', defer);
          },
          unbind({commit}) {
            commit('reset');
            commit('clear');
            commit('resetLoaded', {});
          }
        }
      }
    }
  };
}
