<template>
  <v-menu
      :offset-x="iconOnly"
      :nudge-left="iconOnly ? 8 : 0"
      :left="iconOnly"
      :min-width="iconOnly ? 200 : undefined"
      content-class="elevation-3"
      v-model="menuOpen"
      @input="$refs.activator.$el.blur()"
      :transition="iconOnly ? 'scale-transition' : 'v-menu-transition'"
      origin="top right">
    <template #activator="{on, attrs}">
      <v-btn
          ref="activator"
          v-bind="attrs"
          v-on="on"
          rounded
          :fab="iconOnly"
          :color="activatorColor"
          :elevation="2"
          :small="iconOnly"
          class="text-capitalize"
          :class="activatorClass">
        <v-icon :left="!iconOnly">{{ icon }}</v-icon>
        <template v-if="!iconOnly">{{ btnText }}</template>
      </v-btn>
    </template>
    <v-list color="grey lighten-5 pt-0" dense>
      <slot name="category">
        <v-btn text :ripple="false" class="no-hover justify-start" block>
          <v-icon left v-if="!iconOnly">{{ icon }}</v-icon>
          {{ label }}
        </v-btn>
      </slot>
      <v-list-item-group v-model="chosenIndex" active-class="chosen-item primary" mandatory>
        <template v-for="(item, i) in items || []">
          <slot name="item" :item="item" :i="i">
            <v-list-item class="px-4 primary-text--text">{{ item.title || item }}</v-list-item>
          </slot>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ChoiceBtn',
  props: {
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    iconOnly: Boolean,
    label: {
      type: String,
      default: ''
    },
    activatorClass: {
      type: [Object, String, Array],
      default: null
    }
  },
  data() {
    return {
      menuOpen: false,
      localValue: null
    };
  },
  computed: {
    btnText() {
      const value = this.chosenItem;
      if (!value) return '';
      return value.title || value;
    },
    chosenItem() {
      return this.items[this.chosenIndex];
    },
    chosenIndex: {
      get() {
        if (this.localValue === null) {
          return this.items.indexOf(this.value);
        }
        return this.localValue;
      },
      set(v) {
        // note: undefined is used if selecting the same value as is already selected
        if (v !== this.localValue && typeof v !== 'undefined') {
          this.localValue = v;
          this.$emit('input', this.chosenItem);
        }
      }
    },
    activatorColor() {
      if (this.iconOnly && this.menuOpen) {
        return 'grey primary--text text--lighten-1';
      }
      return 'grey lighten-5';
    }
  },
  watch: {
    value(v) {
      this.localValue = this.items.indexOf(this.value);
    }
  }
};
</script>

<style scoped>
  .chosen-item:before {
    display: none;
  }

  .no-hover:hover:before,
  .no-hover:active:before {
    opacity: 0;
  }
</style>
