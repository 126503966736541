import {Logger} from '@vanti/vue-logger';
import {createLiveCountsStore} from '@/views/dashboard/live-status/live-counts-store';

const log = Logger.get('live-status/desks');

const getCountsQuery = (site, ts) => {
  return site.ref.collection('counts')
      .where('onDate', '==', ts)
      .where('countType', '==', 'booking');
};

const module = createLiveCountsStore(log, getCountsQuery);

/**
 * This store is used for getting v1 counts for desks
 * v1 counts are stored at ns/{ns}/sites/{site}/counts
 */
export default {
  namespaced: true,
  state: module.state,
  getters: {
    ...module.getters,
    deskTotals(state, getters) {
      const counts = getters.countsNow;
      if (!counts) {
        return {};
      }
      return counts.totals && counts.totals.isDesk || {};
    }
  },
  mutations: {
    ...module.mutations
  },
  actions: {
    ...module.actions
  }
};
