<template>
  <form-row label="Profile" large-label>
    <v-autocomplete
        dense
        single-line
        clearable
        :items="profiles"
        item-text="title"
        item-value="value"
        v-model="selected"/>
  </form-row>
</template>

<script>
import FormRow from '@/components/form/FormRow';
import activeSiteReset from '@/mixins/active-site-reset';
import {mapGetters} from 'vuex';
export default {
  name: 'PersonAccessControlProfile',
  components: {FormRow},
  mixins: [activeSiteReset],
  props: {
    profiles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localSelection: null
    };
  },
  computed: {
    ...mapGetters('views/person', ['activeMembership']),
    current() {
      if (this.activeMembership && this.activeMembership.accessControl) {
        return this.activeMembership.accessControl.profile;
      }
      return undefined;
    },
    selected: {
      get() {
        if (this.localSelection !== null) {
          return this.localSelection;
        }
        if (this.current !== null) {
          return this.current;
        }
        return null;
      },
      set(v) {
        this.$logger.debug('selected', v);
        this.localSelection = v;
      }
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(s) {
        this.$emit('input', s);
      }
    }
  },
  methods: {
    /**
     * A method to reset back to the default state,
     * which we want to do in certain cases like when changing active sites
     */
    reset() {
      this.selected = null;
    }
  }
};
</script>

<style scoped>

</style>
