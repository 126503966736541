export default {
  namespaced: true,
  state() {
    return {
      selected: [],
      // control how many selected items there can be at any one time
      maxSelected: Infinity
    };
  },
  getters: {
    anySelected(state) {
      return state.selected.length > 0;
    },
    maxSelected(state) {
      return state.maxSelected;
    },
    isSelectedId(state) {
      const selectedIds = new Set(state.selected.map(s => s.id));
      return id => selectedIds.has(id);
    },
    lastSelected(state) {
      return state.selected.length === 0 ? null : state.selected[state.selected.length - 1];
    },
    alphabeticalSelected(state) {
      const ordered = [...state.selected];
      ordered.sort((a, b) => {
        if ('title' in a && 'title' in b) {
          if (a.title > b.title) {
            return -1;
          } else if (a.title < b.title) {
            return 1;
          } else {
            return 0;
          }
        }

        if ('title' in a) {
          return 1;
        }
        if ('title' in b) {
          return -1;
        }
        return 0;
      });
      return ordered;
    }
  },
  mutations: {
    toggleSelection(state, selection) {
      const idx = state.selected.findIndex(s => s.id === selection.id);
      if (idx === -1) {
        addSelection(state, selection);
      } else {
        state.selected.splice(idx, 1);
      }
    },
    addSelection(state, selection) {
      // is the item already selected?
      if (Boolean(state.selected.find(s => s.id === selection.id))) return;

      // select the item
      addSelection(state, selection);
    },
    removeSelection(state, selection) {
      const idx = state.selected.findIndex(s => s.id === selection.id);
      if (idx >= 0) {
        state.selected.splice(idx, 1);
      }
    },
    clearAll(state) {
      state.selected = [];
    },
    toggleMaxSelected(state) {
      if (state.maxSelected === 1) {
        state.maxSelected = Infinity;
      } else {
        state.maxSelected = 1;
      }
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({commit}, authUser) {
        if (!authUser) {
          commit('clearAll');
        }
      }
    }
  }
};

/**
 * Helper function that deals with removing extra selected items if we're about to go over capacity.
 *
 * @param {*} state
 * @param {*} item
 */
function addSelection(state, item) {
  const extra = state.selected.length + 1 - state.maxSelected;
  if (extra > 0) {
    state.selected.splice(0, extra);
  }
  state.selected.push(item);
}
