<template>
  <v-list flat :dense="dense">
    <v-list-item-group v-model="activePermissions" multiple>
      <transition-group name="slide-y-transition" tag="div" leave-absolute>
        <template v-for="(perm, i) in availablePermissions">
          <v-divider v-if="i !== 0 && perm.depth === 0" :key="perm.v + 'div'"/>
          <v-list-item :key="perm.v" :value="perm.v" :class="'depth-' + perm.depth">
            <template #default="{ active }">
              <v-list-item-action>
                <!-- https://vuetifyjs.com/en/components/list-item-groups#selection-controls -->
                <v-checkbox :input-value="active" :true-value="perm.v" color="accent darken-1"/>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ perm.t }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template #activator="{on}">
                    <v-icon v-on="on" class="grey--text text--lighten-3">mdi-information-outline</v-icon>
                  </template>
                  {{ templateHelp(perm.h) }}
                </v-tooltip>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </transition-group>
      <v-divider/>
    </v-list-item-group>
  </v-list>
</template>

<script>
import {template} from 'lodash';

export default {
  name: 'PermissionEditor',
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    person: {
      type: Object,
      default: null
    },
    available: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      activePermissions: []
    };
  },
  computed: {
    title() {
      if (!this.person) {
        return '';
      }
      if (this.person.displayName) {
        return this.person.displayName;
      }
      if (this.person.title) {
        return this.person.title;
      }
      return '';
    },
    availablePermissions() {
      if (!this.available) {
        return [];
      }

      const result = [];

      const visitValues = (values, depth) => {
        values.forEach(value => {
          value.depth = depth;
          result.push(value);
          if (value.children) {
            // only add the children if the parent is active
            if (this.activePermissions.indexOf(value.v) >= 0) {
              visitValues(value.children, depth + 1);
            }
          }
        });
      };

      visitValues(this.available, 0);
      return result;
    },
    publishData() {
      // turn [a, c] (given available permissions of [a, b, c])
      // into {a: true, b: false, c: true}
      const permissions = {};
      this.activePermissions.forEach(p => permissions[p] = true);
      const addDefaults = available => {
        available.forEach(p => {
          if (!permissions.hasOwnProperty(p.v)) {
            permissions[p.v] = false;
          }
          if (available.children) {
            addDefaults(available.children);
          }
        });
      };
      addDefaults(this.availablePermissions);

      return permissions;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'record'
    },

    publishData: {
      deep: true,
      handler: 'publish'
    }
  },
  methods: {
    publish() {
      this.$emit('input', this.publishData);
    },
    record(newVal) {
      // turn {a: true, b: false, c: true}
      // into [a, c]
      const newActivePermissions = Object.entries(newVal || {})
          .filter(([k, v]) => v)
          .map(([k]) => k);
      newActivePermissions.sort();
      if (!this.arraysEqual(newActivePermissions, this.activePermissions)) {
        this.activePermissions = newActivePermissions;
      }
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    templateHelp(help) {
      return template(help)({name: this.title});
    }
  }
};
</script>

<style scoped>
  .v-list-item__action {
    margin-right: 8px !important;
  }
  .v-list.v-list--dense {
    padding: 0;
  }
  .v-list.v-list--dense >>> .v-list-item__action {
    margin: 0;
  }
  .v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 24px;
  }
  .v-list--dense .v-list-item .v-list-item__title {
    font-size: 14px;
  }

  .depth-1 {
    padding-left: 40px;
  }
  .depth-2 {
    /* this may not be quite right */
    padding-left: 70px;
  }
</style>
