import {CardIdTypeRef} from '@/util/card-type';
import {
  GenericCardFormat,
  Generic34bitEvenCardFormat,
  Generic34bitOddCardFormat,
  Hid26BitCardFormat,
  Hid37BitFcCardFormat,
  HidCorporate1000CardFormat,
  ParserByCardIdType
} from '@/components/cards/card-encoding-util';

/**
 * Paxton token cardIdType
 */
export const PaxtonTokenCardIdType = 'paxton';

export const CardIdTypeNames = {
  [CardIdTypeRef]: 'MIFARE Classic',
  [GenericCardFormat.kahuTypeId]: 'Unknown Format',
  [Generic34bitEvenCardFormat.kahuTypeId]: 'Generic 34-bit (even parity)',
  [Generic34bitOddCardFormat.kahuTypeId]: 'Generic 34-bit (odd parity)',
  [Hid26BitCardFormat.kahuTypeId]: 'Standard 26-bit',
  [Hid37BitFcCardFormat.kahuTypeId]: 'HID 37-bit w/Fac. Code',
  [HidCorporate1000CardFormat.kahuTypeId]: 'HID Corporate 1000 (35-bit)',
  [PaxtonTokenCardIdType]: 'Paxton Token'
};

export {ParserByCardIdType};
