const colors = [
  '#6AD8FC',
  '#9AD6BC',
  '#BCD590',
  '#DCD465',
  '#FBD33C',
  '#FCB541',
  '#FD9645',
  '#FE794A',
  '#FF5352'
];

/**
 * Returns an array of length 101, for the range [0,100] (percentage),
 * where each value is the color to use for that percentage value.
 *
 * @param {string[]} colors
 * @return {string[]}
 */
function computeByUtilisation(colors) {
  const perColor = 101 / colors.length;
  const byUtilisation = [];
  for (let i = 0; i < colors.length; i++) {
    const color = colors[i];
    for (let j = 0; j < perColor; j++) {
      const key = Math.floor((i * perColor) + j);
      byUtilisation[key] = color;
    }
  }
  // let's just make sure we have 101 [0,100]
  const lastColor = colors[colors.length - 1];
  while (byUtilisation.length < 101) {
    byUtilisation.push(lastColor);
  }
  return byUtilisation;
}

export const colorByUtilisation = computeByUtilisation(colors);
