import {EmptyCollection} from '@/store/collection/empty-collection';
import {ChangesCollection} from '@/views/admin/audit/changes-collection';

export default {
  namespaced: true,
  state: {
    /** @type {?DocumentCollection} */
    currentCollection: new EmptyCollection()
  },
  getters: {
    changes(state) {
      return state.currentCollection.documents;
    },
    loadingInfo(state) {
      const c = state.currentCollection;
      if (c.recordFetchAborted()) {
        return {
          text: `Unfortunately we were unable to fetch ${c.records.length > 0 ? 'more' : 'any'} logs at this time.`,
          type: 'error',
          loading: false
        };
      } else if (c.fetchErrorCount > 1) {
        return {
          text: `We're experiencing some issues fetching ${c.records.length > 0 ? 'more ' : ''}logs` +
              ', please wait while we try and sort out the problem.',
          type: 'warning',
          loading: 'warning'
        };
      } else if (c.recordsAreLoading) {
        return {text: `Loading ${c.records.length > 0 ? 'more ' : ''}logs...`, type: 'info', loading: true};
      } else if (!c.expectsMoreRecords) {
        return {text: 'No more logs'};
      } else {
        return {};
      }
    }
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {CardsCollection} collection
     */
    setCurrentCollection(state, collection) {
      state.currentCollection = collection;
    },
    clear(state) {
      state.currentCollection = new EmptyCollection();
    }
  },
  actions: {
    /**
     * Configure the current collection to be based off of the given query clauses.
     *
     * @param {import('vuex').ActionContext} context
     * @param {QueryClause[]} queryClauses
     */
    async bind({state, rootGetters, commit}, queryClauses) {
      const ns = rootGetters.ns;

      if (!state.currentCollection.sameAs({ns, queryClauses})) {
        commit('setCurrentCollection', new ChangesCollection(ns, queryClauses));
      }

      // else the current collection is equivalent to the one we're binding to, don't do anything
    },
    unbind({commit}) {
      commit('clear');
    },
    async load({state}) {
      return state.currentCollection.load();
    },

    async stop({state}) {
      return state.currentCollection.stop();
    }
  }
};
