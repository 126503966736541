<template>
  <div class="stats-root">
    <v-toolbar height="56">
      <v-toolbar-title>
        <v-tabs v-model="tabIndex">
          <v-tab v-for="tab in tabs" :key="tab.name">
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-toolbar-title>
      <v-spacer/>

      <v-menu
          ref="menu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto">
        <template #activator="{ on, attrs }">
          <v-text-field
              v-model="dateRangeText"
              label="Select a date range"
              single-line
              prepend-icon="mdi-calendar"
              readonly
              style="max-width: 400px"
              hide-details
              v-bind="attrs"
              v-on="on"/>
        </template>
        <v-date-picker
            v-model="date"
            :max="todaysDate"
            :first-day-of-week="localeFirstDayOfWeek"
            range
            no-title
            scrollable>
          <div class="d-flex flex-fill flex-column">
            <div class="subtitle-2 mb-2">{{ dateSelectorText }}</div>
            <div class="d-flex">
              <v-spacer/>
              <v-btn outlined @click="resetDate" class="mr-2">Cancel</v-btn>
              <v-btn color="accent" depressed @click="setDate" :disabled="date.length === 0">OK</v-btn>
            </div>
          </div>
        </v-date-picker>
      </v-menu>
      <stats-options/>
      <v-progress-linear indeterminate color="accent" v-if="!loaded" class="loading"/>
    </v-toolbar>

    <v-container fluid>
      <statistics-tab
          v-if="!isHeatmapTabSelected"
          :store-suffix="activeTab.name"
          :floor-titles-by-id="floorTitlesById"
          :graph-options="graphOptions"
          :show-by-floor="options.byFloor"
          :moving-average="options.movingAverage"
          :time-scale="options.timeScale"
          :show-count="activeTab.showCount"
          :show-occupancy-data="showOccupancyData"/>

      <heatmap-tab v-else @bind="heatmapMounted" @unbind="heatmapBeforeDestroy"/>
    </v-container>
  </div>
</template>

<script>
import {toYearMonthDay} from '@/util/dates';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import StatsOptions from '@/views/dashboard/statistics/StatsOptions';
import StatisticsTab from '@/views/dashboard/statistics/StatisticsTab';
import HeatmapTab from '@/views/dashboard/statistics/heatmap/HeatmapTab';
import {TimeScales} from '@/views/dashboard/statistics/statistics';

export default {
  name: 'Statistics',
  components: {StatisticsTab, StatsOptions, HeatmapTab},
  data() {
    return {
      date: [],
      tabs: [
        {
          name: 'desks',
          icon: 'mdi-desk',
          showCount: false,
          floorTitles: 'siteFloorTitlesById'
        },
        {
          name: 'rooms',
          icon: 'mdi-door-open',
          showCount: true,
          floorTitles: 'groupFloorTitlesById'
        },
        {
          name: 'Usage heatmap',
          icon: 'mdi-map'
        }
      ],
      tabIndex: 0,
      dateMenu: false
    };
  },
  computed: {
    ...mapGetters('sites/active', [
      'localeFirstDayOfWeek',
      'doc'
    ]),
    ...mapGetters('config', [
      'themeColors'
    ]),
    ...mapGetters('floors', [
      'groupFloorTitlesById',
      'siteFloorTitlesById'
    ]),
    ...mapGetters('views/dashboard/statistics', [
      'loaded',
      'startEndYmd',
      'hourLabels',
      'dayLabels',
      'numWeeksInRange',
      'numDaysInRange'
    ]),
    ...mapState('views/dashboard/statistics', [
      'selectedDates',
      'options'
    ]),
    ...mapGetters('sites/active', [
      'dashboardOccupancyRoomsEnabled',
      'dashboardOccupancyDesksEnabled'
    ]),

    activeTab() {
      return this.tabs[this.tabIndex];
    },
    isHeatmapTabSelected() {
      return this.activeTab.name === 'Usage heatmap';
    },
    showOccupancyData() {
      return this.activeTab.name === 'desks' ?
        this.dashboardOccupancyDesksEnabled : this.dashboardOccupancyRoomsEnabled;
    },
    dateSelectorText() {
      if (this.date.length === 0) {
        return 'Select a date or date range';
      }
      if (this.date.length === 1 || this.date[0] === this.date[1]) {
        return `Selected: ${this.date[0]}`;
      }
      return `Selected: ${this.date[0]} - ${this.date[1]}`;
    },
    dateRangeText() {
      if (!this.startEndYmd) return '';
      const start = this.startEndYmd.start;
      const end = this.startEndYmd.end;
      if (start === end) {
        return start;
      }
      return `${start} - ${end}`;
    },
    baseFileName() {
      const siteTitle = this.doc && this.doc.title || '';
      const label = siteTitle
          .toLowerCase()
          .replaceAll(' ', '-');
      const dr = this.dateRangeText
          .replaceAll(' - ', '_to_');
      return `graph_${label}_${dr}`;
    },
    colors() {
      const fallback = this.$vuetify.theme.themes.light;
      const theme = this.themeColors;
      const options = this.options.colors;
      const color = (name) => {
        if (options.hasOwnProperty(name)) {
          return options[name];
        }
        if (theme.hasOwnProperty(name)) {
          return theme[name].base;
        }
        return fallback[name].base;
      };
      return [
        color('primary'),
        color('secondary'),
        color('accent')
      ];
    },
    noDataText() {
      if (this.selectedDates.length === 0) {
        return 'Please select a date range';
      }
      if (!this.loaded) {
        return 'Processing...';
      }
      return 'No data for the selected date';
    },
    baseGraphOptions() {
      return {
        noData: {
          text: this.noDataText
        },
        chart: {
          type: 'line',
          height: 500,
          zoom: {
            enabled: false
          },
          toolbar: {
            export: {
              csv: {filename: this.baseFileName},
              png: {filename: this.baseFileName},
              svg: {filename: this.baseFileName}
            },
            tools: {
              download: '<i class="v-icon notranslate mdi mdi-download"/>'
            }
          }
        },
        colors: this.colors,
        fill: {
          type: 'solid',
          opacity: 1
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        legend: {
          horizontalAlign: 'left'
        },
        xaxis: {
          type: 'category',
          tickPlacement: 'on'
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 10,
          labels: {
            formatter(value) {
              return value ? value.toFixed(0) + '%' : '';
            }
          }
        },
        tooltip: {
          y: {
            formatter(value) {
              return isNaN(value) ? '-' : (value + '%');
            }
          }
        }
      };
    },
    todaysDate() {
      return toYearMonthDay(new Date());
    },
    floorTitlesById() {
      return this[this.activeTab.floorTitles];
    },
    graphOptions() {
      if (this.options.timeScale === TimeScales.Hourly) {
        return this.hourlyOptions;
      }
      return this.dailyOptions;
    },
    hourlyOptions() {
      return this.graphsOptionsWithCategories(this.hourLabels);
    },
    dailyOptions() {
      return this.graphsOptionsWithCategories([
        ...this.dayLabels,
        '' // fixme: why is this needed? if it isn't there, the last point if off the chart
      ]);
    }
  },
  mounted() {
    this.bind();
  },
  beforeDestroy() {
    this.unbind();
  },
  methods: {
    ...mapActions('views/dashboard/statistics', ['bind', 'unbind', 'bindHeatmap', 'unbindHeatmap']),
    ...mapMutations('views/dashboard/statistics', ['setSelectedDates']),
    setDate() {
      if (this.date.length === 2) {
        this.setSelectedDates(this.date);
      } else {
        this.setSelectedDates([
          ...this.date,
          ...this.date
        ]);
      }
      this.dateMenu = false;
    },
    resetDate() {
      this.dateMenu = false;
      this.date = this.selectedDates;
    },
    graphsOptionsWithCategories(categories) {
      return {
        ...this.baseGraphOptions,
        xaxis: {
          ...this.baseGraphOptions.xaxis,
          categories
        }
      };
    },
    heatmapMounted() {
      this.$logger.debug('heatmapMounted');
      this.bindHeatmap();
    },
    heatmapBeforeDestroy() {
      this.$logger.debug('heatmapBeforeDestroy');
      this.unbindHeatmap();
    }
  }
};
</script>

<style scoped>
.stats-root {
  max-height: 100vh;
  --toolbar-height: 56px;
}
.stats-root > .v-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  /* 6 is the same as the nav drawer, this means we're above the box-shadow */
  z-index: 6;
}
.loading {
  position: absolute;
  top: var(--toolbar-height);
  left: 0;
  /* 6 is the same as the nav drawer, this means we're above the box-shadow */
  z-index: 6;
}
.stats-root >>> .apexcharts-toolbar {
  z-index: 5; /* under any v-menus */
}
</style>
