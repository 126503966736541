import {Logger} from '@vanti/vue-logger';
import {createLiveCountsStore} from '@/views/dashboard/live-status/live-counts-store';

const log = Logger.get('live-status/rooms');
const getCountsQuery = (site, ts) => {
  const nsDoc = site.ref.parent.parent;
  const siteGroup = nsDoc.collection('groups').doc(site.id);

  return nsDoc.collection('counts')
      .where('onDate', '==', ts)
      .where('site.ref', '==', siteGroup)
      .where('countType', '==', 'booking');
};

const module = createLiveCountsStore(log, getCountsQuery);

/**
 * This store is used for getting v2 counts for rooms
 * v2 counts are stored at ns/{ns}/counts
 */
export default {
  namespaced: true,
  state: module.state,
  getters: {
    ...module.getters,
    roomTotals(state, getters) {
      const counts = getters.countsNow;
      if (!counts) {
        return {};
      }
      return counts.totals && counts.totals.isRoom || {};
    }
  },
  mutations: {
    ...module.mutations
  },
  actions: {
    ...module.actions
  }
};
