export default {
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.width < 768;
    },
    tablet() {
      return !this.mobile && this.$vuetify.breakpoint.width < 960;
    },
    desktop() {
      return !this.mobile;
    }
  }
};
