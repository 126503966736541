import {db, decorateSnapshot} from '@/firebase';
import {ReferenceString} from '@/store/collection/reference-string';
import {Logger} from '@vanti/vue-logger';

const log = Logger.get('DocumentCollection');

const options = {
  // how many times in a row do we get errors before we give up
  maxErrorAbort: 5,
  // how many records we should query the server for at one time. Note we might query more on the first page to seed the
  // tailRecords.
  pageSize: 10,
  // How many extra records we should fetch from the server (on top of pageSize) to check for the end of the
  // list. The larger this is, the better the user experience but the more we download on the first page
  tailRecordsSize: 1,
  /**
   * How long to wait between page fetches when we didn't collect enough records the first time.
   *
   * If errors are happening this value will be multiplied by the number of errors we've received in a row to back off.
   */
  fetchAgainDelayMs: 100
};

/**
 * A single clause in a query. ['person.name', '==', 'Bob'] for example
 *
 * @typedef {[string, string, *]} QueryClause
 */

/**
 * All the information related to a list of documents. Including the query used to collect that list and any metadata
 * about the list that we think we know - i.e. do we think there are more records to get.
 */
export class DocumentCollection {
  /** @type {?firebase.firestore.Query} */
  #query;
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {QueryClause[]} queryClauses The list of query clauses
   */
  constructor(ns, queryClauses) {
    this.ns = ns;
    this.queryClauses = DocumentCollection.sortClauses(queryClauses);

    // we should attempt to fetch more records, assuming we think that will be successful
    this.recordsNeedLoading = false;
    // we are working on fetching records from the server
    this.recordsAreLoading = false;
    // how many request errors in a row have we seen
    this.fetchErrorCount = 0;
    // the last request error we saw
    this.lastFetchError = null;
    // Do we think the server has more records for us to fetch.
    this.expectsMoreRecords = true;
    // track the setTimeout handle that fetches more data until we are told to stop
    this.fetchAgainHandle = 0;
    /**
     * All the records we've fetched from the server. Each item represents a document.
     *
     * @type {DecoratedData[]}
     */
    this.records = [];
    /**
     * The list of documents the search records represent.
     *
     * @type {Array}
     */
    this.documents = [];
    /**
     * Extra records we fetch from the server to check for the end of the item list
     *
     * @type {DecoratedData[]}
     */
    this.tailRecords = [];
    /** @type {?firebase.firestore.Query} */
    this.#query = null;
    this.pageSize = options.pageSize;
  }

  /**
   * Have we aborted fetching records due to too many errors.
   *
   * @return {boolean}
   */
  recordFetchAborted() {
    return this.fetchErrorCount >= options.maxErrorAbort;
  }

  /**
   * Trigger record loading. This will continue to load more records into the cache until you call {@link stop}, we run
   * out of records, or we're experiencing errors.
   *
   * @see recordFetchAborted
   * @see stop
   */
  load() {
    if (!this.recordsNeedLoading) {
      this.recordsNeedLoading = true;
      this.fetchRecords();
    }
  }

  /**
   * Stop loading records. While this won't cancel any in-flight request it will stop any pending or scheduled queries
   * from issuing requests.
   */
  stop() {
    this.recordsNeedLoading = false;
    // note: it feels like it would be possible to just rely on the recordsNeedLoading to control whether
    // the delayed fetches run, however in the case where someone loads then stops then loads again within
    // the timeout period, the recordsNeedLoading will be true by the time the delayed fetchRecords call
    // runs which means they will all attempt to fetch the data. Explicitly cancelling the delayed fetch
    // prevents this from happening, and it's good practice anyway.
    clearTimeout(this.fetchAgainHandle);
  }

  /**
   * Begin fetching records
   *
   * @private
   */
  fetchRecords() {
    if (this.recordsNeedLoading && !this.recordFetchAborted() && this.expectsMoreRecords) {
      this.recordsAreLoading = true;
      this.loadNextPage()
          .then(() => {
            // reset error conditions
            this.fetchErrorCount = 0;
            this.lastFetchError = null;
            // Check again just in case we need to load more records (page isn't full).
            this.fetchAgainHandle = setTimeout(() => this.fetchRecords(), options.fetchAgainDelayMs);
          })
          .catch(err => {
            // Handling errors
            //
            // We want to retry requests that fail, in case they succeed the next time. However we don't want to
            // keep trying forever (spamming the logs), so we keep track of how many times we've caught an error.
            // The counter is reset if we succeed (see above).
            //
            // If the counter reaches a threshold then we don't schedule any more attempts, otherwise we schedule
            // an attempt for t*attempt count in the future.
            //
            // Note that if the user scrolls back up (i.e. the records we're loading aren't needed anymore) then the
            // next attempt won't actually do anything so cancellation isn't needed, yay.

            this.fetchErrorCount++;
            this.lastFetchError = err;

            if (this.recordFetchAborted()) {
              log.error('Giving up fetching records after ' + this.fetchErrorCount + ' attempts', err);
              this.recordsAreLoading = false;
            } else {
              log.warn('Error fetching records (attempt ' + this.fetchErrorCount + '), will try again',
                  err.message);
              // Try again after a delay
              const delay = options.fetchAgainDelayMs * this.fetchErrorCount;
              this.fetchAgainHandle = setTimeout(() => this.fetchRecords(), delay);
            }
          });
    } else {
      this.recordsAreLoading = false;
    }
  }

  /**
   * @return {Promise<firebase.firestore.Query<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    throw new Error('DocumentCollection.baseQuery not implemented');
  }

  /**
   * Fetch the next page of results from the query. This will update any internal state to keep track of metadata and
   * the records the query has returned.
   *
   * @return {Promise<*>}
   */
  async loadNextPage() {
    // Implementation note:
    // Paging is done by returning the records after the last document we already have. This is how firestore works.
    // Because we want to be predictable with our page size we request one more item than is needed to fill a page
    // and hold on to it without showing it to the user (yet). When the next page is shown we can show them that item
    // immediately and continue to load more records.
    //
    // Because we're fetching more than a page we can also use this information to know if we've fetched all the
    // records.

    const tailRecords = this.tailRecords;
    let query = this.#query;
    let expectedSize = this.pageSize;
    if (tailRecords.length === 0) {
      // no last item, get the first page
      /** @type {firebase.firestore.Firestore} */
      const _db = await db;
      const baseQuery = await this.baseQuery();

      query = baseQuery
          .limit(this.pageSize + options.tailRecordsSize);
      this.queryClauses.forEach(([p, c, v]) => {
        // convert special properties as needed
        if (v instanceof ReferenceString) {
          v = _db.doc(v.value);
        }

        query = query.where(p, c, v);
      });

      this.#query = query;
      expectedSize += options.tailRecordsSize;
    } else {
      if (!query) {
        // impossible!
        throw new Error('query is null, but we have records. How can this happen?!');
      }
      const lastRecord = tailRecords[tailRecords.length - 1].raw;
      query = this.#query.startAfter(lastRecord)
          .limit(this.pageSize);

      // already know about these ones, so just add them straight away
      this.useTailRecords();
    }

    const queryResult = await query.get();

    /** @type {DecoratedData[]} */
    const records = [];
    queryResult.forEach(snap => records.push(decorateSnapshot(snap)));

    // have we caught them all?
    if (records.length < expectedSize) {
      this.expectsMoreRecords = false;
    } else {
      // remove tail records from the total list
      this.tailRecords = records.splice(records.length - options.tailRecordsSize);
    }
    // add the records to the list
    this.saveRecords(records);
  }

  /**
   * Check if the given object represents the same records as this object. If the ns and queryClauses are the same then
   * we assume they represent the same records, even if all those records haven't been fetched yet.
   *
   * @param {Object} other
   * @param {string} other.ns
   * @param {QueryClause[]} other.queryClauses
   * @return {boolean}
   */
  sameAs({ns, queryClauses}) {
    if (this.ns !== ns) {
      return false;
    }

    // compare the query clauses (without order)
    const self = this.queryClauses; // already sorted
    if (self.length !== queryClauses.length) {
      return false;
    }

    const other = DocumentCollection.sortClauses(queryClauses);
    // first we loop through to check the simple cases: property and condition
    for (let i = 0; i < self.length; i++) {
      const a = self[i];
      const b = other[i];

      if (a[0] !== b[0] || a[1] !== b[1]) {
        return false;
      }
    }

    // then we loop through to check the value, which can be harder to do (so we delayed it)
    for (let i = 0; i < self.length; i++) {
      const a = self[i][2];
      const b = other[i][2];

      if (a === b) {
        continue;
      }

      if (typeof a.isEqual === 'function' && a.isEqual(b)) {
        continue;
      }

      // all our checks failed, the values aren't equal
      return false;
    }

    return true;
  }

  /**
   * Take any tailRecords and apply them to the record list. Will empty the tailRecords list too.
   *
   * @private
   */
  useTailRecords() {
    this.saveRecords(this.tailRecords);
    this.tailRecords = [];
  }

  /**
   * Adds the given list of records to the records list.
   *
   * @param {DecoratedData[]} records
   * @private
   */
  saveRecords(records) {
    this.records.push(...records);
    this.documents.push(...records);
  }

  /**
   * Sort the query clauses by propertyName to make them consistently ordered.
   *
   * @param {QueryClause[]} clauses
   * @return {QueryClause[]}
   */
  static sortClauses(clauses) {
    clauses.sort((a, b) => {
      if (a[0] > b[0]) {
        return 1;
      }
      if (a[0] < b[0]) {
        return -1;
      }

      if (a[1] > b[1]) {
        return 1;
      }

      if (a[1] < b[1]) {
        return -1;
      }

      // don't compare the value, it's probably an error to have two clauses that share both property and condition
      // but have different values, that doesn't make any sense
      return 0;
    });

    return clauses;
  }
}
