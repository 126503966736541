<template>
  <div class="map fill-height d-flex flex-column">
    <selection-toolbar v-if="!utilisationMap"/>
    <v-toolbar
        v-if="!utilisationMap"
        class="toolbar--grid"
        :class="{vertical: mobile || tablet}"
        color="transparent"
        flat
        absolute
        style="top:50px">
      <choice-btn
          v-if="siteFloors.length > 1"
          icon="mdi-layers"
          label="Floor"
          :items="siteFloors"
          :value="activeFloor"
          @input="chooseFloor"
          :icon-only="mobile || tablet"/>
    </v-toolbar>
    <v-fade-transition>
      <v-progress-linear
          v-if="!activeFloor || !activeFloor.loaded"
          indeterminate
          color="accent"
          style="position: absolute; width: 100%"/>
    </v-fade-transition>
    <v-scale-transition origin="center center">
      <pinch-zoom
          :disable="editMode"
          v-if="activeFloor"
          :class="{hidden: !activeFloor.loaded}"
          class="flex-grow-1 overflow-hidden pa-4"
          ref="pinchZoom"
          center
          v-resize-observer="updateMapSize">
        <template #controls="{home, isHome}">
          <v-btn @click="home" icon elevation="1" class="white">
            <v-fade-transition>
              <v-icon v-if="isHome">mdi-image-filter-center-focus</v-icon>
              <v-icon v-else>mdi-image-filter-center-focus-weak</v-icon>
            </v-fade-transition>
          </v-btn>
        </template>
        <template #default="{scale, transition}">
          <floor-plan :utilisation-map="utilisationMap" :floor="activeFloor" :loading.sync="floorLoading">
            <template #foreground v-if="editMode">
              <circles-edit
                  v-if="activeFloor && activeFloor.svgBounds"
                  :floor="activeFloor"
                  :width="activeFloor.svgBounds.width"
                  :height="activeFloor.svgBounds.height"
                  :view-box="`0 0 ${activeFloor.svgBounds.width} ${activeFloor.svgBounds.height}`"/>
            </template>
            <template #background v-if="previewMode">
              <floor-plan-circles
                  v-if="activeFloor && activeFloor.svgBounds"
                  :floor="activeFloor"
                  :view-box="`0 0 ${activeFloor.svgBounds.width} ${activeFloor.svgBounds.height}`"/>
            </template>
            <template #layers>
              <selection-layer
                  :desks="activeFloor.desksById"
                  :color="neighbourhoodColor"
                  :selected-arr="neighbourhoodDesks"
                  :transform="{x:0, y:0, scale}"
                  :transition="transition"/>
            </template>
          </floor-plan>
        </template>
      </pinch-zoom>
    </v-scale-transition>
  </div>
</template>

<script>
import ChoiceBtn from '@/components/ChoiceBtn';
import FloorPlan from '@/views/desk-booking/settings/map/FloorPlan';
import SelectionLayer from '@/views/desk-booking/settings/map/SelectionLayer';
import PinchZoom from '@/components/PinchZoom';
import breakpoints from '@/plugins/breakpoints';
import ResizeObserver from '@/plugins/resize-observer';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import CirclesEdit from '@/views/desk-booking/settings/map/CirclesEdit.vue';
import SelectionToolbar from '@/views/desk-booking/settings/map/SelectionToolbar';
import FloorPlanCircles from '@/views/desk-booking/settings/map/FloorPlanCircles';

export default {
  name: 'ZoneMap',
  components: {FloorPlanCircles, SelectionToolbar, ChoiceBtn, PinchZoom, FloorPlan, CirclesEdit, SelectionLayer},
  directives: {ResizeObserver},
  mixins: [breakpoints],
  props: {
    utilisationMap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      floorLoading: false,
      mapSize: null,
      waitingToZoom: 0,
      showCircles: false
    };
  },
  computed: {
    ...mapGetters('selection', ['anySelected', 'maxSelected']),
    ...mapState('selection', ['selected']),
    ...mapGetters('views/deskBooking/settings/map', ['activeFloor']),
    ...mapGetters('floors', ['siteFloors']),
    ...mapGetters('sites', ['activeSiteDoc']),
    ...mapState('views/deskBooking/settings/circles', ['editMode', 'previewMode']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['selectedNeighbourhood']),
    neighbourhoodColor() {
      if (!this.selectedNeighbourhood) return 'primary';
      return this.selectedNeighbourhood.color;
    },
    neighbourhoodDesks() {
      if (!this.selectedNeighbourhood) return null;

      const desks = this.selectedNeighbourhood.desks;
      const desksArr = [...desks.existingDesks];
      if (desks.addedDesks) desksArr.push(...desks.addedDesks);
      if (desks.removedDesks) {
        for (const desk of desks.removedDesks) {
          const i = desksArr.findIndex(d => d.id === desk.id);
          if (i !== -1) {
            desksArr.splice(i, 1);
          }
        }
      }
      return desksArr;
    },
    mapImageSize() {
      if (!this.activeFloor) return null;
      return this.activeFloor.svgTargetSize;
    }
  },
  watch: {
    activeFloor: {
      immediate: true,
      handler(f) {
        if (f) {
          f.bind().catch(err => this.$logger.error('activeFloor changed', err));
        }
      }
    },
    ['activeFloor.focusBounds']: {
      immediate: true,
      handler: 'zoomToRect'
    }
  },
  mounted() {
    this.bind()
        .catch(err => this.$logger.error('mounted.bind', err));
  },
  beforeDestroy() {
    this.unbind()
        .catch(err => this.$logger.error('beforeDestroy.unbind', err));
  },
  methods: {
    ...mapActions('views/deskBooking/settings/map', ['bind', 'unbind']),
    ...mapMutations('views/deskBooking/settings/map', ['chooseFloorForSite']),
    ...mapMutations('selection', ['clearAll', 'toggleMaxSelected']),
    ...mapMutations('views/deskBooking/settings/circles', ['setCircle']),
    chooseFloor(floor) {
      this.setCircle(null);
      if (!this.activeSiteDoc) return;
      this.chooseFloorForSite({floor, site: this.activeSiteDoc});
      this.clearFocusOn();
      if (this.$refs.pinchZoom) {
        this.$refs.pinchZoom.reset();
      }
    },
    updateMapSize(sizes) {
      this.mapSize = sizes[sizes.length - 1].contentRect;
    },

    zoomToRect(target, tries = 0) {
      if (this.waitingToZoom) {
        clearTimeout(this.waitingToZoom);
      }
      this.waitingToZoom = 0;
      if (tries > 5) {
        this.$logger.debug('zoomToRect: tried too many times');
      }
      if (!this.$refs.pinchZoom) {
        this.$logger.debug('zoomToRect: no pinchZoom');
        return;
      }
      if (!target) {
        this.$logger.debug('zoomToRect: no target');
        return;
      }
      if (this.mapSize === null) {
        this.$logger.warn('zoomToRect: no mapSize, ResizeObserver supported?');
        this.mapSize = false;
      }
      // how much of canvas should be filled by the target
      const fill = 0.7;

      // the space we need to center in
      const canvas = this.mapSize || this.calcMapSize();
      // the size of the image the target coords are relative to (this is centered within canvas)
      const imageSize = this.mapImageSize;
      if (canvas.width === 0 || canvas.height === 0 || !imageSize || imageSize.width === 0 || imageSize.height === 0) {
        this.waitingToZoom = setTimeout(() => this.zoomToRect(target, tries + 1), 200);
        return;
      }
      // work out if fully scaled whether the width or height fills the canvas
      const landscape = canvas.width / canvas.height < target.width / target.height;
      const scale = landscape ?
          canvas.width * fill / target.width :
          canvas.height * fill / target.height;
      const x = (imageSize.width / 2) - target.x - target.width / 2;
      const y = (imageSize.height / 2) - target.y - target.height / 2;
      // this.$logger.debug('panZoom', canvas, this.mapImageSize, target, {x, y, scale});
      this.$refs.pinchZoom.panZoom({x, y, scale});
    },
    calcMapSize() {
      if (!this.$refs.pinchZoom) return null;
      return this.$refs.pinchZoom.$el.getBoundingClientRect();
    },
    clearFocusOn() {
      if (!this.activeFloor) return;
      this.activeFloor.clearFocus();
    }
  }
};
</script>

<style scoped>
  .map {
    position: relative;
  }

  .map > .v-toolbar {
    right: 0;
  }

  .map--current-floor .v-icon {
    transition: transform ease-out .2s;
    transform: scaleY(1);
  }

  .map--current-floor.expanded .v-icon {
    transform: scaleY(-1);
  }

  .toolbar--grid:not(.vertical) >>> .v-toolbar__content > *:not(:first-child) {
    margin-left: 16px;
  }

  .toolbar--grid.vertical >>> .v-toolbar__content {
    flex-direction: column;
  }

  .toolbar--grid.vertical >>> .v-toolbar__content > * {
    margin-top: 16px;
  }

  >>> .last {
    order: 1;
  }

  .hidden {
    opacity: 0;
  }
</style>
