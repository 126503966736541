<template>
  <v-container fluid class="pa-0">
    <v-toolbar height="56">
      <v-spacer/>
      <v-form @submit.prevent="performSearch" class="action-form" ref="searchForm">
        <search-settings v-model="searchText" @search="performSearch">
          <v-checkbox
              label="Search all sites"
              hide-details
              class="mt-0"
              color="accent"
              v-model="searchAllSites"/>
          <v-checkbox
              label="Active accounts only"
              hide-details
              class="mt-0"
              color="accent"
              v-model="searchActiveAccounts"/>
        </search-settings>
        <card-scan-search
            v-model="cardScan.visible"
            @scan="findPersonForCard"
            :alert-type="cardScan.type"
            :alert-message="cardScan.message"
            :card-title="cardScan.title"
            :searching="cardScan.searching"/>
        <export-site-members-button class="mt-1"/>
      </v-form>
    </v-toolbar>
    <person-list :items="people" :exclude-tags="excludedTags"/>
    <div v-observe-visibility="observerOptions"/>
    <v-card :loading="loadingInfo.loading" flat tile>
      <v-alert v-if="loadingInfo.text" :color="loadingInfo.color" :type="loadingInfo.type" tile text class="ma-0">
        {{ loadingInfo.text }}
        <span v-if="loadingInfo.noRecords && searchText">
          when searching for <b>{{ searchText }}</b>
        </span>
      </v-alert>
      <div v-if="loadingInfo.noRecords && searchText" class="ml-4 mt-4">
        <p class="mt-5">Suggestions:</p>
        <ul>
          <li>Ensure that all names are spelled correctly</li>
          <li>Try the first or last name only</li>
          <li v-if="!search.hasOwnProperty('all-sites')">
            Search across <a @click="searchAllSites = true; performSearch()">all sites</a>
          </li>
        </ul>
      </div>
    </v-card>
    <v-tooltip top>
      <template #activator="{on}">
        <v-btn
            :disabled="!activeSiteDoc"
            v-on="on"
            fab
            bottom
            right
            fixed
            color="secondary"
            :to="{name: 'new-person'}">
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </template>
      <span>Add a person</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import CardScanSearch from '@/components/cards/CardScanSearch';
import PersonList from '@/components/people/PersonList';
import ExportSiteMembersButton from '@/components/cards/ExportSiteMembersButton';
import SearchSettings from '@/components/SearchSettings';
import {ReferenceString} from '@/store/collection/reference-string';
import {ObserveVisibility} from 'vue-observe-visibility';
import {mapActions, mapGetters} from 'vuex';

/**
 * A list of the query properties we support and will act upon.
 *
 * @type {Set<string>}
 */
const queryProperties = new Set([
  'site.membership.type'
]);

export default {
  name: 'PeopleDirectory',
  components: {SearchSettings, CardScanSearch, PersonList, ExportSiteMembersButton},
  directives: {ObserveVisibility},
  props: {
    search: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // we should attempt to fetch more records, assuming we think that will be successful
      itemsNeedLoading: true,
      localData: {
        searchText: null,
        allSites: null,
        active: null
      },
      cardScan: {
        visible: false,
        searching: false,
        message: '',
        title: null,
        type: undefined
      },
      observerOptions: {
        callback: this.handleIntersect,
        intersection: {
          rootMargin: '100px'
        }
      }
    };
  },
  computed: {
    ...mapGetters(['ns']),
    ...mapGetters('views/people', ['people', 'loadingInfo']),
    ...mapGetters('sites', ['activeSiteId', 'activeSiteDoc']),
    ...mapGetters('auth', ['hasAuthUser']),
    queryClauses() {
      const clauses = [];
      const search = this.search || {};
      Object.entries(search).forEach(([k, v]) => {
        if (queryProperties.has(k)) {
          clauses.push([k, '==', v]);
        }
      });

      // special keywords handling
      if (search.q) {
        const parts = search.q.toLowerCase().split(/\s/g);
        if (parts.length === 1) {
          clauses.push(['keywords', 'array-contains', parts[0]]);
        } else if (parts.length > 1) {
          clauses.push(['keywords', 'array-contains-any', parts]);
        }
      }

      // check for document refs
      if (!search.hasOwnProperty('all-sites') && this.activeSiteId !== '') {
        clauses.push(['site.ref', '==', new ReferenceString(`ns/${this.ns}/sites/${this.activeSiteId}`)]);
      }

      if (!search.hasOwnProperty('inactive')) {
        clauses.push(['person.active', '==', true]);
      }

      return clauses;
    },
    excludedTags() {
      return Object.entries(this.search || {})
          .filter(([prop, val]) => prop.startsWith('person.tags.') && val === true)
          .map(([prop]) => prop.substr('person.tags.'.length));
    },
    searchText: {
      get() {
        if (this.localData.searchText !== null) {
          return this.localData.searchText;
        }

        if (this.search.q) {
          return this.search.q;
        }

        return '';
      },

      set(val) {
        this.localData.searchText = val;
      }
    },
    searchAllSites: {
      get() {
        if (this.localData.allSites !== null) {
          return this.localData.allSites;
        }

        return this.search.hasOwnProperty('all-sites');
      },
      set(v) {
        this.localData.allSites = v;
      }
    },
    searchActiveAccounts: {
      get() {
        if (this.localData.active !== null) {
          return this.localData.active;
        }
        return !this.search.hasOwnProperty('inactive');
      },
      set(v) {
        this.localData.active = v;
      }
    }
  },
  watch: {
    queryClauses: {
      deep: true,
      handler(clauses) {
        this.getRecords(clauses);
      },
      immediate: true
    },
    search: {
      deep: true,
      handler() {
        // remove any local data if the incoming search properties change, they are always more important
        Object.keys(this.localData).forEach(k => this.localData[k] = null);
      }
    },
    'cardScan.visible'(v) {
      if (v) {
        this.cardScan.searching = false;
        this.cardScan.message = '';
        this.cardScan.title = null;
        this.cardScan.type = undefined;
      }
    }
  },
  methods: {
    ...mapActions('views/people', ['bind', 'load', 'stop']),
    getRecords(clauses) {
      if (!this.hasAuthUser) return;
      this.bind(clauses)
          .then(() => {
            if (this.itemsNeedLoading) {
              return this.load();
            }
          })
          .catch(err => this.$logger.warn('Failed bind after query change', err));
    },
    /**
     * @param {boolean} isVisible
     */
    handleIntersect(isVisible) {
      this.itemsNeedLoading = isVisible;
      if (this.itemsNeedLoading) {
        this.load();
      } else {
        this.stop();
      }
    },
    performSearch() {
      if (this.searchText !== this.search.keywords) {
        const query = {...this.$route.query, q: this.searchText};
        if (!this.searchText) {
          delete query.q;
        }
        if (this.searchAllSites) {
          query['all-sites'] = true;
        } else {
          delete query['all-sites'];
        }

        if (!this.searchActiveAccounts) {
          query['inactive'] = true;
        } else {
          delete query['inactive'];
        }
        this.$router.push({query}).catch(() => {
          this.$logger.debug('aborted');
        });
      }
    },
    findPersonForCard(id) {
      this.$logger.debug('findPersonForCard', id);
      this.cardScan.searching = true;
      this.$store.dispatch('views/cards/getByUid', {uid: id})
          .then(card => {
            this.cardScan.searching = false;
            if (!card || !card.exists) {
              this.cardScan.message = 'This card hasn\'t been assigned to anybody';
              return;
            }

            if (!card.owner || !card.owner.ref) {
              this.$logger.debug('card ' + id + ' isn\'t assigned to anyone');
              this.cardScan.message = 'This card isn\'t assigned to anybody';
              this.cardScan.title = card.title;
              return;
            }

            this.cardScan.visible = false;
            this.$router.push({name: 'person', params: {id: card.owner.ref.id}});
          })
          .catch(err => {
            this.cardScan.type = 'error';
            this.cardScan.message = 'There was an error, please scan again';
            this.$logger.error('Unable to find person for card', err);
          })
          .finally(() => this.cardScan.searching = false);
    }
  }
};
</script>

<style scoped>
  .action-form {
    /* Width of last two table columns - toolbar right padding + input left padding */
    width: calc(35% - 16px + 8px);
    min-width: 20em;
    display: flex;
    align-items: center;
  }
</style>
