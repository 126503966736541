/**
 * @param {string|undefined} key - of the form: letter(s) followed by a number
 * @param {string} [fallback] - value to use if key is undefined
 * @return {string}
 */
export function incrementSuffix(key, fallback) {
  if (!key) {
    return fallback;
  }
  const match = key.match(/(?<prefix>[a-z]*)(?<num>\d*)/i);
  const num = match && match.groups && match.groups.num;
  const prefix = match && match.groups && match.groups.prefix;
  if (num) {
    return `${prefix}${parseInt(num) + 1}`;
  }
  return key + 1;
}
