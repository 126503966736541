<template>
  <v-dialog v-model="open" max-width="480">
    <template #activator="{on}">
      <v-btn text class="text-none" v-on="on">
        <v-icon left color="grey">mdi-cog</v-icon>
        My Preferences
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Preferences
        <v-spacer/>
        <v-btn icon @click="open = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <edit-display-name/>
        <edit-preferred-site/>
        <edit-group-sites-by-country/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import breakpoints from '@/plugins/breakpoints';
import EditPreferredSite from '@/components/preferences/EditPreferredSite';
import EditDisplayName from '@/components/preferences/EditDisplayName';
import EditGroupSitesByCountry from '@/components/preferences/EditGroupSitesByCountry';

export default {
  name: 'Preferences',
  components: {EditDisplayName, EditPreferredSite, EditGroupSitesByCountry},
  mixins: [breakpoints],
  data() {
    return {
      open: false,
      editing: false,
      reloading: false
    };
  },
  watch: {
    open() {
      if (this.open) {
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }
    }
  }
};
</script>

<style scoped>

</style>
