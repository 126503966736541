<template>
  <v-snackbar :timeout="-1" light color="grey lighten-5" :value="true">
    <div class="d-inline-flex align-center py-2">
      <v-menu :close-on-content-click="false" top offset-y v-model="menuOpen">
        <template #activator="{ on }">
          <v-btn icon height="24px" width="24px" v-on="on">
            <v-icon :color="fill">
              mdi-checkbox-blank-circle
            </v-icon>
          </v-btn>
        </template>

        <v-card min-width="300">
          <v-card-title class="px-4 py-2 grid">
            Change Color
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-color-picker hide-mode-switch mode="rgba" v-model="color"/>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field dense hide-details type="number" prefix="X:" class="mx-2 mt-0" v-model="cx"/>
      <v-text-field dense hide-details type="number" prefix="Y:" class="mx-2 mt-0" v-model="cy"/>
      <v-text-field dense hide-details type="number" prefix="Radius:" class="mx-2 mt-0" v-model="r"/>
    </div>

    <template #action="{ attrs }">
      <v-btn icon @click="deleteCircle"><v-icon>mdi-delete</v-icon></v-btn>
      <v-btn color="error" text v-bind="attrs" @click="unbindCircle">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'CirclesPopup',
  data() {
    return {
      snackbar: false,
      menuOpen: false
    };
  },
  computed: {
    ...mapState('views/deskBooking/settings/circles', ['circle']),
    color: {
      get() {
        if (!this.fill) return null;
        const [r, g, b, a] = this.fill.slice(
            this.fill.indexOf('(') + 1,
            this.fill.indexOf(')')
        ).split(',');
        return {r: r.trim(), g: g.trim(), b: b.trim(), a: a.trim()};
      },
      set(v) {
        if (!v) return;
        this.fill = `rgba(${v.r}, ${v.g}, ${v.b}, ${v.a.toFixed(2)})`;
      }
    },
    showColor() {
      if (typeof this.color === 'string') return this.color;

      return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
        color[key] = Number(this.color[key].toFixed(2));
        return color;
      }, {}), null, 2);
    },
    cx: {
      get() {
        if (!this.circle) return null;
        return this.circle.cx;
      },
      set(v) {
        this.circle.cx = v;
      }
    },
    cy: {
      get() {
        if (!this.circle) return null;
        return this.circle.cy;
      },
      set(v) {
        this.circle.cy = v;
      }
    },
    r: {
      get() {
        if (!this.circle) return null;
        return this.circle.r;
      },
      set(v) {
        this.circle.r = v;
      }
    },
    fill: {
      get() {
        if (!this.circle) return null;
        return this.circle.fill;
      },
      set(v) {
        if (!v) return;
        this.circle.fill = v;
      }
    }
  },
  methods: {
    ...mapMutations('views/deskBooking/settings/circles', ['setCircle']),
    unbindCircle() {
      this.setCircle(null);
    },
    deleteCircle() {
      this.circle.delete();
      this.unbindCircle();
    }
  }
};
</script>

