import {EditableConfigUtil} from './editable-config-util';
import {toEditableConfig} from '@/views/admin/site/editable-config';
import {byId} from '@/store/firestore-util';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    config: {},
    edits: {},
    valid: false
  },
  getters: {
    /**
     * A Vuex getter function that returns a flat configuration object
     * using default site configurations. This function primarily processes
     * the nested configurations and extracts key-value pairs to generate a
     * single flat object.
     *
     * @param {Object} state - The state object of the current module.
     * @param {Object} getters - All getter functions from the current module.
     * @param {Object} rootState - The state object of the root store.
     * @param {Object} rootGetters - All getter functions from the root store.
     *
     * @return {Object} - An object containing the key-value combination.
     * The keys are taken from the default site configuration (either loaded
     * from a remote config or from 'app-site-config.defaults.json') and the
     * values are their corresponding defaults.
     */
    defaultConfigValues(state, getters, rootState, rootGetters) {
      // Fetch the default site configuration from the root getters.
      const defaultSiteConfig = rootGetters['appConfig/defaultSiteConfig'];

      // Process each configuration item to flatten its structure.
      const flatConfig = defaultSiteConfig.map(item => {
        const newObj = {};

        // Iterate through keys of the current configuration item.
        Object.keys(item).forEach(key => {
          // If the value associated with the key is an object, further processing is required.
          if (typeof item[key] === 'object') {
            // Iterate through inner keys of the object.
            Object.keys(item[key]).forEach(innerKey => {
              const config = item[key][innerKey];

              // Assign the key and value to the new object.
              newObj[config.key] = config.value;

              // If the config object has children, process them.
              if (config.hasOwnProperty('children')) {
                Object.keys(config.children).forEach(childrenKey => {
                  const childConfig = config.children[childrenKey];

                  // Assign the child's key and value to the new object.
                  newObj[childConfig.key] = childConfig.value;
                });
              }
            });
          }
        });

        // Return the flattened configuration object for the current item.
        return newObj;
      });

      // Merge all flattened configuration objects into a single object and return.
      return Object.assign({}, ...flatConfig);
    },
    actualConfig(state, getters, rootState, rootGetters) {
      const nsConfig = rootState.ns.doc.config;
      const sitesById = rootGetters['sites/sitesById'];

      const conf = {};
      for (const site of Object.values(sitesById)) {
        if (site.hasOwnProperty('config') && site.hasOwnProperty('group')) {
          const {config, group} = site;
          conf[byId(group.ref)] = config || {};
        }
      }

      conf.global = nsConfig || {};
      return conf;
    },
    getDefaultConfigOptionName: (state, getters, rootState, rootGetters) => (key) => {
      const defaultSiteConfig = rootGetters['appConfig/defaultSiteConfig'];
      // Flat sections
      const flat = [].concat(...defaultSiteConfig.map(o => o.children));

      // Set key: text pair
      const collect = (array, result) => {
        array.forEach((el) => {
          if (el.children) {
            collect(el.children, result);
            result.push(el);
          } else {
            result.push(el);
          }
        });
      };
      const res = [];
      collect(flat, res);

      // find by key
      return (res.find(obj => obj.key === key) || {}).title;
    },
    // used to check which options should be open in the v-treeview component inside the SiteConfigOptionsList component
    optionsWithChildren(state, getters, rootState, rootGetters) {
      const defaultSiteConfig = rootGetters['appConfig/defaultSiteConfig'];
      const flat = [].concat(...defaultSiteConfig.map(o => o.children));
      const result = [];
      for (const item of Object.values(flat)) {
        if (item.hasOwnProperty('children')) result.push(item.key);
      }
      return result;
    }
  },
  mutations: {
    ...EditableConfigUtil.mutations(),

    setConfig(state, config) {
      Vue.set(state, 'config', config);
    },
    clearEdits(state) {
      state.edits = {};
    },
    setValid(state, value) {
      state.valid = value;
    }
  },
  actions: {
    createConfig(context) {
      const {commit, getters} = context;
      const convertedConfig = {};
      for (const [key, value] of Object.entries(getters.actualConfig)) {
        convertedConfig[key] = toEditableConfig(context, {config: {...getters.defaultConfigValues, ...value}, id: key});
      }
      commit('setConfig', convertedConfig);
    },
    clear({commit}) {
      commit('clearEdits');
    }
  }
};
