import DeferUtil from '@/store/defer-util';
import FirestoreUtil, {byRef, byId} from '@/store/firestore-util';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';
import {createSnippet, decorateSnapshot} from '@/firebase';
import moment from 'moment';
import firebase from 'firebase/app';
import {CascadingConfig} from '@/util/config';
import {nowMoment} from '@/util/dates';

const log = Logger.get('bookables');

export default {
  namespaced: true,
  state: {
    /** @type {Object.<string,DecoratedData>} */
    byRef: {},
    /** @type {Object.<string, Object.<string,DecoratedData>>}*/
    byFloor: {},
    /** @type {Object.<string, Listeners<firebase.firestore.QuerySnapshot>>} */
    floorListeners: {},
    /** @type {Object.<string, Promise<DecoratedData>>}*/
    bookableRequests: {}
  },
  getters: {
    byRef(state, getters, rootState, rootGetters) {
      return rootGetters['sites/aggregates/byRef'];
    },
    lastSelected(state, getters, rootState, rootGetters) {
      const selected = rootGetters['selection/lastSelected'];
      if (!selected) return null;
      const ref = byRef(selected);
      if (ref) {
        return state.byRef[ref];
      }
      const id = byId(selected);
      if (id) {
        return Object.values(state.byRef).find(b => byId(b) === id);
      }
      return null;
    },
    allBookables(state, getters, rootState, rootGetters) {
      const numericCollator = new Intl.Collator('en', {numeric: true});
      return rootGetters['sites/aggregates/byType']('bookables').sort((a, b) => {
        return numericCollator.compare(a.title, b.title);
      });
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    /**
     * @param {Object} state
     * @param {firebase.firestore.DocumentChange[]} querySnapshot
     */
    applyBookablesQuerySnapshot(state, querySnapshot) {
      // update the byRef index
      FirestoreUtil.indexQuerySnapshotUpdates(state.byRef, querySnapshot, byRef);

      // update our byFloor index
      FirestoreUtil.indexQuerySnapshotUpdates((doc, type) => {
        const floorRef = doc.get('floor.ref');
        if (!floorRef) return false; // no floor, no index

        const key = floorRef.path;
        if (key in state.byFloor) {
          return state.byFloor[key];
          // don't create the object if we're going to delete it anyway
        } else if (['added', 'modified'].includes(type)) {
          return Vue.set(state.byFloor, key, {});
        } else {
          return false;
        }
      }, querySnapshot, byRef, doc => state.byRef[byRef(doc)]);
    },
    addBookable(state, snapshot) {
      const key = snapshot.ref.path;
      if (key in state.byRef) throw new Error('bookable ' + snapshot.id + ' already indexed');
      const data = decorateSnapshot(snapshot);

      // store in the global index
      Vue.set(state.byRef, key, data);

      // store in the byFloor index
      /** @type {firebase.firestore.DocumentReference|undefined} */
      const floorRef = snapshot.get('floor.ref');
      if (floorRef) {
        const byFloor = state.byFloor[floorRef.path] || Vue.set(state.byFloor, floorRef.path, {});
        Vue.set(byFloor, key, data);
      }
    },
    addBookableRequest(state, {ref, request}) {
      if (ref.path in state.bookableRequests) {
        throw new Error('bookable ' + ref.id + ' already has a request active');
      }
      Vue.set(state.bookableRequests, ref.path, request);
    },
    removeBookableRequest(state, {ref}) {
      Vue.delete(state.bookableRequests, ref.path);
    },
    addFloorListener(state, {floor, callback}) {
      const key = floor.path;
      if (key in state.floorListeners) {
        state.floorListeners[key].add(callback);
      } else {
        Vue.set(state.floorListeners, key, new Listeners().add(callback));
      }
    },
    removeFloorListener(state, {floor, callback}) {
      const key = floor.path;
      if (key in state.floorListeners) {
        const lastListener = state.floorListeners[key].remove(callback);
        if (lastListener) {
          Vue.delete(state.floorListeners, key);
        }
      }
    }
  },
  actions: {
    /**
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {firebase.firestore.DocumentReference} payload.site
     * @return {function():void} function called to stop watching
     */
    watchSite({commit, dispatch}, {site}) {
      log.debug('watchSite', site.id);
      const defer = {};
      defer[site.path] = site.collection('bookables')
          .onSnapshot(
              async snap => commit('applyBookablesQuerySnapshot', FirestoreUtil.prepareQuerySnapshot(snap)),
              err => log.error('watching bookables in ' + site.id, err));
      commit('defer', defer);

      return () => {
        commit('reset', [site.path]);
      };
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {firebase.firestore.DocumentReference} payload.floor
     * @param {function(firebase.firestore.QuerySnapshot)} payload.callback function that will be called on snapshot
     *     updates. Don't rely on this for data, only for
     * @return {function():void} function called to stop watching
     */
    watchFloor({commit, state, dispatch}, {
      floor, callback = () => {
      }
    }) {
      // check if we're already listening to this floor
      const key = floor.path;
      const firstListener = !(key in state.floorListeners);

      commit('addFloorListener', {floor, callback});
      if (firstListener) {
        log.debug('watchFloor', floor.path);
        const defer = {};
        // floor.parent.parent == site
        defer[key] = floor.parent.parent.collection('bookables')
            .where('floor.ref', '==', floor)
            .onSnapshot(
                snap => {
                  commit('applyBookablesQuerySnapshot', FirestoreUtil.prepareQuerySnapshot(snap));
                  const listeners = state.floorListeners[key];
                  // simple guard just in case we have a race between store snapshots and listener removal
                  if (listeners) {
                    listeners.emit(snap);
                  }
                },
                err => log.error('watching bookables on ' + floor.path, err));
        commit('defer', defer);
      }
      // else we could notify the listener of the currently known data, but that's hard to get right

      return () => {
        commit('removeFloorListener', {floor, callback});
        // if nobody is listening to the results, stop the query
        if (!(key in state.floorListeners)) {
          commit('reset', [key]);
        }
      };
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {firebase.firestore.DocumentReference} ref
     * @return {Promise<DecoratedData>}
     */
    async getBookable({state, commit, dispatch}, ref) {
      const key = ref.path;
      if (key in state.byRef) {
        return state.byRef[key];
      }

      // request already pending, return the result
      if (key in state.bookableRequests) {
        return await state.bookableRequests[key];
      }

      // fetch the bookable and return it
      const getAndAddBookable = async () => {
        const bookable = await ref.get();
        commit('addBookable', bookable);
        return state.byRef[key];
      };
      const request = getAndAddBookable();
      // add pending request to the store for other requests to attach to
      commit('addBookableRequest', {ref, request});
      // wait for it to complete
      const bookable = await request;
      // request has completed, remove it from the store
      commit('removeBookableRequest', {ref});
      return bookable;
    },

    /**
     * Reserve the given space using the current linkedPerson.
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {DecoratedData} payload.space
     * @param {DecoratedData} payload.owner
     * @param {Date} payload.fromTime
     * @param {Date} [payload.onDay]
     * @return {Promise}
     */
    async reserveSpace({rootState, rootGetters}, {space, owner, fromTime, onDay}) {
      if (!space || !space.ref) throw new Error('cannot book space ' + space);

      const config = new CascadingConfig([
        space,
        rootGetters['sites/aggregates/byRef'](byRef(space.neighbourhood)),
        rootGetters['sites/aggregates/byRef'](byRef(space.floor)),
        rootGetters['sites/forSpace'](space),
        rootState.ns.doc
      ]);

      const bookingTimes = calcBookingTimes(rootGetters, config, {fromTime, onDay});

      const booking = {
        ...rootGetters['user/creatableSnippet'],
        space: createSnippet(space),
        owner: createSnippet(owner),
        ...bookingTimes,
        // advanced booking via the app
        requiresCheckIn: true
      };

      return space.ref.parent.parent.collection('bookings').add(booking);
    },

    /**
     * Set a bookable as disabled.
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {DecoratedData} payload.bookable
     * @param {boolean} payload.isDisabled
     * @param {string} payload.reason
     * @return {Promise<void>}
     */
    async setDisabled({}, {bookable, isDisabled, reason}) {
      log.debug(`setting ${bookable.title} disabled:${isDisabled}, reason: ${reason}`);
      let updateObj = {};
      if (isDisabled) {
        if (reason === undefined || reason === '') {
          updateObj = {disabled: true};
        } else {
          updateObj = {disabled: reason};
        }
      } else {
        updateObj = {disabled: firebase.firestore.FieldValue.delete()};
      }
      return await bookable.ref.update(updateObj);
    }
  }
};

/**
 * Helper class for managing listeners and event publication.
 *
 * @template {*} T
 */
class Listeners {
  /** Create a new instance */
  constructor() {
    /**
     * @type {function(T)[]}
     */
    this.callbacks = [];
  }

  /**
   * Call all callbacks with the given data
   *
   * @param {T} data
   */
  emit(data) {
    this.callbacks.forEach(c => c(data));
  }

  /**
   * Add a new callback
   *
   * @param {function(T)} callback
   * @return {Listeners<T>}this
   */
  add(callback) {
    this.callbacks.push(callback);
    return this;
  }

  /**
   * Remove the callback.
   *
   * @param {function(T)} callback
   * @return {boolean} if that was the last listener
   */
  remove(callback) {
    const idx = this.callbacks.findIndex(c => c === callback);
    if (idx >= 0) {
      this.callbacks.splice(idx, 1);
    }
    return this.callbacks.length === 0;
  }
}

/**
 * Calculates the from/to times for a booking given some seed properties
 *
 * @param {*} rootGetters
 * @param {CascadingConfig} config
 * @param {Object} [options]
 * @param {Date} [options.fromTime]
 * @param {Date} [options.toTime]
 * @param {Date} [options.onDay]
 * @return {{fromTime: Date, toTime: Date}}
 * @private
 */
export function calcBookingTimes(rootGetters, config, options) {
  let {fromTime, toTime, onDay} = options || {};
  if (!fromTime) {
    /** @type {moment.Moment} */
    const mOnDay = onDay ? moment(onDay) : nowMoment();
    const atTime = config.get('deskBookingStartTime', 'startOfDay');
    const atTimeOnDay = timeOnDay(mOnDay, atTime);

    const now = nowMoment();
    if (now.isBefore(atTimeOnDay)) {
      fromTime = atTimeOnDay.toDate();
    } else {
      fromTime = now.toDate();
    }
  }

  if (!toTime) {
    const atTime = config.get('deskBookingEndTime', 'endOfDay');
    const fromMoment = moment(fromTime);
    const atTimeOnDay = timeOnDay(fromMoment, atTime);

    if (fromMoment.isBefore(atTimeOnDay)) {
      toTime = atTimeOnDay.toDate();
    } else {
      toTime = atTimeOnDay.add(1, 'day').toDate();
    }
  }
  return {fromTime, toTime};
}

/**
 * Returns a Moment with the given time on the day specified by the first argument.
 *
 * @param {moment.Moment} day
 * @param {string} time A time string hh:mm[:ss[.mmm]] or one of the special values ['startOfDay', 'endOfDay']
 * @return {moment.Moment}
 */
function timeOnDay(day, time) {
  // special values
  if (time === 'endOfDay') return day.clone().endOf('day');
  if (time === 'startOfDay') return day.clone().startOf('day');

  return moment(day.format('YYYY-MM-DD') + 'T' + time);
}
