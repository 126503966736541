<template>
  <div>
    <person-info/>
    <v-divider/>
    <v-row v-if="activeSiteDoc" class="px-2">
      <v-col cols="12" md="6" lg="5">
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Permissions
          </v-col>
        </v-row>
        <person-site-permissions/>
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Membership Information
          </v-col>
        </v-row>
        <person-membership-info/>
      </v-col>
      <v-col cols="12" md="6" offset-lg="1">
        <v-row dense>
          <div class="title font-weight-regular">Access Cards</div>
          <v-spacer/>
          <person-card-printing/>
        </v-row>
        <person-access-cards/>
        <v-row dense v-if="showAccessControl">
          <v-col cols="12" class="title font-weight-regular">
            Access Control
          </v-col>
        </v-row>
        <person-access-control v-if="showAccessControl"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PersonAccessCards from '@/components/person/PersonAccessCards';
import PersonInfo from '@/components/person/PersonInfo';
import PersonSitePermissions from '@/components/person/PersonSitePermissions';
import PersonMembershipInfo from '@/components/person/PersonMembershipInfo';
import PersonAccessControl from '@/components/person/PersonAccessControl';
import {mapGetters} from 'vuex';
import PersonCardPrinting from '@/components/person/PersonCardPrinting';

export default {
  name: 'PersonView',
  components: {
    PersonCardPrinting,
    PersonAccessControl,
    PersonMembershipInfo,
    PersonInfo,
    PersonAccessCards,
    PersonSitePermissions
  },
  computed: {
    ...mapGetters('sites', ['activeSiteDoc']),
    showAccessControl() {
      if (!this.activeSiteDoc) {
        return false;
      }
      return this.activeSiteDoc.accessControlOptions;
    }
  }
};
</script>

<style scoped>

</style>
