import {dbUtil, updateDataToArray} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import {byRef} from '@/store/firestore-util';
import Vue from 'vue';
import {
  newNeighbourhoodData, toEditableNeighbourhood
} from '@/views/desk-booking/settings/neighbourhoods/editable-neighbourhood';
import {EditableUtil} from '@/util/editable-util';

const log = Logger.get('desk-booking/settings/neighbourhoods');

export const defaultColor = '#ff0000';
export const defaultExclusiveColor = '#0000ff';

export default {
  namespaced: true,
  state: {
    /**
     * Array of all neighbourhoods for current site
     *
     * @type {EditableNeighbourhood[]}
     */
    neighbourhoods: [],
    /**
     * EditableNeighbourhood currently selected in the component
     *
     * @type {EditableNeighbourhood}
     */
    selectedNeighbourhood: null,
    /**
     * Keyed by neighbourhood id, each edit represents changes to a neighbourhood.
     *
     * @type {Object<string,Object>}
     */
    edits: {},
    /**
     * When a new neighbourhood is added, we put it here before committing the change.
     *
     * @type {EditableNeighbourhood[]}
     */
    added: [],
    /**
     * When a neighbourhood is deleted, add it to the array
     *
     * @type {EditableNeighbourhood[]}
     */
    deleted: []
  },
  getters: {
    /**
     * Stores a modified copy of the neighbourhoods list that includes unsaved changes
     *
     * @param {Object} state
     * @return {EditableNeighbourhood[]}
     */
    allNeighbourhoods(state) {
      const neighbourhoods = state.neighbourhoods.filter(n => !n.deleted);
      return neighbourhoods.concat(state.added);
    },
    /**
     * This is used in addition to sortedNeighbourhoods because this one uses
     * allNeighbourhoods, which contains unsaved changes, whereas sortedNeighbourhoods does not.
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {EditableNeighbourhood[]}
     * @type {EditableNeighbourhood[]}
     */
    allNeighbourhoodsSorted(state, getters) {
      if (!getters.allNeighbourhoods) return null;

      return Object.values(getters.allNeighbourhoods).sort(function(a, b) {
        const keyA = a.title.toLowerCase();
        const keyB = b.title.toLowerCase();
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    },
    includeDeletedNeighbourhoods(state) {
      return state.neighbourhoods.concat(state.added);
    },
    neighbourhoodsByFloor(state, getters, rootState, rootGetters) {
      const activeFloorRef = byRef(rootGetters['views/deskBooking/settings/map/activeFloor']);
      const byFloor = {activeFloor: [], other: []};
      for (const neighbourhood of getters.allNeighbourhoods) {
        const a = neighbourhood.includesFloor(activeFloorRef) ? byFloor.activeFloor : byFloor.other;
        a.push(neighbourhood);
      }
      sortNeighbourhoods(byFloor.activeFloor);
      sortNeighbourhoods(byFloor.other);
      return byFloor;
    },
    edits(state) {
      return Object.entries(state.edits);
    },
    editsByNeighbourhoodId(state) {
      return id => state.edits[id];
    },
    deletions(state) {
      return state.deleted;
    },

    selectedNeighbourhood(state) {
      return state.selectedNeighbourhood;
    },

    existingNeighbourhoods(state, getters, rootState, rootGetters) {
      const activeSitePath = byRef(rootGetters['sites/activeSiteDoc']);
      const neighbourhoodsInSite = rootGetters['sites/aggregates/bySiteByType'](activeSitePath, 'spaces');
      if (neighbourhoodsInSite) {
        return Object.values(neighbourhoodsInSite)
            .filter(n => n.kind && byRef(n.kind) === 'space-kinds/neighbourhood');
      }
      return null;
    },
    sortedNeighbourhoods(state) {
      if (!state.neighbourhoods) return null;

      return Object.values(state.neighbourhoods).sort(function(a, b) {
        const keyA = a.title.toLowerCase();
        const keyB = b.title.toLowerCase();
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    ...EditableUtil.mutations(),
    setNeighbourhood(state, neighbourhood) {
      state.selectedNeighbourhood = neighbourhood;
    },
    clearNeighbourhood(state) {
      state.selectedNeighbourhood = null;
    },
    setNeighbourhoods(state, neighbourhoods) {
      Vue.set(state, 'neighbourhoods', neighbourhoods);
    },
    clearNeighbourhoods(state) {
      state.neighbourhoods = [];
    },
    deleteNeighbourhood(state, neighbourhood) {
      /**
       * If the neighbourhood has been added in this batch of changes, remove it from
       * the added array, so it doesn't appear in the sidebar to the user
       */
      const addedIndex = state.added.findIndex(n => n.id === neighbourhood.id);
      if (addedIndex !== -1) {
        state.added.splice(addedIndex, 1);
        Vue.delete(state.edits, neighbourhood.id);
      }

      state.deleted.push(neighbourhood);
    },
    addNeighbourhood(state, neighbourhood) {
      state.added.push(neighbourhood);
    },
    resetEdit(state, neighbourhood) {
      Vue.delete(state.edits, neighbourhood.id);
      // handle the case where the neighbourhood has been deleted
      const deletedIndex = state.deleted.findIndex(e => e.id === neighbourhood.id);
      if (deletedIndex >= 0) {
        state.deleted.splice(deletedIndex, 1);
      }
      // handle the case where the edit is part of a new neighbourhood
      const addedIndex = state.added.findIndex(e => e.id === neighbourhood.id);
      if (addedIndex >= 0) {
        state.added.splice(addedIndex, 1);
      }
    },
    editComplete(state, neighbourhood) {
      Vue.delete(state.edits, neighbourhood.id);
      // handle the case where the neighbourhood has been deleted
      const deletedIndex = state.deleted.findIndex(e => e.id === neighbourhood.id);
      if (deletedIndex >= 0) {
        state.deleted.splice(deletedIndex, 1);
      }
      // handle the case where the edit is part of a new neighbourhood
      const addedIndex = state.added.findIndex(e => e.id === neighbourhood.id);
      if (addedIndex >= 0) {
        state.added.splice(addedIndex, 1);
      }
    },
    clearState(state) {
      state.added = [];
      state.deleted = [];
      state.edits = {};
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (authUser) {
          return dispatch('fetchNeighbourhoods');
        } else {
          return dispatch('clear');
        }
      }
    },
    async fetchNeighbourhoods({state, getters, commit, dispatch}) {
      if (DeferUtil.hasDefer(state)) {
        // already bound
        return;
      }
      const defer = {};
      defer.neighbourhoodsChange = this.watch(
          () => getters['existingNeighbourhoods'],
          neighbourhoods => {
            commit('clearNeighbourhood');
            if (!neighbourhoods) {
              return dispatch('clear');
            }
            dispatch('convert', neighbourhoods)
                .catch(err => log.error('During neighbourhoods fetch', err));
          }
      );
      commit('defer', defer);
    },
    convert(context, neighbourhoods) {
      context.commit('setNeighbourhoods', neighbourhoods.map(n => toEditableNeighbourhood(context, n)));
    },
    clear({commit}) {
      commit('clearState');
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {BatchGroup} batchGroup
     * @return {Promise<void>}
     */
    async commitAll({state, commit}, batchGroup) {
      for (const neighbourhood of state.added) {
        // edits for added neighbourhoods are still in the edits state
        const edits = state.edits[neighbourhood.id] || {};
        const value = {
          ...neighbourhood.value,
          ...edits
        };
        delete value.ref;
        batchGroup.set(neighbourhood.ref, value);
      }
      for (const [id, change] of Object.entries(state.edits)) {
        const added = state.added.findIndex(n => n.id === id) !== -1;
        const deleted = state.deleted.findIndex(n => n.id === id) !== -1;
        if (!added && !deleted) {
          const neighbourhood = state.neighbourhoods.find(n => n.id === id);
          batchGroup.update(neighbourhood.ref, ...updateDataToArray(change));
        }
      }
      for (const neighbourhood of state.deleted) {
        batchGroup.delete(neighbourhood.ref);
      }
    },

    async createNewNeighbourhood(context, {title, color, exclusive = false, exclusiveAdHoc = false}) {
      log.debug('createNewNeighbourhood', {title, color, exclusive});
      const {commit, rootState, rootGetters} = context;
      const creatableSnippet = rootGetters['user/creatableSnippet'];
      const spaceKind = await dbUtil.doc('space-kinds/neighbourhood');

      const ns = rootState.ns.doc.id;
      const activeSiteId = rootGetters['sites/activeSiteId'];
      const spacesCol = await dbUtil.collection(`ns/${ns}/sites/${activeSiteId}/spaces`);

      const newNeighbourhood = newNeighbourhoodData(
          title,
          color,
          exclusive,
          exclusiveAdHoc,
          spaceKind,
          creatableSnippet
      );

      log.info('newNeighbourhood');
      log.info(newNeighbourhood);

      Object.defineProperty(newNeighbourhood, 'ref', {
        enumerable: true,
        writable: false,
        value: spacesCol.doc()
      });
      Object.defineProperty(newNeighbourhood, 'id', {
        enumerable: false,
        get() {
          return this.ref.id;
        }
      });

      const neighbourhood = toEditableNeighbourhood(context, newNeighbourhood);
      commit('addNeighbourhood', neighbourhood);
      commit('recordEdit', {document: newNeighbourhood});
      return neighbourhood;
    }
  }
};

/**
 * @param {EditableNeighbourhood[]} neighbourhoods
 *
 * @return {EditableNeighbourhood[]}
 */
function sortNeighbourhoods(neighbourhoods) {
  return neighbourhoods.sort(function(a, b) {
    const keyA = a.title.toLowerCase();
    const keyB = b.title.toLowerCase();
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
}

