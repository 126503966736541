import {DocumentCollection} from '@/store/collection/document-collection';

/**
 * All the information related to a list of card changes. Including the query used to collect that list and any
 * metadata about the list that we think we know - i.e. do we think there are more records to get.
 */
export class PersonCardHistoryCollection extends DocumentCollection {
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {firebase.firestore.DocumentReference} personRef
   */
  constructor(ns, personRef) {
    super(ns, []);

    /**
     * @type {firebase.firestore.DocumentReference}
     */
    this.personRef = personRef;
  }


  /**
   * @override
   * @return {Promise<firebase.firestore.Query<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    // all card changes which relate to this person taking ownership (a card assignment)
    return this.personRef.firestore.collection(`ns/${this.ns}/search/changes/records`)
        .where('document.path', 'array-contains', `ns/${this.ns}/cards`)
        .where('change.after.owner.ref', '==', this.personRef)
        .orderBy('change.changeTime', 'desc');
  }
}
