<template>
  <div>
    <div style="position: absolute;right: 10px;bottom:15px;z-index: 10">
      <v-btn text color="error" @click="discardChanges" :disabled="!hasAnyEdits">Discard</v-btn>
      <v-btn
          color="primary"
          class="primary-text--text mr-2"
          @click="saveChanges"
          :loading="saving"
          :disabled="!hasAnyEdits">
        Save
      </v-btn>
    </div>
    <v-expansion-panels v-model="panelsExpanded" :disabled="!hasAnyEdits" class="top-shadow">
      <v-expansion-panel v-model="expanded" style="max-height: 100vh;overflow-y: auto">
        <v-expansion-panel-header
            style="position:sticky;z-index: 10;background: white;top: 0"
            @click="expanded = !expanded"
            hide-actions
            class="mt-1 d-inline-block">
          <v-icon v-if="!expanded" left class="d-inline-block mb-1">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else left class="d-inline-block mb-1">
            mdi-chevron-down
          </v-icon>
          <span>Changes</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat class="pb-10">
            <v-list class="pt-0">
              <v-subheader class="pl-0" v-if="hasNeighbourhoodEdits">
                <b>Neighbourhoods</b>
              </v-subheader>
              <div v-for="neighbourhood in neighbourhoods" :key="neighbourhood.id">
                <neighbourhood-changes v-if="neighbourhood.changed" :neighbourhood="neighbourhood"/>
              </div>

              <div v-if="deskEdits.length > 0">
                <v-subheader class="pl-0">
                  <b>Desks</b>
                </v-subheader>
                <div class="mb-4" v-for="[id, changes] of deskEdits" :key="id">
                  <div class="changes-title">
                    <b>{{ desks.find(n => n.id === id).title }}</b>
                  </div>
                  <div class="changes-subtitle">
                    <div v-for="[variable, val] of Object.entries(changes)" :key="variable">
                      <span v-if="variable === 'disabled'">
                        <b v-if="val">Disabled</b>
                        <b v-else>Enabled</b>
                      </span>
                      <span v-if="variable === 'disabledAfterUse'">
                        <b v-if="val">Disabled After Use</b>
                        <b v-else>Available Immediately After Use</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="circlesChanged">
                <v-subheader class="pl-0">
                  <b>Circles</b>
                </v-subheader>
                <div class="mb-4" v-for="floor of floorsWithCircleChanges" :key="floor">
                  <div class="changes-title">
                    <b>{{ getFloorTitle(floor) }}</b>
                  </div>
                  <div class="changes-subtitle">
                    <span>
                      <b>Circles Changed</b>
                    </span>
                  </div>
                </div>
              </div>
            </v-list>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog :value="saving" max-width="350">
      <v-card>
        <v-card-title>Saving Changes</v-card-title>
        <v-card-subtitle>Changes are being saved, please wait...</v-card-subtitle>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import NeighbourhoodChanges from '@/views/desk-booking/settings/sidebar/NeighbourhoodChanges';

export default {
  name: 'Changes',
  components: {NeighbourhoodChanges},
  data() {
    return {
      expanded: false,
      panelsExpanded: [],
      saving: false
    };
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['edits', 'deletions', 'includeDeletedNeighbourhoods']),
    ...mapState('views/deskBooking/settings/desks', ['desks']),
    ...mapGetters('views/deskBooking/settings/desks', {deskEdits: 'edits', neighbourhoodEdits: 'neighbourhoodEdits'}),
    ...mapGetters('views/deskBooking/settings/circles', ['allCircles', 'floorsWithCircleChanges']),
    ...mapState('views/deskBooking/settings/circles', ['deleted']),
    ...mapGetters('views/deskBooking/settings/map', ['activeFloor']),
    ...mapGetters('floors', ['siteFloors']),
    neighbourhoods() {
      if (!this.includeDeletedNeighbourhoods) return null;
      return this.includeDeletedNeighbourhoods;
    },
    hasNeighbourhoodEdits() {
      if (!this.includeDeletedNeighbourhoods) return null;
      for (const neighbourhood of this.includeDeletedNeighbourhoods) {
        if (neighbourhood.edited) return true;
        if (neighbourhood.added) return true;
        if (neighbourhood.deleted) return true;
      }
      return this.neighbourhoodEdits.length > 0;
    },
    hasAnyEdits() {
      return this.hasNeighbourhoodEdits || this.deskEdits.length > 0 || this.circlesChanged;
    },
    circlesChanged() {
      for (const circle of this.allCircles) {
        if (circle.edited || circle.added) {
          return true;
        }
      }
      return this.deleted.length > 0;
    }
  },
  methods: {
    ...mapActions('views/deskBooking/settings/changes', ['saveAllChanges', 'discardAllChanges']),
    getFloorTitle(floor) {
      return this.siteFloors.find(f => f.ref.path === floor).title;
    },
    async saveChanges() {
      const started = performance.now();
      try {
        this.saving = true;
        await this.saveAllChanges({circlesChanged: this.circlesChanged});
        this.$notify.showSuccess('Changes saved successfully');
      } catch (e) {
        this.$notify.showError('Error saving the changes');
        this.$logger.error('error saving changes', e);
      } finally {
        this.expanded = false;
        this.panelsExpanded = [];
        // minimum 4s "saving" to avoid dialog popping in and out too quickly
        const done = performance.now();
        setTimeout(() => {
          this.saving = false;
        }, 4 * 1000 - done - started);
      }
    },
    async discardChanges() {
      await this.discardAllChanges();
      this.expanded = false;
      this.panelsExpanded = [];
    }
  }
};
</script>

<style scoped>
.v-expansion-panel-header__icon {
  position: absolute;
  right: 70px;
}

.top-shadow {
  border-top: 1px solid #ddd;
  -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%) !important;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%) !important;
}

 .changes-subtitle {
   line-height: 1.3;
   font-size: 0.875rem;
   color: rgba(0,0,0,0.6);
 }
.changes-title {
  line-height: 1.3;
  font-size: 1rem;
}
 </style>
