<script>
import {Floor} from '@/store/floor';
import {colorByUtilisation} from '@/views/dashboard/statistics/heatmap/colors';

/**
 * @typedef {Object} BookableDesk
 * @property {DeskParts} desk
 * @property {Bookable} bookable
 */

export default {
  name: 'DeskHeatmap',
  props: {
    desks: {
      /** @type {BookableDesk[]} */
      type: Array,
      required: true
    },
    floor: {
      type: Floor,
      required: true
    },
    utilisation: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    utilisationByDesk() {
      if (!this.utilisation) {
        return {};
      }
      if (!this.utilisation.hasOwnProperty('byDesk')) {
        return {};
      }
      return this.utilisation.byDesk;
    },
    desksById() {
      const byId = {};
      for (const bookable of this.desks) {
        const id = bookable.desk.id;
        byId[id] = bookable.desk;
      }
      return byId;
    },
    colorByDesk() {
      const byDesk = {};

      for (const desk of Object.values(this.desksById)) {
        const id = desk.id;
        let value = colorByUtilisation[0];
        if (this.utilisationByDesk.hasOwnProperty(id)) {
          const percentage = Math.round(this.utilisationByDesk[id]);
          value = colorByUtilisation[percentage];
        }
        byDesk[id] = value;
      }

      return byDesk;
    }
  },
  watch: {
    desksById: {
      immediate: true,
      deep: true,
      handler() {
        this.deskUpdate();
      }
    },
    colorByDesk: {
      immediate: true,
      deep: true,
      handler() {
        this.updateHeatmap();
      }
    }
  },
  methods: {
    deskUpdate() {
      for (const desk of Object.values(this.desksById)) {
        const deskEl = desk.el;
        if (!deskEl) return;
        deskEl.classList.remove('disabled');
        deskEl.classList.add('solid');
      }
    },
    updateHeatmap() {
      for (const [id, color] of Object.entries(this.colorByDesk)) {
        const desk = this.desksById[id];
        const deskEl = desk.el;
        if (!deskEl) return;
        deskEl.style.setProperty('--color', color);
      }
    }
  },
  render() {
    return this.$slots.default;
  }
};
</script>
