import {mapGetters} from 'vuex';

/**
 * This mixin is used to reset the state for a given component when the active site is changed
 * We use this because if a user doesn't save their changes (using the update button) for a given site,
 * and the user changes then active sites,
 * the local changes remain, and the forms don't update to what the new active site's values are (if any)
 */
export default {
  computed: {
    ...mapGetters('sites', ['activeSiteId'])
  },
  watch: {
    activeSiteId: {
      handler(site) {
        this.reset();
      }
    }
  },
  methods: {
    /**
     * Ensure that this is implemented in components that use this mixin.
     * It should perform whatever state changes are necessary to be considered a "reset" for the component
     */
    reset() {
      this.$logger.warning('Active site changed. Make sure reset method from this mixin is implemented.');
    }
  }
};
