<template>
  <v-dialog v-model="open" max-width="1200" content-class="link-dialog">
    <template #activator="{on}">
      <v-btn color="accent darken-1" outlined v-on="on" :disabled="disabled">Link</v-btn>
    </template>

    <v-container fluid class="pa-0">
      <v-toolbar>
        <v-spacer/>
        <v-form @submit.prevent="performSearch">
          <search-settings style="max-width: 495px;" v-model="searchText" @search="performSearch">
            <v-checkbox
                label="Search all sites"
                hide-details
                class="mt-0"
                color="accent"
                v-model="search['all-sites']"/>
            <v-checkbox
                label="Active accounts only"
                hide-details
                class="mt-0"
                color="accent"
                v-model="search.active"/>
          </search-settings>
        </v-form>
      </v-toolbar>
      <person-list class="people" :items="people" :links="false">
        <template #default="{person}">
          <v-btn color="accent darken-1" outlined @click="linkPerson(person)" :loading="loading">Link</v-btn>
        </template>
      </person-list>
      <div v-observe-visibility="observerOptions"/>
      <!--
      we set a minimum height for this, else the v-observe-visibility
      above it doesn't fire correctly
      -->
      <v-card :loading="loadingInfo.loading" flat tile class="loading">
        <v-alert v-if="loadingInfo.text" :type="loadingInfo.type" tile text class="ma-0">
          {{ loadingInfo.text }}
        </v-alert>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import PersonList from '@/components/people/PersonList';
import SearchSettings from '@/components/SearchSettings';
import {ReferenceString} from '@/store/collection/reference-string';
import {ObserveVisibility} from 'vue-observe-visibility';
import {mapActions, mapGetters} from 'vuex';

/**
 * A list of the query properties we support and will act upon.
 *
 * @type {Set<string>}
 */
const queryProperties = new Set([
  'site.membership.type'
]);

export default {
  name: 'UserLinkPerson',
  components: {PersonList, SearchSettings},
  directives: {ObserveVisibility},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      search: {
        'all-sites': false,
        'active': true,
        // only search for permanent people (not visitors)
        'site.membership.type': 'permanent'
      },
      searchText: '',
      // we should attempt to fetch more records, assuming we think that will be successful
      itemsNeedLoading: true,
      observerOptions: {
        callback: this.handleIntersect,
        intersection: {
          rootMargin: '100px'
        }
      }
    };
  },
  computed: {
    ...mapGetters(['ns']),
    ...mapGetters('views/users/people', ['people', 'loadingInfo']),
    ...mapGetters('sites', ['activeSiteId']),
    queryClauses() {
      const clauses = [];
      const search = this.search;
      Object.entries(search).forEach(([k, v]) => {
        if (queryProperties.has(k)) {
          clauses.push([k, '==', v]);
        }
      });

      // special keywords handling
      if (this.searchText) {
        const parts = this.searchText.toLowerCase().split(/\s/g);
        if (parts.length === 1) {
          clauses.push(['keywords', 'array-contains', parts[0]]);
        } else if (parts.length > 1) {
          clauses.push(['keywords', 'array-contains-any', parts]);
        }
      }

      if ((!search.hasOwnProperty('all-sites') || !search['all-sites']) && this.activeSiteId !== '') {
        clauses.push(['site.ref', '==', new ReferenceString(`ns/${this.ns}/sites/${this.activeSiteId}`)]);
      }

      if (search.hasOwnProperty('active') && search.active) {
        clauses.push(['person.active', '==', true]);
      }

      return clauses;
    }
  },
  watch: {
    open(o) {
      this.$emit('input', o);
      if (o) {
        this.performSearch();
      }
    },
    value(v) {
      if (v !== this.open) {
        this.open = v;
      }
    }
  },
  methods: {
    ...mapActions('views/users/people', ['bind', 'load', 'stop']),
    getRecords(clauses) {
      this.bind(clauses)
          .then(() => {
            if (this.itemsNeedLoading) {
              return this.load();
            }
          })
          .catch(err => this.$logger.warn('Failed bind after query change', err));
    },
    /**
     * @param {boolean} isVisible
     */
    handleIntersect(isVisible) {
      this.$logger.debug('handleIntersect', isVisible);
      this.itemsNeedLoading = isVisible;
      if (this.itemsNeedLoading) {
        this.load();
      } else {
        this.stop();
      }
    },
    performSearch() {
      this.getRecords(this.queryClauses);
    },
    linkPerson(person) {
      this.$emit('link', person);
    }
  }
};
</script>

<style>
  .link-dialog {
    height: 90%;
  }
</style>

<style scoped>
  .container {
    height: 100%;
    background: white;
  }

  .people {
    border-radius: unset;
  }

  .loading {
    min-height: 56px;
  }
</style>
