/**
 * Represents a DocumentReference as a string. Can be used as a value in a query clause and will be converted to the
 * DocumentReference at query time.
 */
export class ReferenceString {
  /**
   * @param {string} value
   */
  constructor(value) {
    /**
     * The reference value that will be wrapped in firestore.doc during querying.
     *
     * @type {string}
     */
    this.value = value;
  }

  /**
   * Check whether this reference string is equivalent to the given value.
   *
   * @param {*} other
   * @return {boolean}
   */
  isEqual(other) {
    return other instanceof ReferenceString && other.value === this.value;
  }
}
