<template>
  <v-menu v-model="menuOpen" :close-on-content-click="false" offset-y>
    <template #activator="{on: onMenu}">
      <template v-if="toolTip">
        <v-tooltip bottom>
          <template #activator="{on: onTooltip}">
            <v-btn v-bind="allButtonOptions" v-on="{...onMenu, ...onTooltip}">
              <v-icon>mdi-credit-card-wireless-outline</v-icon>
            </v-btn>
          </template>

          <span>{{ toolTip }}</span>
        </v-tooltip>
      </template>
      <v-btn v-else v-bind="allButtonOptions" v-on="onMenu">
        <v-icon>mdi-credit-card-wireless-outline</v-icon>
      </v-btn>
    </template>
    <card-scan v-model="cardId" :disabled="!menuOpen">
      <template
          v-for="(slot, name) in $slots"
          #[name]>
        <slot v-if="name === 'default'"/>
        <slot v-else :name="name"/>
      </template>

      <template
          v-for="(slot, name) in $scopedSlots"
          #[name]="props">
        <slot v-if="name === 'default'" v-bind="props"/>
        <slot v-else :name="name" v-bind="props"/>
      </template>
    </card-scan>
  </v-menu>
</template>

<script>

import CardScan from '@/components/cards/CardScan';

const defaultButtonOptions = Object.freeze({
  icon: true
});

export default {
  name: 'CardScanButton',
  components: {CardScan},
  props: {
    value: Boolean,
    closeOnScan: Boolean,
    buttonOptions: {
      type: Object,
      default() {
        return defaultButtonOptions;
      }
    },
    toolTip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menuOpen: this.value,
      cardId: ''
    };
  },
  computed: {
    allButtonOptions() {
      return Object.assign({}, defaultButtonOptions, this.buttonOptions || {});
    }
  },
  watch: {
    value(v) {
      this.menuOpen = v;
    },
    menuOpen(v, oldV) {
      if (v) {
        this.cardId = '';
      }
      if (v !== oldV) {
        this.$emit('input', v);
      }
    },
    cardId(id) {
      if (id) {
        this.$emit('scan', id);
        if (this.closeOnScan) {
          this.menuOpen = false;
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
