<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col>
          <permanent-card-editor
              v-if="edit.permanent"
              :new-card="!hasPermanentCard"
              @cancel="edit.permanent = false"
              @done="edit.permanent = false"/>
          <permanent-card-view
              v-else-if="hasPermanentCard"
              @edit="edit.permanent = true"/>
          <card-view
              v-else
              @assign="edit.permanent = true"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <temporary-card-editor
              v-if="edit.temporary"
              :new-card="!hasTemporaryCard || newTemp"
              @cancel="doneEditingTemp"
              @done="doneEditingTemp"/>
          <temporary-card-view
              v-else-if="hasTemporaryCard"
              @new="newTempCard"
              @edit="edit.temporary = true"/>
          <card-view
              v-else
              temporary
              @assign="edit.temporary = true"/>
        </v-col>
      </v-row>
      <v-row class="pt-2" v-if="cardHistoryEnabled">
        <span v-if="showHistory" class="px-3 title font-weight-regular">History</span>
        <v-spacer/>
        <v-btn text @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show History' }}</v-btn>
      </v-row>
      <template v-if="cardHistoryEnabled && showHistory">
        <v-row dense>
          <v-col cols="12">
            <person-access-card-history/>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import CardView from '@/components/cards/CardView';
import PermanentCardEditor from '@/components/cards/PermanentCardEditor';
import PermanentCardView from '@/components/cards/PermanentCardView';
import TemporaryCardEditor from '@/components/cards/TemporaryCardEditor';
import TemporaryCardView from '@/components/cards/TemporaryCardView';
import {mapGetters} from 'vuex';
import PersonAccessCardHistory from '@/components/person/PersonAccessCardHistory';
import activeSiteReset from '@/mixins/active-site-reset';

export default {
  name: 'PersonAccessCards',
  components: {
    PersonAccessCardHistory,
    CardView, TemporaryCardView, PermanentCardView, PermanentCardEditor, TemporaryCardEditor},
  mixins: [activeSiteReset],
  data() {
    return {
      edit: {
        permanent: false,
        temporary: false
      },
      newTemp: false,
      showHistory: false
    };
  },
  computed: {
    ...mapGetters('appConfig', [
      'cardHistoryEnabled'
    ]),
    ...mapGetters('views/person', [
      'hasPermanentCard',
      'hasTemporaryCard'
    ])
  },
  methods: {
    doneEditingTemp() {
      this.newTemp = false;
      this.edit.temporary = false;
    },
    newTempCard() {
      this.newTemp = true;
      this.edit.temporary = true;
    },
    /**
     * A method to reset back to the default state,
     * which we want to do in certain cases like when changing active sites
     * For this, we want to remove edit mode from all cards
     */
    reset() {
      this.edit.permanent = false;
      this.edit.temporary = false;
      this.newTemp = false;
      this.showHistory = false;
    }
  }
};
</script>

<style scoped>

</style>
