<template>
  <div>
    <v-icon :color="color" x-large>{{ icon }}</v-icon>
    <h4>{{ label }}</h4>
    <span>{{ count }}</span>
  </div>
</template>

<script>
export default {
  name: 'Totals',
  props: {
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    count: {
      type: [Number, String],
      default: null
    }
  }
};
</script>

<style scoped>

</style>
