// NOTE: these functions are also in functions/src/integrations/brivo/cards/card-formats.js
// DO NOT EDIT THIS: make sure to update the brivo one too, which has tests, then copy back to here
// todo: share the code instead of copying it

/**
 * @typedef {Object} CardDetails
 * @property {number} facilityCode
 * @property {number} cardNumber
 */

/**
 * @typedef {function} ParseCard
 * @param {string} hexId
 * @return {CardDetails}
 */

/**
 * @typedef {function} EncodeCard
 * @param {number} cardNumber
 * @param {number} facilityCode
 * @return {string}
 */

/**
 * @typedef {Object} CardFormat
 * @property {number} brivoFormatId - Credential Format ID in Brivo
 * @property {kahu.firestore.CardLookupType|string} kahuTypeId - cardIdType used in Kahu
 * @property {EncodeCard} encode - encode card number + facility code into the HEX ID
 * @property {ParseCard} parse - parse encoded HEX ID into card number + facility code
 */

/**
 * @typedef {CardDetails} GenericCard
 * @property {number} facilityCode 0
 * @property {number|string} cardNumber any
 */


/**
 * @typedef {CardDetails} Generic34bitCard
 * @property {number} facilityCode 0-65535 (16 bits)
 * @property {number} cardNumber 0-65535 (16 bits)
 */

/**
 * @typedef {CardDetails} Standard26bitCard
 * @property {number} facilityCode 0-255 (8 bits)
 * @property {number} cardNumber 0-65535 (16 bits)
 */

/**
 * @typedef {CardDetails} Hid37BitFcCard
 * @property {number} facilityCode 0-65535 (16 bits)
 * @property {number} cardNumber 0-524287 (19 bits)
 */

/**
 * @typedef {CardDetails} HidCorporate1000Card
 * @property {number} facilityCode 0-4095 (12 bits)
 * @property {number} cardNumber 0-1048576 (20 bits)
 */

/**
 * @type {CardFormat}
 */
const Hid26BitCardFormat = {
  brivoFormatId: 100,
  kahuTypeId: '26bit',
  /**
   * Returns the hex representation of the encoded card ID using standard 26bit encoding
   *
   *  * <pre>
   *    ____ Leading Parity Bit (even)
   *   |  __ Facility Code (8 bits)
   *   | |
   *   | |
   *   P FFFFFFFF NNNNNNNNNNNNNNNN P__ Trailing Parity Bit (odd)
   *              |
   *              |_______________ Card Number (16 bits)
   *
   * Leading parity is for the first 12 data bits
   * Trailing parity is for the last 12 data bits
   * </pre>
   *
   * @see https://www.hidglobal.com/sites/default/files/hid-understanding_card_data_formats-wp-en.pdf
   *
   * @param {number} cardNumber 0-65535
   * @param {number} facilityCode 0-255
   * @return {string}
   */
  encode(cardNumber, facilityCode) {
    if (typeof cardNumber !== 'number' || cardNumber < 0 || cardNumber > 65535) {
      throw new Error(`invalid card number, expected 0 < cardNumber < 65535, got '${cardNumber}'`);
    }
    if (typeof facilityCode !== 'number' || facilityCode < 0 || facilityCode > 255) {
      throw new Error(`invalid facility code, expected 0 < facilityCode < 255, got '${facilityCode}'`);
    }
    const cardNumberBinary = cardNumber.toString(2).padStart(16, '0');
    const facilityCodeBinary = facilityCode.toString(2).padStart(8, '0');
    const first12 = facilityCodeBinary + cardNumberBinary.substring(0, 4);
    const last12 = cardNumberBinary.substring(4);
    const leadingParity = parityBit(first12);
    const trailingParity = parityBit(last12, false);

    const cardId = leadingParity + facilityCodeBinary + cardNumberBinary + trailingParity;
    return parseInt(cardId, 2).toString(16).toUpperCase();
  },
  /**
   * Parse a hex card id using standard 26-bit encoding, returning the facility code and card number.
   *
   * <pre>
   *    ____ Leading Parity Bit (even)
   *   |  __ Facility Code (8 bits)
   *   | |
   *   | |
   *   P FFFFFFFF NNNNNNNNNNNNNNNN P__ Trailing Parity Bit (odd)
   *              |
   *              |_______________ Card Number (16 bits)
   *
   * Leading parity is for the first 12 data bits
   * Trailing parity is for the last 12 data bits
   * </pre>
   *
   * @see https://www.hidglobal.com/sites/default/files/hid-understanding_card_data_formats-wp-en.pdf
   * @param {string} hexId card id as a hex string
   * @return {Standard26bitCard}
   */
  parse(hexId) {
    const binary = parseInt(hexId, 16).toString(2).padStart(26, '0');
    const facilityCodeBinary = binary.substring(1, 9);
    const cardNumberBinary = binary.substring(9, 25);
    const facilityCode = parseInt(facilityCodeBinary, 2);
    const cardNumber = parseInt(cardNumberBinary, 2);
    return {facilityCode, cardNumber};
  }
};

const Generic34bitCardFormat = {
  /**
   * Returns the hex representation of the encoded card ID using standard 26bit encoding
   *
   * <pre>
   *    ____ Leading Parity Bit (even|odd)
   *   |  __ Facility Code (16 bits)
   *   | |
   *   | |
   *  P FFFFFFFFFFFFFFFF NNNNNNNNNNNNNNNN P__ Trailing Parity Bit (even|odd)
   *                      |
   *                      |_______________ Card Number (16 bits)
   *
   * Leading parity is for the first 16 data bits
   * Trailing parity is for the last 16 data bits
   * </pre>
   *
   *
   * @param {number} cardNumber 0-65535
   * @param {number} facilityCode 0-65535
   * @param {boolean} [evenParity]
   * @return {string}
   */
  encode(cardNumber, facilityCode, evenParity = true) {
    if (typeof cardNumber !== 'number' || cardNumber < 0 || cardNumber > 65535) {
      throw new Error(`invalid card number, expected 0 < cardNumber < 65535, got '${cardNumber}'`);
    }
    if (typeof facilityCode !== 'number' || facilityCode < 0 || facilityCode > 65535) {
      throw new Error(`invalid facility code, expected 0 < facilityCode < 65535, got '${facilityCode}'`);
    }
    const cardNumberBinary = cardNumber.toString(2).padStart(16, '0');
    const facilityCodeBinary = facilityCode.toString(2).padStart(16, '0');
    const first16 = facilityCodeBinary;
    const last16 = cardNumberBinary;
    const leadingParity = parityBit(first16, evenParity);
    const trailingParity = parityBit(last16, evenParity);

    const cardId = leadingParity + facilityCodeBinary + cardNumberBinary + trailingParity;
    return parseInt(cardId, 2).toString(16).toUpperCase();
  },
  /**
   * Parse a hex card id using generic 34-bit encoding, returning the facility code and card number.
   *
   * <pre>
   *   ____ Leading Parity Bit (even|odd)
   *   |  __ Facility Code (16 bits)
   *   | |
   *   | |
   *   P FFFFFFFFFFFFFFFF NNNNNNNNNNNNNNNN P__ Trailing Parity Bit (odd|even)
   *                      |
   *                      |_______________ Card Number (16 bits)
   *
   * Leading parity is for the first 16 data bits
   * Trailing parity is for the last 16 data bits
   * </pre>
   *
   * @param {string} hexId card id as a hex string
   * @return {Generic34bitCard}
   */
  parse(hexId) {
    const binary = parseInt(hexId, 16).toString(2).padStart(34, '0');
    const facilityCodeBinary = binary.substring(1, 17);
    const cardNumberBinary = binary.substring(17, 33);
    const facilityCode = parseInt(facilityCodeBinary, 2);
    const cardNumber = parseInt(cardNumberBinary, 2);
    return {facilityCode, cardNumber};
  }
};

/**
 * @type {CardFormat}
 */
const Generic34bitOddCardFormat = {
  brivoFormatId: 107,
  kahuTypeId: '34bitOdd',
  /**
   * Returns the hex representation of the encoded card ID using generic 34-bit encoding (odd parity).
   *
   * @param {number} cardNumber 0-65535
   * @param {number} facilityCode 0-65535
   * @return {string}
   */
  encode(cardNumber, facilityCode) {
    return Generic34bitCardFormat.encode(cardNumber, facilityCode, false);
  },
  /**
   * Parse a hex card id using generic 34-bit encoding (odd parity), returning the facility code and card number.
   *
   * @param {string} hexId card id as a hex string
   * @return {Generic34bitCard}
   */
  parse(hexId) {
    return Generic34bitCardFormat.parse(hexId);
  }
};

/**
 * @type {CardFormat}
 */
const Generic34bitEvenCardFormat = {
  brivoFormatId: 105,
  kahuTypeId: '34bitEven',
  /**
   * Returns the hex representation of the encoded card ID using generic 34-bit encoding (even parity).
   *
   * @param {number} cardNumber 0-65535
   * @param {number} facilityCode 0-65535
   * @return {string}
   */
  encode(cardNumber, facilityCode) {
    return Generic34bitCardFormat.encode(cardNumber, facilityCode, true);
  },
  /**
   * Parse a hex card id using generic 34-bit encoding (even parity), returning the facility code and card number.
   *
   * @param {string} hexId card id as a hex string
   * @return {Generic34bitCard}
   */
  parse(hexId) {
    return Generic34bitCardFormat.parse(hexId);
  }
};

/**
 * HID 37-bit w/Facility Code
 *
 * @type {CardFormat}
 */
const Hid37BitFcCardFormat = {
  brivoFormatId: 103,
  kahuTypeId: 'hid37Fc',
  /**
   * Parse a hex card ID using HID 37-bit w/Facility Code encoding (H10304).
   * 16-bit facility code starting at bit 1
   * 19-bit card number starting at bit 17
   *
   * <pre>
   * PFFFFFFFFFFFFFFFFCCCCCCCCCCCCCCCCCCCP
   * EXXXXXXXXXXXXXXXXXX..................
   * ..................XXXXXXXXXXXXXXXXXXO
   * P = Parity
   * O = Odd Parity
   * E = Even Parity
   * </pre>
   *
   * @see https://www.ooaccess.com/kb/37-bit-fc/
   * @param {number} cardNumber 0-524287
   * @param {number} facilityCode 0-65535
   * @return {string}
   */
  encode(cardNumber, facilityCode) {
    if (typeof cardNumber !== 'number' || cardNumber < 0 || cardNumber > 524287) {
      throw new Error(`invalid card number, expected 0 < cardNumber < 524287, got '${cardNumber}'`);
    }
    if (typeof facilityCode !== 'number' || facilityCode < 0 || facilityCode > 65535) {
      throw new Error(`invalid facility code, expected 0 < facilityCode < 65535, got '${facilityCode}'`);
    }
    const cardNumberBinary = cardNumber.toString(2).padStart(19, '0');
    const facilityCodeBinary = facilityCode.toString(2).padStart(16, '0');
    const first18 = facilityCodeBinary + cardNumberBinary.substring(0, 2);
    const last18 = cardNumberBinary.substring(1);
    const leadingParity = parityBit(first18);
    const trailingParity = parityBit(last18, false);

    const cardId = leadingParity + facilityCodeBinary + cardNumberBinary + trailingParity;
    return parseInt(cardId, 2).toString(16).toUpperCase();
  },
  /**
   * Parse a hex card ID using HID 37-bit w/Facility Code encoding (H10304).
   * 16-bit facility code starting at bit 1
   * 19-bit card number starting at bit 17
   *
   * @see https://www.ooaccess.com/kb/37-bit-fc/
   * @param {string} hexId card id as a hex string
   * @return {Hid37BitFcCard}
   */
  parse(hexId) {
    const binary = parseInt(hexId, 16).toString(2).padStart(37, '0');
    const facilityCodeBinary = binary.substring(1, 17);
    const cardNumberBinary = binary.substring(17, 36);
    const facilityCode = parseInt(facilityCodeBinary, 2);
    const cardNumber = parseInt(cardNumberBinary, 2);
    return {facilityCode, cardNumber};
  }
};

/**
 * @type {CardFormat}
 */
const HidCorporate1000CardFormat = {
  brivoFormatId: 102,
  kahuTypeId: 'hidCorp1000',
  /**
   * Returns the hex representation of the encoded card ID using HID Corporate 100 encoding
   *
   * From the link below:
   *
   * This format uses a 12 bit facility code (bits 3-14) and a 20 bit card code (bits 15-34). Bit 1 is an odd parity bit
   * that covers all 35 bits. Bit 2 is an even parity covering bits
   * 3,4,6,7,9,10,12,13,15,16,18,19,21,22,24,25,27,28,30,31,33,34. Bit 35 is odd parity, covering bits
   * 2,3,5,6,8,9,11,12,14,15,17,18,20,21,23,24,26,27,29,30,32,33. When calculating the parity bits, you must calculate
   * bit 2, bit 35, and finally bit 1.
   *
   * @see http://www.pagemac.com/projects/rfid/hid_data_formats
   *
   * @param {number} cardNumber 0-1048576
   * @param {number} facilityCode 0-4095
   * @return {string}
   */
  encode(cardNumber, facilityCode) {
    if (typeof cardNumber !== 'number' || cardNumber < 0 || cardNumber > 1048576) {
      throw new Error(`invalid card number, expected 0 < cardNumber < 1048576, got '${cardNumber}'`);
    }
    if (typeof facilityCode !== 'number' || facilityCode < 0 || facilityCode > 4095) {
      throw new Error(`invalid facility code, expected 0 < facilityCode < 4095, got '${facilityCode}'`);
    }
    const cardNumberBinary = cardNumber.toString(2).padStart(20, '0');
    const facilityCodeBinary = facilityCode.toString(2).padStart(12, '0');

    const dataBits = facilityCodeBinary + cardNumberBinary;

    // parity bits
    const bit2DataIndexes = [3, 4, 6, 7, 9, 10, 12, 13, 15, 16, 18, 19, 21, 22, 24, 25, 27, 28, 30, 31, 33, 34];
    const bit35DataIndexes = [2, 3, 5, 6, 8, 9, 11, 12, 14, 15, 17, 18, 20, 21, 23, 24, 26, 27, 29, 30, 32, 33];
    const bit2Data = substringByCharIndexes(dataBits, bit2DataIndexes.map(i => i - 3));
    const bit2 = parityBit(bit2Data);
    const bit35Data = substringByCharIndexes(bit2 + dataBits, bit35DataIndexes.map(i => i - 2));
    const bit35 = parityBit(bit35Data, false);
    const bit1 = parityBit(bit2 + dataBits + bit35, false);

    const cardId = bit1 + bit2 + dataBits + bit35;
    return parseInt(cardId, 2).toString(16).toUpperCase();
  },
  /**
   * Parse a hex card id using HID Corporate 1000 encoding, returning the facility code and card number.
   *
   * From the link below:
   *
   * This format uses a 12 bit facility code (bits 3-14) and a 20 bit card code (bits 15-34). Bit 1 is an odd parity bit
   * that covers all 35 bits. Bit 2 is an even parity covering bits
   * 3,4,6,7,9,10,12,13,15,16,18,19,21,22,24,25,27,28,30,31,33,34. Bit 35 is odd parity, covering bits
   * 2,3,5,6,8,9,11,12,14,15,17,18,20,21,23,24,26,27,29,30,32,33. When calculating the parity bits, you must calculate
   * bit 2, bit 35, and finally bit 1.
   *
   * @see http://www.pagemac.com/projects/rfid/hid_data_formats
   * @param {string} hexId card id as a hex string
   * @return {HidCorporate1000Card}
   */
  parse(hexId) {
    const binary = parseInt(hexId, 16).toString(2).padStart(35, '0');
    const facilityCodeBinary = binary.substring(3, 14);
    const cardNumberBinary = binary.substring(15, 34);
    const facilityCode = parseInt(facilityCodeBinary, 2);
    const cardNumber = parseInt(cardNumberBinary, 2);
    return {facilityCode, cardNumber};
  }
};

/**
 * A generic card format - any hex string - which uses the Brivo "unknown format" type.
 *
 * @type {CardFormat}
 */
const GenericCardFormat = {
  brivoFormatId: 110,
  kahuTypeId: 'generic',
  /**
   * Returns the card number - no processing is done
   *
   * @param {string|number} cardNumber any
   * @param {number} facilityCode 0
   * @return {string}
   */
  encode(cardNumber, facilityCode) {
    return cardNumber;
  },
  /**
   * @param {string} hexId
   * @return {GenericCard}
   */
  parse(hexId) {
    return {
      cardNumber: hexId,
      facilityCode: 0
    };
  }
};

/** @type {CardFormat[]} */
const Formats = [
  GenericCardFormat,
  Generic34bitEvenCardFormat,
  Generic34bitOddCardFormat,
  Hid26BitCardFormat,
  Hid37BitFcCardFormat,
  HidCorporate1000CardFormat
];

/** @type {Object.<kahu.firestore.CardLookupType,number>} */
const CredentialFormatByCardIdType = Formats.reduce((byKahuTypeId, format) => {
  byKahuTypeId[format.kahuTypeId] = format.brivoFormatId;
  return byKahuTypeId;
}, {});

/** @type {Object.<kahu.firestore.CardLookupType,EncodeCard>} */
const EncoderByCardIdType = Formats.reduce((byKahuTypeId, format) => {
  byKahuTypeId[format.kahuTypeId] = format.encode;
  return byKahuTypeId;
}, {});

/** @type {Object.<kahu.firestore.CardLookupType,ParseCard>} */
const ParserByCardIdType = Formats.reduce((byKahuTypeId, format) => {
  byKahuTypeId[format.kahuTypeId] = format.parse;
  return byKahuTypeId;
}, {});

/** @type {kahu.firestore.CardLookupType[]} */
const SupportedCardIdTypes = Formats.map(f => f.kahuTypeId).sort();

/**
 * See: https://en.wikipedia.org/wiki/Parity_bit
 *
 * @param {string} binaryStr
 * @param {boolean} [evenParity=true]
 * @return {string}
 */
function parityBit(binaryStr, evenParity = true) {
  const ones = binaryStr.split('').filter(bit => bit === '1').length;
  const evenCount = ones % 2 === 0;
  if (evenParity) {
    return evenCount ? '0' : '1';
  } else {
    return evenCount ? '1' : '0';
  }
}

/**
 * Build a string made up of the characters in str at the indexes given
 *
 * @param {string} str
 * @param {number[]} indexes
 * @return {string}
 */
function substringByCharIndexes(str, indexes) {
  let builder = '';
  for (const index of indexes) {
    builder += str.charAt(index);
  }
  return builder;
}

module.exports = {
  CredentialFormatByCardIdType,
  EncoderByCardIdType,
  GenericCardFormat,
  Generic34bitEvenCardFormat,
  Generic34bitOddCardFormat,
  Hid26BitCardFormat,
  Hid37BitFcCardFormat,
  HidCorporate1000CardFormat,
  ParserByCardIdType,
  SupportedCardIdTypes,
  parityBit,
  substringByCharIndexes
};
