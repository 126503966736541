import desks from '@/views/dashboard/statistics/desks';
import rooms from '@/views/dashboard/statistics/rooms';
import {cloneDate, dayOfWeek, fromYearMonthDayUTC, nextDay, toISODate} from '@/util/dates';
import {Logger} from '@vanti/vue-logger';
import {datesInRange} from '@/views/dashboard/statistics/stats-util';
import {formatInTimeZone, zonedTimeToUtc} from 'date-fns-tz';
import DeferUtil from '@/store/defer-util';
import {hoursMinutes} from '@/util/times';
import Vue from 'vue';

const log = Logger.get('dashboard/statistics');

export const TimeScales = {
  Hourly: 0,
  Daily: 1
};

const defaultOptions = () => {
  return {
    colors: {},
    byFloor: false,
    excludeWeekends: true,
    movingAverage: true,
    onlyWorkingHours: true,
    timeScale: TimeScales.Hourly
  };
};

/**
 * @typedef {Object} DateRange
 * @property {Date} start
 * @property {Date} end
 */

/**
 * This store deals with the date selection logic for the statistics page, and handles dispatching
 * actions to the /desks and /rooms sub-modules.
 */
export default {
  namespaced: true,
  state: {
    options: defaultOptions(),
    /**
     * YYYY-MM-DD start and end dates (matches Vuetify date-picker range format).
     * Interpreted in the active site's timezone.
     *
     * @type {string[]}
     */
    selectedDates: []
  },
  getters: {
    loaded(state, getters, rootState, rootGetters) {
      return rootGetters['views/dashboard/counts/site/loaded'] &&
          getters['desks/loaded'] && getters['desks/heatmap/loaded'] &&
          getters['rooms/loaded'] && getters['rooms/heatmap/loaded'];
    },
    startEndYmd(state) {
      const selected = state.selectedDates;
      if (selected.length !== 2) {
        return null;
      }
      const [first, last] = selected;
      let start = first;
      let end = last;
      if (fromYearMonthDayUTC(first) > fromYearMonthDayUTC(last)) {
        // swap dates round if end was selected first
        start = last;
        end = first;
      }
      return {start, end};
    },
    /**
     * Start and end dates for the selectedDates.
     *
     * @type {DateRange|null}
     */
    dateRange(state, getters, rootState, rootGetters) {
      const ymd = getters.startEndYmd;
      if (!ymd) {
        return null;
      }
      const siteTimeZone = rootGetters['sites/active/timeZone'];
      const start = zonedTimeToUtc(`${ymd.start} 00:00:00.000`, siteTimeZone);
      const end = zonedTimeToUtc(`${ymd.end} 23:59:59.999`, siteTimeZone);
      return /** @type {DateRange} */ {start, end};
    },
    /**
     * Dates for each day in the dateRange, at midnight UTC. Used for fetching count documents.
     *
     * @type {Date[]}
     */
    datesInRange(state, getters) {
      const dateRange = getters.dateRange;
      if (!dateRange) {
        return [];
      }
      return datesInRange(dateRange.start, dateRange.end);
    },
    /**
     * Dates for each day in the dateRange, with weekends excluded if excludeWeekends is set,
     * at midnight local (site) time.
     *
     * @type {Date[]}
     */
    siteDays(state, getters, rootState, rootGetters) {
      const dateRange = getters.dateRange;
      if (!dateRange) {
        return [];
      }
      const excludeWeekends = state.options.excludeWeekends;
      const siteTimeZone = rootGetters['sites/active/timeZone'];
      const localeWeekend = rootGetters['sites/active/localeWeekend'];
      let time = cloneDate(dateRange.start);
      const days = [];
      const MAX = 365;
      for (let i = 0; i < MAX; i++) {
        if (!excludeWeekends || !localeWeekend.includes(dayOfWeek(time, siteTimeZone))) {
          days.push(cloneDate(time));
        }
        time = nextDay(time, siteTimeZone);
        if (time > dateRange.end) {
          break;
        }
      }
      return days;
    },
    numDaysInRange(state, getters) {
      return getters.siteDays.length;
    },
    weekLength(state) {
      return state.options.excludeWeekends ? 5 : 7;
    },
    /**
     * The number of 5 (if excludeWeekends is set) or 7-day periods in the selected time frame.
     *
     * @type {number}
     */
    numWeeksInRange(state, getters) {
      const days = getters.numDaysInRange;
      return Math.ceil(days / getters.weekLength);
    },
    /**
     * The site timezone midnight hour in UTC.
     *
     * @type {number}
     */
    midnightHour(state, getters) {
      const dateRange = getters.dateRange;
      if (!dateRange) {
        return 0;
      }
      return dateRange.start.getUTCHours();
    },
    /**
     * Returns datesInRange formatted as YYYY-MM-DD strings, in UTC.
     *
     * @type {string[]}
     */
    ymdDates(state, getters) {
      return getters.datesInRange.map(toISODate);
    },
    /**
     * HH:mm labels for each hour within working hours
     *
     * @type {string[]}
     */
    hourLabels(state, getters) {
      const labels = [];
      for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        labels.push(`${hour}:00`);
      }
      if (getters.statsWorkingDayStart && getters.statsWorkingDayEnd) {
        return labels.slice(getters.statsWorkingDayStart, getters.statsWorkingDayEnd);
      }
      if (getters.workingHoursStart && getters.workingHoursEnd) {
        return labels.slice(getters.workingHoursStart, getters.workingHoursEnd);
      }
      return labels;
    },
    /**
     * YYYY-MM-DD labels for each day in the selected date range; in the site timezone.
     *
     * @type {string[]}
     */
    dayLabels(state, getters, rootState, rootGetters) {
      const siteTimeZone = rootGetters['sites/active/timeZone'];
      return getters.siteDays.map(d => formatInTimeZone(d, siteTimeZone, 'yyyy-MM-dd'));
    },
    weeklyLabels(state, getters) {
      const labels = [];
      for (let i = 0; i < getters.numWeeksInRange; i++) {
        const dayLabel = getters.dayLabels[i * getters.weekLength] || '';
        labels.push(dayLabel);
      }
      return labels;
    },
    workingHoursStart(state, getters, rootState, rootGetters) {
      if (!state.options.onlyWorkingHours) {
        return 0;
      }
      const hm = hoursMinutes(rootGetters['sites/active/workingHoursStartTime']);
      const [hr] = hm.split(':');
      return parseInt(hr);
    },
    workingHoursEnd(state, getters, rootState, rootGetters) {
      if (!state.options.onlyWorkingHours) {
        return 24;
      }
      const hm = hoursMinutes(rootGetters['sites/active/workingHoursEndTime']);
      const [hr] = hm.split(':');
      return parseInt(hr) + 1;
    },
    statsWorkingDayStart(state, getters, rootState, rootGetters) {
      if (!state.options.onlyWorkingHours) {
        return 0;
      }
      const hm = hoursMinutes(rootGetters['sites/active/statsWorkingDayStart']);
      const [hr] = hm.split(':');
      return parseInt(hr);
    },
    statsWorkingDayEnd(state, getters, rootState, rootGetters) {
      if (!state.options.onlyWorkingHours) {
        return 24;
      }
      const hm = hoursMinutes(rootGetters['sites/active/statsWorkingDayEnd']);
      const [hr] = hm.split(':');
      return parseInt(hr) + 1;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setColor(state, {name, color}) {
      Vue.set(state.options.colors, name, color);
    },
    setOption(state, {name, value}) {
      Vue.set(state.options, name, value);
    },
    setSelectedDates(state, dates) {
      state.selectedDates = dates;
    },
    resetOptions(state) {
      state.options = defaultOptions();
    }
  },
  actions: {
    bind({commit, dispatch, getters}) {
      dispatch('views/dashboard/counts/site/bind', null, {root: true})
          .catch(e => log.warn('failed to bind site counts', e));

      const defer = {};
      defer.datesChange = this.watch(
          () => getters.datesInRange,
          datesInRange => {
            dispatch('desks/watchCountsForDates', datesInRange)
                .catch(e => log.warn('failed to get counts for desks', e));
            dispatch('rooms/watchCountsForDates', datesInRange)
                .catch(e => log.warn('failed to get counts for rooms', e));
          }
      );
      commit('defer', defer);
    },
    bindHeatmap({commit, dispatch, getters}) {
      const defer = {};
      defer.heatMapDatesChange = this.watch(
          () => getters.datesInRange,
          datesInRange => {
            dispatch('desks/heatmap/watchCountsForDates', datesInRange)
                .catch(e => log.warn('failed to get counts for desks heatmap', e));
            dispatch('rooms/heatmap/watchCountsForDates', datesInRange)
                .catch(e => log.warn('failed to get counts for rooms heatmap', e));
          },
          {immediate: true}
      );
      commit('defer', defer);
    },
    unbindHeatmap({commit, dispatch}) {
      dispatch('desks/heatmap/unbind')
          .catch(e => log.warn('failed to unbind desk counts', e));
      dispatch('rooms/heatmap/unbind')
          .catch(e => log.warn('failed to unbind room counts', e));
      commit('reset', ['heatMapDatesChange']);
    },
    unbind({dispatch, commit}) {
      commit('reset');
      commit('resetOptions');
      dispatch('views/dashboard/counts/site/unbind', null, {root: true})
          .catch(e => log.warn('failed to unbind site counts', e));
      // clear all defer tasks
      dispatch('desks/unbind')
          .catch(e => log.warn('failed to unbind desk counts', e));
      dispatch('rooms/unbind')
          .catch(e => log.warn('failed to unbind room counts', e));
      dispatch('desks/heatmap/unbind')
          .catch(e => log.warn('failed to unbind desk counts', e));
      dispatch('rooms/heatmap/unbind')
          .catch(e => log.warn('failed to unbind room counts', e));
    }
  },
  modules: {
    desks,
    rooms
  }
};
