/**
 * Compare a and b by the value of a[path] and b[path]
 * path should be . separated for each key depth
 * e.g. `my.nested.value` would compare
 * `a[my][nested][value]` with `b[my][nested][value]`
 *
 * @param {*} a
 * @param {*} b
 * @param {string} path
 * @return {number}
 */
export function compareByPath(a, b, path) {
  const keys = path.split('.');
  let c1 = a;
  let c2 = b;
  for (const k of keys) {
    c1 = c1[k];
    c2 = c2[k];
  }
  return compare(c1, c2);
}

/**
 * @param {*} a
 * @param {*} b
 * @return {number}
 */
export function compare(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

/**
 * Compare by title then by id, ascending.
 *
 * @param {*} a
 * @param {*} b
 * @return {number}
 */
export function byTitle(a, b) {
  return byProps(['title', 'id'], a, b);
}

/**
 * Compares two object properties, ascending.
 *
 * @param {string} prop
 * @param {*} a
 * @param {*} b
 * @return {{v: number, notFound: boolean}}
 */
function byProp(prop, a, b) {
  if (a === b) return {v: 0, notFound: false};
  if (!a) return {v: 1, notFound: false};
  if (!b) return {v: -1, notFound: false};
  const va = a[prop];
  const vb = b[prop];
  if (va && vb) {
    if (va > vb) return {v: 1, notFound: false};
    if (vb > va) return {v: -1, notFound: false};
    return {v: 0, notFound: false};
  }

  if (!va && !vb) return {v: 0, notFound: true};
  if (!va) return {v: 1, notFound: true};
  if (!vb) return {v: -1, notFound: true};
}

/**
 * Compares by a sequence of properties, ascending.
 *
 * @param {string[]} props
 * @param {*} a
 * @param {*} b
 * @return {number}
 */
export function byProps(props, a, b) {
  let res;
  for (const prop of props) {
    res = byProp(prop, a, b);
    if (!res.notFound) return res.v;
  }
  return res.v;
}
