/**
 * @param {string} prop
 * @return {(function(*, *): (number))|*}
 */
export function sortByProp(prop) {
  return (a, b) => {
    if (prop in a && prop in b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      } else {
        return 0;
      }
    }

    if (prop in a) {
      return 1;
    }
    if (prop in b) {
      return -1;
    }
    return 0;
  };
};
