var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('view-header',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('person-info-position',{attrs:{"person":_vm.person,"show-home-site":""}})]},proxy:true},{key:"nextBooking",fn:function(){return [_c('person-info-next-booking',{attrs:{"person":_vm.person}})]},proxy:true},{key:"tags",fn:function(){return [_c('person-info-tags',{staticStyle:{"margin-left":"-4px"},attrs:{"person":_vm.person}})]},proxy:true},{key:"actions",fn:function(){return [_c('person-user-check',{attrs:{"person":_vm.person}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.activeSiteDoc,"append":"","icon":"","to":"edit"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Edit Person")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.activeSiteDoc,"append":"","icon":""},on:{"click":_vm.setPersonToInactive}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.isActive ? 'mdi-minus-circle' : 'mdi-check-circle'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isActive ? 'Set inactive' : 'Re-activate'))])]),(_vm.isPeopleAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"append":"","icon":"","loading":_vm.isLoading},on:{"click":_vm.sendResetPasswordEmail}},on),[_c('v-icon',[_vm._v("mdi-email-alert")])],1)]}}],null,false,3836953443)},[_c('span',[_vm._v("Send password reset link")])]):_vm._e(),(_vm.isPeopleAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.activeSiteDoc,"append":"","icon":""},on:{"click":_vm.deleteThisPerson}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,1709203553)},[_c('span',[_vm._v("Delete Person")])]):_vm._e()]},proxy:true}])})],1)],1),_c('v-divider',{staticClass:"divider-dark mt-4"}),_c('v-row',{staticClass:"px-2"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"title font-weight-regular",attrs:{"cols":"12"}},[_vm._v(" Contact Information ")])],1),_c('person-info-contact',{attrs:{"person":_vm.person}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"title font-weight-regular",attrs:{"cols":"12"}},[_vm._v(" Additional Information ")])],1),(_vm.person && _vm.person.costCentre)?_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_vm._v("Cost Centre:")]),_c('v-col',{attrs:{"cols":"8","md":"9","lg":"10"}},[_vm._v(_vm._s(_vm.person.costCentre))])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Delegates:")])]}}])},[_c('span',[_vm._v("Other people with delegated access on behalf of this person.")])])],1),_c('v-col',{attrs:{"cols":"8","md":"9","lg":"10"}},[_c('person-delegates-summary',{attrs:{"delegates":_vm.person && _vm.person.delegates,"person":_vm.person}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Delegators:")])]}}])},[_c('span',[_vm._v("Other people this person has access on behalf of.")])])],1),_c('v-col',{attrs:{"cols":"8","md":"9","lg":"10"}},[_c('person-delegates-summary',{attrs:{"delegates":_vm.person && _vm.person.delegators,"no-delegates-message":"(no delegators)"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }