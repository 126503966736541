let env = () => {
  const has = {
    pointEvents: typeof window.PointerEvent === 'function',
    touchEvents: typeof window.TouchEvent === 'function'
  };
  env = () => has;
  return has;
};

const tap = {
  install(Vue) {
    Vue.directive('tap', tap);
  },
  bind(el, binding) {
    const has = env();
    if (has.pointEvents || !has.touchEvents) {
      // just use click as browser support is fine at this level
      const l = e => binding.value(e);
      el.$$tap = {
        unbind() {
          el.removeEventListener('click', l);
        }
      };
      el.addEventListener('click', l);
    } else {
      let startPosition;
      let moved = false;
      const start = e => {
        startPosition = {x: e.touches[0].clientX, y: e.touches[0].clientY};
        moved = false;
      };
      const move = e => {
        if (!moved) {
          const position = {x: e.touches[0].clientX, y: e.touches[0].clientY};
          moved = Math.hypot(position.x - startPosition.x, position.y - startPosition.y) > 5;
        }
      };
      const end = e => {
        if (!moved) {
          binding.value(e);
        }
      };

      el.addEventListener('touchstart', start, {passive: true});
      el.addEventListener('touchmove', move, {passive: true});
      el.addEventListener('touchend', end, {passive: true});
      el.$$tap = {
        unbind() {
          el.removeEventListener('touchstart', start);
          el.removeEventListener('touchmove', move);
          el.removeEventListener('touchend', end);
        }
      };
    }
  },
  unbind(el) {
    if (el.$$tap) el.$$tap.unbind();
    delete el.$$tap;
  }
};
export default tap;
