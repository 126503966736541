<template>
  <v-text-field
      v-model="value"
      append-icon="mdi-check"
      @click:append="submit(value)"
      @change="submit"
      :label="label"
      outlined
      dense
      single-line
      hide-details
      class="px-2 pb-2"
      ref="cardNumberInput"/>
</template>

<script>
import {CardIdTypeRef} from '@/util/card-type';
import {EncoderByCardIdType} from '@/components/cards/card-encoding-util';
import {mapGetters} from 'vuex';

export default {
  name: 'CardNumberField',
  props: {
    label: {
      type: String,
      default: 'Card number'
    }
  },
  data() {
    return {
      value: ''
    };
  },
  computed: {
    ...mapGetters('sites/active', [
      'configCardIdType'
    ])
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    submit(uid) {
      // support uids of the format facilityCode:cardNumber (in decimal)
      // encode them into their HEX form
      let id = uid;
      if (uid.includes(':') && this.configCardIdType !== CardIdTypeRef) {
        try {
          const parts = uid.split(':');
          const facilityCode = parseInt(parts[0]);
          const cardNumber = parseInt(parts[1]);
          const encode = EncoderByCardIdType[this.configCardIdType];
          if (encode) {
            id = encode(cardNumber, facilityCode);
          }
        } catch (e) {
          this.$logger.warn(`card encoding failed, failing back to ${id}`, e);
        }
      }
      this.$emit('input', id);
      this.value = '';
    },
    focusInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.cardNumberInput.focus();
        }, 300);
      });
    }
  }
};
</script>

<style scoped>

</style>
