<template>
  <v-col cols="12">
    <v-row dense>
      <v-col cols="12">
        <form-row :label="star('email', 'Email')">
          <v-text-field
              v-bind="formInputProps('email', 'Email')"
              @input="value => email = value"
              :value="formattedEmail">
            <template #message="{message}">
              <span v-if="message === 'Email is already in use'">
                {{ message }}, by
                <router-link :to="{name: 'person', params: {id: personWithEmail.id}}">
                  {{ personWithEmail.title }}
                </router-link>
              </span>
              <span v-else>{{ message }}</span>
            </template>
          </v-text-field>
        </form-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <form-row label="Phone">
          <v-row :key="i" v-for="(p,i) in phones" dense>
            <v-col cols="8" class="pt-0">
              <v-text-field
                  v-bind="formInputProps('phones.' + i + '.number', 'Phone number')"
                  label="Number"
                  v-model.trim="p.number"
                  @keydown="addPhoneReactive(i)"
                  @focusout="removePhoneReactive(p.number, i)"/>
            </v-col>
            <v-col cols="4" class="pt-0">
              <v-select
                  v-bind="formInputProps('phones.' + i + '.type')"
                  :items="phoneTypes"
                  label="Label"
                  v-model="p.type">
                <template #append-outer>
                  <v-btn @click="removePhoneNumber(i)" icon small :disabled="p.number === ''">
                    <v-icon color="grey lighten-4" v-if="p.number !== ''">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </form-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import form, {formInputProps} from '@/components/form/form';
import FormRow from '@/components/form/FormRow';

export default {
  name: 'PersonEditContact',
  components: {FormRow},
  mixins: [
    formInputProps,
    form(['email', 'phones'])
  ],
  props: {
    personWithEmail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      phoneTypes: ['Work', 'Home', 'Mobile', 'Ext', 'Work Mobile', 'Home Mobile']
    };
  },
  computed: {
    formattedEmail() {
      let formattedEmail = null;
      if (!this.email) formattedEmail = null;
      if (this.email instanceof Object) {
        formattedEmail = null;
      } else if (typeof this.email === 'string') {
        formattedEmail = this.email.trim();
      }

      return formattedEmail;
    }
  },
  methods: {
    addPhoneNumber() {
      if (!Array.isArray(this.phones)) {
        this.phones = [];
      }
      this.phones.push({type: '', number: ''});
    },
    removePhoneNumber(index) {
      this.phones.splice(index, 1);
    },
    addPhoneReactive(index) {
      if (!this.phones) {
        return;
      }
      // not the last number, don't add another
      if (this.phones.length !== (index + 1)) {
        return;
      }
      this.addPhoneNumber();
    },
    removePhoneReactive(number, index) {
      // the last number, don't do anything so there's always one blank phone number to use
      if (this.phones.length - 1 === index) {
        return;
      }
      if (number === '') {
        this.removePhoneNumber(index);
      }
    }
  }
};
</script>

<style scoped>

</style>
