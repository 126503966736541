import {editedValue, editValue, resetEditValue} from './editable-config-util';
import nested from 'nested-property';

/**
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData & kahu.firestore.Named} config
 *
 * @return {EditableConfig}
 */
export function toEditableConfig(context, {config, id}) {
  const editableConfig = {};
  const actualConfig = context.rootGetters['views/admin/config/actualConfig'];
  const globalConfig = context.rootState.ns.doc.config || {};

  const fields = Object.keys(config);
  fields.forEach(function(field) {
    Object.defineProperty(editableConfig, field, {
      enumerable: true,
      get() {
        // if the site config has the property, show that value
        if (actualConfig && actualConfig[id] && actualConfig[id].hasOwnProperty(field)) {
          return editedValue(context, actualConfig, field, id);
        } else if (globalConfig && globalConfig.hasOwnProperty(field)) {
          // else if the ns config has the property the SiteConfigOptionsList component will show that value as a hint
          return editedValue(context, globalConfig, field, id);
        } else {
          // else show the edited value
          return editedValue(context, config, field, id);
        }
      },
      set(v) {
        const original = nested.get(actualConfig[id], field);

        if (original === v || (original === undefined && !v)) {
          resetEditValue(context, config, field, id);
        } else {
          editValue(context, config, field, v, id);
        }
      }
    });
  });

  return editableConfig;
}


