<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          :disabled="!noRecords"
          :loading="loading"
          @click="exportSiteMembers">
        <v-icon>
          mdi-export-variant
        </v-icon>
      </v-btn>
    </template>
    <span>Export this site's members to a CSV file</span>
  </v-tooltip>
</template>

<script>
import {sortByProp} from '@/util/sort';
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'ExportSiteMembersButton',
  data() {
    return {
      loading: false,
      rows: null
    };
  },
  computed: {
    ...mapGetters('sites', ['activeSiteDoc', 'activeSiteId']),
    ...mapGetters('ns', ['nsRef']),
    ...mapGetters('sites/active', ['config']),
    ...mapGetters('views/people', ['records']),
    ...mapState('ns', ['doc']),
    ...mapGetters('sites/active', ['departmentInputsEnabled']),

    ns() {
      return this.doc.id;
    },
    noRecords() {
      return !!this.records.length;
    }
  },
  methods: {
    async getCard(personRef, siteId) {
      const query = await this.nsRef.collection(`cards`)
          .where('owner.ref', '==', personRef)
          .where('sites.' + siteId, '==', true)
          .where('role', '==', 'permanent')
          .limit(1)
          .get();
      return !query.empty && query.docs[0];
    },
    siteCardId(card, siteConfig) {
      const cardIdType = card.get('cardIdType');
      const uids = card.get('uids');
      if (cardIdType && cardIdType !== 'ref' && uids && uids[cardIdType]) {
        return uids[cardIdType];
      }
      const siteCardIdType = siteConfig.get('cardIdType', 'ref');
      if (siteCardIdType && siteCardIdType !== 'ref' && uids && uids[siteCardIdType]) {
        return uids[siteCardIdType];
      }
      return card.id;
    },
    async prepareDataForExport() {
      const col = this.nsRef.collection('search').doc('people').collection('records')
          .where('site.ref', '==', this.activeSiteDoc.ref);
      const members = await col.get();
      const people = [];
      const fields = ['email', 'title', 'department'];
      const includeCard = true;
      const siteConfig = this.config;

      if (Object.keys(siteConfig).length) {
        const cardPromises = [];
        for (const doc of members.docs) {
          const person = {link: `https://app.kahu.work/people/view/${doc.get('person.ref').id}`};
          for (const field of fields) {
            person[field] = doc.get(`person.${field}`);
          }
          if (includeCard) {
            cardPromises.push(this.getCard(doc.get('person.ref'), this.activeSiteId));
          }
          people.push(person);
        }

        const cards = await Promise.all(cardPromises);
        for (let i = 0; i < cards.length; i++) {
          if (cards[i]) {
            people[i]['card'] = this.siteCardId(cards[i], siteConfig);
          }
        }
        this.rows = people.sort(sortByProp('title')).reverse();
      } else {
        throw new Error('No config found for site');
      }
    },
    async exportSiteMembers() {
      this.loading = true;
      try {
        await this.prepareDataForExport();
      } catch (error) {
        this.$logger.error('No config found for site');
        this.$notify.showError(error);
        this.loading = false;
        return;
      }

      let csvContent = 'Title,Link,Email,Card';
      csvContent += this.departmentInputsEnabled ? ',Division,Business Group,Business Unit\n' : ',Department\n';

      this.rows.forEach((row) => {
        const card = row.card ? `${row.card}` : '';
        csvContent += `${row.title},${row.link},${row.email || ''},${card},`;
        if (this.departmentInputsEnabled) {
          if (row.department && row.department.includes(', ')) {
            const parts = row.department.split(', ');
            csvContent += `${parts[0] || ''},${parts[1] || ''},${parts[2] || ''}\n`;
          } else {
            csvContent += `,,\n`;
          }
        } else {
          csvContent += `"${row.department || ''}"\n`;
        }
      });

      const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
      const fileNameCsv = `exported-members-${this.ns}-${this.activeSiteId}.csv`;
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', fileNameCsv);
      document.body.appendChild(link);
      link.click();

      this.loading = false;
    }
  }
};
</script>
