/**
 * Module that tracks the list of cards shown by the CardsList component.
 */
import {db, decorateSnapshot} from '@/firebase';
import {EmptyCollection} from '@/store/collection/empty-collection';
import {CardsCollection} from '@/views/people/cards/cards-collection';
import Vue from 'vue';
import {CardIdTypeRef} from '@/util/card-type';
import {Logger} from '@vanti/vue-logger';

const log = Logger.get('cards');

/**
 * @typedef {Object} CacheRecord
 * @property {Date} created
 * @property {Date} accessed
 * @property {*} data
 */

const cacheOpts = {
  maxSize: 20,
  maxIdleMs: 5000 // 5 seconds, just enough to avoid double scanning
};

export default {
  namespaced: true,
  state: {
    /** @type {?DocumentCollection} */
    currentCollection: new EmptyCollection(),
    /** @type {Object.<string, CacheRecord>}*/
    cachedCards: {}
  },
  getters: {
    cards(state) {
      return state.currentCollection.documents;
    },
    cachedCardsById(state, getters, rootState, rootGetters) {
      const siteCardIdType = rootGetters['sites/active/configCardIdType'];
      if (siteCardIdType === CardIdTypeRef) {
        return state.cachedCards;
      }
      const cards = {};
      for (const entry of Object.values(state.cachedCards)) {
        const card = entry.data;
        const cardUid = card.uids[siteCardIdType];
        cards[cardUid] = entry;
      }
      return cards;
    },
    loadingInfo(state) {
      const c = state.currentCollection;
      if (c.recordFetchAborted()) {
        return {
          text: `Unfortunately we were unable to fetch ${c.records.length > 0 ? 'more' : 'any'} cards at this time.`,
          type: 'error',
          loading: false
        };
      } else if (c.fetchErrorCount > 1) {
        return {
          text: `We're experiencing some issues fetching ${c.records.length > 0 ? 'more ' : ''}cards` +
              ', please wait while we try and sort out the problem.',
          type: 'warning',
          loading: 'warning'
        };
      } else if (c.recordsAreLoading) {
        return {text: `Loading ${c.records.length > 0 ? 'more ' : ''}cards...`, type: 'info', loading: true};
      } else if (c.records.length === 0) {
        return {
          text: `No cards could be found`,
          type: 'info',
          color: 'info',
          loading: false,
          noRecords: true
        };
      } else if (!c.expectsMoreRecords) {
        return {text: 'No more cards'};
      } else {
        return {};
      }
    }
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {CardsCollection} collection
     */
    setCurrentCollection(state, collection) {
      state.currentCollection = collection;
    },
    cacheCard(state, {card}) {
      const now = new Date();
      if (state.cachedCards.hasOwnProperty(card.id)) {
        state.cachedCards[card.id].data = card;
        state.cachedCards[card.id].accessed = now;
      } else {
        Vue.set(state.cachedCards, card.id, {
          data: card,
          created: now,
          accessed: now
        });
      }
    },
    clear(state) {
      state.cachedCards = {};
      state.currentCollection = new EmptyCollection();
    },
    pruneCardCache(state, now = new Date()) {
      const entries = Object.entries(state.cachedCards)
          .sort((a, b) => {
            if (a[1].accessed > b[1].accessed) {
              return -1;
            }
            if (a[1].accessed < b[1].accessed) {
              return 1;
            }
            return 0;
          });
      // check age before size, in case removing old items makes the cache small again
      for (let i = entries.length - 1; i >= 0; i--) {
        const [id, record] = entries[i];
        const accessed = record.accessed.getTime();
        if (accessed + cacheOpts.maxIdleMs < now.getTime()) {
          // too old
          Vue.delete(state.cachedCards, id);
        } else {
          // sorted array means the first time we don't satisfy the condition results in all other items being valid
          entries.splice(i + 1);
          break;
        }
      }
      // check size constraints
      if (entries.length > cacheOpts.maxSize) {
        entries.splice(cacheOpts.maxSize).forEach(([id]) => {
          Vue.delete(state.cachedCards, id);
        });
      }
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (!authUser) {
          dispatch('unbind');
        }
      }
    },
    async getByUid({state, getters, rootGetters, commit}, {uid}) {
      commit('pruneCardCache');
      let card;
      if (getters.cachedCardsById.hasOwnProperty(uid)) {
        card = getters.cachedCardsById[uid].data;
      } else {
        const fs = await db;
        const cardsCol = fs.collection(rootGetters.nsRef + '/cards');
        const siteCardIdType = rootGetters['sites/active/configCardIdType'];
        const siteId = rootGetters['sites/activeSiteId'];
        if (siteCardIdType === CardIdTypeRef) {
          const doc = await cardsCol.doc(uid).get();
          card = decorateSnapshot(doc);
        } else {
          const query = await cardsCol
              .where(`uids.${siteCardIdType}`, '==', uid)
              .where(`sites.${siteId}`, '==', true)
              .limit(2)
              .get();
          if (query.size >= 1) {
            card = decorateSnapshot(query.docs[0]);
            if (query.size > 1) {
              log.warn(`getByUid found multiple cards for`, {
                siteId,
                siteCardIdType,
                uid,
                knownRefs: query.docs.map(d => d.ref.path)
              });
            }
          }
        }
      }
      if (card) {
        commit('cacheCard', {card});
      }
      return card;
    },
    /**
     * Fetch a card matching the given UID, owned by the given person.
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} options
     * @param {string} options.uid
     * @param {firebase.firestore.DocumentReference} options.ownerRef
     * @return {Promise<DecoratedData<firebase.firestore.DocumentData>>}
     */
    async ownedCardByUid({commit, getters, rootGetters}, {uid, ownerRef}) {
      commit('pruneCardCache');
      let card;
      if (getters.cachedCardsById.hasOwnProperty(uid)) {
        const cachedCard = getters.cachedCardsById[uid].data;
        if (cachedCard.owner && cachedCard.owner.ref && cachedCard.owner.ref.isEqual(ownerRef)) {
          card = getters.cachedCardsById[uid].data;
        }
      } else {
        const fs = await db;
        const cardsCol = fs.collection(rootGetters.nsRef + '/cards');
        const siteCardIdType = rootGetters['sites/active/configCardIdType'];
        if (siteCardIdType === CardIdTypeRef) {
          const doc = await cardsCol.doc(uid).get();
          card = decorateSnapshot(doc);
        } else {
          const query = await cardsCol
              .where(`uids.${siteCardIdType}`, '==', uid)
              .where(`owner.ref`, '==', ownerRef)
              .limit(2)
              .get();
          if (query.size >= 1) {
            card = decorateSnapshot(query.docs[0]);
            if (query.size > 1) {
              log.warn(`ownedCardByUid found multiple cards for`, {
                ownerRef: ownerRef.path,
                siteCardIdType,
                uid,
                knownRefs: query.docs.map(d => d.ref.path)
              });
            }
          }
        }
      }
      if (card) {
        commit('cacheCard', {card});
      }
      return card;
    },
    /**
     * Configure the current collection to be based off of the given query clauses.
     *
     * @param {import('vuex').ActionContext} context
     * @param {QueryClause[]} queryClauses
     */
    async bind({state, rootGetters, commit}, queryClauses) {
      const ns = rootGetters.ns;

      // We could get into a state where we're using more than one array-contains query: sites and keywords
      // to avoid this we special case and filter sites on the client site
      let sitesClause = null;
      let keywordsClause = null;
      queryClauses = queryClauses.filter(c => {
        if (c[0] === 'keywords') {
          keywordsClause = c;
        } else if (c[0] === 'sites') {
          sitesClause = c;
          // filter it out, we'll add it back in if keywords isn't found
          return false;
        }
        return true;
      });
      const localSiteFilter = keywordsClause && sitesClause;
      if (!localSiteFilter && sitesClause) {
        // add the clauses back in, we only keep them out it out if both keywords and sites are present
        queryClauses.push(sitesClause);
      }

      const currentFilter = state.currentCollection.filter || {};
      const newFilter = localSiteFilter ? {site: sitesClause[2]} : {};

      if (currentFilter.site !== newFilter.site || !state.currentCollection.sameAs({ns, queryClauses})) {
        commit('setCurrentCollection', new CardsCollection(ns, queryClauses, newFilter));
      }

      // else the current collection is equivalent to the one we're binding to, don't do anything
    },
    unbind({commit}) {
      commit('clear');
    },
    async load({state}) {
      return state.currentCollection.load();
    },

    async stop({state}) {
      return state.currentCollection.stop();
    }
  }
};
