<template>
  <span>
    <v-chip v-for="(tag, i) in tags" :key="i" :color="tag.c" class="mx-1 text-capitalize">{{ tag.t }}</v-chip>
  </span>
</template>

<script>
import tagMixin from '@/components/tag-mixin';

export default {
  name: 'PersonInfoTags',
  mixins: [tagMixin],
  props: {
    person: {
      type: Object,
      default: null
    },
    exclude: {
      type: [Function, Array],
      default: () => false
    }
  },
  computed: {
    tags() {
      if (!this.person) {
        return [];
      }

      const tags = Object.entries(this.person.tags || {})
          .filter(([, enabled]) => enabled)
          .filter(([tag]) => this.filterTag(tag))
          .map(([tag]) => {
            return {
              c: this.tagColor(tag),
              t: tag
            };
          });

      if (!this.person.active) {
        tags.push({c: this.tagColor('inactive'), t: 'inactive'});
      }

      return tags;
    }
  },
  methods: {
    filterTag(tag) {
      if (typeof this.exclude === 'function') {
        return !this.exclude(tag);
      }

      if (Array.isArray(this.exclude)) {
        return this.exclude.indexOf(tag) === -1;
      }

      return true;
    }
  }
};
</script>

<style scoped>
</style>
