export const absentProperty = {exists: false, masked: false, value: null, realValue: null};
/**
 * Define the default values for different booking data when they are marked private.
 * Defaults are used when the booking.privacy === true or the corresponding privacy property is true.
 *
 * A string value we act like the booking value is that string.
 * A false value will act like the booking doesn't have that value at all.
 *
 * @type {Object<string,(string|boolean)>}
 */
const defaultMasks = {
  title: 'Private Meeting',
  owner: false
};

/**
 * Return a truthy value if the given prop should be private.
 *
 * @param {Object<string,(boolean|string)>|boolean|null} privacy
 * @param {string} prop
 * @return {boolean|string}
 */
export function calcPrivacy(privacy, prop) {
  if (typeof privacy === 'boolean') return privacy;
  if (!privacy) return false;
  // only if privacy.prop=false should we disable privacy for this property.
  // opt-out / whitelist approach.
  if (privacy[prop] === false) return false;
  return privacy[prop] || true;
}

/**
 * Returns a private value based on the given privacy state and prop (used for defaults).
 *
 * @param {Object<string,(boolean|string)>|boolean} privacy
 * @param {*} realValue
 * @param {string} prop
 * @return {{realValue: *, masked: boolean, exists: boolean, value: *}}
 */
export function calcPrivateValue(privacy, realValue, prop) {
  const mask = calcPrivacy(privacy, prop);
  const masked = Boolean(mask);
  if (!masked) return {exists: Boolean(realValue), masked, value: realValue, realValue};
  const privateValue = (typeof mask === 'string') ? mask : defaultMasks[prop];
  if (!privateValue) return absentProperty;
  const value = masked ? privateValue : realValue;
  return {
    exists: true,
    masked,
    value,
    realValue
  };
}
