<template>
  <v-container fluid :fill-height="loading || (!newPerson && !personExists)" class="pt-5 px-4">
    <template v-if="loading">
      <v-row>
        <v-col cols="12">
          <v-row justify="center" align="center">
            <v-progress-circular indeterminate size="48" color="accent"/>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="loaded && !newPerson && !personExists">
      <page-not-found route-name="directory" route-label="the directory" text="person"/>
    </template>
    <template v-else>
      <!------------------------ Main Info  -------------------------------->
      <span class="d-none">ID {{ id }}</span>
      <router-view/>
    </template>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import PageNotFound from '@/components/PageNotFound';

export default {
  name: 'Person',
  components: {PageNotFound},
  props: {
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('appConfig', ['sectionVisitorsEnabled']),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('views/person', ['loaded', 'personExists', 'memberships']),
    loading() {
      return !this.loaded;
    },
    newPerson() {
      return !this.id;
    }
  },
  watch: {
    activeSiteId: {
      handler: 'loadSiteDetails'
    },
    id: {
      immediate: true,
      handler: 'loadPerson'
    }
  },
  beforeDestroy() {
    // we don't unbind here as we might be coming back to this person later
    // this.$store.dispatch('views/person/unbind');
  },
  methods: {
    loadPerson() {
      const id = this.id;
      this.$store.dispatch('views/person/bind', {id})
          .catch(err => {
            this.$logger.error('Failed to fetch person by id ' + id, err);
          });
    },
    loadSiteDetails() {
      const id = this.id;
      this.$store.dispatch('views/person/bindMembership', {id})
          .catch(err => {
            this.$logger.error('Failed to bind persons site details', err);
          });
      this.$store.dispatch('views/person/bindCards', {id})
          .catch(err => {
            this.$logger.error('Failed to bind persons card details', err);
          });
      this.$store.dispatch('views/person/bindBookings', {id})
          .catch(err => {
            this.$logger.error('Failed to bind persons card details', err);
          });
    }
  }
};
</script>

<style scoped>

</style>
