<template>
  <v-card :max-width="maxWidth" :width="maxWidth">
    <v-card-title>
      <slot name="title">{{ stateLoading ? 'Please wait...' : 'Scan a card' }}</slot>
    </v-card-title>

    <div v-if="stateLoading" class="text-center pb-4">
      <v-progress-circular color="secondary" indeterminate width="2"/>
    </div>
    <v-progress-linear color="secondary" indeterminate v-else-if="stateOk"/>
    <card-number-field
        v-else-if="noCardReaderSoftware"
        @input="cardRead"
        label="Enter a card number"
        :key="`card-number-field-1-${Date.now()}`"/>
    <template v-else-if="!stateOk">
      <v-alert color="error" dark dense tile class="mb-0">
        <v-row align="center" dense>
          <v-col class="shrink">
            <v-icon>mdi-alert</v-icon>
          </v-col>
          <v-col class="grow">{{ errorMessage }}</v-col>
          <v-col class="shrink">
            <v-tooltip bottom>
              <template #activator="{on}">
                <v-btn v-on="on" @click="retry" icon :loading="retrying">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Retry</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-alert>
      <template v-if="!hideManualEntry && !noCardReaderSoftware">
        <v-divider/>
        <div class="pa-2"><a @click="manualEntryVisible = true">Type a card number</a></div>
        <v-expand-transition>
          <card-number-field v-if="manualEntryVisible" @input="cardRead" :key="`card-number-field-2-${Date.now()}`"/>
        </v-expand-transition>
        <v-divider/>
      </template>
      <div class="pa-2">
        Need help? Go to <a href="https://support.kahu.work" target="_blank">support.kahu.work</a>
      </div>
    </template>

    <slot :uid="uid">
      <v-card-text v-if="uid && !hideId">
        <span class="cardno">Card No: {{ uid }}</span>
      </v-card-text>
    </slot>
    <v-card-actions v-if="$slots.actions">
      <v-spacer/>
      <slot name="actions"/>
    </v-card-actions>
    <template v-if="showFakeCards">
      <v-divider/>
      <div class="pa-1">
        <v-chip @click="cardRead(card)" v-for="card in fakeCards" :key="card" class="fake-card my-1">{{ card }}</v-chip>
      </div>
    </template>
  </v-card>
</template>

<script>

import {defaultCardStream, StreamCancelled} from '@/store/api/card-stream';
import {mapGetters} from 'vuex';
import CardNumberField from '@/components/cards/CardNumberField';

export default {
  name: 'CardScan',
  components: {CardNumberField},
  props: {
    allowReScan: {
      type: Boolean,
      default: false
    },
    hideId: {
      type: Boolean,
      default: false
    },
    hideManualEntry: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 360
    },
    disabled: Boolean
  },
  data() {
    return {
      retrying: false,
      subscribed: false,
      port: '',
      uid: '',
      fakeCards: [
        '509714F6',
        'F6659B50',
        'F6659B53',
        'F6659B54',
        'F6659B55',
        'F6659B56',
        'dev-7',
        'dev-8',
        'unknown'
      ],
      manualEntryVisible: false
    };
  },
  computed: {
    ...mapGetters('cardReader', [
      'allPorts',
      'stateLoading',
      'stateError',
      'stateOk',
      'stateNoPorts',
      'stateMultiplePorts',
      'stateListComPortsError',
      'noCardReaderSoftware'
    ]),
    errorMessage() {
      if (this.stateOk || this.stateLoading) {
        return '';
      }
      if (this.stateError) {
        return 'No Kahu Card Reader software running.';
      }
      if (this.stateNoPorts || this.stateListComPortsError) {
        return 'No Kahu Card Reader detected.';
      }
      if (this.stateMultiplePorts) {
        return 'Multiple Kahu Card Readers detected';
      }
      return '';
    },
    showFakeCards() {
      return process.env.NODE_ENV === 'development';
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.uid = val;
      }
    },
    disabled: {
      immediate: true,
      handler(v) {
        if (v) {
          this.manualEntryVisible = false;
          this.unsubscribe();
        } else {
          this.subscribe();
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    cardRead(id) {
      this.uid = id;
      if (this.uid !== this.value || this.allowReScan) {
        this.$emit('input', this.uid);
      }
    },
    retry() {
      this.$logger.debug('retry');
      this.subscribed = false;
      this.retrying = true;
      defaultCardStream.bus.$off('card', this.cardRead);
      this.$store.dispatch('cardReader/cancelPullCards')
          // wait a second
          .then(() => new Promise(res => setTimeout(res, 1000)))
          .then(() => this.subscribe())
          .catch(err => this.$logger.error('failed to retry listening for cards ', err))
          .finally(() => this.retrying = false);
    },
    subscribe() {
      if (this.subscribed) {
        return;
      }
      this.subscribed = true;
      this.$logger.debug('subscribe');
      defaultCardStream.bus.$on('card', this.cardRead);
      this.$store.dispatch('cardReader/bind')
          .catch(err => {
            if (err instanceof StreamCancelled) {

            } else {
              this.$logger.error('Failed to bind the reader ', err);
            }
          });
    },
    unsubscribe() {
      if (!this.subscribed) {
        return;
      }
      this.$logger.debug('unsubscribe');
      this.subscribed = false;
      defaultCardStream.bus.$off('card', this.cardRead);
      this.$store.dispatch('cardReader/cancelPullCards')
          .then(() => this.$logger.debug('stopped listening for cards'))
          .catch(err => this.$logger.error('failed to stop listening for cards ', err));
    }
  }
};
</script>

<style scoped>
  .cardno {
    display: block;
  }

  .fake-card:not(:last-child) {
    margin-right: 8px;
  }
</style>
