/**
 * If the given context doesn't have a property with the given name, return true. Otherwise return context[property].
 *
 * Useful for validation as you want to be valid if a) you aren't checking that property, b) the property is valid.
 *
 * @param {*} context
 * @param {string} property
 * @return {boolean|*}
 */
export function valid(context, property) {
  /**
   * here we validate only strings. But we need to skip email validation in case this is instance of Object.
   * Which could happen because in form field we can store firebase Object which is actually function to perform delete
   */
  if (property === 'email' && context.$model instanceof Object) return true;

  return !context.hasOwnProperty(property) || context[property];
}

export const required = (name, context) => valid(context, 'required') || `${name} is required`;
export const maxLength = (name, context) => valid(context, 'maxLength') ||
    `${name} must have ${context.$params.maxLength.max} characters or less`;
export const minLength = (name, context) => valid(context, 'minLength') ||
    `${name} requires ${context.$params.minLength.min} characters or more`;
export const email = (name, context) => valid(context, 'email') ||
    `${name} should be a valid email address`;
export const unique = (name, context) => valid(context, 'unique') ||
    // unique checks can be async, don't show invalid until we know
    context.$pending ||
    `${name} is already in use`;
export const cardUnique = (name, context) => valid(context, 'cardUnique') ||
    // unique checks can be async, don't show invalid until we know
    context.$pending ||
    `That card has already been added`;

export default [
  required, maxLength, minLength, email, unique, cardUnique
];
