<template>
  <v-text-field
      @change="cardScanned"
      dense
      outlined
      label="Card ID"
      required
      :error="error"
      :messages="messages"
      :rules="rules"
      :value="id">
    <template #append>
      <v-menu
          v-model="menuOpen"
          :close-on-content-click="false"
          :min-width="360"
          offset-y
          left
          nudge-right="8"
          origin="center center"
          transition="slide-y">
        <template #activator="{on: onMenu}">
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-btn small icon v-on="{...on, ...onMenu}">
                <v-icon>mdi-credit-card-wireless-outline</v-icon>
              </v-btn>
            </template>
            <span>Scan {{ id === '' ? 'a' : 'another' }} card</span>
          </v-tooltip>
        </template>

        <card-scan allow-re-scan hide-id :disabled="!menuOpen" @input="cardScanned"/>
      </v-menu>
    </template>
    <template #append-outer>
      <v-tooltip top max-width="400">
        <template #activator="{on}">
          <v-btn small icon v-on="on">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <div>To add a card, either:</div>
        <ul>
          <li>Scan a card with a card reader</li>
          <li>Enter the full card ID</li>
          <li v-if="notCardIdTypeRef">
            Enter the facility code & card number in the format: "facilityCode:cardNumber",
            e.g. type "10:123" (without the quotes) for a card with facility code 10 and card number 123.
          </li>
        </ul>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import CardScan from '@/components/cards/CardScan';
import {mapGetters} from 'vuex';
import {CardIdTypeRef} from '@/util/card-type';
import {EncoderByCardIdType} from '@/components/cards/card-encoding-util';

export default {
  name: 'CardScanField',
  components: {CardScan},
  props: {
    error: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    },
    scanOnLoad: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menuOpen: false,
      id: '',
      scan: false
    };
  },
  computed: {
    ...mapGetters('cardReader', [
      'noCardReaderSoftware'
    ]),
    ...mapGetters('sites/active', [
      'configCardIdType'
    ]),
    notCardIdTypeRef() {
      return this.configCardIdType !== CardIdTypeRef;
    }
  },
  watch: {
    scanOnLoad: {
      handler(scanOnLoad) {
        // don't popup the scan dialog if there is no Kahu card software running
        // users can manually enter IDs instead
        if (this.noCardReaderSoftware) {
          return;
        }
        this.$nextTick(() => {
          this.menuOpen = scanOnLoad;
        });
      },
      immediate: true
    },
    value: {
      handler(v) {
        if (v) {
          this.id = v;
        }
      },
      immediate: true
    }
  },
  methods: {
    cardScanned(uid) {
      this.id = uid;
      // support uids of the format facilityCode:cardNumber (in decimal)
      // encode them into their HEX form
      this.$logger.debug('cardScanned', {uid, cardIdType: this.configCardIdType});
      if (uid.includes(':') && this.configCardIdType !== CardIdTypeRef) {
        try {
          const parts = uid.split(':');
          const facilityCode = parseInt(parts[0]);
          const cardNumber = parseInt(parts[1]);
          const encode = EncoderByCardIdType[this.configCardIdType];
          if (encode) {
            this.id = encode(cardNumber, facilityCode);
          }
        } catch (e) {
          this.$logger.warn(`card encoding failed, failing back to ${uid}`, e);
        }
      }
      this.menuOpen = false;
      this.$emit('input', this.id);
    }
  }
};
</script>

<style scoped>

</style>
