<template>
  <div class="dpad">
    <v-btn
        class="t"
        icon
        @click="imageAdjustment.top--"
        @mousedown="start(() => imageAdjustment.top--)"
        @mouseup="stop">
      <v-icon large>mdi-arrow-up</v-icon>
    </v-btn>
    <v-btn
        class="b"
        icon
        @click="imageAdjustment.top++"
        @mousedown="start(() => imageAdjustment.top++)"
        @mouseup="stop">
      <v-icon large>mdi-arrow-down</v-icon>
    </v-btn>
    <v-btn
        class="l"
        icon
        @click="imageAdjustment.left--"
        @mousedown="start(() => imageAdjustment.left--)"
        @mouseup="stop">
      <v-icon large>mdi-arrow-left</v-icon>
    </v-btn>
    <v-btn
        class="r"
        icon
        @click="imageAdjustment.left++"
        @mousedown="start(() => imageAdjustment.left++)"
        @mouseup="stop">
      <v-icon large>mdi-arrow-right</v-icon>
    </v-btn>
    <v-btn
        class="trr"
        icon
        @click="imageAdjustment.scale += 0.1"
        @mousedown="start(() => imageAdjustment.scale += 0.1)"
        @mouseup="stop">
      <v-icon large>mdi-magnify-plus-outline</v-icon>
    </v-btn>
    <v-btn
        class="rr"
        icon
        @click="imageAdjustment.scale -= 0.1"
        @mousedown="start(() => imageAdjustment.scale -= 0.1)"
        @mouseup="stop">
      <v-icon large>mdi-magnify-minus-outline</v-icon>
    </v-btn>
    <v-btn class="brr" icon @click="rotate">
      <v-icon large>mdi-format-rotate-90</v-icon>
    </v-btn>
    <v-btn class="rrr" @click="reset" small color="grey darken-1" dark outlined>
      <v-icon>mdi-reload</v-icon>Reset
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'PersonCardPrintingAdjustment',
  data() {
    return {
      imageAdjustment: {
        left: 0,
        top: 0,
        scale: 1.0,
        rotate: 0
      },
      interval: 0
    };
  },
  watch: {
    imageAdjustment: {
      deep: true,
      handler() {
        this.$emit('input', this.imageAdjustment);
      }
    }
  },
  methods: {
    reset() {
      this.imageAdjustment = {
        left: 0,
        top: 0,
        scale: 1.0,
        rotate: 0
      };
    },
    rotate() {
      let deg = this.imageAdjustment.rotate - 90;
      if (deg >= 360 || deg <= -360) {
        deg = 0;
      }
      this.imageAdjustment.rotate = deg;
    },
    start(func) {
      this.interval = setInterval(func, 200);
    },
    stop() {
      clearInterval(this.interval);
    }
  }
};
</script>

<style scoped>
.dpad {
  margin: 0 auto;
  width: fit-content;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 40px) 80px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: "tl t tr trr trrr" "l c r rr rrr" "bl b br brr brrr";
}

.dpad .tl {
  grid-area: tl;
}
.dpad .t {
  grid-area: t;
}
.dpad .tr {
  grid-area: tr;
}
.dpad .trr {
  grid-area: trr;
}
.dpad .l {
  grid-area: l;
}
.dpad .c {
  grid-area: c;
}
.dpad .r {
  grid-area: r;
}
.dpad .rr {
  grid-area: rr;
}
.dpad .rrr {
  grid-area: rrr;
}
.dpad .bl {
  grid-area: bl;
}
.dpad .b {
  grid-area: b;
}
.dpad .br {
  grid-area: br;
}
.dpad .brr {
  grid-area: brr;
}
</style>
