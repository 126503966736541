import Vue from 'vue';

export class LoadingUtil {
  /**
   * Vuex state to mixin with a store which needs loading state
   *
   * @param {string} [defaults]
   * @return {Object}
   */
  static state(...defaults) {
    const loaded = {};
    for (const d of defaults) {
      loaded[d] = false;
    }
    return {
      loaded
    };
  }

  /**
   * Vuex getters to mixin with a store which has loading state
   *
   * @param {Logger} log
   * @return {Object}
   */
  static getters(log) {
    return {
      loaded(state) {
        const loading = Object.entries(state.loaded)
            .filter(([, loaded]) => !loaded)
            .map(([name]) => name);
        if (loading.length > 0) {
          log.debug(loading + ' not loaded');
          return false;
        } else {
          return true;
        }
      }
    };
  }

  /**
   * Vuex mutations to mixin with a store which has loading state
   *
   * @return {Object}
   */
  static mutations() {
    return {
      loading(state, name) {
        Vue.set(state.loaded, name, false);
      },
      loaded(state, name) {
        Vue.set(state.loaded, name, true);
      },
      deleteLoaded(state, name) {
        Vue.delete(state.loaded, name);
      },
      resetLoaded(state, defaults) {
        state.loaded = defaults;
      }
    };
  }
}
