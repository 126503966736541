<template>
  <v-card class="pb-2">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title class="flex-fill">
        Create Booking
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <dl class="info-table">
        <dt class="align-self-stretch d-flex align-center field-name">
          <v-icon left>mdi-calendar</v-icon>
        </dt>
        <dd>
          <date-picker-field
              v-model="selectedDate"
              :min="minimumDate"
              :allowed-dates="dateIsAllowed"/>
        </dd>
        <dt class="align-self-stretch d-flex align-center field-name">
          <v-icon left>mdi-account</v-icon>
        </dt>
        <dd>
          <owner-field class="owner-field" v-model="owner"/>
        </dd>
      </dl>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn text @click="$emit('close')">Discard</v-btn>
      <v-btn
          depressed
          color="primary primary-text--text"
          :disabled="!owner || !selectedDate"
          @click="reserveResource"
          :loading="loading">
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import OwnerField from '@/views/room-booking/calendar/OwnerField';
import {currentDate, isSameDay, toDate, forecastDays, toYearMonthDay, fromYearMonthDay} from '@/util/dates';
import {mapActions, mapGetters} from 'vuex';
import DatePickerField from '@/components/form/DatePickerField';

export default {
  name: 'BookDesk',
  components: {DatePickerField, OwnerField},
  props: {
    bookings: {
      type: Array,
      default: () => []
    },
    resource: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      owner: null,
      selectedDate: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters('sites', {
      nsAdminAdvancedDays: 'active/deskBookingNsAdminAdvancedDays',
      deskAdminAdvancedDays: 'active/deskBookingAdminAdvancedDays',
      deskAdminWeekends: 'active/deskBookingAdminWeekends',
      nsAdminWeekends: 'active/deskBookingNsAdminWeekends',
      weekend: 'active/localeWeekend'
    }),
    ...mapGetters('auth', ['isAdmin']),
    minimumDate() {
      return this.toLocalDateString(currentDate());
    },
    maxDate() {
      const days = this.futureDays;
      return this.daysInAdvanced ? days[days.length - 1] : null;
    },
    futureDays() {
      if (!this.daysInAdvanced) return [];
      return forecastDays(currentDate(), this.daysInAdvanced, this.canBookWeekends, this.weekend)
          .map(d => this.toLocalDateString(d));
    },
    availableDates() {
      return this.futureDays.filter(b => !this.bookedLocalDates.includes(b));
    },
    bookedLocalDates() {
      return this.bookings.map(b => this.toLocalDateString(toDate(b.fromTime)));
    },
    daysInAdvanced() {
      // we don't need to check isDeskBookingAdmin here, because that is needed to reach this page
      const days = this.isAdmin ? this.nsAdminAdvancedDays : this.deskAdminAdvancedDays;
      return days === 'unrestricted' ? 0 : days;
    },
    canBookWeekends() {
      // we don't need to check isDeskBookingAdmin here, because that is needed to reach this page
      return this.isAdmin ? this.nsAdminWeekends : this.deskAdminWeekends;
    }
  },
  watch: {
    bookings: 'selectNextAvailableDate',
    resource: 'selectNextAvailableDate'
  },
  mounted() {
    this.selectNextAvailableDate();
  },
  methods: {
    ...mapActions('bookables', {doReserveResource: 'reserveSpace'}),
    isWeekend(date) {
      return this.weekend.includes(date.getDay());
    },
    dateIsAllowed(val) {
      if (this.daysInAdvanced) {
        return this.availableDates.includes(val);
      }
      if (!this.canBookWeekends && this.isWeekend(val)) {
        return false;
      }
      return !this.bookedLocalDates.includes(val);
    },
    selectNextAvailableDate() {
      this.selectedDate = this.nextAvailableDate();
    },
    nextAvailableDate(date = currentDate()) {
      if (this.daysInAdvanced) {
        const next = this.availableDates[0];
        return next ? fromYearMonthDay(next) : null;
      }

      let dateFound = false;
      if (date) {
        while (!dateFound) {
          const isBooked = this.bookedLocalDates.includes(this.toLocalDateString(date));

          // check date not booked
          if (!isBooked) {
            // not booked, check can book weekends
            if (this.canBookWeekends || !this.isWeekend(date)) {
              dateFound = true;
              break;
            }
          }
          // booked, add day continue loop
          date.setDate(date.getDate() + 1);
        }
      }
      return date;
    },
    toLocalDateString(date) {
      if (!date) return null;
      return toYearMonthDay(date);
    },
    async reserveResource() {
      const booking = {
        space: this.resource,
        owner: this.owner,
        onDay: this.selectedDate
      };

      if (isSameDay(this.selectedDate, currentDate())) {
        booking.fromTime = currentDate();
      }

      this.loading = true;
      try {
        await this.doReserveResource(booking);
        this.$notify.showSuccess('Booking has been created.');
      } catch (e) {
        this.$notify.showError(`Error creating booking`);
        this.$logger.error(`error creating booking`, e);
      } finally {
        this.owner = null;
        this.loading = false;
        this.$emit('close');
      }
    }
  }
};
</script>

<style scoped>

.info-table {
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto 1fr;
  font-size: 16px;
}

.info-table dt {
  grid-column-start: 1;
}

.info-table dd {
  grid-column-start: 2;
}

.owner-field {
  margin-top: 0 !important;
  padding: 16px 0 0 0 !important;
}


.field-name {
  padding: 16px 0 0 0;
}

</style>
