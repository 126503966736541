import {createWorkOrderDetail} from '@/views/room-booking/calendar/work-orders-util';

/**
 * @param {import('vuex').ActionContext} context
 * @param {CalendarEvent} event
 * @param {Object} props
 * @return {kahu.firestore.WorkOrder & DecoratedData}
 */
export function newCateringWorkOrder(context, event, props) {
  const ref = context.rootGetters['ns/nsRef'].collection('workOrders').doc();
  const subject = createWorkOrderDetail(event.key, event.resource);
  const workOrder = {
    ...context.rootGetters['user/auditSnippet'],
    ...context.rootGetters['user/creatableSnippet'],
    category: 'Catering',
    subject,
    ...props
  };
  // make the object look decorated
  Object.defineProperty(workOrder, 'ref', {
    enumerable: false,
    writable: false,
    value: ref
  });
  Object.defineProperty(workOrder, 'id', {
    enumerable: false,
    get() {
      return this.ref.id;
    }
  });
  // this is a future document, we haven't added it to firestore yet
  Object.defineProperty(workOrder, 'exists', {
    enumerable: false,
    writable: false,
    value: false
  });
  return workOrder;
}
