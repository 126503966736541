import {DocumentCollection} from '@/store/collection/document-collection';

/**
 * All the information related to a list of card changes. Including the query used to collect that list and any
 * metadata about the list that we think we know - i.e. do we think there are more records to get.
 */
export class CardHistoryCollection extends DocumentCollection {
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {firebase.firestore.DocumentReference} cardRef
   */
  constructor(ns, cardRef) {
    super(ns, []);

    /**
     * @type {firebase.firestore.DocumentReference}
     */
    this.cardRef = cardRef;
  }


  /**
   * @override
   * @return {Promise<firebase.firestore.Query<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    return this.cardRef.firestore.collection(`ns/${this.ns}/search/changes/records`)
        .where('change.document.ref', '==', this.cardRef)
        .orderBy('change.changeTime', 'desc');
  }
}
