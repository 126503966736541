/**
 * @param {Array<T>} arr
 * @return {Array<Array<T>>}
 * @template T
 */
export function chunkArray(arr) {
  if (arr.length === 0) {
    return [];
  }
  const chunks = [[]];
  const chunkItemCount = 10;
  for (const item of arr) {
    let chunk = chunks[chunks.length - 1];
    if (chunk.length >= chunkItemCount) {
      chunk = [];
      chunks.push(chunk);
    }
    chunk.push(item);
  }
  return chunks;
}
