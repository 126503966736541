import Vue from 'vue';
import moment from 'moment';

// set moment locale globally
moment.locale(window.navigator.language);

Vue.filter('moment', (v, f) => {
  if (!v) {
    return '';
  }
  if (typeof v.toDate === 'function') {
    return moment(v.toDate()).format(f);
  }
  return moment(v).format(f);
});
