<template>
  <card-scan-button @scan="$emit('scan', $event)" v-model="open" tool-tip="Search by card">
    <template #title>{{ title }}</template>
    <template #default="{uid}">
      <template v-if="uid">
        <v-alert tile text class="mb-0" :type="alertType">
          <div class="subtitle-2">Card ID: {{ cardTitle ? cardTitle : uid }}</div>
          <div v-if="alertMessage">{{ alertMessage }}</div>
        </v-alert>
        <v-progress-linear v-if="searching" color="secondary" indeterminate/>
      </template>
    </template>
  </card-scan-button>
</template>

<script>
import CardScanButton from '@/components/cards/CardScanButton';
import {mapGetters} from 'vuex';

export default {
  name: 'CardScanSearch',
  components: {CardScanButton},
  props: {
    alertType: {
      type: String,
      default: null
    },
    alertMessage: {
      type: String,
      default: null
    },
    cardTitle: {
      type: String,
      default: null
    },
    searching: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters('cardReader', [
      'noCardReaderSoftware'
    ]),
    title() {
      if (this.noCardReaderSoftware) {
        return 'Search by card';
      } else {
        return 'Scan a card to search';
      }
    }
  },
  watch: {
    open(o) {
      this.$emit('input', o);
    },
    value: {
      handler(v) {
        this.open = v;
      },
      immediate: true
    }
  }
};
</script>

<style scoped>

</style>
