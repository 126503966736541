var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-badge',{attrs:{"value":!_vm.sitesConfigured,"icon":"mdi-cog","color":"warning","offset-x":"15","offset-y":"15","overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""}},'v-btn',attrs,false),Object.assign({}, on, onTooltip)),[_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-domain")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sitesConfigured ? _vm.siteName : 'No sites configured'))])])]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-card',{attrs:{"min-width":"300"}},[_c('v-card-title',{staticClass:"px-4 py-2 grid"},[_c('span',{staticClass:"pr-4"},[_vm._v("Choose Site")]),_c('v-spacer'),(!_vm.sitesConfigured)?_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert")])],1):_vm._e(),(!_vm.onlyOneCountry)?_c('edit-group-sites-by-country',{staticClass:"toggle",attrs:{"label":"Group by country","show-icon":false}}):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-0"},[(!_vm.sitesConfigured)?_c('span',[_vm._v("You have no sites configured, functionality will be restricted. "),_c('br'),_vm._v(" Please contact your administrator.")]):_c('v-radio-group',{ref:"radioGroup",staticClass:"pt-0 mt-0",attrs:{"value":_vm.forceActiveSite || _vm.activeSiteId,"hide-details":""},on:{"change":function($event){return _vm.chooseActiveSite($event)}}},[_c('v-list',{staticClass:"pb-0",attrs:{"dense":""}},[_vm._l((_vm.siteListGroups),function(group){return [(group.title)?_c('v-subheader',{key:group.title},[_c('b',[_vm._v(_vm._s(group.title))])]):_vm._e(),_vm._l((group.sites),function(site){return _c('v-hover',{key:site.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-radio',{attrs:{"label":site.text,"value":site.value,"color":"accent"}})],1),(_vm.preferredSiteId === site.value)?_c('v-list-item-action-text',{staticClass:"grey--text text--lighten-1"},[_vm._v(" (default) ")]):_c('v-list-item-action',[(hover || _vm.loading === site.value)?_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveDefaultSite(site.value)}}},[_vm._v(" Set Default ")]):_vm._e()],1)],1)]}}],null,true)})})]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }