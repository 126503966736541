import {Logger} from '@vanti/vue-logger';
import moment from 'moment';
import {add, sub, differenceInDays, getDay} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';

const log = Logger.get('dates');

export const millis = 1;
export const seconds = 1000 * millis;
export const minutes = 60 * seconds;
export const hours = 60 * minutes;
export const days = 24 * hours;
export const weeks = 7 * days;
export const months = 30 * days;
export const years = 265 * days;

export const monday = 1;
export const tuesday = 2;
export const wednesday = 3;
export const thursday = 4;
export const friday = 5;
export const saturday = 6;
export const sunday = 0;

export const DayNames = {
  [monday]: 'Monday',
  [tuesday]: 'Tuesday',
  [wednesday]: 'Wednesday',
  [thursday]: 'Thursday',
  [friday]: 'Friday',
  [saturday]: 'Saturday',
  [sunday]: 'Sunday'
};

export const DefaultDateTimeFormatOptions = new Intl.DateTimeFormat().resolvedOptions();
export const DefaultLocale = DefaultDateTimeFormatOptions.locale;
export const DefaultTimeZone = DefaultDateTimeFormatOptions.timeZone || 'UTC';

/**
 * Returns a new Date each time.
 *
 * @return {Date}
 */
export function currentDate() {
  return new Date();
}

/**
 * Returns a copy of date
 *
 * @param {Date} date
 * @return {Date}
 */
export function cloneDate(date) {
  return new Date(date.getTime());
}

/**
 * Checks if two dates are the same.
 *
 * @param {Date} first
 * @param {Date} second
 * @return {boolean}
 */
export function isSameDay(first, second) {
  return first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
}

/**
 * Is first after second?
 *
 * @param {Date} first
 * @param {Date} second
 * @return {boolean}
 */
export function isAfter(first, second) {
  return first.getTime() > second.getTime();
}

/**
 * Is first before second?
 *
 * @param {Date} first
 * @param {Date} second
 * @return {boolean}
 */
export function isBefore(first, second) {
  return first.getTime() < second.getTime();
}

/**
 * Return the start of the day.
 *
 * @param {Date} [now]
 * @return {Date}
 */
export function startOfDay(now = currentDate()) {
  const res = new Date(now.getTime());
  res.setHours(0, 0, 0, 0);
  return res;
}

/**
 * Return the start of the day in UTC.
 *
 * @param {Date} [now]
 * @return {Date}
 */
export function startOfDayUTC(now = currentDate()) {
  const res = new Date(now.getTime());
  res.setUTCHours(0, 0, 0, 0);
  return res;
}

/**
 * Return the start of the day.
 *
 * @param {Date} [now]
 * @return {Date}
 */
export function endOfDay(now = currentDate()) {
  const res = new Date(now.getTime());
  res.setHours(23, 59, 59, 999);
  return res;
}

/**
 * Adds the specified duration (in ms) to the time.
 * Note this won't take into account daylight savings and other time-zone related quirks. For these cases, see addDays.
 *
 * @param {Date} now
 * @param {number} duration
 * @return {Date}
 */
export function addTime(now, duration) {
  return new Date(now.getTime() + duration);
}

/**
 * Returns the smallest date from the given dates.
 *
 * @param {...CanBeDate} dates
 * @return {Date}
 */
export function minDate(...dates) {
  let d = undefined;
  for (let i = 0; i < dates.length; i++) {
    if (!dates[i]) continue;
    const di = toDate(dates[i]);
    if (!d || di < d) {
      d = di;
    }
  }
  return d;
}

/**
 * Returns the largest date from the given dates.
 *
 * @param {...CanBeDate} dates
 * @return {Date}
 */
export function maxDate(...dates) {
  let d = undefined;
  for (let i = 0; i < dates.length; i++) {
    if (!dates[i]) continue;
    const di = toDate(dates[i]);
    if (!d || di > d) {
      d = di;
    }
  }
  return d;
}

/**
 * @typedef {string | Date | number | {toDate: function(): Date}} CanBeDate
 */

/**
 * Finds the first possible date out of the given values
 *
 * @param {...(CanBeDate|function():CanBeDate)} dates
 * @return {Date}
 */
export function toDate(...dates) {
  for (let date of dates) {
    if (typeof date === 'function') {
      date = date();
    }
    if (date instanceof Date) {
      return date;
    }
    if (date === null || typeof date === 'undefined') {
      continue;
    }
    if (typeof date.toDate === 'function') return date.toDate();

    try {
      const d = new Date(date);
      if (!isNaN(d)) return d; // check for Invalid Date
    } catch (e) {
      log.warn('Failed to create date from', date);
    }
  }

  return null;
}

/**
 * Finds the first possible date out of the given values and returns it's toTime value.
 *
 * @param {...(CanBeDate|function():CanBeDate)} dates
 * @return {number | false}
 */
export function toTime(...dates) {
  const d = toDate(...dates);
  return d ? d.getTime() : false;
}

/**
 * Returns a [string, number] pair representing the largest unit the distance between the two dates allows. For example
 * it might return ['seconds', 12] but not ['seconds', 120] as that would be ['minutes', 2]
 *
 * @param {CanBeDate} d1
 * @param {CanBeDate} d2
 * @return {(number|string)[]}
 */
export function distanceAndUnit(d1, d2) {
  d1 = toDate(d1);
  d2 = toDate(d2);
  const units = [
    ['years', years],
    ['months', months],
    ['weeks', weeks],
    ['days', days],
    ['hours', hours],
    ['minutes', minutes],
    ['seconds', seconds]
  ];
  const distance = d1.getTime() - d2.getTime();
  for (let i = 0; i < units.length; i++) {
    const [label, value] = units[i];
    if (Math.abs(distance) > value) {
      return [Math.trunc(distance / value), label];
    }
  }

  // default case, 0 seconds ago
  return [-0, 'seconds'];
}

/**
 * Convert the given date into an ISO date string YYYY-MM-DD, in UTC
 *
 * @param {CanBeDate} date
 * @return {string}
 */
export function toISODate(date) {
  const d = toDate(date);
  return d && d.toISOString().substr(0, 10) || '';
}

/**
 * Convert the given date into an ISO time string HH:MM:SS.SSSZ, in UTC
 *
 * @param {CanBeDate} date
 * @return {string}
 */
export function toISOTime(date) {
  const d = toDate(date);
  return d && d.toISOString().substr(11) || '';
}

/**
 * Return the date string YYYY-MM-DD, in the local timezone of the date
 *
 * @param {CanBeDate} date
 * @return {string|null}
 */
export function toYearMonthDay(date) {
  if (!date) return null;
  const d = toDate(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  return [year, month, day].join('-');
}

/**
 * Create a date from the given YYYY-MM-DD string.
 *
 * Note: calling `new Date('YYYY-MM-DD')` will (or may) be interpreted as midnight UTC on that date.
 * Instead, call `new Date(year, month, day)` to get it at midnight in the local timezone.
 *
 * @param {string} ymd - YYYY-MM-DD
 * @return {Date|null}
 */
export function fromYearMonthDay(ymd) {
  if (!ymd) return null;
  let [year, month, day] = ymd.split('-');
  year = parseInt(year);
  month = parseInt(month) - 1;
  day = parseInt(day);
  return new Date(year, month, day);
}

/**
 * Create a date from the given YYYY-MM-DD string, interpreted as UTC
 *
 * @param {string} ymd - YYYY-MM-DD
 * @return {Date|null}
 */
export function fromYearMonthDayUTC(ymd) {
  if (!ymd) return null;
  let [year, month, day] = ymd.split('-');
  year = parseInt(year);
  month = parseInt(month) - 1;
  day = parseInt(day);
  return new Date(Date.UTC(year, month, day));
}

/**
 * Returns all the days that should be included in a forecast based on the given day and configuration options
 *
 * @param {string | Date } today
 * @param {number} [forecastDays]
 * @param {boolean} [includeWeekends]
 * @param {number[]} [weekend] - 0 Sunday - 6 Saturday
 * @return {Date[]}
 */
export function forecastDays(today, forecastDays = 5, includeWeekends = false, weekend = [6, 0]) {
  if (forecastDays <= 0) {
    throw new Error(`forecastDays must be positive, got ${forecastDays}`);
  }
  const dates = [];
  /** @type {Date} */
  let date = new Date(today);
  for (let i = 0; dates.length < forecastDays; i++) {
    if (!includeWeekends && weekend.includes(getDay(date))) {
      date = add(date, {days: 1});
      continue;
    }
    dates.push(date);
    date = add(date, {days: 1});
  }
  return dates;
}

/**
 * Returns all past days based on the given day and configuration options
 *
 * @param {string | Date } today
 * @param {number} days
 * @param {boolean} [includeWeekends]
 * @param {number[]} [weekend] - 0 Sunday - 6 Saturday
 * @return {Date[]}
 */
export function lastWeekDays(today, days = 6, includeWeekends = false, weekend = [6, 0]) {
  if (days <= 0) {
    throw new Error(`days must be positive, got ${days}`);
  }
  const dates = [];
  /** @type {Date} */
  let date = new Date(today);
  for (let i = 0; dates.length < days; i++) {
    if (!includeWeekends && weekend.includes(getDay(date))) {
      date = sub(date, {days: 1});
      continue;
    }
    dates.push(date);
    date = sub(date, {days: 1});
  }
  return dates;
}

/**
 * @param {CanBeDate} date
 * @param {number} day
 * @return {boolean}
 */
export function isDay(date, day) {
  const d = toDate(date);
  return d.getDay() === day;
}

/**
 * Returns the current time.
 *
 * @return {moment.Moment}
 */
export function nowMoment() {
  return moment();
}

/**
 * Add the given number of days to the date
 *
 * @param {Date} date
 * @param {number} days
 * @return {Date}
 */
export function addDays(date, days) {
  const d = new Date(date.getTime());
  d.setDate(date.getDate() + days);
  return d;
}

/**
 *
 * @param {Date} start
 * @param {number} day
 * @param {Date} [end]
 * @return {Date|undefined}
 */
export function nextDateOnDay(start, day, end) {
  let date = start;
  while (!isDay(date, day)) {
    date = addDays(date, 1);
    if (end && isAfter(date, end)) {
      return undefined;
    }
  }
  return date;
}

/**
 * Returns all dates between from and to on the days of the week specified by days.
 *
 * @param {Date} from
 * @param {Date} to
 * @param {number[]} days
 * @return {Date[]}
 */
export function datesOnDays(from, to, days) {
  const dates = [];
  const firstOnDays = days
      .map(d => nextDateOnDay(from, d, to))
      .filter(Boolean); // filter out undefined
  dates.push(...firstOnDays);
  // for each week day, increment by a week until we're out of the date range
  for (const firstOnDay of firstOnDays) {
    let date = addDays(firstOnDay, 7);
    while (isBefore(date, to) || isSameDay(date, to)) {
      dates.push(date);
      date = addDays(date, 7);
    }
  }
  return dates.sort((a, b) => a - b);
}

/**
 * @typedef {string[]} DateRange
 */

/**
 * Takes a date range array and works out the days that are in the range
 *
 * @param {DateRange} dates
 * @return {Date[]}
 */
export function daysInDateRange(dates) {
  if (!dates || dates.length !== 2) throw new Error(`dates array is undefined or not the correct length`);
  let [startDate, endDate] = dates;
  startDate = fromYearMonthDay(startDate);
  endDate = fromYearMonthDay(endDate);


  const difference = differenceInDays(endDate, startDate);
  if (difference < 1) throw new Error(`end date should be after start date`);

  const days = [];
  for (let i = 0; i <= difference; i++) {
    days.push(startDate);
    startDate = addDays(startDate, 1);
  }

  return days;
}

/**
 * Returns a value for UTC midnight in milliseconds
 *
 * @return {number}
 */
export function utcMidnightMs() {
  const date = new Date();
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
}

/**
 * Returns the day of the week in the range [0-6], where 0 is Sunday, in the given timeZone.
 *
 * @param {Date} date
 * @param {string} timeZone
 * @return {number} 0 Sunday - 6 Saturday
 */
export function dayOfWeek(date, timeZone) {
  const dow = formatInTimeZone(date, timeZone, 'EEEE');
  for (const [num, name] of Object.entries(DayNames)) {
    if (dow === name) {
      return parseInt(num);
    }
  }
  throw new Error(`unknown day of the week: ${dow} for ${date} in ${timeZone}`);
}

/**
 * Get the next day in the given time zone.
 *
 * @param {Date} date
 * @param {string} timeZone
 * @return {Date}
 */
export function nextDay(date, timeZone) {
  const ymd = formatInTimeZone(date, timeZone, 'yyyy-MM-dd');
  const next = cloneDate(date);
  next.setUTCDate(next.getUTCDate() + 1);
  let nextYmd = formatInTimeZone(next, timeZone, 'yyyy-MM-dd');
  if (ymd !== nextYmd) {
    return next;
  }
  // perhaps DST boundary
  for (let i = 0; i < 2 && ymd === nextYmd; i++) {
    next.setUTCHours(next.getUTCHours() + 1);
    nextYmd = formatInTimeZone(next, timeZone, 'yyyy-MM-dd');
  }
  return next;
}
