<template>
  <v-menu :close-on-content-click="false" bottom offset-y v-model="menuOpen">
    <template #activator="{ on }">
      <v-btn
          icon
          small
          style="width: 24px; height: 24px"
          v-on="on">
        <v-icon :color="internalColor">mdi-checkbox-blank-circle</v-icon>
      </v-btn>
    </template>

    <v-card min-width="300">
      <v-card-title class="px-4 py-2">
        Change Color
        <v-spacer/>
        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <v-btn
                icon
                @click="done"
                v-bind="attrs"
                color="secondary"
                v-on="on">
              <v-icon color="grey lighten-2">mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Set {{ internalColor }} as this neighbourhood's color</span>
        </v-tooltip>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-color-picker hide-mode-switch mode="hexa" v-model="internalColor"/>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    }
  },
  data() {
    return {
      menuOpen: false,
      internalValue: this.color
    };
  },
  computed: {
    internalColor: {
      get() {
        return this.color;
      },
      set(value) {
        // temporary fix while there is no way to disable the alpha channel in the
        // colorpicker component: https://github.com/vuetifyjs/vuetify/issues/9590
        if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
          this.$emit('update:color', value.substr(0, 7));
        } else {
          this.$emit('update:color', value);
        }
      }
    }
  },
  methods: {
    async done() {
      this.$emit('update:color', this.internalColor);
      this.menuOpen = false;
    }
  }
};
</script>

<style scoped>

</style>
