
/**
 * Batch Group is an abstraction around a {@link firebase.firestore.WriteBatch} to allow more than 500 updates by using
 * multiple batches. Note this means updates across batches aren't atomic.
 */
export class BatchGroup {
  /**
   * @param {firebase.firestore.Firestore} firestore
   */
  constructor(firestore) {
    /**
     * @type {firebase.firestore.Firestore}
     * @private
     */
    this._db = firestore;
    /**
     * @type {firebase.firestore.WriteBatch}
     * @private
     */
    this._batch = this._db.batch();
    /**
     * @type {firebase.firestore.WriteBatch[]}
     * @private
     */
    this._batches = [this._batch];
    /**
     * @type {number}
     * @private
     */
    this._opCount = 0;
  }

  /**
   * See: {@link firebase.firestore.WriteBatch.set}
   *
   * @param {firebase.firestore.DocumentReference} ref
   * @param {T} args
   * @template T
   */
  set(ref, ...args) {
    this._batch.set(ref, ...args);
    this._incrementCheckBatch();
  }

  /**
   * See: {@link firebase.firestore.WriteBatch.update}
   *
   * @param {firebase.firestore.DocumentReference} ref
   * @param {T} args
   * @template T
   */
  update(ref, ...args) {
    this._batch.update(ref, ...args);
    this._incrementCheckBatch();
  }

  /**
   * See: {@link firebase.firestore.WriteBatch.delete}
   *
   * @param {firebase.firestore.DocumentReference} ref
   */
  delete(ref) {
    this._batch.delete(ref);
    this._incrementCheckBatch();
  }

  /**
   * Commit all batches in parallel.
   *
   * @return {Promise<void[]>}
   */
  commit() {
    return Promise.all(this._batches.map(b => b.commit()));
  }


  /**
   * Increment op count and check if we need a new batch.
   *
   * @private
   */
  _incrementCheckBatch() {
    this._opCount++;
    if (this._opCount === 500) {
      this._opCount = 0;
      this._batch = this._db.batch();
      this._batches.push(this._batch);
    }
  }
}
