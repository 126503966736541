import firebase from 'firebase/app';
import {CardIdTypeRef} from '@/util/card-type';

/**
 * Are the two document references equivalent, i.e. do they share the same ref or id properties.
 *
 * @param {Object} p1
 * @param {Object} p2
 * @return {boolean}
 */
export function isSameDocument(p1, p2) {
  if (p1 === p2) {
    // same instance (or both null, undefined)
    return true;
  }

  if (!p1 || !p2) {
    // one of the people is missing
    return false;
  }

  if (p1.ref && p2.ref) {
    if (typeof p1.ref.isEqual === 'function') {
      // ref vs ref
      if (p1.ref.isEqual(p2.ref)) {
        return true;
      }
    }

    if (typeof p1.ref.path === 'string') {
      // ref vs string
      if (p1.ref.path === p2.ref) {
        return true;
      }
    }

    if (typeof p2.ref.path === 'string') {
      // string vs ref
      if (p1.ref === p2.ref.path) {
        return true;
      }
    }

    if (p1.ref === p2.ref) {
      // string vs string
      return true;
    }
  }

  return p1.id === p2.id;
}

/**
 * @typedef {DecoratedData} card
 * @property {Object} [uids]
 * @property {string} [title]
 * @property {Object} [tags]
 * @property {boolean} [disabled]
 * @property {Date} [enabledFrom]
 * @property {Date} [enabledTo]
 * @property {Object} owner
 * @property {string} role
 * @property {Object} sites
 * @property {string} updateReason
 * @property {boolean} [returned]
 */

/**
 * Add or update card document using provided WriteBatch. Checks it the card
 * is changing ownership and removes properties as appropriate.
 *
 * @param {Object} options
 * @param {firebase.firestore.WriteBatch} options.batch
 * @param {firebase.firestore.DocumentReference} options.ref
 * @param {card} options.data
 * @param {DecoratedData} options.existingDoc
 * @param {string} options.activeSiteId
 * @param {string} options.personId
 * @param {string} options.siteCardIdType
 * @param {kahu.firestore.Auditable} options.auditSnippet
 */
export function addOrUpdateCard({batch, ref, data, existingDoc,
  personId, activeSiteId, siteCardIdType, auditSnippet}) {
  const changingOwner = isChangingOwnership(existingDoc, data.id, personId);
  const isExistingCard = existingDoc && existingDoc.exists;
  const cardData = {
    ...createCardDoc(data, changingOwner, isExistingCard, activeSiteId, siteCardIdType),
    ...auditSnippet
  };

  if (isExistingCard) {
    batch.update(existingDoc.ref, cardData);
  } else {
    batch.set(ref, cardData);
  }
}

/**
 * Create a card document from form data. If the ownership of the card is changing
 * then the card document will have all ownership properties deleted (then overridden
 * by provided data).
 *
 * @param {card} data
 * @param {boolean} changingOwner
 * @param {boolean} isExistingCard
 * @param {string} activeSiteId
 * @param {string} siteCardIdType
 * @return {card|firebase.firestore.UpdateData}
 */
export function createCardDoc(data, changingOwner, isExistingCard, activeSiteId, siteCardIdType) {
  // delete all ownership properties if new owner
  const cardDoc = changingOwner ? replacedCardDoc() : {};

  cardDoc.role = data.role;
  cardDoc.updateReason = data.updateReason;
  cardDoc.owner = data.owner;

  if (siteCardIdType !== CardIdTypeRef) {
    cardDoc.uids = {};
    cardDoc.uids[siteCardIdType] = data.id;
  }
  if (data.hasOwnProperty('nonTransferable')) {
    cardDoc.nonTransferable = data.nonTransferable;
  }
  if (data.enabledFrom) {
    cardDoc.enabledFrom = data.enabledFrom;
  }
  if (data.enabledTo) {
    cardDoc.enabledTo = data.enabledTo;
  }

  if (changingOwner || !isExistingCard) {
    // new owner or new card, replace sites field
    cardDoc.sites = data.sites;
  } else {
    for (const site in data.sites) {
      if (!data.sites.hasOwnProperty(site)) {
        continue;
      }
      // only set sites we are changing
      cardDoc['sites.' + site] = data.sites[site];
    }
  }

  if (isExistingCard) {
    if (cardDoc.role === 'permanent') {
      // if this card was previously a temporary card it may have enabledFrom/To fields
      // which are no longer required
      cardDoc.enabledFrom = firebase.firestore.FieldValue.delete();
      cardDoc.enabledTo = firebase.firestore.FieldValue.delete();
    } else if (cardDoc.role === 'temporary') {
      // if this card was previously a permanent card it may have the disabled field set
      cardDoc.disabled = firebase.firestore.FieldValue.delete();
    }
  } else {
    // new card, add activeSiteId as assignedAt
    cardDoc.assignedAt = activeSiteId;
  }

  return cardDoc;
}

/**
 * If the card already exists, check if the owner is different
 *
 * @param {*} cardDoc
 * @param {string} cardId
 * @param {string} personId
 * @return {boolean}
 */
export function isChangingOwnership(cardDoc, cardId, personId) {
  if (cardDoc && cardDoc.id === cardId) {
    if (cardDoc.hasOwnProperty('owner') && cardDoc.owner.hasOwnProperty('ref')) {
      /** @type {firestore.DocumentReference} */
      const oldOwnerRef = cardDoc.owner.ref;
      if (oldOwnerRef.id !== personId) {
        return true;
      }
    }
  }
  return false;
}

/**
 * @typedef {Object} membershipOptions
 * @property {string} ns
 * @property {string} type
 * @property {Date} validFrom
 * @property {Date} [validTo]
 */

/**
 * @typedef {Object} inlinePerson
 * @property {string} id
 * @property {firebase.firestore.DocumentReference} ref
 * @property {string} title
 * @property {string} displayName
 */

/**
 * Add site membership document to the provided batch write
 *
 * @param {Object} args
 * @param {firebase.firestore.WriteBatch} args.batch
 * @param {membershipOptions} args.options
 * @param {inlinePerson} args.person
 * @param {DocumentReference} args.siteDoc
 */
export function addMembership({batch, options, person, siteDoc}) {
  const membershipDoc = {
    ...options,
    person: {
      ref: person.ref,
      title: person.title,
      displayName: person.displayName
    }
  };
  const membershipRef = siteDoc.collection('members').doc(person.id);
  batch.set(membershipRef, membershipDoc);
}

/**
 * Properties of a card which are tied to ownership not the card itself
 *
 * @type {string[]}
 */
const ownershipProperties = [
  'disabled',
  'enabledFrom',
  'enabledTo',
  'owner',
  'role',
  'sites',
  'updateReason',
  'returned'
];

/**
 * Returns a document with all card ownership properties set to delete
 *
 * @return {*}
 */
export function replacedCardDoc() {
  const card = {};
  for (const prop of ownershipProperties) {
    card[prop] = firebase.firestore.FieldValue.delete();
  }
  return card;
}
