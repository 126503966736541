<template>
  <v-card outlined :elevation="(disabled || cardDisabled) ? 0 : 1">
    <slot name="header"/>
    <v-card-title class="font-weight-regular">
      <span class="text-uppercase grey--text text--lighten-1">{{ temporary ? 'Temporary' : 'Permanent' }}</span>
      <v-spacer/>
      <template v-if="hasCard">
        <router-link
            :to="{name: 'card', params: {id: cardId}}"
            class="grey--text text--lighten-3 link">
          <card-id-detail :card-title="cardTitle" :card-id-type="cardIdType" :hex-card-id="siteCardId"/>
        </router-link>
      </template>
      <template v-else>
        <v-tooltip bottom>
          <template #activator="{on}">
            <v-btn icon v-on="on" @click="$emit('assign')">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Assign {{ temporary ? 'Temporary' : 'Permanent' }} Card</span>
        </v-tooltip>
      </template>
    </v-card-title>
    <v-card-text v-if="$slots.content">
      <v-container fluid class="pa-0">
        <slot name="content"/>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="$slots.actions" :class="{'mb-4': actionMargin, 'mr-4': actionMargin}">
      <slot name="actions"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import {CardIdTypeRef} from '@/util/card-type';
import CardIdDetail from '@/components/cards/CardIdDetail';

export default {
  name: 'CardView',
  components: {CardIdDetail},
  props: {
    actionMargin: Boolean,
    card: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    temporary: Boolean
  },
  computed: {
    ...mapGetters('sites/active', ['configCardIdType']),
    cardId() {
      if (this.hasCard) {
        return this.card.id;
      }
      return '';
    },
    cardTitle() {
      if (!this.hasCard) {
        return '';
      }
      const card = this.card;
      if (card.title) {
        return card.title;
      }
      return this.siteCardId;
    },
    cardIdType() {
      const card = this.card;
      // handle card cardIdType override
      if (card.cardIdType) {
        return card.cardIdType;
      }
      // check site-level cardIdType
      if (this.configCardIdType) {
        return this.configCardIdType;
      }
      return CardIdTypeRef;
    },
    siteCardId() {
      const card = this.card;
      if (!card) {
        return undefined;
      }
      // handle card cardIdType override
      if (card.cardIdType && card.cardIdType !== CardIdTypeRef && card.uids && card.uids[card.cardIdType]) {
        return card.uids[card.cardIdType];
      }
      // check site-level cardIdType
      if (this.configCardIdType !== CardIdTypeRef && card.uids && card.uids[this.configCardIdType]) {
        return card.uids[this.configCardIdType];
      }
      return card.id;
    },
    cardDisabled() {
      if (!this.hasCard) {
        return false;
      }
      return this.card.disabled;
    },
    hasCard() {
      if (!this.card) {
        return false;
      }
      return this.card.hasOwnProperty('id');
    }
  }
};
</script>

<style scoped>
  .link {
    text-decoration: none;
  }
</style>
