import bookingCalendar from './calendar/booking-calendar';
import booking from './calendar/booking-view/booking';
import exportBookings from '@/views/room-booking/export/export-bookings';

export default {
  namespaced: true,
  modules: {
    bookingCalendar,
    booking,
    exportBookings
  }
};
