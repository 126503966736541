import {EmptyCollection} from '@/store/collection/empty-collection';
import {PeopleCollection} from '@/views/people/directory/people-collection';
import {db, decorateSnapshot} from '@/firebase';

/**
 * Create a store containing a PeopleCollection
 *
 * @return {Object}
 */
export default function() {
  return {
    namespaced: true,
    state: {
      /** @type {?DocumentCollection} */
      currentCollection: new EmptyCollection()
    },
    getters: {
      records(state) {
        return state.currentCollection.records;
      },
      people(state) {
        // If search bar is an exact match we should only show users when their titles are an exact match.
        // Doing this by checking the collection we have and if the title is an exact match the clause
        const result = state.currentCollection.documents.filter((doc) => doc.title.toLowerCase() ===
        state.currentCollection.queryClauses[0][2].toString().replaceAll(',', ' '));
        if (result.length > 0) {
          return result;
        }
        return state.currentCollection.documents;
      },
      loadingInfo(state) {
        const c = state.currentCollection;
        if (c.recordFetchAborted()) {
          return {
            text: `Unfortunately we were unable to fetch ${c.records.length > 0 ? 'more' : 'any'} people at this time.`,
            type: 'error',
            color: 'error',
            loading: false
          };
        } else if (c.fetchErrorCount > 1) {
          return {
            text: `We're experiencing some issues fetching ${c.records.length > 0 ? 'more ' : ''}people` +
                ', please wait while we try and sort out the problem.',
            type: 'warning',
            color: 'warning',
            loading: 'warning'
          };
        } else if (c.recordsAreLoading) {
          return {
            text: `Loading ${c.records.length > 0 ? 'more ' : ''}people...`,
            type: 'info',
            color: 'grey',
            loading: true
          };
        } else if (c.records.length === 0) {
          return {
            text: `No people could be found`,
            type: 'info',
            color: 'info',
            loading: false,
            noRecords: true
          };
        } else if (!c.expectsMoreRecords) {
          return {text: 'No more people'};
        } else {
          return {};
        }
      }
    },
    mutations: {
      /**
       * @param {Object} state
       * @param {PeopleCollection} collection
       */
      setCurrentCollection(state, collection) {
        state.currentCollection = collection;
      },
      clear(state) {
        state.currentCollection = new EmptyCollection();
      }
    },
    actions: {
      /**
       * Configure the current collection to be based off of the given query clauses.
       *
       * @param {import('vuex').ActionContext} context
       * @param {QueryClause[]} queryClauses
       */
      async bind({state, rootGetters, commit}, queryClauses) {
        const ns = rootGetters.ns;
        /** @type {QueryOptions} */
        const options = {
          pageSize: 100
        };

        if (!state.currentCollection.sameAs({ns, queryClauses})) {
          commit('setCurrentCollection', new PeopleCollection(ns, queryClauses, options));
        }

        // else the current collection is equivalent to the one we're binding to, don't do anything
      },
      unbind({commit}) {
        commit('clear');
      },

      async load({state}) {
        return state.currentCollection.load();
      },

      async stop({state}) {
        return state.currentCollection.stop();
      },

      async peopleWithEmail({rootGetters}, emailAddress) {
        const ns = rootGetters.ns;
        const _db = await db;
        const peopleWithEmail = await _db.collection(`ns/${ns}/people`)
            .where('email', '==', emailAddress.toLowerCase())
            .get();
        const result = [];
        peopleWithEmail.forEach(person => result.push(decorateSnapshot(person)));
        return result;
      }
    }
  };
}
