import {db, decorateReference} from '@/firebase';
import {DocumentCollection} from '@/store/collection/document-collection';

/**
 * All the information related to a list of cards. Including the query used to collect that list and any metadata about
 * the list that we think we know - i.e. do we think there are more records to get.
 */
export class CardsCollection extends DocumentCollection {
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {QueryClause[]} queryClauses The list of query clauses
   * @param {*} [filter] Any filter data to associate with the collection
   */
  constructor(ns, queryClauses, filter = undefined) {
    super(ns, queryClauses);
    this.filter = filter;
    /**
     * A set of seen people references for de-duplicating search records into the card list.
     *
     * @type {Set<string>}
     */
    this.seenCardRefs = new Set();
  }

  /**
   * @return {Promise<firebase.firestore.CollectionReference<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    const _db = await db;
    return _db.collection('ns/' + this.ns + '/search/cards/records')
        .orderBy('ordinal', 'asc');
  }


  /**
   * Adds the given list of records to the records list.
   *
   * @override
   * @param {DecoratedData[]} records
   * @private
   */
  saveRecords(records) {
    this.records.push(...records);

    // save unique cards
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.card && record.card.ref) {
        // if we're being asked to filter sites ourselves
        if (this.filter && this.filter.site && (!record.sites || !record.sites.includes(this.filter.site))) {
          continue;
        }
        const key = record.card.ref.path;
        if (!this.seenCardRefs.has(key)) {
          this.seenCardRefs.add(key);
          const card = decorateReference(record.card);
          this.documents.push(card);
        }
      }
    }
  }
}
