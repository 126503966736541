import interact from 'interactjs'

export default {
  data () {
    return {
      drawingable: undefined
    }
  },

  methods: {
    $_haveVNodeMoreThan: (number, slots, callback) => { if (slots) if (slots.length > 1) callback() },

    enableDrawing (enabled = true) {
      if (enabled && (this.drawingable ? !interact.isSet(this.background) : true)) {
        this.$_haveVNodeMoreThan(1, this.$slots.drawing, () => {
          throw Error(`only 1 slot="drawing" allowed, you have ${this.$slots.drawing.length} slot="drawing"`)
        })
        this.background.style.cursor = 'crosshair';

        /** @type {HTMLElement} */
        let annotatorEl
        /** @type {DOMPoint} */
        let start;
        this.drawingable = interact(this.background)
          .styleCursor(false)
          .draggable({
            inertia: this.inertia,
            snap: { targets: [this.gridTarget] },
            restrict: 'svg', // allow drawing only in background element (outside annotation)
            autoScroll: true,

            onstart: event => {
              const drawing = this.$slots.drawing[0].elm;
              annotatorEl = drawing.cloneNode();
              drawing.parentNode.appendChild(annotatorEl);
              annotatorEl.style.cursor = 'crosshair';
              annotatorEl.classList.add('outline');

              start = new DOMPoint(event.clientX, event.clientY)
                  .matrixTransform(this.$refs.svg.getScreenCTM().inverse());
              annotatorEl.setAttribute('cx', start.x);
              annotatorEl.setAttribute('cy', start.y);
            },

            onmove: event => {
              let p = new DOMPoint(event.clientX, event.clientY)
                  .matrixTransform(this.$refs.svg.getScreenCTM().inverse())
              const r = Math.sqrt(
                  (p.x - start.x) * (p.x - start.x) +
                  (p.y - start.y) * (p.y - start.y)
              );

              annotatorEl.setAttribute('r', r);
              this.$emit('draw', event);
            },

            onend: event => {
              annotatorEl.style.cursor = null;
              annotatorEl.classList.remove('outline');
              this.annotations.appendChild(annotatorEl);
              this.$forceUpdate()
              this.$emit('draw-end', annotatorEl)
              annotatorEl.parentNode.removeChild(annotatorEl);
            }
          })
      } else {
        this.background.style.cursor = 'default';
        if (this.drawingable) this.drawingable.unset()
      }
    }
  }
}
