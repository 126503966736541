import {DocumentCollection} from '@/store/collection/document-collection';
import {db, decorateReference} from '@/firebase';

/**
 * All the information related to a list of people. Including the query used to collect that list and any metadata about
 * the list that we think we know - i.e. do we think there are more records to get.
 */
export class PeopleCollection extends DocumentCollection {
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {QueryClause[]} queryClauses The list of query clauses
   * @param {QueryOptions} options An optional list of query options
   */
  constructor(ns, queryClauses, options = {}) {
    super(ns, queryClauses);
    /**
     * A set of seen people references for de-duplicating search records into the people list.
     *
     * @type {Set<string>}
     */
    this.seenPeopleRefs = new Set();
    this.pageSize = options.pageSize;
  }


  /**
   * @override
   * @return {Promise<firebase.firestore.Query<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    const _db = await db;

    return _db.collection('ns/' + this.ns + '/search/people/records')
        .orderBy('person.title');
  }

  /**
   * Adds the given list of records to the records list.
   *
   * @override
   * @param {DecoratedData[]} records
   * @private
   */
  saveRecords(records) {
    this.records.push(...records);

    // save unique people
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.person && record.person.ref) {
        const key = record.person.ref.path;
        if (!this.seenPeopleRefs.has(key)) {
          this.seenPeopleRefs.add(key);
          const person = decorateReference(record.person);
          this.documents.push(person);
        }
      }
    }
  }
}
