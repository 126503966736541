import settings from '@/views/desk-booking/settings/settings';
import exportBookings from '@/views/desk-booking/export/desk-booking';

export default {
  namespaced: true,
  modules: {
    settings,
    exportBookings
  }
};
