<template>
  <v-row>
    <v-col cols="12">
      <document-autocomplete
          label="Default Floor"
          @input="internal.defaultFloor = $event"
          :items="siteFloors"
          :value="currentDefaultFloor"
          ref="defaultFloorAutoComplete"/>
      <v-autocomplete
          v-model="selectNeighbourhood"
          :items="neighbourhoodsWithExclusiveTags"
          label="Neighbourhoods"
          item-text="title"
          item-value="title"
          chips
          deletable-chips
          multiple
          persistent-hint
          hint="Type to search or select">
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title class="text--primary">
              <v-avatar :style="{ backgroundColor: item.color }" size="24">
                <template #default>
                  <div class="circle"/>
                </template>
              </v-avatar>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-row justify="end">
        <v-col class="text-right">
          <v-btn color="accent" @click="update" :loading="loading" :disabled="!changes">Update</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DocumentAutocomplete from '@/components/form/DocumentAutocomplete';

export default {
  name: 'PersonMembershipInfo',
  components: {DocumentAutocomplete},
  data() {
    return {
      loading: false,
      internal: {
        defaultFloor: undefined
      },
      selectNeighbourhood: []
    };
  },
  computed: {
    ...mapGetters('views/person', ['activeMembership']),
    ...mapGetters('floors', ['siteFloors']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['sortedNeighbourhoods']),
    currentDefaultFloor() {
      return this.activeMembership && this.activeMembership.defaultFloor;
    },
    currentNeighbourhood() {
      return this.activeMembership && this.activeMembership.neighbourhood;
    },
    neighbourhoodsWithExclusiveTags() {
      if (!this.sortedNeighbourhoods) return [];
      return this.sortedNeighbourhoods.map(neighbourhood => {
        if (neighbourhood.exclusive && !neighbourhood.title.includes('(exclusive)')) {
          return {
            ...neighbourhood,
            title: neighbourhood.title.concat(' (exclusive)')
          };
        }
        return neighbourhood;
      });
    },
    changes() {
      return this.defaultFloorChange() || this.neighbourhoodChange();
    }
  },
  created() {
    this.setupNeighbourhoodDefaults();
  },
  methods: {
    ...mapActions('views/person', ['createOrUpdateSiteMembership']),
    update() {
      this.loading = true;
      const toUpdate = {options: {}};
      if (this.defaultFloorChange()) {
        const defaultFloor = this.internal.defaultFloor;
        toUpdate.options.defaultFloor = {
          ref: defaultFloor.ref
        };
        if (defaultFloor.title) {
          toUpdate.options.defaultFloor.title = defaultFloor.title;
        }
      }
      if (this.neighbourhoodChange()) {
        const neighbourhood = this.selectNeighbourhood.map(title => {
          const neighbourhoodObj = this.neighbourhoodsWithExclusiveTags.find(n => n.title === title);
          return {title: neighbourhoodObj.title, ref: neighbourhoodObj.ref};
        });
        toUpdate.options.neighbourhood = neighbourhood;
      }
      this.createOrUpdateSiteMembership(toUpdate)
          .then(() => this.$notify.showSuccess(`Information updated`))
          .catch(err => {
            this.$logger.error('Error updating site information ' + this.activeSiteDoc.id, err);
            this.$notify.showError(`Error updating site information`);
          })
          .finally(() => this.loading = false);
    },
    defaultFloorChange() {
      if (!this.internal.defaultFloor) {
        return false;
      }
      const currentDefaultFloorPath = this.currentDefaultFloor ? this.currentDefaultFloor.ref.path : null;
      return currentDefaultFloorPath !== this.internal.defaultFloor.ref.path;
    },
    neighbourhoodChange() {
      const currentNeighbourhood = this.currentNeighbourhood;
      if (!currentNeighbourhood) return this.selectNeighbourhood.length > 0;
      const currentNeighbourhoodArray = Array.
          isArray(currentNeighbourhood) ? currentNeighbourhood : [currentNeighbourhood];
      if (this.selectNeighbourhood.length !== currentNeighbourhoodArray.length) return true;
      return this.selectNeighbourhood.some(title => {
        const neighbourhoodObj = this.neighbourhoodsWithExclusiveTags.find(n => n.title === title);
        const currentNeighbourhoodMatch = currentNeighbourhoodArray.find(n => n.title === title);
        return !currentNeighbourhoodMatch || neighbourhoodObj.ref.path !== currentNeighbourhoodMatch.ref.path;
      });
    },
    setupNeighbourhoodDefaults() {
      // Displays what neighbourhoods a user has. If a neighbourhood name has changed then it shows that too.
      if (this.currentNeighbourhood && this.neighbourhoodChange()) {
        const neighbourhoods = Array.isArray(this.currentNeighbourhood) ?
      this.currentNeighbourhood : [this.currentNeighbourhood];
        neighbourhoods.forEach(neighbourhood => {
          const overallNeighbourhood = this.neighbourhoodsWithExclusiveTags
              .find(n => n.ref.path === neighbourhood.ref.path);
          if (overallNeighbourhood) {
            neighbourhood.title = overallNeighbourhood.title;
          }
        });
        this.selectNeighbourhood = neighbourhoods.filter(neighbourhood => neighbourhood)
            .map(neighbourhood => neighbourhood.title);
      } else {
        this.selectNeighbourhood = [];
      }
    }
  }
};
</script>

<style scoped>
</style>
