<template>
  <v-tooltip bottom v-if="homeSite">
    <template #activator="{on}">
      <span v-on="on">, <v-icon class="mb-1">mdi-home</v-icon> {{ homeSite }}</span>
    </template>
    {{ title }}'s home site is {{ homeSite }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'PersonInfoHomeSite',
  props: {
    person: {
      type: Object,
      default: null
    }
  },
  computed: {
    title() {
      return this.person.displayName || this.person.title || 'This person';
    },
    homeSite() {
      const homeSite = this.person.homeSite;
      return homeSite ? homeSite.title : null;
    }
  }
};
</script>

<style scoped>

</style>
