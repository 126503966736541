<template>
  <component :is="to ? 'router-link' : 'div'" v-bind="bind" class="card-list-row">
    <div class="td" :style="styleFor('title')" v-if="containsHeader('title')">
      <span>{{ card.title ? card.title : '' }}</span>
    </div>
    <div class="td" :style="styleFor('ownerTitle')" v-if="containsHeader('ownerTitle')">
      <span v-if="hasOwner">{{ card.owner.title || '' }}</span>
      <span v-else class="empty">(not assigned)</span>
    </div>
    <div class="td text-right" :style="styleFor('enabledTo')" v-if="containsHeader('enabledTo')">
      <span v-if="!enabledToMoment"/>
      <v-tooltip v-else-if="expired" open-delay="1500" bottom>
        <template #activator="{on}">
          <span class="expired" v-on="on">
            {{ enabledToMoment.format('L LT') }}
            <v-icon right small color="error">mdi-alert</v-icon>
          </span>
        </template>
        This card has expired
      </v-tooltip>
      <v-tooltip v-else-if="expiresToday" open-delay="1500" bottom>
        <template #activator="{on}">
          <span class="expires-today" v-on="on">
            {{ enabledToMoment.format('L LT') }}
            <v-icon right small color="warning">mdi-clock</v-icon>
          </span>
        </template>
        This card expires today
      </v-tooltip>
      <span v-else>{{ enabledToMoment.format('L LT') }}</span>
    </div>
    <div class="td" :style="styleFor('role')" v-if="containsHeader('role')">
      <span class="text-capitalize">{{ card.role }}</span>
    </div>
    <div class="td" :style="styleFor('id')" v-if="containsHeader('id')">
      <span>{{ id }}</span>
    </div>
    <div class="td" :style="styleFor('returned')" v-if="containsHeader('returned')">
      <v-checkbox :input-value="!card.owner" :ripple="false" color="accent" readonly/>
    </div>
    <div class="td" :style="styleFor('edit')" v-if="containsHeader('edit')">
      <v-btn icon @click.prevent :to="{name: 'card-edit', params: {id: card.id}}">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
  </component>
</template>

<script>
import TemporaryCardView from '@/components/cards/TemporaryCardView';
import moment from 'moment';
import {CardIdTypeRef} from '@/util/card-type';
import {mapGetters} from 'vuex';

export default {
  name: 'CardsListRow',
  components: {TemporaryCardView},
  props: {
    card: {
      type: Object,
      default() {
        return {};
      }
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    to: {
      type: [Object, String],
      default: null
    },
    excludeTags: {
      type: [Function, Array],
      default: undefined
    }
  },
  computed: {
    ...mapGetters('sites/active', ['configCardIdType']),
    bind() {
      const bindings = {};
      if (this.to) {
        bindings.to = this.to;
      }
      return bindings;
    },
    id() {
      const card = this.card;
      if (!card) {
        return undefined;
      }
      // handle card cardIdType override
      if (card.cardIdType && card.cardIdType !== CardIdTypeRef && card.uids && card.uids[card.cardIdType]) {
        return card.uids[card.cardIdType];
      }
      // check site-level cardIdType
      if (this.configCardIdType !== CardIdTypeRef && card.uids && card.uids[this.configCardIdType]) {
        return card.uids[this.configCardIdType];
      }
      return card.id;
    },
    hasOwner() {
      return this.card && this.card.owner && this.card.owner.ref;
    },
    enabledToMoment() {
      if (!this.card || !this.card.enabledTo) {
        return null;
      }
      if (typeof this.card.enabledTo.toDate === 'function') {
        return moment(this.card.enabledTo.toDate());
      } else {
        return moment(this.card.enabledTo);
      }
    },
    expired() {
      if (!this.enabledToMoment) {
        return false;
      }
      return this.enabledToMoment.isBefore(moment());
    },
    expiresToday() {
      if (!this.enabledToMoment) {
        return false;
      }
      return this.enabledToMoment.isBetween(
          moment().subtract(1, 'day').endOf('day'),
          moment().add(1, 'day').startOf('day')
      );
    }
  },
  methods: {
    containsHeader(value) {
      return !!this.headers.find(h => h.value === value);
    },
    styleFor(value) {
      const h = this.headers.find(h => h.value === value);
      if (!h) {
        return {};
      }
      return {
        width: h.width
      };
    }
  }
};
</script>

<style scoped>
  .card-list-row {
    display: flex;
  }

  .td {
    font-size: 0.875rem;
    height: 52px;
    padding: 0 16px;
  }

  [href] {
    text-decoration: none;
    color: var(--text);
  }

  .card-list-row .td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .empty {
    opacity: 30%;
    pointer-events: none;
  }

  .expired {
    font-weight: bold;
    color: var(--v-error-base);
  }

  .expires-today {
    color: var(--v-warning-base);
  }
</style>
