/**
 * @param {string} email
 * @return {{domain: string, local: string}}
 */
export function emailParts(email) {
  const lastAt = email.lastIndexOf('@');
  const parts = {
    local: '',
    domain: ''
  };
  if (lastAt !== -1) {
    parts.local = email.substring(0, lastAt);
    parts.domain = email.substring(lastAt + 1);
  } else {
    parts.local = email;
  }
  return parts;
}
