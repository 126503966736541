<template>
  <div class="person-info-contact">
    <v-row v-for="(row, i) in rows" :key="i">
      <v-col cols="4" md="3" lg="2">{{ row.t }}:</v-col>
      <v-col cols="8" md="9" lg="10">
        <div v-for="(item, j) in row.v" :key="i +':' + j">
          {{ item.t }}
          <span class="text--secondary" v-if="item.type">- {{ item.type }}</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PersonInfoContact',
  props: {
    person: {
      type: Object,
      default: null
    }
  },
  computed: {
    rows() {
      if (!this.person) {
        return [];
      }

      const p = this.person;
      const rows = [];
      if (p.email) {
        rows.push({t: 'Email', v: [{t: p.email}]});
      }
      if (p.phones && p.phones.length > 0) {
        const numbers = p.phones
            .filter(pn => pn.number)
            .map(pn => ({t: pn.number, type: pn.type}));
        if (numbers.length > 0) {
          rows.push({t: 'Phone', v: numbers});
        }
      }
      return rows;
    }
  }
};
</script>

<style scoped>

</style>
