<template>
  <v-row dense>
    <v-col cols="12">
      <person-access-control-profile v-model="values.profile" :profiles="profiles"/>
      <person-access-control-pin v-if="pinSupport" v-model="values.pin" :enabled="hasRestrictedAccess"/>
      <v-row dense>
        <v-col cols="12" class="subtitle-1 font-weight-regular">
          Additional Permissions
        </v-col>
      </v-row>
      <permission-editor v-model="values.permissions" :available="permissions" :person="person"/>
      <v-row justify="end">
        <v-col class="text-right">
          <v-btn color="accent" @click="update" :disabled="noChanges" :loading="loading">Update</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PermissionEditor from '@/components/PermissionEditor';
import {mapActions, mapGetters} from 'vuex';
import PersonAccessControlProfile from '@/components/person/PersonAccessControlProfile';
import firebase from 'firebase/app';
import PersonAccessControlPin from '@/components/person/PersonAccessControlPin';

export default {
  name: 'PersonAccessControl',
  components: {
    PersonAccessControlPin,
    PersonAccessControlProfile,
    PermissionEditor
  },
  data() {
    return {
      loading: false,
      values: {
        profile: undefined,
        permissions: {},
        pin: undefined
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['hasAccessTo']),
    ...mapGetters('views/person', ['activeMembership', 'person']),
    ...mapGetters('sites', ['activeSiteDoc']),
    hasRestrictedAccess() {
      return this.hasAccessTo.people['access-control-restricted'];
    },
    accessControlOptions() {
      if (!this.activeSiteDoc) {
        return {};
      }
      return this.activeSiteDoc.accessControlOptions;
    },
    permissions() {
      if (!this.accessControlOptions || !this.accessControlOptions.permissions) {
        return [];
      }
      let perms = this.accessControlOptions.permissions;
      if (!this.hasRestrictedAccess) {
        perms = perms.filter(p => !p.restricted);
      }
      return perms.map(p => {
        return {
          v: p.value,
          t: p.title,
          h: p.description
        };
      });
    },
    profiles() {
      if (!this.accessControlOptions || !this.accessControlOptions.profiles) {
        return [];
      }
      return this.accessControlOptions.profiles;
    },
    pinSupport() {
      if (!this.accessControlOptions) {
        return false;
      }
      return Boolean(this.accessControlOptions.pinSupport);
    },
    current() {
      if (this.activeMembership && this.activeMembership.accessControl) {
        return this.activeMembership.accessControl;
      }
      return {};
    },
    personsPermissions() {
      return this.current.permissions ? this.current.permissions : {};
    },
    personsProfile() {
      return this.current.profile;
    },
    personsPin() {
      return this.current.pin;
    },
    permissionChanges() {
      return Object.keys(this.values.permissions)
          .filter(k => this.values.permissions[k] !== Boolean(this.personsPermissions[k]));
    },
    noChanges() {
      return this.personsProfile === this.values.profile &&
          this.permissionChanges.length === 0 &&
          this.personsPin === this.values.pin;
    }
  },
  watch: {
    personsPermissions: {
      handler(perms) {
        if (perms) {
          this.values.permissions = perms;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('views/person', ['createOrUpdateSiteMembership']),
    update() {
      if (this.noChanges) {
        return;
      }
      this.loading = true;
      const toUpdate = {options: {accessControl: this.current}};
      if (this.personsProfile !== this.values.profile) {
        const value = this.values.profile ? this.values.profile : firebase.firestore.FieldValue.delete();
        // you can't use firebase.firestore.FieldValue.delete() in a nested object
        toUpdate.options['accessControl.profile'] = value;
        delete toUpdate.options.accessControl.profile;
      }
      if (this.permissionChanges.length !== 0) {
        toUpdate.options.accessControl.permissions = this.values.permissions;
      }
      if (this.personsPin !== this.values.pin) {
        const value = this.values.pin ? this.values.pin : firebase.firestore.FieldValue.delete();
        // you can't use firebase.firestore.FieldValue.delete() in a nested object
        toUpdate.options['accessControl.pin'] = value;
        delete toUpdate.options.accessControl.pin;
      }
      this.createOrUpdateSiteMembership(toUpdate)
          .then(() => this.$notify.showSuccess(`Access control updated`))
          .catch(err => {
            this.$logger.error('Error updating access control ' + this.activeSiteDoc.id, err);
            this.$notify.showError(`Error updating access control`);
          })
          .finally(() => this.loading = false);
    }
  }
};
</script>

<style scoped>

</style>
