<template>
  <v-dialog max-width="600" v-model="open" persistent @keydown.esc="esc">
    <template #activator="{on}">
      <v-tooltip bottom open-delay="250">
        <template #activator="{on: onTooltip}">
          <v-btn icon v-on="{...onTooltip, ...on}" height="24" width="24">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>

        <span>Edit Delegates</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>Delegates</v-card-title>
      <v-card-subtitle>
        Assign people delegated access to perform actions on behalf of {{ personTitle }}.
      </v-card-subtitle>
      <v-card-text>
        <template v-for="(d, k) in sorted">
          <v-row :key="k">
            <v-col cols="3" class="d-flex align-center">
              <span class="title-align">{{ d.title || d.ref && d.ref.id }}</span>
            </v-col>
            <v-col cols="9" class="d-flex">
              <permission-editor
                  class="flex-fill"
                  v-model="d.permissions"
                  :available="permissions"
                  :person="d"
                  dense/>
              <v-tooltip bottom>
                <template #activator="{on}">
                  <v-btn icon small @click="remove(k)" v-on="on" class="delete-align">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <span>Remove {{ d.title || d.ref && d.ref.id }} from delegates</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="3" class="d-flex align-center">
            <span class="mb-3">Add a delegate</span>
          </v-col>
          <v-col cols="9">
            <person-search-field
                store-path="views/person/search"
                :error-messages="search.error ? [search.error] : null"
                v-model="search.value"
                @input="add"
                dense
                class="mt-0"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="close">Cancel</v-btn>
        <v-btn
            @click="save"
            class="primary-text--text"
            color="primary"
            :loading="saving"
            :disabled="!changes">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PermissionEditor from '@/components/PermissionEditor.vue';
import {isEqual} from 'lodash';
import PersonSearchField from '@/components/person/PersonSearchField.vue';
import {mapActions} from 'vuex';
import {byId} from '@/store/firestore-util';
import {byTitle} from '@/util/compare';

export default {
  name: 'PersonDelegatesEdit',
  components: {PersonSearchField, PermissionEditor},
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      delegates: {}, // work with a local copy
      open: false,
      search: {
        error: null,
        errorTimeout: 0,
        value: null
      },
      saving: false
    };
  },
  computed: {
    personId() {
      return byId(this.person);
    },
    personTitle() {
      return this.person && this.person.title;
    },
    existingDelegates() {
      return this.person && this.person.delegates;
    },
    permissions() {
      return [
        {
          v: 'deskBooking',
          t: 'Desk Booking',
          h: `Allow $\{name} to use the desk booking system on behalf of ${this.personTitle}`
        }
      ];
    },
    changes() {
      return !isEqual(this.existingDelegates, this.delegates);
    },
    sorted() {
      const s = {};
      const sorted = Object.values(this.delegates || {}).sort(byTitle);
      for (const d of sorted) {
        s[d.ref.id] = d;
      }
      return s;
    }
  },
  watch: {
    existingDelegates: {
      immediate: true,
      handler(e) {
        this.copyDelegates(e);
      }
    }
  },
  methods: {
    ...mapActions('views/person', ['updatePerson']),
    add() {
      if (this.search.value) {
        const person = this.search.value;
        const id = person.ref.id;
        if (id === this.personId) {
          this.setError(`${this.personTitle} can't be their own delegate`);
        } else if (this.delegates.hasOwnProperty(id)) {
          this.setError(`${person.title} is already a delegate`);
        } else {
          this.$set(this.delegates, id, {
            ref: person.ref,
            title: person.title,
            permissions: {}
          });
        }
        this.$nextTick(() => this.search.value = null);
      }
    },
    remove(k) {
      this.$delete(this.delegates, k);
    },
    close() {
      this.open = false;
      // reset edits
      this.copyDelegates(this.existingDelegates);
      clearTimeout(this.search.errorTimeout);
      this.search.error = null;
      this.search.value = null;
    },
    async save() {
      try {
        this.saving = true;
        const updates = {delegates: this.delegates};
        const ref = this.person.ref;
        // fix for `removeCircularReferences` making existing references invalid object
        for (const delegate of Object.values(updates.delegates)) {
          if (delegate.ref && delegate.ref[':path']) {
            delegate.ref = ref.firestore.doc(delegate.ref[':path']);
          }
        }
        Object.defineProperty(updates, 'ref', {
          enumerable: false,
          get() {
            return ref;
          }
        });
        await this.updatePerson(updates);
        this.close();
      } catch (e) {
        this.$logger.error('save', e);
        this.$notify.showError(`Error saving delegates: ` + e.message);
      } finally {
        this.saving = false;
      }
    },
    setError(msg) {
      this.search.error = msg;
      clearTimeout(this.search.errorTimeout);
      this.search.errorTimeout = setTimeout(() => {
        this.search.error = null;
      }, 5000);
    },
    copyDelegates(delegates) {
      const copy = {};
      if (delegates) {
        for (const [id, delegate] of Object.entries(delegates)) {
          const c = {...delegate};
          if (delegate.permissions) {
            c.permissions = {
              ...delegate.permissions
            };
          }
          copy[id] = c;
        }
      }
      this.delegates = copy;
    },
    esc() {
      if (!this.changes) {
        this.open = false;
      }
    }
  }
};
</script>

<style scoped>
.v-text-field,
.v-text-field >>> .v-label {
  font-size: 14px;
}
.title-align {
  margin-bottom: 1px;
}
.delete-align {
  margin-top: 2px;
}
</style>
