<template>
  <v-layout v-if="hasDeskBooking && hasAccessToDeskBooking" row justify-left align-center>
    <v-icon class="ml-3" left>mdi-desk</v-icon>
    <div>{{ nextBooking }}</div>
  </v-layout>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'PersonInfoNextBooking',
  props: {
    person: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('sites', ['activeSiteDoc']),
    ...mapGetters('views/person', ['activeBooking', 'activeMembership']),
    ...mapGetters('auth', ['hasAccessTo']),
    hasAccessToDeskBooking() {
      return this.hasAccessTo.deskBooking;
    },
    hasDeskBooking() {
      if (!this.person) return false;
      if (!this.activeMembership || !this.activeMembership.permissions) return false;
      return this.activeMembership.permissions.deskBooking;
    },
    nextBooking() {
      if (!this.person) {
        return '';
      }

      if (this.activeBooking) {
        if (!this.activeSiteDoc) return this.activeBooking.space.title;
        return `${this.activeBooking.space.title} - ${this.activeSiteDoc.title}`;
      }

      if (!this.activeSiteDoc) return 'No desk booked today at this site';
      return `No desk booked today at ${this.activeSiteDoc.title}`;
    }
  }
};
</script>

<style scoped>

</style>
