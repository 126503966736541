import {DocumentCollection} from '@/store/collection/document-collection';
import {db} from '@/firebase';

const skipOnlyProps = [
  'lastWrittenBy',
  'lastWrittenVia',
  'thirdParty'
];

/**
 * @param {string} p
 * @return {boolean}
 */
function ignoredPath(p) {
  return skipOnlyProps.some(prefix => p.startsWith(prefix));
}

export class ChangesCollection extends DocumentCollection {
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {QueryClause[]} queryClauses The list of query clauses
   */
  constructor(ns, queryClauses) {
    super(ns, queryClauses);
  }


  /**
   * @override
   * @return {Promise<firebase.firestore.Query<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    const _db = await db;
    return _db.collection(`ns/${this.ns}/search/changes/records`)
        .orderBy('change.changeTime', 'desc');
  }

  /**
   * @override
   * @param {DecoratedData[]} records
   * @private
   */
  saveRecords(records) {
    this.records.push(...records);

    // filter out changes will only touch lastWrittenBy or lastWrittenVia properties
    // filter out changes made by the inline-person-info function
    for (const record of records) {
      const triggeredVia = record.get('change.triggeredVia.ref');
      if (triggeredVia && triggeredVia.id === 'inline-person-info') {
        continue;
      }
      const paths = record.get('paths') || [];
      const skip = paths.every(ignoredPath);
      if (skip) {
        continue;
      }
      this.documents.push(record);
    }
  }
}
