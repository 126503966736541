import {incrementSuffix} from '@/util/suffix';

const numericCollator = new Intl.Collator('en', {numeric: true});

export class BookingUtil {
  /**
   * @param {DecoratedData & kahu.firestore.Booking} booking
   * @param {Object<string,*>} edits
   */
  constructor(booking, edits = {}) {
    this.booking = booking;
    this.edits = edits;
  }

  /**
   * @return {string[]}
   */
  get reservationKeys() {
    const reservations = this.booking.reservations || {};
    const keys = Object.keys(reservations);
    for (const k of Object.keys(this.edits)) {
      if (k.startsWith(`reservations.`)) {
        const suffix = k.replace('reservations.', '');
        const dotIndex = suffix.indexOf('.');
        if (dotIndex !== -1) {
          const key = suffix.slice(0, dotIndex);
          if (!keys.includes(key)) {
            keys.push(key);
          }
        }
      }
    }
    return keys;
  }

  /**
   * Get the last reservation key in the sorted list of keys
   *
   * Uses Intl.Collator to sort numbers as you'd expect: [r10, r6, r8] => [r6, r8, r10]
   *
   * @return {string|undefined}
   */
  get lastReservationKey() {
    const sorted = this.reservationKeys.sort(numericCollator.compare);
    if (sorted.length === 0) {
      return undefined;
    }
    return sorted[sorted.length - 1];
  }

  /**
   * @return {string}
   */
  get nextReservationKey() {
    const last = this.lastReservationKey;
    return incrementSuffix(last, 'r1');
  }
}
