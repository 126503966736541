<template>
  <div class="view-header">
    <div class="view-header__title display-1">
      <slot name="title">{{ title }}</slot>
    </div>
    <div class="view-header__subtitle title text--secondary" v-if="subtitle || $slots.subtitle">
      <slot name="subtitle">{{ subtitle }}</slot>
    </div>
    <div class="view-header__subtitle text--secondary" v-if="nextBooking || $slots.nextBooking">
      <slot name="nextBooking">{{ nextBooking }}</slot>
    </div>
    <div class="view-header__tags" v-if="tags || $slots.tags">
      <slot name="tags" :tags="tags">
        <template v-for="(tag, i) in tags">
          <slot name="tag" :tag="tag" :i="i" :tags="tags">
            <v-chip :key="i">{{ tag }}</v-chip>
          </slot>
        </template>
      </slot>
    </div>
    <div class="view-header__actions" v-if="$slots.actions">
      <slot name="actions"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    nextBooking: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: null
    }
  }
};
</script>

<style scoped>
  .view-header {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto;
  }

  .view-header__title,
  .view-header__subtitle,
  .view-header__tags {
    grid-column-start: 1;
  }

  .view-header__actions {
    grid-column-start: 2;
    grid-row: 1 / -1;
  }
</style>
