import desks from '@/views/dashboard/live-status/desks';
import rooms from '@/views/dashboard/live-status/rooms';
import {Logger} from '@vanti/vue-logger';

const log = Logger.get('live-status');

export default {
  namespaced: true,
  getters: {
    loaded(state, getters, rootState, rootGetters) {
      return getters['desks/loaded'] && getters['rooms/loaded'] && rootGetters['views/dashboard/counts/site/loaded'];
    }
  },
  actions: {
    bind({dispatch}) {
      dispatch('desks/bind')
          .catch(e => log.warn('failed to bind desks', e));
      dispatch('rooms/bind')
          .catch(e => log.warn('failed to bind rooms', e));
      dispatch('views/dashboard/counts/site/bind', null, {root: true})
          .catch(e => log.warn('failed to bind site counts', e));
    },
    unbind({dispatch}) {
      dispatch('desks/unbind')
          .catch(e => log.warn('failed to unbind desks', e));
      dispatch('rooms/unbind')
          .catch(e => log.warn('failed to unbind rooms', e));
      dispatch('views/dashboard/counts/site/unbind', null, {root: true})
          .catch(e => log.warn('failed to unbind site counts', e));
    }
  },
  modules: {
    desks,
    rooms
  }
};
