<script>
import {Bookable} from '@/store/bookable';
import {cleanDeskMarkup, scanDesk} from '@/store/svg-util';
import {mapGetters} from 'vuex';

export default {
  name: 'BookableDesk',
  props: {
    bookable: {
      type: Bookable,
      required: true
    },
    el: {
      type: SVGElement,
      default: null
    },
    parts: {
      type: Object,
      default: null
    },
    utilisationMap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /** @type {DeskParts} */
      localParts: {}
    };
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['allNeighbourhoods']),
    ...mapGetters('views/deskBooking/settings/desks', ['deskById']),
    editableDesk() {
      return this.deskById(this.bookable.id);
    },
    active() {
      return this.bookable.active;
    },
    disabled() {
      if (this.editableDesk) return this.editableDesk.disabled;
      return this.bookable.disabled;
    },
    booked() {
      return this.bookable.booked;
    },
    deskParts() {
      return this.parts || this.localParts;
    },
    chairColor() {
      const neighbourhood = this.editableDesk && this.editableDesk.neighbourhood;
      if (neighbourhood) {
        const deskNH = this.allNeighbourhoods.find(n => n.ref && n.ref.isEqual(neighbourhood.ref));
        if (deskNH) {
          return deskNH.color;
        }
      }
      return null;
    }
  },
  watch: {
    chairColor: {
      immediate: true,
      handler() {
        this.updateChairColor(this.chairColor);
      }
    },
    active: 'applyActive',
    disabled: 'applyDisabled',
    booked: 'applyBooked',
    el: {
      immediate: true,
      handler() {
        this.scanDesk();
        this.applyState();
      }
    }
  },
  methods: {
    scanDesk() {
      if (this.parts) return;
      this.localParts = scanDesk(this.el);
      cleanDeskMarkup(this.localParts);
    },
    applyState() {
      if (this.el) {
        if (!this.utilisationMap) {
          this.applyActive(this.active);
          this.applyDisabled(this.disabled);
          this.applyBooked(this.booked);
          this.updateChairColor(this.chairColor);
        } else {
          this.updateHeatmap();
        }
      }
    },
    applyActive(v) {
      if (this.el) {
        if (v) {
          this.el.classList.add('active');
        } else {
          this.el.classList.remove('active');
        }
      }
    },
    applyDisabled(v) {
      if (this.el) {
        if (v) {
          this.el.classList.add('disabled');
        } else {
          this.el.classList.remove('disabled');
        }
      }
    },
    applyBooked(v) {
      if (this.el) {
        if (v) {
          this.el.classList.add('booked');
        } else {
          this.el.classList.remove('booked');
        }
      }
    },
    updateChairColor(color) {
      const bookableDesk = this.el;
      if (bookableDesk) {
        bookableDesk.style.setProperty('--chair', color);
      }
    },
    updateHeatmap() {
      const bookableDesk = this.el;
      const percentage = Math.floor(Math.random() * 100) + 1;
      let color = '';

      if (percentage > 88) color = '#FF5352';
      else if (percentage > 77 && percentage <= 88) color = '#FE794A';
      else if (percentage > 66 && percentage <= 77) color = '#FD9645';
      else if (percentage > 55 && percentage <= 66) color = '#FCB541';
      else if (percentage > 44 && percentage <= 55) color = '#FBD33C';
      else if (percentage > 33 && percentage <= 44) color = '#DCD465';
      else if (percentage > 22 && percentage <= 33) color = '#BCD590';
      else if (percentage > 11 && percentage <= 22) color = '#9AD6BC';
      else color = '#6AD8FC';

      if (bookableDesk) {
        bookableDesk.style.setProperty('--color', color);
        this.el.classList.add('heatmap');
      }
    }
  },
  render() {
    return this.$slots.default;
  }
};
</script>

<style >
.heatmap > g > rect.tableFill {
  opacity: 0.7!important;
}
</style>
