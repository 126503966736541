<template>
  <v-card>
    <v-card-title class="text-h5 warning lighten-2">
      <v-icon left>mdi-alert-circle</v-icon>
      Change Neighbourhood?
    </v-card-title>
    <v-card-text class="mt-4">
      This desk is currently already in another neighbourhood.
      Are you sure you want to move <b>{{ desk.title }}</b>
      from <b>{{ oldNeighbourhood.title }}</b> to <b>{{ neighbourhood.title }}</b>?
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-checkbox v-model="dontShow"/>
      <span @click="dontShow = !dontShow" style="cursor: pointer">Don't ask again</span>
      <v-spacer/>
      <v-btn
          color="error"
          text
          @click="noChange">
        No
      </v-btn>
      <v-btn
          color="success"
          :loading="loading"
          @click="addDeskFromOther">
        Move
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  name: 'ConfirmNeighbourhoodChangeDialog',
  props: {
    desk: {
      type: Object,
      required: true
    },
    neighbourhood: {
      type: Object,
      required: true
    },
    oldNeighbourhood: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dontShow: false
    };
  },
  watch: {
    dontShow(v) {
      this.updateDontShow(v);
    }
  },
  methods: {
    ...mapMutations('selection', ['removeSelection']),
    updateDontShow(v) {
      this.$emit('dont-show', v);
    },
    close() {
      this.$emit('close-dialog');
    },
    noChange() {
      this.removeSelection(this.desk);
      this.close();
    },
    async addDeskFromOther() {
      try {
        this.loading = true;
        // eslint-disable-next-line vue/no-mutating-props
        this.desk.neighbourhood = this.neighbourhood;
      } catch (e) {
        this.$logger.error(e);
      } finally {
        this.loading = false;
        this.$emit('close-dialog');
      }
    }
  }
};
</script>

<style scoped>

</style>
