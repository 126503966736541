export const HighlightTags = [
  'expired',
  'inactive'
];

export const WarningTags = [
  'non-transferable'
];
export const SuccessTags = [
  'transferable'
];

export default {
  methods: {
    tagColor(tag) {
      if (HighlightTags.includes(tag)) {
        return 'error';
      }
      if (WarningTags.includes(tag)) {
        return 'warning';
      }
      if (SuccessTags.includes(tag)) {
        return 'success';
      }
      return '#EAEAEA';
    }
  }
};
