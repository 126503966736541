<template>
  <v-container fluid class="pa-0">
    <v-toolbar height="56">
      <v-spacer/>
      <v-form @submit.prevent="performSearch" class="action-form" ref="searchForm">
        <search-settings v-model="searchText" @search="performSearch">
          <v-checkbox
              label="Search all sites"
              hide-details
              class="mt-0"
              color="accent"
              v-model="searchAllSites"/>
          <v-checkbox
              label="Include non-transferable cards"
              hide-details
              class="mt-0"
              color="accent"
              v-model="includeNonTransferable"/>
        </search-settings>
        <card-scan-search
            v-model="cardScan.visible"
            @scan="findCard"
            :alert-type="cardScan.type"
            :alert-message="cardScan.message"
            :searching="cardScan.searching"/>
      </v-form>
    </v-toolbar>
    <cards-list-table :items="cards"/>
    <div v-observe-visibility="observerOptions"/>
    <v-card :loading="loadingInfo.loading" flat tile>
      <v-alert v-if="loadingInfo.text" :color="loadingInfo.color" :type="loadingInfo.type" tile text class="ma-0">
        {{ loadingInfo.text }}
        <span v-if="loadingInfo.noRecords && searchText">
          when searching for <b>{{ searchText }}</b>
        </span>
      </v-alert>
      <div v-if="loadingInfo.noRecords && searchText" class="ml-4 mt-4">
        <p class="mt-5">Suggestions:</p>
        <ul>
          <li>Check your spelling</li>
          <li>Try fewer search words</li>
          <li v-if="!search.hasOwnProperty('all-sites')">
            Search across <a @click="searchAllSites = true; performSearch()">all sites</a>
          </li>
          <li v-if="!search.hasOwnProperty('nont')">
            Include <a @click="includeNonTransferable = true; performSearch()">non-transferable cards</a>
          </li>
        </ul>
      </div>
    </v-card>
    <v-tooltip top>
      <template #activator="{on}">
        <v-btn v-on="on" fab bottom right fixed color="secondary" :to="{name: 'new-card'}">
          <v-icon>mdi-card-plus</v-icon>
        </v-btn>
      </template>
      <span>Add a card</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import CardScanSearch from '@/components/cards/CardScanSearch';
import SearchSettings from '@/components/SearchSettings';
import CardsListTable from '@/views/people/cards/CardsListTable';
import {ObserveVisibility} from 'vue-observe-visibility';
import {mapActions, mapGetters} from 'vuex';

/**
 * A list of the query properties we support and will act upon.
 *
 * @type {Set<string>}
 */
const queryProperties = new Set([]);

export default {
  name: 'CardsList',
  components: {SearchSettings, CardScanSearch, CardsListTable},
  directives: {ObserveVisibility},
  props: {
    search: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // we should attempt to fetch more records, assuming we think that will be successful
      itemsNeedLoading: true,
      localData: {
        searchText: null,
        nonTransferable: null,
        allSites: null
      },
      cardScan: {
        visible: false,
        searching: false,
        message: '',
        type: undefined
      },
      observerOptions: {
        callback: this.handleIntersect,
        intersection: {
          rootMargin: '100px'
        }
      }
    };
  },
  computed: {
    ...mapGetters('views/cards', ['loadingInfo', 'cards']),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('auth', ['hasAuthUser']),
    queryClauses() {
      const clauses = [];

      const search = this.search || {};
      if (!search.hasOwnProperty('all-sites') && this.activeSiteId !== '') {
        clauses.push(['sites', 'array-contains', this.activeSiteId]);
      }

      if (search.nont === 'all') {
        // don't add the non-transferable filter
      } else if (search.nont) {
        clauses.push(['nonTransferable', '==', Boolean(search.nont)]);
      } else {
        clauses.push(['nonTransferable', '==', false]);
      }

      Object.entries(search).forEach(([k, v]) => {
        if (queryProperties.has(k)) {
          clauses.push([k, '==', v]);
        }
      });

      // special keywords handling
      if (search.q) {
        const parts = search.q.toLowerCase().split(/\s/g);
        if (parts.length === 1) {
          clauses.push(['keywords', 'array-contains', parts[0]]);
        } else if (parts.length > 1) {
          clauses.push(['keywords', 'array-contains-any', parts]);
        }
      }

      return clauses;
    },
    searchText: {
      get() {
        if (this.localData.searchText !== null) {
          return this.localData.searchText;
        }

        if (this.search && this.search.q) {
          return this.search.q;
        }

        return '';
      },

      set(val) {
        this.localData.searchText = val;
      }
    },
    searchAllSites: {
      get() {
        if (this.localData.allSites !== null) {
          return this.localData.allSites;
        }

        return this.search && this.search.hasOwnProperty('all-sites');
      },
      set(v) {
        this.localData.allSites = v;
      }
    },
    includeNonTransferable: {
      get() {
        if (this.localData.nonTransferable !== null) {
          return this.localData.nonTransferable;
        }

        return this.search && this.search.hasOwnProperty('nont');
      },
      set(v) {
        this.localData.nonTransferable = v;
      }
    }
  },
  watch: {
    queryClauses: {
      deep: true,
      handler(clauses) {
        if (!this.hasAuthUser) return;
        this.getRecords(clauses);
      },
      immediate: true
    },
    search: {
      deep: true,
      handler() {
        // remove any local data if the incoming search properties change, they are always more important
        Object.keys(this.localData).forEach(k => this.localData[k] = null);
      }
    },
    'cardScan.visible'(v) {
      if (v) {
        this.cardScan.searching = false;
        this.cardScan.message = '';
        this.cardScan.type = undefined;
      }
    }
  },
  methods: {
    ...mapActions('views/cards', ['bind', 'load', 'stop']),
    getRecords(clauses) {
      this.$logger.debug('getRecords', clauses);
      this.bind(clauses)
          .then(() => {
            if (this.itemsNeedLoading) {
              return this.load();
            }
          })
          .catch(err => this.$logger.warn('Failed bind after query change', err));
    },
    /**
     * @param {boolean} isVisible
     */
    handleIntersect(isVisible) {
      this.itemsNeedLoading = isVisible;
      if (this.itemsNeedLoading) {
        this.load();
      } else {
        this.stop();
      }
    },
    performSearch() {
      if (!this.search || this.searchText !== this.search.keywords) {
        const query = {...this.$route.query};

        if (this.searchText) {
          query['q'] = this.searchText;
        } else {
          delete query['q'];
        }

        if (this.includeNonTransferable) {
          query['nont'] = 'all';
        } else {
          delete query['nont'];
        }

        if (this.searchAllSites) {
          query['all-sites'] = true;
        } else {
          delete query['all-sites'];
        }

        this.$router.push({query}).catch(() => {
          this.$logger.debug('aborted');
        });
      }
    },
    findCard(id) {
      this.$logger.debug('findCard', id);
      this.cardScan.searching = true;
      this.$store.dispatch('views/cards/getByUid', {uid: id})
          .then(card => {
            this.cardScan.searching = false;
            if (!card.exists) {
              this.cardScan.message = `This card hasn't been added to the system.`;
              return;
            }

            this.cardScan.visible = false;
            this.$router.push({name: 'card', params: {id: card.id}});
          })
          .catch(err => {
            this.cardScan.type = 'error';
            this.cardScan.message = 'There was an error, please scan again';
            this.$logger.error('Unable to find card', err);
          })
          .finally(() => this.cardScan.searching = false);
    }
  }
};
</script>

<style scoped>
  .toolbar {
    border-bottom: var(--v-grey-base) 1px solid;
  }

  .action-form {
    /* Width of last two table columns - toolbar right padding + input left padding */
    width: calc(45% - 16px + 8px);
    min-width: 20em;
    display: flex;
    align-items: center;
  }

  .action-form > :last-child {
    margin-left: 8px;
    /*
    By default an icon button is 48px tall. The form will be 46px tall after the toolbar padding is subtracted. This
    adjusts the button so that it is once again centred in the form
    */
    margin-top: -2px;
  }
</style>
