<template>
  <span>
    <span v-if="returned">
      <v-checkbox
          class="ma-0"
          hide-details
          dense
          label="Returned"
          :input-value="true"
          readonly
          :ripple="false"
          color="accent"/>
    </span>
    <v-btn v-else color="accent" :loading="loading" @click="setReturnedTo">Return</v-btn>
  </span>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'CardReturned',
  props: {
    card: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    returned() {
      if (!this.card) {
        return false;
      }
      return this.card.returned;
    }
  },
  methods: {
    ...mapActions('views/card', [
      'removeOwnership'
    ]),
    setReturnedTo() {
      this.loading = true;
      this.removeOwnership()
          .then(() => {
            this.$notify.showSuccess('Card returned');
          })
          .catch(err => {
            this.$logger.error(err);
            this.$notify.showError(`Could not return card`);
          })
          .finally(() => this.loading = false);
    }
  }
};
</script>

<style scoped>
  .v-input--is-readonly,
  .v-input--is-readonly >>> input,
  .v-input--is-readonly >>> .v-label {
    cursor: default;
  }
</style>
