import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';
import 'firebase/storage';

const hosted = location.hostname !== 'localhost' && process.env.NODE_ENV !== 'development';
const emulated = location.hostname === 'localhost' && process.env.NODE_ENV !== 'development';

/**
 * @return {Promise<firebase.app.App>}
 */
async function initFirebase() {
  let config = {
    apiKey: 'AIzaSyBPhpbtbgM9ptNJFenlhLkKq8Hyy6666jc',
    appId: '1:614972188804:web:0660afb5ebd30cc51ffad1',
    authDomain: 'vanti-cloud.firebaseapp.com',
    databaseURL: 'https://vanti-cloud.firebaseio.com',
    measurementId: 'G-QV8MK53RG4',
    messagingSenderId: '614972188804',
    projectId: 'vanti-cloud',
    storageBucket: 'vanti-cloud.appspot.com'
  };
  if (hosted) {
    config = await fetch('/__/firebase/init.json').then(r => r.json());
    if (config.authDomain === 'kahu-work-prod.firebaseapp.com' ||
        config.authDomain === 'vanti-cloud.firebaseapp.com') {
      // The auth domain needs to match the host domain so third party cookies aren't blocked.
      // We'd configure this via the firebase console but that doesn't appear to be allowed.
      config.authDomain = location.hostname;
    }
  } else if (emulated) {
    // if we're using emulated mode, make sure we don't connect to anything
    config = {
      apiKey: 'AIzaSyBPhpbtbgM9ptNJFenlhLkKq8Hyy6666jc',
      appId: '1:614972188804:web:0660afb5ebd30cc51ffad1',
      projectId: 'vanti-cloud',
      storageBucket: 'vanti-cloud.appspot.com'
    };
  }
  const app = firebase.initializeApp(config);
  if (hosted) {
    // initialise the analytics
    app.analytics();
  } else if (emulated) {
    const db = (process.env.FIRESTORE_EMULATOR_HOST || '127.0.0.1:5001').split(':');
    const storage = (process.env.FIREBASE_STORAGE_EMULATOR_HOST || '127.0.0.1:5002').split(':');
    const functions = (process.env.FIREBASE_FUNCTIONS_EMULATOR_HOST || '127.0.0.1:5003').split(':');
    const auth = process.env.FIREBASE_AUTH_EMULATOR_HOST || '127.0.0.1:9099';
    firebase.firestore().useEmulator(db[0], parseInt(db[1]));
    firebase.storage().useEmulator(storage[0], parseInt(storage[1]));
    firebase.auth().useEmulator(`http://${auth}`);
    firebase.functions().useEmulator(functions[0], parseInt(functions[1]));
  }
  return app;
}

export const app = initFirebase();
const applyVueDevtoolsFix = process.env.NODE_ENV !== 'production';

/** @type {Promise<firebase.auth.Auth>} */
export const auth = app.then(app => app.auth());
/** @type {Promise<firebase.firestore.Firestore>} */
export const db = app.then(app => app.firestore());
/** @type {Promise<firebase.storage.Storage>} */
export const storage = app.then(app => app.storage());
/** @type {Promise<firebase.functions.Functions>} */
export const functions = app.then(app => {
  const region = emulated ? undefined : 'europe-west1';
  return app.functions(region);
});

export const dbUtil = {
  /**
   * doc returns a promise that resolves to the document reference specified by 'path'
   *
   * @param {string} path
   * @return {Promise<DocumentReference>}
   */
  doc(...path) {
    return db.then(db => db.doc(path.join('/')));
  },
  /**
   * @param {string} path
   * @return {Promise<CollectionReference>}
   */
  collection(...path) {
    return db.then(db => db.collection(path.join('/')));
  }
};

export const storageUtil = {
  async loadFileText(path) {
    const api = await storage;
    const downloadUrl = await api.ref(path).getDownloadURL();
    const res = await fetch(downloadUrl);
    return await res.text();
  }
};

export const functionUtil = {
  async callable(name, opts) {
    const f = await functions;
    return f.httpsCallable(name, opts);
  },
  personDeleteApi() {
    return functionUtil.callable('callable-person-delete');
  },
  neighbourhoodDeleteApi() {
    return functionUtil.callable('callable-neighbourhood-delete');
  },
  userCreateApi() {
    return functionUtil.callable('callable-user-create');
  },
  userExistsForPersonApi() {
    return functionUtil.callable('callable-user-existsForPerson');
  },
  bookingExportApi() {
    return functionUtil.callable('callable-booking-export');
  },
  userDeleteApi() {
    return functionUtil.callable('callable-user-delete');
  }
};

export const authProviders = {
  google: new firebase.auth.GoogleAuthProvider(),
  microsoft: new firebase.auth.OAuthProvider('microsoft.com')
};

/**
 * Copy metadata properties from one snapshot to another.
 *
 * @param {firebase.firestore.DocumentSnapshot} from
 * @param {Object} to
 */
function copyProperties(from, to) {
  Object.defineProperty(to, 'raw', {value: from, enumerable: false});
  Object.defineProperty(to, 'ref', {value: removeCircularReferences(from.ref), enumerable: false});
  Object.defineProperty(to, 'id', {value: from.id, enumerable: false});
  Object.defineProperty(to, 'exists', {value: from.exists, enumerable: false});
  Object.defineProperty(to, 'get', {value: from.get.bind(from), enumerable: false});
}

/**
 * Makes all DocumentReferences in the nested object have their firestore properties non-enumerable.
 *
 * @param {*} obj
 * @param {boolean} [force] - force replace, even when applyVueDevtoolsFix is false
 */
export function replaceReferences(obj, force = false) {
  if (!force && !applyVueDevtoolsFix) return;
  if (obj && typeof obj === 'object') {
    for (const key of Object.keys(obj)) {
      const val = obj[key];
      if (val instanceof firebase.firestore.DocumentReference) {
        removeCircularReferences(val);
      } else {
        replaceReferences(val, force);
      }
    }
  }
}

/**
 * Given a firestore DocumentReference, make all properties non-enumerable which fixes circular references
 *
 * @param {firebase.firestore.DocumentReference} ref
 * @return {firebase.firestore.DocumentReference}
 */
export function removeCircularReferences(ref) {
  if (!ref || ref.hasOwnProperty(':path')) return ref; // already safe
  for (const [key, value] of Object.entries(ref)) {
    Object.defineProperty(ref, key, {
      enumerable: false,
      writable: false,
      value
    });
  }
  Object.defineProperty(ref, ':path', {
    enumerable: true,
    value: ref.path
  });
  return ref;
}

/**
 * Extracts the data from the snapshot but adds the ref and id properties to it.
 *
 * @param {firebase.firestore.DocumentSnapshot<T>} snapshot
 * @return {DecoratedData<T>}
 * @template T
 */
export function decorateSnapshot(snapshot) {
  const doc = snapshot.data() || {};
  copyProperties(snapshot, doc);
  replaceReferences(doc);
  return /** @type {DecoratedData} */ doc;
}

/**
 * Add properties to the given object to make it look more like a root document.
 *
 * @param {T} reference
 * @param {firebase.firestore.DocumentReference} [ref]
 * @return {T}
 * @template T
 */
export function decorateReference(reference, ref) {
  if (reference) {
    if (ref) {
      Object.defineProperty(reference, 'ref', {value: ref, enumerable: false});
    } else {
      ref = reference.ref;
    }
    Object.defineProperty(reference, 'id', {value: ref.id, enumerable: false});
    Object.defineProperty(reference, 'exists', {value: true, enumerable: false});
  }
  return reference;
}

const snippetProps = ['kind', 'title', 'displayName', 'department'];

/**
 * @typedef {Object} DocumentSnippet
 * @property {firebase.firestore.DocumentReference} ref
 * @property {string} [title]
 * @property {string} [displayName]
 */
/**
 * Creates an embeddable snipped from the given document
 *
 * @param {DecoratedData} doc
 * @param {string[]} [props]
 * @return {DocumentSnippet|null}
 */
export function createSnippet(doc, props = snippetProps) {
  if (!doc) return null;
  const res = {
    ref: removeCircularReferences(doc.ref)
  };
  for (const prop of props) {
    if (prop in doc) res[prop] = doc[prop];
  }

  return res;
}

/**
 * Create an independent copy of the given data.
 *
 * @param {T} data
 * @param {boolean} [replaceRefs] - avoid circular references from DocumentReferences
 * @return {T}
 * @template T
 */
export function cloneDecoratedData(data, replaceRefs = false) {
  const clone = simpleClone(data, val => {
    return !(val instanceof firebase.firestore.DocumentReference);
  });
  copyProperties(data, clone);
  if (replaceRefs) {
    replaceReferences(clone, true);
  }
  return clone;
}

/**
 * Performs a non-cycle aware deep clone of the given object. The deep function should return true for any value that
 * we should clone.
 *
 * @param {T} data
 * @param {function(*):boolean} deep
 * @return {T}
 * @template T
 */
function simpleClone(data, deep) {
  if (data === null) {
    return null;
  }
  switch (typeof data) {
    case 'bigint':
    case 'boolean':
    case 'function':
    case 'number':
    case 'string':
    case 'symbol':
    case 'undefined':
    default:
      return data;
    case 'object':
      if (deep && !deep(data)) {
        return data;
      }
      if (Array.isArray(data)) {
        return data.map(e => simpleClone(e, deep));
      }

      if (data instanceof firebase.firestore.Timestamp) {
        return new firebase.firestore.Timestamp(data.seconds, data.nanoseconds);
      }

      // special case some objects
      const res = {};
      Object.entries(data).forEach(([k, v]) => {
        res[k] = simpleClone(v, deep);
      });
      return res;
  }
}


/**
 * Converts a {@link firebase.firestore.UpdateData} into an array of alternating keys and values.
 * Useful if your properties contain special characters like `/`.
 *
 * @param {firebase.firestore.UpdateData} updates
 * @return {any[]}
 */
export function updateDataToArray(updates) {
  if (Array.isArray(updates)) return /** @type {Array} */ updates;
  const res = [];
  for (const [key, value] of Object.entries(updates)) {
    res.push(new firebase.firestore.FieldPath(...key.split('.')));
    res.push(value);
  }
  return res;
}
