import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import {decorateSnapshot} from '@/firebase';
import {LoadingUtil} from '@/util/loading';

const log = Logger.get('integrations');

export default {
  namespaced: true,
  state: {
    integrations: {},
    ...LoadingUtil.state()
  },
  getters: {
    ...LoadingUtil.getters(log),
    thirdPartyCollection(state, getters, rootState, rootGetters) {
      const ns = rootGetters['ns/nsRef'];
      if (!ns) {
        return;
      }
      return ns.collection('thirdparty');
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    ...LoadingUtil.mutations(),
    setIntegrations(state, i) {
      state.integrations = i;
    }
  },
  actions: {
    bind({commit, getters}) {
      const thirdParty = getters['thirdPartyCollection'];
      if (!thirdParty) {
        return;
      }
      const ref = thirdParty.doc('integrations');
      const defer = {};
      commit('loading', 'integrations');
      defer.integrations = ref.onSnapshot(
          doc => {
            commit('setIntegrations', decorateSnapshot(doc));
            commit('loaded', 'integrations');
          },
          err => {
            log.error('integrations.onSnapshot', err);
            commit('loaded', 'integrations');
          }
      );
      commit('defer', defer);
    },
    unbind({commit}) {
      commit('reset');
      commit('setIntegrations', {});
    }
  }
};
