<template>
  <div class="caption grey--text text--lighten-3 text-center">
    © Kahu Limited | {{ versionName }}
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'AboutLine',
  computed: {
    ...mapGetters('versions', [
      'versionName'
    ])
  }
};
</script>

<style scoped>

</style>
