<template>
  <v-list-item class="resource-day-item available" two-line>
    <v-list-item-content>
      <v-list-item-subtitle class="overflow-visible">
        <span>{{ overline }}</span>
      </v-list-item-subtitle>
      <v-list-item-title class="flex-grow-1 flex-fill">{{ title }}</v-list-item-title>
      <v-list-item-subtitle>
        <span>{{ createdVia }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-chip v-if="chip" small :color="chip.color" class="my-n1 overflow-visible mr-1">{{ chip.text }}</v-chip>
      <v-menu offset-y left nudge-bottom="4px">
        <template #activator="{on, attr}">
          <v-btn icon v-bind="attr" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card min-width="200px">
          <v-list>
            <v-list-item @click="abortBooking">{{ actionText }} Booking</v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from 'moment';
import {minDate, toDate} from '@/util/dates';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'BookingListItem',
  props: {
    booking: {
      type: [Object],
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('bookings/forSelectedDesk', ['now']),
    title() {
      if (!this.booking || !this.booking.owner) {
        return 'Booking';
      }
      return this.booking.owner.title;
    },
    createdVia() {
      if (!this.booking || !this.booking.createdVia) return null;
      return `Created Via: ${this.booking.createdVia.title}`;
    },
    checkedIn() {
      if (!this.booking) return false;
      if (this.booking.requiresCheckIn) {
        if (!this.booking.checkIn || !this.booking.checkIn.fromTime) return false;
        return toDate(this.booking.checkIn.fromTime) <= this.now;
      }
      return toDate(this.booking.fromTime) <= this.now;
    },
    bounds() {
      const booking = this.calcBounds(this.booking);
      const checkIn = this.calcBounds(this.booking && this.booking.checkIn);
      return {
        fromTime: minDate(booking.fromTime, checkIn.fromTime),
        toTime: minDate(booking.toTime, checkIn.toTime)
      };
    },
    actionText() {
      if (this.checkedIn) {
        return 'Release';
      } else {
        return 'Cancel';
      }
    },
    overline() {
      return this.time;
    },
    bookedToday() {
      const f = moment(this.bounds.fromTime);
      const now = moment(this.now);
      return f.isSame(now, 'day');
    },
    time() {
      const bounds = this.bounds;
      const f = moment(bounds.fromTime);
      const t = moment(bounds.toTime);
      const today = moment(this.now);
      let time = '';
      if (f.isSame(today, 'day')) {
        time = f.format('LT');
      } else {
        time = f.calendar({
          lastDay: '[Yesterday] LT',
          lastWeek: '[Last] dddd LT',
          sameDay: '[Today]',
          nextDay: '[Tomorrow] LT',
          nextWeek: 'dddd LT',
          sameElse: 'L LT'
        });
      }

      if (t) {
        if (t.isSame(f, 'day')) {
          time += ` - ${moment(t).format('LT')}`;
        } else {
          time += ' - ';
          time += t.calendar({
            sameDay: '[Today] LT',
            lastDay: '[Yesterday] LT',
            lastWeek: '[Last] dddd',
            nextDay: '[Tomorrow] LT',
            nextWeek: 'dddd LT',
            sameElse: 'L LT'
          });
        }
      }
      return time;
    },
    chip() {
      if (this.checkedIn) {
        if (this.booking && this.booking.requiresCheckIn) {
          return {
            text: 'Checked In',
            color: 'success'
          };
        } else {
          return {
            text: 'Active',
            color: 'success'
          };
        }
      } else if (this.bookedToday && this.booking && this.booking.requiresCheckIn) {
        return {
          text: 'Not Checked In',
          color: 'warning'
        };
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions('bookings', ['releaseOrCancel']),
    async abortBooking() {
      const booking = this.booking;
      if (!booking) return;
      this.loading = true;
      try {
        const undo = await this.releaseOrCancel({booking, release: this.checkedIn});
        this.$notify.postNotification({
          message: `Booking ${this.checkedIn ? 'released' : 'cancelled'}`,
          color: 'success',
          timeout: 2000,
          show: true,
          action: {
            text: 'Undo',
            run: async () => {
              try {
                await undo();
              } catch (e) {
                this.$notify.showError(`Error during undo`);
                this.$logger.error(`error during undo`, e);
              }
            }
          }
        });
      } catch (e) {
        this.$notify.showError(`Error deleting booking`);
        this.$logger.error(`error deleting booking`, e);
      } finally {
        this.loading = false;
      }
    },
    calcBounds(o) {
      if (!o) return {fromTime: false, toTime: false};
      return {fromTime: o.fromTime && toDate(o.fromTime), toTime: o.toTime && toDate(o.toTime)};
    }
  }
};
</script>

<style scoped>
.resource-day-item__status {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.resource-day-item >>> .v-list-item__subtitle > * {
}

.resource-day-item >>> .v-list-item__subtitle {
  display: flex;
  align-items: baseline;
}

.resource-day-item >>> .v-list-item__title {
  font-size: 110%;
}
</style>
