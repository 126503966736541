<template>
  <v-card outlined>
    <v-list class="px-2 py-0">
      <template v-for="(site, i) in editableSites">
        <v-list-item
            :key="site.id"
            class="enable-pointers px-2"
            @click="site.value = !site.value">
          <v-list-item-content>
            <v-list-item-title>{{ site.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox
                :input-value="site.value"
                :disabled="!editable"
                color="accent darken-1"/>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="(i + 1) !== sites.length" :key="site.id + ':divider'"/>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import {isEqual} from 'lodash';
import {mapGetters} from 'vuex';

export default {
  name: 'UserSitesEditor',
  props: {
    authUserIsAdmin: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sites: []
    };
  },
  computed: {
    ...mapGetters('views/user', {originalUser: 'user'}),
    ...mapGetters('sites', {allSites: 'sitesById'}),
    ...mapGetters('user', ['userSiteIds']),
    userSites() {
      return this.originalUser && this.originalUser.sites;
    },
    selectedSites() {
      return this.sites.filter(s => s.value).map(s => s.id);
    },
    sitesHaveChanged() {
      return !isEqual(this.selectedSites, this.userSites);
    },
    allSelected() {
      return this.sites.every(s => s.value);
    },
    editableSites() {
      return this.sites.filter(s => s.editable)
          .sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
          });
    }
  },
  watch: {
    originalUser: {
      // Don't need deep:true here as the backing model will replace the object on change anyway
      immediate: true,
      handler: 'copyFields'
    },
    allSelected: {
      immediate: true,
      handler() {
        this.$emit('all-selected', this.allSelected);
      }
    },
    sitesHaveChanged: {
      immediate: true,
      handler() {
        this.$emit('changes', this.sitesHaveChanged);
      }
    },
    selectedSites: {
      immediate: true,
      handler() {
        this.$emit('sites', this.selectedSites);
      }
    }
  },
  methods: {
    copyFields(data) {
      this.sites = [];
      if (data) {
        if (data.exists) {
          const sites = data.hasOwnProperty('sites') ? data.sites : [];
          for (const id of Object.keys(this.allSites)) {
            // non-admins can only edit sites they have access to, but we need to keep the values
            // of the other sites for when the document is updated
            const editable = this.authUserIsAdmin ? true : this.userSiteIds.includes(id);
            this.sites.push({
              id,
              editable,
              title: this.allSites[id].title,
              value: sites.includes(id)
            });
          }
        }
      }
    },
    setAll(to) {
      for (let i = 0; i < this.sites.length; i++) {
        this.$set(this.sites[i], 'value', to);
      }
    },
    resetSelected() {
      this.copyFields(this.originalUser);
    }
  }
};
</script>

<style scoped>

</style>
