export default {
  install(Vue) {
    Vue.directive('resize-observer', this);
  },
  bind(el, {value}) {
    if (typeof ResizeObserver === 'function') {
      const ro = new ResizeObserver(entries => value(entries));
      el.dataset.ro = ro;
      ro.observe(el);
    }
  },
  unbind(el) {
    if (!el.dataset.ro) {
      el.dataset.ro.unobserve(el);
      delete el.data.ro;
    }
  }
};
