<template>
  <v-autocomplete
      dense
      single-line
      :items="sites"
      item-text="title"
      item-value="ref"
      placeholder="(none)"
      clearable
      :loading="loading"
      @change="setProperty"
      :value="property"/>
</template>

<script>
import {formSingle} from '@/components/form/form';
import firebase from 'firebase/app';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PersonEditHomeSite',
  mixins: [formSingle()],
  data() {
    return {
      loading: false,
      siteRefs: []
    };
  },
  computed: {
    ...mapGetters('sites', ['sitesById']),
    ...mapGetters('views/person', ['person']),
    sites() {
      const sites = [];
      for (const siteRef of this.siteRefs) {
        const id = siteRef.id;
        sites.push(this.sitesById[id]);
      }
      return sites;
    }
  },
  mounted() {
    this.loadSites();
  },
  methods: {
    ...mapActions('views/person', ['fetchMembershipSiteRefs']),
    async loadSites() {
      if (!this.person) {
        return;
      }
      this.loading = true;
      try {
        this.siteRefs = await this.fetchMembershipSiteRefs(this.person);
      } catch (e) {
        this.$logger.error('loadSites', e);
      } finally {
        this.loading = false;
      }
    },
    setProperty(ref) {
      if (ref) {
        const site = this.sitesById[ref.id];
        this.internalValue = {
          ref,
          title: site.title
        };
      } else {
        this.internalValue = firebase.firestore.FieldValue.delete();
      }
    }
  }
};
</script>

<style scoped>

</style>
