import {db} from '@/firebase';
import views from '@/views/views';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Vuex from 'vuex';

import app from './app';
import appConfig from './app-config';
import auth from './auth';
import bookables from './bookables';
import bookings from './bookings';
import cardReader from './card-reader';
import config from './config';
import floors from './floors';
import ns from './ns';
import resources from '@/store/resources';

import fetchAppConfig from './plugins/fetch-app-config';
import selection from './selection';
import sites from './sites';
import user from './user';
import versions from './versions';

import {Logger} from '@vanti/vue-logger';
import vueTime, {onTheMinute} from '@vanti/vue-time';

const log = Logger.get('store');

Vue.use(Vuex);
Vue.use(Vuelidate);

const store = new Vuex.Store({
  state: {
    loading: false,
    dialog: {show: false}
  },
  getters: {
    dialog(state) {
      return Object.assign({
        show: true,
        title: '',
        body: '',
        color: '',
        confirm: () => {
          store.commit('hideDialog');
        },
        cancel: () => {
          store.commit('hideDialog');
        }
      }, state.dialog);
    },
    ns(state, getters) {
      return getters['auth/ns'];
    },
    nsRef(state, getters) {
      return 'ns/' + getters.ns;
    },
    isDefaultNs(state, getters) {
      return getters['auth/ns'] !== '-';
    }
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    showDialog(state, config) {
      state.dialog = config;
    },
    hideDialog(state) {
      state.dialog = {show: false};
    }
  },
  actions: {
    init() {
    },
    onAuthStateChanged(context, authUser) {
    },
    /**
     * Takes the supported description of ref path, id or ref DocRef and
     * returns a document reference.
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} opts
     * @param {string} opts.collection
     * @param {*} opts.doc
     * @param {boolean} [opts.withNs]
     * @return {Promise<*>}
     */
    async resolveRef({getters}, {collection, doc, withNs = true}) {
      const firestore = await db;
      // we support by ref path, id, or ref DocRef. Work out which one we have...
      let docRef = doc.ref;
      if (!docRef) {
        if (withNs) {
          docRef = `ns/${getters.ns}/${collection}/${doc.id}`;
        } else {
          docRef = `${collection}/${doc.id}`;
        }
      }
      if (typeof docRef === 'string') {
        docRef = firestore.doc(docRef);
      } // note assumes if not a string then it's a DocumentReference already!
      return docRef;
    }
  },
  modules: {
    app,
    appConfig,
    auth,
    bookables,
    bookings,
    cardReader,
    config,
    floors,
    ns,
    resources,
    selection,
    sites,
    user,
    versions,
    views
  },
  plugins: [
    fetchAppConfig,
    vueTime({interval: onTheMinute})
  ]
});

store.dispatch('init')
    .catch(err => log.error('in store init', err));

window.store = store;

export default store;
