import {currentDate} from '@/util/dates';

/**
 *
 * @param {Date[]} ticks
 * @param {function(Date)} cb Called each tick with the current time
 * @param {function():Date} [now=currentDate] Returns the current time
 * @return {function()} A function to cancel the schedule
 */
export function scheduleTicks(ticks, cb, now = currentDate) {
  const n = ticks.length;
  let handle = 0;
  let i = 0;
  const scheduleNext = time => {
    cb(time);

    // find the first time that is after now AND after the last time we ran
    let nextTick = null;
    for (; i < n; i++) {
      if (ticks[i] > time) {
        nextTick = ticks[i];
        break;
      }
    }
    if (nextTick) {
      const delay = nextTick - time;
      handle = setTimeout(() => {
        // use the clock time instead of the tick time as setTimeout could be late executing
        scheduleNext(now());
      }, delay);
    }
  };
  scheduleNext(now());
  return () => clearTimeout(handle);
}
