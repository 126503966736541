<template>
  <v-text-field
      class="search-settings"
      type="search"
      placeholder="Search"
      :value="value"
      @change="textChange"
      outlined
      hide-details
      dense>
    <template #append v-if="$slots.default">
      <v-menu
          offset-y
          left
          nudge-bottom="8"
          nudge-right="8"
          content-class="elevation-1"
          :close-on-content-click="false"
          min-width="300">
        <template #activator="{on: onMenu}">
          <v-tooltip bottom>
            <template #activator="{on: onTooltip}">
              <v-icon style="color: inherit" v-on="{...onMenu, ...onTooltip}">mdi-cog-box</v-icon>
            </template>
            <span>Search settings</span>
          </v-tooltip>
        </template>
        <v-card outlined>
          <v-card-text>
            <slot/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn type="submit" text @click="performSearch">Search</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'SearchSettings',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    performSearch() {
      this.$emit('search');
    },
    textChange(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style>
  /*noinspection CssMissingComma*/
  .search-settings.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-inner {
    /*
    The padding for an icon places it at 7px from the top. This is incorrect as icons are 24px tall and the input is
    40px tall. The top should be 8px from the top
    */
    margin-top: 8px;
  }

  /*
   * The color styles of the border for an input field are derived from the currentColor of the input. Unfortunately
   * the color for the hover state is set at the fieldset level instead of at the parent. This means we can't use it
   * directly to colour the append icon. Here is the exact style used by fieldset but copied into the append-inner
   * element.
   */
  /*noinspection CssMissingComma*/
  .search-settings.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control > .v-input__slot .v-input__append-inner {
    color: rgba(0, 0, 0, 0.38);
  }

  /*noinspection CssMissingComma*/
  .search-settings.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control > .v-input__slot:hover .v-input__append-inner {
    color: rgba(0, 0, 0, 0.86);
  }
</style>
