<template>
  <v-container fluid class="pa-0">
    <v-toolbar height="56">
      <v-spacer/>
      <v-form @submit.prevent="performSearch" class="action-form" ref="searchForm">
        <search-settings v-model="searchText" @search="performSearch">
          <v-subheader class="pl-0">Filter By Role</v-subheader>
          <v-checkbox
              v-for="role of filterRoles"
              hide-details
              class="mt-0"
              color="accent"
              v-model="roles"
              :value="role.key"
              :label="role.title"
              :key="role.key"/>
        </search-settings>
      </v-form>
    </v-toolbar>
    <user-table :items="users"/>
    <div v-observe-visibility="observerOptions"/>
    <v-card :loading="loadingInfo.loading" flat tile>
      <v-alert v-if="loadingInfo.text" :color="loadingInfo.color" :type="loadingInfo.type" tile text class="ma-0">
        {{ loadingInfo.text }}
        <span v-if="loadingInfo.noRecords && searchText">
          when searching for <b>{{ searchText }}</b>
        </span>
      </v-alert>
      <div v-if="loadingInfo.noRecords && searchText" class="ml-4 mt-4">
        <p class="mt-5">Suggestions:</p>
        <ul>
          <li>Ensure that all names are spelled correctly</li>
          <li>Try the first or last name only</li>
        </ul>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import SearchSettings from '@/components/SearchSettings';
import UserTable from '@/components/users/UserTable';
import {ObserveVisibility} from 'vue-observe-visibility';
import {mapActions, mapGetters, mapState} from 'vuex';

/**
 * A list of the query properties we support and will act upon.
 *
 * @type {Set<string>}
 */
const queryProperties = new Set([]);

export default {
  name: 'UsersList',
  components: {UserTable, SearchSettings},
  directives: {ObserveVisibility},
  props: {
    search: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // we should attempt to fetch more records, assuming we think that will be successful
      itemsNeedLoading: true,
      localData: {
        searchText: null,
        allSites: null,
        active: null
      },
      cardScan: {
        visible: false,
        searching: false,
        message: '',
        type: undefined
      },
      observerOptions: {
        callback: this.handleIntersect,
        intersection: {
          rootMargin: '100px'
        }
      },
      roles: []
    };
  },
  computed: {
    ...mapState('appConfig', {appRoles: 'roles'}),
    ...mapGetters('sites', ['activeSiteDoc']),
    ...mapGetters('views/users', ['users', 'loadingInfo']),
    filterRoles() {
      const roles = [];
      for (const group of this.appRoles) {
        for (const role of group.roles) {
          const title = group.title === 'General' ? role.title : `${group.title} - ${role.title}`;
          roles.push({title, key: role.key});
        }
      }
      return roles;
    },
    queryClauses() {
      const clauses = [];
      const search = this.search || {};
      Object.entries(search).forEach(([k, v]) => {
        if (queryProperties.has(k)) {
          clauses.push([k, '==', v]);
        }
      });

      // special keywords handling
      if (search.q) {
        const parts = search.q.toLowerCase().split(/\s/g);
        if (parts.length === 1) {
          clauses.push(['keywords', 'array-contains', parts[0]]);
        } else if (parts.length > 1) {
          clauses.push(['keywords', 'array-contains-any', parts]);
        }
      }

      // check for roles
      if (this.roles.length > 0) {
        clauses.push(['user.roles', 'array-contains-any', this.roles]);
      }

      return clauses;
    },
    searchText: {
      get() {
        if (this.localData.searchText !== null) {
          return this.localData.searchText;
        }

        if (this.search.q) {
          return this.search.q;
        }

        return '';
      },

      set(val) {
        this.localData.searchText = val;
      }
    },
    searchAllSites: {
      get() {
        if (this.localData.allSites !== null) {
          return this.localData.allSites;
        }

        return this.search.hasOwnProperty('all-sites');
      },
      set(v) {
        this.localData.allSites = v;
      }
    }
  },
  watch: {
    queryClauses: {
      deep: true,
      handler(clauses) {
        this.getRecords(clauses);
      },
      immediate: true
    },
    search: {
      deep: true,
      handler() {
        // remove any local data if the incoming search properties change, they are always more important
        Object.keys(this.localData).forEach(k => this.localData[k] = null);
      }
    }
  },
  methods: {
    ...mapActions('views/users', ['bind', 'load', 'stop']),
    getRecords(clauses) {
      this.$logger.debug('getRecords', clauses);
      this.bind(clauses)
          .then(() => {
            if (this.itemsNeedLoading) {
              return this.load();
            }
          })
          .catch(err => this.$logger.warn('Failed bind after query change', err));
    },
    /**
     * @param {boolean} isVisible
     */
    handleIntersect(isVisible) {
      this.itemsNeedLoading = isVisible;
      if (this.itemsNeedLoading) {
        this.load();
      } else {
        this.stop();
      }
    },
    performSearch() {
      if (this.searchText !== this.search.keywords) {
        const query = {...this.$route.query, q: this.searchText};
        if (!this.searchText) {
          delete query.q;
        }
        if (this.searchAllSites) {
          query['all-sites'] = true;
        } else {
          delete query['all-sites'];
        }

        this.$router.push({query}).catch(() => {
          this.$logger.debug('aborted');
        });
      }
    }
  }
};
</script>

<style scoped>
  .action-form {
    /* Width of last two table columns - toolbar right padding + input left padding */
    width: calc(45% - 16px + 8px);
    min-width: 20em;
    display: flex;
    align-items: center;
  }

  .action-form > :last-child {
    margin-left: 8px;
    /*
    By default an icon button is 48px tall. The form will be 46px tall after the toolbar padding is subtracted. This
    adjusts the button so that it is once again centred in the form
    */
    margin-top: -2px;
  }
</style>
