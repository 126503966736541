import {Logger} from '@vanti/vue-logger';
import {createStatisticsStore} from '@/views/dashboard/statistics/statistics-store';

const log = Logger.get('statistics/desks');

const getCountsQuery = (site, chunk, countType = 'booking') => {
  return site.ref.collection('counts')
      .where('countType', '==', countType)
      .where('onDate', 'in', chunk);
};

const module = createStatisticsStore(
    log,
    getCountsQuery,
    'views/dashboard/counts/site/deskCapacity',
    'floors/siteFloorIds',
    'views/dashboard/statistics/desks'
);

/**
 * This store is used for getting v1 counts for desks
 * v2 counts are stored at ns/{ns}/site/{site}/counts
 */
export default {
  namespaced: true,
  state: module.state,
  getters: {
    ...module.getters // spreading like this helps IDEA pick up the modules
  },
  mutations: {
    ...module.mutations
  },
  actions: {
    ...module.actions
  },
  modules: {
    ...module.modules
  }
};
