export const ResourceSuffix = 'R';
export const CapacitySuffix = 'C';

export const PropNames = {
  reservedResource: 'r' + ResourceSuffix,
  reservedAdHocResource: 'ra' + ResourceSuffix,
  checkedInResource: 'c' + ResourceSuffix,
  occupiedResource: 'o' + ResourceSuffix,
  reservedCapacity: 'r' + CapacitySuffix,
  reservedAdHocCapacity: 'ra' + CapacitySuffix,
  checkedInCapacity: 'c' + CapacitySuffix,
  occupiedCapacity: 'o' + CapacitySuffix
};

export const reservedResource = PropNames.reservedResource;
export const reservedAdHocResource = PropNames.reservedAdHocResource;
export const checkedInResource = PropNames.checkedInResource;
export const occupiedResource = PropNames.occupiedResource;
export const reservedCapacity = PropNames.reservedCapacity;
export const reservedAdHocCapacity = PropNames.reservedAdHocCapacity;
export const checkedInCapacity = PropNames.checkedInCapacity;
export const occupiedCapacity = PropNames.occupiedCapacity;
