import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';
import {toEditableCircle} from '@/views/desk-booking/settings/map/editable-circle';
import {EditableUtil} from '@/util/editable-util';
import {db} from '@/firebase';

const log = Logger.get('desk-booking/settings/circles');

export default {
  namespaced: true,
  state: {
    /**
     * Should circles be shown on the map if they are present?
     *
     * @type {boolean}
     */
    previewMode: false,
    /**
     * Is the user currently in edit-circles mode?
     *
     * @type {boolean}
     */
    editMode: false,
    /**
     * Array of all circles for current floor
     *
     * @type {EditableCircle[]}
     */
    circles: [],
    /**
     * Selected circle used in the components
     *
     * @type {EditableCircle}
     */
    circle: null,
    /**
     * Keyed by circle id, each edit represents changes to a circle.
     *
     * @type {Object<string,EditableCircle>}
     */
    edits: {},
    /**
     * When a new circle is added, we put it here before committing the change.
     *
     * @type {EditableCircle[]}
     */
    added: [],
    /**
     * When a circle is deleted, add it to the array
     *
     * @type {EditableCircle[]}
     */
    deleted: []
  },
  getters: {
    allCircles(state) {
      const circles = state.circles.filter(c => !c.deleted);
      return circles.concat(state.added);
    },
    includeDeletedCircles(state) {
      return state.circles.concat(state.added);
    },

    allCirclesByFloor(state) {
      return floor => {
        const circles = state.circles.filter(c => c.floor === floor.ref.path && !c.deleted);
        return circles.concat(state.added.filter(c => c.floor === floor.ref.path));
      };
    },
    allCircleDocsByFloorRef(state) {
      return floorRef => {
        const circles = state.circles.filter(c => c.floor === floorRef && !c.deleted);
        return circles.concat(state.added.filter(c => c.floor === floorRef))
            .map(c => {
              return {cx: c.cx, cy: c.cy, r: c.r, fill: c.fill};
            });
      };
    },
    includeDeletedCirclesByFloor(state) {
      return floor => {
        const circles = state.circles.filter(c => c.floor === floor.ref.path);
        return circles.concat(state.added.filter(c => c.floor === floor.ref.path));
      };
    },

    circle(state) {
      return state.circle;
    },
    hasCircleChanges(state) {
      return state.added.length > 0 || Object.values(state.edits).length > 0 || state.deleted.length > 0;
    },
    circleById(state, getters) {
      return id => {
        return getters.allCircles.find(c => c.id === id);
      };
    },
    changesByCircleId(state) {
      return id => {
        if (state.edits[id]) return state.edits[id];
        return null;
      };
    },
    isCircleDeleted(state) {
      return id => {
        return Boolean(state.deleted.find(c => c.id === id));
      };
    },

    floorsWithCircleChanges(state, getters) {
      const floors = [];
      for (const [id] of Object.entries(state.edits)) {
        if (!floors.includes(getters.circleById(parseInt(id)).floor)) {
          floors.push(getters.circleById(parseInt(id)).floor);
        }
      }
      for (const circle of state.added) {
        if (!floors.includes(circle.floor)) {
          floors.push(circle.floor);
        }
      }
      for (const circle of state.deleted) {
        if (!floors.includes(circle.floor)) {
          floors.push(circle.floor);
        }
      }
      return floors;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    ...EditableUtil.mutations(),
    clearLocalState(state) {
      state.deleted = [];
      state.added = [];
      state.edits = {};
    },
    resetCircles(state) {
      state.circles = [];
    },
    setCircle(state, circle) {
      Vue.set(state, 'circle', circle);
    },
    setPreviewMode(state, val) {
      if (val) {
        state.circle = null;
        state.editMode = false;
      }
      state.previewMode = val;
    },
    setEditMode(state, val) {
      if (val) state.previewMode = false;
      else state.circle = null;
      state.editMode = val;
    },
    setCircles(state, circles) {
      state.circles = circles;
    },

    addCircle(state, circle) {
      state.added.push(circle);
    },
    deleteCircle(state, circle) {
      const index = state.added.findIndex(c => c.id === circle.id);
      if (index !== -1) state.added.splice(index, 1);
      state.deleted.push(circle);
    },
    resetEdit(state, circle) {
      Vue.delete(state.edits, circle.id);

      // handle the case where the circle has been deleted
      const deletedIndex = state.deleted.findIndex(e => e.id === circle.id);
      if (deletedIndex >= 0) {
        state.deleted.splice(deletedIndex, 1);
      }
      // handle the case where the edit is part of a new circle
      const addedIndex = state.added.findIndex(e => e.id === circle.id);
      if (addedIndex >= 0) {
        state.added.splice(addedIndex, 1);
      }
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (authUser) {
          return dispatch('fetchCircles');
        } else {
          return dispatch('clear');
        }
      }
    },
    async fetchCircles({state, commit, dispatch, rootGetters}) {
      if (DeferUtil.hasDefer(state)) {
        // already bound
        return;
      }
      const defer = {};
      defer.floorUpdated = this.watch(
          () => rootGetters['floors/siteFloors'],
          floors => {
            if (!floors) {
              return dispatch('clear');
            }
            dispatch('getCirclesForSite')
                .catch(err => log.error('During desks fetch', err));
          }
      );
      commit('defer', defer);
    },

    async clear({dispatch, commit}) {
      await dispatch('getCirclesForSite');
      commit('clearLocalState');
    },

    getCirclesForSite(context) {
      let i = 0;
      const allCircles = [];
      for (const floor of context.rootGetters['floors/siteFloors']) {
        if (floor && floor.neighbourhoodCircles) {
          const circles = floor.neighbourhoodCircles.circles;
          if (circles) {
            allCircles.push(...circles.map(c => {
              c.id = i;
              c.floor = floor.ref.path;
              i++;
              return toEditableCircle(context, c);
            }));
          }
        }
      }
      context.commit('setCircles', allCircles);
    },

    createNewCircle(context, circle) {
      const editableCircle = toEditableCircle(context, circle);
      context.commit('addCircle', editableCircle);
      context.commit('recordEdit', {document: editableCircle});
      return editableCircle;
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {BatchGroup} batchGroup
     * @return {Promise<void>}
     */
    async saveCircles({commit, getters}, batchGroup) {
      const firestore = await db;
      for (const floorRef of getters.floorsWithCircleChanges) {
        const circles = getters.allCircleDocsByFloorRef(floorRef);
        batchGroup.update(firestore.doc(floorRef), {'neighbourhoodCircles.circles': circles});
      }
      commit('setPreviewMode', true);
    }
  }
};
