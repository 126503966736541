import Vue from 'vue';
import {isSameDocument, replacedCardDoc} from '@/views/people/person/person-util';
import {db, decorateSnapshot} from '@/firebase';
import {Logger} from '@vanti/vue-logger';
import moment from 'moment';
import cardHistory from './card-history';
import {CardIdTypeRef} from '@/util/card-type';

const log = Logger.get('card');

/**
 * Represents the active card, i.e. the card page.
 */
export default {
  namespaced: true,
  state: {
    loaded: false,
    card: null,
    memberships: {},
    watchers: {}
  },
  getters: {
    card(state) {
      return state.card;
    },
    cardExists(state) {
      return state.card && state.card.exists;
    },
    loaded(state) {
      return state.loaded;
    },
    hasOwner(state) {
      if (!state.card) {
        return false;
      }
      return state.card.owner;
    },
    memberships(state) {
      // sorted by the reference path
      const sorted = Object.entries(state.memberships);
      sorted.sort(([k1], [k2]) => {
        if (k1 < k2) {
          return -1;
        }
        if (k2 < k1) {
          return 1;
        }
        return 0;
      });
      return sorted.map(([_, v]) => v);
    },
    activeMembership(state, getters, rootState, rootGetters) {
      const activeSiteId = rootGetters['sites/activeSiteId'];
      return getters.memberships.find(m => {
        // check site ref
        return m.ref.parent.parent.id === activeSiteId;
      });
    },
    notYetValid(state) {
      if (!state.card) {
        return false;
      }
      if (!state.card.hasOwnProperty('enabledFrom')) {
        return false;
      }
      const now = moment();
      const enabledFrom = moment(state.card.enabledFrom.toDate());
      return enabledFrom.isAfter(now);
    },
    hasExpired(state) {
      if (!state.card) {
        return false;
      }
      if (!state.card.hasOwnProperty('enabledTo')) {
        return false;
      }
      const now = moment();
      const enabledTo = moment(state.card.enabledTo.toDate());
      return enabledTo.isBefore(now);
    }
  },
  mutations: {
    setCard(state, card) {
      state.card = card;
      state.loaded = true;
    },
    updateMemberships(state, membershipUpdates) {
      membershipUpdates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            Vue.set(state.memberships, update.doc.ref.path, decorateSnapshot(update.doc));
            break;
          case 'removed':
            Vue.delete(state.memberships, update.doc.ref.path);
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {Object.<string,function>} watchers
     */
    registerWatchers(state, watchers) {
      state.watchers = {...state.watchers, ...watchers};
    },
    resetMemberships(state) {
      state.memberships = {};
    },
    clear(state) {
      state.loaded = false;
      state.card = null;
      state.watchers = {};
      state.memberships = {};
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (!authUser) {
          dispatch('unbind');
        }
      }
    },
    /**
     * Load the card given by the card argument and watch for changes. The card should have an id or ref field,
     * for the bind to do anything.
     *
     * If you bind a card that matches (id or ref match) the currently bound card then nothing happens.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} card
     * @return {Promise<*>}
     */
    async bind({state, commit, dispatch}, card) {
      // don't rebind if setting the same card
      if (isSameDocument(state.card, card)) {
        return;
      }

      if (!card || (!card.ref && !card.id)) {
        // assume they want to clear the active card, in which case we're done
        // we setCard here so that the loading state is set
        commit('setCard', null);
        return;
      }

      await dispatch('unbind');

      const cardRef = await dispatch('resolveRef', {doc: card, collection: 'cards'}, {root: true});
      log.debug('bind', cardRef);
      const watchers = {};
      watchers.card = cardRef.onSnapshot(
          cardSnapshot => commit('setCard', decorateSnapshot(cardSnapshot)),
          err => log.error('cardRef.onSnapshot', err)
      );
      commit('registerWatchers', watchers);
    },
    /**
     * Load the card owners memberships for the current selected site.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<*>}
     */
    async bindMembership({getters, rootGetters, commit, dispatch}, person) {
      const firestore = await db;
      const personRef = await dispatch('views/person/resolvePersonRef', person, {root: true});

      // clear memberships in preparation for site specific to be pulled in
      await dispatch('unbindMembership');
      const ns = rootGetters.ns;
      const activeSiteId = rootGetters['sites/activeSiteId'];

      if (activeSiteId) {
        const watchers = {};
        watchers.memberships = firestore.collection(`ns/${ns}/sites/${activeSiteId}/members`)
            .where('ns', '==', ns)
            .where('person.ref', '==', personRef)
            .onSnapshot(
                membershipQuerySnap => commit('updateMemberships', membershipQuerySnap.docChanges()),
                err => log.error('memberships.onSnapshot', err));

        commit('registerWatchers', watchers);
        log.debug('bindMembership');
      } else {
        log.warn('bindMembership, no active site');
      }
    },
    unbindMembership({state, commit}) {
      if (state.watchers.memberships) {
        state.watchers.memberships(); // stop listening
      }
      commit('resetMemberships');
    },
    unbind({state, commit}) {
      Object.values(state.watchers).forEach(watcher => watcher());
      commit('clear');
    },

    /**
     * Remove ownership properties from the card
     *
     * @param {import('vuex').ActionContext} context
     * @return {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*>}
     */
    removeOwnership({state, rootGetters}) {
      if (!state.card) {
        throw new Error('no card to edit');
      }
      const auditSnippet = rootGetters['user/auditSnippet'];
      return state.card.ref.update({
        ...replacedCardDoc(),
        ...auditSnippet
      });
    },

    /**
     * Add or update the card
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} card
     * @return {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*>}
     */
    async updateCard({state, rootGetters}, card) {
      // Note: because we are bound to the card (or should be), we don't need to re-fetch or event commit changes to
      // our local card as firestore will do this for us.

      const auditSnippet = rootGetters['user/auditSnippet'];
      if (card.ref && typeof card.ref.update === 'function') {
        await card.ref.update({
          ...card,
          ...auditSnippet
        });
        return card.ref;
      } else {
        log.debug('updateCard', card);
        const fb = await db;
        const cards = fb.collection('ns/' + rootGetters.ns + '/cards');
        const siteCardIdType = rootGetters['sites/active/configCardIdType'];
        const copy = {
          ...card,
          ...auditSnippet
        };
        let cardRef = cards.doc(card.id);
        if (siteCardIdType !== CardIdTypeRef) {
          cardRef = cards.doc();
          copy.uids = {};
          copy.uids[siteCardIdType] = card.id;
        }
        delete copy.id;
        await cardRef.set(copy);
        return cardRef;
      }
    },

    /**
     * Check whether the card with the given ID already exists in the database
     *
     * @param {import('vuex').ActionContext} context
     * @param {string} id
     * @return {Promise<boolean>}
     */
    async cardExists({state, rootGetters, dispatch}, id) {
      const card = await dispatch('views/cards/getByUid', {uid: id}, {root: true});
      return card && card.exists;
    }
  },
  modules: {
    history: cardHistory
  }
};
