<template>
  <v-menu v-model="menuOpen" :close-on-content-click="false" offset-y :min-width="360">
    <template #activator="{on: onMenu}">
      <v-tooltip bottom :disabled="fullButton">
        <template #activator="{on}">
          <v-btn
              :icon="!fullButton"
              :class="{'primary-text--text': fullButton}"
              :color="fullButton ? 'primary' : ''"
              v-on="{...on, ...onMenu}">
            <v-icon :left="fullButton">mdi-credit-card-multiple</v-icon>
            <span v-if="fullButton">Replace</span>
          </v-btn>
        </template>
        <span>Replace Card</span>
      </v-tooltip>
    </template>
    <card-scan v-if="scanCard" v-model="cardId" :disabled="!menuOpen"/>
    <v-card v-else>
      <v-card-title>
        Replace Card
        <v-spacer/>
        <span class="grey--text text--lighten-3">{{ cardName }}</span>
      </v-card-title>
      <v-card-text class="subtitle-1">
        <v-select class="mt-4" dense outlined label="Reason for replacement" :items="reasons" v-model="reason"/>
        Are you sure you want to replace {{ name }}'s card?
        <card-ownership-check class="mt-2" :card-id="cardId"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="menuOpen = false">No</v-btn>
        <v-btn color="accent" @click="replace">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import CardOwnershipCheck from '@/components/cards/CardOwnershipCheck';
import CardScan from '@/components/cards/CardScan';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'PermanentCardReplace',
  components: {CardOwnershipCheck, CardScan},
  props: {
    fullButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuOpen: false,
      cardId: '',
      cardData: null,
      reason: 'Lost',
      scanCard: true,
      reasons: [
        'Broken',
        'Lost',
        'Stolen'
      ]
    };
  },
  computed: {
    ...mapGetters('views/person', ['person']),
    ...mapState('views/person', ['permCard']),
    name() {
      return this.person.displayName || this.person.title;
    },
    cardName() {
      return (this.cardData && this.cardData.title) || this.cardId;
    }
  },
  watch: {
    cardId(id) {
      this.cardData = null;
      if (id) {
        this.scanCard = false;
        this.getByUid({uid: id})
            .then(card => {
              if (card) {
                this.cardData = card;
              }
            });
      }
    },
    menuOpen(v) {
      if (v) {
        this.scanCard = true;
        this.cardId = '';
      }
    }
  },
  methods: {
    ...mapActions('views/person', ['replacePermCard']),
    ...mapActions('views/cards', ['getByUid']),
    replace() {
      this.replacePermCard({newId: this.cardId, reason: this.reason, targetCardData: this.cardData})
          .then(() => this.menuOpen = false)
          .then(() => this.$notify.showSuccess('Card replaced'))
          .catch(err => this.$logger.error('Error replacing card', err));
    }
  }
};
</script>

<style scoped>

</style>
