/**
 * Listen for snapshot changes, awaiting the first snapshot response.
 * Returns the snapshot unsubscribe function.
 *
 * @param {firebase.firestore.DocumentReference} ref
 * @param {(snapshot: firebase.firestore.DocumentSnapshot<T>) => void} onNext
 * @param {(error: Error) => void} onError
 * @return {Promise<void>}
 */
export async function awaitFirstSnapshot(ref, onNext, onError) {
  let cancel;
  await new Promise((resolve, reject) => {
    cancel = ref.onSnapshot(userSnap => {
      onNext(userSnap);
      resolve();
    }, err => {
      onError(err);
      reject(err);
    });
  });
  return cancel;
}
