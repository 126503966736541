<template>
  <v-menu bottom offset-y v-model="menuOpen">
    <template #activator="{on, attrs}">
      <v-tooltip bottom>
        <template #activator="{on: onTooltip}">
          <v-badge
              :value="!sitesConfigured"
              icon="mdi-cog"
              color="warning"
              offset-x="15"
              offset-y="15"
              overlap>
            <v-btn
                icon
                text
                v-bind="attrs"
                v-on="{...on, ...onTooltip}">
              <v-icon color="grey lighten-2">mdi-domain</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>{{ sitesConfigured ? siteName : 'No sites configured' }}</span>
      </v-tooltip>
    </template>

    <v-card min-width="300">
      <v-card-title class="px-4 py-2 grid">
        <span class="pr-4">Choose Site</span>
        <v-spacer/>
        <div v-if="!sitesConfigured" class="d-flex justify-end">
          <v-icon color="warning">mdi-alert</v-icon>
        </div>
        <edit-group-sites-by-country v-if="!onlyOneCountry" label="Group by country" :show-icon="false" class="toggle"/>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pt-0">
        <span v-if="!sitesConfigured">You have no sites configured, functionality will be restricted.
          <br> Please contact your administrator.</span>
        <v-radio-group
            v-else
            ref="radioGroup"
            :value="forceActiveSite || activeSiteId"
            @change="chooseActiveSite($event)"
            class="pt-0 mt-0"
            hide-details>
          <v-list dense class="pb-0">
            <template v-for="group in siteListGroups">
              <v-subheader v-if="group.title" :key="group.title"><b>{{ group.title }}</b></v-subheader>
              <v-hover v-for="site in group.sites" :key="site.id" v-slot="{ hover }">
                <v-list-item class="pa-0" dense>
                  <v-list-item-content class="pa-0">
                    <v-radio
                        :label="site.text"
                        :value="site.value"
                        color="accent"/>
                  </v-list-item-content>
                  <v-list-item-action-text
                      v-if="preferredSiteId === site.value"
                      class="grey--text text--lighten-1">
                    (default)
                  </v-list-item-action-text>
                  <v-list-item-action v-else>
                    <v-btn
                        v-if="hover || loading === site.value"
                        @click.stop="saveDefaultSite(site.value)"
                        small
                        text>
                      Set Default
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-hover>
            </template>
          </v-list>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import EditGroupSitesByCountry from '@/components/preferences/EditGroupSitesByCountry';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'SiteMenu',
  components: {EditGroupSitesByCountry},
  data() {
    return {
      menuOpen: false,
      loading: false,
      forceActiveSite: null
    };
  },
  computed: {
    ...mapGetters('sites', [
      'sitesById',
      'sitesByCountry',
      'activeSiteId',
      'activeSiteDoc'
    ]),
    ...mapGetters('user/profile', [
      'preferredSiteId',
      'personGroupSitesByCountry'
    ]),
    siteName() {
      const site = this.activeSiteDoc;
      return (site && site.title) || '';
    },
    onlyOneCountry() {
      return Object.keys(this.siteListByCountry).length === 1;
    },
    groupByCountry() {
      return !this.onlyOneCountry && this.personGroupSitesByCountry;
    },
    siteListGroups() {
      if (this.groupByCountry) {
        return this.siteListByCountry;
      }
      return [{sites: this.siteListSelect}];
    },
    siteListByCountry() {
      return Object.entries(this.sitesByCountry)
          .filter(([country, sites]) => sites.length > 0)
          .map(([country, sites]) => ({
            title: country,
            sites: this.sortedSitesList(sites)
          }))
          .sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
          });
    },
    siteListSelect() {
      return this.sortedSitesList(Object.values(this.sitesById));
    },
    sitesConfigured() {
      return Object.keys(this.sitesById).length > 0;
    }
  },
  watch: {
    siteListGroups() {
      // force active site to update, to ensure radio group shows correctly
      this.forceActiveSite = true;
      this.$nextTick(() => this.forceActiveSite = null);
    }
  },
  methods: {
    ...mapMutations('sites', [
      'chooseActiveSite'
    ]),
    ...mapActions('user/profile', [
      'setPreferredSite'
    ]),
    async saveDefaultSite(siteId) {
      const site = this.sitesById[siteId];
      if (!site) {
        this.$notify.showError(`No site selected`);
        return;
      }
      try {
        this.loading = siteId;
        await this.setPreferredSite(site);
        this.$notify.showSuccess(`Default site updated to ${site.title}`);
      } catch (err) {
        this.$logger.error('error updating default site', err);
        this.$notify.showError('Couldn\'t update user default site');
      } finally {
        this.loading = false;
      }
    },
    sortedSitesList(sites) {
      return sites
          .map(site => ({
            text: site.title || site.id,
            value: site.id
          }))
          .sort((a, b) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          });
    }
  }
};
</script>

<style scoped>
.menuable__content__active {
  max-height: 80vh!important;
}
.v-subheader {
  font-size: 1em;
  padding: 0;
  height: 28px;
  margin-top: 4px;
}

.v-list--dense .v-list-item {
  min-height: 28px;
}

.v-list--dense .v-list-item__action {
  /* allow room for the set default button on hover */
  min-width: 115px;
  margin: 0;
}

.toggle {
  padding: 0;
  margin: 8px 0;
}
</style>
