import {Logger} from '@vanti/vue-logger';
import {decorateSnapshot} from '@/firebase';
import * as impl from '@/views/room-booking/calendar/v2/room-booking';
import {BookingStoreMixin, EventStoreMixin, ResourceStoreMixin} from '@/views/room-booking/calendar/booking-store-util';
import Vue from 'vue';
import resources from '@/views/room-booking/calendar/booking-view/resources';
import {reservationToEvent} from '@/views/room-booking/calendar/v2/event';
import workOrderStore from '@/views/room-booking/calendar/work-orders';

const log = Logger.get('booking');

export default {
  namespaced: true,
  state: {
    cancellers: {
      booking: () => {},
      connectedBookings: () => {}
    },
    /** @type {string|null} */
    bookingId: null,
    /** @type {kahu.firestore.Booking|null} */
    booking: null,
    /** @type {kahu.firestore.Booking[]} */
    connectedBookings: [],
    events: [],
    connectedEvents: [],
    ...BookingStoreMixin.state(),
    ...ResourceStoreMixin.state(),

    /**
     * Same format as BookingStoreMixin.state()
     *
     * @type {null|Object}
     */
    toReplay: null,

    loaded: {
      booking: false,
      replayEdits: false
    }
  },
  getters: {
    bookingsLoaded(state) {
      const loading = Object.entries(state.loaded)
          .filter(([, loaded]) => !loaded)
          .map(([name]) => name)
          .filter(name => name !== 'replayEdits');
      return loading.length <= 0;
    },
    loaded(state) {
      const loading = Object.entries(state.loaded)
          .filter(([, loaded]) => !loaded)
          .map(([name]) => name);
      if (loading.length > 0) {
        log.debug(loading + ' not loaded');
        return false;
      } else {
        return true;
      }
    },
    events(state) {
      let events = state.events;
      if (state.connectedEvents.length > 0) {
        // only do this if we have to, avoid copying arrays.
        events = events.concat(state.connectedEvents);
      }
      if (state.added.length > 0) {
        // only do this if we have to, avoid copying arrays.
        events = events.concat(state.added);
      }
      return events;
    },
    ...EventStoreMixin.getters(),
    ...ResourceStoreMixin.getters()
  },
  mutations: {
    loading(state, name) {
      Vue.set(state.loaded, name, false);
    },
    loaded(state, name) {
      Vue.set(state.loaded, name, true);
    },

    setCanceller(state, {key, canceller}) {
      Vue.set(state.cancellers, key, canceller);
    },
    setBookingId(state, id) {
      state.bookingId = id;
    },
    setBooking(state, booking) {
      state.booking = booking;
    },
    setConnectedBookings(state, connectedBookings) {
      state.connectedBookings = connectedBookings;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setConnectedEvents(state, connectedEvents) {
      state.connectedEvents = connectedEvents;
    },
    ...BookingStoreMixin.mutations(),
    ...ResourceStoreMixin.mutations(),
    /**
     * This can't be reused between here and booking-calendar because we are no dealing with bookingQueries
     *
     * @param {Object} state
     * @param {DecoratedData & kahu.firestore.Booking} booking
     */
    editComplete(state, booking) {
      Vue.delete(state.edits, booking.id);

      // handle the case where the event has been deleted
      const deletedIndex = state.deleted.findIndex(e => e.booking.id === booking.id);
      if (deletedIndex >= 0) {
        const eventIndex = state.events.findIndex(e => e.booking && e.booking.id === booking.id);
        if (eventIndex >= 0) {
          state.events.splice(eventIndex, 1);
        }
        state.deleted.splice(deletedIndex, 1);
      }
      // handle the case where the edit is part of a new booking
      const addedIndex = state.added.findIndex(e => e.booking.id === booking.id);
      if (addedIndex >= 0) {
        if (deletedIndex === -1) {
          state.events.push(state.added[addedIndex]);
        }
        state.added.splice(addedIndex, 1);
      }
    },
    clear(state) {
      state.loaded = {booking: false};
      state.booking = null;
      state.connectedBookings = [];
      state.events = [];
      state.connectedEvents = [];
      state.cancellers = {};
      BookingStoreMixin.stateClear(state);
      ResourceStoreMixin.stateClear(state);
    },

    setToReplay(state, toReplay) {
      state.toReplay = toReplay;
    }
  },
  actions: {
    init: {
      root: true,
      handler({state, getters, dispatch}) {
        // watch bookingId and fetch the corresponding booking if it changes
        this.watch(
            () => state.bookingId,
            async () => {
              log.debug(`watch bookingId`, state.bookingId);
              await dispatch('unbind');
              if (state.bookingId) {
                await dispatch('watchBooking');
              }
            },
            {immediate: true}
        );

        this.watch(
            () => getters.bookingsLoaded,
            loaded => {
              if (!loaded) return;
              dispatch('relayEdits');
              dispatch('workOrders/relayEdits');
            },
            {immediate: true}
        );
      }
    },
    unbind({state, commit}) {
      log.debug(`unbind`);
      for (const canceller of Object.values(state.cancellers)) {
        canceller();
      }
      commit('clear');
    },
    watchBooking(context) {
      const {commit, dispatch, state, rootGetters} = context;
      const bookingRef = rootGetters['ns/nsRef'].collection('bookings').doc(state.bookingId);
      log.debug(`watchBooking ${bookingRef.path}`);
      commit('loading', 'booking');
      commit('loading', 'replayEdits');

      const cancel = bookingRef
          .onSnapshot(
              async snapshot => {
                if (!snapshot.exists) {
                  commit('setBooking', null);
                } else {
                  const safeSnapshot = decorateSnapshot(snapshot);
                  commit('setBooking', safeSnapshot);
                  // we need the resources before we computeEvents
                  await dispatch('fetchBookingResources', safeSnapshot);
                  // this can happen in parallel
                  dispatch('fetchConnectedBookings', safeSnapshot)
                      .catch(err => log.error(`fetchConnectedBookings`, err));
                  commit('setEvents', impl.computeEvents(context, [safeSnapshot]));
                }
                commit('loaded', 'booking');
              },
              err => log.error(`watching booking ${bookingRef.path}`, err)
          );
      commit('setCanceller', {key: 'booking', canceller: cancel});
    },
    /**
     *
     * @param {import('vuex').ActionContext} context
     * @param {firebase.firestore.DocumentSnapshot} booking
     */
    async fetchBookingResources({commit}, booking) {
      const resourceRefs = [];
      for (const key of Object.keys(booking.get('reservations') || {})) {
        const ref = booking.get(`reservations.${key}.resource.ref`);
        if (ref) {
          resourceRefs.push(ref);
        }
      }
      const snaps = await Promise.all(resourceRefs.map(ref => ref.get()));
      commit('setResources', snaps.map(snap => decorateSnapshot(snap)));
    },
    /**
     *
     * @param {import('vuex').ActionContext} context
     * @param {firebase.firestore.DocumentSnapshot} booking
     */
    async fetchConnectedBookings(context, booking) {
      const {commit, state} = context;
      const connectionCount = booking.get('_connectionCount') || 0;
      if (connectionCount > 0) {
        log.debug(`fetchConnectedBookings for ${booking.ref.path}`);
        commit('loading', 'connectedBookings');
        const cancel = booking.ref.parent
            .where('connection.ref.ref', '==', booking.ref)
            .onSnapshot(
                async snapshot => {
                  commit('setConnectedBookings', snapshot.docs.map(doc => decorateSnapshot(doc)));
                  commit('setConnectedEvents', impl.computeEvents(context, state.connectedBookings));
                  commit('loaded', 'connectedBookings');
                },
                err => log.error(`fetchConnectedBookings ${booking.ref.path}`, err)
            );
        commit('setCanceller', {key: 'connectedBookings', canceller: cancel});
      }
    },
    relayEdits(context) {
      const {state, commit, getters} = context;
      if (state.toReplay) {
        log.debug(`replaying edits`);
        const {edits, added, deleted} = state.toReplay;
        // once loaded, replay any edits toReplay
        for (const [k, v] of Object.entries(edits || {})) {
          for (const [property, value] of Object.entries(v)) {
            commit('recordEdit', {booking: {id: k}, property, value});
          }
        }
        for (const toAdd of added || []) {
          // recreate the event with our local context
          commit('addEvent', reservationToEvent(context, toAdd.booking, toAdd.key.id));
        }
        for (const toDelete of deleted || []) {
          // find our copy of the event to delete
          const event = getters.eventByKey(toDelete.key);
          if (event) {
            commit('deleteEvent', event);
          }
        }
        commit('setToReplay', null);
      }
      commit('loaded', 'replayEdits');
    },
    ...BookingStoreMixin.actions(log)
  },
  modules: {
    siteResources: resources,
    workOrders: workOrderStore()
  }
};
