/**
 * Module that tracks the list of users shown by the UsersList component.
 */
import {dbUtil, decorateSnapshot} from '@/firebase';
import {EmptyCollection} from '@/store/collection/empty-collection';
import {UsersCollection} from '@/views/people/users/users-collection';
import peopleStore from '@/views/people/directory/people-store';
import {remove} from 'lodash';

export default {
  namespaced: true,
  state: {
    /** @type {?DocumentCollection} */
    currentCollection: new EmptyCollection()
  },
  getters: {
    users(state) {
      return state.currentCollection.documents;
    },
    loadingInfo(state) {
      const c = state.currentCollection;
      if (c.recordFetchAborted()) {
        return {
          text: `Unfortunately we were unable to fetch ${c.records.length > 0 ? 'more' : 'any'} users at this time.`,
          type: 'error',
          loading: false
        };
      } else if (c.fetchErrorCount > 1) {
        return {
          text: `We're experiencing some issues fetching ${c.records.length > 0 ? 'more ' : ''}users` +
              ', please wait while we try and sort out the problem.',
          type: 'warning',
          loading: 'warning'
        };
      } else if (c.recordsAreLoading) {
        return {text: `Loading ${c.records.length > 0 ? 'more ' : ''}users...`, type: 'info', loading: true};
      } else if (c.records.length === 0) {
        return {
          text: `No users could be found`,
          type: 'info',
          color: 'info',
          loading: false,
          noRecords: true
        };
      } else if (!c.expectsMoreRecords) {
        return {text: 'No more users'};
      } else {
        return {};
      }
    }
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {UsersCollection} collection
     */
    setCurrentCollection(state, collection) {
      state.currentCollection = collection;
    },
    /**
     * @param {Object} state
     * @param {Object} user
     */
    removeUser(state, user) {
      remove(state.currentCollection.documents, u => u.id === user.id);
    }
  },
  actions: {
    /**
     * Configure the current collection to be based off of the given query clauses.
     *
     * @param {import('vuex').ActionContext} context
     * @param {QueryClause[]} queryClauses
     */
    async bind({state, rootGetters, commit}, queryClauses) {
      const ns = rootGetters.ns;
      if (!state.currentCollection.sameAs({ns, queryClauses})) {
        commit('setCurrentCollection', new UsersCollection(ns, queryClauses));
      }

      // else the current collection is equivalent to the one we're binding to, don't do anything
    },

    async load({state}) {
      return state.currentCollection.load();
    },

    async stop({state}) {
      return state.currentCollection.stop();
    },

    getByUid({state, rootGetters}, options) {
      const id = options.uid;
      return dbUtil.doc(rootGetters.nsRef, 'users', id)
          .then(doc => doc.get())
          .then(decorateSnapshot);
    },
    async addOrUpdateCard({state, rootGetters}, user) {
      const auditSnippet = rootGetters['user/auditSnippet'];
      const doc = {
        ...user,
        ...auditSnippet
      };
      if (user.exists) {
        user.ref.update(doc);
      } else {
        const id = user.id;
        delete doc.user.id;
        const userDoc = await dbUtil.doc(rootGetters.nsRef, 'users', id);
        return userDoc.set(doc, {merge: true});
      }
    }
  },
  modules: {
    people: peopleStore()
  }
};
