<template>
  <div>
    <v-row class="px-2" dense>
      <v-col cols="12">
        <view-header :title="title">
          <template #subtitle>
            <person-info-position :person="person" show-home-site/>
          </template>
          <template #nextBooking>
            <person-info-next-booking :person="person"/>
          </template>
          <template #tags>
            <person-info-tags :person="person" style="margin-left: -4px"/>
          </template>
          <template #actions>
            <person-user-check :person="person"/>
            <v-tooltip bottom>
              <template #activator="{on}">
                <v-btn :disabled="!activeSiteDoc" append icon to="edit" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Edit Person</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{on}">
                <v-btn :disabled="!activeSiteDoc" append icon v-on="on" @click="setPersonToInactive">
                  <v-icon>{{ isActive ? 'mdi-minus-circle' : 'mdi-check-circle' }}</v-icon>
                </v-btn>
              </template>

              <span>{{ isActive ? 'Set inactive' : 'Re-activate' }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isPeopleAdmin">
              <template #activator="{on}">
                <v-btn append icon v-on="on" :loading="isLoading" @click="sendResetPasswordEmail">
                  <v-icon>mdi-email-alert</v-icon>
                </v-btn>
              </template>

              <span>Send password reset link</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isPeopleAdmin">
              <template #activator="{on}">
                <v-btn :disabled="!activeSiteDoc" append icon v-on="on" @click="deleteThisPerson">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>

              <span>Delete Person</span>
            </v-tooltip>
          </template>
        </view-header>
      </v-col>
    </v-row>
    <v-divider class="divider-dark mt-4"/>
    <v-row class="px-2">
      <!------------------------ Contact Info  ------------------------------>
      <v-col cols="12" lg="6">
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Contact Information
          </v-col>
        </v-row>
        <person-info-contact :person="person"/>
      </v-col>
      <!--------------------- Additional Info  ----------------------------->
      <v-col cols="12" lg="6">
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Additional Information
          </v-col>
        </v-row>
        <v-row v-if="person && person.costCentre">
          <v-col cols="4" md="3" lg="2">Cost Centre:</v-col>
          <v-col cols="8" md="9" lg="10">{{ person.costCentre }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="3" lg="2">
            <v-tooltip bottom open-delay="250">
              <template #activator="{on}">
                <span v-on="on">Delegates:</span>
              </template>

              <span>Other people with delegated access on behalf of this person.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="8" md="9" lg="10">
            <person-delegates-summary :delegates="person && person.delegates" :person="person"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="3" lg="2">
            <v-tooltip bottom open-delay="250">
              <template #activator="{on}">
                <span v-on="on">Delegators:</span>
              </template>

              <span>Other people this person has access on behalf of.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="8" md="9" lg="10">
            <person-delegates-summary :delegates="person && person.delegators" no-delegates-message="(no delegators)"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PersonInfoContact from '@/components/person/PersonInfoContact';
import PersonInfoPosition from '@/components/person/PersonInfoPosition';
import PersonInfoNextBooking from '@/components/person/PersonInfoNextBooking';
import PersonInfoTags from '@/components/person/PersonInfoTags';
import ViewHeader from '@/components/ViewHeader';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import PersonUserCheck from '@/components/person/PersonUserCheck';
import PersonDelegatesSummary from '@/components/person/PersonDelegatesSummary.vue';

export default {
  name: 'PersonInfo',
  components: {
    PersonDelegatesSummary,
    PersonUserCheck, PersonInfoTags, PersonInfoPosition, PersonInfoNextBooking, ViewHeader, PersonInfoContact},
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('sites', ['activeSiteDoc']),
    ...mapGetters('auth', ['isPeopleAdmin']),
    ...mapGetters('views/person', ['person']),
    isActive() {
      if (!this.person) {
        return false;
      }
      return this.person.active;
    },
    title() {
      if (!this.person || !this.person.title) {
        return '';
      }
      let title = this.person.title;
      if (this.person.displayName) {
        title += ` (${this.person.displayName})`;
      }
      return title;
    }
  },
  methods: {
    ...mapMutations(['showDialog', 'hideDialog']),
    ...mapMutations('views/person', ['loading', 'loaded']),
    ...mapActions('views/person', ['deletePerson', 'togglePersonInactive']),
    ...mapActions('auth', ['sendPasswordResetEmail']),

    deleteThisPerson() {
      const name = this.person.displayName || this.person.title;
      this.showDialog({
        title: 'Delete Person?',
        body: `Are you sure you want to permanently delete ${name}?`,
        confirm: () => {
          this.loading('person');
          this.deletePerson()
              .then(() => {
                this.loaded('person');
                this.$notify.showSuccess(name + ' deleted');
                // run a fresh query so the deleted person isn't in the list
                this.$store.commit('views/people/clear');
                this.$router.push({name: 'directory'});
              })
              .catch((err) => {
                this.$logger.error(err);
                this.$notify.showError('Could not delete person');
              });
          this.hideDialog();
        }
      });
    },
    setPersonToInactive() {
      const name = this.person.displayName || this.person.title;
      this.togglePersonInactive()
          .then(active => {
            this.$notify.showSuccess(name + (active ? ' re-activated' : ' set inactive'));
          })
          .catch(err => {
            this.$logger.error(err);
            this.$notify.showError(`Could not set person to ${this.isActive ? 'inactive' : 'active'}`);
          });
    },
    sendResetPasswordEmail() {
      this.isLoading = true;
      this.sendPasswordResetEmail({
        email: this.person.email
      }).then(() => {
        this.$notify.showSuccess('Password reset email sent');
      }).catch(err => {
        this.$logger.error(err);
        this.$notify.showError(err);
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>

</style>
