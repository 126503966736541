<template>
  <form-row label="PIN" large-label>
    <v-text-field
        v-model="selected"
        autocomplete="new-password"
        dense
        single-line
        pattern="[0-9]*"
        :disabled="!enabled"
        :readonly="!showPin"
        :placeholder="showPin ? 'Enter a PIN' : 'Press edit to enter a PIN'"
        :type="showPin ? 'number' : 'password'"
        :append-icon="selected && enabled ? 'mdi-close' : ''"
        @click:append="localSelection = undefined">
      <template #append-outer>
        <v-btn v-if="!showPin && enabled" @click="showPin = true" text outlined small>
          <v-icon left>mdi-pencil</v-icon>Edit
        </v-btn>
        <v-btn v-if="showPin && enabled" @click="showPin = false" text outlined small>
          <v-icon left>mdi-eye-off</v-icon>Hide
        </v-btn>
      </template>
    </v-text-field>
  </form-row>
</template>

<script>
import FormRow from '@/components/form/FormRow';
import activeSiteReset from '@/mixins/active-site-reset';
import {mapGetters} from 'vuex';
export default {
  name: 'PersonAccessControlPin',
  components: {FormRow},
  mixins: [activeSiteReset],
  props: {
    value: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localSelection: null,
      showPin: false
    };
  },
  computed: {
    ...mapGetters('views/person', ['activeMembership']),
    current() {
      if (this.activeMembership && this.activeMembership.accessControl) {
        return this.activeMembership.accessControl.pin;
      }
      return undefined;
    },
    selected: {
      get() {
        if (this.localSelection !== null) {
          return this.localSelection;
        }
        if (this.current !== null) {
          return this.current;
        }
        return null;
      },
      set(v) {
        this.$logger.debug('selected', v);
        this.localSelection = v;
      }
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(s) {
        this.$emit('input', s);
      }
    }
  },
  methods: {
    /**
     * A method to reset back to the default state,
     * which we want to do in certain cases like when changing active sites
     */
    reset() {
      this.selected = null;
      this.showPin = false;
    }
  }
};
</script>

<style scoped>

</style>
