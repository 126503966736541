<template>
  <div>
    <v-toolbar flat>
      <h2>Neighbourhoods</h2>
      <v-spacer/>
      <v-btn color="secondary" @click="createDialog = true" :disabled="!isAllowedToEditNeighbourhoods">
        <v-icon left>mdi-home-plus</v-icon>
        <span>Add</span>
      </v-btn>
    </v-toolbar>

    <v-alert
        v-if="!isAllowedToEditNeighbourhoods"
        type="warning"
        prominent
        text>
      You don't have permission to edit neighbourhoods
    </v-alert>

    <v-list class="pt-0" v-if="anyNeighbourhoods">
      <template v-if="allCircles.length > 0">
        <v-list-item>
          <v-list-item-title>Circles</v-list-item-title>
          <v-list-item-action-text>
            <v-btn-toggle v-model="circleMode">
              <v-btn small><v-icon left small>mdi-pencil</v-icon>Edit</v-btn>
              <v-btn small><v-icon left small>mdi-circle-multiple-outline</v-icon>Preview</v-btn>
            </v-btn-toggle>
          </v-list-item-action-text>
        </v-list-item>
      </template>
      <v-list-item-group v-model="selectedNeighbourhoodIndex">
        <template v-for="(neighbourhood, index) in neighbourhoodsActiveFloorFirst">
          <v-list-item
              :disabled="editMode && !circle || !isAllowedToEditNeighbourhoods"
              :class="{'disabled-item': editMode || !isAllowedToEditNeighbourhoods}"
              @click.stop="editMode ? setCircleColor(neighbourhood.color) : null"
              dense
              two-line
              :value="index"
              :key="neighbourhood.id">
            <v-list-item-content>
              <v-list-item-title>
                <color-picker :color.sync="neighbourhood.color"/>
                <v-text-field
                    :disabled="editMode || !isAllowedToEditNeighbourhoods"
                    v-model="neighbourhood.title"
                    @click.stop
                    dense
                    hide-details
                    single-line
                    class="d-inline-flex ml-2 mt-0 subtitle-2"/>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ neighbourhood.deskAmount }} desks
                <span v-if="neighbourhood.exclusive && neighbourhood.exclusiveAdHoc">, Exclusive Ad-Hoc</span>
                <span v-else-if="neighbourhood.exclusive">, Exclusive</span>
                <span v-if="neighbourhood.hasFloors">, {{ neighbourhood.floorTitles.join(', ') }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="neighbourhood.exclusive">
              <v-btn icon small @click="openModal(neighbourhood)">
                <v-icon>mdi-account-multiple</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-dialog
                scrollable
                max-width="50%"
                v-model="modalState[neighbourhood.id]">
              <v-card>
                <v-card-title style="font-weight: bold;" v-if="neighbourhood.title">
                  {{ neighbourhood.title }} Members
                </v-card-title>
                <v-divider/>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="member in selectedNeighbourhoodMembers"
                        :key="member.id">
                      <v-list-item-title>{{ member.person.title }}</v-list-item-title>
                      <v-list-item-action>
                        <v-btn icon @click="$router.push('/people/view/' + member.id)">
                          <v-icon>mdi-account-edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="!selectedNeighbourhoodMembers.length">
                      <v-list-item-title>There are no current members of this neighbourhood</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-menu
                :value="editMenu[neighbourhood]"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x>
              <template #activator="{ on, attrs }">
                <v-list-item-action>
                  <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                          v-model="neighbourhood.exclusive"
                          color="primary"/>
                    </v-list-item-action>
                    <v-list-item-title>Exclusive</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="neighbourhood.exclusive">
                    <v-list-item-action>
                      <v-switch
                          v-model="neighbourhood.exclusiveAdHoc"
                          color="primary"/>
                    </v-list-item-action>
                    <v-list-item-title>Ad-hoc exclusive</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-list-item-action class="ml-0">
              <v-btn icon small @click.stop="deleteNeighbourhood(neighbourhood)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index === neighbourhoodsByFloor.activeFloor.length - 1" :key="index"/>
        </template>
      </v-list-item-group>
    </v-list>
    <div class="pl-4" v-else>
      No neighbourhoods at this site!
    </div>
    <v-dialog
        v-model="confirmation"
        v-if="selectedNeighbourhood && storedDesk && storedDeskNeighbourhood"
        width="500">
      <confirm-neighbourhood-change-dialog
          :desk="storedDesk"
          :old-neighbourhood="storedDeskNeighbourhood"
          :neighbourhood="selectedNeighbourhood"
          @close-dialog="confirmation = false"/>
    </v-dialog>

    <v-dialog
        width="500"
        v-model="createDialog">
      <create-neighbourhood-dialog @close-dialog="createDialog = false"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import ColorPicker from '@/views/desk-booking/settings/ColorPicker';
import ConfirmNeighbourhoodChangeDialog
  from '@/views/desk-booking/settings/sidebar/dialogs/ConfirmNeighbourhoodChangeDialog';
import CreateNeighbourhoodDialog from '@/views/desk-booking/settings/sidebar/dialogs/CreateNeighbourhoodDialog';

export default {
  name: 'NeighbourhoodsList',
  components: {ColorPicker, ConfirmNeighbourhoodChangeDialog, CreateNeighbourhoodDialog},
  data() {
    return {
      storedDesk: null,
      confirmation: false,
      createDialog: false,
      selectedNeighbourhoodIndexValue: null,
      editMenu: {},
      selectedNeighbourhoodForMembers: null,
      selectedNeighbourhoodMembers: [],
      modalState: {}
    };
  },
  computed: {
    ...mapState('views/deskBooking/settings/circles', ['editMode', 'previewMode']),
    ...mapGetters('views/deskBooking/settings/circles', ['circle', 'allCirclesByFloor']),
    ...mapState('views/deskBooking/settings/desks', ['desks']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', [
      'allNeighbourhoods',
      'selectedNeighbourhood',
      'neighbourhoodsByFloor'
    ]),
    ...mapGetters('views/deskBooking/settings/map', ['activeFloor']),
    ...mapGetters('auth', ['hasAccessTo']),
    ...mapState('ns', ['doc']),
    allCircles() {
      return this.allCirclesByFloor(this.activeFloor);
    },
    circleMode: {
      get() {
        if (this.editMode) {
          return 0;
        } else if (this.previewMode) {
          return 1;
        } else {
          return null;
        }
      },
      set(v) {
        if (v === 0) {
          this.setEditMode(true);
          this.unselectNeighbourhood();
        } else if (v === 1) {
          this.setPreviewMode(true);
          this.unselectNeighbourhood();
        } else {
          this.setEditMode(false);
          this.setPreviewMode(false);
        }
      }
    },
    anyNeighbourhoods() {
      return this.neighbourhoodsByFloor.activeFloor.length > 0 ||
          this.neighbourhoodsByFloor.other.length > 0;
    },
    neighbourhoodsActiveFloorFirst() {
      return [
        ...this.neighbourhoodsByFloor.activeFloor,
        ...this.neighbourhoodsByFloor.other
      ];
    },
    selectedNeighbourhoodIndex: {
      get() {
        return this.selectedNeighbourhoodIndexValue;
      },
      set(index) {
        if (!this.editMode) {
          this.selectedNeighbourhoodIndexValue = index;
          if (index !== undefined) {
            this.setNeighbourhood(this.neighbourhoodsActiveFloorFirst[index]);
          } else {
            this.unselectNeighbourhood();
          }
        }
      }
    },
    storedDeskNeighbourhood() {
      if (this.storedDesk) {
        return this.allNeighbourhoods.find(n => n.ref.isEqual(this.storedDesk.neighbourhood.ref));
      }
      return null;
    },
    isAllowedToEditNeighbourhoods() {
      return this.hasAccessTo.deskBooking.neighbourhoodSettings;
    }

  },
  methods: {
    ...mapMutations('selection', ['clearAll']),
    ...mapMutations('views/deskBooking/settings/circles', ['setEditMode', 'setPreviewMode']),
    ...mapMutations('views/deskBooking/settings/neighbourhoods', ['setNeighbourhood', 'clearNeighbourhood']),
    unselectNeighbourhood() {
      this.clearNeighbourhood();
      this.clearAll();
    },
    setCircleColor(color) {
      if (!this.circle) return;
      this.circle.fill = this.hexToRgba(color, '0.3');
      this.selectedNeighbourhoodIndexValue = null;
    },
    deleteNeighbourhood(neighbourhood) {
      neighbourhood.delete();
      this.clearNeighbourhood();
    },
    async addDesk(title) {
      const desk = this.desks.find(d => d.title === title);
      if (desk.neighbourhood) {
        this.storedDesk = desk;
        this.confirmation = true;
      } else {
        desk.neighbourhood = this.selectedNeighbourhood;
      }
    },
    hexToRgba(hex, alpha) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c= hex.substring(1).split('');
        if (c.length=== 3) {
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255, alpha].join(', ')+')';
      }
    },
    openModal(selectedNHood) {
      this.selectedNeighbourhoodForMembers = selectedNHood;
      const siteRef = selectedNHood.ref.parent.parent;
      const neighbourhoodPath = this.selectedNeighbourhoodForMembers.ref.path;
      siteRef.collection('members')
          .where('ns', '==', this.doc.id)
          .get()
          .then(querySnapshot => {
            const selectedMembers = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              const matchesNeighbourhood = data.neighbourhood &&
                                    (Array.isArray(data.neighbourhood) ?
                                      data.neighbourhood.some(n => n.ref.path === neighbourhoodPath) :
                                      data.neighbourhood.ref.path === neighbourhoodPath);
              if (matchesNeighbourhood) {
                const member = {...data, id: doc.id};
                selectedMembers.push(member);
              }
              this.$set(this.modalState, selectedNHood.id, true);
            });
            this.selectedNeighbourhoodMembers = selectedMembers;
          })
          .catch(err => {
            this.$logger.error('Error fetching users: ', err);
          });
    },
    closeModal() {
      this.$set(this.modalState, neighbourhood.id, false);
    }
  }
};
</script>

<style scoped>
.v-input--dense.v-text-field--single-line >>> .v-input__control > .v-input__slot {
  min-height: 20px;
}
.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border: none;
}

.disabled-item >>> *:not(i) {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>
