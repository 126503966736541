<template>
  <div>
    <v-toolbar flat>
      <h2 @click="locateDesk(bookable)">{{ bookableTitle }}</h2>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn @click="clearAll" v-on="on" icon class="white mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Clear Selection</span>
      </v-tooltip>
    </v-toolbar>

    <v-alert
        v-if="!isAllowedToEditDeskSettings"
        type="warning"
        prominent
        text>
      You don't have permission to edit desk settings
    </v-alert>

    <v-list subheader two-line>
      <v-subheader>Settings</v-subheader>
      <v-list-item :disabled="!isAllowedToEditDeskSettings">
        <v-list-item-content>
          <v-list-item-title>Desk Bookable</v-list-item-title>
          <v-list-item-subtitle v-if="isBookable">This desk can be booked</v-list-item-subtitle>
          <v-list-item-subtitle v-else>This desk cannot be booked</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
              v-model="isBookable"
              :disabled="!isAllowedToEditDeskSettings"/>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Neighbourhood</v-list-item-title>
          <v-list-item-subtitle>
            <v-select
                filled
                dense
                placeholder="No neighbourhood"
                :items="allNeighbourhoods"
                v-model="selectedNeighbourhood"
                :disabled="!isAllowedToEditNeighbourhood"
                item-text="title"
                item-value="ref">
              <template #item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.title }}</span>
                        <v-spacer/>
                        <v-chip
                            text-color="white"
                            :color="item.color"
                            small/>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <div class="d-flex flex-fill px-4 pt-4">
        <v-subheader class="pa-0">Bookings</v-subheader>
        <v-btn
            icon
            color="primary"
            class="ml-auto mb-auto mt-2"
            :disabled="bookingMenuOpen || !isAllowedToEditDeskSettings"
            @click="bookingMenuOpen = !bookingMenuOpen">
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </div>

      <v-expand-transition>
        <template v-if="bookingMenuOpen">
          <book-desk
              class="ma-2"
              :resource="bookable"
              :bookings="pendingBookings"
              @close="bookingMenuOpen = false"/>
        </template>
      </v-expand-transition>

      <template v-if="pendingBookings.length > 0">
        <booking-list-item v-for="booking in pendingBookings" :key="booking.id" :booking="booking"/>
      </template>
      <v-list-item v-else-if="!selectedBookingsLoading" class="px-4">
        <v-list-item-title>No future bookings for this desk.</v-list-item-title>
      </v-list-item>
    </v-list>
    <div v-if="selectedBookingsLoading" class="d-flex justify-center mt-4">
      <v-progress-circular indeterminate color="accent" class="text-center"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import BookingListItem from '@/components/BookingListItem';
import BookDesk from '@/views/desk-booking/settings/sidebar/BookDesk';
import firebase from 'firebase/app';

export default {
  name: 'SingleDesk',
  components: {BookDesk, BookingListItem},
  props: {
    bookable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disabled: null,
      neighbourhood: null,
      bookingMenuOpen: false
    };
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/desks', ['deskById']),
    ...mapGetters('selection', ['lastSelected']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['sortedNeighbourhoods']),
    ...mapGetters('bookings/forSelectedDesk', {pendingBookings: 'pendingBookings', selectedBookingsLoading: 'loading'}),
    ...mapGetters('auth', ['hasAccessTo']),
    ...mapGetters('sites/active', ['maskedFloorName']),

    selectedDesk() {
      return this.deskById(this.bookable.id);
    },
    bookableTitle() {
      const floorPattern = /^[^.]+/;
      if (this.maskedFloorName) return this.bookable.title.replace(floorPattern, this.maskedFloorName);
      return this.bookable.title;
    },
    isBookable: {
      get() {
        return !(this.selectedDesk.hasOwnProperty('disabled') && (this.selectedDesk.disabled === true) ||
            (typeof this.selectedDesk.disabled === 'string'));
      },
      set(v) {
        this.selectedDesk.disabled = !v;
      }
    },
    allNeighbourhoods() {
      const clonedNeighbourhoods = Array.from(this.sortedNeighbourhoods);
      clonedNeighbourhoods.splice(0, 0,
          {title: 'None', ref: firebase.firestore.FieldValue.delete()});
      return clonedNeighbourhoods;
    },
    selectedNeighbourhood: {
      get() {
        if (!this.selectedDesk || !this.selectedDesk.neighbourhood) return null;
        const currNeighbourhoodRef = this.selectedDesk.neighbourhood;
        return this.sortedNeighbourhoods.find(n => n.ref.path === currNeighbourhoodRef.ref.path);
      },
      set(v) {
        this.selectedDesk.neighbourhood = this.sortedNeighbourhoods.find(n => n.ref.path === v.path);
      }
    },
    isAllowedToEditDeskSettings() {
      return this.hasAccessTo.deskBooking.deskSettings;
    },
    isAllowedToEditNeighbourhood() {
      return this.hasAccessTo.deskBooking.neighbourhoodSettings;
    }
  },
  methods: {
    ...mapMutations('selection', ['clearAll']),
    ...mapActions('views/deskBooking/settings/map', ['locate']),
    locateDesk(bookable) {
      this.locate(bookable);
    }
  }
};
</script>

<style scoped>

</style>
