<template>
  <form-row :label="label" large-label>
    <v-autocomplete
        dense
        single-line
        :items="items"
        item-text="title"
        :menu-props="{maxWidth: 375}"
        item-value="ref.path"
        v-model="selected"
        :clearable="clearable"/>
  </form-row>
</template>

<script>
import FormRow from '@/components/form/FormRow';
/**
 * Select a document from a list using a v-autocomplete.
 * The v-model is the document itself, but internally the ref.path is used for the autocomplete value.
 */
export default {
  name: 'DocumentAutocomplete',
  components: {FormRow},
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localSelection: null
    };
  },
  computed: {
    valuePath() {
      return this.value && this.value.ref && this.value.ref.path;
    },
    selected: {
      get() {
        if (this.localSelection) {
          return this.localSelection;
        }
        if (this.valuePath) {
          return this.valuePath;
        }
        return null;
      },
      set(v) {
        this.localSelection = v;
        this.onSelection(v);
      }
    }
  },
  methods: {
    /**
     * This is necessary because when localSelection changes,
     * we need to emit the change to the parent component
     * so that the value variable is changed, too.
     *
     * @param {string} path
     */
    onSelection(path) {
      // If the combobox has been cleared, emit null
      if (!path) {
        this.$emit('input', null);
      } else {
        // Otherwise, emit the doc path of the selected item
        const doc = this.docByPath(path);
        this.$emit('input', doc);
      }
    },
    docByPath(path) {
      return this.items.find(n => n.ref.path === path);
    },
    /**
     * When the value from the parent component changes (such as if the active site changes),
     * prioritise that over any local changes made
     */
    reset() {
      this.localSelection = null;
    }
  }
};
</script>

<style scoped>

</style>
