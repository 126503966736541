import DeferUtil from '@/store/defer-util';
import {byRef} from '@/store/firestore-util';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';

const log = Logger.get('desk-booking/settings/map');

export default {
  namespaced: true,
  state: {
    bound: false,
    /** @type {Object.<string,Floor>}*/
    chosenFloorBySiteId: {}
  },
  getters: {
    chosenFloor(state, getters, rootState, rootGetters) {
      const activeSite = rootGetters['sites/activeSiteDoc'];
      if (!activeSite) return null;

      return state.chosenFloorBySiteId[activeSite.id];
    },
    activeFloor(state, getters, rootState, rootGetters) {
      // if the user selected one, return that
      if (getters.chosenFloor) {
        return getters.chosenFloor;
      }

      // if the user has a preference, use that
      const userPreferredFloor = rootGetters['user/preferredFloor'];
      if (userPreferredFloor) {
        const foundPreferredFloor = rootGetters['floors/localStateByRef'](byRef(userPreferredFloor));
        if (foundPreferredFloor) {
          return foundPreferredFloor;
        }
      }

      // use the first floor we have
      if (rootGetters['floors/siteFloors'].length > 0) {
        return rootGetters['floors/siteFloors'][0];
      }

      return null;
    },
    desksCountOnFloor(state, getters) {
      return getters.activeFloor ?
        Object.keys(getters.activeFloor.desksById).length :
        0;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    chooseFloorForSite(state, {site, floor}) {
      if (!site) return;
      if (!floor) {
        Vue.delete(state.chosenFloorBySiteId, site.id);
      } else {
        // double check the floor is in the site
        if (!floor.siteRef.isEqual(site.ref)) {
          return log.warn('attempt to choose floor (' + floor.ref.path + ') ' +
              'for unrelated site (' + site.ref.path + ') ignored');
        }
        Vue.set(state.chosenFloorBySiteId, site.id, floor);
      }
    },
    clearLocalState(state) {
      state.chosenFloorBySiteId = {};
    },
    setBound(state, b) {
      state.bound = b;
    }
  },
  actions: {
    async bind({state, commit}) {
    },
    async unbind({commit}) {
      // commit('reset');
      // commit('clearLocalState');
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {DecoratedData} space
     */
    async locate({getters, state, commit, dispatch, rootState, rootGetters}, space) {
      if (!space) {
        log.debug('locate: no space', space);
        return;
      }
      // if the space isn't on a floor we can't locate it
      if (!('floor' in space)) {
        log.debug('locate: no floor');
        return;
      }
      /** @type {firebase.firestore.DocumentReference} */
      const spaceFloorRef = space.floor.ref;
      if (!spaceFloorRef) {
        log.debug('locate: no floor ref');
        return;
      }
      const spaceSiteRef = spaceFloorRef.parent.parent;
      const activeSite = rootGetters['sites/activeSiteDoc'];
      log.debug(`locate ${space.ref.path} on floor ${spaceFloorRef.path} at site ${spaceSiteRef.path}`);

      // check if the space is in the active site
      const spaceInActiveSite = activeSite && activeSite.ref.isEqual(spaceSiteRef);
      if (!spaceInActiveSite) {
        const siteToChoose = rootGetters['sites/sites'][spaceSiteRef.id];
        if (siteToChoose) {
          commit('sites/chooseActiveSite', siteToChoose.ref.id, {root: true});
        } else {
          log.warn(`attempt to locate space ${space.ref.path} in unknown site ${spaceSiteRef.path}`);
          return;
        }
      }

      // check if the space is on the activeFloor
      const activeFloor = getters.activeFloor;
      const spaceOnActiveFloor = activeFloor && activeFloor.floor.ref.isEqual(spaceFloorRef);
      if (!spaceOnActiveFloor) {
        // activate the floor
        // find the floor in our lis
        const floorToSelect = rootGetters['floors/localStateByRef'](spaceFloorRef.path);
        if (!floorToSelect) {
          log.debug('locate: unknown floor', spaceFloorRef.path);
          return;
        }

        const activeSite = rootGetters['sites/activeSiteDoc'];
        commit('chooseFloorForSite', {floor: floorToSelect, site: activeSite});
        await dispatch('floors/locate', {space, floor: floorToSelect}, {root: true});
      } else {
        await dispatch('floors/locate', {space, floor: activeFloor}, {root: true});
      }
    }
  }
};
