import {byId, byRef} from '@/store/firestore-util';
import {db} from '@/firebase';
import {editedValue, editValue, resetEditValue} from '@/util/editable-util';
import nested from 'nested-property';

/**
 * @param {string} title
 * @param {string} color
 * @param {boolean} exclusive
 * @param {boolean} exclusiveAdHoc
 * @param {firebase.firestore.DocumentReference} spaceKind
 * @param {Object} creatableSnippet
 * @return {Object}
 */
export function newNeighbourhoodData(title, color, exclusive, exclusiveAdHoc, spaceKind, creatableSnippet) {
  const creatable = creatableSnippet || {};
  return {
    ...creatable,
    kind: {
      ref: spaceKind,
      title: 'Neighbourhood'
    },
    title: title,
    color: color,
    exclusive: exclusive,
    exclusiveAdHoc
  };
}

/**
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} neighbourhood
 *
 * @return {EditableNeighbourhood}
 */
export function toEditableNeighbourhood(context, neighbourhood) {
  const neighbourhoodByRef = byRef(neighbourhood);
  const editableNeighbourhood = /** @type {EditableNeighbourhood} */ {
    ref: neighbourhood.ref,
    id: byId(neighbourhood),
    desks: {}
  };
  Object.defineProperty(editableNeighbourhood, 'value', {
    enumerable: true,
    get() {
      return neighbourhood;
    }
  });
  Object.defineProperty(editableNeighbourhood, 'title', {
    enumerable: true,
    get() {
      return editedValue(context, neighbourhood, 'title');
    },
    set(v) {
      const original = nested.get(neighbourhood, 'title');
      if (original === v) {
        resetEditValue(context, neighbourhood, 'title');
      } else {
        editValue(context, neighbourhood, 'title', v);
      }
    }
  });
  Object.defineProperty(editableNeighbourhood, 'color', {
    enumerable: true,
    get() {
      return editedValue(context, neighbourhood, 'color');
    },
    set(v) {
      const original = nested.get(neighbourhood, 'color');
      if (original === v) {
        resetEditValue(context, neighbourhood, 'color');
      } else {
        editValue(context, neighbourhood, 'color', v);
      }
    }
  });

  Object.defineProperty(editableNeighbourhood, 'exclusive', {
    enumerable: true,
    get() {
      return editedValue(context, neighbourhood, 'exclusive');
    },
    set(v) {
      const original = nested.get(neighbourhood, 'exclusive');
      if (original === v) {
        resetEditValue(context, neighbourhood, 'exclusive');
      } else {
        editValue(context, neighbourhood, 'exclusive', v);
      }
    }
  });

  Object.defineProperty(editableNeighbourhood, 'exclusiveAdHoc', {
    enumerable: true,
    get() {
      return editedValue(context, neighbourhood, 'exclusiveAdHoc');
    },
    set(v) {
      const original = nested.get(neighbourhood, 'exclusiveAdHoc');
      if (original === v) {
        resetEditValue(context, neighbourhood, 'exclusiveAdHoc');
      } else {
        editValue(context, neighbourhood, 'exclusiveAdHoc', v);
      }
    }
  });

  Object.defineProperty(editableNeighbourhood.desks, 'existingDesks', {
    enumerable: true,
    get() {
      const activeSite = context.rootGetters['sites/activeSiteDoc'];
      const desks = context.rootGetters['sites/aggregates/bySiteByType'](byRef(activeSite), 'bookables') || {};
      return Object.values(desks).filter(d => d.neighbourhood &&
          d.neighbourhood.ref && d.neighbourhood.ref.path === neighbourhoodByRef);
    }
  });

  Object.defineProperty(editableNeighbourhood.desks, 'addedDesks', {
    enumerable: true,
    get() {
      return context.rootGetters['views/deskBooking/settings/desks/additionsByNeighbourhoodRef'](neighbourhoodByRef) ||
          [];
    }
  });

  Object.defineProperty(editableNeighbourhood.desks, 'removedDesks', {
    enumerable: true,
    get() {
      return context.rootGetters['views/deskBooking/settings/desks/removalsByNeighbourhoodRef'](neighbourhoodByRef) ||
          [];
    }
  });

  Object.defineProperty(editableNeighbourhood, 'deskAmount', {
    enumerable: true,
    get() {
      const desks = editableNeighbourhood.desks;
      return desks.existingDesks.length + desks.addedDesks.length - desks.removedDesks.length;
    }
  });

  Object.defineProperty(editableNeighbourhood, 'floorsByRef', {
    enumerable: true,
    get() {
      return editableNeighbourhood.desks.existingDesks
          .filter(desk => desk.floor && desk.floor.ref)
          .reduce((floorsByRef, desk) => {
            const r = byRef(desk.floor);
            if (r) {
              floorsByRef[byRef(desk.floor)] = desk.floor;
            }
            return floorsByRef;
          }, {});
    }
  });

  Object.defineProperty(editableNeighbourhood, 'floorTitles', {
    enumerable: true,
    get() {
      return Object.values(editableNeighbourhood.floorsByRef)
          .map(f => f.title)
          .sort();
    }
  });

  Object.defineProperty(editableNeighbourhood, 'hasFloors', {
    enumerable: true,
    get() {
      return Object.keys(editableNeighbourhood.floorsByRef).length > 0;
    }
  });

  editableNeighbourhood.includesFloor = ref => {
    return editableNeighbourhood.floorsByRef.hasOwnProperty(ref);
  };

  // edit handling
  editableNeighbourhood.commit = batch => {
    let weMadeTheBatch = false;
    return /** @type {Promise<void>} */ Promise.resolve(batch).then(batch => {
      // reuse the passed in batch, or we don't need one
      if (batch) return batch;

      // need to make a new batch
      weMadeTheBatch = true;
      return db.then(_db => _db.batch());
    }).then(batch => {
      // interact with firestore to save the edits
      const tasks = [];
      tasks.push(context.dispatch('commitEdit', {neighbourhood, batch}));
      return Promise.all(tasks).then(() => batch);
    }).then(batch => {
      // if this was a batch that we made, then commit it
      if (weMadeTheBatch) return batch.commit();
      return null;
    });
  };
  editableNeighbourhood.reset = () => {
    context.commit('resetEdit', editableNeighbourhood);
  };
  editableNeighbourhood.delete = () => {
    context.commit('deleteNeighbourhood', editableNeighbourhood);
  };

  Object.defineProperty(editableNeighbourhood, 'changed', {
    enumerable: true,
    get() {
      return editableNeighbourhood.added || editableNeighbourhood.edited || editableNeighbourhood.deleted ||
          (editableNeighbourhood.desks.addedDesks && editableNeighbourhood.desks.addedDesks.length > 0) ||
          (editableNeighbourhood.desks.removedDesks && editableNeighbourhood.desks.removedDesks.length > 0);
    }
  });

  Object.defineProperty(editableNeighbourhood, 'added', {
    enumerable: true,
    get() {
      return context.state.added.findIndex(n => n === editableNeighbourhood) !== -1;
    }
  });
  Object.defineProperty(editableNeighbourhood, 'edited', {
    enumerable: true,
    get() {
      const edit = context.state.edits[byId(neighbourhood)] || {};
      return Object.keys(edit).length > 0;
    }
  });
  Object.defineProperty(editableNeighbourhood, 'deleted', {
    enumerable: true,
    get() {
      return context.state.deleted.findIndex(n => n === editableNeighbourhood) !== -1;
    }
  });

  return editableNeighbourhood;
}
