<template>
  <div>
    <v-row class="px-2" dense>
      <v-col cols="12">
        <view-header :title="title" :subtitle="id">
          <template #title>
            <card-id-detail bottom :card-title="title" :hex-card-id="siteCardId" :card-id-type="cardIdType"/>
          </template>
          <template #tags>
            <card-info-tags :card="card" :has-expired="hasExpired" style="margin-left: -4px"/>
          </template>
          <template #actions>
            <v-tooltip bottom>
              <template #activator="{on}">
                <v-btn append icon to="edit" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Edit card</span>
            </v-tooltip>
          </template>
        </view-header>
      </v-col>
    </v-row>
    <v-divider class="divider-dark mt-4"/>
    <v-row class="px-2">
      <v-col cols="12" lg="5">
        <v-row dense>
          <v-col cols="12" class="title font-weight-regular">
            Assignment
          </v-col>
          <v-col cols="12">
            <card-assignment :card="card" :site-membership="activeMembership"/>
          </v-col>
        </v-row>
        <v-row class="pt-2" v-if="cardHistoryEnabled">
          <span v-if="showHistory" class="px-2 title font-weight-regular">History</span>
          <v-spacer/>
          <v-btn text @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show History' }}</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="px-2" v-if="cardHistoryEnabled && showHistory">
      <v-col cols="12">
        <card-history/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ViewHeader from '@/components/ViewHeader';
import CardInfoTags from '@/views/people/card/components/CardInfoTags';
import {mapGetters} from 'vuex';
import CardAssignment from '@/views/people/card/components/CardAssignment';
import CardHistory from './components/CardHistory';
import {CardIdTypeRef} from '@/util/card-type';
import CardIdDetail from '@/components/cards/CardIdDetail';

export default {
  name: 'CardView',
  components: {CardIdDetail, CardHistory, CardInfoTags, ViewHeader, CardAssignment},
  data() {
    return {
      showHistory: false
    };
  },
  computed: {
    ...mapGetters('appConfig', ['cardHistoryEnabled']),
    ...mapGetters('sites/active', ['configCardIdType']),
    ...mapGetters('views/card', ['card', 'hasExpired', 'activeMembership']),
    isPermanent() {
      if (!this.card) {
        return false;
      }
      return this.card.role === 'permanent';
    },
    title() {
      const card = this.card;
      if (!card) {
        return '';
      }
      if (card.title) {
        return card.title;
      }
      return this.siteCardId;
    },
    cardIdType() {
      const card = this.card;
      // handle card cardIdType override
      if (card.cardIdType) {
        return card.cardIdType;
      }
      // check site-level cardIdType
      if (this.configCardIdType) {
        return this.configCardIdType;
      }
      return CardIdTypeRef;
    },
    siteCardId() {
      const card = this.card;
      if (!card) {
        return undefined;
      }
      // handle card cardIdType override
      if (card.cardIdType && card.cardIdType !== CardIdTypeRef && card.uids && card.uids[card.cardIdType]) {
        return card.uids[card.cardIdType];
      }
      // check site-level cardIdType
      if (this.configCardIdType !== CardIdTypeRef && card.uids && card.uids[this.configCardIdType]) {
        return card.uids[this.configCardIdType];
      }
      return card.id;
    },
    id() {
      if (this.title === this.siteCardId) {
        return '';
      }
      return this.siteCardId;
    }
  }
};
</script>

<style scoped>

</style>
