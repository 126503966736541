import {toNsRef} from '@/util/refs';
import {addTime, startOfDayUTC, toDate, days} from '@/util/dates';
import {createSnippet} from '@/firebase';
import {computeEvents as computeEventsImpl} from './event';
import {isSameDay} from 'date-fns';

/**
 * Compute a string key based on the given data.
 *
 * @param {{ref: string | firebase.firestore.DocumentReference<any>, id?: string}} k
 * @return {string}
 */
export function calculateKey(k) {
  return `${k.ref.path || k.ref}.${k.id}`;
}

/**
 * Returns a query that finds all resources that are part of the given site group
 *
 * @param {kahu.firestore.Site & DecoratedData} site
 * @return {firebase.firestore.Query<kahu.firestore.Resource>}
 */
export function resourceQueryForSite(site) {
  const db = site.ref.firestore;
  const ns = toNsRef(site.ref);
  // translate the site to into the groups collection
  const siteRef = ns.collection('groups').doc(site.ref.id);
  /** @type {firebase.firestore.QuerySnapshot} */
  return ns.collection('resources')
      .where('_groups_ancestors', 'array-contains', siteRef)
      .where('kind.ref', '==', db.doc('resource-kinds/room'));
}

/**
 * Returns a query that finds all bookings in the given period for the given resources.
 *
 * @param {firebase.firestore.CollectionReference} collection Where to look for bookings
 * @param {firebase.firestore.DocumentReference<any>[]} refs The resource refs that are booked
 * @param {firebase.firestore.Timestamp|Date} fromTime
 * @param {firebase.firestore.Timestamp|Date} toTime
 * @return {firebase.firestore.Query<kahu.firestore.Booking>}
 */
export function bookingQueryInCollection(collection, refs, fromTime, toTime) {
  return collection
      .where('_reservations_resource_ref', 'array-contains-any', refs)
      .where('_boundsPeriod.fromTime', '>=', fromTime)
      .where('_boundsPeriod.fromTime', '<', toTime);
}

/**
 * Returns a query that finds all bookings in the given period for the given resources.
 *
 * @param {firebase.firestore.CollectionReference} collection Where to look for bookings
 * @param {firebase.firestore.DocumentReference<any>[]} refs The resource refs that are booked
 * @param {string} date
 * @return {firebase.firestore.Query<kahu.firestore.Booking>}
 */
export function bookingQueryInCollectionOnDate(collection, refs, date) {
  return collection
      .where('_reservations_resource_ref', 'array-contains-any', refs)
      .where(`_boundsPeriodSpans.${date}`, '==', true);
}

/**
 * Create a new Booking for the given resource at the given times.
 *
 * @param {firebase.firestore.Timestamp | Date} start
 * @param {firebase.firestore.Timestamp | Date} end
 * @param {kahu.firestore.Resource & DecoratedData} resource
 * @param {kahu.firestore.Creatable} [creatableSnippet]
 * @return {kahu.firestore.Booking}
 */
export function newBookingData(start, end, resource, creatableSnippet) {
  const creatable = creatableSnippet || {};
  const fromTime = toDate(start);
  const toTime = toDate(end);
  const newBooking = /** @type {kahu.firestore.Booking} */ {
    ...creatable,
    bookedPeriod: {
      fromTime,
      toTime
    },
    reservations: {
      r1: {
        resource: createSnippet(resource),
        requiresCheckIn: true
      }
    }
  };
  // functions normally manage this, but set it now to avoid flash-of-stale-data
  newBooking._boundsPeriod = newBooking.bookedPeriod;
  const spans = calcBoundsPeriodSpans(fromTime, toTime);
  if (spans) {
    newBooking['_boundsPeriodSpans'] = spans;
  }
  return newBooking;
}

/**
 * Converts an array of bookings into an array of events.
 *
 * @param {import('vuex').ActionContext} context
 * @param {(kahu.firestore.Booking & DecoratedData)[]} bookings
 * @return {CalendarEvent[]}
 */
export function computeEvents(context, bookings) {
  return computeEventsImpl(context, bookings);
}

/**
 * @param {Date|firebase.firestore.Timestamp} fromTime
 * @param {Date|firebase.firestore.Timestamp} toTime
 * @return {null|Object<string, boolean>}
 */
export function calcBoundsPeriodSpans(fromTime, toTime) {
  if (!fromTime || !toTime) return null;

  const boundsDays = [];
  const from = typeof fromTime.toDate === 'function' ? fromTime.toDate() : fromTime;
  const to = typeof toTime.toDate === 'function' ? toTime.toDate() : toTime;
  let day = startOfDayUTC(from);
  let count = 0;
  const MAX_DAYS = 365;

  boundsDays.push(day);
  while (!isSameDay(day, to)) {
    day = addTime(day, days);
    boundsDays.push(day);
    count++;

    if (count > MAX_DAYS) {
      break;
    }
  }

  return boundsDays.reduce((spans, day) => {
    const iso = day.toISOString();
    spans[iso.substring(0, 10)] = true;
    spans[iso.substring(0, 7)] = true; // month
    spans[iso.substring(0, 4)] = true; // year
    return spans;
  }, {});
}
