import * as impl from '@/views/room-booking/calendar/v2/room-booking';
import {byRef} from '@/store/firestore-util';
import {vueSet} from 'vue-deepset';
import {createSnippet, removeCircularReferences, updateDataToArray} from '@/firebase';
import Vue from 'vue';
import {reservationToEvent} from '@/views/room-booking/calendar/v2/event';
import {BookingUtil} from '@/views/room-booking/calendar/booking-util';

export class EventStoreMixin {
  /**
   * Vuex getters to mixin with a booking store which deals with events
   *
   * Requires a store getter with the name `events`
   *
   * @return {Object}
   */
  static getters() {
    return {
      eventByKey(state, getters) {
        /** @type {CalendarEvent[]} */
        const events = getters.events;
        const byKey = {};
        events.forEach(e => byKey[impl.calculateKey(e.key)] = e);
        return k => k && byKey[impl.calculateKey(k)];
      },
      // Returns a function that accepts a ref and returns an array of events that have a matching connection.ref
      eventsByConnectionRef(state, getters) {
        const index = {};
        getters.events.forEach(event => {
          const connection = event.connection;
          if (connection) {
            const connectionRef = connection.event.ref;
            const bucket = index[connectionRef] || (index[connectionRef] = []);
            const anchor = connection.anchor;
            bucket.push({event, anchor});
          }
        });
        return ref => index[ref] || [];
      },
      eventsForBookingId(state, getters) {
        /** @type {CalendarEvent[]} */
        const events = getters.events;
        const byId = {};
        events.forEach(e => {
          const forBooking = byId[e.id] || (byId[e.id] = []);
          forBooking.push(e);
        });
        return id => byId[id] || [];
      }
    };
  }
}

export class ResourceStoreMixin {
  /**
   * Vuex store state to mixin with a booking store which deals with resources
   *
   * @return {Object}
   */
  static state() {
    return {
      /**
       * @type {DecoratedData<kahu.firestore.Resource>[]}
       */
      resources: []
    };
  }

  static stateClear(state) {
    state.resources = [];
  }

  /**
   * Vuex store mutations to mixin with a booking store which deals with resources
   *
   * @return {Object}
   */
  static mutations() {
    return {
      setResources(state, resources) {
        state.resources = resources;
      },
      addResource(state, resource) {
        state.resources.push(resource);
      },
      addResources(state, resources) {
        state.resources.push(...resources);
      }
    };
  }

  /**
   * Vuex getters to mixin with a booking store which deals with resources
   *
   * Requires store state with the name `resources`
   *
   * @return {Object}
   */
  static getters() {
    return {
      resources(state) {
        return state.resources;
      },
      resourceByRef(state, getters) {
        const index = {};
        getters.resources.forEach(b => index[byRef(b)] = b);
        return ref => index[ref];
      },
      resourceByTitle(state, getters) {
        const byTitle = {};
        getters.resources.forEach(b => byTitle[b.title] = b);
        return title => byTitle[title];
      },
      resourcesBySiteTitle(state, getters, rootState, rootGetters) {
        const sitesById = rootGetters['sites/sitesById'];
        const bySiteTitle = {};
        getters.resources.forEach(r => {
          const ancestors = r._groups_ancestors || [];
          const ancestorIds = ancestors.map(a => a.id);
          for (const id of ancestorIds) {
            if (sitesById.hasOwnProperty(id)) {
              const title = sitesById[id].title;
              const site = bySiteTitle[title] || (bySiteTitle[title] = []);
              site.push(r);
            }
          }
        });
        return bySiteTitle;
      },
      siteResourcesByResourceTitle(state, getters) {
        const siteTitleByResourceTitle = getters.siteTitleByResourceTitle;
        const resourcesBySiteTitle = getters.resourcesBySiteTitle;
        return title => {
          const siteTitle = siteTitleByResourceTitle(title);
          return resourcesBySiteTitle[siteTitle];
        };
      },
      isFirstResourceForSite(state, getters) {
        const siteResourcesByResourceTitle = getters.siteResourcesByResourceTitle;
        return title => {
          const resources = siteResourcesByResourceTitle(title);
          return resources.findIndex(r => r.title === title) === 0;
        };
      },
      resourceCountForSite(state, getters) {
        const siteResourcesByResourceTitle = getters.siteResourcesByResourceTitle;
        return title => {
          const resources = siteResourcesByResourceTitle(title);
          return resources.length;
        };
      },
      siteTitleByResourceTitle(state, getters) {
        const bySiteTitle = getters.resourcesBySiteTitle;
        const byResourceTitle = {};
        Object.entries(bySiteTitle).forEach(([siteTitle, resources]) => {
          resources.forEach(r => {
            byResourceTitle[r.title] = siteTitle;
          });
        });
        return title => byResourceTitle[title] || '';
      },
      // Returns a function that accepts a ref and returns an array of resources that have a matching parents.ref
      resourcesByParentRef(state, getters) {
        const index = {};
        getters.resources.forEach(b => {
          const parents = b.parents || [];
          parents.forEach(parent => {
            const ref = byRef(parent);
            const children = index[ref] || (index[ref] = []);
            children.push(b);
          });
        });
        return ref => index[ref] || [];
      },
      anyResourcesHaveM365SyncEnabled(state, getters) {
        return getters.resources.some(r => Boolean(r.thirdParty && r.thirdParty.graph && r.thirdParty.graph.uid));
      }
    };
  }
}

export class BookingStoreMixin {
  /**
   * Vuex store state to mixin with a booking store
   *
   * @return {Object}
   */
  static state() {
    return {
      /**
       * Keyed by booking id, each edit represents changes to a booking.
       *
       * @type {Object<string,Object>}
       */
      edits: {},
      /**
       * When a new booking is added, we put it here before committing the change.
       * These are Events not Bookings, if you need the booking use event.booking.
       *
       * @type {CalendarEvent[]}
       */
      added: [],
      /**
       * When a booking is deleted via non-direct methods, i.e. setup time is removed,
       * we add it here until the change is committed
       *
       * @type {CalendarEvent[]}
       */
      deleted: []
    };
  }

  static stateClear(state) {
    state.edits = {};
    state.added = [];
    state.deleted = [];
  }

  /**
   * Vuex store mutations to mixin with a booking store
   *
   * @return {Object}
   */
  static mutations() {
    return {
      addEvent(state, event) {
        state.added.push(event);
      },
      deleteEvent(state, event) {
        state.deleted.push(event);
      },
      restoreEvent(state, event) {
        const deletedIndex = state.deleted.findIndex(e => e === event);
        if (deletedIndex >= 0) {
          state.deleted.splice(deletedIndex, 1);
        }
      },

      recordEdit(state, {booking, property, value}) {
        const edit = state.edits[booking.id] || Vue.set(state.edits, booking.id, {});
        if (property) Vue.set(edit, property, value);
      },
      resetEditProp(state, {booking, property}) {
        const edits = state.edits[booking.id];
        if (!edits) return;
        Vue.delete(edits, property);
        if (Object.keys(edits).length === 0) {
          Vue.delete(state.edits, booking.id);
        }
      },
      resetEdit(state, booking) {
        Vue.delete(state.edits, booking.id);

        // handle the case where the event has been deleted
        const deletedIndex = state.deleted.findIndex(e => e.booking.id === booking.id);
        if (deletedIndex >= 0) {
          state.deleted.splice(deletedIndex, 1);
        }
        // handle the case where the edit is part of a new booking
        const addedIndex = state.added.findIndex(e => e.booking.id === booking.id);
        if (addedIndex >= 0) {
          state.added.splice(addedIndex, 1);
        }
      }
    };
  }

  /**
   * Vuex actions to mixin with a booking store which deals with booking/event edits
   *
   * Requires a `added`, `edits`, `deleted` state and an `editComplete` mutation
   *
   * @param {Logger} log
   * @return {Object}
   */
  static actions(log) {
    return {
      createNewBooking(context, {start, end, category, approved}) {
        log.debug('createNewBooking', {start, end, category, approved});
        const {getters, commit, rootGetters} = context;
        const resource = getters.resourceByTitle(category);
        if (!resource) return; // no resource!
        const creatableSnippet = rootGetters['user/creatableSnippet'];
        const bookingsCol = resource.ref.parent.parent.collection('bookings');
        const newBooking = impl.newBookingData(start, end, resource, creatableSnippet);

        // make the object look decorated
        Object.defineProperty(newBooking, 'ref', {
          enumerable: false,
          writable: false,
          value: removeCircularReferences(bookingsCol.doc())
        });
        Object.defineProperty(newBooking, 'id', {
          enumerable: false,
          get() {
            return this.ref.id;
          }
        });
        // this is a future document, we haven't added it to firestore yet
        Object.defineProperty(newBooking, 'exists', {
          enumerable: false,
          writable: false,
          value: false
        });
        const events = impl.computeEvents(context, [newBooking]);
        // the first event is the one that we will choose to be the primary event!
        // todo: this will need to change if we support creating events with multiple locations
        const event = events[0];
        commit('addEvent', event);
        commit('recordEdit', {booking: newBooking});
        event.approval = approved;
        return event;
      },

      addReservationToBooking(context, {booking, resource}) {
        log.debug('addReservationToBooking', {booking, resource});
        const {commit, state} = context;
        const bookingEdits = state.edits[booking.id];
        const bu = new BookingUtil(booking, bookingEdits);
        const nextKey = bu.nextReservationKey;
        const reservation = /** @type {kahu.firestore.Reservation} */ {
          resource: createSnippet(resource),
          requiresCheckIn: true
        };
        commit('addResource', resource);
        // record separate edits so the `resource` property of the event picks up the edit
        commit('recordEdit', {
          booking,
          property: `reservations.${nextKey}.requiresCheckIn`,
          value: reservation.requiresCheckIn
        });
        commit('recordEdit', {
          booking,
          property: `reservations.${nextKey}.resource`,
          value: reservation.resource
        });
        const event = reservationToEvent(context, booking, nextKey, reservation);
        commit('addEvent', event);
      },

      /**
       * @param {import('vuex').ActionContext} context
       * @param {Object} payload
       * @param {DecoratedData & kahu.firestore.Booking} payload.booking
       * @param {firebase.firestore.WriteBatch} [payload.batch]
       * @return {Promise<*>}
       */
      commitEdit({state, commit}, {booking, batch}) {
        const actions = batchActions(batch);
        const added = state.added.findIndex(e => e.booking.id === booking.id) !== -1;
        const deleted = state.deleted.findIndex(e => e.booking.id === booking.id) !== -1;
        const edited = state.edits.hasOwnProperty(booking.id);
        if (!added && !edited && !deleted) return Promise.resolve(); // nothing to do

        let updatePromise = Promise.resolve();
        if (deleted) {
          updatePromise = actions.delete(booking.ref);
        } else {
          const edit = state.edits[booking.id];

          // note: in the case where the booking already exists (i.e. it's an update)
          // this step is technically not needed as the firestore update will immediately
          // let us know of the new booking state. However in the create case and generally
          // this might be needed to avoid a flash of old content
          Object.entries(edit).forEach(([prop, value]) => vueSet(booking, prop, value));
          if (added) {
            updatePromise = actions.set(booking.ref, booking);
          } else {
            updatePromise = actions.update(booking.ref, ...updateDataToArray(edit));
          }
        }

        commit('editComplete', booking);
        return updatePromise;
      },

      deleteBooking({}, event) {
        /** @type {firebase.firestore.DocumentReference} */
        const ref = event.booking.ref;
        return ref.delete();
      }
    };
  }
}

/**
 * Return an abstraction over a possible absent batch
 *
 * @param {firebase.firestore.WriteBatch} [batch]
 * @return {{set:function, update:function, delete:function}}
 */
function batchActions(batch) {
  if (batch) {
    return {
      delete(ref) {
        batch.delete(ref);
        return Promise.resolve();
      },
      set(ref, ...args) {
        batch.set(ref, ...args);
        return Promise.resolve();
      },
      update(ref, ...args) {
        batch.update(ref, ...args);
        return Promise.resolve();
      }
    };
  } else {
    return {
      delete(ref) {
        return ref.delete();
      },
      set(ref, ...args) {
        return ref.set(...args);
      },
      update(ref, ...args) {
        return ref.update(...args);
      }
    };
  }
}
