import {dbUtil, decorateSnapshot} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import {db} from '../firebase';
import firebase from 'firebase/app';
import userProfile from '@/store/user-profile';

const log = Logger.get('user');
const profileProp = 'person';

export default {
  namespaced: true,
  state: {
    user: {}
  },
  mutations: {
    ...DeferUtil.mutations(log),
    updateUser(state, payload) {
      state.user = payload;
    },
    clear(state) {
      state.user = {};
    }
  },
  getters: {
    /**
     * Snippet used for auditable documents/edits
     *
     * @param {Object} state
     * @param {Object} getters
     * @param {Object} rootState
     * @param {Object} rootGetters
     * @return {kahu.firestore.Auditable}
     */
    auditSnippet(state, getters, rootState, rootGetters) {
      const snippet = {
        authUid: rootGetters['auth/authUser'].uid,
        ref: /** @type {firebase.firestore.DocumentReference} */ state.user.ref
      };
      const linkedPerson = state.user.person;
      if (linkedPerson) {
        if (linkedPerson.title) {
          snippet.title = linkedPerson.title;
        }
        if (linkedPerson.displayName) {
          snippet.displayName = linkedPerson.displayName;
        }
      }
      return {
        lastWrittenBy: snippet,
        lastWrittenVia: {
          ref: snippet.ref.firestore.doc('tools/workplace-app'),
          title: 'Workplace App'
        }
      };
    },
    /**
     * Snippet used for creatable documents (bookings)
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {kahu.firestore.Creatable}
     */
    creatableSnippet(state, getters) {
      const auditable = getters.auditSnippet;
      return {
        createdBy: {
          ...auditable.lastWrittenBy
        },
        createdVia: {
          ...auditable.lastWrittenVia
        }
      };
    },
    userDocLoaded(state) {
      return Object.keys(state.user).length > 0;
    },
    displayName(state) {
      const p = state.profile.person;
      const u = state.user;
      if (p.hasOwnProperty('displayName')) {
        return p.displayName;
      }
      if (u.hasOwnProperty('displayName')) {
        return u.displayName;
      }
      if (p.hasOwnProperty('title')) {
        return p.title;
      }
      return u.title;
    },
    profileSnippet(state) {
      if (!state.user) return null;
      return state.user[profileProp];
    },
    profileRef(state, getters) {
      if (!getters.profileSnippet) return null;
      return getters.profileSnippet.ref;
    },
    hasProfile(state, getters) {
      return Boolean(getters.profileSnippet);
    },
    getUser(state) {
      return state.user;
    },
    userSiteIds(state) {
      if (!state.user.hasOwnProperty('sites')) {
        return [];
      }
      return state.user.sites;
    },
    // use user.profile.preferredSite instead of this
    // though this is used as a fall-back
    preferredSiteId(state) {
      if (!state.user) return null;
      return state.user.defaultSite;
    },
    // use user.profile.preferredSite instead of this
    // though this is used as a fall-back
    preferredSiteRef(state, getters, rootState) {
      if (!getters.preferredSiteId) return null;
      const sitesById = rootState.sites.sitesById;
      const preferredSite = sitesById[getters.preferredSiteId];
      return preferredSite && preferredSite.ref;
    },
    preferredFloor(state) {
      return null;
    },

    userProfile(state) {
      return state.user && state.user.profile;
    }
  },
  actions: {
    init: {
      root: true,
      handler({dispatch, commit, rootGetters}) {
        this.watch(
            () => [rootGetters['auth/authUser'].uid],
            async ([uid]) => {
              if (!uid) {
                commit('reset');
                commit('clear');
                commit('app/clear', 'user', {root: true});
                return;
              }

              commit('app/loading', 'user', {root: true});
              try {
                commit('defer', await dispatch('bindUserDoc', uid));
              } catch (e) {
                log.error('bindUserDoc after authStateChanged', e);
              } finally {
                commit('app/loaded', 'user', {root: true});
              }
            },
            {immediate: true}
        );
      }
    },
    async bindUserDoc({commit, getters, dispatch, rootGetters}, uid) {
      log.debug('bindUserDoc uid=' + uid);

      const defer = {};
      const doc = await dbUtil.doc('/users', uid);
      /** @type {firebase.firestore.DocumentReference|null} */
      // watch all user changes, but await the first document snapshot we receive
      await new Promise((resolve, reject) => {
        defer.user = doc.onSnapshot(userSnap => {
          commit('updateUser', decorateSnapshot(userSnap));
          resolve();
        }, err => {
          log.error(`${doc.path}.onSnapshot`, err);
          commit('reset');
          commit('clear');
          reject(err);
        });
      });
      return defer;
    },
    async getUserDetails({commit}, uid) {
      log.debug('getUserDetails');
      const _db = await db;
      const doc = await _db.doc(`users/${uid}`).get();
      commit('updateUser', decorateSnapshot(doc));
      return doc;
    },
    async updateDefaultSite({state}, defaultSite) {
      if (state.user.ref && typeof state.user.ref.update === 'function') {
        // note: defaultSite changes aren't audited
        return state.user.ref.update({defaultSite});
      } else {
        throw new Error(`state.user doesn't have a ref to update`);
      }
    },

    async addUserFilter({state}, {filter, key}) {
      if (state.user.ref && typeof state.user.ref.update === 'function') {
        const updates = {
          [`profile.roomBookingCalendarFilters.${key}`]: filter
        };
        return state.user.ref.update(updates);
      } else {
        throw new Error(`state.user doesn't have a ref to update`);
      }
    },
    async deleteUserFilter({state}, id) {
      if (state.user.ref && typeof state.user.ref.update === 'function') {
        const updates = {
          [`profile.roomBookingCalendarFilters.${id}`]: firebase.firestore.FieldValue.delete()
        };
        return state.user.ref.update(updates);
      } else {
        throw new Error(`state.user doesn't have a ref to update`);
      }
    }
  },
  modules: {
    profile: userProfile
  }
};
