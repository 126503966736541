// polyfills
import 'intersection-observer';

import Vue from 'vue';
import App from './App.vue';
import './plugins/apex-charts';
import './plugins/logging';
import './plugins/moment';
import './plugins/v-calendar';
import './plugins/vue-annotator';
import router from './router/router';
import store from './store';
import vuetify from './plugins/vuetify';
import {Logger} from '@vanti/vue-logger';
import {Notify} from '@vanti/vue-notify';
import '@/styles/styles.css';

const log = Logger.get('main');
log.info(`Current application version: ${store.state.versions.VERSION} @ ${store.state.versions.COMMITHASH}`);

Vue.config.productionTip = false;

Vue.use(Notify, {store});

const app = new Vue({
  router,
  store,
  vuetify,
  created() {
    store.dispatch('auth/bind')
        .catch(err => log.error('Failed to bind the server ', err));
  },
  render: h => h(App)
});
app.$mount('#app');

// make the root vue app available for testing
if (window.Cypress) {
  window.app = app;
}
