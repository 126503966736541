import {decorateSnapshot} from '@/firebase';
import {Logger} from '@vanti/vue-logger';
import {resourceQueryForSite} from '@/views/room-booking/calendar/v2/room-booking';
import {LoadingUtil} from '@/util/loading';

const log = Logger.get('resources');

export default {
  namespaced: true,
  state: {
    selectedSite: null,
    resources: [],
    ...LoadingUtil.state()
  },
  getters: {
    ...LoadingUtil.getters(log)
  },
  mutations: {
    setSelectedSite(state, id) {
      state.selectedSite = id;
    },
    setResources(state, r) {
      state.resources = r;
    },
    ...LoadingUtil.mutations()
  },
  actions: {
    init: {
      root: true,
      handler({state, commit, dispatch, rootGetters}) {
        this.watch(
            () => state.selectedSite,
            siteId => {
              if (siteId) {
                commit('loading', 'resources');
                const ns = rootGetters['ns/nsRef'];
                const siteRef = ns.collection('groups').doc(siteId);
                dispatch('listResources', {siteRef});
              } else {
                commit('setResources', []);
              }
            },
            {immediate: true}
        );
      }
    },
    async listResources({commit}, {siteRef}) {
      log.debug('listResources', siteRef.path);
      const resources = await resourceQueryForSite({ref: siteRef}).get();
      commit('setResources', resources.docs.map(snap => decorateSnapshot(snap)));
      commit('loaded', 'resources');
    }
  }
};
