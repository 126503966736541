<template>
  <div class="d-flex">
    <span v-if="noDelegates" class="text--secondary">{{ noDelegatesMessage }}</span>
    <span v-else>
      <template v-for="(d, k) in sorted">
        <router-link :key="k" :to="link(d)" class="mr-1">{{ d.title || d.ref && d.ref.id }}</router-link>
      </template>
    </span>

    <template v-if="person">
      <v-spacer/>
      <person-delegates-edit :person="person"/>
    </template>
  </div>
</template>

<script>
import PersonDelegatesEdit from '@/components/person/PersonDelegatesEdit.vue';
import {byTitle} from '@/util/compare';
export default {
  name: 'PersonDelegatesSummary',
  components: {PersonDelegatesEdit},
  props: {
    delegates: {
      type: Object,
      default: null
    },
    person: {
      type: Object,
      default: null
    },
    noDelegatesMessage: {
      type: String,
      default: '(no delegates)'
    }
  },
  computed: {
    noDelegates() {
      return !this.delegates || Object.keys(this.delegates).length === 0;
    },
    sorted() {
      return Object.values(this.delegates || {}).sort(byTitle);
    }
  },
  methods: {
    link(d) {
      const id = d.ref && d.ref.id;
      if (!id) {
        return '';
      }
      return {name: 'person', params: {id}};
    }
  }
};
</script>

<style scoped>
div a::after {
  content: ',';
}
div a:last-child::after {
  content: none;
}
</style>
