import nested from 'nested-property';
import Vue from 'vue';

/**
 * Store util for use with editable values.
 */
export class EditableUtil {
  /**
   * Mutations for use with editValue
   *
   * @return {Object}
   */
  static mutations() {
    return {
      recordEdit(state, {document, property, value}) {
        const edit = state.edits[document.id] || Vue.set(state.edits, document.id, {});
        if (property) Vue.set(edit, property, value);
      }
    };
  }
}

/**
 * Reset value and remove from edits
 *
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} document
 * @param {string} property
 */
export function resetEditValue({state}, document, property) {
  if (state.edits[document.id]) {
    Vue.delete(state.edits[document.id], property);
    // Check if the editable has any other changes
    if (Object.values(state.edits[document.id]).length === 0) {
      // If it doesn't, delete the editable from the edits object
      Vue.delete(state.edits, document.id);
    }
  }
}

/**
 * Edit the given property to set it to the given value.
 *
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} document
 * @param {string} property
 * @param {*} value
 */
export function editValue({commit}, document, property, value) {
  commit('recordEdit', {document, property, value});
}

/**
 * Gets the value for a document, preferring edited values from the store over document values.
 *
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} document
 * @param {string} property
 * @return {*}
 */
export function editedValue({state}, document, property) {
  const id = document.id;
  if (state.edits[id] && state.edits[id].hasOwnProperty(property)) {
    return state.edits[id][property];
  }
  return nested.get(document, property);
}
