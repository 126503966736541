/**
 * @typedef {string|string[]} Name
 */

/**
 * Represents a number of locations where config can be found.
 *
 * Each location passed into the constructor will be asked if the 'config.<name>' value exists, the first matching value
 * will be returned from config requests.
 */
export class CascadingConfig {
  /**
   * @param {Object[]} [locations] Objects containing config.
   * @param {string|null} [configProperty] The property in the location objects to use for config,
   * null to use the location itself
   */
  constructor(locations, configProperty = 'config') {
    this.locations = locations || [];
    this.configProperty = configProperty;
  }

  /**
   * Get the config value with the given name.
   *
   * @param {Name} name The name of the config property
   * @param {T} [fallback] A value to use if no config is present
   * @return {T} The config value or fallback if none found
   * @template {*} T
   */
  get(name, fallback) {
    const get = CascadingConfig.resolver(name, this.configProperty);
    for (const location of this.locations) {
      const val = get(location);
      if (val.exists) {
        return val.value;
      }
    }
    return fallback;
  }

  /**
   * Does the config name exist.
   *
   * @param {Name} name
   * @return {boolean} true if the name exists in any of the configured locations, even if the value is null.
   */
  has(name) {
    const get = CascadingConfig.resolver(name, this.configProperty);
    for (const location of this.locations) {
      if (get(location).exists) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns an object that includes both the value and whether it exists.
   *
   * @param {Name} name
   * @return {{exists: boolean, value: *}}
   */
  resolve(name) {
    const get = CascadingConfig.resolver(name, this.configProperty);
    for (const location of this.locations) {
      const val = get(location);
      if (val.exists) {
        return val;
      }
    }
    return {exists: false};
  }

  /**
   * Returns a function that resolves the given name against a passed object.
   *
   * @param {Name} name
   * @param {string|null} configProperty The property in the location objects to use for config,
   * null to use the location itself
   * @return {function(any):{exists:boolean,value:any}}
   */
  static resolver(name, configProperty) {
    const configObj = obj => configProperty ? obj && obj[configProperty] : obj;

    const partResolver = (obj, name) => {
      const exists = typeof obj === 'object' && obj !== null && name in obj;
      if (exists) {
        return {
          exists,
          value: obj[name]
        };
      } else {
        return {exists};
      }
    };

    const parts = Array.isArray(name) ? name : name.split('.');

    if (parts.length === 1) {
      // simple obj.property case
      return location => {
        if (location instanceof CascadingConfig) return location.resolve(name);
        if (configObj(location)) {
          return partResolver(configObj(location), name);
        } else {
          // no config property
          return {exists: false};
        }
      };
    }

    // more complicated case
    return location => {
      if (location instanceof CascadingConfig) return location.resolve(parts);
      if (configObj(location)) {
        let obj = configObj(location);
        for (const part of parts) {
          const res = partResolver(obj, part);
          if (!res.exists) {
            return res;
          } else {
            obj = res.value;
          }
        }
        return {exists: true, value: obj};
      } else {
        // no config property
        return {exists: false};
      }
    };
  }
}
