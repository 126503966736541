<template>
  <v-dialog v-if="desktop" v-model="open" max-width="800">
    <template #activator="{on}">
      <v-btn icon class="mr-5" v-on="on">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2">
      <v-card-title>Card Printing</v-card-title>
      <v-card-text class="content">
        <v-card flat>
          <v-card-title class="subtitle-1 pb-0 font-weight-bold">Preview</v-card-title>
          <v-card-text>
            <div
                class="id-card"
                :style="{
                  fontSize: options.fontSize.v + options.fontSize.unit
                }"
                :class="{
                  'picture': options.includeImage.v,
                  'with-logo': includeLogo,
                  'portrait': orientation === 'portrait',
                  'top-hole': topHole
                }">
              <div v-if="options.includeImage.v" class="picture">
                <img v-if="profilePicture" :src="profilePicture" :style="pictureStyle" alt="Profile Picture">
                <v-icon v-else size="72" color="grey lighten-4">mdi-account</v-icon>
              </div>
              <div class="name">{{ name }}</div>
              <div v-if="includeLogo" class="ns-logo">
                <img :src="nsLogo" alt="NS Logo">
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card flat>
          <v-card-title class="subtitle-1 pb-0 font-weight-bold">Options</v-card-title>
          <v-card-text class="pb-0">
            <v-list>
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Orientation</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-radio-group v-model="orientation" column>
                    <v-radio
                        label="Landscape"
                        value="landscape"/>
                    <v-radio
                        label="Portrait"
                        value="portrait"/>
                  </v-radio-group>
                </v-list-item-action>
              </v-list-item>
              <v-divider/>
              <template v-for="option in options">
                <v-list-item :key="option.t" :value="option.v">
                  <template #default>
                    <v-list-item-content>
                      <v-list-item-title>{{ option.t }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <template v-if="option.control === 'checkbox'">
                        <v-checkbox v-model="option.v" color="accent darken-1"/>
                      </template>
                      <template v-else-if="option.control === 'number'">
                        <v-text-field
                            type="number"
                            v-model="option.v"
                            dense
                            hide-details
                            :min="option.min"
                            :max="option.max"
                            :suffix="option.unit"/>
                      </template>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider :key="option.t + 'divider'"/>
              </template>
            </v-list>
            <v-text-field v-model="name" label="Name" clearable/>
          </v-card-text>
        </v-card>
        <v-card flat v-if="options.includeImage.v">
          <v-card-title class="subtitle-1 pb-0 font-weight-bold">Image Options</v-card-title>
          <v-card-text class="pb-0">
            <v-file-input
                v-model="file"
                label="Select a new image to upload"
                accept="image/*"
                @change="upload"
                :loading="uploading"/>
            <div class="mt-1 mb-1">Image Adjustments:</div>
            <person-card-printing-adjustment v-model="imageAdjustment"/>
          </v-card-text>
        </v-card>
        <v-card flat class="fit-content br">
          <v-card-text>
            <b>To print, press print below, then on the print dialog:</b>
            <ol>
              <li>Ensure margin is set to none</li>
              <li>Ensure header + footer option is disabled</li>
            </ol>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" class="primary-text--text" @click="printDialog">
          <v-icon left>mdi-printer</v-icon>Print
        </v-btn>
        <v-btn @click="open = false" outlined color="darken-1">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import breakpoints from '@/plugins/breakpoints';
import {mapActions, mapGetters} from 'vuex';
import {storage} from '@/firebase';
import PersonCardPrintingAdjustment from '@/components/person/PersonCardPrintingAdjustment';

export default {
  name: 'PersonCardPrinting',
  components: {PersonCardPrintingAdjustment},
  mixins: [breakpoints],
  data() {
    return {
      open: false,
      orientation: 'landscape',
      file: null,
      uploading: false,
      profilePicture: '',
      nsLogo: '',
      imageAdjustment: {
        left: 0,
        top: 0,
        scale: 1.0,
        rotate: 0
      },
      options: {
        includeImage: {t: 'Include Image', v: true, control: 'checkbox'},
        includeLogo: {t: 'Include logo', v: false, control: 'checkbox'},
        topHole: {t: 'Allow for top hole', v: false, control: 'checkbox'},
        fontSize: {t: 'Font Size', v: 6, control: 'number', unit: 'mm', min: 1, max: 10}
      },
      name: null
    };
  },
  computed: {
    ...mapGetters('auth', ['ns']),
    ...mapGetters('ns', ['logoPath']),
    ...mapGetters('views/person', ['person']),
    profilePicPath() {
      return this.person && this.person.profilePicture || '';
    },
    pictureStyle() {
      return {
        left: this.imageAdjustment.left + 'mm',
        top: this.imageAdjustment.top + 'mm',
        transform: `scale(${this.imageAdjustment.scale}) rotate(${this.imageAdjustment.rotate}deg)`
      };
    },
    title() {
      return this.person && this.person.title || '';
    },
    includeImage() {
      return this.options.includeImage.v;
    },
    includeLogo() {
      return this.options.includeLogo.v;
    },
    topHole() {
      return this.options.topHole.v;
    },
    fontSize() {
      return this.options.fontSize.v;
    }
  },
  watch: {
    profilePicPath: {
      immediate: true,
      async handler(path) {
        if (!path) {
          this.profilePicture = '';
          return;
        }
        if (!this.open) {
          return;
        }

        try {
          await this.loadProfilePicture();
        } catch (e) {
          this.$logger.debug('error loading profile picture', e);
        }
      }
    },
    open: {
      immediate: true,
      async handler(open) {
        if (open && !this.profilePicture) {
          try {
            await this.loadProfilePicture();
          } catch (e) {
            this.$logger.debug('error loading profile picture', e);
          }
        }
      }
    },
    includeLogo: {
      immediate: true,
      async handler(includeLogo) {
        if (includeLogo) {
          try {
            await this.loadNsLogo();
          } catch (e) {
            this.$logger.debug('error loading ns logo', e);
          }
        }
      }
    },
    title: {
      immediate: true,
      handler(t) {
        this.name = t;
      }
    }
  },
  methods: {
    ...mapActions('views/person', ['updatePerson']),
    async loadProfilePicture() {
      if (!this.profilePicPath) {
        return;
      }
      this.$logger.debug('loadProfilePicture', this.profilePicPath);
      const store = await storage;
      this.profilePicture = await store.ref(this.profilePicPath).getDownloadURL();
    },
    async loadNsLogo() {
      if (!this.logoPath || this.nsLogo) {
        return;
      }

      this.$logger.debug('loadNsLogo', this.logoPath);
      const store = await storage;
      this.nsLogo = await store.ref(this.logoPath).getDownloadURL();
    },
    async upload() {
      try {
        if (!this.file) {
          return;
        }
        const file = this.file;
        this.$logger.debug('upload', file);
        const ext = file.name.split('.').pop();
        this.uploading = true;
        const store = await storage;
        const refPath = `ns/${this.ns}/people/${this.person.ref.id}.${ext}`;
        await store.ref(refPath).put(file);

        const personUpdate = {profilePicture: refPath};
        Object.defineProperty(personUpdate, 'ref', {value: this.person.ref, enumerable: false});

        await this.updatePerson(personUpdate);
        this.file = null;
        this.$notify.showSuccess(`Picture uploaded`);
      } catch (e) {
        this.$notify.showError(`Error uploading ${e}`);
        this.$logger.debug('error uploading picture', e);
      } finally {
        this.uploading = false;
      }
    },
    printDialog() {
      const popup = window.open('', '_blank', '', true);
      const classes = [];
      const containerClasses = [];
      if (this.includeImage) {
        classes.push('with-picture');
      }
      if (this.includeLogo) {
        classes.push('with-logo');
      }
      if (this.topHole) {
        classes.push('top-hole');
      }
      if (this.orientation === 'portrait') {
        classes.push('portrait');
        containerClasses.push('portrait');
      }
      popup.document.write(`
<html>
  <head>
    <title>Card Printing | ${this.name}</title>
  </head>
  <body>
  <div id="container" class="${containerClasses.join(' ')}">
    <div id="card" class="${classes.join(' ')}">
      ${this.includeImage ? '<div id="picture"></div>' : ''}
      <div class="name">${this.name}</div>
      ${this.includeLogo ? '<div id="logo"></div>' : ''}
    </div>
  </div>
  </body>
  <style>
  html {
    padding: 0;
    margin: 0
  }

  #container {
    transform: rotate(90deg) translateX(15mm) translateY(5mm);
    height: 54mm;
    width: 86mm;
  }

  #container.portrait {
    transform: rotate(0deg);
  }

  #card {
    height: 54mm;
    width: 86mm;
    display: grid;
    grid-template-columns: 82mm;
    justify-items: center;
    align-items: center;
    padding: 2mm;
    position: relative;
  }
  #card.portrait {
    height: 86mm;
    width: 54mm;
    grid-template-columns: 50mm!important;
    grid-template-rows: 55mm 10mm;
  }
  #card.with-picture {
    grid-template-columns: 26mm 55mm;
  }


  #card.with-picture #picture {
    display: block;
    overflow: hidden;
    height: 30mm;
    width: 22mm;
  }
  #card.portrait #picture {
    height: 40mm;
    width: 30mm;
  }

  #card.portrait.top-hole {
    padding-top: 12mm;
    grid-template-rows: 40mm 20mm;
  }
  #card.portrait.top-hole .picture {
    height: 36mm;
    width: 26mm;
  }

  img {
    max-height: 100%;
  }

  #picture img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    left: ${this.imageAdjustment.left}mm;
    top: ${this.imageAdjustment.top}mm;
    transform: scale(${this.imageAdjustment.scale}) rotate(${this.imageAdjustment.rotate}deg)
  }

  .name {
    font-size: ${this.fontSize}mm;
    line-height: 1.5em;
    font-family: sans-serif;
    font-weight: bold;
    font-variant-caps: petite-caps;
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
    word-break: break-word;
  }
  #card.portrait .name {
    text-align: center;
  }


  #logo {
    height: 8mm;
    position: absolute;
    right: 2mm;
    bottom: 2mm;
  }
  #logo img {
    max-height: 8mm;
  }
  #card.portrait #logo {
    position: relative;
  }
  #logo.left {
    right: unset;
    left: 2mm;
  }
  </style>
</html>
      `);
      const promises = [];
      if (this.includeImage && this.profilePicture) {
        // wait for the image to load
        const img = popup.document.createElement('img');
        popup.document.getElementById('picture').append(img);
        promises.push(new Promise(resolve => {
          img.onload = resolve;
        }));
        img.src = this.profilePicture;
      }
      if (this.includeLogo && this.nsLogo) {
        // wait for the image to load
        const img = popup.document.createElement('img');
        popup.document.getElementById('logo').append(img);
        promises.push(new Promise(resolve => {
          img.onload = resolve;
        }));
        img.src = this.nsLogo;
      }
      if (!this.includeLogo && !this.includeImage) {
        promises.push(new Promise(resolve => {
          setTimeout(resolve, 200);
        }));
      }

      Promise.all(promises)
          .then(() => {
            popup.focus();
            popup.print();
            popup.close();
          });
    }
  }
};
</script>

<style scoped>
.content {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100mm 75mm;
  grid-template-areas: "tl tr" "bl br";
}
.content .tl {
  grid-area: tl;
}
.content .tr {
  grid-area: tr;
}
.content .bl {
  grid-area: bl;
}
.content .br {
  grid-area: br;
}

.id-card {
  height: 54mm;
  width: 86mm;
  border: 1px solid black;
  border-radius: 5mm;
  display: grid;
  grid-template-columns: 82mm;
  justify-items: center;
  align-items: center;
  padding: 2mm;
  position: relative;
  color: black;
  letter-spacing: normal;
  font-size: 6mm;
}
.id-card.portrait {
  margin: 0 auto;
  height: 86mm;
  width: 54mm;
  grid-template-columns: 50mm!important;
  grid-template-rows: 44mm 20mm;
}

.id-card.picture {
  grid-template-columns: 26mm 55mm;
}

.id-card .picture {
  overflow: hidden;
  height: 30mm;
  width: 22mm;
  border: 1px solid grey;
}

.id-card.portrait .picture {
  height: 40mm;
  width: 30mm;
}

.id-card.portrait.top-hole {
  padding-top: 12mm;
  grid-template-rows: 40mm 20mm;
}
.id-card.portrait.top-hole .picture {
  height: 36mm;
  width: 26mm;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;
}

.id-card .picture i {
  height: 100%;
  width: 100%;
}

.id-card .name {
  line-height: 1.5em;
  font-family: sans-serif;
  font-weight: bold;
  font-variant-caps: petite-caps;
  width: 100%;
  max-height: 100%;
  overflow-y: hidden;
  word-break: break-word;
}

.id-card.portrait .name {
  text-align: center;
  line-height: 1;
}

.id-card .ns-logo {
  position: absolute;
  height: 8mm;
  right: 2mm;
  bottom: 2mm;
}

.id-card.portrait .ns-logo {
  position: relative;
}

.id-card .ns-logo.left {
  right: unset;
  left: 2mm;
}

.fit-content {
  height: fit-content;
}
</style>
