<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Change</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(c, index) in all">
        <tr :key="index">
          <td class="time">{{ c.change.changeTime | moment('L LT:ss') }}</td>
          <td><card-history-change :change="c"/></td>
        </tr>
      </template>
      <tr v-if="expectsMore" @click="loadMore" style="cursor: pointer;">
        <td colspan="2" class="text-center">
          <v-btn text>Load More</v-btn>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import {mapGetters} from 'vuex';
import CardHistoryChange from '@/views/people/card/components/CardHistoryChange';

export default {
  name: 'CardHistory',
  components: {CardHistoryChange},
  computed: {
    ...mapGetters('views/card/history', [
      'all',
      'latestRecord',
      'expectsMore'
    ])
  },
  watch: {
    latestRecord() {
      if (!this.latestRecord) {
        return;
      }
      this.$store.dispatch('views/card/history/bindLatest')
          .catch(err => this.$logger.error(err));
    }
  },
  mounted() {
    this.$store.dispatch('views/card/history/bind')
        .then(() => this.$store.dispatch('views/card/history/loadNextPage'))
        .catch(err => this.$logger.error(err));
  },
  beforeDestroy() {
    this.$store.dispatch('views/card/history/unbind')
        .catch(err => this.$logger.error(err));
  },
  methods: {
    loadMore() {
      this.$store.dispatch('views/card/history/loadNextPage')
          .catch(err => this.$logger.error(err));
    }
  }
};
</script>

<style scoped>
  td.time {
    width: 200px;
  }
</style>
