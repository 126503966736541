<template>
  <v-card outlined>
    <v-card-title class="font-weight-regular py-2 px-3">
      <component
          :is="assigned ? 'router-link': 'div'"
          :to="ownerLink"
          class="text-uppercase grey--text text--lighten-1 subtitle-1 link">
        {{ ownerName }}
      </component>
      <v-spacer/>
      <v-chip v-if="assignmentType" outlined small>{{ assignmentType }}</v-chip>
    </v-card-title>
    <v-card-actions class="py-2 px-3">
      <span class="grey--text subtitle-1" v-if="assigned">
        <template v-if="validFromFuture">
          Valid from {{ validFrom | moment('L LT') }}
        </template>
        <template v-else-if="validTo">
          {{ expiryText }} {{ validTo | moment('L LT') }}
        </template>
      </span>
      <v-spacer/>
      <card-returned v-if="assigned" :card="card"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import CardReturned from '@/views/people/card/components/CardReturned';
import moment from 'moment';

export default {
  name: 'CardAssignment',
  components: {CardReturned},
  props: {
    card: {
      type: Object,
      default: null
    },
    siteMembership: {
      type: Object,
      default: null
    }
  },
  computed: {
    assigned() {
      if (!this.card) {
        return false;
      }
      return this.card.owner;
    },
    isPermanent() {
      if (!this.card) {
        return false;
      }
      return this.card.role === 'permanent';
    },
    ownerName() {
      if (!this.card) {
        return 'Unassigned';
      }
      const owner = this.card.owner;
      if (!owner || !owner.title) {
        return 'Unassigned';
      }
      return owner.title;
    },
    ownerLink() {
      if (!this.card) {
        return {};
      }
      const owner = this.card.owner;
      if (!owner) {
        return {};
      }
      return {
        name: 'person',
        params: {
          id: owner.ref.id
        }
      };
    },
    assignmentType() {
      if (!this.card || !this.card.owner) {
        return '';
      }
      return this.isPermanent ? 'Permanent' : 'Temporary';
    },
    validFrom() {
      if (!this.card) {
        return null;
      }
      if (this.card.hasOwnProperty('enabledFrom')) {
        return this.card.enabledFrom;
      }
      if (this.isPermanent && this.siteMembership && this.siteMembership.hasOwnProperty('validFrom')) {
        return this.siteMembership.validFrom;
      }
      return null;
    },
    validFromFuture() {
      if (!this.validFrom) {
        return false;
      }
      const validFrom = moment(this.validFrom.toDate());
      const now = moment(); // todo: this isn't reactive
      return validFrom.isAfter(now);
    },
    validTo() {
      if (!this.card) {
        return null;
      }
      if (this.card.hasOwnProperty('enabledTo')) {
        return this.card.enabledTo;
      }
      if (this.isPermanent && this.siteMembership && this.siteMembership.hasOwnProperty('validTo')) {
        return this.siteMembership.validTo;
      }
      return null;
    },
    hasExpired() {
      if (!this.validTo) {
        return false;
      }
      const now = moment();
      const enabledTo = moment(this.validTo.toDate());
      return enabledTo.isBefore(now);
    },
    expiryText() {
      if (this.hasExpired) {
        return 'Expired on';
      }
      return 'Expires on';
    }
  }
};
</script>

<style scoped>
  .link {
    text-decoration: none;
  }
</style>
