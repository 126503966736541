<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="floor-plan" :style="rootStyle">
    <slot name="background"/>
    <div
        class="floor-plan--plan"
        :class="{'floor-plan--grayscale': grayscale}"
        v-html="planSvgContent"
        ref="plan"/>
    <div class="floor-plan--bookables" v-html="bookablesSvgContent" ref="bookables" v-tap="onBookableClick"/>
    <slot name="layers"/>
    <!-- Renderless components used for state management and interaction within the svgs -->
    <slot name="desks">
      <bookable-desk
          :utilisation-map="utilisationMap"
          v-for="({desk, bookable}) in bookableDesks"
          :key="desk.id"
          :bookable="bookable"
          :el="desk.el"
          :parts="desk"/>
    </slot>
    <slot name="foreground"/>
  </div>
</template>

<script>
import BookableDesk from '@/views/desk-booking/settings/map/BookableDesk';
import Tap from '@/directives/tap';
import {Floor} from '@/store/floor';
import {getDeskId} from '@/store/svg-util';

/**
 * Represents the display of a single floor. It consists of layered SVGs representing the floor plan, the bookable
 * resources, and any neighbourhoods.
 */
export default {
  name: 'FloorPlan',
  components: {BookableDesk},
  directives: {Tap},
  props: {
    floor: {
      type: Floor,
      required: true
    },
    utilisationMap: {
      type: Boolean,
      default: false
    },
    grayscale: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rootStyle() {
      const styles = {};
      if (this.floor.svgTargetSize) {
        styles.width = this.floor.svgTargetSize.width + 'px';
        styles.height = this.floor.svgTargetSize.height + 'px';
      }
      return styles;
    },
    planSvgContent() {
      return this.floor.planSvgContent;
    },
    bookablesSvgContent() {
      return this.floor.bookablesSvgContent;
    },
    svgBounds() {
      return this.floor.svgBounds;
    },
    bookableDesks() {
      return this.floor.bookableDesks;
    },
    unknownDesks() {
      return this.floor.unknownDesks;
    }
  },
  watch: {
    planSvgContent: {
      immediate: true,
      handler() {
        // give the svg element chance to load
        this.$nextTick(() => {
          this.onPlanSvgChanged();
        });
      }
    },
    bookablesSvgContent: {
      immediate: true,
      handler() {
        // give the svg element chance to load
        this.$nextTick(() => {
          this.onBookablesSvgChanged();
        });
      }
    },
    svgBounds: {
      immediate: true,
      deep: true,
      handler() {
        let attempts = 5;
        const attempt = () => {
          if (!this.onBookablesSvgChanged()) {
            attempts--;
            if (attempts > 0) {
              requestAnimationFrame(attempt);
            }
          }
        };
        attempt();
      }
    },
    unknownDesks: {
      immediate: true,
      handler() {
        for (const deskParts of this.unknownDesks) {
          if (deskParts.el) {
            deskParts.el.classList.add('disabled');
          }
        }
      }
    }
  },
  methods: {
    onPlanSvgChanged() {
      this.floor.bindPlanSvg(this.getSvgElement('plan'));
    },
    onBookablesSvgChanged() {
      return this.floor.bindBookablesSvg(this.getSvgElement('bookables'));
    },
    onBookableClick(e) {
      const deskEl = e.target.closest('g[id^=bookable_]');
      if (deskEl) {
        const id = getDeskId(deskEl);
        const found = this.floor.toggleActiveSpaceById(id);
        if (!found) {
          this.$notify.showError(`Desk with ID "${id}" cannot be edited`);
        }
      }
    },
    getSvgElement(refName) {
      /** @type {HTMLDivElement} */
      const div = this.$refs[refName];
      if (!div) {
        this.$logger.debug('no $refs.' + refName);
        return;
      }

      /** @type {SVGSVGElement} */
      const svg = div.firstChild;
      if (!svg) {
        this.$logger.debug('no $refs.' + refName + '.firstChild');
        return;
      }
      return svg;
    }
  }
};
</script>

<style scoped>
  .floor-plan {
    position: relative;
    display: grid;
  }

  .floor-plan > * {
    grid-area: 1 / 1;
  }

  .floor-plan--bookables >>> > svg,
  .floor-plan--plan >>> > svg {
    vertical-align: top;
  }

  .floor-plan--bookables {
    /* default colours */
    /*noinspection CssUnresolvedCustomProperty*/
    --color: var(--v-disabled-base, #8B909D);
    --text: #525a5c;
    /*noinspection CssUnresolvedCustomProperty*/
    --chair: var(--v-disabled-base, #8B909D);
  }

  .floor-plan--bookables >>> g[id^=bookable_] {
    transition: opacity 300ms ease;
  }

  .floor-plan--bookables >>> g[id^=bookable_].inactive {
    opacity: 0.5;
  }

  .floor-plan--bookables >>> g[id^=bookable_] {
    /*noinspection CssUnresolvedCustomProperty*/
    --color: var(--v-free-base);
  }

  .floor-plan--bookables >>> g[id^=bookable_].disabled,
  .floor-plan--bookables >>> g[id^=bookable_].denied {
    /*noinspection CssUnresolvedCustomProperty*/
    --color: var(--v-disabled-base) !important;
    /*noinspection CssUnresolvedCustomProperty*/
    --text: var(--v-disabled-base);
    /*noinspection CssUnresolvedCustomProperty*/
    --chair: var(--v-disabled-base);
  }

  .floor-plan--bookables >>> g[id^=bookable_].disabled.active,
  .floor-plan--bookables >>> g[id^=bookable_].denied.active {
    /*noinspection CssUnresolvedCustomProperty*/
    --text: var(--v-disabled-darken2);
  }

  .floor-plan--bookables >>> g[id^=bookable_].booked {
    /*noinspection CssUnresolvedCustomProperty*/
    --color: var(--v-booked-base);
  }

  .floor-plan--bookables >>> g[id^=bookable_].occupied {
    /*noinspection CssUnresolvedCustomProperty*/
    --color: var(--v-occupied-base);
  }

  .floor-plan--bookables >>> g[id^=bookable_] .tableFill,
  .floor-plan--bookables >>> g[id^=bookable_] .tableBorder {
    fill: var(--color);
  }

  .floor-plan--bookables >>> g[id^=bookable_] text {
    fill: var(--text);
  }

  .floor-plan--bookables >>> g[id^=bookable_] .tableFill {
    transition: opacity 200ms ease;
    opacity: 0.2;
  }

  .floor-plan--bookables >>> g[id^=bookable_].booked .tableFill,
  .floor-plan--bookables >>> g[id^=bookable_].occupied .tableFill {
    opacity: 0.4;
  }

  .floor-plan--bookables >>> g[id^=bookable_].active .tableFill {
    opacity: 0.7;
  }

  .floor-plan--bookables >>> g[id^=bookable_].solid .tableFill {
    opacity: 1;
  }

  .floor-plan--bookables >>> g[id^=bookable_].solid .tableBorder {
    opacity: 0.4;
  }

  /* default chair colour */
  .floor-plan--bookables >>> .chair {
    fill: var(--chair);
  }

  .floor-plan--grayscale {
    filter: grayscale(100%);
  }
</style>
