<template>
  <v-menu
      offset-y
      nudge-top="-1"
      v-model="open"
      :close-on-content-click="false"
      content-class="rounded-t-0">
    <template #activator="{on, attrs}">
      <v-text-field
          :label="label"
          :value="displayDate"
          v-on="on"
          v-bind="attrs"
          hide-details
          readonly
          class="date-field"/>
    </template>
    <v-date-picker
        no-title
        v-bind="$attrs"
        v-model="pickerDate"
        class="rounded-t-0"
        :first-day-of-week="localeFirstDayOfWeek"
        @input="open = false"/>
  </v-menu>
</template>

<script>
import {fromYearMonthDay, toYearMonthDay} from '@/util/dates';
import {mapGetters} from 'vuex';

export default {
  name: 'DatePickerField',
  props: {
    label: {
      type: String,
      default: 'Date'
    },
    value: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      date: null,
      open: false
    };
  },
  computed: {
    ...mapGetters('sites/active', ['localeFirstDayOfWeek']),
    displayDate() {
      return this.date && this.date.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }) || '';
    },
    localDate() {
      if (!this.date) {
        return null;
      }
      return toYearMonthDay(this.date);
    },
    pickerDate: {
      get() {
        return this.localDate;
      },
      set(v) {
        if (typeof v === 'string') {
          this.date = fromYearMonthDay(v);
        } else {
          this.date = v;
        }
        this.$emit('input', this.date);
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) {
          this.date = v;
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
