import {Logger} from '@vanti/vue-logger';
import {createStatisticsStore} from '@/views/dashboard/statistics/statistics-store';

const log = Logger.get('statistics/rooms');

const getCountsQuery = (site, chunk, countType = 'booking') => {
  const nsDoc = site.ref.parent.parent;
  const siteGroup = nsDoc.collection('groups').doc(site.id);

  return nsDoc.collection('counts')
      .where('countType', '==', countType)
      .where('onDate', 'in', chunk)
      .where('site.ref', '==', siteGroup);
};

const module = createStatisticsStore(
    log,
    getCountsQuery,
    'views/dashboard/counts/site/roomCapacity',
    'floors/groupFloorIds',
    'views/dashboard/statistics/rooms'
);

/**
 * This store is used for getting v2 counts for rooms
 * v2 counts are stored at ns/{ns}/counts
 */
export default {
  namespaced: true,
  state: module.state,
  getters: {
    ...module.getters // spreading like this helps IDEA pick up the modules
  },
  mutations: {
    ...module.mutations
  },
  actions: {
    ...module.actions
  },
  modules: {
    ...module.modules
  }
};
