import {byId} from '@/store/firestore-util';
import {editValue, editedValue, resetEditValue} from '@/util/editable-util';
import nested from 'nested-property';

/**
 * @param {import('vuex').ActionContext} context
 * @param {Object} circle
 * @return {EditableCircle}
 */
export function toEditableCircle(context, circle) {
  const editableCircle = /** @type {EditableCircle} */ {};

  Object.defineProperty(editableCircle, 'id', {
    enumerable: true,
    get() {
      return circle.id;
    }
  });

  Object.defineProperty(editableCircle, 'floor', {
    enumerable: true,
    get() {
      return circle.floor;
    }
  });
  Object.defineProperty(editableCircle, 'cx', {
    enumerable: true,
    get() {
      return editedValue(context, circle, 'cx');
    },
    set(v) {
      const original = nested.get(circle, 'cx');
      if (original === v) {
        resetEditValue(context, circle, 'cx');
      } else {
        editValue(context, circle, 'cx', v);
      }
    }
  });
  Object.defineProperty(editableCircle, 'cy', {
    enumerable: true,
    get() {
      return editedValue(context, circle, 'cy');
    },
    set(v) {
      const original = nested.get(circle, 'cy');
      if (original === v) {
        resetEditValue(context, circle, 'cy');
      } else {
        editValue(context, circle, 'cy', v);
      }
    }
  });
  Object.defineProperty(editableCircle, 'r', {
    enumerable: true,
    get() {
      return editedValue(context, circle, 'r');
    },
    set(v) {
      const original = nested.get(circle, 'r');
      if (original === v) {
        resetEditValue(context, circle, 'r');
      } else {
        editValue(context, circle, 'r', v);
      }
    }
  });
  Object.defineProperty(editableCircle, 'fill', {
    enumerable: true,
    get() {
      return editedValue(context, circle, 'fill');
    },
    set(v) {
      const original = nested.get(circle, 'fill');
      if (original === v) {
        resetEditValue(context, circle, 'fill');
      } else {
        editValue(context, circle, 'fill', v);
      }
    }
  });

  editableCircle.reset = () => {
    context.commit('resetEdit', editableCircle);
  };
  editableCircle.delete = () => {
    context.commit('deleteCircle', editableCircle);
  };
  Object.defineProperty(editableCircle, 'added', {
    enumerable: true,
    get() {
      return context.state.added.findIndex(c => c.id === editableCircle.id) !== -1;
    }
  });
  Object.defineProperty(editableCircle, 'edited', {
    enumerable: true,
    get() {
      const edit = context.state.edits[byId(circle)] || {};
      return Object.keys(edit).length > 0;
    }
  });
  Object.defineProperty(editableCircle, 'deleted', {
    enumerable: true,
    get() {
      return context.state.deleted.findIndex(c => c.id === editableCircle.id) !== -1;
    }
  });

  return editableCircle;
}
