import {decorateReference, decorateSnapshot} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';

const log = Logger.get('site-aggregates');

export default {
  namespaced: true,
  state: {
    bySiteRef: {}
  },
  getters: {
    byRef(state) {
      return ref => {
        if (!ref) return null;
        if (ref.path) {
          ref = ref.path;
        }
        // ns/{ns}/sites/{site}/{type}/{id}
        // 0 / 1  / 2   / 3    / 4    / 5
        const parts = ref.split('/');
        if (parts.length !== 6) return null;
        const siteRef = parts.slice(0, 4).join('/');
        const typeId = parts[4];
        const docId = parts[5];

        const siteData = state.bySiteRef[siteRef];
        if (!siteData) return null;
        const typeData = siteData[typeId];
        if (!typeData) return null;
        return typeData[docId];
      };
    },
    bySiteByType(state) {
      return (site, type) => {
        if (state.bySiteRef[site]) {
          return state.bySiteRef[site][type];
        }
      };
    },
    byType(state) {
      return type => {
        let res = [];
        for (const siteData of Object.values(state.bySiteRef)) {
          res = res.concat(Object.values(siteData[type] || {}));
        }
        return res;
      };
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    updateSiteData(state, siteData) {
      const siteKey = siteData.ref.parent.parent.path;
      Vue.set(state.bySiteRef, siteKey, siteData);
    },
    clear(state) {
      state.bySiteRef = {};
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({state, commit, dispatch, rootGetters}, authUser) {
        if (authUser) {
          // we only need to setup the listeners once per app load
          if (DeferUtil.hasDefer(state, 'activeSite')) return;

          const defer = {};
          defer.activeSite = this.watch(
              () => rootGetters['sites/activeSiteDoc'],
              activeSite => dispatch('ensureSiteLoaded', activeSite));
          commit('defer', defer);
        } else {
          commit('reset');
          commit('clear');
        }
      }
    },
    ensureSiteLoaded({state, commit, rootGetters}, site) {
      if (!site) {
        return;
      }
      const deferKey = 'data-' + site.id;

      // are we already watching the data for this site?
      if (DeferUtil.hasDefer(deferKey)) return;

      const defer = {};

      defer[deferKey] = site.ref.collection('aggregates').doc('site')
          .onSnapshot(
              siteData => {
                const data = decorateSnapshot(siteData);
                commit('updateSiteData', decorateAggregates(data));
              },
              err => log.error('watching aggregates', err));

      commit('defer', defer);
    }
  }
};

/**
 * Replaces all the data documents in the aggregate data with decorated versions.
 *
 * @param {Object} data
 * @return {Object}
 */
function decorateAggregates(data) {
  const siteRef = data.ref.parent.parent;
  for (const [collectionId, documentsById] of Object.entries(data)) {
    for (const [docId, doc] of Object.entries(documentsById)) {
      documentsById[docId] = decorateReference(doc, siteRef.collection(collectionId).doc(docId));
    }
  }
  return data;
}
