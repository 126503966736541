import nestedProperty from 'nested-property';
import rules from '@/components/form/validation';
import firebase from 'firebase/app';

export const NOT_SET = '__NOT_SET__';

/**
 * For each key, produce a Vue mixin that adds a computed property to the instance that can be used for safe v-model
 * binding.
 *
 * This will add the following:
 * 1. An internalValue.key data property for each key, edits to the v-model binding will be here. Each value starts with
 *    the value NOT_SET
 * 2. A value props definition that allows incoming v-model bindings
 * 3. A computed property for each key that returns the internalValue.key only if it's set, otherwise the equivalent
 *    property from the input value. The name of the computed property is converted to camelCase: foo.bar -> fooBar
 * 4. Watchers on the internalValue that trigger an 'input' event when changes happen
 * 5. A watcher for value.key for each property that will cause that property to forget any edits made
 * 6. A method publish that can be overridden to customise the data that will be published
 *
 * @param {string[]} keys
 * @return {*}
 */
export default keys => {
  const computed = {};
  const watchers = {};
  const internalValue = {};
  const safeKeys = keys.map(key => key.replace(/\.(.)/g, (match, group1) => group1.toUpperCase()));

  keys.forEach((key, i) => {
    const safeName = safeKeys[i];
    internalValue[key] = NOT_SET;

    computed[safeName] = {
      get() {
        if (this.internalValue[key] === NOT_SET) {
          if (!nestedProperty.has(this.value, key)) {
            return null;
          }
          const v = nestedProperty.get(this.value, key);
          // noinspection JSIncompatibleTypesComparison
          if (v === firebase.firestore.FieldValue.delete()) {
            return null;
          }
          return v;
        }
        return this.internalValue[key];
      },
      set(v) {
        this.internalValue[key] = v;
      }
    };

    watchers['value.' + key] = function() {
      this.internalValue[key] = NOT_SET;
    };
  });

  return {
    props: {
      value: {
        type: Object,
        default: null
      },
      // Vuelidate validator for the properties
      v: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        internalValue
      };
    },
    computed: {
      ...computed,
      publishedData() {
        const data = {};
        keys.forEach((key, i) => {
          nestedProperty.set(data, key, this[safeKeys[i]]);
        });
        return data;
      },

      /**
       * Return a function that can be used to adjust a form label by adding a * if the field is required.
       *
       * @return {function(name,[text]):string}
       */
      star() {
        const v = this.v;
        return (name, text = name) => {
          if (nestedProperty.has(v, name + '.required')) {
            return text + '*';
          } else {
            return text;
          }
        };
      }
    },
    watch: {
      // a change to the internalValue implies a change to the published data. We don't watch published data directly
      // as we don't want to emit events when the input changes
      internalValue: {
        deep: true,
        handler() {
          this.publish(this.publishedData);
        }
      },
      ...watchers
    },
    methods: {
      publish(data) {
        this.$emit('input', data);
      }
    }
  };
};

/**
 * Vue mixin to add a formInputProps computed property that can be used to v-bind to vuetify inputs to make them behave
 * similarly.
 *
 * @type {Object}
 */
export const formInputProps = {
  computed: {
    /**
     * Common properties for form inputs. Specifies error message handling and rule outputs amongst other things.
     *
     * @return {function(string,string?):Object}
     */
    formInputProps() {
      const v = this.v;
      return (value, text = value) => {
        const props = {
          dense: true,
          hideDetails: 'auto',
          singleLine: true
        };
        if (v) {
          if (nestedProperty.has(v, value)) {
            const vContext = nestedProperty.get(v, value);
            // the same logic as the rules property, but for some reason this works with async vuelidate validators
            const results = rules.map(rule => rule(text, vContext))
                .filter(r => r === false || typeof r === 'string');
            props.error = results.length > 0;
            props.messages = results.filter(r => typeof r === 'string');
          }
        }
        return props;
      };
    }
  }
};

/**
 * Returns a Vue mixin, similar to the default function but with only one property.
 *
 * @return {*}
 */
export function formSingle() {
  return {
    props: {
      value: {
        type: null, // any
        default: null
      },
      // Vuelidate validator for the properties
      v: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        internalValue: NOT_SET
      };
    },
    computed: {
      property: {
        get() {
          const v = this.internalValue === NOT_SET ? this.value : this.internalValue;
          // noinspection JSIncompatibleTypesComparison
          if (v === firebase.firestore.FieldValue.delete()) {
            return null;
          }
          return v;
        },
        set(v) {
          this.internalValue = v;
        }
      },

      /**
       * Return a function that can be used to adjust a form label by adding a * if the field is required.
       *
       * @return {function(name,[text]):string}
       */
      star() {
        const v = this.v;
        return (name, text = name) => {
          if (nestedProperty.has(v, name + '.required')) {
            return text + '*';
          } else {
            return text;
          }
        };
      }
    },
    watch: {
      // a change to the internalValue implies a change to the published data. We don't watch published data directly
      // as we don't want to emit events when the input changes
      internalValue: {
        deep: true,
        handler() {
          this.publish(this.internalValue);
        }
      }
    },
    methods: {
      publish(data) {
        this.$emit('input', data);
      }
    }
  };
}
