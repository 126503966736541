<template>
  <v-tooltip bottom>
    <template #activator="{on}">
      <v-chip v-on="on" label outlined :color="color" :class="childClass">
        <slot/>
      </v-chip>
    </template>
    <slot name="tooltip"/>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ChipTooltip',
  props: {
    childClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.v-chip >>> .v-chip__content {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
