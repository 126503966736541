<template>
  <span>{{ value }}</span>
</template>

<script>
export default {
  name: 'PhoneNumber',
  props: {
    value: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>

</style>
