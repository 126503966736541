<template>
  <v-autocomplete
      dense
      :items="sites"
      item-text="title"
      item-value="ref"
      label="Default Site"
      placeholder="(none)"
      :loading="loading"
      @change="savePreferredSite"
      v-model="selected"/>
</template>

<script>
import firebase from 'firebase/app';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'EditPreferredSite',
  data() {
    return {
      internalValue: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters('sites', ['sitesById']),
    ...mapGetters('user/profile', ['preferredSiteRef']),
    sites() {
      return Object.values(this.sitesById);
    },
    selected: {
      get() {
        if (this.internalValue) {
          return this.internalValue.ref;
        }
        return this.preferredSiteRef;
      },
      set(ref) {
        if (ref) {
          const site = this.sitesById[ref.id];
          this.internalValue = {
            ref,
            title: site.title
          };
        } else {
          this.internalValue = firebase.firestore.FieldValue.delete();
        }
      }
    }
  },
  methods: {
    ...mapActions('user/profile', ['setPreferredSite']),
    async savePreferredSite() {
      this.loading = true;
      try {
        const title = this.internalValue.title;
        await this.setPreferredSite(this.internalValue);
        this.internalValue = null;
        this.$notify.showSuccess(`Default site updated to ${title}`);
      } catch (e) {
        this.$logger.error('savePreferredSite', e);
        this.$notify.showError(`Error saving default site: ${e.message}`);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
