import Vue from 'vue';
import nested from 'nested-property';

/**
 * Store util for use with editable config values.
 */
export class EditableConfigUtil {
  /**
   * Mutations for use with editValue
   *
   * @return {Object}
   */
  static mutations() {
    return {
      recordEdit(state, {document, property, value, id}) {
        const edit = state.edits[id] || Vue.set(state.edits, id, {});
        if (property) Vue.set(edit, property, value);
      }
    };
  }
}

/**
 * Reset value and remove from edits
 *
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} document
 * @param {string} property
 * @param {string} id
 */
export function resetEditValue({state}, document, property, id) {
  if (state.edits[id]) {
    Vue.delete(state.edits[id], property);
    // Check if the editable has any other changes
    if (Object.values(state.edits[id]).length === 0) {
      // If it doesn't, delete the editable from the edits object
      Vue.delete(state.edits, id);
    }
  }
}

/**
 * Edit the given property to set it to the given value.
 *
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} document
 * @param {string} property
 * @param {*} value
 * @param {string} id
 */
export function editValue(context, document, property, value, id) {
  context.commit('recordEdit', {document, property, value, id});
}

/**
 * Gets the value for a document, preferring edited values from the store over document values.
 *
 * @param {import('vuex').ActionContext} context
 * @param {DecoratedData} document
 * @param {string} property
 * @param {string} id
 * @return {*}
 */
export function editedValue({state}, document, property, id) {
  if (state.edits[id] && state.edits[id].hasOwnProperty(property)) {
    return state.edits[id][property];
  }

  return nested.get(document[id], property);
}
