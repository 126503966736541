<template>
  <v-row dense>
    <v-col cols="12" :sm="largeLabel ? 5 : 3" :class="classes" class="no-overflow">
      {{ label }}
    </v-col>
    <v-col :cols="slotCols" :sm="slotColsSm">
      <slot/>
    </v-col>
    <v-col v-if="$slots.help" cols="1" align-self="center" class="text-right">
      <slot name="help"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
    combobox: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    largeLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelRef: null
    };
  },
  computed: {
    classes() {
      return this.combobox ? 'pt-4' : 'pt-3';
    },
    slotCols() {
      const largeLabel = this.largeLabel;
      const help = this.$slots.help;
      if (largeLabel && help) {
        return 9;
      } else if (largeLabel) {
        return 10;
      } else if (help) {
        return 11;
      }
      return 12;
    },
    slotColsSm() {
      const largeLabel = this.largeLabel;
      const help = this.$slots.help;
      if (largeLabel && help) {
        return 6;
      } else if (largeLabel) {
        return 7;
      } else if (help) {
        return 8;
      }
      return 9;
    }
  }
};
</script>

<style scoped>
  .no-overflow {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
</style>
