import {decorateSnapshot} from '@/firebase';
import {Logger} from '@vanti/vue-logger';
import {resourceQueryForSite} from '@/views/room-booking/calendar/v2/room-booking';
import {LoadingUtil} from '@/util/loading';
import Vue from 'vue';
import {compareByPath} from '@/util/compare';

const log = Logger.get('resources');

export default {
  namespaced: true,
  state: {
    resourcesBySiteId: {},
    ...LoadingUtil.state()
  },
  getters: {
    ...LoadingUtil.getters(log)
  },
  mutations: {
    setResources(state, {resources, siteId}) {
      Vue.set(state.resourcesBySiteId, siteId, resources);
    },
    clear(state) {
      state.resourcesBySiteId = {};
    },
    ...LoadingUtil.mutations()
  },
  actions: {
    async fetchResourcesForAllSites({commit, dispatch, rootGetters}) {
      commit('loading', 'resources');
      const ns = rootGetters['ns/nsRef'];
      const siteIds = Object.keys(rootGetters['sites/sitesById']);
      for (const siteId of siteIds) {
        dispatch('fetchResourcesForSite', ns.collection('groups').doc(siteId));
      }
      // the resources may not be loaded, but each site has its own loading key now
      commit('loaded', 'resources');
    },
    async fetchResourcesForSite({commit}, siteRef) {
      log.debug('fetchResourcesForSite', siteRef.path);
      const loadingKey = `resources.${siteRef.id}`;
      commit('loading', loadingKey);
      const resourcesSnap = await resourceQueryForSite({ref: siteRef}).get();
      const resources = resourcesSnap.docs
          .map(snap => decorateSnapshot(snap))
          .sort((a, b) => compareByPath(a, b, 'title'));
      commit('setResources', {resources, siteId: siteRef.id});
      commit('loaded', loadingKey);
    }
  }
};
