<template>
  <v-autocomplete
      :search-input.sync="searchText"
      :loading="loading"
      :items="items"
      item-text="title"
      :item-value="byRef"
      :value="selected"
      @input="selectedChanged"
      :readonly="readonly"
      :append-icon="readonly ? '' : '$dropdown'"
      placeholder="(no owner)"
      hide-details
      hide-no-data
      :clearable="!readonly"/>
</template>

<script>
import {byRef} from '@/store/firestore-util';
import {debounce} from 'lodash';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'OwnerField',
  props: {
    value: {
      type: Object,
      default: null
    },
    readonly: Boolean
  },
  data() {
    return {
      queryPending: false,
      searchText: null,
      /** @type {string|null} */
      selected: null
    };
  },
  computed: {
    ...mapGetters('sites', ['activeSiteDoc']),
    ...mapGetters('views/roomBooking/bookingCalendar/owners', ['people', 'loadingInfo']),
    loading() {
      return this.queryPending || this.loadingInfo.loading;
    },
    items() {
      // make sure the value is always in the list of items
      // v-autocomplete will only show the selected value if it's in the list of items
      const result = this.people;
      if (this.value) {
        // noinspection JSCheckFunctionSignatures
        const valueRef = byRef(this.value);
        return [this.value].concat(result.filter(p => byRef(p) !== valueRef));
      }
      return result;
    },
    selectedPerson() {
      if (!this.selected) return this.selected;
      return this.items.find(p => byRef(p) === this.selected) || null;
    },
    queryClauses() {
      const clauses = [];
      // special keywords handling
      if (this.searchText) {
        const parts = this.searchText.toLowerCase().split(/\s/g);
        if (parts.length === 1) {
          clauses.push(['keywords', 'array-contains', parts[0]]);
        } else if (parts.length > 1) {
          clauses.push(['keywords', 'array-contains-any', parts]);
        }
      } else {
        return []; // don't search without a name
      }

      if (this.activeSiteDoc) {
        clauses.push(['site.ref', '==', this.activeSiteDoc.ref]);
      }

      clauses.push(['person.active', '==', true]);

      return clauses;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(n, o) {
        const ref = byRef(n);
        if (ref !== byRef(o)) {
          this.selected = ref;
        }
      }
    },
    queryClauses: {
      deep: true,
      handler() {
        this.queryPending = this.queryClauses.length > 0;
        // noinspection JSValidateTypes
        this.debounceQueryForPeople();
      }
    }
  },
  methods: {
    ...mapActions('views/roomBooking/bookingCalendar/owners', ['bind', 'load', 'stop']),
    byRef,
    debounceQueryForPeople: debounce(function() {
      // eslint-disable-next-line no-invalid-this
      this.queryForPeople();
      // eslint-disable-next-line no-invalid-this
      this.queryPending = false;
    }, 500),
    queryForPeople() {
      const clauses = this.queryClauses;
      this.bind(clauses)
          .then(() => {
            if (clauses.length > 0) {
              // load one page
              this.load();
              this.stop();
            }
          })
          .catch(err => this.$logger.error('during owner query', err));
    },
    selectedChanged(selected) {
      const old = this.selected;
      if (selected !== old) {
        this.selected = selected;
        this.$emit('input', this.selectedPerson);
      }
    }
  }
};
</script>

<style scoped>

</style>
