<template>
  <v-menu v-model="open">
    <template #activator="{on}">
      <v-btn v-on="on" icon>
        <v-icon :color="color">mdi-checkbox-blank-circle</v-icon>
      </v-btn>
    </template>

    <v-color-picker
        v-model="color"
        mode="hexa"
        hide-canvas
        show-swatches/>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorPickerMenu',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  }
};
</script>

<style scoped>

</style>
