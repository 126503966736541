/**
 * Work out the difference between before and after.
 *
 * @param {T[]} before
 * @param {T[]} after
 * @param {function(T):*} [key]
 * @return {{removed: T[], added: T[]}}
 * @template T
 */
export function valueDiff(before, after, key = v => v) {
  // NOTE: also present in the cloud functions
  const added = []; // will contain all refs in after but not in before
  // keyed by the ref path, value is the ref
  const unseen = {}; // will contain all refs that are in before but not in after
  const index = {};
  if (before) {
    before.forEach(r => {
      const k = key(r);
      index[k] = unseen[k] = r;
    });
  }

  if (after) {
    after.forEach(r => {
      const k = key(r);
      if (index.hasOwnProperty(k)) {
        delete unseen[k];
      } else {
        added.push(r);
      }
    });
  }

  return {added, removed: Object.values(unseen)};
}
