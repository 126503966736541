import audit from '@/views/admin/audit/audit';
import config from '@/views/admin/site/config';
import auditSyncReport from '@/views/admin/audit-sync-report/audit-sync-report';
import integrations from '@/views/admin/integrations/integrations';

export default {
  namespaced: true,
  modules: {
    audit,
    config,
    auditSyncReport,
    integrations
  }
};
