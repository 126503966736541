import {storageUtil} from '@/firebase';
import {Bookable} from '@/store/bookable';
import {cleanDeskMarkup, getDeskId, scanDesk} from '@/store/svg-util';
import {Logger} from '@vanti/vue-logger';

/**
 * Helper for interacting with a single floor.
 */
export class Floor {
  #store;
  key;

  planSvgContent;
  bookablesSvgContent;
  svgBounds;
  /** @type {Object.<string, DeskParts>} */
  desksById;
  /**
   * Array of regions in the floor SVGs that should be focused
   *
   * @type {{x:number,y:number,width:number,height:number}[]}
   */
  focusOn;
  /**
   * A space to focus on after the floor has loaded
   *
   * @type {DecoratedData|null}
   */
  focusOnSpaceAfterLoad;

  /** @type {boolean|Promise<void>} */
  bindStarted;
  /** @type {boolean} */
  planSvgContentLoaded;
  /** @type {boolean} */
  bookablesSvgContentLoaded;
  /** @type {boolean} */
  boundsLoaded;
  /** @type {boolean} */
  desksLoaded;

  /**
   * @param {import('vuex').ActionContext} store
   * @param {string} key
   */
  constructor(store, key) {
    this.#store = store;
    this.key = key;
    this.desksById = {};
    this.focusOn = [];
    this.focusOnSpaceAfterLoad = null;

    this.bindStarted = false;
    this.planSvgContentLoaded = false;
    this.bookablesSvgContentLoaded = false;
    this.boundsLoaded = false;
    this.desksLoaded = false;

    this.log = Logger.get(key);
  }

  /**
   * @return {DecoratedData}
   */
  get floor() {
    return this.#store.getters['floors/floorByRef'](this.key);
  }

  /**
   * @return {firebase.firestore.DocumentReference}
   */
  get ref() {
    return this.floor.ref;
  }

  /**
   * @return {string}
   */
  get id() {
    return this.floor.id;
  }

  /**
   * @return {null|firebase.firestore.DocumentReference}
   */
  get siteRef() {
    if (!this.floor) return null;
    if (!this.floor.ref || !this.floor.ref.parent) {
      this.log.debug('how can we have a floor ref but no parent ref?', this.floor.ref);
    }
    return this.floor.ref.parent.parent;
  }

  /**
   * @return {boolean}
   */
  get loaded() {
    return this.planSvgContentLoaded &&
        this.bookablesSvgContentLoaded &&
        this.desksLoaded &&
        this.boundsLoaded;
  }

  /**
   * Return the target size of the svgs taking their native size and the floor scale into account.
   *
   * @return {{width: number, height: number}}
   */
  get svgTargetSize() {
    const res = {width: 0, height: 0};
    if (this.svgBounds) {
      Object.assign(res, this.svgBounds);
    }
    if (this.floor && this.floor.svgScale) {
      res.width *= this.floor.svgScale;
      res.height *= this.floor.svgScale;
    }
    return res;
  }

  /**
   * @return {string}
   */
  get title() {
    if (!this.floor) return '';
    return this.floor.title || this.floor.id;
  }

  /**
   * @return {Object<string, DecoratedData>}
   */
  get bookablesByFloor() {
    if (!this.floor) return {};
    return this.#store.state.bookables.byFloor[this.key] || {};
  }

  /**
   * @return {Object<string, Bookable>}
   */
  get bookablesByRef() {
    const res = {};
    const bookables = this.bookablesByFloor;
    for (const key of Object.keys(bookables)) {
      res[key] = new Bookable(this.#store, key);
    }
    return res;
  }

  /**
   * @return {Bookable[]}
   */
  get bookables() {
    return Object.values(this.bookablesByRef);
  }

  /**
   * @return {{bookableDesks: Array.<{desk:DeskParts,bookable:DecoratedData}>, unknownDesks: DeskParts[]}}
   */
  get allDesks() {
    const res = {
      bookableDesks: [],
      unknownDesks: []
    };

    const foundDeskIds = [];

    /** @type {Bookable[]}*/
    const bookables = this.bookables;
    for (const bookable of bookables) {
      const desk = this.desksById[bookable.space.id];
      if (desk) {
        foundDeskIds.push(desk.id);
        res.bookableDesks.push({desk, bookable});
      }
    }
    res.unknownDesks = Object.keys(this.desksById)
        .filter(id => !foundDeskIds.includes(id))
        .map(id => this.desksById[id]);
    return res;
  }

  /**
   * @return {DeskParts[]}
   */
  get unknownDesks() {
    return this.allDesks.unknownDesks;
  }

  /**
   * @return {Array.<{desk:DeskParts,bookable:DecoratedData}>}
   */
  get bookableDesks() {
    return this.allDesks.bookableDesks;
  }

  /**
   * @return {null|{x: number, width: number, y: number, height: number}}
   */
  get focusBounds() {
    let all = null;
    for (const bounds of this.focusOn) {
      if (!all) {
        all = {
          left: bounds.x,
          top: bounds.y,
          right: bounds.x + bounds.width,
          bottom: bounds.y + bounds.height
        };
      } else {
        if (bounds.x < all.left) all.left = bounds.x;
        if (bounds.y < all.top) all.top = bounds.y;
        if (bounds.x + bounds.width > all.right) all.right = bounds.x + bounds.width;
        if (bounds.y + bounds.height > all.bottom) all.bottom = bounds.y + bounds.height;
      }
    }

    if (!all) {
      // no default zoom
      return null;
    }
    return {
      x: all.left,
      y: all.top,
      width: all.right - all.left,
      height: all.bottom - all.top
    };
  }

  /**
   * @return {Array}
   */
  get neighbourhoodCircles() {
    if (!this.floor) return [];
    return this.floor.neighbourhoodCircles || [];
  }

  /**
   * Focus on the given space.
   *
   * @param {DecoratedData} space
   */
  locate(space) {
    const id = space && space.id;
    if (!id) {
      this.log.debug('locate: no id', id);
      return;
    }

    if (!(id in this.desksById)) {
      if (this.loaded) {
        this.log.debug('locate: unknown desk', id);
      } else {
        // defer locating the desk until the floor has loaded
        this.#store.commit('floors/setFocusOnSpaceAfterLoad', {floor: this, space});
      }
      return;
    }

    const box = this.desksById[id].bounds;
    this.#store.commit('floors/setFocusOn', {
      floor: this,
      focusOn: [{x: box.x, y: box.y, width: box.width, height: box.height}]
    });
  }

  /**
   * Clear any areas that we're focusing on
   */
  clearFocus() {
    if (this.focusOn && this.focusOn.length > 0) {
      this.#store.commit('floors/setFocusOn', {floor: this, focusOn: []});
    }
  }

  /**
   * @param {string} id
   * @return {boolean} was a bookable found
   */
  toggleActiveSpaceById(id) {
    const bookable = Object.values(this.bookablesByRef).find(b => b.id === id);
    if (!bookable) {
      this.log.warn('unknown bookable id', id);
      return false;
    }
    this.#store.commit('selection/toggleSelection', bookable);
    return true;
  }

  /**
   * Inspects and collects information about the svg element.
   *
   * @param {SVGSVGElement} svg
   * @return {boolean} If we were successful
   */
  bindBookablesSvg(svg) {
    if (svg) {
      const svgBounds = svg.getBBox();
      if (svgBounds.width === 0 || svgBounds.height === 0) {
        // there's really no point scanning the desks until the svg has some dimensions
        this.log.debug('abort bindBookablesSvg: svg has no size', svgBounds);
        return false;
      }
      const desksById = {};

      /** @type {NodeListOf<SVGElement>} */
      const desks = svg.querySelectorAll('g[id^=bookable_]');
      for (/** @type {SVGElement} */ const deskEl of desks) {
        desksById[getDeskId(deskEl)] = scanDesk(deskEl, this.floor.svgScale);
      }

      for (const parts of Object.values(desksById)) {
        cleanDeskMarkup(parts);
      }

      this.#store.commit('floors/saveSvgDesks', {floor: this, svgDesks: desksById});

      const focusSpace = this.focusOnSpaceAfterLoad;
      if (focusSpace) {
        this.#store.commit('floors/setFocusOnSpaceAfterLoad', {floor: this, space: null});
        this.locate(focusSpace);
      }
      return true;
    }
    return false;
  }

  /**
   * Inspects and collects information about the svg element.
   *
   * @param {SVGSVGElement} svg
   */
  bindPlanSvg(svg) {
    if (svg) {
      const baseVal = svg.viewBox.baseVal;
      const size = {x: baseVal.x, y: baseVal.y, width: baseVal.width, height: baseVal.height};
      this.#store.commit('floors/setSvgBounds', {floor: this, size});
    }
  }

  /**
   * Fetch SVGs whenever the paths change in the current floor.
   *
   * @return {Promise<Object>}
   */
  async fetchSvgs() {
    const defer = {};

    // watch the svgs that represent the floor so we can load them
    const svgLoader = new SvgLoader(this.#store, this);
    defer.planSvg = svgLoader.watchSvg('planSvg');
    defer.bookablesSvg = svgLoader.watchSvg('bookablesSvg');

    return defer;
  }

  /**
   * Make sure that bookables are loaded for this floor.
   *
   * @return {Promise<Object>}
   */
  async fetchBookables() {
    const defer = {};
    // watch all the bookables on this floor
    defer.bookables = await this.#store.dispatch('bookables/watchFloor', {
      floor: this.floor.ref
    });

    return defer;
  }

  /**
   * Setup any data or watchers for the configured floor.
   *
   * @return {Promise<void>}
   */
  async bind() {
    if (this.bindStarted) return this.bindStarted;
    // notify that we've started to load the floor data
    const done = {
      resolve: () => {
      },
      reject: () => {
      }
    };
    const bindStarted = new Promise((resolve, reject) => {
      done.resolve = resolve;
      done.reject = reject;
    });
    this.#store.commit('floors/setBindStarted', {floor: this, bindTask: bindStarted});


    try {
      const defer = {
        ...await this.fetchSvgs(),
        ...await this.fetchBookables()
      };
      const prefixed = {};
      for (const key of Object.keys(defer)) {
        prefixed[`${this.key}.${key}`] = defer[key];
      }
      this.#store.commit('floors/defer', prefixed);
      done.resolve();
    } catch (e) {
      done.reject(e);
    }

    return bindStarted;
  }

  /**
   * Release all resources, typically watchers.
   */
  unbind() {
    this.#store.commit('reset', [
      `${this.key}.bookables`,
      `${this.key}.planSvg`,
      `${this.key}.bookablesSvg`
    ]);
  }
}

/**
 * Helper for loading svgs based on watchable properties
 */
class SvgLoader {
  /** @type {import('vuex').ActionContext} */
  store;
  /* @type {Floor} */
  state;

  /**
   * @param {import('vuex').ActionContext} store
   * @param {Floor} floor
   */
  constructor(store, floor) {
    this.store = store;
    this.floor = floor;
  }

  /**
   * Watch the given property name on the floor and commit the contents of the reference into the store.
   *
   * @param {string} svgName
   * @return {*}
   */
  watchSvg(svgName) {
    return this.store.watch(() => this.floor.floor && this.floor.floor[svgName], async path => {
      const svgText = path ? await storageUtil.loadFileText(path) : null;
      this.store.commit(`floors/${svgName}ContentUpdate`, {floor: this.floor, content: svgText});
    }, {immediate: true});
  }
}

