export const emptyPerson = Object.freeze({
  title: '',
  displayName: '',
  position: '',
  department: '',
  employer: {
    title: ''
  },
  email: '',
  phones: Object.freeze([]),
  tags: Object.freeze({}),
  costCentre: '',
  active: true
});

export const emptyCard = Object.freeze({
  title: '',
  tags: Object.freeze({})
});
