<template>
  <card-view action-margin :card="newCard ? {} : tempCard" temporary>
    <template #content>
      <v-form v-model="valid">
        <v-container fluid class="pa-0">
          <v-row dense v-if="newCard">
            <v-col cols="5">
              <v-select dense outlined label="Reason" :items="reasons" v-model="form.reason"/>
            </v-col>
            <v-col cols="7">
              <card-scan-field v-model="cardId" scan-on-load :rules="[rules.required]"/>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0 mx-n4" style="max-width: none; flex-grow: 1">
              <card-ownership-check :card-id="form.id" @card-doc="existingCard = $event"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="body-2">
              Valid From:
            </v-col>
            <v-col cols="6">
              <v-datetime-field
                  required
                  dense
                  :rules="[rules.required, rules.isBeforeValidTo]"
                  v-model="form.validFrom"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="body-2">
              Valid To:
            </v-col>
            <v-col cols="6">
              <v-datetime-field
                  required
                  dense
                  :rules="[rules.required, rules.isAfterValidFrom]"
                  v-model="form.validTo"/>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template #actions>
      <v-spacer/>
      <v-btn min-width="112" text @click="$emit('cancel')">Cancel</v-btn>
      <v-btn min-width="112" color="accent" :disabled="!allowSubmit" @click="submit">OK</v-btn>
    </template>
  </card-view>
</template>

<script>
import CardOwnershipCheck from '@/components/cards/CardOwnershipCheck';
import CardScanField from '@/components/cards/CardScanField';
import CardView from '@/components/cards/CardView';
import VDatetimeField from '@/components/VDatetimeField';
import moment from 'moment';
import {mapActions, mapGetters, mapState} from 'vuex';
import {CardIdTypeRef} from '@/util/card-type';

export default {
  name: 'TemporaryCardEditor',
  components: {CardOwnershipCheck, CardScanField, CardView, VDatetimeField},
  props: {
    newCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      existingCard: null,
      form: {
        id: null,
        reason: 'New',
        validFrom: moment().toDate(),
        validTo: moment().endOf('day').toDate()
      },
      reasons: [
        'New',
        'Broken',
        'Lost',
        'Stolen'
      ],
      rules: {
        required: value => !!value || 'Required',
        isAfterValidFrom: value => value > this.form.validFrom || 'Must be after Valid From date',
        isBeforeValidTo: value => value < this.form.validTo || 'Must be before Valid To date'
      },
      valid: true
    };
  },
  computed: {
    ...mapGetters('views/person', [
      'activeMembership',
      'hasPermanentCard',
      'hasTemporaryCard',
      'person'
    ]),
    ...mapState('views/person', [
      'tempCard'
    ]),
    ...mapGetters('sites', [
      'activeSiteId'
    ]),
    ...mapGetters('sites/active', [
      'configCardIdType'
    ]),
    allowSubmit() {
      if (this.newCard) {
        return this.valid;
      } else {
        return this.valid && this.datesChanged;
      }
    },
    cardId: {
      get() {
        if (this.existingCard && this.existingCard.hasOwnProperty('title')) {
          return this.existingCard.title;
        }
        return this.form.id;
      },
      set(v) {
        this.form.id = v;
      }
    },
    cardData() {
      const c = {
        id: this.form.id,
        role: 'temporary',
        sites: {},
        updateReason: this.form.reason,
        owner: {
          ref: this.person.ref,
          title: this.person.title,
          displayName: this.person.displayName
        },
        enabledFrom: this.form.validFrom,
        enabledTo: this.form.validTo
      };
      c.sites[this.activeSiteId] = true;
      if (!this.newCard) {
        if (this.tempCard.cardIdType && this.tempCard.cardIdType !== CardIdTypeRef) {
          // handle card cardIdType override
          c.id = this.tempCard.uids[this.tempCard.cardIdType];
        } else if (this.configCardIdType !== CardIdTypeRef) {
          // use site-level cardIdType
          c.id = this.tempCard.uids[this.configCardIdType];
        } else {
          c.id = this.tempCard.id;
        }
      }
      // temp cards should be transferable by default, but don't change it if the card already has the property
      if (!this.existingCard || !this.existingCard.hasOwnProperty('nonTransferable')) {
        c.nonTransferable = false;
      }
      return c;
    },
    datesChanged() {
      if (this.tempCard.enabledFrom) {
        if (this.form.validFrom.getTime() !== this.tempCard.enabledFrom.toDate().getTime()) {
          return true;
        }
      }
      if (this.tempCard.enabledTo) {
        if (this.form.validTo.getTime() !== this.tempCard.enabledTo.toDate().getTime()) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    tempCard: {
      handler(tempCard) {
        if (!tempCard || this.newCard) {
          return;
        }
        this.existingCard = tempCard; // else the update function thinks this is a new card
        if (tempCard.enabledFrom) {
          this.form.validFrom = tempCard.enabledFrom.toDate();
        }
        if (tempCard.enabledTo) {
          this.form.validTo = tempCard.enabledTo.toDate();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('views/person', [
      'updateTempCard'
    ]),
    submit() {
      const newCard = this.newCard;
      this.updateTempCard({
        card: this.cardData,
        validFrom: this.form.validFrom,
        validTo: this.form.validTo,
        existingDoc: this.existingCard
      })
          .then(() => this.$emit('done'))
          .then(() => this.$notify.showSuccess(newCard ? 'Card added' : 'Card updated'))
          .catch(err => this.$logger.error(`Error ${newCard ? 'adding' : 'updating'} card`, err));
    }
  }
};
</script>

<style scoped>

</style>
