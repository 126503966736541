<template>
  <div class="person-list v-data-table theme--light">
    <table>
      <v-data-table-header :headers="headers" disable-sort/>
    </table>
    <!--
    Note: don't remove page-mode! While the visual functionality is the same, without it a dom node per row is created
    by the recycle-scroller, which quickly adds up.
    -->
    <recycle-scroller
        page-mode
        class="tbody"
        :items="items"
        :item-size="52">
      <template #default="{item}">
        <person-list-row
            :person="item"
            :headers="headers"
            :to="links ? {name: 'person', params: {id: item.id}}: null"
            :exclude-tags="excludeTags">
          <slot :person="item"/>
        </person-list-row>
      </template>
    </recycle-scroller>
    <resize-observer @notify="setWidth"/>
  </div>
</template>

<script>
import PersonListRow from '@/components/people/PersonListRow';
import {ResizeObserver} from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
import {RecycleScroller} from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
// force webpack to include data table styles we need, even though we aren't using it directly
import 'vuetify/src/components/VDataTable/VSimpleTable.sass';

// all widths are in %
const headers = [
  {text: 'Name', value: 'title', width: 25},
  {text: 'Phone', value: 'phones', width: 20},
  {text: 'Email', value: 'email', width: 20},
  {text: 'Job title & Company', value: 'position', width: 20},
  {text: 'Tags', value: 'tags', width: 15},
  {text: 'Edit', value: 'edit', width: 4}
];
const breakpoints = [
  {w: 0, cols: {title: 100}},
  {w: 350, cols: {title: 60, phones: 40}},
  {w: 600, cols: {title: 40, phones: 20, position: 40}},
  {w: 900, cols: {title: 30, phones: 20, email: 25, position: 25}},
  {w: 1200, cols: {title: 25, phones: 20, email: 20, position: 20, tags: 15, edit: 4}}
];

export default {
  name: 'PersonList',
  components: {PersonListRow, RecycleScroller, ResizeObserver},
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    excludeTags: {
      type: [Function, Array],
      default: undefined
    },
    links: {
      type: Boolean,
      default: true
    },
    // slot column width in pixels
    slotWidth: {
      type: Number,
      default: 120
    }
  },
  data() {
    return {
      width: null
    };
  },
  computed: {
    breakpoint() {
      const width = this.width || 0;
      for (let i = breakpoints.length - 1; i >= 0; i--) {
        if (breakpoints[i].w < width) {
          return breakpoints[i];
        }
      }
      return null;
    },
    headers() {
      // clone
      let headers = this.visibleHeaders.map(header => Object.assign({}, header));
      if (this.$scopedSlots.default) {
        // reduce the size of each header by a fraction of the slotWidth
        headers = headers.map(this.scaleWidth);
        headers.push(this.slotHeader);
        return headers;
      }
      // add % to all widths
      return headers.map(header => Object.assign(header, {width: header.width + '%'}));
    },
    slotHeader() {
      return {
        text: '',
        value: '$slot',
        width: this.slotWidth + 'px'
      };
    },
    visibleHeaders() {
      if (!this.breakpoint) {
        return headers;
      }

      return headers.filter(header => this.breakpoint.cols[header.value])
          // create a copy with the width from the breakpoint
          .map(header => Object.assign({}, header, {width: this.breakpoint.cols[header.value]}));
    }
  },
  methods: {
    scaleWidth(header) {
      // originally had this calculation in CSS, but the v-data-table-header needs the width to be a string with a
      // simple unit, calc is not supported.
      const perc = header.width * 0.01;
      const w = this.width * perc - (perc * this.slotWidth);
      header.width = `${w}px`;
      return header;
    },
    setWidth() {
      this.width = this.$el.clientWidth;
    }
  }
};
</script>

<style scoped>
  .tbody {
    width: 100%;
  }

  .person-list {
    /* needed by vue-resize */
    position: relative;
  }

  .person-list table {
    background-color: inherit;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    border-spacing: 0;
  }

  .person-list >>> th {
    height: 56px;
    font-size: 14px;
    font-weight: normal;
    padding: 0 16px;
    color: rgba(0,0,0,0.6);
  }

  .person-list >>> thead tr:last-child th {
    border-bottom: solid var(--v-grey-base) 1px;
  }

  .person-list >>> .hover {
    background: #eee;
  }
</style>
