<template>
  <v-menu offset-y :close-on-content-click="false" v-model="open">
    <template #activator="{ on }">
      <v-tooltip bottom>
        <template #activator="{on: toolTipOn}">
          <v-btn icon v-on="{...on, ...toolTipOn}">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        Show options
      </v-tooltip>
    </template>

    <v-card width="350">
      <v-card-title>Options</v-card-title>
      <v-card-text class="pa-0">
        <v-list dense>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Colors</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="flex-row">
              <color-picker-menu v-model="primaryColor"/>
              <color-picker-menu v-model="secondaryColor"/>
              <color-picker-menu v-model="accentColor"/>
            </v-list-item-action>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Moving Average</v-list-item-title>
              <v-list-item-subtitle>Show a 3-point moving average</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="movingAverage"/>
            </v-list-item-action>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Time Scale</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn-toggle dense mandatory v-model="timeScale" color="primary">
                <v-btn v-for="v in timeScales" :key="v">{{ v }}</v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Exclude Weekends</v-list-item-title>
              <v-list-item-subtitle>{{ weekendText }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="excludeWeekends"/>
            </v-list-item-action>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Only working hours</v-list-item-title>
              <v-list-item-subtitle>{{ calculatedStartTime }} - {{ calculatedEndTime }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="onlyWorkingHours"/>
            </v-list-item-action>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>By Floor</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="byFloor"/>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="close" outlined>Cancel</v-btn>
        <v-btn @click="save" color="accent" depressed :disabled="!hasEdits">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import {DayNames} from '@/util/dates';
import {TimeScales} from '@/views/dashboard/statistics/statistics';
import ColorPickerMenu from '@/components/ColorPickerMenu';

export default {
  name: 'StatsOptions',
  components: {ColorPickerMenu},
  data() {
    return {
      local: {
        colors: {
          primary: null,
          secondary: null,
          accent: null
        },
        byFloor: null,
        excludeWeekends: null,
        movingAverage: null,
        onlyWorkingHours: null,
        timeScale: null
      },
      open: false
    };
  },
  computed: {
    ...mapGetters('config', [
      'themeColors'
    ]),
    ...mapState('views/dashboard/statistics', [
      'options'
    ]),
    ...mapGetters('sites/active', [
      'localeWeekend',
      'workingHoursStartTime',
      'workingHoursEndTime',
      'statsWorkingDayStart',
      'statsWorkingDayEnd'
    ]),
    calculatedStartTime() {
      return this.statsWorkingDayStart !== null ? this.statsWorkingDayStart : this.workingHoursStartTime;
    },
    calculatedEndTime() {
      return this.statsWorkingDayEnd !== null ? this.statsWorkingDayEnd : this.workingHoursEndTime;
    },
    colors() {
      const fallback = this.$vuetify.theme.themes.light;
      const theme = this.themeColors;
      const options = this.options.colors;
      const color = (name) => {
        if (options.hasOwnProperty(name)) {
          return options[name];
        }
        if (theme.hasOwnProperty(name)) {
          return theme[name].base;
        }
        return fallback[name].base;
      };
      return {
        primary: color('primary'),
        secondary: color('secondary'),
        accent: color('accent')
      };
    },
    primaryColor: {
      get() {
        if (this.local.colors.primary !== null) {
          return this.local.colors.primary;
        }
        return this.colors.primary;
      },
      set(v) {
        if (v === this.colors.primary) {
          v = null;
        }
        this.local.colors.primary = v;
      }
    },
    secondaryColor: {
      get() {
        if (this.local.colors.secondary !== null) {
          return this.local.colors.secondary;
        }
        return this.colors.secondary;
      },
      set(v) {
        if (v === this.colors.secondary) {
          v = null;
        }
        this.local.colors.secondary = v;
      }
    },
    accentColor: {
      get() {
        if (this.local.colors.accent !== null) {
          return this.local.colors.accent;
        }
        return this.colors.accent;
      },
      set(v) {
        if (v === this.colors.accent) {
          v = null;
        }
        this.local.colors.accent = v;
      }
    },
    byFloor: {
      get() {
        if (this.local.byFloor !== null) {
          return this.local.byFloor;
        }
        return this.options.byFloor;
      },
      set(v) {
        if (v === this.options.byFloor) {
          v = null;
        }
        this.local.byFloor = v;
      }
    },
    excludeWeekends: {
      get() {
        if (this.local.excludeWeekends !== null) {
          return this.local.excludeWeekends;
        }
        return this.options.excludeWeekends;
      },
      set(v) {
        if (v === this.options.excludeWeekends) {
          v = null;
        }
        this.local.excludeWeekends = v;
      }
    },
    movingAverage: {
      get() {
        if (this.local.movingAverage !== null) {
          return this.local.movingAverage;
        }
        return this.options.movingAverage;
      },
      set(v) {
        if (v === this.options.movingAverage) {
          v = null;
        }
        this.local.movingAverage = v;
      }
    },
    onlyWorkingHours: {
      get() {
        if (this.local.onlyWorkingHours !== null) {
          return this.local.onlyWorkingHours;
        }
        return this.options.onlyWorkingHours;
      },
      set(v) {
        if (v === this.options.onlyWorkingHours) {
          v = null;
        }
        this.local.onlyWorkingHours = v;
      }
    },
    timeScales() {
      return Object.keys(TimeScales);
    },
    timeScale: {
      get() {
        if (this.local.timeScale !== null) {
          return this.local.timeScale;
        }
        return this.options.timeScale;
      },
      set(v) {
        if (v === this.options.timeScale) {
          v = null;
        }
        this.local.timeScale = v;
      }
    },
    weekendText() {
      return this.localeWeekend.map(dow => DayNames[dow]).join(' - ');
    },
    hasEdits() {
      return Object.values(this.local).some(v => v !== null);
    }
  },
  methods: {
    ...mapMutations('views/dashboard/statistics', [
      'setColor',
      'setOption'
    ]),
    close() {
      this.open = false;
      const options = Object.keys(this.local);
      for (const option of options) {
        if (option === 'colors') {
          this.local[option] = {
            primary: null,
            secondary: null,
            accent: null
          };
        } else {
          this.local[option] = null;
        }
      }
    },
    save() {
      const options = Object.keys(this.local);
      for (const option of options) {
        if (option === 'colors') {
          continue;
        }
        if (this.local[option] !== null) {
          this.setOption({name: option, value: this.local[option]});
        }
      }

      for (const [name, color] of Object.entries(this.local.colors)) {
        if (color !== null) {
          this.setColor({name, color});
        }
      }
      this.close();
    }
  }
};
</script>

<style scoped>
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-list {
  --list-item-height: 30px;
  max-height: calc(10 * var(--list-item-height));
  min-height: calc(5 * var(--list-item-height));
  padding-bottom: 4px;
  overflow: auto;
}

.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: var(--list-item-height);
}
</style>
