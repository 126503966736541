import {Logger} from '@vanti/vue-logger';
import {EmptyCollection} from '@/store/collection/empty-collection';
import {CardHistoryCollection} from '@/views/people/card/card-history-collection';
import {db, decorateSnapshot} from '@/firebase';
import {compareByPath} from '@/util/compare';
import Vue from 'vue';

const log = Logger.get('card-history');

/**
 * @typedef {Object} owner
 * @property {DocumentReference} [ref]
 * @property {string} [title]
 * @property {string} [displayName]
 */

/**
 * @typedef {Object} changeField
 * @property {owner} owner
 */

/**
 * @typedef {Object} change
 * @property {firebase.firestore.Timestamp} changeTime
 * @property {changeField} [after]
 * @property {changeField} [before]
 */

/**
 * @typedef {Object} assignment
 * @property {owner} person
 * @property {Date} assigned
 * @property {Date} [unassigned]
 */

/**
 * @param {change} change
 * @return {boolean}
 */
export function hasPreviousOwner(change) {
  return change.hasOwnProperty('before') &&
      change.before.hasOwnProperty('owner') &&
      change.before.owner.hasOwnProperty('ref');
}

/**
 * @param {change} change
 * @return {boolean}
 */
export function hasNewOwner(change) {
  return change.hasOwnProperty('after') &&
      change.after.hasOwnProperty('owner') &&
      change.after.owner.hasOwnProperty('ref');
}

export default {
  namespaced: true,
  state: {
    /** @type {?DocumentCollection} */
    currentCollection: new EmptyCollection(),
    latestChanges: {},
    watchers: {}
  },
  getters: {
    /**
     * Combines records and latestChanges, removing duplicates
     *
     * @param {Object} state
     * @return {{}}
     */
    allRecords(state) {
      // use ID as the key to ensure there are no duplicates between the currentCollection and latestChanges snapshot
      const changes = {};
      // note id of the latestChanges includes the full document path
      // id in the object is just the document id
      for (const id of Object.keys(state.latestChanges)) {
        const change = state.latestChanges[id];
        changes[change.id] = change;
      }
      for (const r of state.currentCollection.records) {
        changes[r.id] = r;
      }
      return changes;
    },
    /**
     * The latest record; PersonAccessCardHistory component watches this and calls bindLatest when it changes
     *
     * @param {Object} state
     * @return {DecoratedData|undefined}
     */
    latestRecord(state) {
      return state.currentCollection.records[0];
    },
    all(state, getters) {
      const assignments = [];
      for (const id of Object.keys(getters.allRecords)) {
        assignments.push(getters.allRecords[id]);
      }
      return assignments.sort((a, b) => -compareByPath(a, b, 'change.changeTime'));
    },
    expectsMore(state) {
      return state.currentCollection.expectsMoreRecords;
    }
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {PeopleCollection} collection
     */
    setCurrentCollection(state, collection) {
      state.currentCollection = collection;
    },
    updateLatest(state, updates) {
      log.debug('updateLatest', updates);
      updates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            Vue.set(state.latestChanges, update.doc.ref.path, decorateSnapshot(update.doc));
            break;
          case 'removed':
            Vue.delete(state.latestChanges, update.doc.ref.path);
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {Object.<string,function>} watchers
     */
    registerWatchers(state, watchers) {
      state.watchers = {...state.watchers, ...watchers};
    },
    reset(state) {
      state.watchers = {};
      state.latestChanges = {};
      state.currentCollection = new EmptyCollection();
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (!authUser) {
          dispatch('unbind');
        }
      }
    },
    async bind({commit, rootGetters}) {
      log.debug('bind');

      if (!rootGetters['views/card/cardExists']) {
        commit('setCurrentCollection', new EmptyCollection());
        throw new Error('no card to bind history');
      }
      const ns = rootGetters.ns;
      const card = rootGetters['views/card/card'];
      commit('setCurrentCollection', new CardHistoryCollection(ns, card.ref));
    },
    /**
     * Bind a snapshot of changes starting from the latest record in state.currentCollection.records
     *
     * @param {import('vuex').ActionContext} context
     * @return {Promise<void>}
     */
    async bindLatest({commit, state, rootGetters}) {
      if (!rootGetters['views/card/cardExists']) {
        throw new Error('no card to bind history');
      }
      const latest = state.currentCollection.records[0];
      if (!latest) {
        log.warn('bindLatest nothing to bind');
        return;
      }
      if (state.watchers && state.watchers.latest) {
        state.watchers.latest(); // cancel previous watcher
      }

      const ns = rootGetters.ns;
      const card = rootGetters['views/card/card'];
      const watchers = {};
      const firestore = await db;
      // watch for changes after the latest change we have
      watchers.latest = firestore.collection(`ns/${ns}/search/changes/records`)
          .where('change.document.ref', '==', card.ref)
          .orderBy('change.changeTime', 'desc')
          // endAt the DocumentSnapshot didn't appear to work, but the timestamp does.
          // Note this means this snapshot includes the latest document as a duplicate
          // this is handled in the getters. If we used endBefore we may miss a change.
          .endAt(latest.change.changeTime)
          .onSnapshot(
              querySnap => commit('updateLatest', querySnap.docChanges()),
              err => log.error('latest.onSnapshot', err));

      commit('registerWatchers', watchers);
    },
    unbind({state, commit}) {
      Object.values(state.watchers).forEach(watcher => watcher());
      commit('reset');
    },

    async loadNextPage({state}) {
      return state.currentCollection.loadNextPage();
    },

    async stop({state}) {
      return state.currentCollection.stop();
    }
  }
};
