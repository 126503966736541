import {utcMidnightMs} from '@/util/dates';
import DeferUtil from '@/store/defer-util';
import {scheduleTicks} from '@/util/schedule';
import {addDays} from 'date-fns';
import {LoadingUtil} from '@/util/loading';
import firebase from 'firebase/app';
import {countsToTotals, countTotalAtTime} from '@/views/dashboard/counts/counts-util';
import {decorateSnapshot} from '@/firebase';

/**
 * @typedef {function(DecoratedData, firebase.firestore.Timestamp): firebase.firestore.Query} GetCountsQuery
 */

/**
 * Vuex store which deals with live counts
 *
 * @param {Logger} log
 * @param {GetCountsQuery} getCountsQuery
 * @return {Object}
 */
export function createLiveCountsStore(log, getCountsQuery) {
  return {
    namespaced: true,
    state: {
      /**
       * The count document for today/now
       *
       * @type {kahu.firestore.stats.ByDayBookingCounts|null}
       */
      todaysCounts: null,
      /**
       * The current time at a resolution that matters for calculating active bookings.
       *
       * @type {Date}
       */
      time: new Date(),
      ...LoadingUtil.state()
    },
    getters: {
      ...LoadingUtil.getters(log),
      countTotals(state) {
        return countsToTotals(state.todaysCounts);
      },
      countsNow(state, getters) {
        if (!getters.countTotals) return null;
        return countTotalAtTime(getters.countTotals, state.time);
      },
      /**
       * Returns an array of all the date values of the count totals. This is used to calculate when we need to 'wake
       * up' to change our state to correctly represent the current counts.
       *
       * @param {Object} state
       * @param {Object} getters
       * @return {Date[]}
       */
      changeSchedule(state, getters) {
        if (!getters.countTotals) return [];
        return getters.countTotals.map(c => c.date);
      }
    },
    mutations: {
      ...LoadingUtil.mutations(),
      ...DeferUtil.mutations(log),
      setTodaysCounts(state, snap) {
        state.todaysCounts = snap;
      },
      clear(state) {
        state.todaysCounts = null;
      },
      setTime(state, time) {
        state.time = time;
      }
    },
    actions: {
      async bind({commit, state, dispatch, getters, rootGetters}) {
        if (DeferUtil.hasDefer(state)) {
          // already bound
          return;
        }
        log.debug('bind');

        // set up daily query refresh
        dispatch('scheduleCountsRefresh')
            .catch(err => log.error('During scheduleCountsRefresh', err));

        const defer = {};

        defer.siteChanged = this.watch(
            () => rootGetters['sites/activeSiteDoc'],
            activeSite => {
              commit('clear');
              dispatch('bindCounts', {activeSite})
                  .catch(err => log.error('During bindCounts refresh', err));
            },
            {immediate: true}
        );

        defer.changeSchedule = this.watch(
            () => getters.changeSchedule,
            () => dispatch('scheduleChanges'),
            {immediate: true});

        commit('defer', defer);
      },

      async bindCounts({state, commit, dispatch, rootGetters}, {activeSite}) {
        if (!activeSite) {
          return;
        }
        log.debug('bindCounts for site', activeSite && activeSite.ref && activeSite.ref.path);
        commit('loading', 'counts');

        // Gets timestamp for current UTC day midnight for use in query
        const timestamp = firebase.firestore.Timestamp.fromMillis(utcMidnightMs());

        const currentQuery = getCountsQuery(activeSite, timestamp);
        currentQuery.limit(1);

        const defer = {};

        defer.currentCounts = currentQuery.onSnapshot(
            snap => {
              if (snap.empty) {
                commit('setTodaysCounts', null);
              } else {
                commit('setTodaysCounts', decorateSnapshot(snap.docs[0]));
              }
              commit('loaded', 'counts');
            },
            err => {
              commit('loaded', 'counts');
              log.error('currentCounts.onSnapshot', err);
            }
        );

        commit('defer', defer);
      },

      async unbind({commit}) {
        commit('reset');
        commit('clear');
      },

      /**
       * Sets up a chain of setTimeouts that update the state.time property based on the changeSchedule. This has the
       * effect of allowing getters and computed properties to rely on state.time to calculate up-to-date counts without
       * needing to manage state separately.
       *
       * @param {import('vuex').ActionContext} context
       */
      scheduleChanges({state, getters, commit}) {
        commit('defer', {
          scheduleChanges: scheduleTicks(getters.changeSchedule, d => {
            if (state.time < d) {
              commit('setTime', d);
            }
          })
        });
      },

      /**
       * Call bindCounts each day at UTC midnight to update daily counts doc
       *
       * @param {*} context
       * @return {Promise<void>}
       */
      async scheduleCountsRefresh({rootGetters, commit, dispatch}) {
        const defer = {};
        // utcMidnight is required for sites in different time zones as all counts start from UTC midnight
        const utcMidnight = new Date(utcMidnightMs());
        const nextDay = addDays(utcMidnight, 1);
        const msTilStartOfTomorrow = nextDay.getTime() - Date.now();
        log.debug(`scheduleBookingRefresh in ${msTilStartOfTomorrow}ms`);

        // call bindBookings at midnight
        const nextRefreshHandle = setTimeout(() => {
          dispatch('bindCounts', {activeSite: rootGetters['sites/activeSiteDoc']})
              .catch(err => log.error('During bindCounts refresh', err));
          // set up the next refresh
          dispatch('scheduleCountsRefresh')
              .catch(err => log.error('During scheduleCountsRefresh setup', err));
        }, msTilStartOfTomorrow);
        defer.nextRefreshHandle = () => clearTimeout(nextRefreshHandle);

        commit('defer', defer);
      }
    }
  };
}
