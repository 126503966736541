<template>
  <v-toolbar color="white" height="56px" class="toolbar--grid flex-grow-0" style="z-index: 6" elevation="1">
    <v-toolbar-title class="subtitle-1">
      <span v-if="editMode">
        Editing this floors circles, drag to move/resize the circles. The map cannot be moved while editing.
      </span>
      <span v-else-if="selected.length === 0 && !selectedNeighbourhood">
        Please select one or more desks, or a neighbourhood to begin
      </span>
      <span v-else-if="selectedNeighbourhood">
        Editing <b>{{ selectedNeighbourhood.title }}</b>, click desks to add or remove them
      </span>
      <span v-else>
        Selected <b>{{ selected.length }}</b> desk{{ selected.length === 1 ? '' : 's' }}
        to edit, use the sidebar to make changes
      </span>
    </v-toolbar-title>
    <v-spacer/>
    <v-toolbar-title>
      <span v-if="isAdmin && desksCountOnFloor" class="subtitle-1">
        Desk count on this floor: {{ desksCountOnFloor }} (site total: {{ totalDesksCount }})
      </span>
    </v-toolbar-title>
    <v-menu v-if="editMode" offset-y left>
      <template #activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mr-2"
            icon
            small
            v-bind="attrs"
            v-on="on">
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Editing Circles</v-card-title>
        <v-card-text>
          <ul>
            <li>Click & drag in empty space to make a new circle</li>
            <li>Click a circle to select and edit</li>
            <li>Click & drag a circle to move</li>
            <li>Click & drag the edge of a circle to resize</li>
            <li>Change circle colour in the popup</li>
            <li>Select a neighbourhood while editing a circle to copy the colour</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-toolbar>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: 'SelectionToolbar',
  computed: {
    ...mapState('selection', ['selected']),
    ...mapGetters('selection', ['maxSelected']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['selectedNeighbourhood']),
    ...mapState('views/deskBooking/settings/circles', ['editMode']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('views/deskBooking/settings/map', ['desksCountOnFloor']),
    ...mapGetters('views/deskBooking/settings/desks', [
      'totalDesksCount',
      'desksCountOnEachFloor',
      'totalDesksCount',
      'totalDesksLoaded'
    ]),

    floorsLoaded() {
      return this.desksCountOnEachFloor ?
        Object.values(this.desksCountOnEachFloor).every(floor => floor !== 0) :
        null;
    }
  },
  watch: {
    desksCountOnEachFloor() {
      if (!this.floorsLoaded) return;
      const isTotalDesksCountEqual = this.totalDesksLoaded === this.totalDesksCount;

      if (!isTotalDesksCountEqual) {
        this.$logger
        // eslint-disable-next-line max-len
            .debug(`Total desks count(${this.totalDesksLoaded}) is not equal with sum of desks count on each floor(${this.totalDesksCount})`);
      }
    }
  },
  methods: {
    ...mapMutations('selection', ['toggleMaxSelected'])
  }
};
</script>

<style scoped>

</style>
