import liveStatus from '@/views/dashboard/live-status/live-status';
import statistics from '@/views/dashboard/statistics/statistics';
import counts from '@/views/dashboard/counts/counts';

export default {
  namespaced: true,
  modules: {
    counts,
    liveStatus,
    statistics
  }
};
