<template>
  <v-container fluid :fill-height="loading || (!newCard && !cardExists)" class="pt-5 px-4">
    <template v-if="loading">
      <v-row>
        <v-col cols="12">
          <v-row justify="center" align="center">
            <v-progress-circular indeterminate size="48" color="accent"/>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="loaded && !newCard && !cardExists">
      <page-not-found route-name="cards" route-label="all cards" text="card"/>
    </template>
    <template v-else>
      <span class="d-none">ID {{ id }}</span>
      <router-view/>
    </template>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import PageNotFound from '@/components/PageNotFound';

export default {
  name: 'Card',
  components: {PageNotFound},
  props: {
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('views/card', ['card', 'loaded', 'cardExists', 'hasOwner']),
    ...mapGetters('sites', ['activeSiteId']),
    loading() {
      return !this.loaded;
    },
    newCard() {
      return !this.id;
    }
  },
  watch: {
    activeSiteId: {
      handler: 'bindMembership'
    },
    hasOwner: {
      handler: 'bindMembership'
    },
    id: {
      immediate: true,
      handler: 'loadCard'
    }
  },
  methods: {
    loadCard() {
      const id = this.id;
      this.$store.dispatch('views/card/bind', {id})
          .catch(err => {
            this.$logger.error('Failed to fetch card by id ' + id, err);
          });
    },
    bindMembership() {
      if (!this.hasOwner) {
        this.$logger.warn('bindMembership, no card owner');
        return;
      }
      this.$store.dispatch('views/card/bindMembership', this.card.owner)
          .catch(err => {
            this.$logger.error('Failed to bind card owners site details', err);
          });
    }
  }
};
</script>

<style scoped>

</style>
