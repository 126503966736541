import Vue from 'vue';
import {Logger} from '@vanti/vue-logger';
import {valueDiff} from '@/util/diff';
import DeferUtil from '@/store/defer-util';
import {db, decorateSnapshot} from '@/firebase';
import {LoadingUtil} from '@/util/loading';

const log = Logger.get('work-orders');
/**
 * Creates a store for watching, manipulating and deleting work orders.
 *
 * @return  {Object}
 */
export default function() {
  return {
    namespaced: true,
    state: {
      /**
       * Contains all edits to a work order keyed by the work orders id.
       *
       * @type {Object<string, Object<string, *>>}
       */
      edits: {},
      /**
       * New work orders create for a booking keyed by their id.
       *
       * @type {Object<string, kahu.firestore.WorkOrder>}
       */
      added: {},
      /**
       * List of all reference objects that have been queued for deletion.
       *
       * @type {firebase.firestore.DocumentReference[]}
       */
      deleted: [],
      /**
       * Stores each known work order reference keyed by the document path. The work orders
       * are loaded from the currently active reservation in the calendar view. e.g. If currently displaying
       * the booking popout or side bar the work orders for that reservation will be present in this store.
       * Currently work orders are cleared and fetched at loading/unloading of either the booing popout or sidebar
       * being shown.
       *
       * @type {Object<string, firebase.firestore.DocumentReference>}
       */
      allRefs: {},
      /**
       * Stores each work order keyed by its document reference path
       *
       * @type {Object<string, kahu.firestore.WorkOrder & DecoratedData>}
       */
      byRef: {},

      /**
       * Stores all edits associated with work orders, this is so they are able to get
       * replayed when switching between popout and sidebar view.
       *
       * @type {null|Object}
       */
      toReplay: null,
      ...LoadingUtil.state()
    },
    getters: {
      ...LoadingUtil.getters(log),
      workOrderByRef(state) {
        return ref => state.byRef[ref];
      },
      allWorkOrderRefs(state) {
        const addedIds = Object.keys(state.added);
        return Object.values(state.allRefs)
            .filter(w => !addedIds.includes(w.id));
      },
      workOrdersByCategory(state) {
        return category => Object.values(state.byRef)
            .filter(w => w.category && w.category === category);
      },
      // Used for copying across the edited values when moving between popout and sidebar views
      getEdits(state) {
        return {
          edits: state.edits,
          added: state.added,
          deleted: state.deleted
        };
      },
      hasEdits(state) {
        return Object.keys(state.edits).length > 0 || Object.keys(state.added).length > 0 || state.deleted.length > 0;
      }
    },
    mutations: {
      ...LoadingUtil.mutations(),
      ...DeferUtil.mutations(log),
      addWorkOrder(state, {ref, value}) {
        Vue.set(state.added, ref.id, value);
      },
      setWorkOrder(state, workOrder) {
        Vue.set(state.byRef, workOrder.ref.path, workOrder);
      },
      /**
       * Adds the list of firestore references to the state keyed by document path
       *
       * @param {Object} state
       * @param {firebase.firestore.DocumentReference[]} refs
       */
      setWorkOrderRefs(state, refs) {
        const allRefs = {};
        for (const ref of refs) {
          allRefs[ref.path] = ref;
        }
        state.allRefs = allRefs;
      },
      // Adds the selected work orders ref to the deleted array ready to be committed. If deleting a newly added
      // work order that is yet to be pushed to firestore just remove it from the added state object.
      setDeleteWorkOrder(state, workOrder) {
        if (state.added.hasOwnProperty(workOrder.id)) {
          Vue.delete(state.added, workOrder.id);
        } else {
          state.deleted.push(workOrder);
        }
      },
      removeWorkOrder(state, ref) {
        Vue.delete(state.byRef, ref.path);
        Vue.delete(state.allRefs, ref.path);
        Vue.delete(state.loaded, ref.path);
      },
      clearStore(state) {
        state.allRefs = {};
        state.byRef = {};
        state.edits = {};
        state.added = {};
        state.deleted = [];
      },
      recordEdit(state, {workOrder, property, value}) {
        // if we're recording an edit for a new workOrder, just edit the added object
        if (state.added.hasOwnProperty(workOrder.id)) {
          Vue.set(state.added[workOrder.id], property, value);
        } else {
          // otherwise add to edits
          const edit = state.edits[workOrder.id] || Vue.set(state.edits, workOrder.id, {});
          if (property) Vue.set(edit, property, value);
        }
      },
      setToReplay(state, toReplay) {
        state.toReplay = toReplay;
      }
    },
    actions: {
      init: {
        root: true,
        handler({commit, getters, dispatch}) {
          this.watch(
              () => getters['allWorkOrderRefs'],
              (newRefs, oldRefs) => {
                const {added, removed} = valueDiff(oldRefs, newRefs, p => p.path);
                added.forEach(ref => dispatch('watchWorkOrder', ref));
                removed.forEach(ref => dispatch('forgetWorkOrder', ref));
              }, {immediate: true}
          );
        }
      },
      watchWorkOrder({state, commit}, ref) {
        commit('loading', ref.id);
        commit('defer', {
          [ref.path]: ref.onSnapshot(
              snap => {
                commit('setWorkOrder', decorateSnapshot(snap));
                commit('loaded', ref.id);
              }, err => {
                commit('loaded', ref.id);
                log.error(`watchWorkOrder ${ref.path}.onSnapshot`, err);
              })
        });
      },
      forgetWorkOrder({commit}, ref) {
        commit('reset', [ref.path]);
        commit('deleteLoaded', ref.id);
        commit('removeWorkOrder', ref);
      },
      deleteWorkOrder({commit, dispatch}, ref) {
        dispatch('forgetWorkOrder', ref);
        commit('setDeleteWorkOrder', ref);
      },
      clear({commit}) {
        commit('reset');
        commit('clearStore');
      },
      async commit({state, rootGetters}) {
        const firestore = await db;
        const batch = await firestore.batch();

        const workOrderCol = rootGetters['ns/nsRef'].collection('workOrders');

        // add all updates
        for (const [key, value] of Object.entries(state.edits)) {
          const doc = await workOrderCol.doc(key);
          batch.update(doc, value);
        }

        // add all new work orders
        for (const [key, value] of Object.entries(state.added)) {
          const doc = await workOrderCol.doc(key);
          batch.set(doc, value);
        }

        // add all deleted work orders
        for (const ref of state.deleted) {
          const doc = await workOrderCol.doc(ref.id);
          batch.delete(doc);
        }
        return batch.commit();
      },
      relayEdits(context) {
        const {state, commit} = context;
        if (state.toReplay) {
          log.debug(`re playing work order edits`);
          const {edits, added, deleted} = state.toReplay;
          log.debug(`relayEdits`, {edits, added});
          // once loaded, replay any edits toReplay
          for (const [k, v] of Object.entries(edits || {})) {
            for (const [property, value] of Object.entries(v)) {
              commit('recordEdit', {workOrder: {id: k}, property, value});
            }
          }
          for (const [k, v] of Object.entries(added || {})) {
            commit('addWorkOrder', {ref: {id: k}, value: v});
          }

          for (const toDelete of deleted || []) {
            commit('setDeleteWorkOrder', toDelete);
          }
          commit('setToReplay', null);
        }
      }
    }
  };
};
