<template>
  <v-row dense>
    <v-col cols="12" lg="6" class="pr-lg-6">
      <v-row dense v-if="newCard">
        <v-col cols="12">
          <form-row :label="star('id', 'Card ID')">
            <card-scan-field
                v-bind="formInputProps('id', 'Card ID')"
                v-model="id"/>
          </form-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <form-row :label="star('nonTransferable', 'Non-transferable')">
            <v-switch
                class="mt-1"
                :messages="nonTransferableMessages"
                v-bind="formInputProps('nonTransferable', 'Non-transferable')"
                v-model="nonTransferable"
                :hide-details="false"/>

            <template #help>
              <v-menu offset-y>
                <template #activator="{ on }">
                  <v-btn icon v-on="on" small>
                    <v-icon class="grey--text text--lighten-3">mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    Non-transferable cards are intended to be used by a single person,<br>
                    they typically have some identifying features printed or written <br>
                    on the card. For example a photo or name.
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </form-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <form-row :label="star('title', 'Card Name')">
            <v-text-field
                v-bind="formInputProps('title', 'Card Name')"
                v-model="title"/>
          </form-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <form-row label="Custom Tags" combobox>
            <v-combobox
                v-bind="formInputProps('tags')"
                chips
                multiple
                v-model="tagsList"
                :items="knownTags"/>
          </form-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CardScanField from '@/components/cards/CardScanField';
import form, {formInputProps} from '@/components/form/form';
import FormRow from '@/components/form/FormRow';
import Vue from 'vue';

export default {
  name: 'CardEditAbout',
  components: {CardScanField, FormRow},
  mixins: [
    formInputProps,
    form(['id', 'nonTransferable', 'title', 'tags'])
  ],
  props: {
    newCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Capture all the tags we've seen from both the original object and that have been added via an edit.
     *
     * @return {string[]}
     */
    knownTags() {
      const tags = new Set();
      Object.keys(this.tags || {}).forEach(k => tags.add(k));
      Object.keys(this.value.tags || {}).forEach(k => tags.add(k));
      const sorted = Array.from(tags);
      sorted.sort();
      return sorted;
    },

    /**
     * Converts between the string[] model needed by v-combobox and the {string:bool} model used by the person object.
     */
    tagsList: {
      get() {
        return Object.entries(this.tags || {})
            .filter(([, enabled]) => enabled)
            .map(([tag]) => tag);
      },

      set(v) {
        if (!this.tags) {
          this.tags = {};
        }
        const newTags = {};
        v.forEach(tag => newTags[tag] = true);
        Object.keys(this.tags).forEach(tag => {
          if (!newTags[tag]) {
            this.tags[tag] = false;
          }
        });
        Object.keys(newTags).forEach(tag => Vue.set(this.tags, tag, true));
      }
    },

    nonTransferableMessages() {
      if (!this.nonTransferable) {
        return 'Please check the card has no personally identifying markings';
      } else {
        return undefined;
      }
    }
  }
};
</script>

<style scoped>

</style>
