import {setHoursMinutesSeconds} from '@/util/times';
import {toDate} from '@/util/dates';

/**
 * Takes a ByDayBookingCounts document and returns an array of total values for each change time.
 *
 * @param {kahu.firestore.stats.ByDayBookingCounts} count
 * @return {CountTotals}
 */
export function countsToTotals(count) {
  // check if this is a valid count document
  if (!count || Object.keys(count).length === 0 || !count.hasOwnProperty('onDate')) return [];
  const changes = [];
  let runningTotals = {};

  const date = toDate(count.onDate);
  const countChanges = count.changes || {};
  if (countChanges.hasOwnProperty('initial')) {
    runningTotals = countChanges.initial;
  }
  changes.push({date, totals: runningTotals});

  for (const key of Object.keys(countChanges).sort()) {
    if (!key.startsWith('at')) continue;

    const timeStr = key.replace('at', '');
    const currDate = setHoursMinutesSeconds(new Date(date.getTime()), timeStr);
    const change = countChanges[key];

    runningTotals = mergeChanges(runningTotals, change);
    changes.push({date: currDate, totals: runningTotals});
  }

  return changes;
}

/**
 * @param {CountTotals} counts
 * @param {Date} start
 * @param {Date} end
 * @return {CountTotals}
 */
export function countsInRange(counts, start, end) {
  return counts.filter(count => {
    const on = count.date;
    return on >= start && on <= end;
  });
}

/**
 * Finds the count total for the given time. If there is no entry at the given time, the first entry
 * before the given time is selected.
 *
 * @param {CountTotals} countTotals
 * @param {Date} time
 * @return {CountTotal}
 */
export function countTotalAtTime(countTotals, time) {
  for (const stat of countTotals) {
    if (stat.date > time) {
      return countTotals[countTotals.indexOf(stat)-1];
    }
  }
  return countTotals[countTotals.length-1];
}

/**
 * Merges two values with the following rules:
 * 1. If the values are both numbers, add them together
 * 2. If the values are both objects, union the object properties with any shared keys merging
 * 3. Else value the second value overwrites the first
 *
 * @param {*} c1
 * @param {*} c2
 * @return {*}
 */
function mergeChanges(c1, c2) {
  if (typeof c1 === 'number' && typeof c2 === 'number') {
    return c1 + c2;
  }
  if (typeof c1 === 'object' && typeof c2 === 'object') {
    const res = Object.assign({}, c1, c2);
    Object.keys(c1).forEach(key => {
      if (c2.hasOwnProperty(key)) {
        res[key] = mergeChanges(c1[key], c2[key]);
      }
    });
    return res;
  }
  return c2;
}
