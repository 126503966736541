<template>
  <span>
    <v-chip v-for="(tag, i) in tags" :key="i" :color="tag.c" class="mx-1 text-capitalize">{{ tag.t }}</v-chip>
  </span>
</template>

<script>
import tagMixin from '@/components/tag-mixin';

export default {
  name: 'CardInfoTags',
  mixins: [tagMixin],
  props: {
    card: {
      type: Object,
      default: null
    },
    hasExpired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tags() {
      if (!this.card) {
        return [];
      }

      const tags = Object.entries(this.card.tags || {})
          .filter(([, enabled]) => enabled)
          .map(([tag]) => {
            return {
              c: this.tagColor(tag),
              t: tag
            };
          });

      if (this.card.disabled) {
        tags.push({c: this.tagColor('inactive'), t: 'inactive'});
      }

      if (this.card.nonTransferable) {
        tags.push({c: this.tagColor('non-transferable'), t: 'non-transferable'});
      } else {
        tags.push({c: this.tagColor('transferable'), t: 'transferable'});
      }

      if (this.hasExpired) {
        tags.push({c: this.tagColor('expired'), t: 'expired'});
      }

      return tags;
    }
  }
};
</script>

<style scoped>

</style>
