<template>
  <card-view action-margin :card="permCard">
    <template #content>
      <v-form v-model="valid">
        <v-container fluid class="pa-0">
          <v-row dense v-if="newCard">
            <v-col cols="5">
              <v-select dense outlined label="Reason" :items="reasons" v-model="form.reason"/>
            </v-col>
            <v-col cols="7">
              <card-scan-field v-model="cardId" scan-on-load :rules="[rules.required]"/>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0 mx-n4" style="max-width: none; flex-grow: 1">
              <card-ownership-check :card-id="form.id" @card-doc="existingCard = $event"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="body-2">
              Valid From:
            </v-col>
            <v-col cols="6">
              <v-datetime-field dense v-model="form.validFrom" class="mt-0"/>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="body-2">
              Valid To:
            </v-col>
            <template v-if="form.validTo">
              <v-col cols="6">
                <v-datetime-field dense v-model="form.validTo" class="mt-0"/>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template #activator="{on}">
                    <v-btn v-on="on" icon @click="form.validTo = null">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  Remove end date
                </v-tooltip>
              </v-col>
            </template>
            <v-col v-else cols="9">
              <v-btn @click="setEndDate" text outlined><v-icon left>mdi-update</v-icon>Set an end date</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template #actions>
      <v-spacer/>
      <v-btn min-width="112" text @click="$emit('cancel')">Cancel</v-btn>
      <v-btn min-width="112" color="accent" :disabled="!allowSubmit" @click="submit">
        OK
      </v-btn>
    </template>
  </card-view>
</template>

<script>
import CardOwnershipCheck from '@/components/cards/CardOwnershipCheck';
import CardScanField from '@/components/cards/CardScanField';
import CardView from '@/components/cards/CardView';
import VDatetimeField from '@/components/VDatetimeField';
import moment from 'moment';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'PermanentCardEditor',
  components: {CardOwnershipCheck, CardScanField, CardView, VDatetimeField},
  props: {
    newCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        id: null,
        reason: 'New',
        validFrom: moment().toDate(),
        validTo: null
      },
      reasons: [
        'New',
        'Broken',
        'Lost',
        'Stolen'
      ],
      rules: {
        required: value => !!value || 'Required'
      },
      valid: true,
      existingCard: null
    };
  },
  computed: {
    ...mapGetters('views/person', [
      'activeMembership',
      'hasPermanentCard',
      'hasTemporaryCard',
      'person'
    ]),
    ...mapState('views/person', ['permCard']),
    ...mapGetters('sites', ['activeSiteId']),
    allowSubmit() {
      if (this.newCard) {
        return this.valid;
      } else {
        return this.valid && (this.validFromChanged || this.validToChanged);
      }
    },
    cardId: {
      get() {
        if (this.existingCard && this.existingCard.hasOwnProperty('title')) {
          return this.existingCard.title;
        }
        return this.form.id;
      },
      set(v) {
        this.form.id = v;
      }
    },
    cardData() {
      const c = {
        id: this.form.id,
        role: 'permanent',
        sites: {},
        updateReason: this.form.reason,
        owner: {
          ref: this.person.ref,
          title: this.person.title,
          displayName: this.person.displayName
        }
      };
      c.sites[this.activeSiteId] = true;
      // perm cards should be non-transferable by default, but don't change it if the card already has the property
      if (!this.existingCard || !this.existingCard.hasOwnProperty('nonTransferable')) {
        c.nonTransferable = true;
      }
      return c;
    },
    validFrom() {
      if (!this.activeMembership) {
        return null;
      }
      if (!this.activeMembership.hasOwnProperty('validFrom')) {
        return null;
      }
      return this.activeMembership.validFrom.toDate();
    },
    validFromChanged() {
      return (this.validFrom ? this.validFrom.getTime() : 0) !== this.form.validFrom.getTime();
    },
    validTo() {
      if (!this.activeMembership) {
        return null;
      }
      if (!this.activeMembership.hasOwnProperty('validTo')) {
        return null;
      }
      return this.activeMembership.validTo.toDate();
    },
    validToChanged() {
      return (this.validTo ? this.validTo.getTime() : 0) !== (this.form.validTo ? this.form.validTo.getTime() : 0);
    }
  },
  watch: {
    validFrom: {
      handler(validFrom) {
        if (validFrom && validFrom instanceof Date) {
          this.form.validFrom = validFrom;
        }
      },
      immediate: true
    },
    validTo: {
      handler(validTo) {
        if (validTo && validTo instanceof Date) {
          this.form.validTo = validTo;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('views/person', [
      'newPermCard',
      'updateSiteValidity'
    ]),
    setEndDate() {
      this.form.validTo = moment().endOf('week').toDate();
    },
    submit() {
      if (this.newCard) {
        this.submitNew();
      } else {
        this.submitExisting();
      }
    },
    submitNew() {
      this.newPermCard({
        card: this.cardData,
        validFrom: this.form.validFrom,
        validTo: this.form.validTo,
        existingDoc: this.existingCard
      })
          .then(() => this.$emit('done'))
          .then(() => this.$notify.showSuccess('Card added'))
          .catch(err => this.$logger.error('Error adding card', err));
    },
    submitExisting() {
      if (this.allowSubmit) {
        this.updateSiteValidity({
          ref: this.activeMembership.ref,
          validFrom: this.form.validFrom,
          validTo: this.form.validTo
        })
            .then(() => this.$emit('done'))
            .then(() => this.$notify.showSuccess('Validity updated'))
            .catch(err => this.$logger.error('Error updating validity', err));
      }
    }
  }
};
</script>

<style scoped>

</style>
