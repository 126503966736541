/**
 * Represents the active person, i.e. the person page.
 */

import {db, decorateSnapshot, functionUtil} from '@/firebase';
import FirestoreUtil from '@/store/firestore-util';
import cardHistory from '@/views/people/person/person-card-history';
import peopleStore from '@/views/people/directory/people-store';
import {addMembership, addOrUpdateCard, isSameDocument, replacedCardDoc} from '@/views/people/person/person-util';
import {Logger} from '@vanti/vue-logger';
import firebase from 'firebase/app';
import Vue from 'vue';
import {CardIdTypeRef} from '@/util/card-type';
import {LoadingUtil} from '@/util/loading';
import DeferUtil from '@/store/defer-util';
import {addDays, startOfDay} from '@/util/dates';
import {scheduleTicks} from '@/util/schedule';

const log = Logger.get('person');

/**
 * @typedef {string} RefPath
 * @private
 */

/**
 * @typedef {Object} State
 * @property {boolean} loaded
 * @property {?DecoratedData} person
 * @property {Object.<RefPath,DecoratedData>} cards
 * @property {Object | DecoratedData} tempCard
 * @property {Object | DecoratedData} permCard
 * @property {Object.<RefPath,DecoratedData>} memberships
 * @property {Object.<string,function>} watchers
 * @property {Object.<string,firebase.firestore.FirestoreError>} errors
 */

export default {
  namespaced: true,
  /** @type {State} */
  state: {
    ...LoadingUtil.state('person'),
    person: null,
    cards: {},
    tempCard: {},
    permCard: {},
    memberships: {},
    watchers: {},
    errors: {},
    bookings: {},
    now: new Date(),
    departments: {}
  },
  getters: {
    ...LoadingUtil.getters(log),
    person(state) {
      return state.person;
    },
    personExists(state) {
      return state.person && state.person.exists;
    },
    cards(state) {
      // sorted by the reference path
      const sortedCards = Object.entries(state.cards);
      sortedCards.sort(([k1], [k2]) => {
        if (k1 < k2) {
          return -1;
        }
        if (k2 < k1) {
          return 1;
        }
        return 0;
      });
      return sortedCards.map(([_, v]) => v);
    },
    cardError(state) {
      return state.errors.cards;
    },
    hasPermanentCard(state) {
      return state.permCard.hasOwnProperty('id');
    },
    hasTemporaryCard(state) {
      return state.tempCard.hasOwnProperty('id');
    },
    memberships(state) {
      // sorted by the reference path
      const sorted = Object.entries(state.memberships);
      sorted.sort(([k1], [k2]) => {
        if (k1 < k2) {
          return -1;
        }
        if (k2 < k1) {
          return 1;
        }
        return 0;
      });
      return sorted.map(([_, v]) => v);
    },
    activeMembership(state, getters, rootState, rootGetters) {
      const activeSiteId = rootGetters['sites/activeSiteId'];
      return getters.memberships.find(m => {
        // check site ref
        return m.ref.parent.parent.id === activeSiteId;
      });
    },
    cardCollection(state, getters, rootState, rootGetters) {
      return rootGetters.nsRef + '/cards';
    },
    ns(state, getters, rootState, rootGetters) {
      return rootGetters.ns;
    },
    knownBookings(state) {
      return Object.values(state.bookings);
    },
    activeBooking(state, getters) {
      const now = state.now;
      return getters.knownBookings.find(b => {
        return b.fromTime.toDate() <= now && (!b.toTime || b.toTime.toDate() > now);
      }) || null;
    },
    /**
     * Returns a sorted (ascending) array of all the fromTime and toTimes for all known bookings. This is used to
     * calculate when we need to 'wake up' to change our state to correctly represent the active bookings.
     *
     * @param {Object} state
     * @return {Date[]}
     */
    changeSchedule(state) {
      const changes = new Set();
      Object.values(state.bookings).forEach(b => {
        if (b.fromTime) {
          changes.add(b.fromTime.toMillis());
        }
        if (b.toTime) {
          changes.add(b.toTime.toMillis());
        }
      });
      return Array.from(changes).sort((a, b) => a - b).map(t => new Date(t));
    }
  },
  mutations: {
    ...LoadingUtil.mutations(),
    ...DeferUtil.mutations(log),
    setTime(state, time) {
      state.now = time;
    },
    setPerson(state, person) {
      Vue.delete(state.errors, 'person');
      state.person = person;
    },
    setDepartments(state, docs) {
      Vue.delete(state.errors, 'departments');
      state.departments = docs;
    },
    /**
     * @param {State} state
     * @param {firebase.firestore.DocumentChange[]} cardUpdates
     */
    updateCards(state, cardUpdates) {
      Vue.delete(state.errors, 'cards');
      cardUpdates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            Vue.set(state.cards, update.doc.ref.path, decorateSnapshot(update.doc));
            break;
          case 'removed':
            Vue.delete(state.cards, update.doc.ref.path);
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {firebase.firestore.DocumentChange[]} cardUpdates
     */
    updateTempCard(state, cardUpdates) {
      Vue.delete(state.errors, 'tempCard');
      cardUpdates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            state.tempCard = update.doc;
            break;
          case 'removed':
            state.tempCard = {};
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {firebase.firestore.DocumentChange[]} cardUpdates
     */
    updatePermCard(state, cardUpdates) {
      Vue.delete(state.errors, 'permCard');
      cardUpdates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            state.permCard = update.doc;
            break;
          case 'removed':
            state.permCard = {};
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {firebase.firestore.DocumentChange[]} bookingSnapshot
     */
    updateBookings(state, bookingSnapshot) {
      Vue.delete(state.errors, 'bookings');
      bookingSnapshot.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            Vue.set(state.bookings, update.doc.ref.path, update.doc);
            break;
          case 'removed':
            Vue.delete(state.bookings, update.doc.ref.path);
            break;
        }
      });
    },
    updateMemberships(state, membershipUpdates) {
      Vue.delete(state.errors, 'memberships');
      membershipUpdates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            Vue.set(state.memberships, update.doc.ref.path, update.doc);
            break;
          case 'removed':
            Vue.delete(state.memberships, update.doc.ref.path);
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {Object.<string,function>} watchers
     */
    registerWatchers(state, watchers) {
      state.watchers = {...state.watchers, ...watchers};
    },

    recordError(state, errors) {
      Object.entries(errors).forEach(([name, err]) => {
        if (err) {
          Vue.set(state.errors, name, err);
        } else {
          Vue.delete(state.errors, name);
        }
      });
    },
    /**
     * @param {State} state
     */
    reset(state) {
      state.person = null;
      state.cards = {};
      state.tempCard = {};
      state.permCard = {};
      state.memberships = {};
      state.bookings = {};
      state.watchers = {};
    },
    /**
     * Reset state entries
     *
     * @param {State} state
     * @param {string[]} keys
     */
    resetKeys(state, keys) {
      keys.forEach(w => {
        Vue.set(state, w, {});
      });
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch, rootGetters}, authUser) {
        if (!authUser) {
          dispatch('unbind');
        }
      }
    },
    scheduleChanges({getters, commit}) {
      commit('defer', {
        scheduleChanges: scheduleTicks(getters.changeSchedule, d => commit('setTime', d))
      });
    },
    /**
     * Load the person given by the person argument and watch for changes. The person should have an id or ref field,
     * for the bind to do anything.
     *
     * If you bind a person that matches (id or ref match) the currently bound person then nothing happens.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<*>}
     */
    async bind({state, getters, commit, dispatch}, person) {
      // don't rebind if setting the same person
      if (isSameDocument(state.person, person)) {
        return;
      }

      await dispatch('unbind');
      commit('loading', 'person');

      if (!person || (!person.ref && !person.id)) {
        // assume they want to clear the active person, in which case we're done
        commit('setPerson', null);
        commit('loaded', 'person');
        return;
      }

      // if the person has data, then assume it's a representation of the person so we can be eager in our state
      if (typeof person.data === 'function') {
        commit('setPerson', decorateSnapshot(person));
      }

      // fetch all the information about a person we can
      const personRef = await dispatch('resolvePersonRef', person);

      const watchers = {};
      watchers.person = personRef.onSnapshot(
          personSnapshot => commit('setPerson', decorateSnapshot(personSnapshot)),
          err => commit('recordError', {person: err}));

      // register watchers so far
      commit('registerWatchers', watchers);

      await dispatch('bindCards', personRef);
      await dispatch('bindMembership', personRef);
      await dispatch('bindDepartments');

      const defer = {};
      dispatch('bindBookings', personRef)
          .catch(err => log.error('During bindBookings refresh', err));

      defer.changeSchedule = this.watch(
          () => getters.changeSchedule,
          () => dispatch('scheduleChanges', () => new Date()),
          {immediate: true});

      commit('defer', defer);
      commit('loaded', 'person');
    },
    /**
     * Load the ns department list for the current selected site.
     *
     * @param {import('vuex').ActionContext} context
     */
    async bindDepartments({getters, commit, dispatch, rootGetters}) {
      commit('loading', 'departments');
      const firestore = await db;
      await firestore.collection(`ns/${getters.ns}/departments`).doc('inputs')
          .onSnapshot(
              doc => {
                commit('setDepartments', doc.data());
                commit('loaded', 'departments');
              },
              err => {
                commit('recordError', {departments: err});
                commit('loaded', 'departments');
              });
    },
    /**
     * Load the persons memberships for the current selected site.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<*>}
     */
    async bindMembership({getters, commit, dispatch, rootGetters}, person) {
      commit('loading', 'membership');
      const firestore = await db;
      const personRef = await dispatch('resolvePersonRef', person);

      // clear memberships in preparation for site specific to be pulled in
      await dispatch('unbindKeys', ['memberships']);

      const activeSiteId = rootGetters['sites/activeSiteId'];
      if (activeSiteId) {
        const watchers = {};
        watchers.memberships = firestore.collection(`ns/${getters.ns}/sites/${activeSiteId}/members`)
            .where('ns', '==', getters.ns)
            .where('person.ref', '==', personRef)
            .onSnapshot(
                membershipQuerySnap => {
                  commit('updateMemberships',
                      FirestoreUtil.prepareQuerySnapshot(membershipQuerySnap));
                  commit('loaded', 'membership');
                },
                err => {
                  commit('recordError', {memberships: err});
                  commit('loaded', 'membership');
                });

        commit('registerWatchers', watchers);
      } else {
        commit('recordError', {
          memberships: 'no valid site id'
        });
      }
    },
    /**
     * Fetch the site references for all sites the given person has a membership at.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<firebase.firestore.DocumentReference[]>}
     */
    async fetchMembershipSiteRefs({getters, dispatch}, person) {
      const firestore = await db;
      const personRef = await dispatch('resolvePersonRef', person);
      // we get one record per site membership, and one with just the person
      const records = await firestore.collection(`ns/${getters.ns}/search/people/records`)
          .where('person.ref', '==', personRef)
          .get();
      return records.docs
          .map(r => r.get('site.ref'))
          .filter(Boolean); // filter out undefined
    },
    /**
     * Load the persons cards for the current selected site.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<*>}
     */
    async bindCards({getters, commit, dispatch, rootGetters}, person) {
      commit('loading', 'tempCards');
      commit('loading', 'permCards');
      const firestore = await db;
      const personRef = await dispatch('resolvePersonRef', person);

      // clear cards and their watchers in preparation for site specific to be pulled in
      await dispatch('unbindKeys', ['tempCard', 'permCard']);

      const activeSiteId = rootGetters['sites/activeSiteId'];
      if (activeSiteId) {
        const query = firestore.collection(`ns/${getters.ns}/cards`)
            .where('owner.ref', '==', personRef)
            .where('sites.' + activeSiteId, '==', true)
            .limit(1);

        const watchers = {};
        watchers.tempCard = query.where('role', '==', 'temporary')
            .onSnapshot(
                cardQuerySnap => {
                  commit('updateTempCard', FirestoreUtil.prepareQuerySnapshot(cardQuerySnap));
                  commit('loaded', 'tempCards');
                },
                err => {
                  commit('recordError', {tempCard: err});
                  commit('loaded', 'tempCards');
                });

        watchers.permCard = query.where('role', '==', 'permanent')
            .onSnapshot(
                cardQuerySnap => {
                  commit('updatePermCard', FirestoreUtil.prepareQuerySnapshot(cardQuerySnap));
                  commit('loaded', 'permCards');
                },
                err => {
                  commit('recordError', {permCard: err});
                  commit('loaded', 'permCards');
                });

        commit('registerWatchers', watchers);
      } else {
        commit('recordError', {
          permCard: 'no valid site id',
          tempCard: 'no valid site id'
        });
      }
    },
    /**
     * Load the persons bookings for the current selected site.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<*>}
     */
    async bindBookings({state, getters, commit, dispatch, rootGetters}, person) {
      commit('loading', 'bookings');
      const personRef = await dispatch('resolvePersonRef', person);

      // clear bookings and their watchers in preparation for site specific to be pulled in
      await dispatch('unbindKeys', ['bookings']);

      const activeSiteDoc = rootGetters['sites/activeSiteDoc'];
      const hasAccess = rootGetters['auth/hasAccessTo'];

      if (activeSiteDoc && hasAccess.deskBooking) {
        const now = new Date();
        const startOfToday = startOfDay(now);
        const startOfTomorrow = startOfDay(addDays(now, 1));
        const startAfter = firebase.firestore.Timestamp.fromDate(startOfToday);
        const startBefore = firebase.firestore.Timestamp.fromDate(startOfTomorrow);

        const query = activeSiteDoc.ref.collection('bookings')
            .where('owner.ref', '==', personRef)
            .where('fromTime', '>=', startAfter)
            .where('fromTime', '<', startBefore)
            .orderBy('fromTime');

        const watchers = {};
        watchers.bookings = query.onSnapshot(
            snap => {
              commit('updateBookings', FirestoreUtil.prepareQuerySnapshot(snap));
              commit('loaded', 'bookings');
            },
            err => {
              log.error('during selected person booking query', err);
              commit('loaded', 'bookings');
            });

        commit('registerWatchers', watchers);
      } else {
        commit('recordError', {
          bookings: 'no valid site id'
        });
        commit('loaded', 'bookings');
      }
    },
    /**
     * Takes the supported person description of ref path, id or ref DocRef and
     * returns a document reference for that person.
     *
     * @param {import('vuex').ActionContext} context
     * @param {*} person
     * @return {Promise<*>}
     */
    async resolvePersonRef({dispatch}, person) {
      return dispatch('resolveRef', {collection: 'people', doc: person}, {root: true});
    },
    unbind({state, commit}) {
      Object.values(state.watchers).forEach(watcher => watcher());
      commit('reset');
    },
    /**
     * Unbind watchers and clear state for each key
     *
     * @param {import('vuex').ActionContext} context
     * @param {string[]} keys
     */
    unbindKeys({state, commit}, keys) {
      keys.forEach(key => {
        state[key] = {};
        if (state.watchers.hasOwnProperty(key)) {
          state.watchers[key](); // stop listening
        }
      });
      commit('resetKeys', keys);
    },
    async addSiteMembership({getters, rootGetters}, {site, options, person = getters.person}) {
      if (!person) {
        throw new Error('No person to add membership for');
      }
      const membershipDoc = {
        ns: rootGetters.ns,
        ...options,
        person: {
          ref: person.ref,
          title: person.title,
          displayName: person.displayName
        }
      };

      const membershipRef = site.ref.collection('members').doc(person.id);
      return membershipRef.set(membershipDoc);
    },

    /**
     * Create or update the person's site membership. If they are not a member of the site,
     * they will be added as a member.
     *
     * @param {import('vuex').ActionContext} context
     * @param {kahu.firestore.Member} options
     * @return {Promise<void|*>}
     */
    async createOrUpdateSiteMembership({getters, rootGetters}, {options}) {
      const person = getters.person;
      if (!person) {
        throw new Error('no person to update site permissions for');
      }

      const auditSnippet = rootGetters['user/auditSnippet'];
      if (!getters.activeMembership) {
        // if the person doesn't have a membership to the active site, add one
        const activeSite = rootGetters['sites/activeSiteDoc'];
        if (!activeSite) throw new Error('no activeSite to add the person to');
        const siteDoc = activeSite.ref;
        const membershipDoc = {
          ns: getters.ns,
          ...options,
          person: {
            ref: person.ref,
            title: person.title,
            displayName: person.displayName
          },
          ...auditSnippet
        };
        const membershipRef = siteDoc.collection('members').doc(person.id);
        return membershipRef.set(membershipDoc);
      } else {
        return getters.activeMembership.ref.update({
          ...options,
          ...auditSnippet
        });
      }
    },

    async updateSiteValidity({getters, rootGetters}, {ref, validFrom, validTo}) {
      const data = {};
      const auditSnippet = rootGetters['user/auditSnippet'];
      if (validFrom) {
        data.validFrom = firebase.firestore.Timestamp.fromDate(validFrom);
      }
      if (validTo) {
        data.validTo = (validTo instanceof Date) ? firebase.firestore.Timestamp.fromDate(validTo) : validTo;
      } else {
        data.validTo = firebase.firestore.FieldValue.delete();
      }
      return ref.update({
        ...data,
        ...auditSnippet
      });
    },

    async updatePerson({state, rootGetters}, person) {
      if (person.email && typeof person.email.toLowerCase === 'function') {
        person.email = person.email.toLowerCase();
      }
      // Note: because we are bound to the person (or should be), we don't need to re-fetch or event commit changes to
      // our local person as firestore will do this for us.

      const auditSnippet = rootGetters['user/auditSnippet'];
      const data = {
        ...person,
        ...auditSnippet
      };
      if (person.ref && typeof person.ref.update === 'function') {
        return person.ref.update(data);
      } else {
        const fb = await db;
        return fb.collection('ns/' + rootGetters.ns + '/people')
            .add(data);
      }
    },

    async deletePerson({getters, dispatch}) {
      const person = getters.person;
      if (person) {
        await dispatch('unbind');
        const deletePerson = await functionUtil.personDeleteApi();
        return deletePerson({refPath: person.ref.path});
      }
    },

    async togglePersonInactive({getters, rootGetters}) {
      const person = getters.person;
      if (person) {
        const auditSnippet = rootGetters['user/auditSnippet'];
        const active = !person.active;
        await person.ref.update({
          active,
          ...auditSnippet
        });
        return active;
      }
    },

    /**
     * Enable the permanent card, if present;
     * expire the person's temporary card if there is one.
     *
     * @param {import('vuex').ActionContext} context
     * @return {Promise<void>}
     */
    async enablePermCard({state, rootGetters}) {
      const card = state.permCard;
      if (!card.hasOwnProperty('id')) {
        throw new Error('no permanent card');
      }
      const fb = await db;
      const batch = fb.batch();
      const auditSnippet = rootGetters['user/auditSnippet'];
      batch.update(card.ref, {
        disabled: firebase.firestore.FieldValue.delete(),
        ...auditSnippet
      });

      // expire temp card if there is one, and if it's not already expired
      const tempCard = state.tempCard;
      if (tempCard.hasOwnProperty('id')) {
        if (tempCard.enabledTo.toDate().getTime() > Date.now()) {
          batch.update(tempCard.ref, {
            enabledTo: firebase.firestore.Timestamp.now(),
            ...auditSnippet
          });
        }
      }
      await batch.commit();
    },

    async disablePermCard({state, rootGetters}) {
      const card = state.permCard;
      if (!card.hasOwnProperty('id')) {
        throw new Error('no permanent card');
      }
      const auditSnippet = rootGetters['user/auditSnippet'];
      return card.ref.update({
        disabled: true,
        ...auditSnippet
      });
    },

    /**
     * Assign the person a new permanent card.
     *
     * - set the card document
     * - add or update site membership
     * - set temp card expired (if applicable)
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {*} payload.card
     * @param {*} payload.existingDoc
     * @param {Date} payload.validFrom
     * @param {Date|null} payload.validTo
     * @return {Promise<void>}
     */
    async newPermCard({state, getters, dispatch, rootGetters}, {card, existingDoc, validFrom, validTo}) {
      log.debug('newPermCard', {card, validFrom, validTo});
      const person = getters.person;
      if (!person) {
        throw new Error('no person');
      }

      const fb = await db;
      const batch = fb.batch();
      const newCard = !existingDoc || !existingDoc.exists;

      const cardsCol = fb.collection(getters.cardCollection);
      const siteCardIdType = rootGetters['sites/active/configCardIdType'];
      let cardRef = cardsCol.doc(card.id);
      if (siteCardIdType !== CardIdTypeRef) {
        cardRef = newCard ? cardsCol.doc() : existingDoc.ref;
      }
      const id = cardRef.id;
      const auditSnippet = rootGetters['user/auditSnippet'];
      addOrUpdateCard({
        auditSnippet,
        activeSiteId: rootGetters['sites/activeSiteId'],
        batch,
        existingDoc,
        data: card,
        personId: person.id,
        ref: cardRef,
        siteCardIdType
      });

      if (!getters.activeMembership) {
        // if the person doesn't have a membership to the active site, add one
        const options = {
          ns: getters.ns,
          type: 'permanent',
          validFrom
        };
        if (validTo) {
          options.validTo = validTo;
        }
        const siteDoc = rootGetters['sites/activeSiteDoc'].ref;
        addMembership({batch, options, person, siteDoc});
      } else {
        // remove validTo date if it's not set (will be null)
        batch.update(getters.activeMembership.ref, {
          validFrom,
          validTo: validTo ? validTo : firebase.firestore.FieldValue.delete(),
          ...auditSnippet
        });
      }

      if (state.tempCard.hasOwnProperty('id') && state.tempCard.id !== id) {
        // if there is a temporary card it should be set to expired, unless it already has
        const now = new Date();
        if (state.tempCard.enabledTo.toDate() > now) {
          batch.update(state.tempCard.ref, {
            enabledTo: firebase.firestore.Timestamp.now(),
            ...auditSnippet
          });
        }
      }

      await batch.commit();
    },

    /**
     * Replace an existing card with a new one.
     *
     * - set the existing card to an empty object
     * - copy all the properties of the existing one to the new card
     * - set temp inactive
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {string} payload.newId
     * @param {string} payload.reason
     * @param {*} payload.targetCardData
     * @return {Promise<void>}
     */
    async replacePermCard({state, getters, rootGetters}, {newId, reason, targetCardData}) {
      const currentCard = state.permCard;
      if (!currentCard.hasOwnProperty('id')) {
        throw new Error('no permanent card');
      }

      const fb = await db;
      const siteCardIdType = rootGetters['sites/active/configCardIdType'];
      const siteId = rootGetters['sites/activeSiteId'];
      let newCardRef = currentCard.ref.parent.doc(newId);
      const newCardData = {...replacedCardDoc(), ...currentCard};
      newCardData.updateReason = reason;
      // don't copy these to the replacement card
      delete newCardData['title'];
      delete newCardData['uids'];
      delete newCardData.disabled;
      delete newCardData.nonTransferable;
      delete newCardData.cardIdType;

      if (siteCardIdType !== CardIdTypeRef) {
        if (targetCardData && targetCardData.exists) {
          newCardRef = targetCardData.ref;
        } else {
          newCardRef = currentCard.ref.parent.doc();
        }
        newCardData.uids = {};
        newCardData.uids[siteCardIdType] = newId;
      }

      // if the newly assigned card is a new document, or it doesn't have the property. Set it to true
      if (!targetCardData || !targetCardData.hasOwnProperty('nonTransferable')) {
        newCardData.nonTransferable = true;
      }

      // if the newly assigned card is a new document, or it doesn't have the property, set it
      if (!targetCardData || !targetCardData.hasOwnProperty('assignedAt')) {
        newCardData.assignedAt = siteId;
      }

      const batch = fb.batch();
      const auditSnippet = rootGetters['user/auditSnippet'];
      batch.set(currentCard.ref, {
        ...replacedCardDoc(),
        ...auditSnippet
      }, {merge: true});
      batch.set(newCardRef, {
        ...newCardData,
        ...auditSnippet
      }, {merge: true});

      const tempCard = state.tempCard;
      if (tempCard.hasOwnProperty('id')) {
        // if there is a temporary card it should be set to expired, unless it already has
        const now = new Date();
        if (tempCard.enabledTo.toDate() > now) {
          batch.update(tempCard.ref, {
            enabledTo: firebase.firestore.Timestamp.now(),
            ...auditSnippet
          });
        }
      }
      await batch.commit();
    },

    /**
     * Assign the person a new temporary card, or update an existing one
     *
     * - set or update the card document
     * - add or update site membership
     * - set perm card disabled (if applicable)
     * - if there is a temp card, remove ownership
     *
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {*} payload.card
     * @param {*} payload.existingDoc
     * @param {Date} payload.validFrom
     * @param {Date} payload.validTo
     * @return {Promise<void>}
     */
    async updateTempCard({state, getters, dispatch, rootGetters}, {card, existingDoc, validFrom, validTo}) {
      log.debug('updateTempCard', {card, validFrom, validTo});
      const person = getters.person;
      if (!person) {
        throw new Error('no person');
      }

      const fb = await db;
      const batch = fb.batch();

      const newCard = !existingDoc || !existingDoc.exists;

      const cardsCol = fb.collection(getters.cardCollection);
      const siteCardIdType = rootGetters['sites/active/configCardIdType'];
      let cardRef = cardsCol.doc(card.id);
      if (siteCardIdType !== CardIdTypeRef) {
        cardRef = newCard ? cardsCol.doc() : existingDoc.ref;
      }
      const id = cardRef.id;
      const auditSnippet = rootGetters['user/auditSnippet'];
      addOrUpdateCard({
        auditSnippet,
        activeSiteId: rootGetters['sites/activeSiteId'],
        batch,
        existingDoc,
        data: card,
        personId: person.id,
        ref: cardRef,
        siteCardIdType
      });

      if (!getters.activeMembership) {
        // if the person doesn't have a membership to the active site, add one
        const options = {
          ns: getters.ns,
          type: 'temporary',
          validFrom,
          validTo
        };
        const siteDoc = rootGetters['sites/activeSiteDoc'].ref;
        addMembership({batch, options, person, siteDoc});
      } else {
        // if the person is already a member of the site, check to see if
        // the card validity is in the range of the membership validity
        // if not, update the membership validity
        const activeMembership = getters.activeMembership;
        const data = {};
        if (activeMembership.validFrom) {
          // membership valid from is after valid from
          if (activeMembership.validFrom.toDate() > validFrom) {
            data.validFrom = validFrom;
          }
        }
        if (activeMembership.validTo) {
          // membership valid to is before form valid to
          if (activeMembership.validTo.toDate() < validTo) {
            data.validTo = validTo;
          }
        }
        batch.update(activeMembership.ref, {
          ...data,
          ...auditSnippet
        });
      }

      if (state.tempCard.hasOwnProperty('id') && state.tempCard.id !== id) {
        // if there is an existing temp card, remove ownership
        batch.update(state.tempCard.ref, {
          ...replacedCardDoc(),
          ...auditSnippet
        });
      }

      // don't disable the permanent card if its being made into the temporary
      if (state.permCard.hasOwnProperty('id') && state.permCard.id !== id) {
        if (validTo.getTime() > Date.now()) {
          // temp card hasn't expired, so any permanent card should be disabled
          batch.update(state.permCard.ref, {
            disabled: true,
            ...auditSnippet
          });
        }
      }

      await batch.commit();
    }
  },
  modules: {
    cardHistory,
    search: peopleStore() // used for delegate search
  }
};
