import {createSnippet, db} from '@/firebase';
import {byId, byRef} from '@/store/firestore-util';
import {toTime} from '@/util/dates';
import {calcPrivacy, calcPrivateValue} from '@/util/privacy';
import {defineApprovalProperties} from './event-approval';
import {defineConnectionProperties} from './event-connections';
import nested from 'nested-property';
import {newCateringWorkOrder} from '@/views/room-booking/calendar/v2/work-order';
import {CascadingConfig} from '@/util/config';
import {createWorkOrderDetail} from '@/views/room-booking/calendar/work-orders-util';
import {eventsConflictWithRange} from '@/util/range';
import {calcBoundsPeriodSpans} from '@/views/room-booking/calendar/v2/room-booking';

/**
 * Converts an array of bookings into an array of events.
 *
 * @param {import('vuex').ActionContext} context
 * @param {(kahu.firestore.Booking & DecoratedData)[]} bookings
 * @return {CalendarEvent[]}
 */
export function computeEvents(context, bookings) {
  const events = [];
  for (const booking of bookings) {
    events.push(...bookingToEvents(context, booking));
  }
  return events;
}

/**
 * Each booking can result in multiple events.
 * Maybe the booking reserves multiple rooms, maybe it blocks divisible rooms.
 *
 * @param {import('vuex').ActionContext} context
 * @param {kahu.firestore.Booking & DecoratedData} booking
 * @return {CalendarEvent[]}
 */
export function bookingToEvents(context, booking) {
  const events = [];
  for (const [reservationKey, reservation] of Object.entries(booking.reservations)) {
    const resourceRef = byRef(reservation.resource);
    const resource = context.getters.resourceByRef(resourceRef);
    if (!resource) {
      // a resource is reserved that isn't being displayed, so don't create an event for it.
      continue;
    }

    const event = reservationToEvent(context, booking, reservationKey);
    events.push(event);

    // work out any blocked events based on the reservation

    // block out any parents
    const seenParentRefs = {[resourceRef]: true};
    const parents = [...(resource.parents || [])]; // copy to avoid editing the original data
    while (parents.length > 0) {
      const parent = parents.pop();
      const parentRef = byRef(parent);
      if (seenParentRefs.hasOwnProperty(parentRef)) continue;
      seenParentRefs[parentRef] = true;

      const parentSpace = context.getters.resourceByRef(parentRef);
      if (!parentSpace) continue;

      events.push(newBlockedEvent(context, event, parentSpace));
      parents.push(...(parentSpace.parents || []));
    }

    // block out any children
    const findChildren = context.getters.resourcesByParentRef;
    const seenChildRefs = {[resourceRef]: true};
    const children = [...findChildren(resourceRef)]; // copy to avoid editing the indexed array
    while (children.length > 0) {
      const child = children.pop();
      const childRef = byRef(child);
      if (seenChildRefs.hasOwnProperty(childRef)) continue;
      seenChildRefs[childRef] = true;

      events.push(newBlockedEvent(context, event, child));
      children.push(...findChildren(childRef));
    }
  }

  return events;
}

/**
 * Converts a booking reservation into an event.
 *
 * @param {import('vuex').ActionContext} context
 * @param {kahu.firestore.Booking & DecoratedData} booking
 * @param {string} reservationKey
 * @param {kahu.firestore.Reservation} [newReservation]
 * @return {CalendarEvent}
 */
export function reservationToEvent(context, booking, reservationKey, newReservation) {
  const reservation = booking.reservations[reservationKey] || newReservation;
  const event = /** @type {CalendarEvent} */ {
    timed: true,
    ref: byRef(booking),
    id: byId(booking)
  };
  Object.defineProperty(event, 'key', {value: {ref: booking.ref, id: reservationKey}});
  Object.defineProperty(event, 'booking', {value: booking});
  Object.defineProperty(event, 'reservationCount', {
    enumerable: true,
    get() {
      return Object.keys(booking.reservations).length;
    }
  });
  Object.defineProperty(event, 'reservation', {value: reservation});
  Object.defineProperty(event, 'reservationKey', {value: reservationKey});
  Object.defineProperty(event, 'resourceSnippet', {
    enumerable: false,
    get() {
      return reservationValue(context, booking, reservationKey, 'resource');
    },
    set(v) {
      editReservationValue(context, booking, reservationKey, 'resource', createSnippet(v));
    }
  });
  Object.defineProperty(event, 'resource', {
    enumerable: true,
    get() {
      const resourceSnippet = event.resourceSnippet;
      const resourceRefString = byRef(resourceSnippet);
      return context.getters.resourceByRef(resourceRefString) || resourceSnippet || {};
    }
  });
  Object.defineProperty(event, 'resourceConfig', {
    enumerable: true,
    get() {
      const resourceSnippet = event.resourceSnippet;
      const resourceRefString = byRef(resourceSnippet);
      const resourceDoc = context.getters.resourceByRef(resourceRefString);
      let siteDoc = {};
      if (resourceDoc?.site && resourceDoc?.site?.ref) {
        siteDoc = context.rootGetters['sites/sitesById'][resourceDoc.site.ref.id];
      }
      return new CascadingConfig([
        resourceDoc || resourceSnippet || {},
        siteDoc || {},
        context.rootState.ns.doc || {}
      ]);
    }
  });
  Object.defineProperty(event, 'cateringIsEnabled', {
    enumerable: true,
    get() {
      return event.resourceConfig.get('cateringIsEnabled', false);
    }
  });
  Object.defineProperty(event, 'cateringEditTimeEnd', {
    enumerable: true,
    get() {
      return event.resourceConfig.get('cateringEditTimeEnd', '-1d');
    }
  });
  Object.defineProperty(event, 'privacy', {
    enumerable: true,
    get() {
      return booking.privacy;
    }
  });
  Object.defineProperty(event, 'name', {
    enumerable: true,
    get() {
      const privateValue = calcPrivateValue(event.privacy, bookingValue(context, booking, 'title'), 'title');
      return privateValue.value;
    },
    set(value) {
      if (calcPrivacy(event.privacy, 'title')) return;
      editValue(context, booking, 'title', value);
    }
  });
  Object.defineProperty(event, 'reservationStart', {
    enumerable: true,
    get() {
      return toTime(reservationValue(context, booking, reservationKey, 'reservedPeriod.fromTime'));
    },
    set(v) {
      editReservationValue(context, booking, reservationKey, 'reservedPeriod.fromTime', new Date(v));
    }
  });
  Object.defineProperty(event, 'bookingStart', {
    enumerable: true,
    get() {
      return toTime(bookingValue(context, booking, 'bookedPeriod.fromTime'));
    },
    set(v) {
      editValue(context, booking, 'bookedPeriod.fromTime', new Date(v));
    }
  });
  Object.defineProperty(event, 'start', {
    enumerable: true,
    get() {
      // calendar events don't move if they are checked in early or late
      return event.reservationStart || event.bookingStart;
    },
    set(v) {
      const adj = event.adjacentBefore;
      // if the reservation is overriding any of the period properties continue using it
      if (event.reservationStart || event.reservationEnd) {
        event.reservationStart = v;
      } else {
        event.bookingStart = v;
      }

      if (adj && adj.connected) {
        const duration = adj.duration;
        adj.start = v - duration;
        adj.end = v;
      }
    }
  });
  Object.defineProperty(event, 'reservationEnd', {
    enumerable: true,
    get() {
      return toTime(reservationValue(context, booking, reservationKey, 'reservedPeriod.toTime'));
    },
    set(v) {
      editReservationValue(context, booking, reservationKey, 'reservedPeriod.toTime', new Date(v));
    }
  });
  Object.defineProperty(event, 'bookingEnd', {
    enumerable: true,
    get() {
      return toTime(bookingValue(context, booking, 'bookedPeriod.toTime'));
    },
    set(v) {
      editValue(context, booking, 'bookedPeriod.toTime', new Date(v));
    }
  });
  Object.defineProperty(event, 'end', {
    enumerable: true,
    get() {
      if (event.checkedOut) return toTime(reservation.checkInPeriod.toTime);
      return event.reservationEnd || event.bookingEnd;
    },
    set(v) {
      const adj = event.adjacentAfter;
      // if the reservation is overriding any of the period properties continue using it
      if (event.reservationStart || event.reservationEnd) {
        event.reservationEnd = v;
      } else {
        event.bookingEnd = v;
      }

      if (adj && adj.connected) {
        const duration = adj.duration;
        adj.start = v;
        adj.end = v + duration;
      }
    }
  });
  Object.defineProperty(event, 'duration', {
    enumerable: true,
    get() {
      return event.end - event.start;
    }
  });
  Object.defineProperty(event, 'category', {
    enumerable: true,
    get() {
      return event.resource.title;
    },
    set(v) {
      const resource = v && v.title ? context.getters.resourceByTitle(v.title) : context.getters.resourceByTitle(v);
      const range = {start: event.bookingStart, end: event.bookingEnd};

      if (!eventsConflictWithRange(context.getters.events, range, resource.ref)) {
      // if (computeConflicts(existingBookingsForResource).length === 0) {
        event.resourceSnippet = resource;

        // update the catering requests if it has any
        if (event.cateringRequests.length > 0) {
          if (event.cateringIsEnabled) {
            const updatedValue = createWorkOrderDetail(event.key, event.resource);
            event.updateAllCateringRequests({
              property: 'subject',
              value: updatedValue
            });
          } else {
            event.removeAllCateringRequests();
          }
        }
      }
    }
  });
  Object.defineProperty(event, 'owner', {
    enumerable: true,
    get() {
      const privateValue = event.ownerPrivacy;
      if (privateValue.masked) {
        if (privateValue.exists) return {title: privateValue.value, ref: {path: 'private'}};
        return null;
      }
      return privateValue.value;
    },
    set(v) {
      if (calcPrivacy(event.privacy, 'owner')) return;
      editValue(context, booking, 'owner', createSnippet(v));

      if (event.needsApproval) {
        event.approval = true;
      }
    }
  });
  Object.defineProperty(event, 'ownerPrivacy', {
    enumerable: true,
    get() {
      return calcPrivateValue(event.privacy, bookingValue(context, booking, 'owner'), 'owner');
    }
  });

  Object.defineProperty(event, 'requiresCheckIn', {
    enumerable: true,
    get() {
      return Boolean(reservationValue(context, booking, reservationKey, 'requiresCheckIn'));
    },
    set(v) {
      editReservationValue(context, booking, reservationKey, 'requiresCheckIn', v);
    }
  });
  Object.defineProperty(event, 'checkedIn', {
    enumerable: true,
    get() {
      return Boolean(reservation.checkInPeriod && reservation.checkInPeriod.fromTime);
    }
  });
  Object.defineProperty(event, 'checkedOut', {
    enumerable: true,
    get() {
      return Boolean(reservation.checkInPeriod && reservation.checkInPeriod.toTime);
    }
  });

  Object.defineProperty(event, 'active', {
    enumerable: true,
    get() {
      return (!reservation.requiresCheckIn || event.checkedIn) && !event.checkedOut;
    }
  });

  Object.defineProperty(event, 'note', {
    enumerable: true,
    get() {
      return reservationValue(context, booking, reservationKey, 'note');
    },
    set(v) {
      editReservationValue(context, booking, reservationKey, 'note', v);
    }
  });

  Object.defineProperty(event, 'requests', {
    enumerable: true,
    get() {
      return reservationValue(context, booking, reservationKey, 'requests');
    },
    set(v) {
      editReservationValue(context, booking, reservationKey, 'requests', v);
    }
  });

  Object.defineProperty(event, 'cateringRequests', {
    enumerable: true,
    get() {
      const requests = event.requests || [];
      return requests.filter(r => r.category === 'Catering');
    }
  });

  Object.defineProperty(event, 'workOrderRefs', {
    enumerable: true,
    get() {
      const requests = event.requests || [];
      return requests.map(r => r && r.ref).filter(Boolean);
    }
  });

  Object.defineProperty(event, 'firstCateringWorkOrder', {
    enumerable: true,
    get() {
      if (event.cateringRequests.length === 0) {
        return null;
      }
      const firstRequest = event.cateringRequests[0];
      const workOrdersByRef = context.state.workOrders.byRef;
      const workOrdersAddedById = context.state.workOrders.added;
      const ref = byRef(firstRequest);
      const id = firstRequest && firstRequest.ref && firstRequest.ref.id;
      if (workOrdersAddedById.hasOwnProperty(id)) {
        return workOrdersAddedById[id];
      }
      if (workOrdersByRef.hasOwnProperty(ref)) {
        return workOrdersByRef[ref];
      }
      return null;
    },
    set({ref, value}) {
      const isNew = Boolean(!ref && value);
      const isUpdate = Boolean(ref && value);
      const isDelete = Boolean(ref && !value);
      if (isNew) {
        // 1. create a new work order reference + document
        // 2. commit an edit to the reservation requests
        // 3. commit the new work order
        const workOrder = newCateringWorkOrder(context, event, {[value.property]: value.value});

        const request = {category: workOrder.category, ref: workOrder.ref};
        // use requests set here, rather than calling .push, since this will record it as an edit
        event.requests = event.requests ? [...event.requests, request] : [request];
        // save the new work order in the store until we commit and save it
        context.commit('workOrders/addWorkOrder', {
          ref: workOrder.ref,
          value: workOrder
        });
      } else if (isUpdate) {
        context.commit('workOrders/recordEdit', {
          workOrder: ref,
          ...value
        });
      } else if (isDelete) {
        // commit the work order delete, and the change to reservation requests
        const addedWorkOrders = context.state.workOrders.added;
        if (addedWorkOrders.hasOwnProperty(ref.id)) {
          // if we're deleting a work order that doesn't yet exist, we also want to reset the request changes
          // otherwise if we do the filter below it still looks like there are changes.
          context.commit('resetEditProp', {booking, property: `reservations.${reservationKey}.requests`});
        } else {
          event.requests = event.requests.filter(r => r && r.ref && !r.ref.isEqual(ref));
        }
        context.commit('workOrders/setDeleteWorkOrder', ref);
      }
    }
  });

  Object.defineProperty(event, 'hadCateringRequests', {
    enumerable: true,
    get() {
      const bookingRequests = booking.reservations[reservationKey].requests || null;
      return bookingRequests && bookingRequests.some(r => r.category === 'Catering') &&
          event.cateringRequests.length === 0;
    }
  });

  defineApprovalProperties(context, event);
  defineConnectionProperties(context, event);

  event.addReservation = resource => {
    return context.dispatch('addReservationToBooking', {booking, resource});
  };

  event.removeAllCateringRequests = () => {
    // set the catering requests up to be deleted.
    event.cateringRequests.forEach(r => context.commit('workOrders/setDeleteWorkOrder', r.ref));
    // filter out all catering requests for this reservation
    const cateringIds = event.cateringRequests.map(r => r.ref.id);
    event.requests = event.requests.filter(r => !cateringIds.includes(r.ref.id));
  };

  event.updateAllCateringRequests = update => {
    // updates all catering requests for this event with the submitted information.
    event.cateringRequests.forEach(r => {
      context.commit('workOrders/recordEdit', {
        workOrder: r.ref,
        ...update
      });
    });
  };

  // edit handling
  event.commit = batch => {
    // ensure _boundsPeriodSpans is populated so the booking is captured in queries immediately
    const spans = calcBoundsPeriodSpans(new Date(event.bookingStart), new Date(event.bookingEnd));
    if (spans) {
      booking['_boundsPeriodSpans'] = spans;
    }
    const connectionsIn = event.connectionsIn;
    let weMadeTheBatch = false;
    return /** @type {Promise<void>} */ Promise.resolve(batch).then(batch => {
      // reuse the passed in batch, or we don't need one
      if (batch || connectionsIn.length === 0) return batch;

      // need to make a new batch
      weMadeTheBatch = true;
      return db.then(_db => _db.batch());
    }).then(batch => {
      // interact with firestore to save the edits
      const tasks = [];
      tasks.push(context.dispatch('commitEdit', {booking, batch}));
      connectionsIn.forEach(({event}) => tasks.push(event.commit(batch)));
      return Promise.all(tasks).then(() => batch);
    }).then(batch => {
      // if this was a batch that we made, then commit it
      if (weMadeTheBatch) return batch.commit();
      return null;
    }).then(() => {
      return context.dispatch('workOrders/commit')
          .then(() => context.commit('workOrders/clearStore'));
    });
  };
  event.reset = () => {
    context.commit('resetEdit', booking);
    event.connectionsIn.forEach(({event}) => event.reset());
    // clear any updates to the work orders
    context.commit('workOrders/clearStore');
  };
  event.delete = () => {
    context.commit('deleteEvent', event);
  };
  event.restore = () => {
    context.commit('restoreEvent', event);
  };
  Object.defineProperty(event, 'added', {
    enumerable: true,
    get() {
      return context.state.added.findIndex(e => e === event) !== -1;
    }
  });
  Object.defineProperty(event, 'edited', {
    enumerable: true,
    get() {
      const edit = context.state.edits[booking.id] || {};
      return Object.keys(edit).length > 0;
    }
  });
  Object.defineProperty(event, 'editedGraph', {
    enumerable: true,
    get() {
      if (event.edited) return true;
      return event.connectionsIn.findIndex(({event}) => event.editedGraph) !== -1;
    }
  });
  Object.defineProperty(event, 'deleted', {
    enumerable: true,
    get() {
      return context.state.deleted.findIndex(e => e === event) !== -1;
    }
  });

  return event;
}

/**
 * Create a new event that blocks out an equivalent period for the given space
 *
 * @param {import('vuex').ActionContext} context
 * @param {Object} event
 * @param {kahu.firestore.Resource & DecoratedData} resource
 * @return {Object}
 */
export function newBlockedEvent(context, event, resource) {
  const ref = event.ref + '>' + resource.title;
  const res = {
    timed: true,
    blocked: true,
    category: resource.title,
    ref,
    key: {
      ref,
      id: resource.ref.path
    },
    commit() {
    },
    reset() {
    }
  };
  Object.defineProperty(res, 'name', {
    enumerable: true,
    get() {
      return `Reserved via ${event.category}`;
    }
  });
  Object.defineProperty(res, 'start', {
    enumerable: true,
    get() {
      return event.start;
    }
  });
  Object.defineProperty(res, 'end', {
    enumerable: true,
    get() {
      return event.end;
    }
  });
  Object.defineProperty(res, 'accepted', {
    enumerable: true,
    get() {
      return event.accepted;
    }
  });

  Object.defineProperty(res, 'rejected', {
    enumerable: true,
    get() {
      return event.rejected;
    }
  });

  Object.defineProperty(res, 'showEvent', {
    enumerable: true,
    get() {
      return event.showEvent;
    }
  });
  return res;
}

/**
 * Gets the value from a booking. Prefers edited values over non-edited.
 *
 * @param {import('vuex').ActionContext} context
 * @param {kahu.firestore.Booking & DecoratedData} booking
 * @param {string} property
 * @return {*}
 */
export function bookingValue({state}, booking, property) {
  if (state.edits[booking.id]) {
    if (state.edits[booking.id].hasOwnProperty(property)) {
      return state.edits[booking.id][property];
    }
  }
  return nested.get(booking, property);
}

/**
 * Edit the given property to set it to the given value.
 *
 * @param {import('vuex').ActionContext} context
 * @param {kahu.firestore.Booking & DecoratedData} booking
 * @param {string} property
 * @param {*} value
 */
export function editValue({commit}, booking, property, value) {
  commit('recordEdit', {booking, property, value});
}

/**
 * Gets the value from a booking reservation. Prefers edited values over non-edited.
 *
 * @param {import('vuex').ActionContext} context
 * @param {kahu.firestore.Booking & DecoratedData} booking
 * @param {string} reservationKey
 * @param {string} property
 * @return {*}
 */
export function reservationValue(context, booking, reservationKey, property) {
  return bookingValue(context, booking, `reservations.${reservationKey}.${property}`);
}

/**
 * Edit the given property to set it to the given value.
 *
 * @param {import('vuex').ActionContext} context
 * @param {kahu.firestore.Booking & DecoratedData} booking
 * @param {string} reservationKey
 * @param {string} property
 * @param {*} value
 */
export function editReservationValue(context, booking, reservationKey, property, value) {
  editValue(context, booking, `reservations.${reservationKey}.${property}`, value);
}
