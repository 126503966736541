import map from '@/views/desk-booking/settings/map/map';
import neighbourhoods from '@/views/desk-booking/settings/neighbourhoods/neighbourhoods';
import desks from '@/views/desk-booking/settings/neighbourhoods/desks';
import circles from '@/views/desk-booking/settings/map/circles';
import changes from '@/views/desk-booking/settings/sidebar/changes-store';

export default {
  namespaced: true,
  modules: {
    changes,
    circles,
    desks,
    map,
    neighbourhoods
  }
};
