<template>
  <v-tooltip :top="!bottom" :bottom="bottom">
    <template #activator="{on}">
      <span v-on="on">{{ cardTitle }}</span>
    </template>
    <table class="card-id-details">
      <tr><td>Card Format</td><td class="text-right">{{ cardTypeName }}</td></tr>
      <template v-if="hasCardIdDetails">
        <tr><td>Card Number</td><td class="text-right">{{ cardNumber }}</td></tr>
        <tr><td>Facility Code</td><td class="text-right">{{ facilityCode }}</td></tr>
      </template>
    </table>
  </v-tooltip>
</template>

<script>
import {CardIdTypeNames, ParserByCardIdType} from '@/components/cards/card-id-types';

export default {
  name: 'CardIdDetail',
  props: {
    bottom: {
      type: Boolean,
      default: false
    },
    cardIdType: {
      type: String,
      required: true
    },
    cardTitle: {
      type: String,
      required: true
    },
    hexCardId: {
      type: String,
      required: true
    }
  },
  computed: {
    cardTypeName() {
      return CardIdTypeNames[this.cardIdType];
    },
    hasCardIdDetails() {
      return Boolean(ParserByCardIdType[this.cardIdType]);
    },
    cardIdDetails() {
      if (!this.hasCardIdDetails) {
        return;
      }
      const parse = ParserByCardIdType[this.cardIdType];
      return parse(this.hexCardId);
    },
    cardNumber() {
      if (!this.cardIdDetails) {
        return;
      }
      return this.cardIdDetails.cardNumber;
    },
    facilityCode() {
      if (!this.cardIdDetails) {
        return;
      }
      return this.cardIdDetails.facilityCode;
    }
  }
};
</script>

<style scoped>
.card-id-details {
  border-collapse: collapse;
}
.card-id-details td {
  /*border: 1px solid grey;*/
  padding: 5px;
}
</style>
