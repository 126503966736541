import {updateDataToArray} from '@/firebase';
import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';
import {toEditableDesk} from '@/views/desk-booking/settings/neighbourhoods/editable-desk';
import {EditableUtil} from '@/util/editable-util';
import {byRef} from '@/store/firestore-util';

const log = Logger.get('desk-booking/settings/desks');

export default {
  namespaced: true,
  state: {
    /**
     * Array of converted desks
     *
     * @type {EditableDesk[]}
     */
    desks: [],
    /**
     * Keyed by desk id, each edit represents changes to a desk.
     *
     * @type {Object<string,Object>}
     */
    edits: {},
    /**
     * Keyed by neighbourhood id, each entry represents a desk neighbourhood addition.
     *
     * @type {Object<string,EditableDesk[]>}
     */
    additions: {},
    /**
     * Keyed by neighbourhood id, each entry represents a desk neighbourhood removal.
     *
     * @type {Object<string,EditableDesk[]>}
     */
    removals: {}
  },
  getters: {
    deskById(state) {
      return id => {
        return state.desks.find(d => d.id === id);
      };
    },
    additionsByNeighbourhoodRef(state) {
      return ref => {
        return state.additions[ref];
      };
    },
    removalsByNeighbourhoodRef(state) {
      return ref => {
        return state.removals[ref];
      };
    },
    allEdits(state) {
      return Object.values(state.edits);
    },
    edits(state) {
      const edits = [];
      for (const [desk, changes] of Object.entries(state.edits)) {
        for (const [variable, val] of Object.entries(changes)) {
          if (variable !== 'neighbourhood') {
            edits.push([desk, {[variable]: val}]);
          }
        }
      }
      return edits;
    },
    neighbourhoodEdits(state) {
      const edits = [];
      for (const [desk, changes] of Object.entries(state.edits)) {
        for (const [variable, val] of Object.entries(changes)) {
          if (variable === 'neighbourhood') {
            edits.push([desk, val]);
          }
        }
      }
      return Object.values(edits);
    },
    totalDesksCount(state) {
      return state.desks?.length;
    },
    totalDesksLoaded(state, getters, rootState, rootGetters) {
      return Object.values(getters.desksCountOnEachFloor).reduce((a, b) => a + b, 0);
    },
    desksCountOnEachFloor(state, getters, rootState, rootGetters) {
      const floors = rootGetters['floors/floors'];

      const res = Object.keys(floors).reduce((acc, floor)=>{
        acc[floor] = Object.keys(floors[floor].desksById).length;
        return acc;
      }, {});

      return res;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    ...EditableUtil.mutations(),
    setDesks(state, desks) {
      Vue.set(state, 'desks', desks);
    },
    clearLocalState(state) {
      state.desks = [];
    },
    removeDeskFromArrays(state, {desk}) {
      // Check if desk has an existing neighbourhood
      if (desk.neighbourhood) {
        // Get the path from the desks neighbourhood
        const nhPath = byRef(desk.neighbourhood);
        // Get the list of removed desks from the original neighbourhood
        const nhRemovals = state.removals[nhPath];

        // If there are removals for that neighbourhood
        if (nhRemovals) {
          // Find the desk in the array and remove it
          const removalIndex = nhRemovals.findIndex(d => d.id === desk.id);
          if (removalIndex !== -1) nhRemovals.splice(removalIndex, 1);
        }
      }

      // Loop through all neighbourhoods in additions to find any references to the desk
      for (const desks of Object.values(state.additions)) {
        const index = desks.findIndex(d => d.id === desk.id);
        if (index !== -1) {
          // If the desk exists in that additions entry, remove it from the array
          desks.splice(index, 1);
        }
      }
    },
    recordRemoval(state, {neighbourhood, desk}) {
      /**
       * This loop checks if the edited desk already has a removal in the state
       * If it does, the function returns without an action, this stops the same desk from appearing as a
       * removal under multiple neighbourhoods
       */
      for (const neighbourhood of Object.values(state.removals)) {
        if (neighbourhood.findIndex(d => d.id === desk.id) !== -1) return;
      }

      const removal = state.removals[neighbourhood.ref.path] ||
          Vue.set(state.removals, neighbourhood.ref.path, []);
      if (desk) removal.push(desk);
    },
    recordAddition(state, {neighbourhood, desk}) {
      /**
       * This loop checks if the edited desk already has an addition in the state
       * If it does, the existing change will be removed and the state will only store the most recent neighbourhood
       * addition, this stops the same desk from appearing as an addition under multiple neighbourhoods
       */
      for (const [id, neighbourhood] of Object.entries(state.additions)) {
        const index = neighbourhood.findIndex(d => d.id === desk.id);
        if (index !== -1) {
          state.additions[id].splice(index, 1);
        }
      }

      const ref = neighbourhood.ref ? neighbourhood.ref : neighbourhood;
      const addition = state.additions[ref.path] ||
          Vue.set(state.additions, ref.path, []);
      if (desk) addition.push(desk);
    },
    resetEdit(state, desk) {
      for (const neighbourhood of Object.values(state.additions)) {
        const i = neighbourhood.findIndex(d => d.id === desk.id);
        if (i !== -1) {
          neighbourhood.splice(i, 1);
        }
      }
      for (const neighbourhood of Object.values(state.removals)) {
        const i = neighbourhood.findIndex(d => d.id === desk.id);
        if (i !== -1) {
          neighbourhood.splice(i, 1);
        }
      }
      Vue.delete(state.edits, desk.id);
    },
    clearEdits(state) {
      state.edits = {};
      state.additions = {};
      state.removals = {};
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (authUser) {
          return dispatch('fetchDesks');
        } else {
          return dispatch('clear');
        }
      }
    },
    async fetchDesks({state, commit, dispatch, rootGetters}) {
      if (DeferUtil.hasDefer(state)) {
        // already bound
        return;
      }
      const defer = {};
      defer.desksUpdated = this.watch(
          () => {
            const bySiteByType = rootGetters['sites/aggregates/bySiteByType'];
            const activeSite = rootGetters['sites/activeSiteDoc'];
            if (!activeSite) return;
            return bySiteByType(byRef(activeSite), 'bookables');
          },
          bookablesById => {
            // we have all site bookables here, so need to filter by kind to just get desks
            const desks = Object.values(bookablesById || {})
                .filter(b => b.kind && b.kind.ref && b.kind.ref.path === 'space-kinds/desk');
            log.debug('desksUpdated', desks.length);
            commit('clearLocalState');
            if (desks.length === 0) {
              return dispatch('clear');
            }
            dispatch('convert', desks)
                .catch(err => log.error('During desks fetch', err));
          }
      );
      commit('defer', defer);
    },

    clear({commit}) {
      commit('clearEdits');
    },

    async convert(context, desks) {
      context.commit('setDesks', desks.map(d => toEditableDesk(context, d)));
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {BatchGroup} batchGroup
     * @return {Promise<void>}
     */
    async commitAll({state, commit}, batchGroup) {
      for (const [id, change] of Object.entries(state.edits)) {
        const desk = state.desks.find(d => d.id === id);
        batchGroup.update(desk.ref, ...updateDataToArray(change));
      }
    }
  }
};
