import admin from '@/views/admin/admin';
import card from '@/views/people/card/card';
import cards from '@/views/people/cards/cards';
import dashboard from '@/views/dashboard/dashboard';
import deskBooking from '@/views/desk-booking/desk-booking';
import people from '@/views/people/directory/people';
import person from '@/views/people/person/person';
import roomBooking from '@/views/room-booking/room-booking';
import user from '@/views/people/users/user';
import users from '@/views/people/users/users';

export default {
  namespaced: true,
  modules: {
    admin,
    card,
    cards,
    dashboard,
    deskBooking,
    people,
    person,
    roomBooking,
    users,
    user
  }
};
