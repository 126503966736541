<template>
  <span class="person-info-name">
    <span class="person-info-name__title" v-if="showTitle">{{ title }}</span>
    <span class="person-info-name__space" v-if="showTitle && showDisplayName">&ensp;</span>
    <span class="person-info-name__displayName" v-if="showDisplayName">{{ displayName }}</span>
  </span>
</template>

<script>
export default {
  name: 'PersonInfoName',
  props: {
    person: {
      type: Object,
      default: null
    },
    hideTitle: Boolean,
    hideDisplayName: Boolean
  },
  computed: {
    title() {
      return this.person && this.person.title || '';
    },
    displayName() {
      const dn = this.person && this.person.displayName || '';
      if (this.showTitle) {
        return `(${dn})`;
      } else {
        return dn;
      }
    },
    showTitle() {
      return !this.hideTitle && this.title;
    },
    showDisplayName() {
      return !this.hideDisplayName && this.displayName || !this.showTitle;
    }
  }
};
</script>

<style scoped>
  .person-info-name {
    display: inline-flex;
  }

  .person-info-name__title {
    /* Remove space from the title before the display name */
    flex: 1 1 0;
    min-width: 5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .person-info-name__space {
    /* Hide the space if the title is fully hidden (due to space constraints) */
    flex: 0 1 0;
    overflow: hidden;
  }

  .person-info-name__displayName {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

</style>
