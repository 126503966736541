import {Logger} from '@vanti/vue-logger';
import {EmptyCollection} from '@/store/collection/empty-collection';
import {PersonCardHistoryCollection} from '@/views/people/person/person-card-history-collection';
import {db, decorateSnapshot} from '@/firebase';
import Vue from 'vue';
import {compareByPath} from '@/util/compare';

const log = Logger.get('person-card-history');

/**
 * @param {change} change
 * @return {Date}
 */
function changeTime(change) {
  return change.changeTime.toDate();
}

export default {
  namespaced: true,
  state: {
    /** @type {?DocumentCollection} */
    currentCollection: new EmptyCollection(),
    latestChanges: {},
    watchers: {}
  },
  getters: {
    /**
     * Combines records and latestChanges, removing duplicates
     *
     * @param {Object} state
     * @return {{}}
     */
    allRecords(state) {
      // use ID as the key to ensure there are no duplicates between the currentCollection and latestChanges snapshot
      const changes = {};
      // note id of the latestChanges includes the full document path
      // id in the object is just the document id
      for (const id of Object.keys(state.latestChanges)) {
        const change = state.latestChanges[id];
        changes[change.id] = change;
      }
      for (const r of state.currentCollection.records) {
        changes[r.id] = r;
      }
      return changes;
    },
    /**
     * The latest record; PersonAccessCardHistory component watches this and calls bindLatest when it changes
     *
     * @param {Object} state
     * @return {DecoratedData|undefined}
     */
    latestRecord(state) {
      return state.currentCollection.records[0];
    },
    /**
     * Do we expect there to be more records available to fetch (i.e. another page)?
     *
     * @param {Object} state
     * @return {boolean}
     */
    expectsMore(state) {
      return state.currentCollection.expectsMoreRecords;
    },
    /**
     * Map from the records to a more useful format for display.
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {[]}
     */
    assignments(state, getters) {
      const assignments = [];
      for (const id of Object.keys(getters.allRecords)) {
        const change = getters.allRecords[id].change;
        assignments.push({
          card: change.after,
          document: change.document,
          time: changeTime(change)
        });
      }
      return assignments.sort((a, b) => -compareByPath(a, b, 'time'));
    }
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {PeopleCollection} collection
     */
    setCurrentCollection(state, collection) {
      state.currentCollection = collection;
    },
    updateLatest(state, updates) {
      log.debug('updateLatest', updates);
      updates.forEach(update => {
        switch (update.type) {
          case 'added':
          case 'modified':
            Vue.set(state.latestChanges, update.doc.ref.path, decorateSnapshot(update.doc));
            break;
          case 'removed':
            Vue.delete(state.latestChanges, update.doc.ref.path);
            break;
        }
      });
    },
    /**
     * @param {State} state
     * @param {Object.<string,function>} watchers
     */
    registerWatchers(state, watchers) {
      state.watchers = {...state.watchers, ...watchers};
    },
    clear(state) {
      state.watchers = {};
      state.latestChanges = {};
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      handler({dispatch}, authUser) {
        if (!authUser) {
          dispatch('unbind');
        }
      }
    },
    async bind({commit, rootGetters}) {
      log.debug('bind');

      if (!rootGetters['views/person/personExists']) {
        commit('setCurrentCollection', new EmptyCollection());
        throw new Error('no person to bind card-history');
      }
      const ns = rootGetters.ns;
      const person = rootGetters['views/person/person'];
      commit('setCurrentCollection', new PersonCardHistoryCollection(ns, person.ref));
    },
    /**
     * Bind a snapshot of changes starting from the latest record in state.currentCollection.records
     *
     * @param {import('vuex').ActionContext} context
     * @return {Promise<void>}
     */
    async bindLatest({commit, dispatch, state, rootGetters}) {
      if (!rootGetters['views/person/personExists']) {
        throw new Error('no person to bind card-history');
      }
      const latest = state.currentCollection.records[0];
      if (!latest) {
        log.warn('bindLatest nothing to bind');
        return;
      }
      await dispatch('unbind');

      const ns = rootGetters.ns;
      const person = rootGetters['views/person/person'];
      const watchers = {};
      const firestore = await db;
      // watch for changes after the latest change we have
      watchers.latest = firestore.collection(`ns/${ns}/search/changes/records`)
          .where('document.path', 'array-contains', `ns/${ns}/cards`)
          .where('change.after.owner.ref', '==', person.ref)
          .orderBy('change.changeTime', 'desc')
          // endAt the DocumentSnapshot didn't appear to work, but the timestamp does.
          // Note this means this snapshot includes the latest document as a duplicate
          // this is handled in the getters. If we used endBefore we may miss a change.
          .endAt(latest.change.changeTime)
          .onSnapshot(
              querySnap => commit('updateLatest', querySnap.docChanges()),
              err => log.error('latest.onSnapshot', err));

      commit('registerWatchers', watchers);
    },
    unbind({state, commit}) {
      Object.values(state.watchers).forEach(watcher => watcher());
      commit('clear');
    },

    async loadNextPage({state}) {
      return state.currentCollection.loadNextPage();
    },

    async stop({state}) {
      return state.currentCollection.stop();
    }
  }
};
