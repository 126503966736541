<template>
  <v-container fluid fill-height>
    <v-row class="align-center justify-center">
      <v-col cols="6">
        <h1 class="primary--text text-h1 mb-4">Oops!</h1>
        <h5 class="text-h5">
          We can't seem to find the {{ text }} you were looking for.
        </h5>
        <h5 v-if="routeLabel && routeName" class="text-h5">
          Would you like to view <router-link :to="{name: routeName}">{{ routeLabel }}</router-link>?
        </h5>
        <router-link v-else :to="{name: 'loading'}">Click here to go home</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {
    routeName: {
      type: String,
      default: 'loading'
    },
    routeLabel: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: 'page'
    }
  }
};
</script>

<style scoped>
</style>
