/**
 * Returns the DocumentReference pointing to the current ns or null.
 *
 * @param {firebase.firestore.DocumentReference} ref
 * @return {firebase.firestore.DocumentReference}
 */
export function toNsRef(ref) {
  return closestChildOfCollection(ref, 'ns');
}

/**
 * Returns the closest parent DocumentReference who is a direct child of the named collectionId.
 *
 * @param {firebase.firestore.DocumentReference} ref
 * @param {string} collectionId
 * @return {firebase.firestore.DocumentReference}
 */
export function closestChildOfCollection(ref, collectionId) {
  while (ref && ref.parent && ref.parent.id !== collectionId) {
    ref = ref.parent.parent;
  }
  return ref;
}
