/**
 * Returns true if any of the values in anyOf are present in arr
 *
 * @param {[]} arr
 * @param {[]} anyOf
 * @return {boolean}
 */
export function arrayContainsAny(arr, anyOf) {
  for (const el of arr) {
    if (anyOf.includes(el)) {
      return true;
    }
  }
  return false;
}

/**
 * Rotate an array such that the first `count` items are at the end.
 *
 * @param {[]} arr
 * @param {number} count
 * @return {[]}
 */
export function arrayRotate(arr, count) {
  return [...arr.slice(count, arr.length), ...arr.slice(0, count)];
}
