import {groupBy} from 'lodash';

export default {
  namespaced: true,
  state: {
    headers: [
      {text: 'Name', value: 'ownerName', section: 'Person'},
      {text: 'Email', value: 'ownerEmail', section: 'Person'},
      {text: 'Division', value: 'departmentName', section: 'Person'},
      {text: 'Business Group', value: 'businessGroupName', section: 'Person'},
      {text: 'Business Unit', value: 'businessUnitName', section: 'Person'},
      {text: 'Site', value: 'siteName', section: 'Desk'},
      {text: 'Floor', value: 'floorName', section: 'Desk'},
      {text: 'Neighbourhood', value: 'neighbourhoodName', section: 'Desk'},
      {text: 'Desk', value: 'spaceName', section: 'Desk'},
      {text: 'From Date', value: 'fromTimeStringDate', section: 'Booking'},
      {text: 'From Time', value: 'fromTimeStringTime', section: 'Booking'},
      {text: 'To Date', value: 'toTimeStringDate', section: 'Booking'},
      {text: 'To Time', value: 'toTimeStringTime', section: 'Booking'},
      {text: 'Checked In', value: 'checkedInString', section: 'Booking'},
      {text: 'Checked Out', value: 'checkedOutString', section: 'Booking'},
      {text: 'Created Via', value: 'createdViaTitle', section: 'Booking'},
      {text: 'Tags', value: 'ownerTags', section: 'Person'}
    ],
    selectedHeaders: [],
    exportTz: 'Europe/London'
  },
  getters: {
    headersBySection: state => {
      return groupBy(state.headers, 'section');
    },
    exportTz: state => state.exportTz
  },
  mutations: {
    selectAllHeaders(state) {
      state.selectedHeaders = state.headers;
    },
    updateSelectedHeaders(state, payload) {
      state.selectedHeaders = payload;
    },
    updateExportTz(state, payload) {
      state.exportTz = payload;
    }
  },
  actions: {}
};
