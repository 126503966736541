<template>
  <component :is="to ? 'router-link' : 'div'" v-bind="bind" class="user-list-row">
    <div class="td" :style="styleFor('title')" v-if="containsHeader('title')">
      <span>{{ title }}</span>
    </div>
    <div class="td" :style="styleFor('email')" v-if="containsHeader('email')">
      <span class="email">{{ user.email }}</span>
    </div>
    <div class="td" :style="styleFor('lastSignInTime')" v-if="containsHeader('lastSignInTime')">
      <!-- todo: display this correctly-->
    </div>
    <div class="td" :style="styleFor('peopleRef')" v-if="containsHeader('peopleRef')">
      <v-btn icon v-if="user.person && user.person.ref" :to="{name: 'person', params: {id: user.person.ref.id}}">
        <v-icon>mdi-account-check</v-icon>
      </v-btn>
    </div>
  </component>
</template>

<script>
import PersonInfoPosition from '@/components/person/PersonInfoPosition';
import PersonInfoTags from '@/components/person/PersonInfoTags';
import PhoneNumber from '@/components/PhoneNumber';

export default {
  name: 'UserTableRow',
  components: {PersonInfoTags, PersonInfoPosition, PhoneNumber},
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    to: {
      type: [Object, String],
      default: null
    },
    excludeTags: {
      type: [Function, Array],
      default: undefined
    }
  },
  computed: {
    bind() {
      const bindings = {};
      if (this.to) {
        bindings.to = this.to;
      }
      return bindings;
    },
    title() {
      if (!this.user) {
        return '';
      }
      if (this.user.title) {
        return this.user.title;
      }
      if (this.user.person && this.user.person.title) {
        return this.user.person.title;
      }
      return '';
    }
  },
  methods: {
    choosePhoneNumber(phones) {
      if (!phones || phones.length === 0) {
        return '';
      }

      const priority = ['Ext', 'Work', 'Work Mobile'];
      for (let i = 0; i < priority.length; i++) {
        const found = phones.find(p => p.type === priority[i]);
        if (found) {
          return found.number;
        }
      }
      return phones[0].number;
    },
    containsHeader(value) {
      return !!this.headers.find(h => h.value === value);
    },
    styleFor(value) {
      const h = this.headers.find(h => h.value === value);
      if (!h) {
        return {};
      }
      return {
        width: h.width
      };
    }
  }
};
</script>

<style scoped>
  .user-list-row {
    display: flex;
  }

  .td {
    font-size: 0.875rem;
    height: 52px;
    padding: 0 16px;
  }

  [href] {
    text-decoration: none;
    color: var(--text);
  }

  .user-list-row .td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
