/**
 * @typedef {Object} DeskParts
 * @property {string} [id]
 * @property {SVGElement} [el]
 * @property {SVGRect} [bounds]
 * @property {SVGElement} [table]
 * @property {SVGRect} [tableBounds]
 * @property {SVGElement} [tableFill]
 * @property {SVGElement} [tableBorder]
 * @property {SVGElement} [chair]
 * @property {SVGElement} [label]
 */

/**
 * Find the id of the desk based on a desk element.
 *
 * @param {SVGElement} el
 * @return {string}
 */
export function getDeskId(el) {
  const id = el.id;
  if (id.startsWith('bookable_')) {
    return id.substr('bookable_'.length);
  } else {
    return id;
  }
}

/**
 * Convert an SVGRect into an object containing the same properties. This is useful for debugging in vue dev tools as
 * an SVGRect doesn't show the content.
 *
 * @param {SVGRect} rect
 * @return {SVGRect}
 */
function rectToObject(rect) {
  return /** @type {SVGRect} */ {
    x: rect.x,
    y: rect.y,
    width: rect.width,
    height: rect.height,
    get top() {
      return this.height < 0 ? this.y + this.height : this.y;
    },
    get bottom() {
      return this.height < 0 ? this.y : this.y + this.height;
    },
    get left() {
      return this.width < 0 ? this.x + this.width : this.x;
    },
    get right() {
      return this.width < 0 ? this.x : this.x + this.width;
    }
  };
}

/**
 * Scales the given rectangle
 *
 * @param {SVGRect} rect
 * @param {number} scale
 * @return {SVGRect}
 */
function scaleRect(rect, scale) {
  return rectToObject({
    x: rect.x * scale,
    y: rect.y * scale,
    width: rect.width * scale,
    height: rect.height * scale
  });
}

/**
 * Scan the desk svg element to extract the parts.
 *
 * @param {SVGElement} deskEl
 * @param {number} [scale]
 * @return {DeskParts}
 */
export function scanDesk(deskEl, scale = 1) {
  /** @type {DeskParts} */
  const parts = {
    id: getDeskId(deskEl),
    el: deskEl
  };

  if (deskEl) {
    // preferred method - explicitly name table & chair
    parts.tableFill = deskEl.querySelector('[id^=table]');
    if (parts.tableFill && parts.tableFill.tagName === 'g') {
      // e.g.
      // <g id="bookable_id">
      //   <g id="table_*">
      //     <rect/>           <!-- this is the table -->
      //     <path/>           <!-- this is the table border -->
      //   </g>
      //   <g id="chair_*">
      //     <rect/>           <!-- this is the chair -->
      //   </g>
      //   <text>D1.14</text>
      // </g>

      const tableGroup = parts.tableFill;
      parts.tableFill = tableGroup.querySelector('rect');
      parts.tableBorder = tableGroup.querySelector('path');

      parts.chair = deskEl.querySelector('[id^=chair]');
      if (parts.chair && parts.chair.tagName === 'g') {
        parts.chair = parts.chair.querySelector('rect');
      }
    } else if (parts.tableFill) {
      // e.g.
      // <g id="bookable_id">
      //   <path id="table*"/>  <!-- this is the table -->
      //   <path/>              <!-- this is the table border -->
      //   <path id="chair*"/>  <!-- this is the chair -->
      //   <text>D1.14</text>
      // </g>
      parts.chair = deskEl.querySelector('[id^=chair]');
      parts.tableBorder = deskEl.querySelector('path:not([id])');
    } else if (deskEl.querySelector('g')) {
      // if it has a group in - assume this is the table
      parts.table = deskEl.querySelector('g:nth-child(1)');
      parts.tableFill = parts.table.querySelector('rect');
      parts.tableBorder = parts.table.querySelector('path');
      parts.chair = deskEl.querySelector('g:nth-child(2) > rect');
    } else if (deskEl.querySelector('rect') !== null) {
      // if it has a rect in - assume the first one is the chair, 2nd is tableFill, and maybe a
      // path that's the border
      parts.chair = deskEl.querySelector('rect:nth-of-type(1)');
      parts.tableFill = deskEl.querySelector('rect:nth-of-type(2)');
      parts.tableBorder = deskEl.querySelector('path:nth-of-type(1)');
    } else {
      // otherwise - assume there's some paths as above
      parts.chair = deskEl.querySelector('path:nth-of-type(1)');
      parts.tableFill = deskEl.querySelector('path:nth-of-type(2)');
      parts.tableBorder = deskEl.querySelector('path:nth-of-type(3)');
    }
    parts.label = deskEl.querySelector('text');
  }

  if (parts.el) parts.bounds = scaleRect(parts.el.getBBox(), scale);
  if (parts.table) {
    parts.tableBounds = scaleRect(parts.table.getBBox(), scale);
  } else if (parts.tableBorder) {
    parts.tableBounds = scaleRect(parts.tableBorder.getBBox(), scale);
  } else if (parts.tableFill) {
    parts.tableBounds = scaleRect(parts.tableFill.getBBox(), scale);
  }

  return parts;
}

/**
 * Clean up the desk parts removing any extra styling and applying classes.
 *
 * @param {DeskParts} deskParts
 */
export function cleanDeskMarkup(deskParts) {
  if (deskParts.tableBorder) {
    deskParts.tableBorder.style.fill = null;
    deskParts.tableBorder.removeAttribute('fill');
    deskParts.tableBorder.classList.add('tableBorder');
  }
  if (deskParts.tableFill) {
    deskParts.tableFill.style.fill = null;
    deskParts.tableFill.style.opacity = null;
    deskParts.tableFill.removeAttribute('fill');
    deskParts.tableFill.removeAttribute('opacity');
    deskParts.tableFill.classList.add('tableFill');
  }
  if (deskParts.chair) {
    deskParts.chair.style.fill = null;
    deskParts.chair.removeAttribute('fill');
    deskParts.chair.classList.add('chair');
  }
}

/**
 * Find the center of the desk.
 *
 * @param {DeskParts} deskParts
 * @return {{x: number, y: number}|null}
 */
export function deskCenter(deskParts) {
  if (!deskParts) return null;
  return rectToCenter(deskParts.tableBounds || deskParts.bounds);
}

/**
 * Return the center of a rectangle
 *
 * @param {SVGRect} rect
 * @return {{x: number, y: number}}
 */
function rectToCenter(rect) {
  return {
    x: rect.x + rect.width / 2,
    y: rect.y + rect.height / 2
  };
}
