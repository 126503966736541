import {decorateReference} from '@/firebase';

/**
 * Helpers for interacting with a single bookable.
 */
export class Bookable {
  #context;
  key;

  /**
   * @param {{state:Object,getters:Object}} context
   * @param {string} key
   */
  constructor(context, key) {
    this.#context = context;
    this.key = key;
  }

  /**
   * @return {DecoratedData|undefined}
   */
  get space() {
    return this.#context.state.bookables.byRef[this.key];
  }

  /**
   * @return {string|undefined}
   */
  get id() {
    return this.space && this.space.id;
  }

  /**
   * @return {firebase.firestore.DocumentReference|undefined}
   */
  get ref() {
    return this.space && this.space.ref;
  }

  /**
   * @return {string}
   */
  get spaceKindId() {
    if (!this.space || !this.space.kind) return '';
    return this.space.kind.ref.id;
  }

  /**
   * @return {string}
   */
  get typeIcon() {
    switch (this.spaceKindId) {
      case 'desk':
        return 'mdi-desk';
      case 'room':
        return 'mdi-door-open';
      default:
        return '';
    }
  }

  /**
   * @return {string}
   */
  get typeText() {
    switch (this.spaceKindId) {
      case 'desk':
        return 'Desk';
      case 'room':
        return 'Meeting Room';
      default:
        return '';
    }
  }

  /**
   * @return {string}
   */
  get title() {
    if (!this.space) return '';
    return this.space.title || this.space.id;
  }

  /**
   * @return {boolean}
   */
  get disabled() {
    if (!this.space) {
      return false;
    }
    const disabled = this.space.disabled;
    if (disabled === null || typeof disabled === 'undefined') {
      return false;
    }
    // special case, if the reason is '' then assume no reason at all
    if (typeof disabled === 'string' && disabled.length === 0) {
      return true;
    }
    return disabled;
  }

  /**
   * @return {boolean}
   */
  get active() {
    if (!this.space) {
      return false;
    }
    return this.#context.getters['selection/isSelectedId'](this.space.id);
  }

  /**
   * @return {null|DecoratedData[]}
   */
  get activeBooking() {
    if (!this.space) {
      return null;
    }
    return this.#context.getters['bookings/activeBookings'].find(b => this.space.ref.isEqual(b.space.ref));
  }

  /**
   * @return {boolean}
   */
  get booked() {
    return Boolean(this.activeBooking);
  }

  /**
   * @return {boolean}
   */
  get userCanBook() {
    return this.#context.getters['user/canBookDesks'];
  }

  /**
   * @return {DecoratedData}
   */
  get neighbourhood() {
    return decorateReference(this.space.neighbourhood);
  }

  /**
   * Is the current user in the same neighbourhood as this bookable?
   *
   * @return {boolean}
   */
  get inUsersNeighbourhood() {
    const userNeighbourhood = this.#context.getters['user/neighbourhood'];
    // if the user doesn't have a neighbourhood, then we say everything is in it
    if (!userNeighbourhood) return true;
    const neighbourhood = this.neighbourhood;
    if (!neighbourhood) return false;

    return userNeighbourhood.ref.isEqual(neighbourhood.ref);
  }
}
