<template>
  <div class="mb-4">
    <div class="neighbourhood-title">
      <v-icon left :color="neighbourhood.color">
        mdi-checkbox-blank-circle
      </v-icon>
      <b>{{ neighbourhood.title }}</b>
    </div>
    <div class="subtitle">
      <div v-if="propertyEdits">
        <div v-for="[variable, val] of Object.entries(propertyEdits)" :key="variable">
          <span><b>{{ variable.charAt(0).toUpperCase() + variable.slice(1) }}</b>:
            <v-icon v-if="variable === 'color'" :color="val">mdi-checkbox-blank-circle</v-icon>
            {{ val }}
          </span>
        </div>
      </div>
      <div v-if="addedDesksList">
        <b>Desks Added</b>
        <p>{{ addedDesksList }}</p>
      </div>
      <div v-if="removedDesksList">
        <b>Desks Removed</b>
        <p>{{ removedDesksList }}</p>
      </div>
      <div v-if="neighbourhood.deleted">
        <b>Neighbourhood Deleted</b>
      </div>
      <div v-if="neighbourhood.added">
        <b>Neighbourhood Created</b>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'NeighbourhoodChanges',
  props: {
    neighbourhood: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['editsByNeighbourhoodId', 'deletions']),
    propertyEdits() {
      return this.editsByNeighbourhoodId(this.neighbourhood.id);
    },
    addedDesksList() {
      if (!this.neighbourhood.desks.addedDesks) return null;
      return this.neighbourhood.desks.addedDesks.map(d => d.title).join(', ');
    },
    removedDesksList() {
      if (!this.neighbourhood.desks.removedDesks) return null;
      return this.neighbourhood.desks.removedDesks.map(d => d.title).join(', ');
    }
  }
};
</script>

<style scoped>
  .subtitle {
    line-height: 1.3;
    font-size: 0.875rem;
    color: rgba(0,0,0,0.6);
  }
  .neighbourhood-title {
    line-height: 1.3;
    font-size: 1rem;
  }
</style>
