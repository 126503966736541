import {dbUtil} from '@/firebase';
import {Logger} from '@vanti/vue-logger';
import DeferUtil from './defer-util';

const log = Logger.get('versions');

const appName = 'workplace-app';

export default {
  namespaced: true,
  state: {
    VERSION: process.env.VUE_APP_VERSION || 'local',
    COMMITHASH: process.env.VUE_APP_COMMITHASH || '',
    l: {},
    remoteVersion: {}
  },
  getters: {
    upToDate(state, getters) {
      if (process.env.NODE_ENV === 'development') {
        return true;
      }
      if (!state.remoteVersion || !state.remoteVersion.gitVersion) {
        return true;
      }
      return getters.localVersionName === state.remoteVersion.gitVersion ||
          state.VERSION === state.remoteVersion.gitVersion;
    },
    versionName(state, getters) {
      if (getters.upToDate) {
        if (state.remoteVersion) {
          if (state.remoteVersion.displayName) {
            return state.remoteVersion.displayName;
          }
          if (state.remoteVersion.title) {
            return state.remoteVersion.title;
          }
        }
      }
      return getters.localVersionName;
    },
    localVersionName(state) {
      const slashIndex = state.VERSION.lastIndexOf('/');
      return state.VERSION.substr(slashIndex + 1);
    },
    appName() {
      return appName;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setRemoteVersion(state, remoteVersion) {
      state.remoteVersion = remoteVersion;
    }
  },
  actions: {
    async bind({commit, dispatch, state}) {
      const versionDoc = await dbUtil.doc(`versions/${appName}`);
      return new Promise((resolve, reject) => {
        const unbindVersionDoc = versionDoc.onSnapshot(
            doc => {
              const hasRemoteVersion = Boolean(state.remoteVersion);
              commit('setRemoteVersion', doc.data());

              // if this isn't the first time we've seen the remote version (i.e. we assume it's an update)
              if (hasRemoteVersion) {
                if (this.hasModule('serviceWorker')) {
                  dispatch('serviceWorker/update', null, {root: true})
                      .catch(err => log.error('during service worker update after version change', err));
                }
              }
            },
            error => reject(error));

        commit('defer', {versionDoc: unbindVersionDoc});
      });
    },

    unbind({commit}) {
      commit('reset');
    },

    onAuthStateChanged: {
      root: true,
      async handler({dispatch}, authUser) {
        try {
          if (authUser) await dispatch('bind');
          else await dispatch('unbind');
        } catch (e) {
          log.error('bind after authStateChange', e);
        }
      }
    }
  }
};
