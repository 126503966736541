<template>
  <v-data-table
      :headers="selectedHeaders"
      :items="items"
      :footer-props="footerProps"
      :items-per-page="15"
      :loading="loadingInfo.loading"
      :loading-text="loadingInfo.text"
      class="bookings-list v-data-table theme--light elevation-1">
    <template #no-data>
      <v-card flat>
        <v-alert tile text class="ma-0 text-center" type="info">
          {{ noDataText }}
        </v-alert>
      </v-card>
    </template>
  </v-data-table>
</template>

<script>
// force webpack to include data table styles we need, even though we aren't using it directly
import 'vuetify/src/components/VDataTable/VSimpleTable.sass';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'BookingsListTable',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    loadingInfo: {
      type: Object,
      default: () => {}
    },
    noDataText: {
      type: String,
      default: ''
    },
    tz: {
      type: String,
      default: 'UTC'
    }
  },
  data() {
    return {
      width: null,
      footerProps: {
        'items-per-page-options': [10, 50, 100, 200, 500],
        'show-first-last-page': true
      }
    };
  },
  computed: {
    ...mapState('views/deskBooking/exportBookings', ['selectedHeaders'])
  },
  created() {
    this.selectAllHeaders();
  },
  methods: {
    ...mapMutations('views/deskBooking/exportBookings', ['selectAllHeaders'])
  }
};
</script>

<style scoped>
.v-data-table .table-header >>> th {
  color: var(--text);
}

.tbody {
  width: 100%;
}

.bookings-list >>> th {
  height: 56px;
  font-size: 14px;
  font-weight: normal;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.6);
}

.bookings-list >>> thead tr:last-child th {
  border-bottom: solid var(--v-grey-base) 1px;
}

.bookings-list >>> .hover {
  background: #eee;
}
</style>
