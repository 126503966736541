import interact from 'interactjs'

const getNum = (target, attr) => parseFloat(target.getAttribute(attr)) || 0;

export default {
  props: {
    inertia: Boolean,
    grid: {
      type: [Array, Number],
      validator: (value) => (value.length === 2 && value.every(v => typeof v === 'number')) || (typeof (value) === 'number')
    },
    minSize: {
      type: [Array, Number],
      default: () => 10,
      validator: (value) => (value.length === 2 && value.every(v => typeof v === 'number')) || (typeof (value) === 'number')
    },
    multipleSelect: Boolean
  },

  methods: {
    /** @func fixDrawingMode is hard fix, need to think another solution */
    makeInteractable (node, fixDrawingMode = false) {
      const master = this.$refs.svg

      const interaction = interact(node)
        .draggable({
          inertia: this.inertia,
          snap: { targets: [this.gridTarget] },

          // keep the element within the area of it's parent
          restrict: {
            restriction: undefined,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
          },
          autoScroll: true,

          onend: event => this.$emit('move-end', event.target),

          // call this function on every dragmove event
          onmove: event => {
            const target = event.target;
            target.setAttribute('cx', getNum(target, 'cx') + event.dx);
            target.setAttribute('cy', getNum(target, 'cy') + event.dy);
            this.$emit('move', target);
          }
        })

        .resizable({
          inertia: this.inertia,
          snap: { targets: [this.gridTarget] },
          // // resize from all edges and corners
          edges: { left: true, right: true, bottom: true, top: true },

          // // keep the edges inside the parent
          restrictEdges: fixDrawingMode ? undefined : {
            outer: 'svg',
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
          },
          autoScroll: true,

          // // minimum size
          restrictSize: fixDrawingMode ? undefined : {
            min: { width: this.minWidth, height: this.minHeight }
          },

          onend: event => this.$emit('resize-end', event.target),

          // called on every resizemove event
          onmove: (event) => {
            const target = event.target;

            let x = getNum(target, 'x') + event.deltaRect.left
            let y = getNum(target, 'y') + event.deltaRect.top
            switch (target.nodeName) {
              case 'circle':
                const antiPhytagoras = (x, y) => x !== 0 && y !== 0 && Math.sign(x) !== Math.sign(y) ? 0 : Math.sign(x + y) * Math.hypot(event.deltaRect.width, event.deltaRect.height)
                const delta = antiPhytagoras(event.deltaRect.width, event.deltaRect.height)
                const d = s => delta !== 0 ? (s / 2) : 0
                const threshold = (this.minWidth + this.minHeight) / 2 // because deltaRect will keep going when resizing in diagonal

                // BUG: still have slight move when resizing diagonally
                if (event.rect.width > threshold && event.rect.height > threshold) {
                  x += d(event.deltaRect.width)
                  y += d(event.deltaRect.height)
                } else {
                  x = getNum(target, 'x')
                  y = getNum(target, 'y')
                }

                let diameter = getNum(target, 'r') * 2 + delta
                diameter = Math.max(diameter, threshold)

                // Enable resizing when circle in edge of canvas
                const notEdgeTopLeft = x + Math.abs(event.deltaRect.width) >= 0 && y + Math.abs(event.deltaRect.height) >= 0
                const notEdgeBottomRight = (x + diameter) <= getNum(master, 'width') + Math.abs(event.deltaRect.width) && (y + diameter) <= getNum(master, 'height') + Math.abs(event.deltaRect.height)

                if (notEdgeBottomRight && notEdgeTopLeft) {
                  target.setAttribute('r', diameter / 2);
                }
                break
            }

            this.$emit('resize', target)
          }
        })

      return interaction
    },

    enableInteraction (enabled = true) {
      if (this.$refs.annotations.hasChildNodes()) {
        this.$refs.annotations.childNodes.forEach((node, id) => {
          if (!enabled) {
            interact(node).draggable(false).resizable(false)
          } else this.makeInteractable(node)
        })
      }
    }
  },

  mounted () {
    this.$on('draw-end', annotator => this.makeInteractable(annotator, false))
  },

  beforeDestroy () {
    this.$off('draw-end')
  },

  computed: {
    gridTarget: function () {
      return this.grid ? interact.createSnapGrid({
        x: this.grid[0],
        y: this.grid[1]
      }) : null
    },
    minWidth: function () {
      return typeof (this.minSize) === 'number' ? this.minSize : this.minSize[0]
    },
    minHeight: function () {
      return typeof (this.minSize) === 'number' ? this.minSize : this.minSize[1]
    }
  }
}
