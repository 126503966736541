import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';

const log = Logger.get('app');

export default {
  namespaced: true,
  state: {
    loaded: {
      auth: false
    }
  },
  getters: {
    loaded(state) {
      const loading = Object.entries(state.loaded)
          .filter(([, loaded]) => !loaded)
          .map(([name]) => name);
      if (loading.length > 0) {
        log.debug(loading + ' not loaded');
        return false;
      } else {
        return true;
      }
    }
  },
  mutations: {
    clear(state, name) {
      Vue.delete(state.loaded, name);
    },
    loading(state, name) {
      Vue.set(state.loaded, name, false);
    },
    loaded(state, name) {
      Vue.set(state.loaded, name, true);
    }
  }
};
