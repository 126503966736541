<template>
  <component :is="to ? 'router-link' : 'div'" v-bind="bind" class="person-list-row">
    <div class="td" :style="styleFor('title')" v-if="containsHeader('title')">
      <person-info-name :person="person" hide-display-name/>
    </div>
    <div class="td" :style="styleFor('phones')" v-if="containsHeader('phones')">
      <phone-number v-if="person.phones && person.phones.length > 0" :value="choosePhoneNumber(person.phones)"/>
    </div>
    <div class="td" :style="styleFor('email')" v-if="containsHeader('email')">
      <span class="email">{{ person.email }}</span>
    </div>
    <div class="td" :style="styleFor('position')" v-if="containsHeader('position')">
      <person-info-position :person="person"/>
    </div>
    <div class="td" :style="styleFor('tags')" v-if="containsHeader('tags')">
      <person-info-tags :person="person" :exclude="excludeTags"/>
    </div>
    <div class="td" :style="styleFor('edit')" v-if="containsHeader('edit')">
      <v-btn icon @click.prevent :to="{name: 'person-edit', params: {id: person.id}}">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div class="td" :style="styleFor('$slot')" v-if="$slots.default && containsHeader('$slot')">
      <slot/>
    </div>
  </component>
</template>

<script>
import PersonInfoName from '@/components/person/PersonInfoName';
import PersonInfoPosition from '@/components/person/PersonInfoPosition';
import PersonInfoTags from '@/components/person/PersonInfoTags';
import PhoneNumber from '@/components/PhoneNumber';

export default {
  name: 'PersonListRow',
  components: {PersonInfoTags, PersonInfoPosition, PhoneNumber, PersonInfoName},
  props: {
    person: {
      type: Object,
      default() {
        return {};
      }
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    to: {
      type: [Object, String],
      default: null
    },
    excludeTags: {
      type: [Function, Array],
      default: undefined
    }
  },
  computed: {
    bind() {
      const bindings = {};
      if (this.to) {
        bindings.to = this.to;
      }
      return bindings;
    }
  },
  methods: {
    choosePhoneNumber(phones) {
      if (!phones || phones.length === 0) {
        return '';
      }

      const priority = ['Ext', 'Work', 'Work Mobile'];
      for (let i = 0; i < priority.length; i++) {
        const found = phones.find(p => p.type === priority[i]);
        if (found) {
          return found.number;
        }
      }
      return phones[0].number;
    },
    containsHeader(value) {
      return !!this.headers.find(h => h.value === value);
    },
    styleFor(value) {
      const h = this.headers.find(h => h.value === value);
      if (!h) {
        return {};
      }
      return {
        width: h.width
      };
    }
  }
};
</script>

<style scoped>
  .person-list-row {
    display: flex;
  }

  .td {
    font-size: 0.875rem;
    height: 52px;
    padding: 0 16px;
  }

  [href] {
    text-decoration: none;
    color: var(--text);
  }

  .person-list-row .td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
