<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="justify-center">
          <v-card max-width="600" outlined>
            <v-card-title class="text-center text-break">
              Sorry! You do not have access to the Kahu Workplace app.
            </v-card-title>
            <v-card-subtitle class="text-center">
              Please contact your administrator if you require access.
            </v-card-subtitle>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoAccess'
};
</script>

<style scoped>

</style>
