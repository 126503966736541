<template>
  <v-switch
      @click.stop
      v-model="selected"
      @change="saveSiteCategories"
      :label="label"
      hide-details
      dense
      :prepend-icon="showIcon ? 'mdi-domain' : ''"/>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'EditGroupSitesByCountry',
  props: {
    label: {
      type: String,
      default: 'Group site list by country'
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      internalValue: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters('user/profile', ['personGroupSitesByCountry']),
    selected: {
      get() {
        if (this.internalValue) {
          return this.internalValue;
        }
        return this.personGroupSitesByCountry;
      },
      set(v) {
        this.internalValue = v;
      }
    }
  },
  methods: {
    ...mapActions('user/profile', ['setGroupSitesByCountry']),
    async saveSiteCategories() {
      this.loading = true;
      try {
        await this.setGroupSitesByCountry(this.internalValue);
        this.internalValue = null;
        this.$notify.showSuccess(`Site list updated to ${this.selected ? 'group by country' : 'default'}`);
      } catch (e) {
        this.$logger.error('saveSiteCategories', e);
        this.$notify.showError(`Couldn't update your site list preferences`);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
