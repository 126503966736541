<template>
  <router-link :to="link">{{ person | personsName }}</router-link>
</template>

<script>
export default {
  name: 'PersonLink',
  filters: {
    personsName(person) {
      if (!person) {
        return '';
      }
      if (person.displayName) {
        return person.displayName;
      }
      if (person.title) {
        return person.title;
      }
      return person.ref.id;
    }
  },
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      if (!this.person) {
        return {};
      }
      return {name: 'person', params: {id: this.person.ref.id}};
    }
  }
};
</script>

<style scoped>

</style>
