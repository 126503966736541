<template>
  <div class="heatmap-tab fill-height d-flex flex-column">
    <v-toolbar
        class="heatmap-tab__toolbar"
        :class="{vertical: mobile || tablet}"
        color="transparent"
        flat
        absolute>
      <choice-btn
          v-if="siteFloors.length > 1"
          icon="mdi-layers"
          label="Floor"
          :items="siteFloors"
          :value="activeFloor"
          @input="chooseFloor"
          :icon-only="mobile || tablet"/>
    </v-toolbar>
    <v-fade-transition>
      <v-progress-linear
          v-if="!activeFloor || !activeFloor.loaded"
          indeterminate
          color="accent"
          style="position: absolute; width: 100%"/>
    </v-fade-transition>
    <v-scale-transition origin="center center">
      <pinch-zoom
          v-if="activeFloor"
          :class="{hidden: !activeFloor.loaded}"
          class="flex-grow-1 overflow-hidden pa-4 heatmap-tab__pinch-zoom"
          ref="pinchZoom"
          center
          v-resize-observer="updateMapSize"
          @start="clearFocusOn">
        <template #controls="{home, isHome}">
          <div class="heatmap-tab__controls">
            <div class="heatmap-tab__legend">
              <div class="heatmap-tab__legend--min-value mr-2">0%</div>
              <div class="heatmap-tab__legend--grid"/>
              <div class="heatmap-tab__legend--max-value ml-2">100%</div>
            </div>
            <v-btn @click="home" icon elevation="1" class="white">
              <v-fade-transition>
                <v-icon v-if="isHome">mdi-image-filter-center-focus</v-icon>
                <v-icon v-else>mdi-image-filter-center-focus-weak</v-icon>
              </v-fade-transition>
            </v-btn>
          </div>
        </template>
        <template #default>
          <floor-plan :floor="activeFloor" :loading.sync="floorLoading" grayscale class="heatmap">
            <template #desks>
              <desk-heatmap
                  :desks="bookableDesks"
                  :utilisation="meanReservedUtilisation"
                  :floor="activeFloor"/>
            </template>
          </floor-plan>
        </template>
      </pinch-zoom>
    </v-scale-transition>
  </div>
</template>

<script>
import ChoiceBtn from '@/components/ChoiceBtn';
import PinchZoom from '@/components/PinchZoom';
import FloorPlan from '@/views/desk-booking/settings/map/FloorPlan';
import ResizeObserver from '@/plugins/resize-observer';
import DeskHeatmap from '@/views/dashboard/statistics/heatmap/DeskHeatmap';
import breakpoints from '@/plugins/breakpoints';
import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: 'HeatmapTab',
  components: {PinchZoom, FloorPlan, ChoiceBtn, DeskHeatmap},
  directives: {ResizeObserver},
  mixins: [breakpoints],
  data() {
    return {
      mapSize: null,
      floorLoading: false
    };
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/map', ['activeFloor']),
    ...mapGetters('views/dashboard/statistics/desks/heatmap', [
      'meanReservedUtilisation'
    ]),
    ...mapGetters('floors', ['siteFloors']),
    ...mapGetters('sites', ['activeSiteDoc']),

    bookableDesks() {
      return this.activeFloor.bookableDesks;
    }
  },
  watch: {
    activeFloor: {
      immediate: true,
      handler(f) {
        if (f) {
          f.bind().catch(err => this.$logger.error('activeFloor changed', err));
        }
      }
    }
  },
  mounted() {
    this.$emit('bind');
    this.bind()
        .catch(err => this.$logger.error('mounted.bind', err));
  },
  beforeDestroy() {
    this.$emit('unbind');
    this.unbind()
        .catch(err => this.$logger.error('beforeDestroy.unbind', err));
  },
  methods: {
    ...mapMutations('views/deskBooking/settings/map', ['chooseFloorForSite']),
    ...mapActions('views/deskBooking/settings/map', ['bind', 'unbind']),

    chooseFloor(floor) {
      if (!this.activeSiteDoc) return;
      this.chooseFloorForSite({floor, site: this.activeSiteDoc});
      this.clearFocusOn();
      if (this.$refs.pinchZoom) {
        this.$refs.pinchZoom.reset();
      }
    },
    updateMapSize(sizes) {
      this.mapSize = sizes[sizes.length - 1].contentRect;
    },
    clearFocusOn() {
      if (!this.activeFloor) return;
      this.activeFloor.clearFocus();
    }
  }
};
</script>

<style lang="scss" scoped>
.heatmap-tab {
  position: relative;

  &__pinch-zoom {
    height: 90vh;
  }

  &__toolbar {
    position: absolute;
    right: 20px;
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 440px;

    &--grid {
      width: 100%;
      height: 25px;
      background: linear-gradient(90deg,#6AD8FC 11%,
      #9AD6BC 22%, #BCD590 33%,
      #DCD465 44%, #FBD33C 55%,
      #FCB541 66%, #FD9645 77%,
      #FE794A 88%, #FF5352 100%);
    }
  }
}

::v-deep .controls {
  z-index: $zindex-map-controls;
}
</style>
