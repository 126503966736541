<template>
  <div>
    <v-toolbar flat>
      <h2>Selected Desks</h2>
      <v-spacer/>
      <v-tooltip v-if="anySelected" bottom>
        <template #activator="{on}">
          <v-btn @click="clearAll" v-on="on" icon class="white mr-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Clear Selection</span>
      </v-tooltip>
    </v-toolbar>

    <v-alert
        v-if="!isAllowedToEditDeskSettings || !isAllowedToEditNeighbourhood"
        type="warning"
        prominent
        text>
      <div v-if="!isAllowedToEditDeskSettings">You don't have permission to edit desk settings</div>
      <div v-if="!isAllowedToEditNeighbourhood">You don't have permission to edit neighbourhoods</div>
    </v-alert>

    <div style="width: 100%;" class="pa-4">
      <span>{{ deskList }}</span>
    </div>

    <div class="d-flex flex-fill px-4 pt-4">
      <v-subheader class="pa-0">Actions</v-subheader>
    </div>
    <v-list>
      <v-list-item @click="createDialog = !createDialog" :disabled="!isAllowedToEditNeighbourhood">
        <v-icon left>mdi-home-plus</v-icon>
        Create neighbourhood from selection
      </v-list-item>
      <v-divider/>
      <v-list-item
          @click="neighbourhoodSelectOpen = !neighbourhoodSelectOpen"
          :disabled="!isAllowedToEditNeighbourhood">
        <v-icon left>mdi-home-group</v-icon>
        Update desk neighbourhoods
      </v-list-item>

      <v-expand-transition>
        <template v-if="neighbourhoodSelectOpen">
          <v-select
              filled
              dense
              placeholder="No neighbourhood"
              :items="allNeighbourhoods"
              v-model="selectedNeighbourhood"
              @change="updateMultipleNeighbourhoods"
              item-text="title"
              item-value="ref">
            <template #item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <span>{{ item.title }}</span>
                      <v-spacer/>
                      <v-chip
                          text-color="white"
                          :color="item.color"
                          small/>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </template>
      </v-expand-transition>
      <div v-if="anyDeskDisabled">
        <v-divider/>
        <v-list-item @click="updateDisabled(false)" :disabled="!isAllowedToEditDeskSettings">
          <v-icon left>mdi-toggle-switch</v-icon>
          Enable bookings for these desks
        </v-list-item>
      </div>
      <div v-if="anyDeskEnabled">
        <v-divider/>
        <v-list-item @click="updateDisabled(true)" :disabled="!isAllowedToEditDeskSettings">
          <v-icon left>mdi-toggle-switch-off</v-icon>
          Disable bookings for these desks
        </v-list-item>
      </div>
    </v-list>


    <v-dialog
        width="500"
        v-model="createDialog">
      <create-neighbourhood-dialog @close-dialog="createDialog = false"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import CreateNeighbourhoodDialog from '@/views/desk-booking/settings/sidebar/dialogs/CreateNeighbourhoodDialog';
import firebase from 'firebase/app';

export default {
  name: 'MultiDesk',
  components: {CreateNeighbourhoodDialog},
  data() {
    return {
      neighbourhoodSelectOpen: false,
      createDialog: false,
      neighbourhood: null
    };
  },
  computed: {
    ...mapGetters('views/deskBooking/settings/desks', ['deskById']),
    ...mapGetters('selection', ['anySelected', 'maxSelected']),
    ...mapState('selection', ['selected']),
    ...mapGetters('selection', ['lastSelected']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['allNeighbourhoodsSorted']),
    ...mapGetters('auth', ['hasAccessTo']),
    ...mapGetters('sites/active', ['maskedFloorName']),

    selectedNeighbourhood: {
      get() {
        if (this.neighbourhood) return this.neighbourhood;
        if (!this.lastSelected || !this.lastSelected.neighbourhood) return null;
        const currNeighbourhoodRef = this.lastSelected.neighbourhood;
        return this.allNeighbourhoodsSorted.find(n => n.id === currNeighbourhoodRef.id);
      },
      set(v) {
        this.neighbourhood = v;
      }
    },
    anyDeskDisabled() {
      for (const desk of this.selected) {
        const editableDesk = this.deskById(desk.id);
        if (editableDesk.disabled) {
          return true;
        }
      }
      return false;
    },
    anyDeskEnabled() {
      for (const desk of this.selected) {
        const editableDesk = this.deskById(desk.id);
        if (!editableDesk.disabled) {
          return true;
        }
      }
      return false;
    },
    allNeighbourhoods() {
      const clonedNeighbourhoods = Array.from(this.allNeighbourhoodsSorted);
      clonedNeighbourhoods.splice(0, 0,
          {title: 'None', ref: firebase.firestore.FieldValue.delete()});
      return clonedNeighbourhoods;
    },
    deskList() {
      let deskList = '';
      const desksArr = this.selected;
      desksArr.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });

      desksArr.forEach((desk, index) => {
        if (this.maskedFloorName) {
          const floorPattern = /^[^.]+/;
          deskList += desk.title.replace(floorPattern, this.maskedFloorName);
        } else {
          deskList += desk.title;
        }
        if (index < this.selected.length - 1) deskList += ', ';
      });

      return deskList;
    },
    isAllowedToEditDeskSettings() {
      return this.hasAccessTo.deskBooking.deskSettings;
    },
    isAllowedToEditNeighbourhood() {
      return this.hasAccessTo.deskBooking.neighbourhoodSettings;
    }
  },
  methods: {
    ...mapMutations('selection', ['clearAll']),

    updateMultipleNeighbourhoods(selectedRef) {
      const neighbourhood = this.allNeighbourhoodsSorted.find(n => n.id === selectedRef.id);
      for (const desk of this.selected) {
        const editableDesk = this.deskById(desk.id);
        editableDesk.neighbourhood = neighbourhood;
      }
      this.neighbourhood = null;
      this.neighbourhoodSelectOpen = false;
    },

    updateDisabled(disabled) {
      for (const desk of this.selected) {
        const selectedDesk = this.deskById(desk.id);
        selectedDesk.disabled = disabled;
      }
    }
  }
};
</script>

<style scoped>

</style>
