import DeferUtil from '@/store/defer-util';
import {Logger} from '@vanti/vue-logger';
import {LoadingUtil} from '@/util/loading';
import Vue from 'vue';
import {decorateSnapshot} from '@/firebase';

const log = Logger.get('counts/site');

/**
 * This store combines v1 and v2 site counts to get desk, room and site capacity values
 */
export default {
  namespaced: true,
  state: {
    ...LoadingUtil.state(),
    /**
     * Site counts pulled from the v1 counts collection:
     * /ns/{ns}/sites/{site}/counts
     *
     * @type {Object.<string, kahu.firestore.stats.SiteCounts>}
     */
    siteCounts: {},
    /**
     * Site counts pulled from the v2 counts collection:
     * /ns/{ns}/counts
     *
     * @type {Object.<string, kahu.firestore.stats.SiteCounts>}
     */
    nsSiteCounts: {}
  },
  getters: {
    ...LoadingUtil.getters(log),
    siteCapacity(state, getters) {
      return getters.deskCapacity + getters.roomCapacity;
    },
    siteCount(state, getters) {
      return getters.deskCount + getters.roomCount;
    },
    deskCount(state) {
      return state.siteCounts.deskCount || 0;
    },
    deskCountByFloor(state) {
      const byFloor = state.siteCounts.byFloor || {};
      const countByFloor = {};
      for (const [floor, counts] of Object.entries(byFloor)) {
        countByFloor[floor] = counts && counts.deskCount || 0;
      }
      return countByFloor;
    },
    deskCapacity(state) {
      return state.siteCounts.deskCapacity || 0;
    },
    deskCapacityByFloor(state) {
      const byFloor = state.siteCounts.byFloor || {};
      const countByFloor = {};
      for (const [floor, counts] of Object.entries(byFloor)) {
        countByFloor[floor] = counts && counts.deskCapacity || 0;
      }
      return countByFloor;
    },
    roomCount(state) {
      return state.nsSiteCounts.roomCount || 0;
    },
    roomCountByFloor(state) {
      const byFloor = state.nsSiteCounts.byFloor || {};
      const countByFloor = {};
      for (const [floor, counts] of Object.entries(byFloor)) {
        countByFloor[floor] = counts && counts.roomCount || 0;
      }
      return countByFloor;
    },
    roomCapacity(state) {
      return state.nsSiteCounts.roomCapacity || 0;
    },
    roomCapacityByFloor(state) {
      const byFloor = state.nsSiteCounts.byFloor || {};
      const countByFloor = {};
      for (const [floor, counts] of Object.entries(byFloor)) {
        countByFloor[floor] = counts && counts.roomCapacity || 0;
      }
      return countByFloor;
    }
  },
  mutations: {
    ...LoadingUtil.mutations(),
    ...DeferUtil.mutations(log),
    updateSiteCounts(state, counts) {
      Vue.set(state, 'siteCounts', counts);
    },
    updateNsSiteCounts(state, counts) {
      Vue.set(state, 'nsSiteCounts', counts);
    },
    clear(state) {
      state.siteCounts = {};
      state.nsSiteCounts = {};
    }
  },
  actions: {
    async bind({commit, state, dispatch, rootGetters}) {
      if (DeferUtil.hasDefer(state)) {
        // already bound
        return;
      }
      log.debug('bind');

      const defer = {};
      defer.siteChanged = this.watch(
          () => rootGetters['sites/activeSiteDoc'],
          activeSite => {
            commit('clear');
            dispatch('bindCounts', {activeSite})
                .catch(err => log.error('During bindBookings refresh', err));
          },
          {immediate: true}
      );
      commit('defer', defer);
    },

    async unbind({commit}) {
      commit('reset');
      commit('clear');
    },

    async bindCounts({state, commit, dispatch, rootGetters}, {activeSite}) {
      if (!activeSite) {
        return;
      }
      log.debug('bindCounts for site', activeSite && activeSite.ref && activeSite.ref.path);
      commit('loading', 'site-counts');
      commit('loading', 'ns-site-counts');

      const siteRef = activeSite.ref;
      const nsRef = siteRef.parent.parent;
      const siteCounts = siteRef.collection('counts').doc('site');
      const nsSiteCounts = nsRef.collection('counts').doc(activeSite.id);

      const defer = {};

      defer.siteCounts = siteCounts.onSnapshot(
          doc => {
            commit('updateSiteCounts', decorateSnapshot(doc));
            commit('loaded', 'site-counts');
          },
          err => {
            log.error('siteCounts.onSnapshot', err);
            commit('loaded', 'site-counts');
          }
      );

      defer.nsSiteCounts = nsSiteCounts.onSnapshot(
          doc => {
            commit('updateNsSiteCounts', decorateSnapshot(doc));
            commit('loaded', 'ns-site-counts');
          },
          err => {
            log.error('nsSiteCounts.onSnapshot', err);
            commit('loaded', 'ns-site-counts');
          }
      );

      commit('defer', defer);
    }
  }
};
