import {DocumentCollection} from '@/store/collection/document-collection';
import {db, decorateReference} from '@/firebase';

/**
 * All the information related to a list of users. Including the query used to collect that list and any metadata about
 * the list that we think we know - i.e. do we think there are more records to get.
 */
export class UsersCollection extends DocumentCollection {
  /**
   * Construct the collection with all default values.
   *
   * @param {string} ns The active namespace
   * @param {QueryClause[]} queryClauses The list of query clauses
   */
  constructor(ns, queryClauses) {
    super(ns, queryClauses);
    /**
     * A set of seen people references for de-duplicating search records into the user list.
     *
     * @type {Set<string>}
     */
    this.seenUserRefs = new Set();
  }

  /**
   * @return {Promise<firebase.firestore.CollectionReference<firebase.firestore.DocumentData>>}
   */
  async baseQuery() {
    const _db = await db;
    return _db.collection('ns/' + this.ns + '/search/users/records')
        .orderBy('user.email');
  }


  /**
   * Adds the given list of records to the records list.
   *
   * @override
   * @param {DecoratedData[]} records
   * @private
   */
  saveRecords(records) {
    this.records.push(...records);

    // save unique people
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.user && record.user.ref) {
        if (record.user.type && record.user.type !== 'user') {
          continue;
        }
        const key = record.user.ref.path;
        if (!this.seenUserRefs.has(key)) {
          this.seenUserRefs.add(key);
          const user = decorateReference(record.user);
          this.documents.push(user);
        }
      }
    }
  }
}
