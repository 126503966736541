import {decorateSnapshot} from '@/firebase';
import FirestoreUtil, {byRef} from '@/store/firestore-util';
import peopleStore from '@/views/people/directory/people-store';
import workOrderStore from '@/views/room-booking/calendar/work-orders';
import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';
import * as impl from '@/views/room-booking/calendar/v2/room-booking';
import {BookingStoreMixin, EventStoreMixin, ResourceStoreMixin} from '@/views/room-booking/calendar/booking-store-util';
import {resourceQueryForSite} from '@/views/room-booking/calendar/v2/room-booking';
import {LoadingUtil} from '@/util/loading';
import {isEqual} from 'lodash';
import {chunkArray} from '@/util/chunks';
import {DefaultTimeZone, toISODate} from '@/util/dates';
import {zonedTimeToUtc} from 'date-fns-tz';
import {datesInRange} from '@/views/dashboard/statistics/stats-util';

const log = Logger.get('booking-calendar');

/**
 * The day object used for VCalendar changes
 *
 * @see https://vuetifyjs.com/en/api/v-calendar/#events-change
 *
 * @typedef {Object} VCalendarDay
 * @property {string} date
 */

/**
 * The period object used for VCalendar change events
 *
 * @see https://vuetifyjs.com/en/api/v-calendar/#events-change
 *
 * @typedef {Object} TimePeriod
 * @property {VCalendarDay|null} start
 * @property {VCalendarDay|null} end
 */

export default {
  namespaced: true,
  state: {
    /**
     * The time period selected on the VCalendar
     *
     * @type {TimePeriod}
     */
    timePeriod: {
      start: null,
      end: null
    },

    filterSelection: null,
    showRejectedEvents: false,

    ...BookingStoreMixin.state(),
    ...ResourceStoreMixin.state(),
    ...LoadingUtil.state(),

    /**
     * @typedef {Object} BookingsQuery
     * @property {number} useCounter
     * @property {function():void} cancel
     * @property {TimePeriod} timePeriod
     * @property {DecoratedData<kahu.firestore.Resource>[]} resources
     * @property {CalendarEvent[]} events
     * @property {Object<number,Object<string,Booking>>} bookings Keyed by query id, then booking ref
     */
    /**
     * Represents all the queries we are running against the server for bookings and their responses.
     *
     * @type {BookingsQuery[]}
     */
    bookingQueries: []
  },
  getters: {
    ...LoadingUtil.getters(log),
    eventsToShow(state, getters) {
      if (state.showRejectedEvents) {
        return getters.events;
      }
      return getters.events.filter(e => e.showEvent);
    },
    hiddenEventsCount(state, getters) {
      return getters.events.length - getters.eventsToShow.length;
    },
    events(state, getters) {
      if (state.added.length > 0) {
        // only do this if we have to, avoid copying arrays.
        return getters.allEvents.concat(state.added);
      }
      return getters.allEvents;
    },
    ...EventStoreMixin.getters(),
    eventsByCategory(state, getters) {
      const index = {};
      getters.events.forEach(event => {
        const category = event.category;
        const bucket = index[category] || (index[category] = []);
        bucket.push(event);
      });
      return category => index[category] || [];
    },

    bookings(state, getters) {
      return bookingsToArray(getters.primaryBookings);
    },

    ...ResourceStoreMixin.getters(),

    queryForParameters(state) {
      return params => {
        return state.bookingQueries.find(query => {
          if (!timePeriodWithin(query.timePeriod, params.timePeriod)) return false; // time isn't covered
          // either the query searches for all the resources, or it searches for enough to cover the params
          return !query.resources || containsAllRefs(query.resources, params.resources);
        });
      };
    },
    primaryQuery(state, getters) {
      return getters.queryForParameters({timePeriod: state.timePeriod, resources: getters.resources});
    },
    primaryBookings(state, getters) {
      const q = getters.primaryQuery;
      if (!q) return {};
      return q.bookings;
    },
    allEvents(state) {
      // remove duplicates - since multiple queries could contain the same events
      const byKey = {};
      const queryEvents = [].concat(...state.bookingQueries.map(q => q.events));
      for (const e of queryEvents) {
        const key = impl.calculateKey(e.key);
        if (!byKey.hasOwnProperty(key)) {
          byKey[key] = e;
        }
      }
      return Object.values(byKey);
    },
    roomCount(state, getters) {
      return getters.resources?.length;
    }
  },
  mutations: {
    ...LoadingUtil.mutations(),
    setTimeRange(state, range) {
      state.timePeriod = range;
    },
    setFilterSelection(state, selection) {
      if (isEqual(state.filterSelection, selection)) return; // don't trigger Vue updates if there are no changes
      state.filterSelection = selection;
    },
    setShowRejectedEvents(state, showRejectedEvents) {
      state.showRejectedEvents = showRejectedEvents;
    },
    applyBookingSnapshotUpdate(state, {snapshot, id}) {
      const bookings = state.bookings;
      if (!bookings.hasOwnProperty(id)) Vue.set(bookings, id, {});
      FirestoreUtil.indexQuerySnapshotUpdates(bookings[id], snapshot, byRef);
    },
    applyQueryBookingSnapshotUpdate(state, {query, snapshot, id}) {
      const bookings = query.bookings;
      if (!bookings.hasOwnProperty(id)) Vue.set(bookings, id, {});
      FirestoreUtil.indexQuerySnapshotUpdates(bookings[id], snapshot, byRef);
    },
    setQueryEvents(state, {query, events}) {
      query.events = events;
    },

    ...BookingStoreMixin.mutations(),
    ...ResourceStoreMixin.mutations(),
    editComplete(state, booking) {
      Vue.delete(state.edits, booking.id);

      // handle the case where the event has been deleted
      const deletedIndex = state.deleted.findIndex(e => e.booking.id === booking.id);
      if (deletedIndex >= 0) {
        state.bookingQueries.forEach(q => {
          const eventIndex = q.events.findIndex(e => e.booking && e.booking.id === booking.id);
          if (eventIndex >= 0) {
            q.events.splice(eventIndex, 1);
          }
        });
        state.deleted.splice(deletedIndex, 1);
      }
      // handle the case where the edit is part of a new booking
      const addedIndex = state.added.findIndex(e => e.booking.id === booking.id);
      if (addedIndex >= 0) {
        if (deletedIndex === -1) {
          state.bookingQueries.forEach(q => {
            q.events.push(state.added[addedIndex]);
          });
        }
        state.added.splice(addedIndex, 1);
      }
    },
    clear(state) {
      state.resources = [];
      state.bookingQueries.forEach(q => q.cancel());
      state.bookingQueries = [];
      state.edits = {};
      state.added = [];
      state.deleted = [];
    },
    clearBookings(state) {
      state.bookings = {};
    },

    addQuery(state, query) {
      state.bookingQueries.push(query);
    },
    removeQuery(state, query) {
      const index = state.bookingQueries.findIndex(q => q === query);
      if (index >= 0) {
        state.bookingQueries.splice(index, 1);
      }
    },
    addQueryUse(state, query) {
      query.useCounter++;
    },
    removeQueryUse(state, query) {
      query.useCounter--;
    }
  },
  actions: {
    bind({dispatch, rootGetters, getters, state}) {
      let oldActiveSiteId = null; // keep track of the site ID to check for actual changes

      // keep track of the active site and fetch any resource rooms for that site.
      this.watch(
          () => rootGetters['sites/activeSiteDoc'],
          activeSiteDoc => {
            const newSiteId = activeSiteDoc && activeSiteDoc.id;
            if (oldActiveSiteId === newSiteId) {
              return;
            }
            oldActiveSiteId = newSiteId;
            if (!state.filterSelection) {
              dispatch('fetchActiveSiteResources');
            }
          },
          {immediate: true}
      );

      let oldNsId = null;
      let oldFilterSelection = null;

      this.watch(
          () => [state.filterSelection, rootGetters['ns/nsRef']],
          ([selection, nsRef]) => {
            const nsId = nsRef && nsRef.id;
            const noNsChange = oldNsId === nsId;
            const noFilterChange = selection === oldFilterSelection;
            if (noNsChange && noFilterChange) {
              return;
            }
            oldNsId = nsId;
            oldFilterSelection = selection;
            if (selection) {
              dispatch('fetchSelectionResources', {selection, nsRef});
            } else {
              dispatch('fetchActiveSiteResources');
            }
          },
          {immediate: true}
      );

      // keep track of the time period and list of resource rooms so we can fetch bookings for them
      let cancelCurrentQuery = () => {
      };
      this.watch(
          () => [state.timePeriod, getters.resources],
          () => {
            cancelCurrentQuery();
            dispatch('watchBookings', {timePeriod: state.timePeriod, resources: getters.resources})
                .then(cancel => cancelCurrentQuery = cancel);
          },
          {immediate: true}
      );
    },
    async fetchSelectionResources({commit, state, rootGetters, dispatch}, {selection, nsRef}) {
      if (!nsRef || !selection) return;
      log.debug('fetchSelectionResources', selection);
      commit('loading', 'selection-resources');

      const groupsCol = nsRef.collection('groups');
      const resourcesCol = nsRef.collection('resources');
      const promises = [];
      for (const siteId of selection.sites) {
        promises.push(dispatch('fetchResourcesForSite', groupsCol.doc(siteId)));
      }
      promises.push(dispatch('fetchResources', selection.resources.map(id => resourcesCol.doc(id))));
      const resourceArrays = await Promise.all(promises);
      commit('setResources', resourceArrays.flat());
      commit('loaded', 'selection-resources');
    },
    async fetchResources({commit}, refs) {
      return Promise.all(refs.map(r => r.get().then(snap => decorateSnapshot(snap))));
    },
    async fetchResourcesForSite({commit}, siteRef) {
      log.debug('fetchResourcesForSite', siteRef.path);
      const resourcesSnap = await resourceQueryForSite({ref: siteRef}).get();
      return resourcesSnap.docs
          .map(snap => decorateSnapshot(snap));
    },
    async fetchActiveSiteResources({commit, rootGetters}) {
      commit('loading', 'site-resources');
      /** @type {{ref: firebase.firestore.DocumentReference}|null} */
      const site = rootGetters['sites/activeSiteDoc'];
      log.debug('fetchActiveSiteResources', site && site.id);
      if (!site) {
        commit('loaded', 'site-resources');
        commit('clear');
        return;
      }

      const resources = await impl.resourceQueryForSite(site).get();
      commit('setResources', resources.docs.map(doc => decorateSnapshot(doc)));
      commit('loaded', 'site-resources');
    },

    /**
     * @param {import('vuex').ActionContext} context
     * @param {Object} payload
     * @param {TimePeriod} payload.timePeriod
     * @param {DecoratedData[]} payload.resources
     * @return {Promise<function():void>}
     */
    async watchBookings(context, {timePeriod, resources}) {
      const {commit, getters} = context;
      const {start, end} = timePeriod;

      // first we check if there's already a query that covered the parameters we are being asked to watch
      const existingQuery = getters.queryForParameters({timePeriod, resources});
      if (existingQuery) {
        // we're already querying for bookings that this watch should find
        commit('addQueryUse', existingQuery);
        return existingQuery.cancel;
      }

      if (!start || !end || !resources || resources.length === 0) {
        return () => {
        };
      }

      commit('loading', 'watch-bookings');

      /** @type {firebase.firestore.CollectionReference} */
      const collection = resources[0].ref.parent.parent.collection('bookings');
      const dates = periodToDates(timePeriod);

      // open snapshot listeners for each of the resources

      // We're about to use the 'in' query to search for bookings in sets of spaces.
      // 'in' only supports up to 10 items in its query so we chunk up all the
      // resources in a collection into groups of 10 max.
      const chunks = chunkArray(resources);
      log.debug('watchBookings', {dates, chunks: chunks.length, collection: collection.path});

      const cancellers = [];
      const loadingKeys = [];
      const query = {
        useCounter: 1,
        resources: resources,
        timePeriod: timePeriod,
        events: [],
        bookings: {},
        cancel: () => {
          commit('removeQueryUse', query);
          // nobody is using the query anymore
          if (query.useCounter <= 0) {
            commit('removeQuery', query);
            cancellers.forEach(c => c()); // cancel snapshot listeners
            loadingKeys.forEach(k => commit('deleteLoaded', k)); // remove any loading keys
          }
        }
      };
      commit('addQuery', query);

      let queryId = 1;
      for (let i = 0; i < chunks.length; i++) {
        const refs = chunks[i].map(b => b.ref);
        for (const date of dates) {
          const id = queryId++;
          const loadingKey = `watch-bookings-query-${id}-${date}`;
          loadingKeys.push(loadingKey);
          commit('loading', loadingKey);
          const cancel = impl.bookingQueryInCollectionOnDate(collection, refs, date)
              .onSnapshot(
                  snapshot => {
                    const safeSnapshot = FirestoreUtil.prepareQuerySnapshot(snapshot);
                    commit('applyQueryBookingSnapshotUpdate', {query, snapshot: safeSnapshot, id});
                    // todo: try to re-compute only the events that have changed
                    commit('setQueryEvents', {
                      query,
                      events: impl.computeEvents(context, bookingsToArray(query.bookings))
                    });
                    commit('loaded', loadingKey);
                  },
                  err => {
                    commit('loaded', loadingKey);
                    log.error(`during booking query for chunk ${i} on ${date} in ${collection.path}`, err);
                  }
              );
          cancellers.push(cancel);
        }
      }

      commit('loaded', 'watch-bookings');
      return query.cancel;
    },
    ...BookingStoreMixin.actions(log),

    async copyEditsToBookingStore(context, bookingId) {
      const {state, commit, getters, rootGetters} = context;
      const bookingRef = rootGetters['ns/nsRef'].collection('bookings').doc(bookingId);
      // transfer local edits from our store to the /booking store for use with the booking sidebar
      const edits = state.edits[bookingId] || {};
      const isRelatedEvent = e => e.id === bookingId ||
          (e.connection && e.connection.ref && e.connection.ref.ref && e.connection.ref.ref.isEqual(bookingRef));
      const added = state.added.filter(isRelatedEvent);
      const deleted = state.deleted.filter(isRelatedEvent);
      commit('views/roomBooking/booking/setToReplay', {edits: {[bookingId]: edits}, added, deleted}, {root: true});
      commit('views/roomBooking/booking/workOrders/setToReplay', getters['workOrders/getEdits'], {root: true});
      commit('resetEdit', {id: bookingId});
    }
  },
  modules: {
    owners: peopleStore(),
    workOrders: workOrderStore()
  }
};

/**
 * @param {TimePeriod} larger
 * @param {TimePeriod} smaller
 * @return {boolean}
 */
function timePeriodWithin(larger, smaller) {
  return Boolean(larger && smaller) &&
      larger.start.date <= smaller.start.date && larger.end.date >= smaller.end.date;
}

/**
 * Returns true if all byRef of needles are found in byRef of haystack.
 *
 * @param {Object[]} haystack
 * @param {Object[]} needles
 * @return {boolean}
 */
function containsAllRefs(haystack, needles) {
  for (const needle of needles) {
    const ref = byRef(needle);
    if (haystack.findIndex(doc => byRef(doc) === ref) === -1) return false;
  }
  return true;
}

/**
 * @param {Object<number,Object<string,Booking>>} bookings
 * @return {Booking[]}
 */
function bookingsToArray(bookings) {
  const res = {};
  // we do it this way just in case there are duplicate ids in each bucket of bookings.
  Object.values(bookings).forEach(segment => Object.assign(res, segment));
  return Object.values(res);
}

/**
 * Returns all UTC dates the period intersects. End is exclusive, so if end is the start of
 * a day, that day won't be included; but up to the end of the previous day will.
 *
 * @param {TimePeriod} period
 * @param {string} [timeZone] - the time zone to interpret YYYY-MM-DD dates in
 * @return {string[]}
 */
export function periodToDates({start, end}, timeZone = DefaultTimeZone) {
  const dates = [];
  if (!start || !end) {
    return dates;
  }

  const fromTime = zonedTimeToUtc(`${start.date} 00:00:00.000`, timeZone);
  let toTime = zonedTimeToUtc(`${end.date} 00:00:00.000`, timeZone);
  toTime = new Date(toTime.getTime() - 1); // toTime is exclusive

  return datesInRange(fromTime, toTime).map(toISODate);
}
