<template>
  <v-autocomplete
      :search-input.sync="searchText"
      :loading="loading"
      :items="items"
      item-text="title"
      :item-value="byRef"
      :value="selected"
      @input="selectedChanged"
      :readonly="readonly"
      :append-icon="readonly ? '' : '$dropdown'"
      label="Type to search for a person"
      hide-no-data
      v-bind="$attrs"
      :clearable="!readonly">
    <template #append-outer>
      <slot name="append-outer"/>
    </template>
  </v-autocomplete>
</template>

<script>
import {byRef} from '@/store/firestore-util';
import {debounce} from 'lodash';

export default {
  name: 'PersonSearchField',
  props: {
    storePath: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      default: null
    },
    readonly: Boolean
  },
  data() {
    return {
      queryPending: false,
      searchText: null,
      /** @type {string|null} */
      selected: null
    };
  },
  computed: {
    people() {
      return this.$store.getters[`${this.storePath}/people`];
    },
    loadingInfo() {
      return this.$store.getters[`${this.storePath}/loadingInfo`];
    },
    loading() {
      return this.queryPending || this.loadingInfo.loading;
    },
    items() {
      // make sure the value is always in the list of items
      const result = this.people;
      if (this.value) {
        // noinspection JSCheckFunctionSignatures
        const valueRef = byRef(this.value);
        return [this.value].concat(result.filter(p => byRef(p) !== valueRef));
      }
      return result;
    },
    selectedPerson() {
      if (!this.selected) return this.selected;
      return this.items.find(p => byRef(p) === this.selected) || null;
    },
    queryClauses() {
      const clauses = [];
      // special keywords handling
      if (this.searchText) {
        const parts = this.searchText.toLowerCase().split(/\s/g);
        if (parts.length === 1) {
          clauses.push(['keywords', 'array-contains', parts[0]]);
        } else if (parts.length > 1) {
          clauses.push(['keywords', 'array-contains-any', parts]);
        }
      } else {
        return []; // don't search without a name
      }

      clauses.push(['person.active', '==', true]);

      return clauses;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(n, o) {
        const ref = byRef(n);
        if (ref !== byRef(o)) {
          this.selected = ref;
        }
      }
    },
    queryClauses: {
      deep: true,
      handler() {
        this.queryPending = this.queryClauses.length > 0;
        // noinspection JSValidateTypes
        this.debounceQueryForPeople();
      }
    }
  },
  methods: {
    byRef,
    debounceQueryForPeople: debounce(function() {
      // eslint-disable-next-line no-invalid-this
      this.queryForPeople();
      // eslint-disable-next-line no-invalid-this
      this.queryPending = false;
    }, 500),
    queryForPeople() {
      const clauses = this.queryClauses;
      this.$store.dispatch(`${this.storePath}/bind`, clauses)
          .then(() => {
            if (clauses.length > 0) {
              // load one page
              this.$store.dispatch(`${this.storePath}/load`);
              this.$store.dispatch(`${this.storePath}/stop`);
            }
          })
          .catch(err => this.$logger.error('during owner query', err));
    },
    selectedChanged(selected) {
      const old = this.selected;
      if (selected !== old) {
        this.selected = selected;
        this.$emit('input', this.selectedPerson);
      }
    }
  }
};
</script>

<style scoped>
.v-input >>> .v-input__append-outer, .v-input__prepend-outer {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
