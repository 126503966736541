import * as dates from '@/util/dates';
import {bookingValue, editValue} from './event';
import {removeCircularReferences} from '@/firebase';

/**
 * Define on the given event properties related to connected bookings.
 *
 * @param {import('vuex').ActionContext} context
 * @param {CalendarEvent} event
 */
export function defineConnectionProperties(context, event) {
  const booking = event.booking;

  Object.defineProperty(event, 'connection', {
    enumerable: true,
    get() {
      /** @type {kahu.firestore.ConnectedReservation} */
      const rawConnection = bookingValue(context, booking, 'connection');
      if (!rawConnection) return null;
      const connectedEvent = context.getters.eventByKey(rawConnection.ref);
      if (!connectedEvent) return null;
      return {
        anchor: rawConnection.anchor,
        ref: rawConnection.ref,
        event: connectedEvent
      };
    },
    /**
     * @param {import('../calendar-event.d.ts').Connection} v
     */
    set(v) {
      let value = null;
      if (v) {
        value = {};
        if (v.anchor) {
          value.anchor = v.anchor;
        }
        if (v.event) {
          value.ref = {
            ref: removeCircularReferences(v.event.booking.ref),
            id: v.event.reservationKey
          };
        }
      }
      editValue(context, booking, 'connection', value);
    }
  });

  Object.defineProperty(event, 'connected', {
    enumerable: true,
    get() {
      return Boolean(event.connection);
    }
  });

  Object.defineProperty(event, 'allConnections', {
    enumerable: false, // this has circular refs, so can't be enumerated. Otherwise this breaks dev tools
    get() {
      const connections = [];

      // check if we are connected to something else
      const connection = event.connection;
      if (connection) {
        connections.push({
          from: event,
          to: connection.event,
          anchor: connection.anchor
        });
      }

      // check if others are connected to us
      const myConnections = event.connectionsIn
          // filter out connections for other reservations
          .filter(({event: e}) => e.connection.ref.id === event.reservationKey)
          .filter(({event: e}) => !e.deleted)
          .map(({event: e, anchor}) => {
            return {
              from: e,
              to: event,
              anchor
            };
          });
      connections.push(...myConnections);
      return connections;
    }
  });
  Object.defineProperty(event, 'connectionsIn', {
    enumerable: false, // can cause circular references with connection
    get() {
      // note: we don't check for connectionCount > 0 here, even though it could be more efficient
      // because connectionCount is only updated by server functions and the data we're working with
      // might be local only during an edit.
      return context.getters.eventsByConnectionRef(event.ref);
    }
  });
  Object.defineProperty(event, 'adjacentBefore', {
    enumerable: false, // this has circular refs, so can't be enumerated. Otherwise this breaks dev tools
    get() {
      for (const {from, to, anchor} of event.allConnections) {
        if (to === event) {
          if (anchor === 'START' && from.end === to.start) {
            return from;
          }
        } else if (anchor === 'END' && to.end === from.start) {
          return to;
        }
      }
      return null;
    }
  });
  Object.defineProperty(event, 'hasAdjacentBefore', {
    enumerable: true,
    get() {
      return Boolean(event.adjacentBefore && event.adjacentBefore.duration > 0);
    }
  });

  Object.defineProperty(event, 'adjacentAfter', {
    enumerable: false, // this has circular refs, so can't be enumerated. Otherwise this breaks dev tools
    get() {
      for (const {from, to, anchor} of event.allConnections) {
        if (from === event) {
          if (anchor === 'START' && from.end === to.start) {
            return to;
          }
        } else if (anchor === 'END' && to.end === from.start) {
          return from;
        }
      }
      return null;
    }
  });
  Object.defineProperty(event, 'hasAdjacentAfter', {
    enumerable: true,
    get() {
      return Boolean(event.adjacentAfter && event.adjacentAfter.duration > 0);
    }
  });

  Object.defineProperty(event, 'setupMins', {
    enumerable: true,
    get() {
      const adj = event.adjacentBefore;
      return adj ? adj.duration / dates.minutes : 0;
    },
    set(v) {
      const adj = event.adjacentBefore;
      if (!adj) {
        if (v <= 0) return; // no event and no time, all is good
        context.dispatch('createNewBooking', {
          start: event.start - v * dates.minutes,
          end: event.start,
          category: event.category
        }).then(newEvent => {
          newEvent.name = 'Setup';
          newEvent.connection = {
            anchor: 'START',
            event
          };
          // todo: set the owner
        });
      } else {
        if (v === 0) {
          adj.delete();
        } else if (adj.deleted) {
          adj.restore();
        }
        adj.start = adj.end - (v * dates.minutes);
      }
    }
  });

  Object.defineProperty(event, 'cleanDownMins', {
    enumerable: true,
    get() {
      const adj = event.adjacentAfter;
      return adj ? adj.duration / dates.minutes : 0;
    },
    set(v) {
      const adj = event.adjacentAfter;
      if (!adj) {
        if (v <= 0) return; // no event and no time, all is good
        context.dispatch('createNewBooking', {
          start: event.end,
          end: event.end + v * dates.minutes,
          category: event.category
        }).then(newEvent => {
          newEvent.name = 'Clean down';
          newEvent.connection = {
            anchor: 'END',
            event
          };
          // todo: set the owner
        });
      } else {
        if (v === 0) {
          adj.delete();
        } else if (adj.deleted) {
          adj.restore();
        }
        adj.end = adj.start + (v * dates.minutes);
      }
    }
  });
}
